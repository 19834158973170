export type InvoiceForListView = {
  invoiceId: string;
  hirerName: string;
  invoiceDate: string;
  invoiceDueDate: string;
  invoiceReference: string;
  status: InvoiceStatus;
  total: number;
};
export type Invoice = {
  invoiceId: string;
  hirerId: string;
  hirerName: string;
  invoiceDate: string;
  invoiceDueDate: string;
  invoiceReference: string;
  displayReference: string;
  poReference: string;
  status: InvoiceStatus;
  subTotal: number;
  vatAmount: number;
  total: number;
  createdDateTime: string;
  createdBy: string;
  paidDateTime?: string;
  paidBy?: string;
  rejectedBy?: string;
  rejectedDateTime?: string;
  approvedBy?: string;
  approvedDateTime?: string;
  invoiceItems: InvoiceItem[];
};

export type InvoiceItem = {
  invoiceItemId: string;
  shiftId: string;
  description: string;
  subTotal: number;
  vatAmount: number;
  total: number;
};

export type ShiftAwaitingInvoice = {
  shiftId: string;
  description: string;
  total: number;
};

export type ShiftsAwaitingInvoiceSummary = {
  weekStartDate: string;
  weekEndDate: string;
  count: number;
  total: number;
};

export enum InvoiceStatus {
  Draft = 'Draft',
  AwaitingApproval = 'Awaiting Approval',
  Approved = 'Approved',
  Sent = 'Sent',
  Paid = 'Paid',
  Rejected = 'Rejected',
}

export type RemoveInvoiceItemFromInvoiceParams = {
  invoiceId: string;
  invoiceItemId: string;
};

export type AddShiftToInvoiceParams = {
  invoiceId: string;
  shiftId: string;
};

export type DeleteInvoiceParams = {
  invoiceId: string;
};

export type RequestApprovalInvoiceParams = {
  invoiceId: string;
};

export type ApproveInvoiceParams = {
  invoiceId: string;
};

export type RejectInvoiceParams = {
  invoiceId: string;
};

export type MarkAsPaidInvoiceParams = {
  invoiceId: string;
};

export type DownloadInvoiceParams = {
  invoiceId: string;
};

export type GetShiftsAwaitingInvoiceQueryParams = {
  hirerId: string;
};

export type InvoiceQueryType =
  | {
      type: 'weekNumber';
      data: {
        year: string;
        weekNumber: string;
      };
    }
  | {
      type: 'dateRange';
      data: {
        fromDate: string;
        toDate: string;
      };
    };

export type GetShiftsAwaitingInvoiceSummaryQueryParams = {
  hirerId: string;
  queryType: InvoiceQueryType;
};

export type GetInvoicesQueryParams = {
  hirerId: string;
};
export type CreateInvoiceParams = {
  hirerId: string;
  queryType: InvoiceQueryType;
};

export type CreateInvoiceResponse = {
  invoiceId: string;
};

export type InvoiceEditParams = Pick<
  Invoice,
  | 'invoiceId'
  | 'invoiceReference'
  | 'poReference'
  | 'invoiceDate'
  | 'invoiceDueDate'
>;
