import { Component } from 'react';
import { Button, Col, Modal, Panel, Row } from 'react-bootstrap';
import $ from 'jquery';
import { AgencyUtils } from 'pages/Agency/AgencyUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { AlertWithIcon, Field, Icon, WellSmall } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { searchWorkers, sendAddWorkerRequest } from 'utils/api';

export class AddWorkerButton extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, isSaving: false };
    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  handleShowModalClick() {
    this.setState({ showModal: true });
  }

  handleCloseModalClick() {
    this.setState({ showModal: false });
  }

  render() {
    const title = 'Register Worker';
    const bsSize = this.props.isXs ? 'xs' : this.props.isSm ? 'sm' : null;
    const block = this.props.block || false;
    return (
      <>
        <Button
          bsStyle='primary'
          bsSize={bsSize}
          block={block}
          onClick={this.handleShowModalClick}
          className={this.props.className}
          style={this.props.style}
        >
          <Icon icon='fa-clipboard-check' /> {title}
        </Button>
        <AddWorkerModal
          {...this.state}
          {...this.props}
          title={title}
          handleCloseModalClick={this.handleCloseModalClick}
        />
      </>
    );
  }
}

class AddWorkerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isSearching: false,
      hasSearched: false,
      WorkerInternalReference: '',
      workerRecord: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onEntered() {
    document.getElementById('WorkerInternalReference').focus();
  }

  onExited() {
    this.setState({
      hasSearched: false,
      workerRecord: null,
      WorkerInternalReference: '',
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleResetClick() {
    this.setState({
      hasSearched: false,
      workerRecord: null,
    });
  }

  async handleSearchClick() {
    const me = this;

    if (!me.state.WorkerInternalReference) {
      AppUtils.addInvalidClass($('#WorkerInternalReference'));
      AppUtils.displayError(
        'Error',
        'Please enter a reference',
        false,
        'validationError'
      );
      return;
    }

    me.setState({ isSearching: true, workerRecord: null });
    AppUtils.clearNotifications();

    const data = await searchWorkers(me.state.WorkerInternalReference);
    AppUtils.handleAjaxDone(data, () => {
      if (data.record) {
        me.setState({ workerRecord: data.record });
      } else {
        throw new Error('Could not get record');
      }
    });
    me.setState({ isSearching: false, hasSearched: true });
  }

  async handleSubmitClick() {
    this.setState({ isSaving: true });
    AppUtils.clearNotifications();

    const data = await sendAddWorkerRequest(this.state.WorkerInternalReference);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Invitation Sent');
      this.props.handleCloseModalClick();
      PubSub.publish(AgencyUtils.Events.WorkerRequestSent, data);
    });
    this.setState({ isSaving: false });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.showModal}
        onHide={this.props.handleCloseModalClick}
        onEntered={this.onEntered}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body
            {...this.state}
            handleChange={this.handleChange}
            handleSearchClick={this.handleSearchClick}
            handleSubmitClick={this.handleSubmitClick}
            handleResetClick={this.handleResetClick}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.handleCloseModalClick}>
            <i className='fa fa-times' /> Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = (props) => {
  return (
    <>
      <SearchField {...props} />
      <WorkerConfirmationUi {...props} />
      <WorkerNotFoundUi {...props} />
    </>
  );
};

const SearchField = (props) => {
  if (props.workerRecord) {
    return null;
  }
  return (
    <>
      <WellSmall>
        <h4>Worker Registration</h4>
        <ul>
          <li>
            Search for the worker by entering their unique reference and
            pressing the Search button.
          </li>
          <li>
            {`Once you've found the worker, send an invitation for them to start working with your agency.`}
          </li>
          <li>
            {`Once the invitation has been accepted, they'll be able to view available shifts and begin applying for work.`}
          </li>
        </ul>
      </WellSmall>
      <Row>
        <Field.InCol
          columns={12}
          name='WorkerInternalReference'
          label='Worker Reference'
          value={props.WorkerInternalReference}
          maxLength='50'
          required
          disabled={props.workerRecord ? true : false}
          isControlled
          onChange={props.handleChange}
          btnAddOnPost={<SearchButton {...props} />}
        />
      </Row>
    </>
  );
};

const SearchButton = (props) => {
  return (
    <Button
      bsStyle='primary'
      onClick={props.handleSearchClick}
      disabled={props.isSearching}
    >
      <Icon icon='fas fa-search' isSpinning={props.isSearching} /> Search
    </Button>
  );
};

const WorkerConfirmationUi = (props) => {
  const { workerRecord, isSearching, hasSearched } = props;
  if (!hasSearched) {
    return null;
  }
  if (isSearching) {
    return null;
  }
  if (!workerRecord) {
    return null;
  }
  return (
    <Panel bsStyle='success' className='m-b-none'>
      <Panel.Heading>
        <Icon icon='fas fa-thumbs-up' /> Match Found
      </Panel.Heading>
      <Panel.Body>
        <Row>
          <Col md={6}>
            <h4>
              {workerRecord.firstName} {workerRecord.surname}
            </h4>
            <em>
              <small>{workerRecord.emailAddress}</small>
            </em>
          </Col>
          <Col md={6} className='text-right'>
            Reference:{' '}
            <span className='label label-warning' style={{ fontSize: '1em' }}>
              {workerRecord.internalReference}
            </span>
          </Col>
        </Row>
      </Panel.Body>
      <Panel.Footer className='text-right'>
        <SearchAgainButton {...props} />
        <SendRequestButton {...props} />
      </Panel.Footer>
    </Panel>
  );
};

const WorkerNotFoundUi = ({
  workerRecord,
  isSearching,
  hasSearched,
  WorkerInternalReference,
}) => {
  if (!WorkerInternalReference) {
    return null;
  }
  if (isSearching) {
    return null;
  }
  if (!hasSearched || workerRecord) {
    return null;
  }
  return (
    <AlertWithIcon
      bsStyle='danger'
      icon='fa-thumbs-down fa-2x'
      className='m-b-none'
    >
      Could not find worker with reference: {WorkerInternalReference}
    </AlertWithIcon>
  );
};

const SendRequestButton = ({ isSaving, handleSubmitClick }) => {
  const buttonTitle = isSaving ? 'Sending...' : 'Send Invitation';
  return (
    <Button
      bsStyle='success'
      bsSize='sm'
      className='m-l-sm'
      onClick={handleSubmitClick}
      disabled={isSaving}
    >
      <Icon icon='fa-check' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};

const SearchAgainButton = ({ isSaving, handleResetClick }) => {
  return (
    <Button bsSize='sm' onClick={handleResetClick} disabled={isSaving}>
      <Icon icon='fa-search' /> Change Search
    </Button>
  );
};
