import { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'hooks/queries/queryUtils';
import {
  useAddShiftsToPlanConfigQuery,
  useCreatePlanItemsMutation,
} from 'hooks/queries/shiftPlanningQueries';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall } from 'pages/Utils/react-utils';
import {
  ShiftPlanItemCreateModel,
  ShiftTemplateOption,
} from 'types/ShiftPlanningTypes';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';

const getValueFromInputWithName = (name: string) => {
  const input = document.getElementsByName(name)[0];
  if (!input) {
    return '';
  }
  return (input as HTMLInputElement).value;
};

const _shiftTemplateLimit = 10;

export const AddShiftsModal = ({
  isModalOpen,
  handleModalClose,
  hirerId,
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
  hirerId: string;
}) => {
  const [shiftTemplateCount, setShiftTemplateCount] = useState(1);

  const createPlanItemsMutation = useCreatePlanItemsMutation();

  const queryClient = useQueryClient();

  const handleSubmitClick = () => {
    const items = Array.from({ length: shiftTemplateCount })
      .map<ShiftPlanItemCreateModel>((_, index) => {
        const shiftTemplateId = getValueFromInputWithName(
          `ShiftConfigId_${index}`
        );
        const shiftQty = getValueFromInputWithName(`ShiftQty_${index}`);
        if (!shiftTemplateId || !shiftQty) {
          return null;
        }

        if (!AppUtils.isNumeric(shiftQty)) {
          return null;
        }

        return {
          shiftTemplateId,
          shiftQty: parseInt(shiftQty),
        };
      })
      .filter((x) => x !== null);

    if (items.length <= 0) {
      AppUtils.displayError(
        'Error',
        'Please add at least one shift with a valid quantity'
      );
      return;
    }

    const maxShiftQty = 10;
    if (items.some((m) => m.shiftQty <= 0 || m.shiftQty > maxShiftQty)) {
      AppUtils.displayError(
        'Error',
        `Please enter a valid shift quantity. The maximum is ${maxShiftQty}.`
      );
      return;
    }

    createPlanItemsMutation.mutate(
      {
        hirerId: hirerId,
        items: items,
        isVehiclePlanItem: true,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.VehiclePlanForHirer],
          });
          AppUtils.displaySuccess('Success', 'Shifts added to plan');
          handleModalClose();
        },
      }
    );
  };

  const handleAddShiftsClick = () => {
    if (shiftTemplateCount >= _shiftTemplateLimit) {
      return;
    }
    setShiftTemplateCount(shiftTemplateCount + 1);
  };

  const handleRemoveShiftsClick = () => {
    if (shiftTemplateCount <= 1) {
      return;
    }

    setShiftTemplateCount(shiftTemplateCount - 1);
  };

  const configQuery = useAddShiftsToPlanConfigQuery(hirerId);

  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Shifts To Plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {configQuery.isLoading ? (
          <Row>
            <Col xs={12}>
              <WellSmall hasSpinner />
            </Col>
          </Row>
        ) : null}

        {configQuery.isError ? (
          <Row>
            <Col xs={12}>
              <ErrorAlert msg={extractErrorMessages(configQuery.error)} />
            </Col>
          </Row>
        ) : null}

        {configQuery.isSuccess ? (
          <>
            <Row>
              <Col xs={12}>
                {shiftTemplateCount < _shiftTemplateLimit ? (
                  <Button
                    bsSize='sm'
                    bsStyle='primary'
                    onClick={handleAddShiftsClick}
                  >
                    <Icon icon='fa-plus' /> Add Another Shift
                  </Button>
                ) : null}
                {shiftTemplateCount > 1 ? (
                  <Button
                    bsSize='sm'
                    bsStyle='danger'
                    onClick={handleRemoveShiftsClick}
                    className='m-l-xs'
                  >
                    <Icon icon='fa-times' /> Remove
                  </Button>
                ) : null}
              </Col>
            </Row>

            <hr />

            <Row>
              <Col xs={9}>
                <ColumnTitle title='Template' />
              </Col>
              <Col xs={3}>
                <ColumnTitle title='How Many?' />
              </Col>
            </Row>
            {Array.from({ length: shiftTemplateCount }).map((_, index) => {
              return (
                <Row key={index}>
                  <ShiftTemplateField
                    id={index}
                    shiftTemplates={configQuery.data?.shiftTemplates ?? []}
                  />
                  <ShiftQtyField id={index} />
                  <Col
                    xs={2}
                    style={{
                      textAlign: 'right',
                    }}
                  ></Col>
                </Row>
              );
            })}
          </>
        ) : null}

        {createPlanItemsMutation.isError ? (
          <Row>
            <Col xs={12}>
              <ErrorAlert
                msg={extractErrorMessages(createPlanItemsMutation.error)}
              />
            </Col>
          </Row>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>
          <i className='fa fa-times' /> Close
        </Button>
        <ModalSaveButton
          isSaved={createPlanItemsMutation.isSuccess}
          isSaving={createPlanItemsMutation.isLoading}
          onClick={handleSubmitClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

const ColumnTitle = ({ title }: { title: string }) => {
  return <h5>{title}</h5>;
};

const ShiftTemplateField = ({
  shiftTemplates,
  id,
}: {
  shiftTemplates: ShiftTemplateOption[];
  id: number;
}) => {
  if (shiftTemplates.length <= 0) {
    return (
      <Col xs={12}>
        <WellSmall>
          Could not find shift template options for chosen customer
        </WellSmall>
      </Col>
    );
  }

  const templates = shiftTemplates.map((template) => {
    const { value, text, shiftIndustryType } = template;
    return { value, text: `${text} (${shiftIndustryType})` };
  });

  return (
    <Field.InCol
      columns={9}
      name={`ShiftConfigId_${id}`}
      noLabel
      options={templates}
      isSelect
      required
    />
  );
};

const ShiftQtyField = ({ id }: { id: number }) => {
  return (
    <Field.InCol
      columns={3}
      name={`ShiftQty_${id}`}
      noLabel
      type='number'
      required
      min={1}
      max={10}
    />
  );
};

const ModalSaveButton = ({
  isSaving,
  isSaved,
  onClick,
}: {
  isSaving: boolean;
  isSaved: boolean;
  onClick: () => void;
}) => {
  const isDisabled = isSaving || isSaved;
  const buttonTitle = isSaving
    ? 'Adding...'
    : isSaved
    ? 'Added!'
    : 'Add Shifts to Plan';
  return (
    <Button bsStyle='success' onClick={onClick} disabled={isDisabled}>
      <Icon icon='fa-check' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};
