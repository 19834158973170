import { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import $ from 'jquery';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import { approveAgencyInvite } from 'utils/api';
import { HirerUtils } from '../HirerUtils';

export class ApproveButton extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, isSaving: false };
    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  handleShowModalClick() {
    this.setState({ showModal: true });
  }

  handleCloseModalClick() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <>
        <Button
          bsStyle='success'
          bsSize='sm'
          className='m-l-sm'
          onClick={this.handleShowModalClick}
        >
          <Icon icon='fa-thumbs-up' /> Accept
        </Button>
        <ApproveAgencyInviteModal
          {...this.state}
          {...this.props}
          handleCloseModalClick={this.handleCloseModalClick}
        />
      </>
    );
  }
}

class ApproveAgencyInviteModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();

    this.onExited = this.onExited.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  defaultState() {
    return {
      isSaving: false,
      isSaved: false,
    };
  }

  onExited() {
    this.setState(this.defaultState());
  }

  async handleSubmitClick() {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);
    model['HirerUserInviteId'] = this.props.record.hirerUserInviteId;

    this.setState({ isSaving: true });
    AppUtils.clearNotifications();

    const data = await approveAgencyInvite(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Invite Approved');
      this.setState({ isSaved: true });
      setTimeout(() => this.props.handleCloseModalClick(), 500);
      AppUtils.publish(HirerUtils.Events.AgencyInviteAccepted, data);
    });
    this.setState({ isSaving: false });

    // $.ajax({
    //   type: "POST",
    //   url: AppSettings.baseUrl + "Hirer/AgencyInviteApprove",
    //   data: JSON.stringify(model),
    // })
    //   .done(function (data) {
    //     AppUtils.handleAjaxDone(data, () => {
    //       AppUtils.displaySuccess("Done", "Invite Approved");
    //       me.setState({ isSaved: true });
    //       setTimeout(() => me.props.handleCloseModalClick(), 500);
    //       AppUtils.publish(HirerUtils.Events.AgencyInviteAccepted, data);
    //     });
    //   })
    //   .fail(function (xhr) {
    //     AppUtils.handleXhrFail(xhr);
    //   })
    //   .always(function () {
    //     me.setState({ isSaving: false });
    //   });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.showModal}
        onHide={this.props.handleCloseModalClick}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Accept Invite</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.state} {...this.props} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.handleCloseModalClick} bsSize='sm'>
            <i className='fa fa-times' /> Close
          </Button>
          <ModalSaveButton
            {...this.props}
            {...this.state}
            onClick={() => this.handleSubmitClick()}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = (props) => {
  return (
    <>
      <WellSmall className='bg-success'>
        <p className='m-b-none'>
          You have chosen to accept this invite from{' '}
          <strong>{props.record.createdBy}</strong> to become a customer of{' '}
          <strong>{props.record.companyName}</strong>.
        </p>
      </WellSmall>
      <WellSmall className='m-b-none'>
        Are you sure you would like to proceed?
      </WellSmall>
    </>
  );
};

const ModalSaveButton = (props) => {
  const isDisabled = props.isSaving || props.isSaved;
  const buttonTitle = props.isSaving
    ? 'Accepting...'
    : props.isSaved
    ? 'Accepted'
    : 'Yes, Accept Invite';
  return (
    <Button
      bsStyle='success'
      bsSize='sm'
      onClick={props.onClick}
      disabled={isDisabled}
    >
      <Icon icon='fa-thumbs-up' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
