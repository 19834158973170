import { Col, FormGroup } from 'react-bootstrap';
import 'pretty-checkbox/dist/pretty-checkbox.min.css';
import { AppUtils } from './app-utils';

export const RadioButton = (props) => {
  const checkbox = <RadioButtonOutput {...props} />;
  if (props.columns) {
    return <Col md={props.columns}>{checkbox}</Col>;
  }
  return checkbox;
};

const RadioButtonOutput = (props) => {
  const options = props.options.map((option) => {
    const value = AppUtils.isObject(option) ? option.value : option;
    const text = AppUtils.isObject(option) ? option.text : option;
    return { value, text };
  });

  let ret = (
    <>
      {props.label ? (
        <label className='control-label' htmlFor={props.name}>
          {props.label}
        </label>
      ) : null}
      <div>
        {options.map((option, i) => (
          <PrettyRadioField
            key={i}
            name={props.name}
            checked={props.value === option.value}
            value={option.value}
            text={option.text}
            onChange={props.onChange}
          />
        ))}
      </div>
    </>
  );

  if (props.noFormGroup) {
    ret = <div>{ret}</div>;
  } else {
    ret = <FormGroup>{ret}</FormGroup>;
  }

  return ret;
};

const PrettyRadioField = (props) => {
  return (
    <div style={{ float: 'left' }}>
      <div className='pretty p-svg p-smooth p-bigger p-round'>
        <RadioField {...props} />
        <div className='state p-primary'>
          <i className='fas fa-check svg'></i>
          <label className=''>{props.text}</label>
        </div>
      </div>
    </div>
  );
};

const RadioField = ({ name, checked, value, onChange }) => {
  if (onChange) {
    return (
      <input
        type='radio'
        name={name}
        checked={checked}
        onChange={onChange}
        value={value}
      />
    );
  }

  return (
    <input type='radio' name={name} defaultChecked={checked} value={value} />
  );
};
