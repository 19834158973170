import { ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';

function FormField(props) {
  const { error, type, inputRef, ...inputProps } = props;

  return (
    <>
      {props.label && <ControlLabel>{props.label}</ControlLabel>}

      <FormControl
        componentClass={type === 'textarea' ? 'textarea' : 'input'}
        type={type === 'textarea' ? undefined : type}
        inputRef={inputRef}
        {...inputProps}
      />

      {error && error.message && <HelpBlock>{error.message}</HelpBlock>}
    </>
  );
}

export default FormField;
