import { Component } from 'react';
import { Alert, Button, Col, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import { AppUtils } from 'pages/Utils/app-utils';
import {
  Field,
  Icon,
  WellSmall,
  SidebarData,
  SmallMuted,
} from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { getHirerEditConfig, getHirerProfile, saveHirer } from 'utils/api';
import { ContentWithSidebarWrapper, Sidebar } from 'components/Sidebar';
import { HirerUtils } from '../../HirerUtils';
import { AddUserButton } from './Buttons/AddHirerUserButton';
import HirerUserTable from './HirerUsersTable';

class HirerEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isGettingRecord: false,
      record: null,
      isGettingConfig: false,
      configuration: null,
      activeTab: 1,
      isSaving: false,
      errors: [],
    };

    this.subscriber = this.subscriber.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  componentDidMount() {
    this.getRecord();
    this.getConfig();

    const _me = this;
    this.subscribeToEvents().forEach((e) => {
      PubSub.subscribe(e, _me.subscriber);
    });
  }

  subscribeToEvents() {
    return [HirerUtils.Events.ProfileSaved];
  }

  subscriber(event, data) {
    console.log(`subscriber: ${event}`);
    switch (event) {
      case HirerUtils.Events.ProfileSaved:
        this.setState({
          record: data.record,
        });
        return;

      default:
        break;
    }
  }

  async getRecord() {
    this.setState({ isGettingRecord: true, errors: [] });

    const data = await getHirerProfile();
    AppUtils.handleAjaxDone(data, () => {
      if (data.record) {
        this.setState({ record: data.record });
      } else {
        this.setState({ errors: ['Could not get record'] });
      }
    });
    this.setState({ isGettingRecord: false });
  }

  async getConfig() {
    this.setState({ isGettingConfig: true });

    const data = await getHirerEditConfig();
    AppUtils.handleAjaxDone(data, () => {
      if (data.configuration) {
        this.setState({ configuration: data.configuration });
      } else {
        throw new Error('Could not get configuration');
      }
    });
    this.setState({ isGettingConfig: false });
  }

  handleChange(e) {
    console.log(`HirerEdit.handleChange: ${e.target.name}: ${e.target.value}`);
    const { record } = this.state;
    console.log(e.target.name.toCamelCase());
    record[e.target.name.toCamelCase()] = e.target.value;
    this.setState({ record });
  }

  setIsSaving(val) {
    this.setState({ isSaving: val });
  }

  async save() {
    const _me = this;

    let model = AppUtils.getDataModel();
    model['HirerId'] = _me.state.record.hirerId;

    _me.setIsSaving(true);

    const data = await saveHirer(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Success', 'Details saved');
      PubSub.publish(HirerUtils.Events.ProfileSaved, data);
    });
    setTimeout(() => _me.setIsSaving(false), 500);
  }

  handleTabSelect(tabToMakeActive) {
    console.log(`Edit.handleTabSelect: ${tabToMakeActive}`);
    this.setState({ activeTab: tabToMakeActive });
  }

  render() {
    if (this.state.errors.length > 0) {
      return <Errors {...this.state} />;
    }
    if (this.state.isGettingConfig) {
      return <GettingConfigMsg {...this.state} />;
    }
    if (this.state.isGettingRecord) {
      return <GettingRecordMsg {...this.state} />;
    }
    if (!this.state.configuration) {
      return null;
    }
    if (!this.state.record) {
      return null;
    }
    return (
      <>
        <Sidebar>
          <SidebarContent {...this.state} handleSaveClick={this.save} />
        </Sidebar>
        <ContentWithSidebarWrapper>
          <EditPanel
            {...this.state}
            handleChange={this.handleChange}
            handleTabSelect={this.handleTabSelect}
          />
        </ContentWithSidebarWrapper>
      </>
    );
  }
}

const Errors = ({ errors }) => {
  if (errors.length <= 0) {
    return null;
  }
  return (
    <Alert bsStyle='danger'>
      The following errors occurred:
      <ul>
        {errors.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>
    </Alert>
  );
};

const SidebarContent = (props) => {
  const { record } = props;
  return (
    <>
      <div className='btn-container'>
        <SaveButton {...props} handleSaveClick={props.handleSaveClick} />
      </div>
      <SidebarData
        label='Created'
        value={AppUtils.formatDateTimeUtc(record.createdDateTime)}
      />
      <SidebarData label='Created By' value={record.createdBy} />
    </>
  );
};

const EditPanel = (props) => {
  const nextButton = null;
  let eventKey = 1;
  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col md={12}>
            <SmallMuted>Profile for</SmallMuted>
            <h3 className='m-b-none'>{props.record.companyName}</h3>
          </Col>
        </Row>
      </Panel.Heading>
      <Panel.Body className='tabs-container' style={{ padding: '10px' }}>
        <Tabs
          activeKey={props.activeTab}
          onSelect={props.handleTabSelect}
          id='hirer-edit-tabs'
          animation={false}
        >
          <Tab eventKey={eventKey++} title='Customer Details'>
            <CustomerDetailsTab {...props} nextButton={nextButton} />
          </Tab>
          <Tab eventKey={eventKey++} title='Users'>
            <UsersTab {...props} nextButton={nextButton} />
          </Tab>
        </Tabs>
      </Panel.Body>
    </Panel>
  );
};

const GettingConfigMsg = ({ isGettingConfig }) => {
  if (!isGettingConfig) {
    return null;
  }
  return (
    <WellSmall hasSpinner>
      Please wait while the profile is configured...
    </WellSmall>
  );
};

const GettingRecordMsg = ({ isGettingRecord }) => {
  if (!isGettingRecord) {
    return null;
  }
  return (
    <WellSmall hasSpinner>
      Please wait while the profile is retrieved...
    </WellSmall>
  );
};

const CustomerDetailsTab = ({ record, nextButton }) => {
  console.log('CustomerDetailsTab.render');
  return (
    <>
      <Row>
        <Field.InCol
          columns={6}
          name='CompanyName'
          label='Company Name'
          value={record.companyName}
          maxLength='100'
        />
      </Row>
      <Row>
        <Field.InCol
          columns={4}
          name='CompanyNumber'
          label='Company Number'
          value={record.companyNumber}
          maxLength='30'
        />
        <Field.InCol
          columns={8}
          name='CompanyEmailAddress'
          label='Company Email'
          value={record.companyEmailAddress}
          maxLength='256'
        />
      </Row>
      <hr />
      <Row>
        <Field.InCol
          columns={4}
          name='MainContactName'
          label='Main Contact Name'
          value={record.mainContactName}
          maxLength='100'
        />
        <Field.InCol
          columns={8}
          name='MainContactEmailAddress'
          label='Main Contact Email'
          value={record.mainContactEmailAddress}
          maxLength='256'
        />
      </Row>
      <hr />
      <Row>
        <Field.InCol
          columns={4}
          name='AddressLine1'
          label='Address Line 1'
          value={record.addressLine1}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='AddressLine2'
          label='Address Line 2'
          value={record.addressLine2}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='AddressLine3'
          label='Address Line 3'
          value={record.addressLine3}
          maxLength='100'
        />
      </Row>
      <Row>
        <Field.InCol
          columns={4}
          name='Town'
          label='Town/City'
          value={record.town}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='County'
          value={record.county}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='Postcode'
          value={record.postcode}
          maxLength='10'
        />
      </Row>
      <Row>
        <Field.InCol
          columns={4}
          name='Region'
          value={record.region}
          maxLength='100'
        />
      </Row>

      {nextButton}
    </>
  );
};

const UsersTab = ({ nextButton }) => {
  console.log('UsersTab.render');
  return (
    <>
      <WellSmall>
        <AddUserButton />
      </WellSmall>
      <HirerUserTable />

      {nextButton}
    </>
  );
};

const SaveButton = (props) => {
  const text = props.isSaving ? 'Saving' : 'Save';
  return (
    <Button
      bsStyle='info'
      bsSize='sm'
      block
      onClick={props.handleSaveClick}
      disabled={props.isSaving}
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {text}
    </Button>
  );
};

export default HirerEdit;
