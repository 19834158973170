import { ReactNode, useEffect } from 'react';
import { AppUtils } from 'pages/Utils/app-utils';

interface ISidebarProps {
  children?: React.ReactNode;
}

export const Sidebar = (props: ISidebarProps) => {
  return (
    <div className='sidebar-panel' style={{ minHeight: '100%' }}>
      {props.children}
    </div>
  );
};

export const ContentWithSidebarWrapper = (props: { children: ReactNode }) => {
  useEffect(() => {
    AppUtils.sidebarPrep();
  }, []);
  return <div className='wrapper'>{props.children}</div>;
};
