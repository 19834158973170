import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useVehicleOptions } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { useAssignVehicleToPlanItemMutation } from 'hooks/queries/shiftPlanningQueries';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall } from 'pages/Utils/react-utils';
import { AssignVehicleToPlanItemParams } from 'types/ShiftPlanningTypes';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';

export const AssignVehicleToPlanItemModal = ({
  isModalOpen,
  handleModalClose,
  shiftPlanItemId,
  existingVehicleId,
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
  shiftPlanItemId: string;
  existingVehicleId?: string;
}) => {
  const assignVehicleToPlanItemMutation = useAssignVehicleToPlanItemMutation();
  const queryClient = useQueryClient();

  const handleSubmitClick = () => {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    const params = AppUtils.getDataModel(
      $modal
    ) as AssignVehicleToPlanItemParams;

    assignVehicleToPlanItemMutation.mutate(
      {
        shiftPlanItemId,
        params,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ShiftPlanForHirer],
          });
          AppUtils.displaySuccess('Success', 'Vehicle assigned successfully');
          handleModalClose();
        },
      }
    );
  };

  const { query, vehicleOptions } = useVehicleOptions();

  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Vehicle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {query.isLoading ? (
          <Row>
            <Col xs={12}>
              <WellSmall hasSpinner />
            </Col>
          </Row>
        ) : null}

        {query.isError ? (
          <Row>
            <Col xs={12}>
              <ErrorAlert msg={extractErrorMessages(query.error)} />
            </Col>
          </Row>
        ) : null}

        {query.isSuccess ? (
          <>
            <Row>
              <Field.InCol
                columns={12}
                name={`VehicleId`}
                label={'Vehicle'}
                options={vehicleOptions}
                defaultValue={existingVehicleId}
                value={existingVehicleId}
                isSelect
                required
              />
            </Row>
          </>
        ) : null}

        {assignVehicleToPlanItemMutation.isError ? (
          <Row>
            <Col xs={12}>
              <ErrorAlert
                msg={extractErrorMessages(
                  assignVehicleToPlanItemMutation.error
                )}
              />
            </Col>
          </Row>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>
          <i className='fa fa-times' /> Close
        </Button>
        <ModalSaveButton
          isSaved={assignVehicleToPlanItemMutation.isSuccess}
          isSaving={assignVehicleToPlanItemMutation.isLoading}
          onClick={handleSubmitClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

const ModalSaveButton = ({
  isSaving,
  isSaved,
  onClick,
}: {
  isSaving: boolean;
  isSaved: boolean;
  onClick: () => void;
}) => {
  const isDisabled = isSaving || isSaved;
  const buttonTitle = isSaving
    ? 'Assigning...'
    : isSaved
    ? 'Assigned!'
    : `Assign Vehicle to Plan`;
  return (
    <Button bsStyle='success' onClick={onClick} disabled={isDisabled}>
      <Icon icon='fa-check' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};
