import { useState } from 'react';
import moment from 'moment';

export const useReportDates = () => {
  const [selectedStartDate, setStartDate] = useState<Date>(
    moment().subtract(6, 'days').set({ hour: 12 }).toDate()
  );

  const [selectedEndDate, setEndDate] = useState<Date>(
    moment().set({ hour: 12 }).toDate()
  );

  const handleSetDate = (type: 'start' | 'end', date: Date) => {
    const dateToSet = moment(date).set({ hour: 12 }).toDate();
    if (type === 'start') {
      setStartDate(dateToSet);
    } else {
      setEndDate(dateToSet);
    }
  };

  return {
    selectedStartDate,
    selectedEndDate,
    handleSetDate,
  };
};
