import { useState } from 'react';
import { Button, Col, Panel, Row } from 'react-bootstrap';
import { useShiftContext } from 'pages/Shift/ShiftContext';
import { Icon } from 'pages/Utils/react-utils';
import {
  PaymentAdjustmentForm,
  PaymentAdjustmentsTable,
} from 'components/PaymentAdjustments';

export const PaymentAdjustments = ({
  areNewAdjustmentsAllowed = true,
}: {
  areNewAdjustmentsAllowed?: boolean;
}) => {
  const [isFormShowing, setIsFormShowing] = useState(false);
  const { shiftId } = useShiftContext();

  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col sm={8}>
            <Icon icon='fa-credit-card' /> Payment Adjustments
          </Col>
          {areNewAdjustmentsAllowed ? (
            <Col sm={4} style={{ textAlign: 'right' }}>
              {!isFormShowing ? (
                <Button
                  bsStyle='primary'
                  bsSize='xs'
                  onClick={() => setIsFormShowing(true)}
                >
                  <Icon icon='fa-plus' /> Add
                </Button>
              ) : null}
            </Col>
          ) : null}
        </Row>
      </Panel.Heading>
      <Panel.Body>
        {areNewAdjustmentsAllowed ? (
          <PaymentAdjustmentForm
            shiftId={shiftId}
            isFormShowing={isFormShowing}
            closeForm={() => setIsFormShowing(false)}
          />
        ) : null}
        <PaymentAdjustmentsTable shiftId={shiftId} />
      </Panel.Body>
    </Panel>
  );
};
