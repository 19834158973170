import { ReactNode, useState } from 'react';
import { Alert, Panel, Row, Col, Button } from 'react-bootstrap';
import {
  CellProps,
  Column,
  HeaderProps,
  UseSortByColumnOptions,
} from 'react-table';
import { useQueryClient } from '@tanstack/react-query';
import { FormLabel } from 'components';
import { useSelectedCustomer } from 'hooks';
import { useGetPaidShiftsQuery } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { useListViewCheckbox } from 'hooks/useListViewCheckbox';
import useSubscription from 'hooks/useSubscription';
import moment from 'moment';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { Checkbox } from 'pages/Utils/Checkbox';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, SmallInfo } from 'pages/Utils/react-utils';
import { ICustomEvent } from 'types/GeneralTypes';
import { PaidShift } from 'types/ShiftTypes';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { Table } from 'components/Table';
import { DownloadCsvButton } from './DownloadSelectedButton';

type Shift = PaidShift;

export const PaidListView = ({
  setDisplayShiftId,
}: {
  setDisplayShiftId: (shiftId: string) => void;
}) => {
  const { selectedCustomerId, renderCustomerSelect } = useSelectedCustomer();

  const [filterState, setFilterState] = useState({
    fromDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  });

  const handleFilterChange = (e: ICustomEvent) => {
    setFilterState((currentFilterData) => {
      return {
        ...currentFilterData,
        [AppUtils.toCamelCase(e.target.name)]: e.target.value,
      };
    });
  };

  const { isFetching, isSuccess, isError, error, data, refetch } =
    useGetPaidShiftsQuery(
      { ...filterState, hirerId: selectedCustomerId },
      {
        onSuccess: (data) => {
          handleNewRecords(data);
        },
      }
    );

  const queryClient = useQueryClient();
  useSubscription([ShiftUtils.Events.ShiftsPaid], () => {
    queryClient.invalidateQueries([QueryKeys.PaidShifts]);
    resetSelectedRecords();
  });

  const handleSearchClick = () => {
    refetch();
  };

  const records = data ?? [];

  const {
    resetSelectedRecords,
    recordIdsSelected,
    handleCheckboxChange,
    handleNewRecords,
    areAllSelected,
  } = useListViewCheckbox({
    records,
  });

  return (
    <>
      <Alert>Shifts which have been marked as paid.</Alert>

      <Filters
        filterState={filterState}
        handleSearchClick={handleSearchClick}
        handleFilterChange={handleFilterChange}
        isFetching={isFetching}
        renderCustomerSelect={renderCustomerSelect}
      />

      <ErrorAlertForQueryOrNull isError={isError} error={error} />

      {isSuccess && records.length <= 0 ? (
        <Alert>No shifts found. Update the filters above and try again.</Alert>
      ) : null}

      {records.length > 0 ? (
        <Panel>
          <Panel.Heading>
            <Row>
              <Col sm={4}>Paid Shifts</Col>
              <Col sm={8} className='text-right'>
                <DownloadSelectedButtonContainer
                  records={records}
                  recordIdsSelected={recordIdsSelected}
                />
              </Col>
            </Row>
          </Panel.Heading>
          <Panel.Body>
            <TableContainer
              records={records}
              recordIdsSelected={recordIdsSelected}
              handleCheckboxChange={handleCheckboxChange}
              setDisplayShiftId={setDisplayShiftId}
              areAllSelected={areAllSelected()}
            />
          </Panel.Body>
        </Panel>
      ) : null}
    </>
  );
};

type FilterState = {
  fromDate: string;
  toDate: string;
};

const Filters = ({
  isFetching,
  handleSearchClick,
  filterState,
  handleFilterChange,
  renderCustomerSelect,
}: {
  isFetching: boolean;
  filterState: FilterState;
  handleFilterChange: (e: ICustomEvent) => void;
  handleSearchClick: () => void;
  renderCustomerSelect: () => ReactNode;
}) => {
  const { fromDate, toDate } = filterState;
  return (
    <Panel id='ApprovedFilterContainer'>
      <Panel.Body>
        <Row>
          <DatePicker
            columns={4}
            name='fromDate'
            label='Date From'
            value={AppUtils.getDateString(moment(fromDate, 'YYYY-MM-DD'))}
            changeDate={(e: { date: Date }) => {
              handleFilterChange({
                target: {
                  name: 'fromDate',
                  value: moment(e.date).format('YYYY-MM-DD'),
                },
              });
            }}
            required
            notInFormGroup
          />
          <DatePicker
            columns={4}
            name='toDate'
            label='Date To'
            value={AppUtils.getDateString(moment(toDate, 'YYYY-MM-DD'))}
            changeDate={(e: { date: Date }) => {
              handleFilterChange({
                target: {
                  name: 'toDate',
                  value: moment(e.date).format('YYYY-MM-DD'),
                },
              });
            }}
            required
            notInFormGroup
          />
          <Col sm={4}>
            <FormLabel name='HirerId' label='Customer' />
            {renderCustomerSelect()}
          </Col>
        </Row>
      </Panel.Body>
      <Panel.Footer className='text-right'>
        <SearchButton
          isGetting={isFetching}
          handleSearchClick={handleSearchClick}
        />
      </Panel.Footer>
    </Panel>
  );
};

const SearchButton = ({
  isGetting,
  handleSearchClick,
}: {
  isGetting: boolean;
  handleSearchClick: () => void;
}) => {
  return (
    <Button
      bsStyle='primary'
      bsSize='sm'
      onClick={handleSearchClick}
      disabled={isGetting}
    >
      <Icon icon='fa-search' isSpinning={isGetting} />{' '}
      {isGetting ? 'Searching' : 'Search'}
    </Button>
  );
};

const DownloadSelectedButtonContainer = (props: {
  records: Shift[];
  recordIdsSelected: string[];
}) => {
  // TODO: Implement this
  return null;

  if (props.records.length <= 0) {
    return null;
  }
  return <DownloadCsvButton {...props} />;
};

const TableContainer = ({
  handleCheckboxChange,
  recordIdsSelected,
  records,
  setDisplayShiftId,
  areAllSelected,
}: {
  handleCheckboxChange: (id: string) => void;
  recordIdsSelected: string[];
  records: Shift[];
  setDisplayShiftId: (shiftId: string) => void;
  areAllSelected: boolean;
}) => {
  const isRecordSelected = (id: string) => recordIdsSelected.indexOf(id) > -1;

  const columns: (Column<Shift> & UseSortByColumnOptions<Shift>)[] = [
    {
      accessor: 'shiftId',
      disableSortBy: true,
      width: '20px',
      Header: (headerProps: HeaderProps<Shift>) => {
        if (headerProps.data.every((shift) => shift.paymentAmount === null)) {
          return null;
        } else {
          return (
            <Checkbox
              id={`checkbox_all`}
              checked={areAllSelected}
              onChange={() => handleCheckboxChange('all')}
            />
          );
        }
      },
      Cell: (cellProps: CellProps<Shift>) => {
        const record = cellProps.row.original;
        return (
          <Checkbox
            disabled={record.paymentAmount === null}
            id={`checkbox_${record.shiftId}`}
            checked={isRecordSelected(record.shiftId)}
            onChange={() => handleCheckboxChange(record.shiftId)}
          />
        );
      },
    },
    {
      Header: 'Shift',
      accessor: 'name',
      Cell: (cellProps: CellProps<Shift>) => (
        <>
          {cellProps.row.original.name}
          <div>
            <SmallInfo>{cellProps.row.original.companyName}</SmallInfo>
          </div>
        </>
      ),
    },
    {
      Header: 'When',
      accessor: (record: Shift) =>
        AppUtils.concatDateAndTime(record.shiftDate, record.shiftTime),
      Cell: ({ value }: { value: string }) =>
        AppUtils.formatDateTimeNonUtc(value),
    },
    {
      Header: 'Worker',
      accessor: (record: Shift) => record.workerSurname,
      Cell: (cellProps: CellProps<Shift>) => (
        <>
          {cellProps.row.original.workerFirstName}{' '}
          {cellProps.row.original.workerSurname}
        </>
      ),
    },
    {
      Header: 'Amount £',
      accessor: (record: Shift) => record.paymentAmount,
      Cell: ({ value }: { value: string }) => (
        <span>{AppUtils.formatNumber(value)}</span>
      ),
    },
    {
      Header: 'Paid',
      accessor: (record: Shift) => record.paidDateTime,
      Cell: (cellProps: CellProps<Shift>) => (
        <>
          {AppUtils.formatDateTimeUtc(cellProps.row.original.paidDateTime)}
          <br />
          <SmallInfo>{cellProps.row.original.paidBy}</SmallInfo>
        </>
      ),
    },
    {
      Header: 'Payment Ref',
      accessor: (record: Shift) => record.paymentReference,
    },
    {
      id: 'open',
      accessor: (record: Shift) => record.shiftId,
      disableSortBy: true,
      Cell: (cellProps: CellProps<Shift>) => {
        const shift = cellProps.row.original;
        return (
          <>
            <Button
              className='pull-right'
              bsStyle='primary'
              bsSize='xs'
              onClick={() => setDisplayShiftId(shift.shiftId)}
            >
              Open &raquo;
            </Button>
          </>
        );
      },
    },
  ];

  return <Table columns={columns} records={records} />;
};
