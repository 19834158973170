import { useState } from 'react';
import { Alert, Button, Col, Panel, Row } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import useFilterData from 'hooks/useFilterData';
import useGetConfig from 'hooks/useGetConfig';
import { useListViewCheckbox } from 'hooks/useListViewCheckbox';
import useSubscription from 'hooks/useSubscription';
import moment from 'moment';
import { ShiftUtils, StartToEndCell } from 'pages/Shift/ShiftUtils';
import { Checkbox } from 'pages/Utils/Checkbox';
import { DatePicker } from 'pages/Utils/DatePicker';
import { TimeDropdown } from 'pages/Utils/TimeDropdown';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, MapToDiv, WellSmall, Icon } from 'pages/Utils/react-utils';
import { getCompletedListViewConfig, getCompletedShifts } from 'utils/api';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { ApproveSelectedButton } from '../../Hirer/Shift/ApproveSelectedButton';
import { EditTimeModal } from '../../Shift/EditTimeModal';

const _filterContainerId = 'ApprovedFiltersContainer';
const _filterFieldPrefix = 'approvedFilter';

export const CompletedShiftsListView = (props) => {
  const [recordToBeEdited, setRecordToBeEdited] = useState(null);

  const { isGettingConfig, configuration } = useGetConfig(
    getCompletedListViewConfig
  );

  // const canApproveTimeForHirerIds =
  //   configuration?.hirers
  //     .filter((hirer) => hirer.canAgencyApproveWorkerTime)
  //     .map((hirer) => hirer.hirerId) ?? [];

  const recordMapper = (record) => {
    return {
      ...record,
      // canApprove: canApproveTimeForHirerIds.indexOf(record.hirerId) > -1,
      canApprove: true,
    };
  };

  const { filterData, getDataModel, handleFilterChange } =
    useFilterData(_filterFieldPrefix);

  const { isLoading, isError, error, data, refetch } = useQuery({
    queryKey: ['getCompletedShifts', getDataModel()],
    queryFn: () => getCompletedShifts(getDataModel()),
    onSuccess: (data) => {
      handleNewRecords(data.records.map(recordMapper));
    },
  });

  const getrecords = data?.records || [];

  const records = getrecords.map(recordMapper);

  const approvableRecords = records.filter((record) => record.canApprove);

  useSubscription(
    [
      ShiftUtils.Events.ShiftCreated,
      ShiftUtils.Events.TimesUpdated,
      ShiftUtils.Events.ShiftsApproved,
      ShiftUtils.Events.ShiftsDisapproved,
    ],
    () => {
      refetch();
      resetSelectedRecords();
    }
  );

  const getApprovableRecordById = (id) => {
    return approvableRecords.find((record) => record.shiftId === id);
  };

  const {
    resetSelectedRecords,
    recordIdsSelected,
    handleCheckboxChange,
    handleNewRecords,
  } = useListViewCheckbox({
    records,
    canRecordBeChecked: (record) => record.canApprove,
  });

  const handleEditClick = (shiftId) => {
    const record = getApprovableRecordById(shiftId);
    if (!record) {
      AppUtils.displayError(
        'Error',
        `Could not find Shift to edit with id: ${shiftId}`
      );
      return;
    }

    setRecordToBeEdited(record);
  };

  const handleSetRecordToBeEdited = (record) => {
    setRecordToBeEdited(record);
  };

  const clearRecordToBeEdited = () => {
    setRecordToBeEdited(null);
  };

  if (isGettingConfig) {
    return <GettingConfigMsg isGettingConfig={isGettingConfig} />;
  }

  if (!configuration) {
    return null;
  }

  if (error) {
    return <ErrorAlertForQueryOrNull isError={isError} error={error} />;
  }

  return (
    <>
      <Filters
        configuration={configuration}
        filterData={filterData}
        handleSearchClick={refetch}
        handleFilterChange={handleFilterChange}
        isLoading={isLoading}
      />
      <TableContainer
        {...props}
        getData={refetch}
        handleCheckboxChange={handleCheckboxChange}
        handleEditClick={handleEditClick}
        handleSetRecordToBeEdited={handleSetRecordToBeEdited}
        clearRecordToBeEdited={clearRecordToBeEdited}
        approvableRecords={approvableRecords}
        recordToBeEdited={recordToBeEdited}
        recordIdsSelected={recordIdsSelected}
        records={records}
      />
      <EditTimeModal
        record={recordToBeEdited}
        handleCloseModalClick={clearRecordToBeEdited}
      />
    </>
  );
};

const Filters = (props) => {
  const {
    handleSearchClick,
    isLoading,
    configuration,
    filterData,
    handleFilterChange,
  } = props;

  const handleChangeDate = (name, date) => {
    handleFilterChange({
      target: { name, value: moment(date).format('YYYY-MM-DD') },
    });
  };

  const hirerOptions = configuration.hirers.map((hirer) => ({
    value: hirer.hirerId,
    text: hirer.companyName,
  }));

  return (
    <Panel id={_filterContainerId}>
      <Panel.Body>
        <Row>
          <Col sm={4}>
            <DatePicker
              name={`${_filterFieldPrefix}FromDate`}
              label='From Date'
              value={AppUtils.getDateString(
                filterData[`${_filterFieldPrefix}FromDate`]
              )}
              changeDate={(e) =>
                handleChangeDate(`${_filterFieldPrefix}FromDate`, e.date)
              }
            />
            <TimeDropdown
              name={`${_filterFieldPrefix}FromTime`}
              label='From Time'
              placeholder='From Time'
              value={filterData[`${_filterFieldPrefix}FromTime`]}
              onChange={handleFilterChange}
            />
          </Col>

          <Col sm={4}>
            <DatePicker
              name={`${_filterFieldPrefix}ToDate`}
              label='To Date'
              value={AppUtils.getDateString(
                filterData[`${_filterFieldPrefix}ToDate`]
              )}
              changeDate={(e) =>
                handleChangeDate(`${_filterFieldPrefix}ToDate`, e.date)
              }
            />
            <TimeDropdown
              name={`${_filterFieldPrefix}ToTime`}
              label='To Time'
              placeholder='To Time'
              value={filterData[`${_filterFieldPrefix}ToTime`]}
              onChange={handleFilterChange}
            />
          </Col>

          <Col sm={4}>
            <Field.Main
              name={`${_filterFieldPrefix}HirerId`}
              label='Customer'
              value={filterData[`${_filterFieldPrefix}HirerId`]}
              options={hirerOptions}
              isSelect
              onChange={handleFilterChange}
            />
          </Col>
        </Row>
      </Panel.Body>
      <Panel.Footer className='text-right'>
        <Button
          className='m-b-none'
          bsStyle='primary'
          bsSize='sm'
          onClick={handleSearchClick}
          disabled={isLoading}
        >
          <Icon icon='fa-search' isSpinning={isLoading} />{' '}
          {isLoading ? 'Searching' : 'Search'}
        </Button>
      </Panel.Footer>
    </Panel>
  );
};

const GettingConfigMsg = ({ isGettingConfig }) => {
  if (!isGettingConfig) {
    return null;
  }
  return (
    <WellSmall hasSpinner>
      Please wait while this section is configured...
    </WellSmall>
  );
};

const ButtonContainer = (props) => {
  if (props.records.length <= 0) {
    return null;
  }
  return (
    <WellSmall className='text-right'>
      <RefreshButton {...props} />
      {props.approvableRecords.length ? (
        <ApproveSelectedButton {...props} />
      ) : null}
    </WellSmall>
  );
};

const RefreshButton = ({
  getData,
  isGetting,
  buttonText,
  className,
  bsSize,
}) => {
  return (
    <Button
      bsStyle='primary'
      bsSize={bsSize || 'sm'}
      onClick={() => {
        AppUtils.destroyTooltips();
        getData();
      }}
      disabled={isGetting}
      title='Refresh'
      data-toggle={!buttonText ? 'tooltip' : null}
      className={['m-r-xs', className].join(' ')}
    >
      <Icon icon='fa fa-redo' /> {buttonText ? buttonText : null}
    </Button>
  );
};

const TableContainer = (props) => {
  return (
    <>
      <Alert bsStyle='warning'>
        <Icon icon='fa-clock' /> Shifts which have been Completed but have not
        yet been Approved.
      </Alert>
      <hr />
      <ButtonContainer {...props} />
      <Table {...props} />
    </>
  );
};

const tableId = 'completed-shifts-table';
const Table = ({
  approvableRecords,
  recordIdsSelected,
  records,
  setDisplayShiftId,
  handleCheckboxChange,
  handleEditClick,
}) => {
  const isRecordSelected = (id) => recordIdsSelected.indexOf(id) > -1;

  const areAllSelected =
    approvableRecords.length > 0 &&
    approvableRecords.length === recordIdsSelected.length;

  return (
    <table
      className='table table-condensed table-striped table-hover'
      id={tableId}
      data-order='[[ 2, "asc" ]]'
    >
      <thead>
        <tr>
          <th>
            <Checkbox
              id={`checkbox_all`}
              checked={areAllSelected}
              onChange={() => handleCheckboxChange('all')}
            />
          </th>
          <th>Details</th>
          <th>When</th>
          <th>Worker</th>
          <th />
          <th>
            Entered
            <i
              className='fa fa-info-circle m-l text-primary'
              data-toggle='tooltip'
              title='These are the times entered by the worker'
            />
          </th>
          <th>Edited</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {records.map((item, i) => (
          <TableRow
            key={i}
            record={item}
            setDisplayShiftId={setDisplayShiftId}
            isSelected={isRecordSelected(item.shiftId)}
            handleCheckboxChange={handleCheckboxChange}
            handleEditClick={handleEditClick}
          />
        ))}
      </tbody>
    </table>
  );
};

const TableRow = ({
  record,
  setDisplayShiftId,
  isSelected,
  handleCheckboxChange,
  handleEditClick,
}) => {
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  return (
    <tr className={isSelected ? `selected` : ''}>
      <td>
        {record.canApprove && (
          <Checkbox
            id={`checkbox_${record.shiftId}`}
            checked={isSelected}
            onChange={() => handleCheckboxChange(record.shiftId)}
          />
        )}
      </td>
      <td>
        <MapToDiv>{[record.companyName, record.name]}</MapToDiv>
      </td>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime)}
      </td>
      <td
        data-order={record.workerSurname}
      >{`${record.workerFirstName} ${record.workerSurname}`}</td>
      <td>
        {record.isLinked ? (
          <Icon
            icon='fa-link'
            tooltip={
              record.linkedShiftRequiresChargeUpdate
                ? 'All linked shifts require a Charge amount before approving'
                : 'This shift is linked to another shift'
            }
            colour={record.linkedShiftRequiresChargeUpdate ? 'red' : 'green'}
          />
        ) : null}
      </td>
      <td>
        <StartToEndCell
          startDate={record.shiftDateStartActualWorker}
          start={record.shiftTimeStartActualWorker}
          endDate={record.shiftDateEndActualWorker}
          end={record.shiftTimeEndActualWorker}
          who={record.completedBy}
          when={record.completedDateTime}
        />
      </td>
      <td>
        <StartToEndCell
          startDate={record.shiftDateStartActualConfirmed}
          start={record.shiftTimeStartActualConfirmed}
          endDate={record.shiftDateEndActualConfirmed}
          end={record.shiftTimeEndActualConfirmed}
          who={record.shiftTimeConfirmedBy}
          when={record.shiftTimeConfirmedDateTime}
        />
      </td>

      <td className='text-right'>
        <EditTimeButton
          canApprove={record.canApprove}
          onClick={() => handleEditClick(record.shiftId)}
        />
        <OpenShiftButton onClick={() => setDisplayShiftId(record.shiftId)} />
      </td>
    </tr>
  );
};

const OpenShiftButton = ({ onClick }) => {
  return (
    <Button
      className='m-l-xs'
      bsStyle='primary'
      bsSize='xs'
      onClick={onClick}
      data-toggle='tooltip'
      title='Open Shift'
    >
      <Icon icon='fa-arrow-right' />
    </Button>
  );
};

const EditTimeButton = ({ canApprove, onClick }) => {
  return (
    canApprove && (
      <Button
        bsStyle='primary'
        bsSize='xs'
        onClick={onClick}
        data-toggle='tooltip'
        title='Edit Shift Times'
      >
        <Icon icon='fa-clock' />
      </Button>
    )
  );
};
