import { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import useSubscription from 'hooks/useSubscription';
import $ from 'jquery';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils, Constants } from 'pages/Utils/app-utils';
import { ListViewUtils } from 'pages/Utils/list-view-utils';
import {
  MapToDiv,
  RateCol,
  SmallMuted,
  WellSmall,
  Icon,
} from 'pages/Utils/react-utils';
import { getNoShowShifts } from 'utils/api';

export const NoShowShiftsListView = (props) => {
  const { isLoading, isFetching, error, data, refetch } = useQuery(
    ['getNoShowShifts'],
    getNoShowShifts,
    {
      refetchInterval: Constants.DefaultAutoRefetchFrequency,
    }
  );
  const records = data?.records || [];

  useSubscription(
    [ShiftUtils.Events.ShiftCreated, ShiftUtils.Events.ShiftCancelled],
    refetch
  );

  if (error) {
    return (
      <WellSmall className='m-b-none'>Error encountered: {error}</WellSmall>
    );
  }
  if (isLoading) {
    return (
      <WellSmall className='m-b-none' hasSpinner>
        Getting Shifts...
      </WellSmall>
    );
  }
  if (records.length <= 0) {
    return (
      <WellSmall className='m-b-none'>
        No Shifts Found.
        <Button
          onClick={refetch}
          bsSize='xs'
          bsStyle='primary'
          className='pull-right'
          disabled={isFetching}
        >
          <Icon icon='fa-redo' /> Refresh
        </Button>
      </WellSmall>
    );
  }
  return (
    <TableContainer
      {...props}
      records={records}
      refetch={refetch}
      isFetching={isFetching}
    />
  );
};

const TableContainer = (props) => {
  const { isFetching, refetch } = props;
  return (
    <>
      <Alert bsStyle='danger'>
        <Button
          onClick={refetch}
          bsSize='xs'
          bsStyle='primary'
          className='pull-right'
          disabled={isFetching}
        >
          <Icon icon='fa-redo' /> Refresh
        </Button>
        <Icon icon='fa-user-clock' />
        {` Shifts with a start time in the past but but which the worker hasn't yet started.`}
      </Alert>
      <Table {...props} />
    </>
  );
};

const tableId = 'no-show-shifts-table';
class Table extends Component {
  componentDidMount() {
    this.initDataTables();
  }

  shouldComponentUpdate(nextProps) {
    if (
      JSON.stringify(nextProps.records) !== JSON.stringify(this.props.records)
    ) {
      return true;
    }

    return false;
  }

  UNSAFE_componentWillUpdate() {
    this.destroyDataTables();
  }

  componentDidUpdate() {
    this.initDataTables();
  }

  componentWillUnmount() {
    this.destroyDataTables();
  }

  initDataTables() {
    if (this.props.records.length > 0) {
      ListViewUtils.init(tableId);
    }
  }

  destroyDataTables() {
    const $table = $('#' + tableId);
    if ($table.length) {
      $table.DataTable().destroy();
    }
  }

  render() {
    return (
      <table
        className='table table-condensed table-striped table-hover'
        id={tableId}
        data-order='[[ 1, "asc" ]]'
      >
        <thead>
          <tr>
            <th>Details</th>
            <th>When</th>
            <th>Rate</th>
            <th>Worker</th>
            <th>Posted</th>
            <th>Allocated</th>
            <th className='no-sort' />
          </tr>
        </thead>
        <tbody>
          {this.props.records.map((item, i) => (
            <TableRow
              key={i}
              record={item}
              setDisplayShiftId={this.props.setDisplayShiftId}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

const TableRow = (props) => {
  const { record, setDisplayShiftId } = props;
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  return (
    <tr>
      <td>
        <MapToDiv>{[record.companyName, record.name]}</MapToDiv>
      </td>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime)}
      </td>
      <td>
        <RateCol record={record} />
      </td>
      <td
        data-order={record.workerSurname}
      >{`${record.workerFirstName} ${record.workerSurname}`}</td>
      <td data-order={record.createdDateTime}>
        {AppUtils.formatDateTimeUtc(record.createdDateTime)}
        <div>
          <SmallMuted>by {record.createdBy}</SmallMuted>
        </div>
      </td>
      <td data-order={record.allocatedDateTime}>
        {AppUtils.formatDateTimeUtc(record.allocatedDateTime)}
        <div>
          <SmallMuted>{record.allocatedBy}</SmallMuted>
        </div>
      </td>
      <td className='text-right'>
        <Button
          bsStyle='primary'
          bsSize='xs'
          onClick={() => setDisplayShiftId(record.shiftId)}
        >
          Open &raquo;
        </Button>
      </td>
    </tr>
  );
};
