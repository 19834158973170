import { WorkerShift } from 'types/WorkerTypes';
import { get } from './apiBase';

const getWorkerShifts = async (workerId: string) => {
  return get<WorkerShift[]>(`Worker/${workerId}/Shifts`);
};

export const workerApi = {
  getWorkerShifts,
};
