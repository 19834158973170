import { Col, Button, Row } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils, Constants } from 'pages/Utils/app-utils';
import { Icon, DashboardPanel, Muted } from 'pages/Utils/react-utils';
import { getCurrentSchedule } from 'utils/api';
import DataTable from 'components/DataTable';
import { GettingRecordsMessage } from 'components/GettingRecordsMessage';
import { NoRecordsFoundMessage } from 'components/NoRecordsFoundMessage';
import { WorkerImage } from '../../Worker/WorkerUtils';

const CurrentScheduleListView = () => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['currentSchedule'],
    getCurrentSchedule,
    {
      refetchInterval: Constants.DefaultAutoRefetchFrequency,
    }
  );
  const records = data?.records || [];
  const isGetting = isLoading || isFetching;

  return (
    <DashboardPanel
      header={
        <>
          <Row>
            <Col sm={10}>
              <h5>Current Schedule</h5>
              <Muted style={{ marginLeft: '5px' }}>
                Shifts where the start time is within the previous or the next
                12 hours.
              </Muted>
            </Col>
            <Col sm={2} className='text-right'>
              <Button
                bsSize='sm'
                bsStyle='primary'
                onClick={refetch}
                disabled={isGetting}
              >
                <Icon icon='fa-redo' isSpinning={isGetting} /> Refresh
              </Button>
            </Col>
          </Row>
        </>
      }
      content={
        <Body records={records} isGetting={isGetting} getData={refetch} />
      }
    />
  );
};

const tableId = 'current-schedule-table';

const Body = ({ records, isGetting }) => {
  if (records.length <= 0 && isGetting) {
    return <GettingRecordsMessage message='Getting current schedule...' />;
  }
  if (records.length <= 0) {
    return (
      <NoRecordsFoundMessage records={records} message='No Shifts Found.' />
    );
  }

  return (
    <DataTable
      tableId={tableId}
      records={records}
      render={() => {
        return (
          <table
            className='table table-condensed table-striped table-hover m-b-none'
            id={tableId}
            data-order='[[ 0, "asc" ]]'
          >
            <thead>
              <tr>
                <th>When</th>
                <th>Worker</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record, i) => (
                <TableRow key={i} record={record} />
              ))}
            </tbody>
          </table>
        );
      }}
    />
  );
};

const _statusToLabelMap = {
  [ShiftUtils.Status.Allocated]: 'info',
  [ShiftUtils.Status.InProgress]: 'warning',
  [ShiftUtils.Status.Complete]: 'success',
  [ShiftUtils.Status.Approved]: 'primary',
};

const TableRow = ({ record }) => {
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  const status =
    record.status === ShiftUtils.Status.Allocated ? 'Confirmed' : record.status;
  const statusLabel = (
    <span className={`label label-${_statusToLabelMap[record.status]}`}>
      {status}
    </span>
  );
  return (
    <tr>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime)}
      </td>
      <td data-order={record.workerSurname}>
        {record.workerHasImage && (
          <WorkerImage
            workerId={record.workerId}
            firstName={record.WorkerFirstName}
            surname={record.workerSurname}
            mobileNumber={record.workerMobileNumber}
          />
        )}
        {`${record.workerFirstName} ${record.workerSurname}`}
      </td>
      <td>{statusLabel}</td>
    </tr>
  );
};

export default CurrentScheduleListView;
