import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useSubscription from 'hooks/useSubscription';
import { WellSmall } from 'pages/Utils/react-utils';
import { getHirersActive } from 'utils/api';
import { useAlertWithRefreshButton } from 'components/AlertWithRefreshButton';
import DataTable from 'components/DataTable';
import { NoRecordsFoundMessage } from 'components/NoRecordsFoundMessage';
import { HirerUtils } from './HirerUtils';

const tableId = 'hirers-active-table';

const HirerListViewActive = () => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['getHirersActive'],
    getHirersActive
  );
  const records = data?.records || [];

  useSubscription(
    [HirerUtils.Events.HirerSaved, HirerUtils.Events.HirerDeleted],
    refetch
  );

  const { alert, refreshButton } = useAlertWithRefreshButton(
    'Your active Customers',
    isLoading,
    isFetching,
    refetch
  );

  if (isLoading || (isFetching && !data)) {
    return <WellSmall hasSpinner>Loading...</WellSmall>;
  }

  if (records.length <= 0) {
    return (
      <NoRecordsFoundMessage
        records={records}
        message='No customers found'
        refreshButton={refreshButton}
      />
    );
  }

  return (
    <>
      {alert}
      <DataTable
        records={records}
        tableId={tableId}
        render={() => {
          return (
            <table className='table table-condensed table-striped' id={tableId}>
              <thead>
                <tr>
                  <th>Company</th>
                  <th>RightJob Ref</th>
                  <th>Contact</th>
                </tr>
              </thead>
              <tbody>
                {records.map((item, i) => (
                  <Row key={i} record={item} />
                ))}
              </tbody>
            </table>
          );
        }}
      />
    </>
  );
};

const Row = ({ record }) => {
  return (
    <tr>
      <td>
        <Link to={`/Hirer/Edit/${record.hirerId}`}>{record.companyName}</Link>
      </td>
      <td>{record.internalReference}</td>
      <td>{record.mainContactName}</td>
    </tr>
  );
};

export default HirerListViewActive;
