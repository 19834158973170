import { Component } from 'react';
import { Alert, Tab, Tabs } from 'react-bootstrap';
import { WellSmall } from 'pages/Utils/react-utils';
import { usePermissions, userPermissions } from 'utils/userPermissions';
import { CreateNewShiftButton } from '../../Shift/Buttons/CreateNewShiftButton';
import { OpenShiftsListView } from '../../Shift/OpenShiftsListView';
import { ContentContainer, ShiftContainer } from '../../Shift/ShiftViewer';
import { AllocatedShiftsListView } from './AllocatedShiftsListView';
import { CancelledShiftsListView } from './CancelledShiftsListView';
import { ExpiredShiftsListView } from './ExpiredShiftsListView';
import { InProgressShiftsListView } from './InProgressShiftsListView';
import { NoShowShiftsListView } from './NoShowShiftsListView';
import { PreAllocatedShiftsListView } from './PreAllocatedShiftsListView';
import { RejectedShiftsListView } from './RejectedShiftsListView';

class ShiftAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
      displayShiftId: null,
    };

    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.setDisplayShiftId = this.setDisplayShiftId.bind(this);
  }

  handleTabSelect(tabToMakeActive) {
    this.setState({ activeTab: tabToMakeActive });
  }

  setDisplayShiftId(displayShiftId) {
    this.setState({ displayShiftId });
  }

  render() {
    const { displayShiftId } = this.state;
    return (
      <>
        <ContentContainer displayShiftId={displayShiftId}>
          <CreateNewShiftSection />
          <TabsMain
            {...this.state}
            handleTabSelect={this.handleTabSelect}
            setDisplayShiftId={this.setDisplayShiftId}
          />
        </ContentContainer>
        <ShiftContainer
          displayShiftId={displayShiftId}
          setDisplayShiftId={this.setDisplayShiftId}
        />
      </>
    );
  }
}

const CreateNewShiftSection = () => {
  const { hasPermission } = usePermissions();
  if (!hasPermission(userPermissions.shift.create.outsidePlanner)) {
    return null;
  }

  return (
    <WellSmall>
      <CreateNewShiftButton />
    </WellSmall>
  );
};

const TabsMain = ({ activeTab, handleTabSelect, setDisplayShiftId }) => {
  let eventKey = 1;
  return (
    <div className='tabs-container'>
      <Tabs
        activeKey={activeTab}
        animation={true}
        onSelect={handleTabSelect}
        id='shift-admin-tabs'
        mountOnEnter={true}
      >
        <Tab eventKey={eventKey++} title='Open'>
          <OpenShiftsTab setDisplayShiftId={setDisplayShiftId} />
        </Tab>
        <Tab eventKey={eventKey++} title='Pre-Allocated'>
          <PreAllocatedShiftsTab setDisplayShiftId={setDisplayShiftId} />
        </Tab>
        <Tab eventKey={eventKey++} title='Allocated'>
          <AllocatedShiftsTab setDisplayShiftId={setDisplayShiftId} />
        </Tab>
        <Tab eventKey={eventKey++} title='No Show'>
          <NoShowsShiftsTab setDisplayShiftId={setDisplayShiftId} />
        </Tab>
        <Tab eventKey={eventKey++} title='In Progress'>
          <InProgressShiftsTab setDisplayShiftId={setDisplayShiftId} />
        </Tab>
        <Tab eventKey={eventKey++} title='Rejected'>
          <RejectedShiftsTab setDisplayShiftId={setDisplayShiftId} />
        </Tab>
        <Tab eventKey={eventKey++} title='Expired'>
          <ExpiredShiftsTab setDisplayShiftId={setDisplayShiftId} />
        </Tab>
        <Tab eventKey={eventKey++} title='Cancelled'>
          <CancelledShiftsTab setDisplayShiftId={setDisplayShiftId} />
        </Tab>
      </Tabs>
    </div>
  );
};

const OpenShiftsTab = ({ setDisplayShiftId }) => {
  return (
    <OpenShiftsListView
      intro={
        <Alert bsStyle='info'>
          Shifts which are Open but have not yet been Allocated.
        </Alert>
      }
      setDisplayShiftId={setDisplayShiftId}
    />
  );
};

const PreAllocatedShiftsTab = ({ setDisplayShiftId }) => {
  return <PreAllocatedShiftsListView setDisplayShiftId={setDisplayShiftId} />;
};

const AllocatedShiftsTab = ({ setDisplayShiftId }) => {
  return <AllocatedShiftsListView setDisplayShiftId={setDisplayShiftId} />;
};

const NoShowsShiftsTab = ({ setDisplayShiftId }) => {
  return <NoShowShiftsListView setDisplayShiftId={setDisplayShiftId} />;
};

const InProgressShiftsTab = ({ setDisplayShiftId }) => {
  return <InProgressShiftsListView setDisplayShiftId={setDisplayShiftId} />;
};

const RejectedShiftsTab = () => {
  return <RejectedShiftsListView />;
};

const ExpiredShiftsTab = ({ setDisplayShiftId }) => {
  return <ExpiredShiftsListView setDisplayShiftId={setDisplayShiftId} />;
};

const CancelledShiftsTab = ({ setDisplayShiftId }) => {
  return <CancelledShiftsListView setDisplayShiftId={setDisplayShiftId} />;
};

export default ShiftAdmin;
