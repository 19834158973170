import { CSSProperties, ReactNode } from 'react';

interface StackProps {
  children: ReactNode;
  gap?: number;
  direction?: 'row' | 'column';
  style?: CSSProperties;
  className?: string;
}

export const Stack = ({
  children,
  gap,
  direction = 'column',
  style,
  className,
}: StackProps) => {
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexDirection: direction,
        gap,
        ...style,
      }}
    >
      {children}
    </div>
  );
};
