import { CSSProperties, Fragment } from 'react';
import { ICellRendererParams, ColDef } from 'ag-grid-community';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon } from 'pages/Utils/react-utils';
import { ShiftPlanItemShift } from 'types/ShiftPlanningTypes';
import { getNiceTime, formatDate, dateFormats } from 'utils';
import { IconContainer } from 'components/Planner';

export const isDataRequired = (shift?: ShiftPlanItemShift) => {
  return !shift?.vehicleReg || !shift?.isShiftTimeConfirmed;
};

export const ShiftCellRenderer = (props: ICellRendererParams) => {
  return !props.value
    ? ''
    : [
        props.value.workerName ? (
          <Fragment key={1}>{props.value.workerName}</Fragment>
        ) : null,
        <div key={2}>
          {[
            props?.value?.status === ShiftUtils.Status.Allocated &&
            isDataRequired(props?.value) ? (
              <CellIcon
                icon='fa-exclamation-triangle'
                style={{ color: '#ff4646' }}
              />
            ) : null,
            props.value.isPartOfShiftGroup ? (
              <CellIcon icon='fa-link' style={{ color: '#a500ea' }} />
            ) : null,
            props.value.isShiftTimeConfirmed
              ? AppUtils.formatTime(props.value.shiftTime)
              : 'Time TBC',
            props.value.vehicleReg ? '- ' + props.value.vehicleReg : null,
            props.value.trailerRef ? '- ' + props.value.trailerRef : null,
            props.value.hasNotes ? (
              <CellIcon icon='fa-comments' style={{ color: '#72bdff' }} />
            ) : null,
            props.value.hasPaymentAdjustments ? (
              <CellIcon icon='fa-money-bill' />
            ) : null,
            props.value.vehicleChangedWhileInProgress ? (
              <CellIcon
                icon='fa-exclamation-triangle'
                style={{ color: 'yellow' }}
              />
            ) : null,
            props.value.hasLocations ? (
              <CellIcon icon='fa-map-marker-check' style={{ color: 'green' }} />
            ) : null,
          ]
            .filter(Boolean)
            .map((item, index) => (
              <span key={index} className='m-l-xs'>
                {item}
              </span>
            ))}
        </div>,
      ]

        .filter(Boolean)
        .map((item, index) => <Fragment key={index}>{item}</Fragment>);
};

const CellIcon = ({ icon, style }: { icon: string; style?: CSSProperties }) => {
  return (
    <IconContainer small style={style}>
      <Icon icon={icon} />
    </IconContainer>
  );
};

export const ShiftTimeRenderer = (props: ICellRendererParams) => {
  return props.value ? getNiceTime(props.value) : '';
};

export const DeleteColumnRenderer = (props: ICellRendererParams) => {
  return <Icon icon='fa-times' colour='red' />;
};

export const SelectShiftColumnRenderer = (props: ICellRendererParams) => {
  return <Icon icon='fa-check' colour='green' />;
};

export const baseDefaultColDef: ColDef = {
  sortable: false,
  width: 100,
  cellStyle: { cursor: 'pointer' },
};

export const useColumnDefinitions = () => {
  const getColumnDefinitions = (
    startDate: Date,
    numberOfDays: number
  ): ColDef[] => {
    const columnDefinitions: ColDef[] = [
      {
        field: 'name',
        headerName: 'Shift',
        pinned: 'left',
        width: 150,
      },
    ];

    const iterateForDaysFromTodayAndAddToObject = (numberOfDays: number) => {
      for (let i = 0; i < numberOfDays; i++) {
        const date = new Date(startDate);
        date.setDate(date.getDate() + i);

        const formattedDate = formatDate(date);

        const shiftColumnDefinition: ColDef = {
          colId: `shiftCol-${formattedDate}`,
          cellRenderer: ShiftCellRenderer,
          field: formattedDate,
          headerName: formatDate(date, dateFormats.niceShortDay),
          width: 225,
          cellClassRules: {
            'shift-cell': () => true,
            'cell-planned': (params) =>
              params?.value?.status === ShiftUtils.Status.Planned,
            'cell-dataRequired': (params) =>
              (params?.value?.status === ShiftUtils.Status.PreAllocated ||
                params?.value?.status === ShiftUtils.Status.Allocated) &&
              isDataRequired(params?.value),
            'cell-pre-allocated': (params) =>
              params?.value?.status === ShiftUtils.Status.PreAllocated,
            'cell-accepted': (params) =>
              params?.value?.status === ShiftUtils.Status.Allocated &&
              params?.value?.vehicleReg,
            'cell-inProgress': (params) =>
              params?.value?.status === ShiftUtils.Status.InProgress,
            'cell-rejected': (params) =>
              params?.value?.status === ShiftUtils.Status.Rejected,
            'cell-not-started': (params) =>
              params?.value?.status === ShiftUtils.Status.NotStarted,
            'cell-cancelled': (params) =>
              params?.value?.status === ShiftUtils.Status.Cancelled,
            'cell-completed': (params) =>
              params?.value?.status === ShiftUtils.Status.Complete,
            'cell-approved': (params) =>
              [
                ShiftUtils.Status.Approved,
                ShiftUtils.Status.PaymentAuthorised,
              ].includes(params?.value?.status),
            'cell-empty': (params) => !params.value,
          },
        };
        columnDefinitions.push(shiftColumnDefinition);
      }
    };
    iterateForDaysFromTodayAndAddToObject(numberOfDays);

    return columnDefinitions;
  };

  return { getColumnDefinitions };
};

export const deleteColumnDefinition: ColDef = {
  field: 'delCol',
  headerName: ' ',
  cellRenderer: DeleteColumnRenderer,
  width: 50,
  pinned: 'left',
  cellStyle: { textAlign: 'center' },
};

export const selectShiftsColumnDefinition: ColDef = {
  field: 'selectCol',
  checkboxSelection: true,
  headerCheckboxSelection: true,
  width: 50,
  pinned: 'left',
  cellStyle: { textAlign: 'center' },
};
