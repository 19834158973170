import { Component } from 'react';
import { Button, Modal, Alert } from 'react-bootstrap';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { disapproveShift } from 'utils/api';
import { useAuth } from 'utils/auth';

export class DisapproveButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isModalShowing: false,
    };

    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  handleShowModalClick() {
    if (this.props.recordIdsSelected.length <= 0) {
      AppUtils.displayError(
        'Error',
        'No Shifts selected. Please select some and try again.'
      );
    } else {
      this.setState({ isModalShowing: true });
    }
  }

  handleCloseModalClick() {
    this.setState({ isModalShowing: false });
  }

  async handleSubmitClick() {
    console.log('handleSubmitClick');
    const { recordIdsSelected } = this.props;

    let model = {
      shiftIds: recordIdsSelected,
    };

    this.setState({ isSaving: true });
    const data = await disapproveShift(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess(
        'Done',
        `Shift${AppUtils.pluralise(
          recordIdsSelected.length,
          '',
          's'
        )} Disapproved`
      );
      this.handleCloseModalClick();
      PubSub.publish(ShiftUtils.Events.ShiftsDisapproved, data);
    });
    this.setState({ isSaving: false });
  }

  onExited() {
    AppUtils.destroyTooltips();
  }

  render() {
    const { records, recordIdsSelected } = this.props;
    const paidRecordsCount = records.filter(
      (m) => m.isPaid && recordIdsSelected.indexOf(m.shiftId) > -1
    ).length;

    const recordsSelectedCount = recordIdsSelected.length;
    const plural = AppUtils.pluralise(recordsSelectedCount, '', 's');
    const btnText = `Disapprove ${
      recordsSelectedCount === 0
        ? ''
        : `${recordsSelectedCount} Shift${plural}...`
    }`;
    return (
      <>
        <Button
          className='m-r-sm'
          bsStyle='success'
          bsSize='sm'
          onClick={this.handleShowModalClick}
          disabled={recordsSelectedCount <= 0}
        >
          <Icon icon='fa-undo' /> {btnText}
        </Button>
        <Modal
          backdrop='static'
          show={this.state.isModalShowing}
          onHide={this.handleCloseModalClick}
          onExited={this.onExited}
        >
          <Modal.Header closeButton>
            <Modal.Title>Disapprove Selected Shift{plural}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Body
              {...this.state}
              {...this.props}
              recordsSelectedCount={recordsSelectedCount}
              plural={plural}
              paidRecordsCount={paidRecordsCount}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCloseModalClick} bsSize='sm'>
              <i className='fa fa-times' /> Close
            </Button>
            <SubmitButton
              {...this.state}
              handleSubmitClick={this.handleSubmitClick}
              paidRecordsCount={paidRecordsCount}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const Body = ({ recordsSelectedCount, plural, paidRecordsCount }) => {
  const auth = useAuth();
  if (paidRecordsCount > 0) {
    return (
      <Alert bsStyle='danger'>
        <p>
          You have selected {paidRecordsCount} Shift
          {AppUtils.pluralise(paidRecordsCount, '', 's')}{' '}
          {`where payment to the worker has already been processed which means it's not possible to disapprove`}{' '}
          {AppUtils.pluralise(paidRecordsCount, 'this Shift', 'these Shifts')}.
        </p>
        {!auth.user.record.isAgencyUser && (
          <p>
            {`If there's a problem with the dates/times recorded for`}{' '}
            {AppUtils.pluralise(paidRecordsCount, 'this Shift', 'these Shifts')}{' '}
            then please contact the Agency for advice on how to proceed.
          </p>
        )}
      </Alert>
    );
  } else {
    return (
      <>
        <p>
          You have chosen to disapprove{' '}
          <span className='text-success'>{recordsSelectedCount}</span> selected
          Shift
          {plural}. The following will happen for{' '}
          {AppUtils.pluralise(
            recordsSelectedCount,
            'this shift',
            'these shifts'
          )}
          :
        </p>

        <WellSmall>
          <ul className='m-b-none'>
            <li>The existing approval date, time and person will be cleared</li>
            <li>
              The status of the Shift will be reset to Awaiting Time Approval
            </li>
            <li>{`The Shift times will become editable until it's re-approved`}</li>
            <li>
              {`The Shift finance calculations will be cleared and re-calculated when it's re-approved`}
            </li>
          </ul>
        </WellSmall>

        <p className='m-b-none'>Are you sure you would like to proceed?</p>
      </>
    );
  }
};

const SubmitButton = ({ isSaving, handleSubmitClick, paidRecordsCount }) => {
  const buttonTitle = isSaving ? 'Disapproving...' : 'Yes, disapprove';
  return (
    paidRecordsCount <= 0 && (
      <Button
        bsStyle='success'
        bsSize='sm'
        className='m-l-sm'
        onClick={handleSubmitClick}
        disabled={isSaving || paidRecordsCount > 0}
      >
        <Icon icon='fa-check' isSpinning={isSaving} /> {buttonTitle}
      </Button>
    )
  );
};
