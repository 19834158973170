import { Component } from 'react';
import $ from 'jquery';
import { ListViewUtils } from '../pages/Utils/list-view-utils';

class DataTable extends Component {
  componentDidMount() {
    this.initDataTables();
  }

  shouldComponentUpdate(nextProps) {
    if (
      JSON.stringify(this.props.records) !== JSON.stringify(nextProps.records)
    ) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillUpdate() {
    this.destroyDataTables();
  }

  componentDidUpdate() {
    this.initDataTables();
  }

  componentWillUnmount() {
    this.destroyDataTables();
  }

  initDataTables() {
    if (this.props.records.length > 0) {
      ListViewUtils.init(this.props.tableId);
    }
  }

  destroyDataTables() {
    const $table = $('#' + this.props.tableId);
    if ($table.length) {
      $table.DataTable().destroy();
    }
  }

  render() {
    return this.props.render();
  }
}

export default DataTable;
