import { useQuery, useMutation } from '@tanstack/react-query';
import { shiftApi } from 'apis/shiftApi';
import { shiftPlanApi } from 'apis/shiftPlanApi';
import { AppUtils } from 'pages/Utils/app-utils';
import {
  AcceptMultipleShiftsParams,
  AssignVehicleToPlanItemParams,
  AssignWorkerToPlanItemParams,
  CancelShiftFromPlanParams,
  CreatePlanItemsParams,
  CreateSingleShiftForDateParams,
  CreateSingleShiftForVehicleMutationParams,
  DailyReportForHirerQueryParams,
  DeletePlanItemParams,
  DeleteShiftFromPlanParams,
  PlanForHirerQueryParams,
  SendMessageParams,
  SendMultipleShiftsToWorkersParams,
  SendShiftToWorkerParams,
  UpdateShiftPlanItemTimeParams,
  UpdateShiftPlanSingleShiftTimeParams,
  UpdateShiftPlanSingleShiftVehicleParams,
  UpdateShiftPlanSingleShiftWorkerParams,
} from 'types/ShiftPlanningTypes';
import { extractErrorMessages, handleDownloadBlob } from 'utils';
import { QueryKeys } from './queryUtils';

export const useGetPlanForHirerQuery = (params: PlanForHirerQueryParams) => {
  const { hirerId, startDate } = params;
  return useQuery({
    queryKey: [
      !params.vehiclePlanItems
        ? QueryKeys.ShiftPlanForHirer
        : QueryKeys.VehiclePlanForHirer,
      hirerId,
      startDate.toString(),
    ],
    queryFn: () => {
      return shiftPlanApi.getPlanForHirer(params);
    },
    enabled: !!hirerId && !!startDate,
  });
};

export const useAddShiftsToPlanConfigQuery = (hirerId: string) => {
  return useQuery({
    queryKey: ['addShiftsToPlanConfig', hirerId],
    queryFn: () => {
      return shiftApi.getAddShiftsToPlanConfig(hirerId);
    },
  });
};

export const useCreatePlanItemsMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: CreatePlanItemsParams) => {
      return shiftPlanApi.createPlanItems(params);
    },
  });

  return mutation;
};

export const useSendMessageMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: SendMessageParams) => {
      return shiftPlanApi.sendMessage(params);
    },
  });

  return mutation;
};

export const useAssignWorkerToPlanItemMutation = () => {
  const mutation = useMutation({
    mutationFn: ({
      shiftPlanItemId,
      params,
    }: {
      shiftPlanItemId: string;
      params: AssignWorkerToPlanItemParams;
    }) => {
      return shiftPlanApi.assignWorkerToPlanItem(shiftPlanItemId, params);
    },
  });

  return mutation;
};

export const useAssignVehicleToPlanItemMutation = () => {
  const mutation = useMutation({
    mutationFn: ({
      shiftPlanItemId,
      params,
    }: {
      shiftPlanItemId: string;
      params: AssignVehicleToPlanItemParams;
    }) => {
      return shiftPlanApi.assignVehicleToPlanItem(shiftPlanItemId, params);
    },
  });

  return mutation;
};

export const useCreateSingleShiftMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: CreateSingleShiftForDateParams) => {
      return shiftPlanApi.createSingleShift(params);
    },
  });

  return mutation;
};

export const useCreateSingleShiftForVehicleMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: CreateSingleShiftForVehicleMutationParams) => {
      return shiftPlanApi.createSingleShiftForVehicle(params);
    },
  });

  return mutation;
};

export const useUpdateShiftPlanTimeMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: UpdateShiftPlanItemTimeParams) => {
      return shiftPlanApi.updateShiftPlanItemTime(params);
    },
  });

  return mutation;
};

export const useUpdateShiftPlanSingleShiftTimeMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: UpdateShiftPlanSingleShiftTimeParams) => {
      return shiftPlanApi.updateSingleShiftTime(params);
    },
  });

  return mutation;
};

export const useUpdateShiftPlanSingleShiftVehicleMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: UpdateShiftPlanSingleShiftVehicleParams) => {
      return shiftPlanApi.updateSingleShiftVehicle(params);
    },
  });

  return mutation;
};

export const useUpdateShiftPlanSingleShiftWorkerMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: UpdateShiftPlanSingleShiftWorkerParams) => {
      return shiftPlanApi.updateSingleShiftWorker(params);
    },
  });

  return mutation;
};

export const useDeletePlanItemMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: DeletePlanItemParams) => {
      return shiftPlanApi.deletePlanItem(params);
    },
  });

  return mutation;
};

export const useDeleteShiftFromPlanMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: DeleteShiftFromPlanParams) => {
      return shiftPlanApi.deleteShiftFromPlan(params);
    },
  });

  return mutation;
};

export const useCancelShiftFromPlanMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: CancelShiftFromPlanParams) => {
      return shiftPlanApi.cancelShiftFromPlan(params);
    },
  });

  return mutation;
};

export const useSendShiftToWorkerMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: SendShiftToWorkerParams) => {
      return shiftPlanApi.sendShiftToWorker(params);
    },
  });

  return mutation;
};

export const useSendMultipleShiftsToWorkersMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: SendMultipleShiftsToWorkersParams) => {
      return shiftPlanApi.sendShiftsToWorkers(params);
    },
  });

  return mutation;
};

export const useAcceptMultipleShiftsMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: AcceptMultipleShiftsParams) => {
      return shiftPlanApi.acceptShifts(params);
    },
  });

  return mutation;
};

export const useDownloadShiftPlanQuery = (params: PlanForHirerQueryParams) => {
  const { hirerId, startDate, vehiclePlanItems = false } = params;
  return useQuery({
    queryKey: [
      QueryKeys.ShiftPlanExcelDownload,
      hirerId,
      startDate,
      vehiclePlanItems,
    ],
    queryFn: () => {
      return shiftPlanApi.getWeeklySummaryDownload(params);
    },
    enabled: false,
    retry: false,
    onSuccess: (response) => {
      handleDownloadBlob(response);
    },
    onError: (error) => {
      AppUtils.displayError(
        'Error',
        extractErrorMessages(error),
        false,
        'download-error'
      );
    },
  });
};

export const useDownloadDailyPlanQuery = (
  params: DailyReportForHirerQueryParams
) => {
  const { hirerId, vehiclePlanItems = false } = params;
  return useQuery({
    queryKey: [
      QueryKeys.ShiftPlanExcelDailyDownload,
      hirerId,
      vehiclePlanItems,
    ],
    queryFn: () => {
      return shiftPlanApi.getDailySummaryDownload(params);
    },
    enabled: false,
    retry: false,
    onSuccess: (response) => {
      handleDownloadBlob(response);
    },
    onError: (error) => {
      AppUtils.displayError(
        'Error',
        extractErrorMessages(error),
        false,
        'download-error'
      );
    },
  });
};
