import { ReactNode, createContext, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

type HirerContextType = {
  hirerId: string;
};

const HirerContext = createContext<HirerContextType | undefined>(undefined);

type HirerContextProviderProps = {
  children: ReactNode;
};

export const HirerContextProvider = ({
  children,
}: HirerContextProviderProps) => {
  const params = useParams<{ hirerId: string }>();
  const hirerId = params.hirerId;
  if (!hirerId) {
    throw new Error('Hirer Id is required');
  }

  const value = useMemo(
    () => ({
      hirerId,
    }),
    [hirerId]
  );

  return (
    <HirerContext.Provider value={value}>{children}</HirerContext.Provider>
  );
};

export const useHirerContext = () => {
  const context = useContext(HirerContext);
  if (context === undefined) {
    throw new Error(
      'useHirerContext must be used within a HirerContextProvider'
    );
  }
  return context;
};
