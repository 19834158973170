import { useState } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { Dropdown } from 'components';
import { useLinkShiftMutation, useNewShiftConfigQuery } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import { ICustomEvent } from 'types/GeneralTypes';
import { LinkShiftParams } from 'types/ShiftTypes';
import { extractErrorMessages } from 'utils';
import { CustomerSelect } from 'components/CustomerSelect';
import { ErrorAlert } from 'components/ErrorAlert';
import { HourAndMinuteDropdown } from 'components/HourAndMinuteDropdown';
import { ModalContent } from 'components/Planner/ModalContent';
import { useShiftMenuModalContext } from 'components/Planner/ShiftMenuModalContext';
import { EShiftModalMenuUi } from 'components/Planner/shiftMenuModalTypes';
import {
  BackButton,
  CloseButton,
} from 'components/Planner/shiftMenuModalUtils';
import { ShiftSummary } from '../ShiftSummary';

const _formId = 'LinkShiftForm';

export const LinkShiftModalContent = () => {
  const { shiftToHandle, setUiState } = useShiftMenuModalContext();

  const queryClient = useQueryClient();

  const {
    mutate: linkShift,
    isError,
    error,
    isLoading,
    isSuccess,
  } = useLinkShiftMutation();

  const handleSubmitClick = () => {
    const { shiftId } = shiftToHandle;

    const $form = $(`#${_formId}`);
    if (!AppUtils.validateItems($form.find('.required'))) {
      return false;
    }

    const params = AppUtils.getDataModel($form) as LinkShiftParams;
    params.shiftTime = `${$form.find('[name=ShiftTimeHour]').val()}:${$form
      .find('[name=ShiftTimeMinutes]')
      .val()}`;

    linkShift(
      { shiftId, ...params },
      {
        onSuccess: () => {
          shiftToHandle.isPartOfShiftGroup = true;
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.LinkedShifts],
          });
          AppUtils.publish(ShiftUtils.Events.ShiftLinked);
          AppUtils.displaySuccess('Success', 'Shift linked successfully');
          setUiState(EShiftModalMenuUi.Default);
        },
      }
    );
  };

  return (
    <ModalContent
      title='Link Shift'
      Body={<UiStateLinkShift isError={isError} error={error} />}
      Footer={
        <UiStateConfirmLinkActions
          isSuccess={isSuccess}
          isLoading={isLoading}
          handleSubmitClick={handleSubmitClick}
        />
      }
    />
  );
};

const UiStateLinkShift = ({
  isError,
  error,
}: {
  isError: boolean;
  error: unknown;
}) => {
  const { shiftToHandle, shiftPlanItemToHandle } = useShiftMenuModalContext();
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    null
  );
  return (
    <>
      <h4>Current Shift</h4>
      <WellSmall>
        <ShiftSummary
          shiftToHandle={shiftToHandle}
          shiftPlanItemToHandle={shiftPlanItemToHandle}
        />
      </WellSmall>

      <WellSmall>
        Create a new shift and link it to the selected shift. You would do this
        if you have a split shift which you want to associate with the current
        shift.
      </WellSmall>

      <div id={_formId}>
        <CustomerSelect
          selectedCustomerId={selectedCustomerId}
          setSelectedCustomerId={setSelectedCustomerId}
          noFormGroup={false}
          label='Customer'
        />

        <ShiftTemplateSelect selectedCustomerId={selectedCustomerId} />

        <HourAndMinuteDropdown
          prefix='Shift'
          label='Shift Start Time'
          required
        />
      </div>

      <Alert bsStyle='info'>
        This will create a new shift which is linked to the current shift. Are
        you sure you want to proceed?
      </Alert>

      {isError ? (
        <Row>
          <Col xs={12}>
            <ErrorAlert msg={extractErrorMessages(error)} />
          </Col>
        </Row>
      ) : null}
    </>
  );
};

const ShiftTemplateSelect = ({
  selectedCustomerId,
}: {
  selectedCustomerId: string;
}) => {
  const [shiftTemplateId, setShiftTemplateId] = useState<string | null>(null);
  const query = useNewShiftConfigQuery();
  const options = selectedCustomerId
    ? query.data?.hirerOptions
        ?.find((hirer) => hirer.hirerId === selectedCustomerId)
        .shiftTemplates.map((template) => ({
          value: template.value,
          text: template.text,
        }))
    : [];
  return (
    <Dropdown
      name='shiftTemplateId'
      value={shiftTemplateId}
      options={options}
      onChange={(e: ICustomEvent) => {
        setShiftTemplateId(e.target.value);
      }}
      label={'Shift Template'}
      required
    />
  );
};

const UiStateConfirmLinkActions = ({
  isSuccess,
  isLoading,
  handleSubmitClick,
}: {
  isSuccess: boolean;
  isLoading: boolean;
  handleSubmitClick: () => void;
}) => {
  return (
    <Row>
      <Col sm={4} style={{ textAlign: 'left' }}>
        <BackButton />
      </Col>
      <Col sm={8}>
        <CloseButton />
        <ModalSaveButton
          isSaved={isSuccess}
          isSaving={isLoading}
          onClick={handleSubmitClick}
        />
      </Col>
    </Row>
  );
};

const ModalSaveButton = ({
  isSaving,
  isSaved,
  onClick,
}: {
  isSaving: boolean;
  isSaved: boolean;
  onClick: () => void;
}) => {
  const isDisabled = isSaving || isSaved;
  const buttonTitle = isSaving
    ? 'Creating...'
    : isSaved
    ? 'Linked!'
    : `Yes, Create and Link Shift`;
  return (
    <Button bsStyle='success' onClick={onClick} disabled={isDisabled}>
      <Icon icon='fa-check' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};
