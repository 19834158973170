import { useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Col,
  ControlLabel,
  FormGroup,
  Modal,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useWorkerOptions } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { useAssignWorkerToPlanItemMutation } from 'hooks/queries/shiftPlanningQueries';
import moment from 'moment';
import { getWorkerTypeFromIndustryType } from 'pages/ShiftConfig/ShiftConfigUtils';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall } from 'pages/Utils/react-utils';
import { ShiftIndustryType } from 'types/ShiftConfigTypes';
import { AssignWorkerToPlanItemParams } from 'types/ShiftPlanningTypes';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';

const oneToSevenOptions = Array.from({ length: 8 }, (_, i) => i);

const assignDatesTypeOptions = {
  Pattern: 'Pattern',
  SpecifciDates: 'SpecificDates',
} as const;

type ValueOf<T> = T[keyof T];
type AassignDatesTypeOptionTypes = ValueOf<typeof assignDatesTypeOptions>;

type ConfigToStore = {
  assignDatesType: AassignDatesTypeOptionTypes;
  shiftDates: string[];
};

const localStorageKey = 'previouslyUsedAssignDatesConfig';
const getFromLocalStorageOrDefault = () => {
  const config = localStorage.getItem(localStorageKey);
  if (config) {
    const parsedConfig = JSON.parse(config);
    return parsedConfig as ConfigToStore;
  }

  return null;
};

export const AssignWorkerToPlanItemModal = ({
  isModalOpen,
  handleModalClose,
  shiftPlanItemId,
  shiftIndustryType,
  existingWorkerId,
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
  shiftPlanItemId: string;
  shiftIndustryType: ShiftIndustryType;
  existingWorkerId?: string;
}) => {
  const assignWorkerToPlanItemMutation = useAssignWorkerToPlanItemMutation();
  const queryClient = useQueryClient();

  const handleSubmitClick = () => {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }

    const getData = AppUtils.getDataModel(
      $modal
    ) as AssignWorkerToPlanItemParams & {
      ShiftDates: string;
    };

    const seed = {
      assignDatesType,
      ...getData,
    };

    const params =
      assignDatesType === assignDatesTypeOptions.Pattern
        ? seed
        : {
            ...seed,
            shiftDates,
          };

    assignWorkerToPlanItemMutation.mutate(
      {
        shiftPlanItemId,
        params,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ShiftPlanForHirer],
          });

          const config: ConfigToStore = {
            assignDatesType,
            shiftDates,
          };
          localStorage.setItem(localStorageKey, JSON.stringify(config));

          AppUtils.displaySuccess('Success', 'Plan item assigned successfully');
          handleModalClose();
        },
      }
    );
  };

  const { query: configQuery, workerOptions } =
    useWorkerOptions(shiftIndustryType);

  const workerType = AppUtils.toTitleCase(
    getWorkerTypeFromIndustryType(shiftIndustryType)
  );

  const [assignDatesType, setAssignDatesType] =
    useState<AassignDatesTypeOptionTypes>(
      getFromLocalStorageOrDefault()?.assignDatesType ??
        assignDatesTypeOptions.Pattern
    );

  const handleAssignDatesTypeChange = (value: AassignDatesTypeOptionTypes) => {
    setAssignDatesType(value);
  };

  const [shiftDates, setShiftDates] = useState(
    getFromLocalStorageOrDefault()?.shiftDates ?? []
  );
  const handleShiftDatesChange = (shiftDates: Date[]) => {
    setShiftDates(shiftDates.map((m) => moment(m).format('YYYY-MM-DD')));
  };

  const changeOrClearDate = (e: { dates: Date[] }) => {
    handleShiftDatesChange(e.dates);
  };

  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Assign {workerType}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {configQuery.isLoading ? (
          <Row>
            <Col xs={12}>
              <WellSmall hasSpinner />
            </Col>
          </Row>
        ) : null}

        {configQuery.isError ? (
          <Row>
            <Col xs={12}>
              <ErrorAlert msg={extractErrorMessages(configQuery.error)} />
            </Col>
          </Row>
        ) : null}

        {configQuery.isSuccess ? (
          <>
            <Row>
              {existingWorkerId ? (
                <Field.Main
                  id='WorkerId'
                  columns={12}
                  name='WorkerId'
                  noLabel
                  noFormGroup
                  value={existingWorkerId}
                  type='hidden'
                />
              ) : null}
              <Field.InCol
                columns={12}
                name={`WorkerId${existingWorkerId ? '-display' : ''}`}
                label={workerType}
                options={workerOptions}
                defaultValue={existingWorkerId}
                value={existingWorkerId}
                isSelect
                required={existingWorkerId ? false : true}
                disabled={existingWorkerId ? true : false}
              />
            </Row>

            <FormGroup>
              <ControlLabel>How do you want to define the dates?</ControlLabel>
              <ButtonToolbar>
                <ToggleButtonGroup
                  type='radio'
                  name='AssignDatesType'
                  value={assignDatesType}
                  onChange={handleAssignDatesTypeChange}
                  justified
                >
                  <ToggleButton
                    value={assignDatesTypeOptions.Pattern}
                    className='btn-sm'
                  >
                    Pattern
                  </ToggleButton>
                  <ToggleButton
                    value={assignDatesTypeOptions.SpecifciDates}
                    className='btn-sm'
                  >
                    Specific Dates
                  </ToggleButton>
                </ToggleButtonGroup>
              </ButtonToolbar>
            </FormGroup>

            {assignDatesType === assignDatesTypeOptions.Pattern ? (
              <>
                <Row>
                  <DatePicker
                    columns={12}
                    name='StartShiftPatternFrom'
                    label='Start Shift Pattern From'
                    options={{ startDate: new Date() }}
                    isDatepicker
                  />
                </Row>
                <Row>
                  <Field.InCol
                    columns={6}
                    name={'ShiftPatternOn'}
                    label={'Days On'}
                    options={oneToSevenOptions}
                    value={0}
                    isSelect
                    required
                  />
                  <Field.InCol
                    columns={6}
                    name={'ShiftPatternOff'}
                    label={'Days Off'}
                    options={oneToSevenOptions}
                    value={0}
                    isSelect
                    required
                  />
                </Row>
              </>
            ) : null}
            {assignDatesType === assignDatesTypeOptions.SpecifciDates ? (
              <>
                <Row>
                  <DatePicker
                    columns={12}
                    name='ShiftDatesPicker'
                    label={`Shift Dates (${shiftDates.length} selected)`}
                    options={{ startDate: new Date() }}
                    multidate={31}
                    changeDate={changeOrClearDate}
                    clearDate={changeOrClearDate}
                    value={shiftDates
                      .map((m) => moment(m).format('DD/MM/yyyy'))
                      .join(', ')}
                  />
                </Row>
              </>
            ) : null}
          </>
        ) : null}

        {assignWorkerToPlanItemMutation.isError ? (
          <Row>
            <Col xs={12}>
              <ErrorAlert
                msg={extractErrorMessages(assignWorkerToPlanItemMutation.error)}
              />
            </Col>
          </Row>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>
          <i className='fa fa-times' /> Close
        </Button>
        <ModalSaveButton
          isSaved={assignWorkerToPlanItemMutation.isSuccess}
          isSaving={assignWorkerToPlanItemMutation.isLoading}
          onClick={handleSubmitClick}
          workerType={workerType}
        />
      </Modal.Footer>
    </Modal>
  );
};

const ModalSaveButton = ({
  isSaving,
  isSaved,
  onClick,
  workerType,
}: {
  isSaving: boolean;
  isSaved: boolean;
  onClick: () => void;
  workerType: string;
}) => {
  const isDisabled = isSaving || isSaved;
  const buttonTitle = isSaving
    ? 'Assigning...'
    : isSaved
    ? 'Assigned!'
    : `Assign ${workerType} to Plan`;
  return (
    <Button bsStyle='success' onClick={onClick} disabled={isDisabled}>
      <Icon icon='fa-check' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};
