import { Component, useEffect, useState } from 'react';
import { Alert, Col, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { FormLabel } from 'components';
import { Checkbox } from 'pages/Utils/Checkbox';
import { AppUtils } from 'pages/Utils/app-utils';
import {
  AlertWithIcon,
  Field,
  Icon,
  SidebarData,
  WellSmall,
} from 'pages/Utils/react-utils';
import { ShiftTemplateStatus } from 'types/ShiftConfigTypes';
import { getShiftConfigById, getConfigForEditShiftConfigUi } from 'utils/api';
import configureSubscriptions from 'utils/configureSubscriptions';
import { userPermissions, usePermissions } from 'utils/userPermissions';
import { Button } from 'components/Button';
import { ChangeShiftConfigStatusModal } from '../ChangeShiftConfigStatusModal';
import { TabIntro } from '../ShiftConfigCommon';
import { ShiftConfigEvents } from '../ShiftConfigUtils';
import { getWorkerType } from '../ShiftConfigUtils';
import ApplicantTab from './ApplicantTab';
import { ChargeRatesTab } from './ChargeRatesTab';
import { PayRatesTab } from './PayRatesTab';
import { PushToShiftsSection } from './PushToShiftsSection';
import {
  ShiftConfigContextProvider,
  ShiftConfigContext,
  useShiftConfigContext,
} from './ShiftConfigContextProvider';
import ShiftDetailsTab from './ShiftDetailsTab';

const Sidebar = (props) => {
  return (
    <div
      className='sidebar-panel'
      style={{ minHeight: '100%', marginTop: '-20px' }}
    >
      {props.children}
    </div>
  );
};

const Wrapper = (props) => {
  useEffect(() => {
    AppUtils.sidebarPrep();
  }, []);
  return <div className='wrapper'>{props.children}</div>;
};

export const ShiftConfigEdit = (props) => {
  const { hasPermission } = usePermissions();
  const params = useParams();
  if (!params.shiftConfigId) {
    return <Errors errors={['Could not get Shift Configuration Id']} />;
  }

  if (!hasPermission(userPermissions.shiftTemplate.view)) {
    return null;
  }

  return (
    <ShiftConfigContextProvider>
      <Main {...props} shiftConfigId={params.shiftConfigId} />
    </ShiftConfigContextProvider>
  );
};

class Main extends Component {
  static contextType = ShiftConfigContext;
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
      errors: [],
      cleanupSubscriptions: null,
    };

    this.subscriber = this.subscriber.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getConfig();

    const _me = this;
    const { cleanup } = configureSubscriptions(
      _me.subscribeToEvents(),
      _me.subscriber
    );
    this.setState({ cleanupSubscriptions: cleanup });
  }

  subscribeToEvents() {
    return [
      ShiftConfigEvents.ShiftConfigSaved,
      ShiftConfigEvents.ShiftConfigStatusChanged,
      ShiftConfigEvents.ShiftConfigShiftSyncRequested,
    ];
  }

  subscriber(event, data) {
    const { setRecord, setShiftFinance } = this.context;
    switch (event) {
      case ShiftConfigEvents.ShiftConfigSaved:
        setRecord(data.record);
        setShiftFinance(data.shiftFinance);
        return;

      case ShiftConfigEvents.ShiftConfigStatusChanged:
        setRecord(data.record);
        return;

      case ShiftConfigEvents.ShiftConfigShiftSyncRequested:
        this.getData();
        return;

      default:
        return;
    }
  }

  componentWillUnmount() {
    const { cleanupSubscriptions } = this.state;
    if (cleanupSubscriptions) {
      cleanupSubscriptions();
    }
  }

  async getData() {
    const { setIsGettingRecord } = this.context;
    setIsGettingRecord(true);
    this.setState({ errors: [] });
    const data = await getShiftConfigById(this.props.shiftConfigId);
    AppUtils.handleAjaxDone(data, () => {
      const errors = [];
      if (!data.record) {
        errors.push('Could not get Shift Template record');
      }
      if (!data.shiftFinance) {
        errors.push('Could not get Shift Finance record');
      }
      if (errors.length) {
        AppUtils.displayErrors(errors);
        this.setState({ errors });
      } else {
        const { setRecord, setShiftFinance } = this.context;
        setRecord(data.record);
        setShiftFinance(data.shiftFinance);
      }
    });
    setIsGettingRecord(false);
  }

  async getConfig() {
    const { setIsGettingConfig, setConfiguration } = this.context;

    setIsGettingConfig(true);
    const data = await getConfigForEditShiftConfigUi();
    AppUtils.handleAjaxDone(data, () => {
      if (data.configuration) {
        setConfiguration(data.configuration);
      } else {
        this.setState({ errors: ['Could not get configuration'] });
      }
    });
    setIsGettingConfig(false);
  }

  handleTabSelect(tabToMakeActive) {
    this.setState({ activeTab: tabToMakeActive });
  }

  render() {
    const { isGettingConfig, isGettingRecord, configuration, record } =
      this.context;

    if (this.state.errors.length > 0) {
      return <Errors errors={this.state.errors} />;
    }
    if (isGettingConfig) {
      return <GettingConfigMsg />;
    }
    if (isGettingRecord && !record) {
      return <GettingRecordMsg />;
    }
    if (!configuration) {
      return null;
    }
    if (!record) {
      return null;
    }
    return (
      <>
        <Sidebar>
          <SidebarContent {...this.state} />
        </Sidebar>
        <Wrapper>
          <BackButton />
          {record.isTemplate ? (
            <AlertWithIcon bsStyle='info' icon='fa-3x fa-info-square'>
              The Shift Template is where you configure all of the default
              aspects of a particular type of shift. When you create a Shift,
              you`ll select a Shift Template to base it on.
            </AlertWithIcon>
          ) : null}
          <EditPanel
            activeTab={this.state.activeTab}
            handleTabSelect={this.handleTabSelect}
          />
        </Wrapper>
      </>
    );
  }
}

const BackButton = () => {
  const history = useHistory();
  return (
    <Button onClick={() => history.goBack()} className='m-b-sm'>
      &laquo; Back
    </Button>
  );
};

const Errors = ({ errors }) => {
  if (errors.length <= 0) {
    return null;
  }
  return (
    <Alert bsStyle='danger'>
      The following errors occurred:
      <ul>
        {errors.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>
    </Alert>
  );
};

const SidebarContent = () => {
  const { record, isDraft } = useShiftConfigContext();
  return (
    <>
      {record.isTemplate ? (
        <>
          {isDraft ? (
            <div className='btn-container'>
              <SaveButton />
            </div>
          ) : null}

          <StatusSection />

          {!isDraft ? <PushToShiftsSection /> : null}
        </>
      ) : null}

      <SidebarData
        label='Shift Sync Requested'
        value={
          record.shiftSyncRequested
            ? AppUtils.formatDateTimeUtc(
                record.shiftSyncRequested,
                undefined,
                'DD MMM YYYY HH:mm:ss'
              )
            : ['N/A']
        }
      />
      <SidebarData
        label='Shift Sync Completed'
        value={
          record.shiftSyncCompleted
            ? AppUtils.formatDateTimeUtc(
                record.shiftSyncCompleted,
                undefined,
                'DD MMM YYYY HH:mm:ss'
              )
            : 'N/A'
        }
      />
      <SidebarData
        label='Created'
        value={AppUtils.formatDateTimeUtc(record.createdDateTime)}
      />
      <SidebarData label='Created By' value={record.createdBy} />
    </>
  );
};

const StatusSection = () => {
  const { record, isDraft, isLive } = useShiftConfigContext();

  return (
    <div className='m-b-sm'>
      <Alert bsStyle={isDraft ? 'danger' : isLive ? 'success' : null}>
        <SidebarData
          label='Status'
          labelStyle={{ color: '#999' }}
          value={<strong>{record.templateStatus}</strong>}
        />
        <ChangeStatusToLiveButton />
        <ChangeStatusToDraftButton />
      </Alert>
    </div>
  );
};

class ChangeStatusToLiveButton extends Component {
  static contextType = ShiftConfigContext;
  constructor(props) {
    super(props);
    this.state = {
      recordToChange: null,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  handleCloseModalClick() {
    this.setState({ recordToChange: null });
  }

  async handleClick() {
    const { isSaving, record, handleSaveClick } = this.context;

    if (isSaving) {
      return null;
    }

    const doneFn = (data) => {
      if (data.status === AppUtils.ApiStatus.Success) {
        AppUtils.clearInvalidClassName();

        const isValid = this.context.canShiftConfigGoLive();
        if (isValid) {
          this.setState({ recordToChange: record });
        }
      }
    };

    handleSaveClick(doneFn);
  }

  render() {
    if (!this.context.canEdit) {
      return null;
    }
    if (this.context.isLive) {
      return null;
    }
    return (
      <>
        <Button
          bsSize='sm'
          bsStyle='primary'
          block
          style={{ textAlign: 'center' }}
          onClick={this.handleClick}
          disabled={this.context.isSaving}
        >
          <Icon icon='fa-check' isSpinning={this.context.isSaving} /> Change to
          Live
        </Button>
        <ChangeShiftConfigStatusModal
          {...this.state}
          handleCloseModalClick={this.handleCloseModalClick}
          status={ShiftTemplateStatus.Live}
        />
      </>
    );
  }
}

const ChangeStatusToDraftButton = () => {
  const { hasPermission } = usePermissions();
  const { record, isDraft } = useShiftConfigContext();
  const [recordToChange, setRecordToChange] = useState(null);

  if (!hasPermission(userPermissions.shiftTemplate.edit)) {
    return null;
  }

  if (isDraft) {
    return null;
  }
  return (
    <>
      <Button
        bsSize='sm'
        bsStyle='warning'
        block
        style={{ textAlign: 'center' }}
        onClick={() => setRecordToChange(record)}
      >
        <Icon icon='fa-undo-alt' /> Change to Draft
      </Button>
      <ChangeShiftConfigStatusModal
        recordToChange={recordToChange}
        handleCloseModalClick={() => setRecordToChange(null)}
        status={ShiftTemplateStatus.Draft}
      />
    </>
  );
};

const EditPanel = ({ activeTab, handleTabSelect }) => {
  let eventKey = 1;
  const { record } = useShiftConfigContext();

  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col md={12}>
            <h3>Shift Template: {record.name}</h3>
            <em>
              {record.shiftPaymentType} shift for{' '}
              <span className='text-info'>{record.companyName}</span>
            </em>
          </Col>
        </Row>
      </Panel.Heading>
      <Panel.Body className='tabs-container' style={{ padding: '10px' }}>
        <Tabs
          activeKey={activeTab}
          onSelect={handleTabSelect}
          id='shift-config-tabs'
          animation={false}
        >
          <Tab eventKey={eventKey++} title='Shift Details'>
            <ShiftDetailsTab />
          </Tab>
          <Tab eventKey={eventKey++} title='Site Location'>
            <SiteAddressDetailsTab />
          </Tab>
          <Tab eventKey={eventKey++} title='Applicant Requirements'>
            <ApplicantTab />
          </Tab>
          <Tab eventKey={eventKey++} title='Rules'>
            <RulesTab />
          </Tab>
          <Tab eventKey={eventKey++} title='Pay Rates'>
            <PayRatesTab />
          </Tab>
          <Tab eventKey={eventKey++} title='Charge Rates'>
            <ChargeRatesTab />
          </Tab>
          <Tab eventKey={eventKey++} title='Other'>
            <OtherTab />
          </Tab>
        </Tabs>
      </Panel.Body>
    </Panel>
  );
};

const GettingConfigMsg = () => {
  const { isGettingConfig } = useShiftConfigContext();
  if (!isGettingConfig) {
    return null;
  }
  return (
    <WellSmall>
      <i className={AppUtils.spinIconClass} /> Please wait while the shift
      template is configured...
    </WellSmall>
  );
};

const GettingRecordMsg = () => {
  const isGettingRecord = useShiftConfigContext();
  if (!isGettingRecord) {
    return null;
  }
  return (
    <WellSmall>
      <i className={AppUtils.spinIconClass} /> Please wait while the shift
      template is retrieved...
    </WellSmall>
  );
};

const SiteAddressDetailsTab = () => {
  const { record, disabled } = useShiftConfigContext();
  return (
    <>
      <TabIntro icon='fa-2x fa-compass'>
        Configure the site location the {getWorkerType(record)} needs to attend
        to start the shift
      </TabIntro>
      <Row>
        <Field.InCol
          columns={6}
          name='SiteAddressLine1'
          label='Address Line 1'
          value={record.siteAddressLine1}
          maxLength='100'
          disabled={disabled}
        />
        <Field.InCol
          columns={6}
          name='SiteAddressLine2'
          label='Address Line 2'
          value={record.siteAddressLine2}
          maxLength='100'
          disabled={disabled}
        />
      </Row>
      <Row>
        <Field.InCol
          columns={6}
          name='SiteAddressLine3'
          label='Address Line 3'
          value={record.siteAddressLine3}
          maxLength='100'
          disabled={disabled}
        />
        <Field.InCol
          columns={6}
          name='SiteTown'
          label='Town'
          value={record.siteTown}
          maxLength='100'
          disabled={disabled}
        />
      </Row>
      <Row>
        <Field.InCol
          columns={6}
          name='SiteCounty'
          label='County'
          value={record.siteCounty}
          maxLength='100'
          disabled={disabled}
        />
        <Field.InCol
          columns={6}
          name='SitePostcode'
          label='Postcode'
          value={record.sitePostcode}
          maxLength='10'
          disabled={disabled}
        />
      </Row>
    </>
  );
};

const RulesTab = () => {
  const { record, disabled } = useShiftConfigContext();

  return (
    <>
      <TabIntro icon='fa-2x fa-clipboard-list-check'>
        Set rules for breaks and overtime.
      </TabIntro>
      <Row>
        <Col sm={6}>
          <Panel className='m-b-none'>
            <Panel.Heading>Breaks</Panel.Heading>
            <Panel.Body>
              <Row>
                <Checkbox
                  columns={6}
                  id='AreBreaksPaid'
                  label='Are Breaks Paid?'
                  checked={record.areBreaksPaid}
                  disabled={disabled}
                />
                <Field.InCol
                  columns={6}
                  name='BreakDurationMinutes'
                  label='Break duration'
                  value={record.breakDurationMinutes}
                  maxLength='2'
                  addOnPost='Minutes'
                  disabled={disabled}
                />
              </Row>
              <Row>
                <Col sm={12}>
                  <FormLabel
                    label='Breaks apply to shifts longer than'
                    name='BreaksApplyHours'
                  />
                  <Row>
                    <Field.InCol
                      columns={6}
                      name='BreaksApplyHours'
                      value={record.breaksApplyHours}
                      maxLength='2'
                      isNumber
                      noLabel
                      addOnPost='Hours'
                      disabled={disabled}
                    />
                    <Field.InCol
                      columns={6}
                      name='BreaksApplyMinutes'
                      value={record.breaksApplyMinutes ?? 0}
                      maxLength='2'
                      isNumber
                      noLabel
                      addOnPost='Minutes'
                      disabled={disabled}
                    />
                  </Row>
                </Col>
              </Row>
            </Panel.Body>
          </Panel>
        </Col>
        <Col sm={6}>
          <Panel className='m-b-none'>
            <Panel.Heading>Overtime</Panel.Heading>
            <Panel.Body>
              <Row>
                <Checkbox
                  columns={12}
                  id='IsOvertimePaid'
                  label='Is Overtime Paid?'
                  checked={record.isOvertimePaid}
                  disabled={disabled}
                />
              </Row>
              <Row>
                <Col sm={12}>
                  <FormLabel
                    label='Overtime paid after'
                    name='OvertimePaidAfterHours'
                  />
                  <Row>
                    <Field.InCol
                      columns={6}
                      name='OvertimePaidAfterHours'
                      value={record.overtimePaidAfterHours}
                      maxLength='2'
                      isNumber
                      noLabel
                      addOnPost='Hours'
                      disabled={disabled}
                    />
                    <Field.InCol
                      columns={6}
                      name='OvertimePaidAfterMinutes'
                      value={record.overtimePaidAfterMinutes}
                      maxLength='2'
                      isNumber
                      noLabel
                      addOnPost='Minutes'
                      disabled={disabled}
                    />
                  </Row>
                </Col>
              </Row>
            </Panel.Body>
          </Panel>
        </Col>
      </Row>
    </>
  );
};

const OtherTab = () => {
  const { record, disabled } = useShiftConfigContext();
  return (
    <>
      <TabIntro icon='fa-2x fa-file-signature'>
        Any additional info about this shift. This will be displayed to the{' '}
        {getWorkerType(record)} once they have been accepted for the shift.
      </TabIntro>
      <Row>
        <Field.InCol
          columns={12}
          name='AdditionalNotes'
          label='Additional Notes'
          value={record.additionalNotes}
          isTextArea
          maxLength='1000'
          disabled={disabled}
        />
      </Row>
    </>
  );
};

const SaveButton = () => {
  const { handleSaveClick, isSaving, canEdit } = useShiftConfigContext();

  if (!canEdit) {
    return null;
  }

  const text = isSaving ? 'Saving' : 'Save';
  return (
    <Button
      bsStyle='info'
      bsSize='sm'
      block
      onClick={() => handleSaveClick(null)}
      disabled={isSaving}
    >
      <Icon icon='fa-check' isSpinning={isSaving} /> {text}
    </Button>
  );
};
