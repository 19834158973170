import { Component } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { deleteShift } from 'utils/api';

export class DeleteSelectedButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isModalShowing: false,
    };

    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  handleShowModalClick() {
    if (this.props.recordIdsSelected.length <= 0) {
      AppUtils.displayError(
        'Error',
        'No Shifts selected. Please select some and try again.'
      );
    } else {
      this.setState({ isModalShowing: true });
    }
  }

  handleCloseModalClick() {
    this.setState({ isModalShowing: false });
  }

  async handleSubmitClick() {
    console.log('handleSubmitClick');

    const model = {
      shiftIds: this.props.recordIdsSelected,
    };

    this.setState({ isSaving: true });
    const data = await deleteShift(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Shifts Deleted');
      this.handleCloseModalClick();
      PubSub.publish(ShiftUtils.Events.ShiftsDeleted, data);
    });
    this.setState({ isSaving: false });
  }

  onExited() {
    AppUtils.destroyTooltips();
  }

  render() {
    const recordsSelectedCount = this.props.recordIdsSelected.length;
    const plural = recordsSelectedCount > 1 ? 's' : '';
    const btnText = `Delete ${
      recordsSelectedCount === 0
        ? ''
        : `${recordsSelectedCount} Shift${plural}...`
    }`;
    return (
      <>
        <Button
          bsStyle='danger'
          bsSize='sm'
          onClick={this.handleShowModalClick}
          disabled={recordsSelectedCount <= 0}
        >
          <Icon icon='fa-trash' /> {btnText}
        </Button>
        <Modal
          backdrop='static'
          show={this.state.isModalShowing}
          onHide={this.handleCloseModalClick}
          onExited={this.onExited}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Selected Shifts</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Body
              {...this.state}
              {...this.props}
              recordsSelectedCount={recordsSelectedCount}
              plural={plural}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCloseModalClick} bsSize='sm'>
              <i className='fa fa-times' /> Close
            </Button>
            <SubmitButton
              {...this.state}
              handleSubmitClick={this.handleSubmitClick}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const Body = ({ recordsSelectedCount, plural }) => {
  return (
    <>
      <p>
        You have chosen to delete{' '}
        <span className='text-success'>{recordsSelectedCount}</span> Shift
        {plural}.
      </p>
      <Alert bsStyle='danger'>
        You will not be able to undo this. Are you sure you would like to
        proceed?
      </Alert>
    </>
  );
};

const SubmitButton = (props) => {
  const buttonTitle = props.isSaving ? 'Deleting...' : 'Yes, Delete';
  return (
    <Button
      bsStyle='danger'
      bsSize='sm'
      className='m-l-sm'
      onClick={props.handleSubmitClick}
      disabled={props.isSaving}
    >
      <Icon icon='fa-trash' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
