import { Component } from 'react';
import { Button, Row, Col, Well } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { ShiftUtils, hasShiftBeenRunningTooLong } from 'pages/Shift/ShiftUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { ListViewUtils } from 'pages/Utils/list-view-utils';
import {
  MapToDiv,
  Icon,
  AlertWithIcon,
  DashboardPanel,
  WellSmall,
} from 'pages/Utils/react-utils';
import { getShiftsRunningForTooLong } from 'utils/api';

export class RunningForTooLongListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGetting: false,
      records: [],
      lastGet: null,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  setIsGetting(val, lastGet) {
    let newState = { isGetting: val };
    if (lastGet) {
      newState['lastGet'] = lastGet;
    }
    this.setState(newState);
  }

  async getData() {
    this.setIsGetting(true);
    const data = await getShiftsRunningForTooLong();
    if (data.records) {
      this.setState({ records: data.records });
    }
    this.setIsGetting(false, new Date());
  }

  render() {
    return <MainContent {...this.props} {...this.state} />;
  }
}

const MainContent = (props) => {
  const { isGetting, records } = props;
  if (isGetting) {
    return (
      <WellSmall className='m-b-none' hasSpinner>
        Getting Shifts which have been running for too long...
      </WellSmall>
    );
  }
  if (records.length <= 0) {
    return (
      <Well className='m-b-none'>
        <Row>
          <Col sm={8}>
            No Shifts found which have been running for too long.
          </Col>
          <Col sm={4} className='text-right'>
            <BackButton {...props} />
          </Col>
        </Row>
      </Well>
    );
  }
  return <TableContainer {...props} />;
};

const TableContainer = (props) => {
  return (
    <>
      <DashboardPanel
        header={
          <Row>
            <Col sm={8}>
              <h5>Long Running Shifts</h5>
            </Col>
            <Col sm={4} className='text-right'>
              <BackButton {...props} />
            </Col>
          </Row>
        }
        content={
          <>
            <AlertWithIcon bsStyle='danger' icon='fa-exclamation-circle'>
              Shifts which have been running for more than{' '}
              {ShiftUtils.ShiftRunWarningTimeHours} hours.
            </AlertWithIcon>
            <hr />
            <Table {...props} />
          </>
        }
      />
    </>
  );
};

const BackButton = ({ changeUi }) => (
  <Button
    bsSize='sm'
    bsStyle='primary'
    style={{ marginLeft: 'auto' }}
    onClick={() => changeUi()}
  >
    &laquo; Back
  </Button>
);

const tableId = 'shifts-running-too-long-table';
class Table extends Component {
  componentDidMount() {
    this.initDataTables();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.lastGet !== this.props.lastGet) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillUpdate() {
    this.destroyDataTables();
  }

  componentDidUpdate() {
    this.initDataTables();
  }

  componentWillUnmount() {
    this.destroyDataTables();
  }

  initDataTables() {
    if (this.props.records.length > 0) {
      ListViewUtils.init(tableId);
    }
  }

  destroyDataTables() {
    const $table = $('#' + tableId);
    if ($table.length) {
      $table.DataTable().destroy();
    }
  }

  render() {
    return (
      <table
        className='table table-condensed table-striped table-hover'
        id={tableId}
        data-order='[[ 1, "asc" ]]'
      >
        <thead>
          <tr>
            <th>Details</th>
            <th>Worker</th>
            <th>Schduled</th>
            <th>Started</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {this.props.records.map((item, i) => (
            <TableRow
              key={i}
              record={item}
              setDisplayShiftId={this.props.setDisplayShiftId}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

const TableRow = (props) => {
  const { record, setDisplayShiftId } = props;
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  const wasStartedMoreThan15HoursAgo = hasShiftBeenRunningTooLong(record);
  return (
    <tr>
      <td>
        <MapToDiv>{[record.companyName, record.name]}</MapToDiv>
      </td>
      <td data-order={record.workerSurname}>
        {`${record.workerFirstName} ${record.workerSurname}`}
        <DriverMobile record={record} />
      </td>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime)}
      </td>
      <td data-order={record.inProgressDateTime}>
        <InProgressContainer
          wasStartedMoreThan15HoursAgo={wasStartedMoreThan15HoursAgo}
        >
          <TooLongIcon record={record} />
          {AppUtils.formatDateTimeUtc(record.inProgressDateTime)}
        </InProgressContainer>
      </td>
      <td>
        {setDisplayShiftId ? (
          <Button
            bsStyle='primary'
            bsSize='xs'
            onClick={() => setDisplayShiftId(record.shiftId)}
          >
            Open &raquo;
          </Button>
        ) : (
          <Link
            to={`Shift/Edit/${record.shiftId}`}
            className='btn btn-xs btn-primary'
          >
            Open &raquo;
          </Link>
        )}
      </td>
    </tr>
  );
};

const DriverMobile = ({ record }) => {
  return (
    record.workerMobileNumber && (
      <>
        <br />
        {record.workerMobileNumber}
      </>
    )
  );
};

const InProgressContainer = ({ wasStartedMoreThan15HoursAgo, children }) => {
  if (wasStartedMoreThan15HoursAgo) {
    return (
      <span
        style={{ color: 'red' }}
        title={`Started more than ${ShiftUtils.ShiftRunWarningTimeHours} hours ago, please investigate.`}
        data-toggle='tooltip'
      >
        {children}
      </span>
    );
  } else {
    return <span>{children}</span>;
  }
};

const TooLongIcon = ({ record }) => {
  return (
    hasShiftBeenRunningTooLong(record) && (
      <Icon icon='fa-exclamation-circle' style={{ marginRight: '4px' }} />
    )
  );
};
