import { useEffect, useState } from 'react';
import { IApiResponse } from 'utils/apiUtils';
import { AppUtils } from '../pages/Utils/app-utils';

interface IConfiguration extends IApiResponse {
  configuration: { [key: string]: unknown };
}

const useGetConfig = (getConfigFn: () => Promise<IConfiguration>) => {
  const [isGettingConfig, setIsGettingConfig] = useState(false);
  const [configuration, setConfiguration] = useState(null);

  useEffect(() => {
    const getConfig = async () => {
      setIsGettingConfig(true);
      const data = await getConfigFn();
      if (data.status === AppUtils.ApiStatus.Success && data.configuration) {
        setConfiguration(data.configuration);
      }
      setIsGettingConfig(false);
    };

    getConfig();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isGettingConfig, configuration };
};

export default useGetConfig;
