import { Component } from 'react';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { Dropdown } from 'components';
import $ from 'jquery';
import PubSub from 'pubsub-js';
import { updateShiftTimes } from 'utils/api';
import { DatePicker } from '../Utils/DatePicker';
import { AppUtils } from '../Utils/app-utils';
import { Icon, WellSmall } from '../Utils/react-utils';
import { ShiftUtils } from './ShiftUtils';

const getHourOptions = () => {
  let _hourOptions = [];
  for (let i = 0; i < 24; i++) {
    _hourOptions.push(`0${i.toString()}`.slice(-2));
  }
  return _hourOptions;
};

const getMinuteOptions = () => {
  let _minuteOptions = [];
  for (let i = 0; i < 60; i++) {
    _minuteOptions.push(`0${i.toString()}`.slice(-2));
  }
  return _minuteOptions;
};

export class EditTimeModal extends Component {
  constructor(props) {
    super(props);
    this.state = { isSaving: false };

    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  async handleSubmitClick() {
    console.log('handleSubmitClick');
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);
    model['ShiftId'] = this.props.record.shiftId;

    this.setState({ isSaving: true });
    AppUtils.clearNotifications();

    const data = await updateShiftTimes(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Success', 'Shift Times updated');
      this.props.handleCloseModalClick();
      PubSub.publish(ShiftUtils.Events.TimesUpdated, data);
    });
    this.setState({ isSaving: false });
  }

  onExited() {
    AppUtils.destroyTooltips();
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={!!this.props.record}
        onHide={this.props.handleCloseModalClick}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Shift Times</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.state} {...this.props} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.handleCloseModalClick} bsSize='sm'>
            <i className='fa fa-times' /> Close
          </Button>
          <SubmitButton
            {...this.state}
            handleSubmitClick={this.handleSubmitClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const getInProgressDate = (record) => getInProgressVal(record, 'YYYY-MM-DD');
const getInProgressTime = (record) => getInProgressVal(record, 'HH:mm');
const getInProgressVal = (record, outFormat) =>
  AppUtils.formatDateTimeUtc(record.inProgressDateTime, null, outFormat);

const Body = (props) => {
  const { record } = props;
  if (!record) {
    return null;
  }
  return (
    <>
      <h4>Shift Info</h4>
      <Alert bsStyle='info' className='m-b-lg'>
        <DataRow
          label='Worker'
          value={`${record.workerFirstName} ${record.workerSurname}`}
        />
        <TimeRow
          label='Scheduled Start'
          date={record.shiftDate}
          time={record.shiftTime}
        />
      </Alert>
      <h4>
        {ShiftUtils.isInProgress(record)
          ? 'Actual Info'
          : 'Times Entered by the Worker'}
      </h4>
      <Alert bsStyle='info' className='m-b-lg'>
        <TimeRow
          label={ShiftUtils.isInProgress(record) ? 'Started At' : 'Start'}
          date={record.shiftDateStartActualWorker || getInProgressDate(record)}
          time={record.shiftTimeStartActualWorker || getInProgressTime(record)}
        />
        {ShiftUtils.isInProgress(record) ? null : (
          <TimeRow
            label='End'
            date={record.shiftDateEndActualWorker}
            time={record.shiftTimeEndActualWorker}
          />
        )}
      </Alert>
      <Row>
        <Col sm={3}>
          <h4>Edit Times</h4>
        </Col>
        <Col sm={9}>
          <WellSmall className='m-b'>
            Select updated times and press Save.
          </WellSmall>
        </Col>
      </Row>
      <Row>
        <Col sm={9} smOffset={3}>
          <Row>
            <Col sm={4} />
            <Col sm={4} className='m-b-xs'>
              Hours
            </Col>
            <Col sm={4} className='m-b-xs'>
              Minutes
            </Col>
          </Row>
        </Col>
      </Row>
      <TimeEditRow
        label='Start'
        status={record.status}
        date={
          record.shiftDateStartActualConfirmed ||
          record.shiftDateStartActualWorker ||
          getInProgressDate(record)
        }
        time={
          record.shiftTimeStartActualConfirmed ||
          record.shiftTimeStartActualWorker ||
          getInProgressTime(record)
        }
      />
      {ShiftUtils.isInProgress(record) ? null : (
        <TimeEditRow
          label='End'
          status={record.status}
          date={
            record.shiftDateEndActualConfirmed ||
            record.shiftDateEndActualWorker
          }
          time={
            record.shiftTimeEndActualConfirmed ||
            record.shiftTimeEndActualWorker
          }
        />
      )}
    </>
  );
};

const TimeRow = ({ label, date, time }) => (
  <DataRow
    label={label}
    value={`${AppUtils.getDateString(date)} ${AppUtils.formatTime(time)}`}
  />
);

const DataRow = ({ label, value }) => {
  return (
    <Row>
      <Col sm={3} className='text-right'>
        {label}
      </Col>
      <Col sm={9} className='font-bold'>
        {value}
      </Col>
    </Row>
  );
};

const TimeEditRow = ({ label, date, time }) => {
  const timeSplit = time.split(':');
  return (
    <Row>
      <Col sm={3} className='text-right'>
        {label}
      </Col>
      <Col sm={9}>
        <Row>
          <DatePicker
            columns={4}
            name={`ShiftDate${label}`}
            value={AppUtils.getDateString(date)}
            required
            noLabel
          />
          <Col sm={4}>
            <Dropdown
              name={`ShiftTimeHour${label}`}
              value={timeSplit[0]}
              options={getHourOptions()}
              noLabel
            />
          </Col>
          <Col sm={4}>
            <Dropdown
              name={`ShiftTimeMinute${label}`}
              value={timeSplit[1]}
              options={getMinuteOptions()}
              noLabel
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
const SubmitButton = (props) => {
  const buttonTitle = props.isSaving ? 'Saving...' : 'Save';
  return (
    <Button
      bsStyle='success'
      bsSize='sm'
      className='m-l-sm'
      onClick={props.handleSubmitClick}
      disabled={props.isSaving}
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
