import { Component } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import $ from 'jquery';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import { rejectWorkerAgencyLink } from 'utils/api';
import { WorkerImage } from '../../Worker/WorkerUtils';
import { WorkerAdminUtils } from './WorkerAdminUtils';

export class RejectWorkerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isSaved: false,
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExited() {
    this.setState({ isSaved: false });
  }

  async handleSaveClick() {
    console.log('handleSaveClick');
    const $modal = $('.modal');

    let model = AppUtils.getDataModel($modal);
    model['WorkerAgencyLinkId'] = this.props.recordToReject.workerAgencyLinkId;

    this.setState({ isSaving: true });
    AppUtils.clearNotifications();

    const data = await rejectWorkerAgencyLink(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Worker Rejected');
      this.setState({ isSaved: true });
      AppUtils.publish(WorkerAdminUtils.Events.WorkerRejected, data);
      this.props.handleCloseModalClick();
    });
    this.setState({ isSaving: false });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={!!this.props.recordToReject}
        onHide={this.props.handleCloseModalClick}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reject Worker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.props} {...this.state} />
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize='sm' onClick={this.props.handleCloseModalClick}>
            <i className='fa fa-times'></i> Cancel
          </Button>
          <ModalConfirmButton
            {...this.state}
            handleSaveClick={this.handleSaveClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = (props) => {
  if (!props.recordToReject) {
    return null;
  }

  const record = props.recordToReject;

  return (
    <>
      <p>You have chosen to reject the following worker:</p>
      <WellSmall>
        {record.workerHasImage && (
          <WorkerImage
            style={{ width: '60px' }}
            workerId={record.workerId}
            firstName={record.firstName}
            surname={record.surname}
            mobileNumber={record.mobileNumber}
            isClickDisabled
          />
        )}
        {record.firstName} {record.surname}
      </WellSmall>
      <p>
        If you proceed, this worker will no longer be able to search for shifts
        or be accepted for shifts from your agency.
      </p>
      <Alert bsStyle='danger'>Are you sure you want to proceed?</Alert>
    </>
  );
};

const ModalConfirmButton = (props) => {
  const isDisabled = props.isSaving || props.isSaved;
  const buttonTitle = props.isSaving
    ? 'Rejecting...'
    : props.isSaved
    ? 'Rejected'
    : 'Yes, Reject';
  return (
    <Button
      bsStyle='danger'
      bsSize='sm'
      onClick={props.handleSaveClick}
      disabled={isDisabled}
    >
      <Icon icon='fa-thumbs-down' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
