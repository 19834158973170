import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import useSubscription from 'hooks/useSubscription';
import { AppUtils } from 'pages/Utils/app-utils';
import { SmallMuted, Icon, WellSmall, SpinIcon } from 'pages/Utils/react-utils';
import { getSiteAssessments } from 'utils/api';
import { useAlertWithRefreshButton } from 'components/AlertWithRefreshButton';
import DataTable from 'components/DataTable';
import { NoRecordsFoundMessage } from 'components/NoRecordsFoundMessage';
import { DeleteSiteAssessmentModal } from './DeleteSiteAssessmentModal';
import { WorkerUtils } from './WorkerUtils';

const SiteAssessmentsTable = ({ record }) => {
  const [recordToDelete, setRecordToDelete] = useState(null);
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['workerSiteAssessments', record.workerId],
    () => getSiteAssessments(record.workerId)
  );
  const records = data?.records || [];

  const handleDeleteButtonClick = (recordToDelete) => {
    setRecordToDelete(recordToDelete);
  };

  const handleCloseDeleteModalClick = () => {
    setRecordToDelete(null);
  };

  useSubscription([WorkerUtils.Events.SiteAssessmentDeleted], refetch);

  const { alert, refreshButton } = useAlertWithRefreshButton(
    "This worker's Site Assessments.",
    isLoading,
    isFetching,
    refetch
  );

  if (isLoading) {
    return (
      <WellSmall>
        <SpinIcon /> Getting Site Assessments...
      </WellSmall>
    );
  }
  if (records.length <= 0) {
    return (
      <NoRecordsFoundMessage records={records} refreshButton={refreshButton} />
    );
  }
  return (
    <>
      {alert}
      <Table
        records={records}
        handleDeleteButtonClick={handleDeleteButtonClick}
      />
      <DeleteSiteAssessmentModal
        recordToDelete={recordToDelete}
        handleCloseModalClick={handleCloseDeleteModalClick}
      />
    </>
  );
};
export default SiteAssessmentsTable;

const tableId = 'site-assessments-table';

const Table = ({ records, handleDeleteButtonClick }) => {
  return (
    <DataTable
      tableId={tableId}
      records={records}
      render={() => {
        if (records.length <= 0) {
          return null;
        }
        return (
          <table
            className='table table-condensed table-striped'
            id={tableId}
            data-order='[[0, "asc"]]'
          >
            <thead>
              <tr>
                <th>Assessment Date</th>
                <th>Company</th>
                <th>Status</th>
                <th>Created</th>
                <th className='no-sort' />
              </tr>
            </thead>
            <tbody>
              {records.map((item, i) => (
                <TableRow
                  key={i}
                  record={item}
                  handleDeleteButtonClick={handleDeleteButtonClick}
                />
              ))}
            </tbody>
          </table>
        );
      }}
    />
  );
};

const TableRow = ({ record, handleDeleteButtonClick }) => {
  return (
    <tr>
      <td data-order={record.assessmentDate}>
        {AppUtils.getDateString(record.assessmentDate)}
      </td>
      <td>{record.companyName}</td>
      <td>{record.status}</td>
      <td data-order={record.createdDateTime}>
        {AppUtils.formatDateTimeUtc(record.createdDateTime)}
        <br />
        <SmallMuted>by {record.createdBy}</SmallMuted>
      </td>
      <td className='text-right'>
        <Button
          bsStyle='danger'
          bsSize='xs'
          onClick={() => handleDeleteButtonClick(record)}
          data-toggle='tooltip'
          title={`Delete asessment for ${record.companyName}`}
        >
          <Icon icon='fa-times' />
        </Button>
      </td>
    </tr>
  );
};
