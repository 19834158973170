import { Row, Col } from 'react-bootstrap';
import { useShiftContext } from 'pages/Shift/ShiftContext';
import { Notes } from './Notes';

export const ShiftNotesTabContent = () => {
  const { shiftId } = useShiftContext();
  return (
    <Row>
      <Col md={6}>
        <Notes shiftId={shiftId} type='Worker' />
      </Col>
      <Col md={6}>
        <Notes shiftId={shiftId} type='Hirer' />
      </Col>
    </Row>
  );
};
