import { Button } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import useSubscription from 'hooks/useSubscription';
import fileDownload from 'js-file-download';
import { AppUtils } from 'pages/Utils/app-utils';
import { WellSmall, SpinIcon, SmallMuted, Icon } from 'pages/Utils/react-utils';
import { getWorkerFiles, getWorkerFile } from 'utils/api';
import { useAlertWithRefreshButton } from 'components/AlertWithRefreshButton';
import DataTable from 'components/DataTable';
import { NoRecordsFoundMessage } from 'components/NoRecordsFoundMessage';
import { AddFileButton } from './AddFileButton';

const tableId = 'worker-files-table';

const WorkerFileTable = ({ record }) => {
  const { workerId } = record;
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['workerFiles', workerId],
    () => getWorkerFiles(workerId)
  );
  const records = data?.records || [];

  useSubscription(
    [AppUtils.Events.WorkerFileAdded, AppUtils.Events.WorkerFileRemoved],
    refetch
  );

  const { alert, refreshButton } = useAlertWithRefreshButton(
    <>
      {`This worker's uploaded files. `}
      <AddFileButton workerId={workerId} />
    </>,
    isLoading,
    isFetching,
    refetch
  );

  if (isLoading) {
    return (
      <WellSmall>
        <SpinIcon /> Getting files...
      </WellSmall>
    );
  }

  if (records.length <= 0) {
    return (
      <NoRecordsFoundMessage
        message={
          <>
            No Files Found
            <AddFileButton workerId={workerId} />
          </>
        }
        records={records}
        refreshButton={refreshButton}
      />
    );
  }

  return (
    <>
      {alert}
      <DataTable
        tableId={tableId}
        records={records}
        render={() => {
          if (records.length <= 0) {
            return null;
          }
          return (
            <table
              className='table table-condensed table-striped'
              id={tableId}
              data-order='[[2, "asc"]]'
            >
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Created</th>
                  <th className='no-sort'></th>
                </tr>
              </thead>
              <tbody>
                {records.map((item, i) => (
                  <Row key={i} record={item} />
                ))}
              </tbody>
            </table>
          );
        }}
      />
    </>
  );
};

const Row = ({ record }) => {
  return (
    <tr>
      <td>{record.type}</td>
      <td data-order={record.createdDateTime}>
        {AppUtils.formatDateTimeUtc(record.createdDateTime)}
        <br />
        <SmallMuted>by {record.createdBy}</SmallMuted>
      </td>
      <td className='text-right'>
        <DownloadButton record={record} />
      </td>
    </tr>
  );
};

const getFile = async (fileId, fileName) => {
  const data = await getWorkerFile(fileId);
  if (data.ok) {
    const blob = await data?.blob();
    if (blob) {
      fileDownload(blob, fileName.split('/').slice(-1).pop());
    }
  } else {
    AppUtils.displayError(
      'Error',
      `There was an error retrieving the file. Please contact support with the name of the worker and details of the file you're trying to download.`
    );
  }
};

const DownloadButton = ({ record }) => {
  return (
    <Button
      bsSize='xs'
      bsStyle='info'
      onClick={() => getFile(record.workerFileId, record.name)}
      data-toggle='tooltip'
      title={`Download ${record.type}`}
    >
      <Icon icon='fa-download' />
    </Button>
  );
};

export default WorkerFileTable;
