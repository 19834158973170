import { Component } from 'react';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import $ from 'jquery';
import { duplicateShiftConfig } from 'utils/api';
import { AppUtils } from '../Utils/app-utils';
import { Icon, WellSmall, SmallMuted, Field } from '../Utils/react-utils';
import { ShiftConfigEvents } from './ShiftConfigUtils';

export class DuplicateShiftConfigModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDuplicating: false,
      isDuplicated: false,
    };

    this.handleDuplicateClick = this.handleDuplicateClick.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExited() {
    this.setState({ isDuplicated: false });
  }

  async handleDuplicateClick() {
    const $modal = $('.modal');
    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);
    model['ShiftConfigId'] = this.props.recordToDuplicate.shiftConfigId;

    this.setState({ isDuplicating: true });
    AppUtils.clearNotifications();

    const data = await duplicateShiftConfig(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Template duplicated');
      this.setState({ isDuplicated: true });
      AppUtils.publish(ShiftConfigEvents.ShiftConfigDuplicated, data);
      this.props.handleCloseModalClick();
    });

    this.setState({ isDuplicating: false });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={!!this.props.recordToDuplicate}
        onHide={this.props.handleCloseModalClick}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Shift Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.props} {...this.state} />
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize='sm' onClick={this.props.handleCloseModalClick}>
            <i className='fa fa-times'></i> Cancel
          </Button>
          <ModalConfirmButton
            {...this.state}
            handleDuplicateClick={this.handleDuplicateClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = (props) => {
  if (!props.recordToDuplicate) {
    return null;
  }
  return (
    <>
      <p>You have chosen to duplicate the following Shift Template:</p>
      <WellSmall>
        <ConfirmRow
          label='Company'
          value={props.recordToDuplicate.companyName}
        />
        <ConfirmRow label='Template' value={props.recordToDuplicate.name} />
      </WellSmall>
      <Row>
        <Field.InCol
          columns={12}
          name='DuplicateShiftTemplateName'
          label='New Template Name'
          value={`${props.recordToDuplicate.name} - COPY`}
          required
        />
      </Row>
      <Alert bsStyle='info' bsSize='sm'>
        Are you sure you want to proceed?
      </Alert>
    </>
  );
};

const ConfirmRow = ({ label, value }) => {
  return (
    <Row>
      <Col sm={4} className='text-right'>
        <SmallMuted>{label}</SmallMuted>
      </Col>
      <Col sm={8}>{value}</Col>
    </Row>
  );
};

const ModalConfirmButton = (props) => {
  const isDisabled = props.isDuplicating || props.isDuplicated;
  const buttonTitle = props.isDuplicating
    ? 'Duplicating...'
    : props.isDuplicated
    ? 'Duplicated'
    : 'Yes, Duplicate Template';
  return (
    <Button
      bsStyle='primary'
      bsSize='sm'
      onClick={props.handleDuplicateClick}
      disabled={isDisabled}
    >
      <Icon icon='fa-clone' isSpinning={props.isDuplicating} /> {buttonTitle}
    </Button>
  );
};
