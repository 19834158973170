import { Col, Row } from 'react-bootstrap';
import { ModalContent } from 'components/Planner/ModalContent';
import { useShiftMenuModalContext } from 'components/Planner/ShiftMenuModalContext';
import {
  BackButton,
  CloseButton,
} from 'components/Planner/shiftMenuModalUtils';
import { ShiftTrailersUi } from 'components/ShiftTrailers';

export const UpdateTrailerModalContent = () => {
  const { shiftToHandle } = useShiftMenuModalContext();

  return (
    <ModalContent
      title={`Trailers`}
      Body={
        <ShiftTrailersUi
          shiftId={shiftToHandle.shiftId}
          shiftDate={shiftToHandle.shiftDate}
          shiftTime={shiftToHandle.shiftTime}
        />
      }
      Footer={<ViewTrailersActions />}
    />
  );
};

const ViewTrailersActions = () => {
  return (
    <Row>
      <Col sm={4} style={{ textAlign: 'left' }}>
        <BackButton />
      </Col>
      <Col sm={8}>
        <CloseButton />
      </Col>
    </Row>
  );
};
