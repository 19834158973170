import { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useMarkAsPaidInvoiceMutation } from 'hooks/queries';
import { useModal } from 'hooks/useModal';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon } from 'pages/Utils/react-utils';
import { Invoice } from 'types/Invoice';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';

export const useMarkAsPaidDialog = () => {
  const [invoice, setInvoice] = useState<Invoice | null>(null);

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  const showMarkAsPaidDialog = (invoice: Invoice) => {
    setInvoice(invoice);
    handleModalOpen();
  };

  const renderMarkAsPaidDialog = () => {
    if (!invoice) {
      return null;
    }

    return (
      <MarkAsPaidDialog
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        onExited={() => setInvoice(null)}
        invoice={invoice}
      />
    );
  };

  return { showMarkAsPaidDialog, renderMarkAsPaidDialog };
};

const MarkAsPaidDialog = ({
  isModalOpen,
  handleModalClose,
  onExited,
  invoice,
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
  onExited?: () => void;
  invoice: Invoice;
}) => {
  const history = useHistory();
  const markaspaidRequest = useMarkAsPaidInvoiceMutation();
  const handleMarkAsPaidClick = () => {
    markaspaidRequest.mutate(
      { invoiceId: invoice.invoiceId },
      {
        onSuccess: () => {
          AppUtils.publish('invoice.markaspaidd');
          AppUtils.displaySuccess('Thanks', 'Invoice MarkAsPaidd');
          handleModalClose();
          history.push('/Invoice');
        },
      }
    );
  };

  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Mark Invoice as Paid</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You are going to mark this invoice as paid. Once complete, the invoice
          will be locked and no further changes can be made.
        </p>

        <Alert bsStyle='info'>Are you sure you want to proceed?</Alert>

        {markaspaidRequest.isError ? (
          <ErrorAlertForQueryOrNull
            isError={markaspaidRequest.isError}
            error={markaspaidRequest.error}
          />
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>Cancel</Button>
        <Button
          bsStyle='success'
          onClick={handleMarkAsPaidClick}
          disabled={markaspaidRequest.isLoading}
        >
          <Icon icon='fa-check' isSpinning={markaspaidRequest.isLoading} /> Yes,
          Mark As Paid
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
