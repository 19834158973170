import { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { AppUtils } from 'pages/Utils/app-utils';
import {
  Icon,
  MapToDiv,
  RateCol,
  SmallMuted,
  WellSmall,
} from 'pages/Utils/react-utils';
import { getExpiredShifts } from 'utils/api';
import DataTable from 'components/DataTable';

export class ExpiredShiftsListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGetting: false,
      records: [],
      lastGet: null,
      showModal: false,
      recordToEdit: null,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  setIsGetting(val, lastGet) {
    let newState = { isGetting: val };
    if (lastGet) {
      newState['lastGet'] = lastGet;
    }
    this.setState(newState);
  }

  async getData() {
    this.setIsGetting(true);
    const data = await getExpiredShifts();
    if (data.status === AppUtils.ApiStatus.Success && data.records) {
      this.setState({ records: data.records });
    }
    this.setIsGetting(false, new Date());
  }

  render() {
    if (this.state.isGetting) {
      return (
        <WellSmall className='m-b-none' hasSpinner>
          Getting Expired Shifts...
        </WellSmall>
      );
    }
    if (this.state.records.length <= 0) {
      return (
        <WellSmall className='m-b-none'>No Expired Shifts Found.</WellSmall>
      );
    }
    return <TableContainer {...this.props} {...this.state} />;
  }
}

const tableId = 'expired-shifts-table';
const TableContainer = ({ records, setDisplayShiftId }) => {
  return (
    <>
      <Alert bsStyle='danger'>
        <Icon icon='fa-business-time' /> Shifts which were not Allocated and
        have now Expired.
      </Alert>
      <DataTable
        records={records}
        tableId={tableId}
        render={() => {
          return (
            <table
              className='table table-condensed table-striped table-hover'
              id={tableId}
              data-order='[[ 1, "asc" ]]'
            >
              <thead>
                <tr>
                  <th>Details</th>
                  <th>When</th>
                  <th>Rate</th>
                  <th>Posted</th>
                  <th className='no-sort' />
                </tr>
              </thead>
              <tbody>
                {records.map((item, i) => (
                  <TableRow
                    key={i}
                    record={item}
                    setDisplayShiftId={setDisplayShiftId}
                  />
                ))}
              </tbody>
            </table>
          );
        }}
      />
    </>
  );
};

const TableRow = (props) => {
  const { record, setDisplayShiftId } = props;
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  return (
    <tr>
      <td>
        <MapToDiv>{[record.companyName, record.name]}</MapToDiv>
      </td>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime)}
      </td>
      <td>
        <RateCol record={record} />
      </td>
      <td data-order={record.createdDateTime}>
        {AppUtils.formatDateTimeUtc(record.createdDateTime)}
        <div>
          <SmallMuted>by {record.createdBy}</SmallMuted>
        </div>
      </td>
      <td className='text-right'>
        <Button
          bsStyle='primary'
          bsSize='xs'
          onClick={() => setDisplayShiftId(record.shiftId)}
        >
          Open &raquo;
        </Button>
      </td>
    </tr>
  );
};
