import { Col, Row } from 'react-bootstrap';
import { useAuth } from 'utils/auth';

const AdminHome = () => {
  const auth = useAuth();
  return (
    <Row
      className='border-bottom white-bg dashboard-header m-b'
      style={{ marginTop: '-15px' }}
    >
      <Col md={6}>
        <h2>Hello {auth.user.record.firstName}</h2>
      </Col>
      <Col md={6} className='text-right'>
        <div>
          <small>ADMIN</small>
        </div>
      </Col>
    </Row>
  );
};

export default AdminHome;
