import { Component } from 'react';
import { Alert, Col, Button, Panel, Row } from 'react-bootstrap';
import StarRatingComponent from 'react-star-rating-component';
import $ from 'jquery';
import moment from 'moment';
import { ShiftUtils, PaidStatusBadge } from 'pages/Shift/ShiftUtils';
import { Checkbox } from 'pages/Utils/Checkbox';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils, Constants } from 'pages/Utils/app-utils';
import { ListViewUtils } from 'pages/Utils/list-view-utils';
import {
  WellSmall,
  SmallMuted,
  Icon,
  DashboardPanel,
} from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { getApprovedShifts } from 'utils/api';
import { WorkerImage } from '../../Worker/WorkerUtils';
import { AddFeedbackButton } from './AddFeedbackButton';
import { DisapproveButton } from './DisapproveButton';

export class ShiftApprovedListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGetting: false,
      records: [],
      recordIdsSelected: [],
      lastGet: null,
      error: null,
    };

    this.subscriber = this.subscriber.bind(this);
    this.getData = this.getData.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
  }

  componentDidMount() {
    const _me = this;
    [
      ShiftUtils.Events.ShiftsApproved,
      ShiftUtils.Events.FeedbackAdded,
      ShiftUtils.Events.ShiftsDisapproved,
    ].forEach((e) => {
      PubSub.subscribe(e, _me.subscriber);
    });

    this.getData();
  }

  subscriber(event) {
    switch (event) {
      case ShiftUtils.Events.FeedbackAdded:
      case ShiftUtils.Events.ShiftsDisapproved:
        this.setState({ recordIdsSelected: [] }, () => {
          this.getData();
        });
        break;

      case ShiftUtils.Events.ShiftsApproved:
        this.getData();
        break;

      default:
        break;
    }
  }

  setIsGetting(val, lastGet) {
    let newState = { isGetting: val };
    if (lastGet) {
      newState['lastGet'] = lastGet;
    }
    this.setState(newState);
  }

  handleSearchClick() {
    this.getData();
  }

  async getData() {
    const model = AppUtils.getDataModel($('#ApprovedFilterContainer'));

    this.setIsGetting(true);

    const data = await getApprovedShifts(model);
    if (data.records) {
      this.setState({ records: data.records });
    }
    this.setIsGetting(false, new Date());
  }

  handleCheckboxChange(id) {
    console.log(`handleCheckboxChange`, id);

    if (id === 'all') {
      return this.handleCheckboxSelectAllClick();
    }

    let recordIdsSelected = [...this.state.recordIdsSelected];
    const index = recordIdsSelected.indexOf(id);
    if (index > -1) {
      recordIdsSelected.splice(index, 1);
    } else {
      recordIdsSelected.push(id);
    }
    this.setState({ recordIdsSelected }, this.processUiUpdates);
  }

  handleCheckboxSelectAllClick() {
    const getRecordIdsToBeSelected = () => {
      if (this.state.recordIdsSelected.length === this.state.records.length) {
        return [];
      }
      return this.state.records.map((record) => record.shiftId);
    };
    this.setState(
      { recordIdsSelected: getRecordIdsToBeSelected() },
      this.processUiUpdates
    );
    return;
  }

  processUiUpdates() {
    const $table = $(`#${tableId}`);
    $table.find('.selected').removeClass('selected');
    $table.find('input[type="checkbox"]').prop('checked', false);
    $table.find('#checkbox_approved_all').prop('checked', false);

    this.state.recordIdsSelected.forEach((recordId) => {
      const $checkbox = $(`#checkbox_${recordId}`);
      $checkbox.prop('checked', true);
      $checkbox.closest('tr').addClass('selected');
    });

    if (
      this.state.records.length > 0 &&
      this.state.records.length === this.state.recordIdsSelected.length
    ) {
      $table.find('#checkbox_approved_all').prop('checked', true);
    }
  }

  render() {
    return (
      <>
        <Alert bsStyle='success'>
          <Icon icon='fa-check' /> Shifts which have been Completed and the
          shift times have been approved.
        </Alert>
        <Filters {...this.state} handleSearchClick={this.handleSearchClick} />
        {this.state.error ? (
          <Alert bsStyle='danger'>{this.state.error}</Alert>
        ) : null}
        <TableContainer
          {...this.state}
          handleCheckboxChange={this.handleCheckboxChange}
        />
      </>
    );
  }
}

const Filters = (props) => {
  return (
    <Panel id='ApprovedFilterContainer'>
      <Panel.Body>
        <Row>
          <DatePicker
            columns={4}
            name='FromDate'
            label='Date From'
            value={AppUtils.getDateString(moment().subtract(30, 'days'))}
            required
            notInFormGroup
          />
          <DatePicker
            columns={4}
            name='ToDate'
            label='Date To'
            value={AppUtils.getDateString(moment())}
            required
            notInFormGroup
          />
          <Col sm={4}>
            <label className='control-label block'>&nbsp;</label>
            <Checkbox
              id='RestrictToShiftsWithNoFeedback'
              title='Only Show Shifts with no Feedback'
              style={{ height: '34px', paddingTop: '10px' }}
              notInFormGroup
            />
          </Col>
        </Row>
      </Panel.Body>
      <Panel.Footer className='text-right'>
        <SearchButton
          isGetting={props.isGetting}
          handleSearchClick={props.handleSearchClick}
        />
      </Panel.Footer>
    </Panel>
  );
};

const SearchButton = ({ isGetting, handleSearchClick }) => {
  return (
    <Button
      bsStyle='primary'
      bsSize='sm'
      onClick={handleSearchClick}
      disabled={isGetting}
    >
      <Icon icon='fa-search' isSpinning={isGetting} />{' '}
      {isGetting ? 'Searching' : 'Search'}
    </Button>
  );
};

const TableContainer = (props) => {
  if (props.isGetting) {
    return (
      <WellSmall className='m-b-none' hasSpinner>
        Searching for Approved Shifts...
      </WellSmall>
    );
  }
  if (props.records.length <= 0 && !props.lastGet) {
    return null;
  }
  if (props.records.length <= 0) {
    return (
      <WellSmall className='m-b-none'>No Approved Shifts found.</WellSmall>
    );
  }
  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col sm={6}>Results</Col>
          <Col sm={6} className='text-right'>
            <DisapproveButton {...props} />
            <AddFeedbackButton {...props} />
          </Col>
        </Row>
      </Panel.Heading>
      <Panel.Body>
        <Summary {...props} />
        <Table {...props} />
      </Panel.Body>
    </Panel>
  );
};

const Summary = ({ records }) => {
  if (records.length <= 0) {
    return null;
  }
  const total = AppUtils.formatNumber(
    records.reduce((total, record) => total + record.chargeTotalIncludingVat, 0)
  );
  const containerStyle = { marginBottom: '0' };
  const getContent = (value) => (
    <h1 className='no-margins' style={{ color: Constants.Colours.primary }}>
      {value}
    </h1>
  );
  return (
    <Row
      style={{
        background: '#efefef',
        marginTop: '-10px',
        marginBottom: '10px',
        padding: '10px 0',
      }}
    >
      <Col sm={3}>
        <DashboardPanel
          containerStyle={containerStyle}
          title='Total Cost'
          content={getContent(`£${total}`)}
        />
      </Col>
      <Col sm={3}>
        <DashboardPanel
          containerStyle={containerStyle}
          title='Total Shift Qty'
          content={getContent(records.length)}
        />
      </Col>
    </Row>
  );
};

const tableId = 'approved-shifts-table';
class Table extends Component {
  componentDidMount() {
    this.initDataTables();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.lastGet !== this.props.lastGet) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillUpdate() {
    this.destroyDataTables();
  }

  componentDidUpdate() {
    this.initDataTables();
  }

  componentWillUnmount() {
    this.destroyDataTables();
  }

  initDataTables() {
    if (this.props.records.length > 0) {
      ListViewUtils.init(tableId);
    }
  }

  destroyDataTables() {
    const $table = $('#' + tableId);
    if ($table.length) {
      $table.DataTable().destroy();
    }
  }

  render() {
    if (this.props.records.length <= 0) {
      return (
        <WellSmall className='m-b-none'>No Approved shifts found.</WellSmall>
      );
    }

    const isRecordSelected = (id) =>
      this.props.recordIdsSelected.indexOf(id) > -1;
    const areAllSelected =
      this.props.records.length > 0 &&
      this.props.records.length === this.props.recordIdsSelected.length;

    return (
      <table
        className='table table-condensed table-striped'
        id={tableId}
        data-order='[[2, "desc"]]'
      >
        <thead>
          <tr>
            <th className='no-sort'>
              <Checkbox
                id='checkbox_approved_all'
                defaultChecked={areAllSelected}
                onChange={() => this.props.handleCheckboxChange('all')}
              />
            </th>
            <th>Shift</th>
            <th>When</th>
            <th>Worker</th>
            <th>
              Times
              <i
                className='fa fa-info-circle m-l text-primary'
                data-toggle='tooltip'
                title='These are the approved times'
              />
            </th>
            <th>Approved</th>
            <th>Feedback</th>
          </tr>
        </thead>
        <tbody>
          {this.props.records.map((item, i) => (
            <TableRow
              key={i}
              record={item}
              isSelected={isRecordSelected(item.shiftId)}
              handleCheckboxChange={this.props.handleCheckboxChange}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

const TableRow = ({ record, isSelected, handleCheckboxChange }) => {
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  return (
    <tr className={isSelected ? `selected` : null}>
      <td>
        <Checkbox
          id={`checkbox_${record.shiftId}`}
          defaultChecked={isSelected}
          onChange={() => handleCheckboxChange(record.shiftId)}
        />
      </td>
      <td>
        {record.name}
        <br />
        <SmallMuted>{record.agencyName}</SmallMuted>
      </td>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime)}
      </td>
      <td>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {record.workerHasImage && (
            <WorkerImage
              workerId={record.workerId}
              firstName={record.workerFirstName}
              surname={record.workerSurname}
              mobileNumber={record.workerMobileNumber}
            />
          )}
          <div>
            {record.workerFirstName} {record.workerSurname}
            <br />
            <PaidStatusBadge record={record} />
          </div>
        </div>
      </td>
      <td>
        {AppUtils.formatTime(record.shiftTimeStartActualConfirmed)} -{' '}
        {AppUtils.formatTime(record.shiftTimeEndActualConfirmed)}
      </td>
      <td data-order={record.approvedDateTime}>
        {AppUtils.formatDateTimeUtc(record.approvedDateTime)}
        <br />
        <SmallMuted>by {record.approvedBy}</SmallMuted>
      </td>
      <td data-order={record.feedbackScore}>
        <StarRatingComponent
          name='Score'
          value={record.feedbackScore}
          renderStarIcon={() => <Icon icon='fa-star' />}
          editing={false}
        />
      </td>
    </tr>
  );
};
