import { CSSProperties, ReactNode } from 'react';

export const IconContainer = ({
  children,
  style,
  small,
}: {
  children: ReactNode;
  style?: CSSProperties;
  small?: boolean;
}) => (
  <span style={{ fontSize: small ? '1.1em' : '1.3em', ...style }}>
    {children}
  </span>
);
