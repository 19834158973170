import { Alert } from 'react-bootstrap';
import { extractErrorMessages } from 'utils';

export const ErrorAlertForQueryOrNull = ({
  isError,
  error,
  style,
  className,
}: {
  isError: boolean;
  error: unknown;
  style?: React.CSSProperties;
  className?: string;
}) => {
  if (!isError) {
    return null;
  }

  return (
    <Alert bsStyle='danger' style={style} className={className}>
      <strong>Error!</strong> {extractErrorMessages(error)}
    </Alert>
  );
};
