import { Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { SpinIcon, WellSmall } from 'pages/Utils/react-utils';
import { getWorkerProfile } from 'utils/api';
import { WorkerProfile } from './WorkerProfile';

export const Profile = () => {
  const params = useParams();
  return <Main workerId={params?.workerId} />;
};

const Main = ({ workerId }) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['workerProfile', workerId],
    () => getWorkerProfile(workerId)
  );
  const record = data?.record;

  if (!workerId) {
    return <Alert bsStyle='danger'>Could not get Worker id</Alert>;
  }

  if ((isLoading || isFetching) && !record) {
    return (
      <WellSmall className='m-t'>
        <SpinIcon /> Getting Profile...
      </WellSmall>
    );
  }
  if (!record) {
    return (
      <Alert bsStyle='danger'>
        Could not get Profile for Worker with id: {workerId}
      </Alert>
    );
  }
  return <WorkerProfile record={record} refetch={refetch} />;
};

export default Profile;
