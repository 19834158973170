import { ModalContent } from 'components/Planner/ModalContent';
import { useShiftMenuModalContext } from 'components/Planner/ShiftMenuModalContext';
import { ShiftSegments } from 'components/ShiftSegments';

export const ViewShiftSegments = () => {
  return (
    <ModalContent
      title='View Shift Locations'
      Body={<ViewShiftSegmentsBody />}
    />
  );
};

const ViewShiftSegmentsBody = () => {
  const {
    shiftToHandle: { status, shiftId, hirerId },
  } = useShiftMenuModalContext();

  return <ShiftSegments hirerId={hirerId} shiftId={shiftId} status={status} />;
};
