import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { useSendMessageMutation } from 'hooks/queries/shiftPlanningQueries';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon } from 'pages/Utils/react-utils';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';

export const SendMessageModal = ({
  isModalOpen,
  handleModalClose,
  hirerId,
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
  hirerId: string;
}) => {
  const sendMessageMutation = useSendMessageMutation();

  const handleSubmitClick = () => {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }

    const dataModel = AppUtils.getDataModel($modal) as {
      subject: string;
      message: string;
    };

    sendMessageMutation.mutate(
      {
        hirerId: hirerId,
        ...dataModel,
      },
      {
        onSuccess: () => {
          AppUtils.displaySuccess('Success', 'Message sent successfully');
          handleModalClose();
        },
      }
    );
  };

  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Send Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert type='info'>
          Your message will be sent to all workers who have shifts in the plan.
        </Alert>

        <Row>
          <Field.InCol columns={12} name='subject' label='Subject' required />
        </Row>
        <Row>
          <Field.InCol
            columns={12}
            name='message'
            label='Message'
            required
            isTextArea
          />
        </Row>

        {sendMessageMutation.isError ? (
          <Row>
            <Col xs={12}>
              <ErrorAlert
                msg={extractErrorMessages(sendMessageMutation.error)}
              />
            </Col>
          </Row>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>
          <i className='fa fa-times' /> Close
        </Button>
        <ModalSaveButton
          isSaved={sendMessageMutation.isSuccess}
          isSaving={sendMessageMutation.isLoading}
          onClick={handleSubmitClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

const ModalSaveButton = ({
  isSaving,
  isSaved,
  onClick,
}: {
  isSaving: boolean;
  isSaved: boolean;
  onClick: () => void;
}) => {
  const isDisabled = isSaving || isSaved;
  const buttonTitle = isSaving
    ? 'Sending...'
    : isSaved
    ? 'Sent'
    : 'Send Message';
  return (
    <Button bsStyle='success' onClick={onClick} disabled={isDisabled}>
      <Icon icon='fa-check' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};
