import { requireAuth, useAuth } from 'utils/auth';
import AdminHome from './Admin/AdminHome';
import AgencyHome from './Agency/AgencyHome';
import HirerHome from './Hirer/HirerHome';

const IndexPage = () => {
  const auth = useAuth();
  const { record } = auth.user;

  if (record.isAdminUser) {
    return <AdminHome />;
  }

  if (record.isAgencyUser) {
    return <AgencyHome />;
  }

  if (record.isHirerUser) {
    return <HirerHome />;
  }

  console.error('Could not calculate home page for user', { auth });
  return <h1>Could not calculate home page for user</h1>;
};

export default requireAuth(IndexPage);
