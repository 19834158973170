import { ReactNode, useState } from 'react';
import { Button, Modal, Row, Col, Alert } from 'react-bootstrap';
import {
  useArchiveTrailerMutation,
  useCreateTrailerMutation,
  useEditTrailerMutation,
} from 'hooks/queries/trailerQueries';
import $ from 'jquery';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { Trailer } from 'types/TrailerTypes';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { Stack } from 'components/Stack';
import { TrailerUtils } from './TrailerUtils';

export const TrailerModal = (props: {
  showModal: boolean;
  handleCloseModalClick: () => void;
  record?: Trailer;
}) => {
  const record = props.record || ({} as Trailer);
  const createTrailer = useCreateTrailerMutation();
  const editTrailer = useEditTrailerMutation();

  const handleSubmitClick = () => {
    const handleSuccess = () => {
      AppUtils.displaySuccess('Done', 'Trailer saved');
      props.handleCloseModalClick();
      PubSub.publish(TrailerUtils.Events.TrailerSaved);
    };
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    const model = AppUtils.getDataModel($modal) as Trailer;

    if (record?.trailerId) {
      model['trailerId'] = record.trailerId;
      editTrailer.mutate(model, {
        onSuccess: () => {
          handleSuccess();
        },
      });
    } else {
      createTrailer.mutate(model, {
        onSuccess: () => {
          handleSuccess();
        },
      });
    }
  };

  const archiveTrailer = useArchiveTrailerMutation();
  const handleArchiveConfirmClick = record.trailerId
    ? () => {
        if (!record.trailerId) {
          AppUtils.displayError('Error', 'Could not find trailer id');
          return;
        }

        archiveTrailer.mutate(
          { trailerId: record.trailerId },
          {
            onSuccess: () => {
              AppUtils.displaySuccess('Done', 'Trailer archived');
              props.handleCloseModalClick();
              PubSub.publish(TrailerUtils.Events.TrailerSaved);
            },
          }
        );
      }
    : null;

  const isNew = !props.record;
  const isLoading = editTrailer.isLoading || createTrailer.isLoading;
  const error = editTrailer.error || createTrailer.error;
  const isError = !!error;

  return (
    <Modal
      backdrop='static'
      show={props.showModal}
      onHide={props.handleCloseModalClick}
    >
      <Modal.Header closeButton>
        <Modal.Title>{isNew ? 'Create' : 'Edit'} Trailer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Field.InCol
            columns={6}
            name='reference'
            label='Reference'
            value={record.reference}
            maxLength='30'
            required
          />
        </Row>

        <Row>
          <NumberField
            record={record}
            name='damageAllowancePerDay'
            label='Damage Allowance'
          />
          <NumberField
            record={record}
            name='depreciationAllowancePerDay'
            label='Depreciation Allowance'
          />
        </Row>

        <Row>
          <NumberField
            record={record}
            name='fridgeFuelCostPerDay'
            label='Fridge Fuel Cost'
          />
          <NumberField
            record={record}
            name='insuranceCostPerDay'
            label='Insurance Cost'
          />
        </Row>

        <Row>
          <NumberField
            record={record}
            name='maintenanceCostPerDay'
            label='Trailer Maintenance Cost'
          />
          <NumberField
            record={record}
            name='rentalCostPerDay'
            label='Rental Cost'
          />
        </Row>

        <Row>
          <NumberField
            record={record}
            name='trackingCostPerDay'
            label='Tracking Cost'
          />
          <NumberField
            record={record}
            name='washAllowancePerDay'
            label='Wash Allowance'
          />
        </Row>

        <ErrorAlertForQueryOrNull error={error} isError={isError} />
      </Modal.Body>
      <FooterMain
        handleArchiveConfirmClick={handleArchiveConfirmClick}
        isArchiving={archiveTrailer.isLoading}
        handleSubmitClick={handleSubmitClick}
        closeButton={
          <Button onClick={props.handleCloseModalClick}>
            <Icon icon='fa-times' /> Cancel
          </Button>
        }
        isNew={isNew}
        isLoading={isLoading}
      />
    </Modal>
  );
};

type SaveButtonProps = {
  isNew: boolean;
  isLoading: boolean;
  handleSubmitClick: () => void;
};

type FooterMainProps = {
  handleArchiveConfirmClick: () => void | null;
  isArchiving: boolean;
  closeButton: ReactNode;
} & SaveButtonProps;

const FooterMain = (props: FooterMainProps) => {
  const { closeButton, ...rest } = props;
  const [isArchiveUiShowing, setIsArchiveUiShowing] = useState(false);
  const handleArchiveClick = () => {
    setIsArchiveUiShowing(true);
  };

  return (
    <Modal.Footer>
      {!isArchiveUiShowing ? (
        <Stack
          direction='row'
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <div>
            {props.handleArchiveConfirmClick ? (
              <Button
                bsStyle='danger'
                onClick={handleArchiveClick}
                disabled={props.isLoading}
              >
                <Icon icon='fa-times' isSpinning={props.isLoading} /> Archive
              </Button>
            ) : null}
          </div>
          <Row>
            <Col sm={12}>
              {props.closeButton}
              <SaveButton {...rest} />
            </Col>
          </Row>
        </Stack>
      ) : null}

      {isArchiveUiShowing ? (
        <Alert bsStyle='danger' style={{ textAlign: 'left' }}>
          Are you sure you want to archive this trailer?
          <Stack direction='row' style={{ marginTop: 10 }}>
            <Button
              onClick={() => {
                setIsArchiveUiShowing(false);
              }}
              disabled={props.isArchiving}
            >
              <Icon icon='fa-times' /> Cancel
            </Button>
            <Button
              bsStyle='danger'
              onClick={props.handleArchiveConfirmClick}
              disabled={props.isArchiving}
            >
              <Icon icon='fa-check' isSpinning={props.isArchiving} /> Yes,
              Archive
            </Button>
          </Stack>
        </Alert>
      ) : null}
    </Modal.Footer>
  );
};

const NumberField = ({
  record,
  name,
  label,
}: {
  record: Trailer;
  name: keyof Trailer;
  label: string;
}) => {
  return (
    <Field.InCol
      columns={6}
      name={name}
      label={label}
      value={AppUtils.formatNumber(record[name])}
      maxLength='9'
      isNumber
      addOnPre='£'
      addOnPost={'Per Day'}
      required
    />
  );
};

const SaveButton = (props: SaveButtonProps) => {
  const buttonTitle = `${props.isNew ? 'Creat' : 'Sav'}${
    props.isLoading ? 'ing...' : 'e Trailer'
  }`;

  return (
    <Button
      bsStyle='success'
      onClick={props.handleSubmitClick}
      disabled={props.isLoading}
    >
      <Icon icon='fa-check' isSpinning={props.isLoading} /> {buttonTitle}
    </Button>
  );
};
