import { Component } from 'react';
import { Alert, Button, Modal, Row, Well } from 'react-bootstrap';
import $ from 'jquery';
import PubSub from 'pubsub-js';
import { getChangeOnCallUserConfig, updateOnCallUser } from 'utils/api';
import { AppUtils } from '../Utils/app-utils';
import { Field, Icon, SpinIcon, WellSmall } from '../Utils/react-utils';
import { AgencyUtils } from './AgencyUtils';

export class ChangeOnCallUserButton extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, isSaving: false };
    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  handleShowModalClick() {
    this.setState({ showModal: true });
  }

  handleCloseModalClick() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <>
        <Button
          bsStyle='success'
          bsSize='xs'
          onClick={this.handleShowModalClick}
        >
          <Icon icon='fa-phone' /> Change
        </Button>
        <ChangeOnCallUserModal
          {...this.state}
          {...this.props}
          handleCloseModalClick={this.handleCloseModalClick}
        />
      </>
    );
  }
}

class ChangeOnCallUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();

    this.onEntered = this.onEntered.bind(this);
    this.onExited = this.onExited.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  defaultState() {
    return {
      isSaving: false,
      isSaved: false,
      isGettingConfig: false,
      hasFinishedGettingConfig: false,
      configuration: null,
    };
  }

  async onEntered() {
    const me = this;
    me.setState({ isSaved: false, isGettingConfig: true, configuration: null });
    const data = await getChangeOnCallUserConfig();
    AppUtils.handleAjaxDone(data, () => {
      if (data.configuration) {
        me.setState({ configuration: data.configuration });
      } else {
        AppUtils.displayError('', 'Could not get configuration', true);
      }
    });
    me.setState({ isGettingConfig: false, hasFinishedGettingConfig: true });
  }

  onExited() {
    this.setState(this.defaultState());
  }

  async handleSubmitClick() {
    const me = this;
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);

    me.setState({ isSaving: true });
    AppUtils.clearNotifications();
    const data = await updateOnCallUser(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Success', 'On call user changed');
      me.setState({ isSaved: true });
      setTimeout(() => me.props.handleCloseModalClick(), 500);
      PubSub.publish(AgencyUtils.Events.OnCallUserChanged, data);
    });
    me.setState({ isSaving: false });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.showModal}
        onHide={this.props.handleCloseModalClick}
        onEntered={this.onEntered}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change On Call User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.state} {...this.props} />
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize='sm' onClick={this.props.handleCloseModalClick}>
            <i className='fa fa-times' /> Close
          </Button>
          <ModalSaveButton
            {...this.props}
            {...this.state}
            onClick={() => this.handleSubmitClick()}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = (props) => {
  const { isGettingConfig, configuration, hasFinishedGettingConfig, isSaved } =
    props;
  if (isGettingConfig) {
    return (
      <WellSmall className='m-b-none'>
        <SpinIcon /> Getting configuration options...
      </WellSmall>
    );
  }
  if (!configuration && hasFinishedGettingConfig) {
    return (
      <Alert bsStyle='danger' className='m-b-none'>
        <Icon icon='fa-times' /> Could not get configuration options
      </Alert>
    );
  }
  if (!configuration) {
    return null;
  }
  if (isSaved) {
    return <SavedUi isSaved={isSaved} />;
  }

  return (
    <>
      <Well>
        {`Whoever is listed as the "On Call User" will receive email notifications around the workflow of a Shift e.g. if someone doesn't start their Shift on time etc.`}
      </Well>
      <Row>
        <Field.InCol
          columns={12}
          name='UserAccountId'
          label='User'
          value={props.onCallUserAccountId}
          options={configuration.userOptions}
          isSelect
          required
        />
      </Row>
    </>
  );
};

const SavedUi = ({ isSaved }) =>
  !isSaved ? null : (
    <WellSmall className='text-success m-b-none'>
      <Icon icon='fa-check' /> User changed!
    </WellSmall>
  );

const ModalSaveButton = (props) => {
  if (!props.configuration) {
    return null;
  }
  const isDisabled = props.isSaving || props.isSaved;
  const buttonTitle = props.isSaving
    ? 'Saving...'
    : props.isSaved
    ? 'Saved'
    : 'Save';
  return (
    <Button
      bsSize='sm'
      bsStyle='success'
      onClick={props.onClick}
      disabled={isDisabled}
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
