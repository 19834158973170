import { Component } from 'react';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import $ from 'jquery';
import { DatePicker } from 'pages/Utils/DatePicker';
import { TimeDropdown } from 'pages/Utils/TimeDropdown';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall, SmallMuted } from 'pages/Utils/react-utils';
import { updateShiftTime } from 'utils/api';
import { ApiStatus } from 'utils/apiUtils';
import { ShiftUtils } from '../ShiftUtils';

const canTimeBeChanged = (record) => {
  if (ShiftUtils.isOpen(record) || ShiftUtils.isAllocated(record)) {
    return true;
  }
  return false;
};

export class ChangeShiftTimeButton extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  handleShowModalClick() {
    this.setState({ showModal: true });
  }

  handleCloseModalClick() {
    this.setState({ showModal: false });
  }

  render() {
    if (!canTimeBeChanged(this.props.record)) {
      return null;
    }

    return (
      <>
        <Button
          bsStyle='primary'
          onClick={this.handleShowModalClick}
          bsSize='xs'
          className='m-r-xs pull-right'
        >
          <Icon icon='fa-clock' /> Change...
        </Button>
        <ChangeShiftTimeModal
          {...this.state}
          {...this.props}
          handleCloseModalClick={this.handleCloseModalClick}
        />
      </>
    );
  }
}

class ChangeShiftTimeModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();

    this.onExited = this.onExited.bind(this);
    this.handleChangeTimeClick = this.handleChangeTimeClick.bind(this);
  }

  defaultState() {
    return {
      isSaving: false,
      isSaved: false,
    };
  }

  onExited() {
    this.setState(this.defaultState());
  }

  async handleChangeTimeClick() {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);
    model['ShiftId'] = this.props.record.shiftId;

    this.setState({ isSaving: true });
    AppUtils.clearNotifications();
    const data = await updateShiftTime(model);
    if (data.status === ApiStatus.Success) {
      AppUtils.displaySuccess('Success', 'Shift Time Changed');
      this.setState({ isSaved: true });
      setTimeout(() => this.props.handleCloseModalClick(), 500);
      AppUtils.publish(ShiftUtils.Events.ShiftTimeChanged, data);
    }
    this.setState({ isSaving: false });

    // $.ajax({
    //   type: "POST",
    //   url: getBaseUrl() + "Shift/ChangeTime",
    //   data: JSON.stringify(model),
    // })
    //   .done(function (data) {
    //     AppUtils.handleAjaxDone(data, () => {
    //       AppUtils.displaySuccess("Success", "Shift Time Changed");
    //       me.setState({ isSaved: true });
    //       setTimeout(() => me.props.handleCloseModalClick(), 500);
    //       AppUtils.publish(ShiftUtils.Events.ShiftTimeChanged, data);
    //     });
    //   })
    //   .fail(function (xhr) {
    // AppUtils.handleXhrFail(xhr);
    //   })
    //   .always(function () {
    //     me.setState({ isSaving: false });
    //   });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.showModal}
        onHide={this.props.handleCloseModalClick}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Shift Time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.props} {...this.state} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.handleCloseModalClick} bsSize='sm'>
            <i className='fa fa-times' /> Close
          </Button>
          <ModalSaveButton
            {...this.props}
            {...this.state}
            onClick={this.handleChangeTimeClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = (props) => {
  const { isSaved, record } = props;
  if (isSaved) {
    return <SavedUi isSaved={isSaved} />;
  }

  const postFieldComments =
    record.status === ShiftUtils.Status.Open
      ? 'these comments will be sent to all workers who have applied for this shift.'
      : record.status === ShiftUtils.Status.Allocated
      ? 'these comments will be sent to the allocated workers.'
      : '';

  return (
    <>
      <Alert bsStyle='info'>
        To change the time, please select the new time below. The new time must
        be within 4 hours of the current start time. If you want to choose a new
        time which is more than 4 hours different then please cancel this shift
        and create a new one.
      </Alert>
      <Row>
        <DatePicker
          columns={12}
          name='ShiftDate'
          label='Shift Date'
          value={AppUtils.formatDateTimeNonUtc(
            record.shiftDate,
            'YYYY-MM-DD',
            'DD/MM/YYYY'
          )}
          required
        />
      </Row>
      <Row>
        <Col md={12}>
          <TimeDropdown
            name='ShiftTime'
            label='Shift Start Time'
            placeholder='Choose Shift Start Time...'
            value={record.shiftTime}
            required
          />
        </Col>
      </Row>
      <Row>
        <Field.InCol
          columns={12}
          name='Comments'
          label='Comments about why the time is being changed'
          isTextArea
          required
          postFieldComponent={
            <SmallMuted>**Please note** {postFieldComments}</SmallMuted>
          }
        />
      </Row>
      <WellSmall>Are you sure you want to proceed?</WellSmall>
    </>
  );
};

const SavedUi = () => (
  <WellSmall className='text-success m-b-none'>
    <Icon icon='fa-check' /> Shift time changed!
  </WellSmall>
);

const ModalSaveButton = (props) => {
  const isDisabled = props.isSaving || props.isSaved;
  const buttonTitle = props.isSaving
    ? 'Changing Time...'
    : props.isSaved
    ? 'Shift Time Saved'
    : 'Yes, Change Time';
  return (
    <Button
      bsStyle='primary'
      onClick={props.onClick}
      disabled={isDisabled}
      bsSize='sm'
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
