import { ReactNode } from 'react';
import { Alert, Panel, Row, Col, Button } from 'react-bootstrap';
import {
  CellProps,
  Column,
  HeaderProps,
  UseSortByColumnOptions,
} from 'react-table';
import { useQueryClient } from '@tanstack/react-query';
import { FormLabel } from 'components';
import { useSelectedCustomer } from 'hooks';
import { useGetApprovedShiftsQuery } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import useFilterData from 'hooks/useFilterData';
import { useListViewCheckbox } from 'hooks/useListViewCheckbox';
import useSubscription from 'hooks/useSubscription';
import moment from 'moment';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { Checkbox } from 'pages/Utils/Checkbox';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils, Constants } from 'pages/Utils/app-utils';
import {
  DashboardPanel,
  Icon,
  SmallInfo,
  SmallMuted,
} from 'pages/Utils/react-utils';
import { ICustomEvent } from 'types/GeneralTypes';
import { Shift } from 'types/ShiftTypes';
import { userPermissions, usePermissions } from 'utils/userPermissions';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { Table } from 'components/Table';
import { AuthoriseSelectedButton } from './AuthoriseSelectedButton';
import { usePaymentAdjustmentsModal } from './PaymentAdjustmentsModal';

type PaymentAuthListViewProps = {
  setDisplayShiftId: (shiftId: string) => void;
};

export const PaymentAuthListView = (props: PaymentAuthListViewProps) => {
  const { hasPermission } = usePermissions();
  const canView = hasPermission(userPermissions.shift.awaitingPaymentAuth.view);
  if (!canView) {
    return null;
  }
  return <PaymentAuthListViewMain {...props} />;
};

const _filterFieldPrefix = 'awaitingPaymentAuthFilter';

const PaymentAuthListViewMain = ({
  setDisplayShiftId,
}: PaymentAuthListViewProps) => {
  const { selectedCustomerId, renderCustomerSelect } = useSelectedCustomer();

  const { filterData, getDataModel, handleFilterChange } = useFilterData(
    _filterFieldPrefix,
    -30,
    0
  );

  const { isFetching, isSuccess, isError, error, data, refetch } =
    useGetApprovedShiftsQuery(
      { ...getDataModel(), hirerId: selectedCustomerId },
      {
        onSuccess: (data) => {
          handleNewRecords(data.records);
        },
      }
    );

  const queryClient = useQueryClient();
  useSubscription([ShiftUtils.Events.ShiftPaymentsAuthorised], () => {
    queryClient.invalidateQueries([QueryKeys.ApprovedShifts]);
    resetSelectedRecords();
  });

  const handleSearchClick = () => {
    refetch();
  };

  const records = data?.records ?? [];

  const {
    resetSelectedRecords,
    recordIdsSelected,
    selectedTotal,
    handleCheckboxChange,
    handleNewRecords,
    areAllSelected,
  } = useListViewCheckbox({
    records,
    canRecordBeChecked: (shift) => {
      return (
        shift.paymentAmount !== null &&
        shift.paymentAuthRequestedDateTime === null
      );
    },
  });

  return (
    <>
      <Alert>
        All approved Shifts which have not yet been authorised for payment.
        <p></p>
        <p>
          {`Choose which shifts you want to authorise and press 'Authorise'.`}
        </p>
      </Alert>

      <Filters
        filterData={filterData}
        handleSearchClick={handleSearchClick}
        handleFilterChange={handleFilterChange}
        isFetching={isFetching}
        renderCustomerSelect={renderCustomerSelect}
      />

      <ErrorAlertForQueryOrNull isError={isError} error={error} />

      {isSuccess && records.length <= 0 ? (
        <Alert>No shifts found. Update the filters above and try again.</Alert>
      ) : null}

      {records.length > 0 ? (
        <Panel>
          <Panel.Heading>
            <Row>
              <Col sm={4}>Payment Authorisation Required</Col>
              <Col sm={8} className='text-right'>
                <AuthoriseSelectedButtonContainer
                  records={records}
                  recordIdsSelected={recordIdsSelected}
                  selectedTotal={selectedTotal}
                />
              </Col>
            </Row>
          </Panel.Heading>
          <Panel.Body>
            <Summary
              recordIdsSelected={recordIdsSelected}
              selectedTotal={selectedTotal}
              records={records}
            />
            <TableContainer
              records={records}
              recordIdsSelected={recordIdsSelected}
              handleCheckboxChange={handleCheckboxChange}
              setDisplayShiftId={setDisplayShiftId}
              areAllSelected={areAllSelected()}
            />
          </Panel.Body>
        </Panel>
      ) : null}
    </>
  );
};

type FilterState = {
  [key: string]: string;
};

const Filters = ({
  isFetching,
  handleSearchClick,
  filterData,
  handleFilterChange,
  renderCustomerSelect,
}: {
  isFetching: boolean;
  filterData: FilterState;
  handleFilterChange: (e: ICustomEvent) => void;
  handleSearchClick: () => void;
  renderCustomerSelect: () => ReactNode;
}) => {
  const handleChangeDate = (name: string, date: Date) =>
    handleFilterChange({
      target: { name, value: moment(date).format('YYYY-MM-DD') },
    });

  return (
    <Panel id='ApprovedFilterContainer'>
      <Panel.Body>
        <Row>
          <DatePicker
            columns={4}
            name={`${_filterFieldPrefix}FromDate`}
            label='Date From'
            value={AppUtils.getDateString(
              filterData[`${_filterFieldPrefix}FromDate`]
            )}
            changeDate={(e: { date: Date }) => {
              handleChangeDate(`${_filterFieldPrefix}FromDate`, e.date);
            }}
            required
            notInFormGroup
          />
          <DatePicker
            columns={4}
            name={`${_filterFieldPrefix}ToDate`}
            label='Date To'
            value={AppUtils.getDateString(
              filterData[`${_filterFieldPrefix}ToDate`]
            )}
            changeDate={(e: { date: Date }) => {
              handleChangeDate(`${_filterFieldPrefix}ToDate`, e.date);
            }}
            required
            notInFormGroup
          />
          <Col sm={4}>
            <FormLabel name='HirerId' label='Customer' />
            {renderCustomerSelect()}
          </Col>
        </Row>
      </Panel.Body>
      <Panel.Footer className='text-right'>
        <SearchButton
          isGetting={isFetching}
          handleSearchClick={handleSearchClick}
        />
      </Panel.Footer>
    </Panel>
  );
};

const SearchButton = ({
  isGetting,
  handleSearchClick,
}: {
  isGetting: boolean;
  handleSearchClick: () => void;
}) => {
  return (
    <Button
      bsStyle='primary'
      bsSize='sm'
      onClick={handleSearchClick}
      disabled={isGetting}
    >
      <Icon icon='fa-search' isSpinning={isGetting} />{' '}
      {isGetting ? 'Searching' : 'Search'}
    </Button>
  );
};

const AuthoriseSelectedButtonContainer = (props: {
  records: Shift[];
  recordIdsSelected: string[];
  selectedTotal: number;
}) => {
  // const { hasPermission } = usePermissions();
  // const canAuthorise = hasPermission(
  //   UserPermissions.shift.awaitingPaymentAuth.authorise
  // );
  // if (!canAuthorise) {
  //   return null;
  // }

  if (props.records.length <= 0) {
    return null;
  }

  return <AuthoriseSelectedButton {...props} />;
};

const Summary = ({
  records,
  recordIdsSelected,
  selectedTotal,
}: {
  records: Shift[];
  recordIdsSelected: string[];
  selectedTotal: number;
}) => {
  if (records.length <= 0) {
    return null;
  }
  const containerStyle = { marginBottom: '0' };
  const total = AppUtils.getCurrencyVal(
    records.reduce((total, record) => total + record.paymentAmount, 0)
  );

  const getContent = (value: string | number, isSelected = false) => (
    <h1
      className='no-margins'
      style={{
        color: isSelected
          ? Constants.Colours.success
          : Constants.Colours.primary,
      }}
    >
      {value}
    </h1>
  );
  return (
    <Row
      style={{
        background: '#efefef',
        marginTop: '-10px',
        marginBottom: '10px',
        padding: '10px 0',
      }}
    >
      <Col sm={3}>
        <DashboardPanel
          containerStyle={containerStyle}
          title='Total Amount'
          content={getContent(total)}
        />
      </Col>
      <Col sm={3}>
        <DashboardPanel
          containerStyle={containerStyle}
          title='Total Shift Qty'
          content={getContent(records.length)}
        />
      </Col>
      <Col sm={3}>
        <DashboardPanel
          containerStyle={containerStyle}
          title='Selected Amount'
          content={getContent(
            `${AppUtils.getCurrencyVal(selectedTotal)}`,
            true
          )}
        />
      </Col>
      <Col sm={3}>
        <DashboardPanel
          containerStyle={containerStyle}
          title='Selected Shift Qty'
          content={getContent(recordIdsSelected.length, true)}
        />
      </Col>
    </Row>
  );
};

const TableContainer = ({
  handleCheckboxChange,
  recordIdsSelected,
  records,
  setDisplayShiftId,
  areAllSelected,
}: {
  handleCheckboxChange: (id: string) => void;
  recordIdsSelected: string[];
  records: Shift[];
  setDisplayShiftId: (shiftId: string) => void;
  areAllSelected: boolean;
}) => {
  const { showPaymentAdjustmentsModal, renderPaymentAdjustmentsModal } =
    usePaymentAdjustmentsModal();
  const isRecordSelected = (id: string) => recordIdsSelected.indexOf(id) > -1;

  const columns: (Column<Shift> & UseSortByColumnOptions<Shift>)[] = [
    {
      accessor: 'shiftId',
      disableSortBy: true,
      width: '20px',
      Header: (headerProps: HeaderProps<Shift>) => {
        if (headerProps.data.every((shift) => shift.paymentAmount === null)) {
          return null;
        } else {
          return (
            <Checkbox
              id={`checkbox_all`}
              checked={areAllSelected}
              onChange={() => handleCheckboxChange('all')}
            />
          );
        }
      },
      Cell: (cellProps: CellProps<Shift>) => {
        const record = cellProps.row.original;
        return (
          <Checkbox
            disabled={
              record.paymentAmount === null ||
              record.paymentAuthRequestedDateTime !== null
            }
            id={`checkbox_${record.shiftId}`}
            checked={isRecordSelected(record.shiftId)}
            onChange={() => handleCheckboxChange(record.shiftId)}
          />
        );
      },
    },
    {
      Header: 'Shift',
      accessor: 'name',
      Cell: (cellProps: CellProps<Shift>) => (
        <>
          {cellProps.row.original.name}
          <div>
            <SmallInfo>{cellProps.row.original.companyName}</SmallInfo>
          </div>
          {cellProps.row.original.paymentAuthRequestedDateTime !== null ? (
            <div>
              <SmallMuted>
                Authorised:{' '}
                {AppUtils.formatDateTimeNonUtc(
                  cellProps.row.original.paymentAuthRequestedDateTime
                )}{' '}
                by {cellProps.row.original.paymentAuthRequestedBy}
              </SmallMuted>
            </div>
          ) : null}
        </>
      ),
    },
    {
      Header: 'When',
      accessor: (record: Shift) =>
        AppUtils.concatDateAndTime(record.shiftDate, record.shiftTime),
      Cell: ({ value }: { value: string }) =>
        AppUtils.formatDateTimeNonUtc(value),
    },
    {
      Header: 'Worker',
      accessor: (record: Shift) => record.workerSurname,
      Cell: (cellProps: CellProps<Shift>) => (
        <>
          {cellProps.row.original.workerFirstName}{' '}
          {cellProps.row.original.workerSurname}
        </>
      ),
    },
    {
      Header: 'Payment Amount £',
      accessor: (record: Shift) => record.paymentAmount,
      Cell: ({ value }: { value: string }) => (
        <span>{AppUtils.formatNumber(value)}</span>
      ),
    },
    {
      Header: 'Adjustments £',
      accessor: (record: Shift) => record.paymentAdjustmentAmount,
      Cell: ({ value }: { value: number }) => (
        <span
          style={{ color: value > 0 ? 'green' : value < 0 ? 'red' : 'black' }}
        >
          {AppUtils.formatNumber(value)}
        </span>
      ),
    },
    {
      id: 'open',
      accessor: (record: Shift) => record.shiftId,
      disableSortBy: true,
      Cell: (cellProps: CellProps<Shift>) => {
        const shift = cellProps.row.original;
        return (
          <>
            <Button
              className='pull-right'
              bsStyle='primary'
              bsSize='xs'
              onClick={() => setDisplayShiftId(shift.shiftId)}
            >
              Open &raquo;
            </Button>
            {shift.hasPaymentAdjustments ? (
              <Button
                title={`Please review the payment adjustments for this shift before authorising.`}
                data-toggle='tooltip'
                className='pull-right m-r-xs'
                bsStyle='danger'
                bsSize='xs'
                onClick={() => {
                  AppUtils.destroyTooltips();
                  showPaymentAdjustmentsModal(shift.shiftId);
                }}
              >
                <Icon icon='fa-exclamation' />
              </Button>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table columns={columns} records={records} />
      {renderPaymentAdjustmentsModal()}
    </>
  );
};
