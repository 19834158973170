import { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useRequestApprovalInvoiceMutation } from 'hooks/queries';
import { useModal } from 'hooks/useModal';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon } from 'pages/Utils/react-utils';
import { Invoice } from 'types/Invoice';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';

export const useRequestApprovalDialog = () => {
  const [invoice, setInvoice] = useState<Invoice | null>(null);

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  const showRequestApprovalDialog = (invoice: Invoice) => {
    setInvoice(invoice);
    handleModalOpen();
  };

  const renderRequestApprovalDialog = () => {
    if (!invoice) {
      return null;
    }

    return (
      <RequestApprovalDialog
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        onExited={() => setInvoice(null)}
        invoice={invoice}
      />
    );
  };

  return { showRequestApprovalDialog, renderRequestApprovalDialog };
};

const RequestApprovalDialog = ({
  isModalOpen,
  handleModalClose,
  onExited,
  invoice,
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
  onExited?: () => void;
  invoice: Invoice;
}) => {
  const history = useHistory();
  const requestApprovalRequest = useRequestApprovalInvoiceMutation();
  const handleRequestApprovalClick = () => {
    requestApprovalRequest.mutate(
      { invoiceId: invoice.invoiceId },
      {
        onSuccess: () => {
          AppUtils.publish('invoice.approvalRequested');
          AppUtils.displaySuccess('Thanks', 'Invoice Approval Requested.');
          handleModalClose();
          history.push('/Invoice');
        },
      }
    );
  };

  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Request Approval</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You are going to request approval for this invoice. Once requested, an
          email notification will be sent to the approver(s), the invoice will
          be locked and no further changes can be made.
        </p>

        <Alert bsStyle='info'>Are you sure you want to proceed?</Alert>

        {requestApprovalRequest.isError ? (
          <ErrorAlertForQueryOrNull
            isError={requestApprovalRequest.isError}
            error={requestApprovalRequest.error}
          />
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>Cancel</Button>
        <Button
          bsStyle='success'
          onClick={handleRequestApprovalClick}
          disabled={requestApprovalRequest.isLoading}
        >
          <Icon
            icon='fa-envelope'
            isSpinning={requestApprovalRequest.isLoading}
          />{' '}
          Yes, Request Approval
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
