import { Component } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { ShiftTemplateStatus } from 'types/ShiftConfigTypes';
import { updateShiftConfigStatus } from 'utils/api';
import { AppUtils } from '../Utils/app-utils';
import { Icon } from '../Utils/react-utils';
import { ShiftConfigEvents } from './ShiftConfigUtils';

export class ChangeShiftConfigStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChanging: false,
      isChanged: false,
    };

    this.handleChangeClick = this.handleChangeClick.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExited() {
    this.setState({ isChanged: false });
  }

  async handleChangeClick() {
    this.setState({ isChanging: true });
    AppUtils.clearNotifications();

    const data = await updateShiftConfigStatus(
      this.props.recordToChange.shiftConfigId,
      this.props.status
    );
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Status Changed');
      AppUtils.publish(ShiftConfigEvents.ShiftConfigStatusChanged, data);
      this.props.handleCloseModalClick();
    });

    this.setState({ isChanging: false });
  }

  render() {
    return (
      <Modal
        bsSize='sm'
        backdrop='static'
        show={!!this.props.recordToChange}
        onHide={this.props.handleCloseModalClick}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change to {this.props.status}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.props} {...this.state} />
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize='sm' onClick={this.props.handleCloseModalClick}>
            <i className='fa fa-times'></i> Cancel
          </Button>
          <ModalConfirmButton
            {...this.state}
            status={this.props.status}
            handleChangeClick={this.handleChangeClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = (props) => {
  if (!props.recordToChange) {
    return null;
  }
  return (
    <>
      <p>
        You have chosen to change the status to <strong>{props.status}</strong>.
      </p>
      <Alert
        bsStyle={props.status === ShiftTemplateStatus.Live ? 'info' : 'warning'}
      >
        Are you sure you want to proceed?
      </Alert>
    </>
  );
};

const ModalConfirmButton = (props) => {
  const isDisabled = props.isChanging || props.isChanged;
  const buttonTitle = props.isChanging
    ? 'Changing Status...'
    : `Yes, Change to ${props.status}`;
  return (
    <Button
      bsStyle={
        props.status === ShiftTemplateStatus.Live ? 'primary' : 'warning'
      }
      bsSize='sm'
      onClick={props.handleChangeClick}
      disabled={isDisabled}
    >
      <Icon
        icon={
          props.status === ShiftTemplateStatus.Live ? 'fa-check' : 'fa-undo-alt'
        }
        isSpinning={props.isChanging}
      />{' '}
      {buttonTitle}
    </Button>
  );
};
