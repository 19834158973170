import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useWorkerImageUrlQuery } from 'hooks/queries/agencyQueries';
import { Icon, SpinIcon } from '../Utils/react-utils';
import './WorkerImage.scss';

const WorkerProfileStatus = {
  Complete: 'Complete',
  Incomplete: 'Incomplete',
};

const isProfileComplete = (record) =>
  record.status === WorkerProfileStatus.Complete;

export const ProfileStatus = ({ record }) => {
  const className = `label label-${
    isProfileComplete(record) ? 'success' : 'danger'
  }`;
  return <span className={className}>{record.status}</span>;
};

const _grey = '#bbb';
export const WorkerFeedback = ({ record }) => {
  return (
    <>
      <span
        className='label'
        style={{ fontSize: '0.8em' }}
        data-toggle='tooltip'
        title={
          record.feedbackScore
            ? `Average feedback score of ${
                record.feedbackScore
              } (out of 5) from ${record.feedbackCount} Shift${
                record.feedbackCount > 1 ? 's' : ''
              }`
            : `No feedback yet`
        }
      >
        {!record.feedbackScore ? (
          <>
            <Icon icon='fa fa-star' colour={_grey} />{' '}
            <span style={{ color: _grey }}>{record.feedbackCount}</span>
          </>
        ) : (
          <>
            <span className='text-success'>{record.feedbackScore}</span>{' '}
            <Icon icon='fa fa-star' colour='#ffb400' />{' '}
            <span style={{ color: _grey }}>{record.feedbackCount}</span>
          </>
        )}
      </span>
    </>
  );
};

export const WorkerImage = ({
  workerId,
  firstName,
  surname,
  mobileNumber,
  style,
  isClickDisabled,
  imageUrl,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isModalShowing, setIsModalShowing] = useState(false);
  const handleShowModal = () =>
    isClickDisabled ? null : setIsModalShowing(true);
  const handleCloseModal = () => setIsModalShowing(false);

  const imageUrlQuery = useWorkerImageUrlQuery(workerId, imageUrl);

  const useStyle = {
    display: isLoaded ? 'inline' : 'none',
    cursor: !isClickDisabled ? 'pointer' : 'inherit',
    ...style,
  };

  if (isError) {
    return null;
  }

  const getImageUrl = imageUrl || imageUrlQuery.data?.imageUrl;

  return (
    <>
      {getImageUrl ? (
        <span className='worker-image-container'>
          <img
            src={getImageUrl}
            className='img-circle'
            style={useStyle}
            alt={`${firstName} ${surname}`}
            onClick={handleShowModal}
            onLoad={() => setIsLoaded(true)}
            onError={() => setIsError(true)}
          />
        </span>
      ) : null}
      {getImageUrl && !isLoaded ? (
        <SpinIcon style={{ marginRight: '3px' }} />
      ) : null}
      {!isLoaded ? null : (
        <Modal backdrop='static' show={isModalShowing}>
          <Modal.Body style={{ textAlign: 'center' }}>
            <h1>
              {firstName} {surname}
            </h1>
            {mobileNumber && (
              <h3>
                <Icon icon='fa-phone' /> {mobileNumber}
              </h3>
            )}
            <img
              src={getImageUrl}
              className='img-rounded'
              style={{ width: `300px`, cursor: `pointer` }}
              alt={`${firstName} ${surname}`}
              onClick={handleCloseModal}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCloseModal} bsSize='sm'>
              <i className='fa fa-times' /> Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
