import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { useModal } from 'hooks/useModal';
import useSubscription from 'hooks/useSubscription';
import { AgencyUtils } from 'pages/Agency/AgencyUtils';
import { Icon } from 'pages/Utils/react-utils';
import { getAgencyUsers } from 'utils/api';
import { useAlertWithRefreshButton } from 'components/AlertWithRefreshButton';
import DataTable from 'components/DataTable';
import { GettingRecordsMessage } from 'components/GettingRecordsMessage';
import { NoRecordsFoundMessage } from 'components/NoRecordsFoundMessage';
import { ViewPermissionsModal } from './Permissions';

const tableId = 'agency-users-table';

export const AgencyUserTable = () => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  const [selectedUserAccountId, setSelectedUserAccountId] = useState(null);
  const { data, isLoading, isFetching, refetch } = useQuery(
    [QueryKeys.AgencyUsers],
    getAgencyUsers
  );
  const records = data?.records || [];
  useSubscription([AgencyUtils.Events.UserSaved], refetch);

  const { alert, refreshButton } = useAlertWithRefreshButton(
    'Your agency users.',
    isLoading,
    isFetching,
    refetch
  );

  if (isLoading) {
    return <GettingRecordsMessage message='Getting users...' />;
  }
  if (records.length <= 0) {
    return (
      <NoRecordsFoundMessage
        records={records}
        message='No users found'
        refreshButton={refreshButton}
      />
    );
  }

  const handleViewPermissionClick = (userAccountId) => {
    setSelectedUserAccountId(userAccountId);
    handleModalOpen();
  };

  return (
    <>
      {alert}
      <DataTable
        tableId={tableId}
        records={records}
        render={() => {
          if (records.length <= 0) {
            return null;
          }
          return (
            <table
              className='table table-condensed table-striped'
              id={tableId}
              data-order='[[0, "asc"]]'
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Permissions</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {records.map((record, i) => (
                  <Row
                    key={i}
                    record={record}
                    handleViewPermissionClick={handleViewPermissionClick}
                  />
                ))}
              </tbody>
            </table>
          );
        }}
      />
      {isModalOpen ? (
        <ViewPermissionsModal
          userAccountId={selectedUserAccountId}
          handleCloseModal={handleModalClose}
        />
      ) : null}
    </>
  );
};

const Row = ({ record, handleViewPermissionClick }) => {
  return (
    <tr>
      <td data-order={record.surname}>
        {record.firstName} {record.surname}
      </td>
      <td>{record.emailAddress}</td>
      <td>{record.status}</td>
      <td>{record.permissions.length}</td>
      <td style={{ textAlign: 'right' }}>
        <Button
          bsSize='xs'
          bsStyle='primary'
          onClick={() => handleViewPermissionClick(record.userAccountId)}
        >
          <Icon icon='fa-lock' />
        </Button>
      </td>
    </tr>
  );
};
