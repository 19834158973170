import { useMutation, useQuery } from '@tanstack/react-query';
import { shiftApi } from 'apis/shiftApi';
import { isHgvShift } from 'pages/ShiftConfig/ShiftConfigUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { ShiftIndustryType } from 'types/ShiftConfigTypes';
import {
  AddNoteToShiftParams,
  AddPaymentAdjustmentToShiftParams,
  AuthoriseSingleShiftParams,
  CompleteShiftParams,
  GetApprovedShiftsParams,
  GetDailyViewQueryParams,
  GetPaidShiftsParams,
  GetPayrollReportParams,
  GetPayrollCsvParams,
  GetUnpaidShiftsParams,
  PaidShift,
  PayrollReportShift,
  Shift,
  UnpaidShift,
  UpdateVehicleParams,
  UnpaidContractorShift,
  LinkShiftParams,
  AddTrailerToShiftParams,
  RemoveTrailerFromShiftParams,
  ShiftNoteType,
  CalculatorParams,
} from 'types/ShiftTypes';
import { extractErrorMessages, handleDownloadBlob } from 'utils';
import { QueryKeys } from './queryUtils';
import { useGetTrailersActiveQuery } from './trailerQueries';
import { useGetVehiclesActiveQuery } from './vehicleQueries';

export const useNewShiftConfigQuery = () => {
  return useQuery({
    queryKey: [QueryKeys.NewShiftConfig],
    queryFn: () => {
      return shiftApi.getNewShiftConfigV2();
    },
  });
};

export const useWorkerOptions = (shiftIndustryType: ShiftIndustryType) => {
  const isHgvShiftIndustryType = isHgvShift(shiftIndustryType);
  const query = useNewShiftConfigQuery();
  const workerOptions =
    query?.data?.workerOptions.filter((worker) =>
      isHgvShiftIndustryType
        ? worker.canApplyForHgvShifts
        : !worker.canApplyForHgvShifts
    ) ?? [];

  return { query, workerOptions };
};
export const useVehicleOptions = () => {
  const query = useGetVehiclesActiveQuery();
  const vehicleOptions =
    query.data?.map((m) => ({ text: m.reg, value: m.vehicleId })) ?? [];

  const getVehicleReg = (vehicleId: string) => {
    const vehicle = query.data?.find((m) => m.vehicleId === vehicleId);
    return vehicle?.reg ?? '-';
  };

  return { query, vehicleOptions, vehicleData: query.data, getVehicleReg };
};

export const useTrailerOptions = () => {
  const query = useGetTrailersActiveQuery();
  const trailerOptions =
    query.data?.map((m) => ({ text: m.reference, value: m.trailerId })) ?? [];

  const getTrailerRef = (trailerId: string) => {
    const trailer = query.data?.find((m) => m.trailerId === trailerId);
    return trailer?.reference ?? '-';
  };

  return { query, trailerOptions, getTrailerRef };
};

export const useShiftTrailersQuery = (shiftId: string) => {
  return useQuery({
    queryKey: [QueryKeys.ShiftTrailers, shiftId],
    queryFn: () => {
      return shiftApi.getShiftTrailers(shiftId);
    },
  });
};

export const useAddTrailerToShiftMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: AddTrailerToShiftParams) => {
      return shiftApi.addTrailerToShift(params);
    },
  });

  return mutation;
};

export const useRemoveTrailerFromShiftMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: RemoveTrailerFromShiftParams) => {
      return shiftApi.removeTrailerFromShift(params);
    },
  });

  return mutation;
};

export const useShiftNotesQuery = (shiftId: string, type: ShiftNoteType) => {
  return useQuery({
    queryKey: [QueryKeys.ShiftNotes, shiftId, type],
    queryFn: () => {
      return shiftApi.getShiftNotes(shiftId, type);
    },
  });
};

export const useShiftFilesQuery = (shiftId: string) => {
  return useQuery({
    queryKey: [QueryKeys.ShiftFiles, shiftId],
    queryFn: () => {
      return shiftApi.getShiftFiles(shiftId);
    },
  });
};

export const useDownloadShiftFileQuery = (
  shiftId: string,
  shiftFileId: string
) => {
  return useQuery({
    queryKey: [QueryKeys.ShiftFile, shiftFileId],
    queryFn: () => {
      return shiftApi.getShiftFile(shiftId, shiftFileId);
    },
    enabled: false,
    retry: false,
    onSuccess: (response) => {
      handleDownloadBlob(response);
    },
    onError: (error) => {
      AppUtils.displayError(
        'Error',
        extractErrorMessages(error),
        false,
        'download-error'
      );
    },
  });
};

export const useDownloadAllShiftFilesQuery = (shiftId: string) => {
  return useQuery({
    queryKey: [QueryKeys.ShiftFiles, 'all'],
    queryFn: () => {
      return shiftApi.getShiftFilesZip(shiftId);
    },
    enabled: false,
    retry: false,
    onSuccess: (response) => {
      handleDownloadBlob(response);
    },
    onError: (error) => {
      AppUtils.displayError(
        'Error',
        extractErrorMessages(error),
        false,
        'download-error'
      );
    },
  });
};

export const useLinkedShiftsQuery = (shiftId: string) => {
  return useQuery({
    queryKey: [QueryKeys.LinkedShifts, shiftId],
    queryFn: () => {
      return shiftApi.getLinkedShifts(shiftId);
    },
  });
};

export const useShiftSegmentsQuery = (shiftId: string) => {
  return useQuery({
    queryKey: [QueryKeys.ShiftSegments, shiftId],
    queryFn: () => {
      return shiftApi.getShiftSegments(shiftId);
    },
  });
};

type ShiftLocationConfig = {
  hirerId: string;
  locations: ShiftCalculatorLocation[];
};

type ShiftCalculatorLocation = {
  locationId: string;
  name: string;
  postcode: string;
};

const shiftCalculatorLocationsKey = 'shiftCalculatorLocations';

const getParsedLocations = () => {
  const locations = localStorage.getItem(shiftCalculatorLocationsKey);

  const parsedLocations = (
    locations ? JSON.parse(locations) : []
  ) as ShiftLocationConfig[];

  return parsedLocations;
};

const getLocationsConfigForHirer = (hirerId: string) => {
  const parsedLocations = getParsedLocations();

  const locationForHirer = parsedLocations.find(
    (loc) => loc.hirerId === hirerId
  ) ?? {
    hirerId: hirerId,
    locations: [],
  };

  return locationForHirer;
};

const writeConfigForHirer = (configForHirer: ShiftLocationConfig) => {
  const parsedLocations = getParsedLocations();

  // Replace the existing location for the hirer
  const newLocations = parsedLocations.filter(
    (loc) => loc.hirerId !== configForHirer.hirerId
  );
  newLocations.push(configForHirer);

  localStorage.setItem(
    shiftCalculatorLocationsKey,
    JSON.stringify(newLocations)
  );
};

export const useShiftCalculatorLocationsQuery = (hirerId: string) => {
  return useQuery({
    queryKey: [QueryKeys.CalculatorLocations, hirerId],
    queryFn: () => {
      const config = getLocationsConfigForHirer(hirerId);
      return Promise.resolve(config.locations);
    },
  });
};

export const useSaveShiftCalculatorLocationMutation = () => {
  const mutation = useMutation({
    mutationFn: ({
      hirerId,
      locationId,
      locationName,
      postcode,
      isManual,
    }: {
      hirerId: string;
      locationId: string;
      locationName: string;
      postcode: string;
      isManual: boolean;
    }) => {
      const configForHirer = getLocationsConfigForHirer(hirerId);

      configForHirer.locations.push({
        locationId,
        name: locationName,
        postcode: postcode,
      });

      writeConfigForHirer(configForHirer);

      return Promise.resolve(true);
    },
  });

  return mutation;
};

export const useDeleteShiftCalculatorLocationMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: { hirerId: string; locationArrayIndex: number }) => {
      const configForHirer = getLocationsConfigForHirer(params.hirerId);
      configForHirer.locations.splice(params.locationArrayIndex, 1);
      writeConfigForHirer(configForHirer);

      return Promise.resolve();
    },
  });

  return mutation;
};

export const useCalculateMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: CalculatorParams) => {
      const config = getLocationsConfigForHirer(params.hirerId);
      if (config.locations.length === 0) {
        AppUtils.displayError('Error', 'Please add locations to calculate');
        return;
      }

      if (params.hirerId === 'NoCustomerSelected') {
        if (config.locations.length < 2) {
          AppUtils.displayError('Error', 'Please enter at least 2 locations');
          return;
        }

        params.hirerId = null;
      }

      return shiftApi.calculator({
        ...params,
        postcodes: config.locations.map((loc) => loc.postcode),
      });
    },
  });

  return mutation;
};

export const useGetApprovedShiftsQuery = (
  params: GetApprovedShiftsParams,
  options?: { onSuccess: (data: { records: Shift[] }) => void }
) => {
  return useQuery({
    queryKey: [QueryKeys.ApprovedShifts, params],
    queryFn: () => {
      return shiftApi.getApprovedShifts(params);
    },
    onSuccess: options?.onSuccess,
  });
};

export const useGetPaidShiftsQuery = (
  params: GetPaidShiftsParams,
  options?: { onSuccess: (data: PaidShift[]) => void }
) => {
  return useQuery({
    queryKey: [QueryKeys.PaidShifts, params],
    queryFn: () => {
      return shiftApi.getPaidShifts(params);
    },
    onSuccess: options?.onSuccess,
  });
};

export const useGetUnpaidShiftsQuery = (
  params: GetUnpaidShiftsParams,
  options?: { onSuccess: (data: { records: UnpaidShift[] }) => void }
) => {
  return useQuery({
    queryKey: [QueryKeys.UnpaidShifts, params],
    queryFn: () => {
      return shiftApi.getUnpaidShifts(params);
    },
    onSuccess: options?.onSuccess,
  });
};

export const useGetUnpaidContractorShiftsQuery = (
  params: GetUnpaidShiftsParams,
  options?: { onSuccess: (data: UnpaidContractorShift[]) => void }
) => {
  return useQuery({
    queryKey: [QueryKeys.UnpaidContractorShifts, params],
    queryFn: () => {
      return shiftApi.getUnpaidContractorShifts(params);
    },
    onSuccess: options?.onSuccess,
  });
};

export const useSendContractorEmailMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: { shiftIds: string[] }) => {
      return shiftApi.sendContractorInvoiceEmails(params);
    },
  });

  return mutation;
};

export const useMarkShiftsAsPaidMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: Parameters<typeof shiftApi.markShiftsAsPaid>[0]) => {
      return shiftApi.markShiftsAsPaid(params);
    },
  });

  return mutation;
};

export const useGetPayrollReportsQuery = (
  params: GetPayrollReportParams,
  options?: {
    onSuccess: (data: {
      weekStartDate: string;
      weekEndDate: string;
      records: PayrollReportShift[];
    }) => void;
  }
) => {
  return useQuery({
    queryKey: [QueryKeys.PayrollReportShifts, params],
    queryFn: () => {
      return shiftApi.getPayrollReportShifts(params);
    },
    onSuccess: options?.onSuccess,
  });
};

export const useShiftPaymentAdjustmentsQuery = (shiftId: string) => {
  return useQuery({
    queryKey: [QueryKeys.ShiftPaymentAdjustments, shiftId],
    queryFn: () => {
      return shiftApi.getShiftPaymentAdjustments(shiftId);
    },
  });
};

export const useAddNoteToShiftMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: AddNoteToShiftParams) => {
      return shiftApi.addNoteToShift(params);
    },
  });

  return mutation;
};

export const useAddPaymentAdjustmentToShiftMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: AddPaymentAdjustmentToShiftParams) => {
      return shiftApi.addPaymentAdjustmentToShift(params);
    },
  });

  return mutation;
};

export const useCompleteShiftMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: CompleteShiftParams) => {
      return shiftApi.completeShift(params);
    },
  });

  return mutation;
};

export const useUpdateVehicleMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: UpdateVehicleParams) => {
      return shiftApi.updateVehicle(params);
    },
  });

  return mutation;
};

export const useGetDailyViewQuery = (params: GetDailyViewQueryParams) => {
  const { hirerId, date } = params;
  return useQuery({
    queryKey: [QueryKeys.DailyView, hirerId, date.toString()],
    queryFn: () => {
      return shiftApi.getDailyView(params);
    },
  });
};

export const useAuthoriseShiftPaymentMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: AuthoriseSingleShiftParams) => {
      return shiftApi.authorisePayment(params);
    },
  });

  return mutation;
};

export const useGetPayrollCsvMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: GetPayrollCsvParams) => {
      return shiftApi.getPayrollCsv(params);
    },
  });

  return mutation;
};

export const useSaveShiftSegmentMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: Parameters<typeof shiftApi.addShiftSegment>[0]) => {
      return shiftApi.addShiftSegment(params);
    },
  });

  return mutation;
};

export const useAddRdcShiftSegmentMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: Parameters<typeof shiftApi.addRdcShiftSegment>[0]) => {
      return shiftApi.addRdcShiftSegment(params);
    },
  });

  return mutation;
};

export const useLinkShiftMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: LinkShiftParams) => {
      return shiftApi.linkShift(params);
    },
  });

  return mutation;
};
export const useDeleteShiftSegmentMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: Parameters<typeof shiftApi.deleteShiftSegment>[0]) => {
      return shiftApi.deleteShiftSegment(params);
    },
  });

  return mutation;
};

export const useUploadShiftMileageMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: Parameters<typeof shiftApi.uploadMileage>[0]) => {
      return shiftApi.uploadMileage(params);
    },
  });

  return mutation;
};

export const useUploadShiftTimesMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: Parameters<typeof shiftApi.uploadTimes>[0]) => {
      return shiftApi.uploadTimes(params);
    },
  });

  return mutation;
};

export const useUpdateShiftsFromShiftConfigMutation = () => {
  const mutation = useMutation({
    mutationFn: (
      params: Parameters<typeof shiftApi.pushShiftConfigToShifts>[0]
    ) => {
      return shiftApi.pushShiftConfigToShifts(params);
    },
  });

  return mutation;
};

export const useUpdateShiftChargeRateMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: Parameters<typeof shiftApi.updateChargeRate>[0]) => {
      return shiftApi.updateChargeRate(params);
    },
  });

  return mutation;
};
