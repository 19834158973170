import { Alert, Button } from 'react-bootstrap';
import { Icon } from 'pages/Utils/react-utils';

const useAlertWithRefreshButton = (message, isLoading, isFetching, refetch) => {
  const refreshButton = (
    <Button
      bsSize='xs'
      bsStyle='primary'
      onClick={refetch}
      disabled={isLoading || isFetching}
      className='pull-right'
    >
      <Icon icon='fa-redo' isSpinning={isLoading || isFetching} /> Refresh
    </Button>
  );
  const alert = (
    <Alert bsStyle='info'>
      {message}
      {refreshButton}
    </Alert>
  );

  return { refreshButton, alert };
};

export default useAlertWithRefreshButton;
