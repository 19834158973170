import { Button } from 'react-bootstrap';
import { useModal } from 'hooks/useModal';
import { Icon } from 'pages/Utils/react-utils';
import { LocationModal } from './LocationModal';

export const CreateNewLocationButton = () => {
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const handleShowModalClick = () => {
    handleModalOpen();
  };

  const handleCloseModalClick = () => {
    handleModalClose();
  };

  return (
    <div>
      <Button
        bsStyle='primary'
        onClick={handleShowModalClick}
        style={{ marginBottom: 0 }}
      >
        <Icon icon='fa-map-marked-alt' /> Add New Location
      </Button>
      <LocationModal
        handleCloseModalClick={handleCloseModalClick}
        showModal={isModalOpen}
      />
    </div>
  );
};
