import { Component } from 'react';
import {
  Alert,
  Button,
  ButtonToolbar,
  Col,
  ControlLabel,
  FormGroup,
  Modal,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useWorkerOptions } from 'hooks/queries';
import $ from 'jquery';
import moment from 'moment';
import { DatePicker } from 'pages/Utils/DatePicker';
import { TimeDropdown } from 'pages/Utils/TimeDropdown';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, SpinIcon, WellSmall } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { createShift, getNewShiftConfig } from 'utils/api';
import { userPermissions, usePermissions } from 'utils/userPermissions';
import {
  getAppNameFromIndustryType,
  getWorkerTypeFromIndustryType,
} from '../../ShiftConfig/ShiftConfigUtils';
import { ShiftUtils } from '../ShiftUtils';

export const CreateNewShiftButton = (props) => {
  const { hasPermission } = usePermissions();
  if (!hasPermission(userPermissions.shift.create.outsidePlanner)) {
    return null;
  }

  return <CreateNewShiftButtonMain {...props} />;
};

class CreateNewShiftButtonMain extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, isSaving: false };
    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  handleShowModalClick() {
    this.setState({ showModal: true });
  }

  handleCloseModalClick() {
    this.setState({ showModal: false });
  }

  render() {
    const title = 'New Shift';
    const bsSize = this.props.isXs ? 'xs' : this.props.isSm ? 'sm' : null;
    const block = this.props.block || false;

    return (
      <>
        <Button
          bsStyle='success'
          bsSize={bsSize}
          block={block}
          onClick={this.handleShowModalClick}
          className={this.props.className}
          style={this.props.style}
        >
          <Icon icon='fa-briefcase' /> {title}
        </Button>
        <NewShiftModal
          {...this.state}
          {...this.props}
          title={title}
          handleCloseModalClick={this.handleCloseModalClick}
        />
      </>
    );
  }
}

const _shiftAllocationType = ShiftUtils.AllocationType;

class NewShiftModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();

    this.handleChange = this.handleChange.bind(this);
    this.handleShiftAllocationTypeChange =
      this.handleShiftAllocationTypeChange.bind(this);
    this.handleShiftDatesChange = this.handleShiftDatesChange.bind(this);
    this.onEntered = this.onEntered.bind(this);
    this.onExited = this.onExited.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  defaultState() {
    return {
      isSaving: false,
      isSaved: false,
      isGettingConfig: false,
      hasFinishedGettingConfig: false,
      configuration: null,
      HirerId: null,
      ShiftConfigId: null,
      shiftAllocationType: _shiftAllocationType.RegisteredWorkers,
      shiftDates: [],
    };
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.name === 'HirerId') {
      this.setState({ ShiftConfigId: null });
    }
  }

  handleShiftAllocationTypeChange(shiftAllocationType) {
    this.setState({ shiftAllocationType });
  }

  handleShiftDatesChange(shiftDates) {
    this.setState({
      shiftDates: shiftDates.map((m) => moment(m).format('YYYY-MM-DD')),
    });
  }

  async onEntered() {
    const me = this;
    me.setState({ isSaved: false, isGettingConfig: true, configuration: null });
    const data = await getNewShiftConfig();
    AppUtils.handleAjaxDone(data, () => {
      if (data.configuration) {
        me.setState({ configuration: data.configuration });
      } else {
        throw new Error('Could not get configuration');
      }
    });
    me.setState({ isGettingConfig: false, hasFinishedGettingConfig: true });
  }

  onExited() {
    this.setState(this.defaultState());
  }

  async handleSubmitClick() {
    const me = this;
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);
    model['shiftAllocationType'] = this.state.shiftAllocationType;

    if (
      this.state.shiftAllocationType === _shiftAllocationType.SpecificWorker
    ) {
      model['ShiftDates'] = this.state.shiftDates;
    }

    if (!model['ShiftDates'] || model['ShiftDates'].length === 0) {
      model['ShiftDates'] = [model['ShiftDate']];
    }

    me.setState({ isSaving: true });
    AppUtils.clearNotifications();
    const data = await createShift(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Success', 'Shift created');
      me.setState({ isSaved: true });
      setTimeout(() => me.props.handleCloseModalClick(), 500);
      PubSub.publish(ShiftUtils.Events.ShiftCreated, data);
    });
    me.setState({ isSaving: false });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.showModal}
        onHide={this.props.handleCloseModalClick}
        onEntered={this.onEntered}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body
            {...this.state}
            handleChange={this.handleChange}
            handleShiftAllocationTypeChange={
              this.handleShiftAllocationTypeChange
            }
            handleShiftDatesChange={this.handleShiftDatesChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.handleCloseModalClick}>
            <i className='fa fa-times' /> Close
          </Button>
          <ModalSaveButton
            {...this.props}
            {...this.state}
            onClick={() => this.handleSubmitClick()}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = (props) => {
  const {
    isGettingConfig,
    configuration,
    hasFinishedGettingConfig,
    isSaved,
    handleChange,
    HirerId,
    ShiftConfigId,
  } = props;
  if (isGettingConfig) {
    return (
      <WellSmall className='m-b-none'>
        <SpinIcon /> Getting configuration options...
      </WellSmall>
    );
  }
  if (!configuration && hasFinishedGettingConfig) {
    return (
      <Alert bsStyle='danger' className='m-b-none'>
        <Icon icon='fa-times' /> Could not get configuration options
      </Alert>
    );
  }
  if (!configuration) {
    return null;
  }
  if (isSaved) {
    return <SavedUi isSaved={isSaved} />;
  }

  const hirerOptions = configuration.hirerOptions.map((item) => ({
    value: item.hirerId,
    text: item.companyName,
  }));
  const hirer = configuration.hirerOptions.find(
    (item) => item.hirerId === HirerId
  );
  const shiftConfig = hirer?.shiftTemplates.find(
    (shiftTemplate) => shiftTemplate.value === ShiftConfigId
  );
  const workerType = shiftConfig
    ? getWorkerTypeFromIndustryType(shiftConfig.shiftIndustryType)
    : null;
  const appName = shiftConfig
    ? getAppNameFromIndustryType(shiftConfig.shiftIndustryType)
    : null;
  return (
    <>
      <Row>
        <Field.InCol
          columns={12}
          name='HirerId'
          label='Customer'
          value={props.HirerId}
          options={hirerOptions}
          isSelect
          required
          onChange={handleChange}
        />
      </Row>
      <ShiftTemplate {...props} hirer={hirer} />
      {ShiftConfigId && (
        <>
          <ShiftWorkerTypePicker
            {...props}
            hirer={hirer}
            workerType={workerType}
            appName={appName}
          />
          <ShiftAllocationTypeAllowApplicants {...props} />
          <ShiftAllocationTypeSpecificWorker
            {...props}
            workerType={workerType}
            shiftIndustryType={shiftConfig?.shiftIndustryType}
          />
        </>
      )}
    </>
  );
};

const ShiftTemplate = ({ HirerId, hirer, ShiftConfigId, handleChange }) => {
  if (!HirerId) {
    return null;
  }
  if (!hirer) {
    return (
      <WellSmall>
        Could not find shift template options for chosen customer
      </WellSmall>
    );
  }

  const templates = hirer.shiftTemplates.map((template) => {
    const { value, text, shiftIndustryType } = template;
    return { value, text: `${text} (${shiftIndustryType})` };
  });
  return (
    <Row>
      <Field.InCol
        columns={12}
        name='ShiftConfigId'
        label='Shift Template'
        options={templates}
        isSelect
        required
        value={ShiftConfigId}
        onChange={handleChange}
      />
    </Row>
  );
};

const ShiftWorkerTypePicker = ({
  HirerId,
  ShiftConfigId,
  shiftAllocationType,
  handleShiftAllocationTypeChange,
  workerType,
  appName,
}) => {
  if (!HirerId || !ShiftConfigId) {
    return null;
  }

  const getAlertContent = () => {
    switch (shiftAllocationType) {
      case _shiftAllocationType.Public:
        return `Any ${workerType} who has the ${appName} app can apply for this shift.`;

      case _shiftAllocationType.RegisteredWorkers:
        return (
          <p>
            Only {workerType}s{' '}
            <Link to={`Worker`} style={{ textDecoration: 'underline' }}>
              who are registered
            </Link>{' '}
            with your agency can apply for this shift.
          </p>
        );

      case _shiftAllocationType.SpecificWorker:
        return `Only the ${workerType} you choose will be able to accept this shift.`;

      default:
        return '';
    }
  };

  return (
    <>
      <FormGroup>
        <ControlLabel>Who can apply for this Shift?</ControlLabel>
        <ButtonToolbar>
          <ToggleButtonGroup
            type='radio'
            name='ShiftAllocationType'
            value={shiftAllocationType}
            onChange={handleShiftAllocationTypeChange}
            justified
          >
            <ToggleButton
              value={_shiftAllocationType.Public}
              className='btn-sm'
            >
              All {workerType}s
            </ToggleButton>
            <ToggleButton
              value={_shiftAllocationType.RegisteredWorkers}
              className='btn-sm'
            >
              My Registered {workerType}s
            </ToggleButton>
            <ToggleButton
              value={_shiftAllocationType.SpecificWorker}
              className='btn-sm'
            >
              Specific {workerType}
            </ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>
      </FormGroup>
      <Alert bsStyle='info' bsSize='sm'>
        {getAlertContent()}
      </Alert>
    </>
  );
};

const ShiftAllocationTypeAllowApplicants = (props) => {
  const { HirerId, shiftAllocationType } = props;
  if (!HirerId) {
    return null;
  }
  if (
    shiftAllocationType !== _shiftAllocationType.Public &&
    shiftAllocationType !== _shiftAllocationType.RegisteredWorkers
  ) {
    return null;
  }
  return (
    <>
      <ShiftDateTime {...props} />
      <ShiftQty {...props} />
    </>
  );
};

const ShiftAllocationTypeSpecificWorker = ({
  HirerId,
  shiftAllocationType,
  handleShiftDatesChange,
  shiftDates,
  workerType,
  shiftIndustryType,
}) => {
  const { workerOptions } = useWorkerOptions(shiftIndustryType);

  if (!HirerId) {
    return null;
  }

  if (shiftAllocationType !== _shiftAllocationType.SpecificWorker) {
    return null;
  }

  const changeOrClearDate = (e) => handleShiftDatesChange(e.dates);
  return (
    <>
      <Row>
        <Field.InCol
          columns={12}
          name='WorkerId'
          label={AppUtils.toTitleCase(workerType)}
          options={workerOptions}
          isSelect
          required
        />
      </Row>
      <Row>
        <DatePicker
          columns={12}
          name='ShiftDates'
          label={`Shift Dates (${shiftDates.length} selected)`}
          required
          multidate={31}
          changeDate={changeOrClearDate}
          clearDate={changeOrClearDate}
        />
      </Row>
      <Row>
        <Col md={12}>
          <TimeDropdown
            name='ShiftTime'
            label='Shift Start Time'
            placeholder='Choose Shift Start Time...'
            required
          />
        </Col>
      </Row>
    </>
  );
};

const ShiftDateTime = ({ HirerId }) => {
  if (!HirerId) {
    return null;
  }
  return (
    <>
      <Row>
        <DatePicker columns={12} name='ShiftDate' label='Shift Date' required />
      </Row>
      <Row>
        <Col md={12}>
          <TimeDropdown
            name='ShiftTime'
            label='Shift Start Time'
            placeholder='Choose Shift Start Time...'
            required
          />
        </Col>
      </Row>
    </>
  );
};

const ShiftQty = ({ HirerId }) => {
  if (!HirerId) {
    return null;
  }
  return (
    <Row>
      <Field.InCol
        columns={12}
        name='Qty'
        value='1'
        label='Number of Shifts'
        maxLength='2'
        isNumber
        tooltip='Max value: 10'
        tooltipPosition='left'
      />
    </Row>
  );
};

const SavedUi = ({ isSaved }) =>
  !isSaved ? null : (
    <WellSmall className='text-success m-b-none'>
      <Icon icon='fa-check' /> Shift created!
    </WellSmall>
  );

const ModalSaveButton = ({ configuration, isSaving, isSaved, onClick }) => {
  if (!configuration) {
    return null;
  }
  const isDisabled = isSaving || isSaved;
  const buttonTitle = isSaving
    ? 'Creating...'
    : isSaved
    ? 'Shift Created'
    : 'Create Shift';
  return (
    <Button bsStyle='success' onClick={onClick} disabled={isDisabled}>
      <Icon icon='fa-check' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};
