import { useQuery } from '@tanstack/react-query';
import useSubscription from 'hooks/useSubscription';
import { getHirerUsers } from 'utils/api';
import { useAuth } from 'utils/auth';
import { useAlertWithRefreshButton } from 'components/AlertWithRefreshButton';
import DataTable from 'components/DataTable';
import { GettingRecordsMessage } from 'components/GettingRecordsMessage';
import { NoRecordsFoundMessage } from 'components/NoRecordsFoundMessage';
import { HirerUtils } from '../../HirerUtils';

const HirerUserTable = ({ hirerId }) => {
  const auth = useAuth();
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['hirerUsers', hirerId],
    () => getHirerUsers(hirerId)
  );
  const records = data?.records || [];

  useSubscription([HirerUtils.Events.UserInvited], refetch);

  const { alert, refreshButton } = useAlertWithRefreshButton(
    auth?.user?.record?.isHirerUser
      ? 'Your company users'
      : 'The users for this customer.',
    isLoading,
    isFetching,
    refetch
  );

  if (isLoading) {
    return <GettingRecordsMessage message='No users found.' />;
  }

  if (records.length <= 0) {
    return (
      <NoRecordsFoundMessage records={records} refreshButton={refreshButton} />
    );
  }

  return (
    <>
      {alert}
      <Table records={records} />
    </>
  );
};

const tableId = 'hirer-users-table';
const Table = ({ records }) => {
  return (
    <DataTable
      tableId={tableId}
      records={records}
      render={() => {
        if (records.length <= 0) {
          return null;
        }
        return (
          <table
            className='table table-condensed table-striped'
            id={tableId}
            data-order='[[0, "asc"]]'
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {records.map((item, i) => (
                <Row key={i} record={item} />
              ))}
            </tbody>
          </table>
        );
      }}
    />
  );
};

const Row = ({ record }) => {
  return (
    <tr>
      <td data-order={record.surname}>
        {record.firstName} {record.surname}
      </td>
      <td>{record.emailAddress}</td>
      <td>{record.status}</td>
    </tr>
  );
};

export default HirerUserTable;
