import 'datatables.net-bs';
import 'datatables.net-bs/css/dataTables.bootstrap.css';
import 'styles/list-view.scss';
import $ from './app-utils-jquery';

export const ListViewUtils = {
  init: function (tableId, showPager, paramDataTableOptions) {
    if (ListViewUtils.errorCount > 3) {
      console.log('Error count greater than 3. Not trying any more');
      return;
    }

    var $table = tableId ? $('#' + tableId) : $('.table');
    if ($table.data('apply-data-tables') !== false) {
      var dataTableOpts = {
        columnDefs: [{ targets: 'no-sort', orderable: false }],
        destroy: true,
        dom:
          "<'dt-toolbar row'<'col-sm-4'f><'col-sm-8 text-right'i>>t" +
          (showPager ? "<'dt-toolbar row'<'col-sm-12 text-right'p>>" : ''),
        pageLength: 250,
        oLanguage: {
          sLengthMenu: '_MENU_',
          sInfo:
            "Showing <span class='text-primary'>_START_</span> to <span class='text-primary'>_END_</span> of <span class='text-primary'>_TOTAL_</span>",
          sInfoEmpty: "<span class='text-danger'>Showing 0 to 0 of 0</span>",
          sSearch: 'Filter: ',
        },
        initComplete: function () {
          $table
            .siblings('.dt-toolbar')
            .after('<hr style="margin: 10px 0;" />');
        },
      };
      if (paramDataTableOptions) {
        $.extend(dataTableOpts, paramDataTableOptions);
      }
      setTimeout(function () {
        if (!$table.hasClass('dataTable')) {
          try {
            var dataTable = $table.DataTable(dataTableOpts);
            $table.find('thead th input[type="text"]').on('keyup', function () {
              dataTable
                .column($(this).parent().index() + ':visible')
                .search(this.value)
                .draw();
            });
          } catch (e) {
            console.log(e);
            ListViewUtils.errorCount++;
            ListViewUtils.init(tableId);
          }
        }
      }, 250);
    }
  },

  errorCount: 0,
};
