import {
  Button,
  Row,
  Col,
  FormControl,
  FormGroup,
  ControlLabel,
  Well,
  Alert,
} from 'react-bootstrap';
import { Dropdown, FormLabel } from 'components';
import { AppUtils } from '../Utils/app-utils';
import './react-utils.scss';

const renderPropName = 'onlyRenderWhen';
const shouldRender = (props) => {
  // If the property exists and the property is truthy then do render
  // If the property exists and the property is falsy then don't render
  // If the property doesn't exist (i.e. the default) then do render
  if (renderPropName in props) {
    if (props[renderPropName]) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const Field = (function () {
  const InCol = (props) => {
    if (!shouldRender(props)) {
      return null;
    }

    if (props.isStatic) {
      return <StaticInCol {...props} />;
    }
    return (
      <Col md={props.columns || 3}>
        <Main {...props} />
      </Col>
    );
  };

  const StaticInCol = (props) => {
    if (!shouldRender(props)) {
      return null;
    }

    const cols = props.columns || 3;
    return (
      <Col md={cols}>
        <FormControlStatic label={props.label} value={props.value} />
      </Col>
    );
  };

  const Main = (props) => {
    if (!shouldRender(props)) {
      return null;
    }
    let field;
    if (props.isTextArea) {
      field = <TextArea {...props} />;
    } else if (props.isDatepicker) {
      field = <DatePicker {...props} />;
    } else if (props.isSelect) {
      field = <Dropdown {...props} />;
    } else {
      field = <InputField {...props} />;
    }
    return field;
  };

  return {
    InCol,
    Main,
    StaticInCol,
  };
})();

const FormControlStatic = (props) => {
  return (
    <FormGroup>
      <ControlLabel>{props.label}</ControlLabel>
      <FormControl.Static {...props}>{props.value}</FormControl.Static>
    </FormGroup>
  );
};

export const InputField = (props) => {
  const name = props.name;
  const label = props.label ?? name;
  const value = props.value ?? '';
  const classes = props.classes ?? [];
  const required = props.required ?? false;
  const maxLength = props.maxLength ?? '';
  const disabled = props.disabled ?? false;
  const type = props.type ?? 'text';
  const isControlled = props.isControlled ?? false;
  const isNumber = props.isNumber ?? false;
  const zeroIsNotAllowed = props.zeroIsNotAllowed === true ? true : false;

  let classNames = ['form-control'];
  if (classes.length > 0) {
    classNames = classNames.concat(classes);
  }
  if (required) {
    classNames.push('required');
  }
  if (isNumber) {
    classNames.push('is-number');
  }
  if (zeroIsNotAllowed) {
    classNames.push('zero-not-allowed');
  }

  const labelRet = props.noLabel ? null : label ? (
    <FormLabel {...props} label={label} />
  ) : null;

  let input = (
    <input
      type={type}
      name={name}
      id={name}
      defaultValue={value}
      className={classNames.join(' ')}
      onKeyUp={props.onKeyUp}
      maxLength={maxLength}
      disabled={disabled}
      placeholder={props.placeholder}
      onBlur={props.onBlur}
    />
  );

  if (isControlled) {
    input = (
      <input
        type={type}
        name={name}
        id={name}
        value={value}
        className={classNames.join(' ')}
        maxLength={maxLength}
        disabled={disabled}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        onKeyUp={props.onKeyUp}
        onChange={props.onChange}
      />
    );
  }

  const hasAddOn = props.addOnPre || props.addOnPost || props.btnAddOnPost;
  const addOnPre = props.addOnPre ? (
    <span className='input-group-addon'>{props.addOnPre}</span>
  ) : null;
  const addOnPost = props.addOnPost ? (
    <span className='input-group-addon'>{props.addOnPost}</span>
  ) : null;
  const btnAddOnPost = props.btnAddOnPost ? (
    <span className='input-group-btn'>{props.btnAddOnPost}</span>
  ) : null;

  const inputRet = (
    <>
      {addOnPre}
      {input}
      {addOnPost}
      {btnAddOnPost}
    </>
  );

  const labelAndInput = hasAddOn ? (
    <>
      {labelRet}
      <div className='input-group'>{inputRet}</div>
    </>
  ) : (
    <>
      {labelRet}
      {inputRet}
    </>
  );

  if (props.noFormGroup) {
    return labelAndInput;
  }

  return <div className='form-group'>{labelAndInput}</div>;
};

export const SpinIcon = ({ style }) => (
  <i className={AppUtils.spinIconClass} style={style} />
);

export const WellSmall = (props) => {
  const spinner = props.hasSpinner ? <SpinIcon /> : null;
  return (
    <Well bsSize='sm' className={props.className} style={props.style}>
      {spinner} {props.children}
    </Well>
  );
};

export const ListViewNoRecordsWell = (props) => {
  return (
    <Well bsSize='sm' className={props.className}>
      <Row>
        <Col sm={10}>{props.message}</Col>
        <Col sm={2} className='text-right'>
          <Button
            bsSize={props.btnSize || 'sm'}
            bsStyle={props.btnStyle || 'primary'}
            onClick={props.btnOnClick}
            className={props.btnClassName}
          >
            <Icon icon='fa-redo' /> {props.btnTitle || 'Refresh'}
          </Button>
        </Col>
      </Row>
    </Well>
  );
};

export const Icon = (props) => {
  const { isSpinning, icon, colour, style, tooltip, tooltipPosition, onClick } =
    props;
  let { className } = props;

  const iconClassName = isSpinning ? AppUtils.spinIconClass : `fas ${icon}`;
  className = className ? `${className} ${iconClassName}` : iconClassName;

  const finalStyle = {
    ...(colour && { color: colour }),
    ...style,
  };

  const tooltipConfig = tooltip
    ? {
        'data-toggle': 'tooltip',
        'data-placement': tooltipPosition ? tooltipPosition : 'top',
        title: tooltip,
      }
    : {};

  return (
    <i
      className={className}
      style={finalStyle}
      {...tooltipConfig}
      onClick={onClick}
    />
  );
};

const TextArea = (props) => {
  const name = props.name;
  const label = props.label;
  const value = props.value;
  const classes = props.classes || [];
  const required = props.required || false;
  const maxLength = props.maxLength || '';
  const disabled = props.disabled || false;
  const rows = props.rows || 4;
  const isControlled = props.isControlled || false;
  const postFieldComponent = props.postFieldComponent;

  let inputClasses = ['form-control'];
  if (classes.length > 0) {
    inputClasses = inputClasses.concat(classes);
  }
  if (required) {
    inputClasses.push('required');
  }
  const labelRet = label ? (
    <label className='control-label' htmlFor={name}>
      {label}
    </label>
  ) : null;

  if (isControlled) {
    return (
      <div className='form-group'>
        {labelRet}
        <textarea
          name={name}
          id={name}
          value={value}
          rows={rows}
          className={inputClasses.join(' ')}
          onKeyUp={props.onKeyUp}
          maxLength={maxLength}
          disabled={disabled}
          onChange={props.handleChange}
        />
        {postFieldComponent ? postFieldComponent : null}
      </div>
    );
  } else {
    return (
      <div className='form-group'>
        {labelRet}
        <textarea
          name={name}
          id={name}
          defaultValue={value}
          rows={rows}
          className={inputClasses.join(' ')}
          onKeyUp={props.onKeyUp}
          maxLength={maxLength}
          disabled={disabled}
        />
        {postFieldComponent ? postFieldComponent : null}
      </div>
    );
  }
};

const DatePicker = (props) => {
  const name = props.name;
  const label = props.label || name;
  const value = props.value;
  const classes = props.classes || [];
  const required = props.required || false;
  const disabled = props.disabled || false;
  const readonly = props.readonly || false;

  let inputClasses = ['form-control', 'is-date'];
  if (classes.length > 0) {
    inputClasses = inputClasses.concat(classes);
  }
  if (required) {
    inputClasses.push('required');
  }

  const labelRet = props.noLabel ? null : label ? (
    <FormLabel {...props} label={label} />
  ) : null;

  const ret = (
    <>
      {labelRet}
      <div className='input-group date'>
        <input
          type='text'
          name={name}
          id={name}
          defaultValue={value}
          className={inputClasses.join(' ')}
          disabled={disabled}
          readOnly={readonly}
          autoComplete='off'
        />
        <span className='input-group-addon'>
          <i className='fas fa-calendar' />
        </span>
      </div>
    </>
  );

  if (props.notInFormGroup) {
    return ret;
  }

  return <div className='form-group'>{ret}</div>;
};

export const DashboardHeader = (props) => (
  <div
    className='row border-bottom white-bg dashboard-header m-b'
    style={{ marginTop: '-15px' }}
  >
    {props.children}
  </div>
);

export const DashboardPanel = (props) => {
  let header = null;
  if (!props.noHeader) {
    if (props.header) {
      header = props.header;
    } else {
      header = <h5>{props.title}</h5>;
    }
    header = <div className='ibox-title'>{header}</div>;
  }

  const { containerStyle } = props;

  return (
    <div className='ibox float-e-margins' style={containerStyle}>
      {header}
      <div className='ibox-content' style={{ minHeight: '69px' }}>
        {props.content}
      </div>
    </div>
  );
};

export const AlertWithIcon = (props) => {
  const className = ['alert-with-icon', props.className || ''].join(' ');
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
    ...props.style,
  };

  const icon = props.icon || 'fa fa-info-circle';
  const bsStyle = props.bsStyle || 'info';

  return (
    <Alert bsStyle={bsStyle} style={style} className={className}>
      <Icon className='fa-2x' icon={icon} />
      <div>{props.children}</div>
    </Alert>
  );
};

export const SmallInfo = (props) => {
  return (
    <em className='text-info'>
      <small>{props.children}</small>
    </em>
  );
};

export const SmallErrorText = (props) => {
  return (
    <em className='text-danger'>
      <small>{props.children}</small>
    </em>
  );
};

export const Muted = (props) => {
  return (
    <span className='text-muted' style={props.style}>
      {props.children}
    </span>
  );
};

export const SmallMuted = (props) => {
  const className = `text-muted${props.className ? ' ' + props.className : ''}`;
  return (
    <em className={className}>
      <small>{props.children}</small>
    </em>
  );
};

export const RateCol = ({ record }) => {
  if (record.shiftPaymentType === 'Fixed') {
    return (
      <>
        <div>
          {AppUtils.formatNumber(record.dayRateFixed)} <br />
          <SmallMuted>fixed (agency)</SmallMuted>
        </div>
        <div>
          {AppUtils.formatNumber(record.dayRateFixedPayeWithHoliday)} <br />
          <SmallMuted>fixed (paye)</SmallMuted>
        </div>
      </>
    );
  }
  return (
    <>
      {AppUtils.formatNumber(record.dayRateHourly)} <br />
      <SmallMuted>per hour</SmallMuted>
    </>
  );
};

// Use when you have non component elements in the array which need wrapping in a div
// If you need to wrap component elements then use `getDivArray`
// This is because passing component elements in the array will result in the key error (https://fb.me/react-warning-keys) unless you give each element a key before it gets to here
export const MapToDiv = (props) => {
  if (!props.children || props.children.length <= 0) {
    return null;
  }
  return getDivArray(props.children);
};

export const getDivArray = (componentArray) => {
  return componentArray.map((item, i) => <div key={i}>{item}</div>);
};

export const SidebarData = (props) => {
  return (
    <div className='m-b-sm'>
      <Muted style={props.labelStyle}>{props.label}</Muted>
      <div>{props.value}</div>
    </div>
  );
};

export const setIsGettingWithAutoRefresh = function (
  _this,
  isGetting,
  lastGet,
  fetchFn,
  frequency
) {
  let newState = { isGetting };
  if (lastGet) {
    newState['lastGet'] = lastGet;

    // If lastGet is being set it means a fetch has just finished
    // Check if there's a timeout to fetch again
    // If so clear it and restart
    if (_this.state.timeout) {
      clearTimeout(_this.state.timeout);
    }

    // Set new timeout
    newState['timeout'] = setTimeout(() => {
      fetchFn();
    }, frequency);
  }
  _this.setState(newState);
};

export const EmptyLabel = () => {
  return <label className='control-label'>&nbsp;</label>;
};
