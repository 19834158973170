import { CSSProperties } from 'react';
import { Alert } from 'react-bootstrap';

export const ErrorAlert = ({
  msg,
  style,
  className,
}: {
  msg: string;
  style?: CSSProperties;
  className?: string;
}) => {
  return (
    <Alert bsStyle='danger' style={style} className={className}>
      <strong>Error!</strong> {msg}
    </Alert>
  );
};
