import { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useApproveInvoiceMutation } from 'hooks/queries';
import { useModal } from 'hooks/useModal';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon } from 'pages/Utils/react-utils';
import { Invoice } from 'types/Invoice';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';

export const useApproveDialog = () => {
  const [invoice, setInvoice] = useState<Invoice | null>(null);

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  const showApproveDialog = (invoice: Invoice) => {
    setInvoice(invoice);
    handleModalOpen();
  };

  const renderApproveDialog = () => {
    if (!invoice) {
      return null;
    }

    return (
      <ApproveDialog
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        onExited={() => setInvoice(null)}
        invoice={invoice}
      />
    );
  };

  return { showApproveDialog, renderApproveDialog };
};

const ApproveDialog = ({
  isModalOpen,
  handleModalClose,
  onExited,
  invoice,
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
  onExited?: () => void;
  invoice: Invoice;
}) => {
  const history = useHistory();
  const approveRequest = useApproveInvoiceMutation();
  const handleApproveClick = () => {
    approveRequest.mutate(
      { invoiceId: invoice.invoiceId },
      {
        onSuccess: () => {
          AppUtils.publish('invoice.approved');
          AppUtils.displaySuccess('Thanks', 'Invoice Approved');
          handleModalClose();
          history.push('/Invoice');
        },
      }
    );
  };

  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Approve Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You are going to approve this invoice. Once approved, the invoice will
          be locked and no further changes can be made.
        </p>

        <Alert bsStyle='info'>Are you sure you want to proceed?</Alert>

        {approveRequest.isError ? (
          <ErrorAlertForQueryOrNull
            isError={approveRequest.isError}
            error={approveRequest.error}
          />
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>Cancel</Button>
        <Button
          bsStyle='success'
          onClick={handleApproveClick}
          disabled={approveRequest.isLoading}
        >
          <Icon icon='fa-check' isSpinning={approveRequest.isLoading} /> Yes,
          Approve
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
