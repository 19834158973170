import { Component } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import $ from 'jquery';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { adminSaveAgency } from 'utils/api';
import { AgencyUtils } from '../AgencyUtils';

export class AddAgencyButton extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, isSaving: false };
    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  handleShowModalClick() {
    this.setState({ showModal: true });
  }

  handleCloseModalClick() {
    this.setState({ showModal: false });
  }

  render() {
    const title = 'Create Agency...';
    const bsSize = this.props.isXs ? 'xs' : this.props.isSm ? 'sm' : null;
    const block = this.props.block || false;
    return (
      <>
        <Button
          bsStyle='primary'
          bsSize={bsSize}
          block={block}
          onClick={this.handleShowModalClick}
          className={this.props.className}
          style={this.props.style}
        >
          <Icon icon='fa-clipboard-check' /> {title}
        </Button>
        <AddAgencyModal
          {...this.state}
          {...this.props}
          title={title}
          handleCloseModalClick={this.handleCloseModalClick}
        />
      </>
    );
  }
}

class AddAgencyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
    };

    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  onEntered() {
    $('#CompanyName').focus();
  }

  async handleSubmitClick() {
    const me = this;
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);

    me.setState({ isSaving: true });
    const data = await adminSaveAgency(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Agency Created');
      me.props.handleCloseModalClick();
      PubSub.publish(AgencyUtils.Events.AgencySaved, data);
    });
    me.setState({ isSaving: false });

    // $.ajax({
    //   type: "POST",
    //   url: `${AppSettings.baseUrl}Admin/AgencySave`,
    //   data: JSON.stringify(model),
    // })
    //   .done(function (data) {
    //     AppUtils.handleAjaxDone(data, () => {
    //       AppUtils.displaySuccess("Done", "Agency Created");
    //       me.props.handleCloseModalClick();
    //       PubSub.publish(AgencyUtils.Events.AgencySaved, data);
    //     });
    //   })
    //   .fail(function (xhr) {
    //     AppUtils.handleXhrFail(xhr);
    //   })
    //   .always(function () {
    //     me.setState({ isSaving: false });
    //   });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.showModal}
        onHide={this.props.handleCloseModalClick}
        onEntered={this.onEntered}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.state} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.handleCloseModalClick} bsSize='sm'>
            <i className='fa fa-times' /> Close
          </Button>
          <SubmitButton
            {...this.state}
            handleSubmitClick={this.handleSubmitClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = () => {
  return (
    <>
      <Row>
        <Field.InCol
          columns={12}
          name='CompanyName'
          label='Company Name'
          maxLength='100'
          required
        />
      </Row>
      <Row>
        <Field.InCol
          columns={4}
          name='CompanyNumber'
          label='Company Number'
          maxLength='30'
        />
        <Field.InCol
          columns={8}
          name='CompanyEmailAddress'
          label='Company Email'
          maxLength='256'
        />
      </Row>
      <hr />
      <Row>
        <Field.InCol
          columns={4}
          name='AddressLine1'
          label='Address Line 1'
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='AddressLine2'
          label='Address Line 2'
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='AddressLine3'
          label='Address Line 3'
          maxLength='100'
        />
      </Row>
      <Row>
        <Field.InCol
          columns={4}
          name='Town'
          label='Town/City'
          maxLength='100'
        />
        <Field.InCol columns={4} name='County' maxLength='100' />
        <Field.InCol columns={4} name='Postcode' maxLength='10' />
      </Row>
      <Row>
        <Field.InCol columns={4} name='Region' maxLength='100' />
      </Row>
      <hr />
      <h3>Contact Details</h3>
      <WellSmall>Please enter the details of the first Agency user.</WellSmall>
      <Row>
        <Field.InCol
          columns={6}
          name='FirstName'
          label='First Name'
          maxLength='100'
          required
        />
        <Field.InCol
          columns={6}
          name='Surname'
          label='Surname'
          maxLength='100'
          required
        />
      </Row>
      <Row>
        <Field.InCol
          columns={12}
          name='EmailAddress'
          label='Email'
          maxLength='256'
          required
        />
      </Row>
      <Row>
        <Field.InCol
          columns={6}
          name='Password'
          label='Password'
          type='password'
          required
        />
        <Field.InCol
          columns={6}
          name='PasswordConfirm'
          label='Password Confirm'
          type='password'
          required
        />
      </Row>
    </>
  );
};

const SubmitButton = (props) => {
  const buttonTitle = props.isSaving ? 'Creating...' : 'Create';
  return (
    <Button
      bsStyle='success'
      bsSize='sm'
      className='m-l-sm'
      onClick={props.handleSubmitClick}
      disabled={props.isSaving}
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
