import { useState, useCallback } from 'react';
import { ColDef, CellClickedEvent, BodyScrollEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useGetPlanForHirerQuery, useNewShiftConfigQuery } from 'hooks/queries';
import { useModal } from 'hooks/useModal';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import {
  ShiftPlanItem,
  ShiftPlanItemModelArray,
  ShiftPlanItemDataModel,
  ShiftPlanItemShift,
} from 'types/ShiftPlanningTypes';
import { extractErrorMessages, formatDate } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { GettingRecordsMessage } from 'components/GettingRecordsMessage';
import { AssignWorkerToPlanItemModal } from './AssignWorkerToPlanItemModal';
import { useCreateSingleShift } from './CreateSingleShiftForVehicleModal';
import { DeletePlanItemModal } from './DeletePlanItemModal';
import { useShiftMenuModal } from './ShiftMenuModal/ShiftMenuModal';
import { useUpdateTimeModal } from './UpdateTimeModal';
import {
  deleteColumnDefinition,
  baseDefaultColDef,
  selectShiftsColumnDefinition,
  useColumnDefinitions,
} from './vehiclePlannerUtils';

export const VehiclePlannerGrid = ({
  gridRef,
  selectedCustomerId,
  showDeleteColumn,
  showSelectShiftsColumn,
  startDate,
  query,
}: {
  gridRef: React.MutableRefObject<AgGridReact | null>;
  selectedCustomerId: string | null;
  showDeleteColumn: boolean;
  showSelectShiftsColumn: boolean;
  startDate: Date;
  query: ReturnType<typeof useGetPlanForHirerQuery>;
}) => {
  const { getColumnDefinitions } = useColumnDefinitions();

  const defaultColDef: ColDef[] = [
    ...(showDeleteColumn ? [deleteColumnDefinition] : [null]),
    ...(showSelectShiftsColumn ? [selectShiftsColumnDefinition] : [null]),
    ...getColumnDefinitions(startDate, 30),
  ].filter(Boolean);

  const {
    isModalOpen: isAssignModalOpen,
    handleModalOpen: handleAssignModalOpen,
    handleModalClose: handleAssignModalClose,
  } = useModal();

  const {
    isModalOpen: isDeleteModalOpen,
    handleModalOpen: handleDeleteModalOpen,
    handleModalClose: handleDeleteModalClose,
  } = useModal();

  const [handleShiftPlanItem, setHandleShiftPlanItem] =
    useState<ShiftPlanItem | null>(null);

  const { handleShiftMenuModalOpen, renderShiftMenuModal } =
    useShiftMenuModal();

  const { handleCreateSingleShiftModalOpen, renderCreateSingleShiftModal } =
    useCreateSingleShift(selectedCustomerId);

  const { handleUpdateTimeModalOpen, renderUpdateTimeModal } =
    useUpdateTimeModal();

  const cellClickedListener = useCallback(
    (event: CellClickedEvent<ShiftPlanItem>) => {
      if (event.colDef.field === 'name') {
        setHandleShiftPlanItem(event.data);
        handleAssignModalOpen();
        return;
      }

      if (event.colDef.field === 'shiftTime') {
        handleUpdateTimeModalOpen({ shiftPlanItem: event.data });
        return;
      }

      if (event.colDef.field === 'delCol') {
        setHandleShiftPlanItem(event.data);
        handleDeleteModalOpen();
        return;
      }

      if (
        event.value &&
        typeof event.value === 'object' &&
        'shiftId' in event.value &&
        event.colDef.colId &&
        event.colDef.colId.startsWith('shiftCol')
      ) {
        const shift: ShiftPlanItemShift = {
          ...event.value,
        };

        if (shift.status === ShiftUtils.Status.Rejected) {
          handleCreateSingleShiftModalOpen({
            shiftPlanItem: event.data,
            date: event.colDef.field,
          });
          return;
        } else {
          const shiftPlanItem = event.data;
          handleShiftMenuModalOpen({ shift, shiftPlanItem });
          return;
        }
      }

      if (
        !event.value &&
        event.colDef.colId &&
        event.colDef.colId.startsWith('shiftCol')
      ) {
        handleCreateSingleShiftModalOpen({
          shiftPlanItem: event.data,
          date: event.colDef.field,
        });

        return;
      }
    },
    [
      handleAssignModalOpen,
      handleCreateSingleShiftModalOpen,
      handleDeleteModalOpen,
      handleShiftMenuModalOpen,
      handleUpdateTimeModalOpen,
    ]
  );

  const hirersQuery = useNewShiftConfigQuery();
  const hirerName = hirersQuery.data?.hirerOptions.find(
    (hirer) => hirer.hirerId === selectedCustomerId
  )?.companyName;

  if (query.isError) {
    return <ErrorAlert msg={extractErrorMessages(query.error)} />;
  }

  if (query.isLoading) {
    return (
      <GettingRecordsMessage
        message={`Loading plan ${hirerName ? `for ${hirerName}` : ''}`}
      />
    );
  }

  const rowData = query.data.reduce<ShiftPlanItemModelArray>((acc, curr) => {
    const ret = { ...curr, shifts: [...curr.shifts] } as ShiftPlanItemDataModel;
    ret.shifts.forEach((shift) => {
      ret[formatDate(shift.shiftDate)] = shift;
    });

    delete ret.shifts;

    acc.push(ret);

    return acc;
  }, []);

  const handleVirtualColumnsChanged = (e: BodyScrollEvent) => {
    const columnApi = e.columnApi;
    const allColumns = columnApi.getAllGridColumns();
    const allDisplayedColumns = columnApi.getAllDisplayedVirtualColumns();
    const lastColumn = allColumns[allColumns.length - 1];
    const colIdLastColumn = lastColumn.getColId();

    if (allDisplayedColumns.find((col) => col.getColId() === colIdLastColumn)) {
      console.log('🎉🎉🎉🎉 Final column scrolled to');
    }
  };

  if (query.isSuccess) {
    return (
      <>
        <div style={{ height: 'calc(100vh - 250px)' }}>
          <div style={{ height: '100%' }} className='ag-theme-balham'>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={defaultColDef}
              defaultColDef={baseDefaultColDef}
              onCellClicked={cellClickedListener}
              alwaysShowHorizontalScroll={true}
              columnHoverHighlight
              scrollbarWidth={8}
              onVirtualColumnsChanged={handleVirtualColumnsChanged}
              rowSelection='multiple'
              suppressRowClickSelection={true}
              rowHeight={55}
              suppressScrollOnNewData={true}
            />
          </div>
        </div>

        {isAssignModalOpen ? (
          <AssignWorkerToPlanItemModal
            isModalOpen={isAssignModalOpen}
            handleModalClose={handleAssignModalClose}
            shiftPlanItem={handleShiftPlanItem}
          />
        ) : null}

        {isDeleteModalOpen ? (
          <DeletePlanItemModal
            isModalOpen={isDeleteModalOpen}
            handleModalClose={handleDeleteModalClose}
            shiftPlanItemId={handleShiftPlanItem.shiftPlanItemId}
            hirerId={selectedCustomerId}
          />
        ) : null}

        {renderShiftMenuModal()}
        {renderCreateSingleShiftModal()}
        {renderUpdateTimeModal()}
      </>
    );
  }

  return null;
};
