import { Route, Switch } from 'react-router-dom';
import NotFoundPage from '../not-found';
import AgencyAdmin from './Agency/AgencyAdmin';
import UserAdmin from './User/UserAdmin';

const AdminRoutes = () => {
  return (
    <Switch>
      <Route exact path='/Agency' component={AgencyAdmin} />
      <Route exact path='/User' component={UserAdmin} />
      <Route path='*' component={NotFoundPage} />
    </Switch>
  );
};

export default AdminRoutes;
