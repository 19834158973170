export const FormLabel = (props) => {
  if (props.tooltip) {
    return (
      <label
        className='control-label'
        htmlFor={props.name}
        style={{ display: 'block' }}
      >
        {props.label}
        <i
          className='fas fa-info-circle pull-right text-primary'
          data-toggle='tooltip'
          data-placement={props.tooltipPosition}
          title={props.tooltip}
          data-original-title={props.tooltip}
        />
      </label>
    );
  } else {
    return (
      <label className='control-label' htmlFor={props.name} style={props.style}>
        {props.label}
      </label>
    );
  }
};
