import { Row, Col, Button } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useAddPaymentAdjustmentToShiftMutation } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon } from 'pages/Utils/react-utils';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';

const containerName = 'addPaymentAdjustmentContainer';

export const PaymentAdjustmentForm = ({
  shiftId,
  isFormShowing,
  closeForm,
}: {
  shiftId: string;
  isFormShowing: boolean;
  closeForm: () => void;
}) => {
  const { mutate, isError, error, isLoading } =
    useAddPaymentAdjustmentToShiftMutation();

  const queryClient = useQueryClient();

  const handleSaveClick = () => {
    const $container = $(`#${containerName}`);

    if (!AppUtils.validateItems($container.find('.required'))) {
      return false;
    }

    const dataModel = AppUtils.getDataModel($container) as {
      Reason: string;
      Amount: string;
    };

    const params = {
      shiftId,
      reason: dataModel.Reason,
      amount: parseFloat(dataModel.Amount),
    };
    mutate(params, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ShiftPaymentAdjustments],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VehiclePlanForHirer],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ShiftPlanForHirer],
        });

        AppUtils.displaySuccess('Success', 'Payment Adjustment added');

        closeForm();
      },
    });
  };

  return (
    <div id={containerName}>
      {isFormShowing ? (
        <>
          <Row>
            <Field.InCol
              columns={12}
              name='Amount'
              label='Adjustment Amount'
              required
              maxLength={7}
              isNumber
            />
          </Row>

          <Row>
            <Field.InCol
              columns={12}
              name='Reason'
              label='Why are you adding this adjustment?'
              required
              isTextArea
              maxLength={1000}
            />
          </Row>

          <Row>
            <Col sm={12} style={{ textAlign: 'right' }} className='m-b-sm'>
              <Button
                bsStyle='danger'
                bsSize='small'
                onClick={closeForm}
                disabled={isLoading}
                className='m-r-sm'
              >
                <Icon icon='fa-times' isSpinning={isLoading} /> Cancel
              </Button>
              <Button
                bsSize='small'
                bsStyle='primary'
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                <Icon icon='fa-save' isSpinning={isLoading} /> Save Payment
                Adjustment
              </Button>
              <hr />
            </Col>
          </Row>

          {isError ? (
            <Row>
              <Col xs={12}>
                <ErrorAlert msg={extractErrorMessages(error)} />
              </Col>
            </Row>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
