import { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useAuth } from 'utils/auth';
import { userPermissions } from 'utils/userPermissions';
import { ContentContainer, ShiftContainer } from '../../Shift/ShiftViewer';
import { ApprovedShiftsListView } from './ApprovedShiftsListView';
import { CompletedShiftsListView } from './CompletedShiftsListView';
import { ContractorShiftsListView } from './ContractorShiftsListView';
import { PaidListView } from './PaidListView';
import { PaymentAuthListView } from './PaymentAuthListView';
import { PayrollReportListView } from './PayrollReportListView';
import { UnpaidListView } from './UnpaidListView';

export class ShiftsCompleted extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
      displayShiftId: null,
    };

    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.setDisplayShiftId = this.setDisplayShiftId.bind(this);
  }

  handleTabSelect(tabToMakeActive) {
    this.setState({ activeTab: tabToMakeActive });
  }

  setDisplayShiftId(displayShiftId) {
    this.setState({ displayShiftId });
  }

  render() {
    const { displayShiftId } = this.state;
    return (
      <>
        <ContentContainer displayShiftId={displayShiftId}>
          <TabsMain
            {...this.state}
            handleTabSelect={this.handleTabSelect}
            setDisplayShiftId={this.setDisplayShiftId}
          />
        </ContentContainer>
        <ShiftContainer
          displayShiftId={displayShiftId}
          setDisplayShiftId={this.setDisplayShiftId}
        />
      </>
    );
  }
}

const CompleteTabLabel = (props) => {
  return (
    <span
      className={`m-l-xs label ${
        props.variant === 'warning'
          ? 'label-warning'
          : props.variant === 'success'
          ? 'label-success'
          : props.variant === 'danger'
          ? 'label-danger'
          : ''
      }`}
    >
      {props.subTitle}
    </span>
  );
};

const TabsMain = ({ activeTab, handleTabSelect, setDisplayShiftId }) => {
  const { hasPermission } = useAuth();

  const canUserViewAwaitingPaymentAuthTab = hasPermission(
    userPermissions.shift.awaitingPaymentAuth.view
  );

  const canUserViewAwaitingPaymentTab = hasPermission(
    userPermissions.shift.awaitingPayment.view
  );
  let eventKey = 1;
  return (
    <div className='tabs-container'>
      <Tabs
        activeKey={activeTab}
        animation={true}
        onSelect={handleTabSelect}
        id='shift-admin-tabs'
        mountOnEnter={true}
      >
        <Tab
          eventKey={eventKey++}
          title={
            <CompleteTabLabel
              subTitle='Awaiting Time Approval'
              variant='warning'
            />
          }
        >
          <CompletedShiftsListView setDisplayShiftId={setDisplayShiftId} />
        </Tab>
        <Tab eventKey={eventKey++} title={'Time Approved'}>
          <ApprovedShiftsListView setDisplayShiftId={setDisplayShiftId} />
        </Tab>

        {canUserViewAwaitingPaymentAuthTab ? (
          <Tab eventKey={eventKey++} title='Contractor Shifts'>
            <ContractorShiftsListView setDisplayShiftId={setDisplayShiftId} />
          </Tab>
        ) : null}

        {canUserViewAwaitingPaymentAuthTab ? (
          <Tab
            eventKey={eventKey++}
            title={
              <CompleteTabLabel
                subTitle='Awaiting Payment Auth'
                variant='danger'
              />
            }
          >
            <PaymentAuthListView setDisplayShiftId={setDisplayShiftId} />
          </Tab>
        ) : null}

        {canUserViewAwaitingPaymentTab ? (
          <Tab eventKey={eventKey++} title='Awaiting Payment'>
            <UnpaidListView setDisplayShiftId={setDisplayShiftId} />
          </Tab>
        ) : null}

        {canUserViewAwaitingPaymentTab ? (
          <Tab eventKey={eventKey++} title='Payroll Report'>
            <PayrollReportListView setDisplayShiftId={setDisplayShiftId} />
          </Tab>
        ) : null}

        <Tab eventKey={eventKey++} title='Paid'>
          <PaidListView setDisplayShiftId={setDisplayShiftId} />
        </Tab>
      </Tabs>
    </div>
  );
};
