import { useState } from 'react';
import { Alert, Button, Panel } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useRemoveShiftFromInvoiceMutation } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon } from 'pages/Utils/react-utils';
import { InvoiceStatus, ShiftAwaitingInvoice } from 'types/Invoice';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { Stack } from 'components/Stack';
import { useDeleteAllItemsDialog } from './DeleteAllItemsDialog';
import { useInvoiceContext } from './InvoiceContext';

export const InvoiceShifts = () => {
  const { invoice, invoiceId, isEditable } = useInvoiceContext();
  const { invoiceItems } = invoice;

  const deleteAllItems = useDeleteAllItemsDialog();

  const [processingIds, setProcessingIds] = useState<string[]>([]);

  const queryClient = useQueryClient();

  const removeShift = useRemoveShiftFromInvoiceMutation();
  const handleRemoveClick = (invoiceItemId: string) => {
    if (!isEditable) {
      AppUtils.displayError('Error', 'Cannot remove shift from this invoice');
      return;
    }

    setProcessingIds((prev) => [...prev, invoiceItemId]);
    removeShift.mutate(
      { invoiceId, invoiceItemId },
      {
        onSuccess: () => {
          AppUtils.displaySuccess('Done', 'Shift removed from invoice');

          // Add item to awaitinginvoiceShifts cache
          queryClient.setQueryData<ShiftAwaitingInvoice[]>(
            [QueryKeys.GetInvoice, 'shiftsAwaitingInvoice', invoice.hirerId],
            (prev) => {
              // Get the shift from the invoice
              const removedInvoiceItem = invoiceItems.find(
                (item) => item.invoiceItemId === invoiceItemId
              );

              // If we can't find it, just retrun the previous value
              if (!removedInvoiceItem) {
                return prev;
              }

              const { shiftId, description, subTotal } = removedInvoiceItem;

              // The invoice item total includes vat but the shift awaiting invoice total does not so use the sub total from the removed invoice item to populate the shift awaiting invoice total going back into the cache
              const shiftToAdd: ShiftAwaitingInvoice = {
                shiftId,
                description,
                total: subTotal,
              };

              // Found it, so add it to the cache
              return prev ? [...prev, shiftToAdd] : [shiftToAdd];
            }
          );

          // Remove items from cache
          queryClient.setQueryData<typeof invoice>(
            [QueryKeys.GetInvoice, invoiceId],
            (prev) => {
              const newInvoiceItems = prev.invoiceItems.filter(
                (item) => item.invoiceItemId !== invoiceItemId
              );

              return {
                ...prev,
                invoiceItems: newInvoiceItems,
                subTotal: newInvoiceItems.reduce(
                  (acc, curr) => acc + curr.subTotal,
                  0
                ),
                vatAmount: newInvoiceItems.reduce(
                  (acc, curr) => acc + curr.vatAmount,
                  0
                ),
                total: newInvoiceItems.reduce(
                  (acc, curr) => acc + curr.total,
                  0
                ),
              };
            }
          );
        },
        onSettled: () => {
          setProcessingIds((prev) => prev.filter((id) => id !== invoiceItemId));
        },
      }
    );
  };

  if (invoiceItems.length === 0) {
    return (
      <Alert bsStyle='info'>
        There are no shifts on this invoice. Please add some above.
      </Alert>
    );
  }

  return (
    <Panel>
      <Panel.Heading>Shifts ({invoiceItems.length})</Panel.Heading>
      <Panel.Body>
        <ErrorAlertForQueryOrNull
          isError={removeShift.isError}
          error={removeShift.error}
        />

        {/* {removeShift.isLoading || query.isFetching ? <LinearProgress /> : null} */}

        <table className='table table-hover table-condensed table-striped'>
          <thead>
            <tr>
              {['Description', 'Subtotal', 'VAT', 'Total'].map((item) => {
                return (
                  <th
                    key={item}
                    style={{
                      textAlign: item !== 'Description' ? 'right' : 'left',
                    }}
                  >
                    {item}
                  </th>
                );
              })}
              <th style={{ textAlign: 'right' }}>
                {invoice.status === InvoiceStatus.Draft ? (
                  <>
                    <Button
                      bsStyle='danger'
                      bsSize='sm'
                      onClick={() => deleteAllItems.showDialog(invoice)}
                    >
                      Remove All
                    </Button>
                    {deleteAllItems.renderDialog()}
                  </>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {invoiceItems.map((item) => (
              <Row
                key={item.shiftId}
                item={item}
                isEditable={isEditable}
                handleRemoveClick={handleRemoveClick}
                isBeingProcessed={processingIds.includes(item.invoiceItemId)}
              />
            ))}
          </tbody>
        </table>
      </Panel.Body>
    </Panel>
  );
};

const Row = ({
  item,
  isEditable,
  handleRemoveClick,
  isBeingProcessed,
}: {
  item: ReturnType<typeof useInvoiceContext>['invoice']['invoiceItems'][0];
  isEditable: boolean;
  handleRemoveClick: (invoiceItemId: string) => void;
  isBeingProcessed: boolean;
}) => {
  const history = useHistory();
  return (
    <>
      <tr>
        <td>{item.description}</td>
        <td align='right'>{AppUtils.getCurrencyVal(item.subTotal)}</td>
        <td align='right'>{AppUtils.getCurrencyVal(item.vatAmount)}</td>
        <td align='right'>{AppUtils.getCurrencyVal(item.total)}</td>
        <td align='right'>
          <Stack direction='row' gap={5} style={{ justifyContent: 'flex-end' }}>
            <Button
              bsSize='small'
              bsStyle='info'
              onClick={() => history.push(`/Shift/Edit/${item.shiftId}`)}
            >
              Open &raquo;
            </Button>

            {isEditable ? (
              <Button
                bsSize='sm'
                bsStyle='danger'
                onClick={() => handleRemoveClick(item.invoiceItemId)}
                disabled={isBeingProcessed}
              >
                {isBeingProcessed ? <Icon isSpinning /> : 'Remove'}
              </Button>
            ) : null}
          </Stack>
        </td>
      </tr>
    </>
  );
};
