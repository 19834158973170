import { Component } from 'react';
import { Row, Button, Modal } from 'react-bootstrap';
import $ from 'jquery';
import { changePassword } from 'utils/api';
import { AppUtils } from '../Utils/app-utils';
import { Field, Icon } from '../Utils/react-utils';

export class ChangePasswordLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isSaving: false,
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  handleShow(e) {
    e.preventDefault();
    this.setState({ showModal: true });
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  async handleSaveClick() {
    var invalidClassName = AppUtils.invalidClassName;
    $('.' + invalidClassName).removeClass(invalidClassName);

    var isValid = true;
    var $item;
    var $modal = $('.modal');
    var $fields = $modal.find('.form-control');
    $fields.each(function (i, item) {
      $item = $(item);
      if ($item.val() === '') {
        isValid = false;

        $item.addClass(invalidClassName);
        AppUtils.addTooltip($item, 'Required');
      }
    });

    if (!isValid) {
      AppUtils.displayError(
        'Validation',
        'Please enter a value in all required fields'
      );
      $('.' + invalidClassName)
        .first()
        .focus();
    }

    if (isValid) {
      if ($('#PasswordNew').val() !== $('#PasswordConfirm').val()) {
        isValid = false;
        AppUtils.displayError(
          'Validation',
          'New password and confirmation do not match.'
        );
        $('#PasswordNew, #PasswordConfirm').addClass(invalidClassName);
        $('.' + invalidClassName)
          .first()
          .focus();
      }
    }

    if (!isValid) {
      return;
    }

    var model = {};
    var id;
    var $this;
    $fields.each(function () {
      $this = $(this);
      id = $this.attr('id');
      model[id] = $this.val();
    });

    this.setState({ isSaving: true });

    const data = await changePassword(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Password changed successfully');
      this.handleClose();
    });

    this.setState({ isSaving: false });

    // $.ajax({
    //   type: "POST",
    //   url: AppSettings.baseUrl + "Account/ChangePassword",
    //   data: JSON.stringify(model),
    // })
    //   .done(function (data) {
    //     AppUtils.handleAjaxDone(data, () => {
    //       AppUtils.displaySuccess("Done", "Password changed successfully");
    //       this.handleClose();
    //     });
    //   })
    //   .fail(function (xhr) {
    //     AppUtils.handleXhrFail(xhr);
    //   })
    //   .always(function () {
    //     this.setState({ isSaving: false });
    //   });
  }

  render() {
    return (
      <>
        <a href='#changePasswordLink' onClick={(e) => this.handleShow(e)}>
          <Icon className='fa-key' /> Change Password
        </a>
        <ChangePasswordModal
          {...this.state}
          handleClose={this.handleClose}
          handleSaveClick={this.handleSaveClick}
        />
      </>
    );
  }
}

class ChangePasswordModal extends Component {
  onEntered() {
    $('.modal #PasswordCurrent').focus();
  }

  render() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.handleClose}
        onEntered={this.onEntered}
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*To change your password, we'll send you an email with a Change Password link. Do you want to proceed?*/}
          <Row>
            <Field.InCol
              columns={12}
              name='PasswordCurrent'
              label='Current Password'
              type='password'
            />
          </Row>
          <Row>
            <Field.InCol
              columns={6}
              name='PasswordNew'
              label='New Password'
              type='password'
            />
            <Field.InCol
              columns={6}
              name='PasswordConfirm'
              label='Confirm New Password'
              type='password'
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.handleClose}>
            <i className='fas fa-times-circle' /> Close
          </Button>
          <ModalButton {...this.props} />
        </Modal.Footer>
      </Modal>
    );
  }
}

const ModalButton = (props) => {
  const icon = props.isSaving ? AppUtils.spinIconClass : 'fas fa-check';
  const text = props.isSaving ? 'Changing Password...' : 'Change Password';
  return (
    <Button
      bsStyle='success'
      onClick={props.handleSaveClick}
      disabled={props.isSaving}
    >
      <i className={icon}></i> {text}
    </Button>
  );
};
