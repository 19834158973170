import { Component, useMemo } from 'react';
import { AppUtils } from 'pages/Utils/app-utils';
import { WellSmall } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { getUsersByType } from 'utils/api';
import { Table } from 'components/Table';
import { UserUtils } from './UserUtils';

export class UserListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGetting: false,
      records: [],
      lastGet: null,
    };

    this.subscriber = this.subscriber.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();

    const _me = this;
    [UserUtils.Events.UserSaved].forEach((e) => {
      PubSub.subscribe(e, _me.subscriber);
    });
  }

  subscriber() {
    this.getData();
  }

  setIsGetting(val, lastGet) {
    let newState = { isGetting: val };
    if (lastGet) {
      newState['lastGet'] = lastGet;
    }
    this.setState(newState);
  }

  async getData() {
    this.setIsGetting(true);
    const data = await getUsersByType(this.props.type);
    if (data.records) {
      this.setState({ records: data.records });
    }
    this.setIsGetting(false, new Date());

    // $.ajax({
    //   url: `${AppSettings.baseUrl}Admin/Users?type=${this.props.type}`,
    // })
    //   .done(function (data) {
    //     if (data.status === AppUtils.ApiStatus.Success && data.records) {
    //       this.setState({ records: data.records });
    //     }
    //   })
    //   .always(function () {
    //     this.setIsGetting(false, new Date());
    //   });
  }

  render() {
    if (this.state.isGetting) {
      return (
        <WellSmall hasSpinner className='m-b-none'>
          Getting users...
        </WellSmall>
      );
    }
    if (this.state.records.length <= 0) {
      return <WellSmall className='m-b-none'>No users found.</WellSmall>;
    }
    return <TableContainer records={this.state.records} />;
  }
}

const TableContainer = ({ records }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'surname',
        Cell: ({ row }) => `${row.original.firstName} ${row.original.surname}`,
      },
      {
        Header: 'Email',
        accessor: 'emailAddress',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Created',
        accessor: (record) => record.createdDateTime,
        Cell: ({ value }) => AppUtils.formatDateTimeUtc(value),
      },
    ],
    []
  );

  return <Table columns={columns} records={records} />;
};
