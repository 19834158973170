import { AxiosResponse } from 'axios';
import format from 'date-fns/format';

const hasJsonStructure = (str: string) => {
  if (typeof str !== 'string') {
    return false;
  }

  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    return type === '[object Object]' || type === '[object Array]';
  } catch (err) {
    return false;
  }
};

export const nameof = <TForm>(field: keyof TForm) => field;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getErrorMessages = (error: any) => {
  if (error?.Messages) {
    return error.Messages.join('; ');
  }
  if (error?.messages) {
    return error.messages.join('; ');
  }

  if (error?.response?.data?.messages) {
    return error.response.data.messages;
  }
  if (error.message) {
    return [error.message];
  }
  if (!error) {
    return ['No Error Received'];
  }
  if (!error.response) {
    return ['No Error Response Received'];
  }
  if (error.response.status === 404) {
    return ['URL Not Found (404)'];
  }
  if (!error.response.data) {
    return ['No Error Data Received'];
  }
  if (!hasJsonStructure(JSON.stringify(error.response.data))) {
    return ['Error occurred. Invalid Error response received'];
  }

  if (!error.response.data.messages) {
    return ['No Error Messages Received'];
  }

  return error.response.data.messages;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractErrorMessages = (error: any) => {
  return getErrorMessages(error);
};

export const isObject = function (valueToCheck: object) {
  return !!valueToCheck && valueToCheck.constructor === Object;
};

export const getApiBaseUrl = () => process.env.REACT_APP_API_BASE_URL;

export const dateFormats = {
  dto: 'yyyy-MM-dd',
  nice: 'E, MMM do, yyyy',
  niceShort: 'MMM do',
  niceShortDay: 'E dd MMM',
  niceShortDayYear: 'E MMM do yyyy',
  niceShortDayWithTime: 'E dd MMM HH:mm',
  ukStandardWithTime: 'dd/MM/yyyy HH:mm',
};

export const formatDt = (date: Date, returnFormat: string) => {
  return format(date, returnFormat);
};

const _currentYear = new Date().getFullYear();

export const formatDate = (
  date: Date | string,
  returnFormat: string = dateFormats.dto,
  hideYearIfCurrent = false
) => {
  const dateToUse = typeof date === 'string' ? new Date(date) : date;
  if (hideYearIfCurrent) {
    if (returnFormat.includes('yyyy')) {
      if (dateToUse.getFullYear() === _currentYear) {
        returnFormat = returnFormat.replaceAll('yyyy', '');
      }
    }
  }

  return formatDt(dateToUse, returnFormat);
};

export const getNiceTime = (uglyTime: string) => {
  const split = uglyTime.split(':');
  split.pop();
  return split.join(':');
};

export const handleDownloadBlob = (response: AxiosResponse<Blob, unknown>) => {
  const url = window.URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.href = url;

  const header = response.headers['content-disposition'];
  const fileName = header
    ? header.split(';')[1].split('=')[1].replaceAll('"', '')
    : 'fileDownload.xlsx';

  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
};
