import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { useDeletePlanItemMutation } from 'hooks/queries/shiftPlanningQueries';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';

export const DeletePlanItemModal = ({
  isModalOpen,
  handleModalClose,
  shiftPlanItemId,
  hirerId,
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
  shiftPlanItemId: string;
  hirerId: string;
}) => {
  const queryClient = useQueryClient();

  const deletePlanItemsMutation = useDeletePlanItemMutation();
  const handleSubmitClick = () => {
    deletePlanItemsMutation.mutate(
      {
        shiftPlanItemId,
        hirerId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ShiftPlanForHirer],
          });
          AppUtils.displaySuccess('Success', 'Plan Item deleted successfully');
          handleModalClose();
        },
      }
    );
  };

  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Plan Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WellSmall>
          <p>
            You are going to delete this plan item. You will not be able to undo
            this.
          </p>
          <p>
            <strong>Please note</strong>: this will delete any draft shifts.
            However any shifts which have already been pushed to the worker will
            have to be cancelled manually.
          </p>
        </WellSmall>

        <Alert bsStyle='danger'>Are you sure you want to proceed?</Alert>

        {deletePlanItemsMutation.isError ? (
          <Row>
            <Col xs={12}>
              <ErrorAlert
                msg={extractErrorMessages(deletePlanItemsMutation.error)}
              />
            </Col>
          </Row>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>
          <i className='fa fa-times' /> Close
        </Button>
        <ModalSaveButton
          isSaved={deletePlanItemsMutation.isSuccess}
          isSaving={deletePlanItemsMutation.isLoading}
          onClick={handleSubmitClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

const ModalSaveButton = ({
  isSaving,
  isSaved,
  onClick,
}: {
  isSaving: boolean;
  isSaved: boolean;
  onClick: () => void;
}) => {
  const isDisabled = isSaving || isSaved;
  const buttonTitle = isSaving
    ? 'Deleting...'
    : isSaved
    ? 'Deleted!'
    : `Yes, Delete Plan Item`;
  return (
    <Button bsStyle='danger' onClick={onClick} disabled={isDisabled}>
      <Icon icon='fa-times' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};
