import { Component } from 'react';
// eslint-disable-next-line import/order
import $ from 'jquery';
import 'assets/bootstrap-datepicker/bootstrap-datepicker.1.8.0';
import 'assets/bootstrap-datepicker/bootstrap-datepicker3.v1.8.0.css';
import { Field } from './react-utils';

export class DatePicker extends Component {
  componentDidMount() {
    let options = {
      format: 'dd/mm/yyyy',
      orientation: 'bottom auto',
      autoclose: this.props.multidate ? false : true,
      todayHighlight: true,
      clearBtn: true,
      assumeNearbyYear: true,
      weekStart: 1,
      multidate: this.props.multidate ? this.props.multidate : false,
      multidateSeparator: ', ',
      changeDate: this.props.changeDate ? this.props.changeDate : null,
    };

    if (this.props.options) {
      options = { ...options, ...this.props.options };
    }

    const $dp = $(`#${this.props.name}`)
      .closest('.input-group.date')
      .datepicker(options);

    if (this.props.changeDate) {
      $dp.on('changeDate', this.props.changeDate);
    }
    if (this.props.clearDate) {
      $dp.on('clearDate', this.props.changeDate);
    }
  }

  render() {
    if (this.props.columns) {
      return <Field.InCol {...this.props} isDatepicker />;
    } else {
      return <Field.Main {...this.props} isDatepicker />;
    }
  }
}
