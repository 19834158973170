import { Dropdown } from 'components';
import { useHirerOptions } from 'hooks/queries/hirerQueries';
import { ICustomEvent } from 'types/GeneralTypes';

export const CustomerSelect = ({
  selectedCustomerId,
  setSelectedCustomerId,
  noFormGroup = true,
  label,
}: {
  selectedCustomerId: string | null;
  setSelectedCustomerId: (selectedCustomerId: string) => void;
  noFormGroup?: boolean;
  label?: string;
}) => {
  const { isLoading, isSuccess, options } = useHirerOptions();

  if (isLoading) {
    return <span> Loading...</span>;
  }

  if (isSuccess) {
    return (
      <Dropdown
        name='hirerId'
        value={selectedCustomerId}
        options={options}
        onChange={(e: ICustomEvent) => {
          setSelectedCustomerId(e.target.value);
        }}
        noFormGroup={noFormGroup}
        noLabel={label === undefined}
        label={label}
        required
      />
    );
  }
};
