import { useState, useCallback, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import loadImage from 'blueimp-load-image/js';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import { resetAppLogo, uploadAppLogo } from 'utils/api';
import { useAuth } from 'utils/auth';
// eslint-disable-next-line import/order
import 'react-image-crop/dist/ReactCrop.css';

// Increase pixel density for crop preview quality on retina screens.
const pixelRatio = window.devicePixelRatio || 1;

const _width = 600;
const _height = 200;

function LogoUpload() {
  const auth = useAuth();
  const [upImg, setUpImg] = useState();
  const [fileName, setFileName] = useState('');
  const [croppedBlob, setCroppedBlob] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const defaultCropConfig = { unit: '%', width: 100, height: 100 };
  const [crop, setCrop] = useState(defaultCropConfig);
  const [completedCrop, setCompletedCrop] = useState(null);

  const resetState = () => {
    setUpImg();
    setFileName('');
    setCroppedBlob(null);
    setCrop(defaultCropConfig);
    setCompletedCrop(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        loadImage(
          acceptedFiles[0],
          (img) => {
            setFileName(acceptedFiles[0].name);
            var base64data = img.toDataURL();
            setUpImg(base64data);
          },
          { orientation: true, canvas: true }
        );
      } else {
        alert('No files found');
      }
    },
  });

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    setCroppedBlob(null);
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob((blob) => setCroppedBlob(blob));
  }, [completedCrop]);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('file', croppedBlob, fileName);

    setIsSaving(true);
    const data = await uploadAppLogo(formData);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Logo Updated');
      if (data.logoPath) {
        AppUtils.publish(AppUtils.Events.AppLogoUpdated);
        resetState();
      }
    });
    setIsSaving(false);
  };

  return (
    <section>
      {auth?.user?.record?.logoPath && <ResetAppLogoSection />}
      <h5>Change App Logo</h5>
      <p>If you want to change the app logo, follow these steps:</p>
      <WellSmall>
        <ul className='m-b-none'>
          <li>
            Drag your logo file into the upload area below or click the upload
            area to choose your logo file
          </li>
          <li>Select which area of your logo file you want to use</li>
          <li>{`When you're happy, press the button called "Use This Logo"`}</li>
        </ul>
      </WellSmall>

      <h5>Upload</h5>
      <div
        {...getRootProps({
          className: 'dropzone m-b',
          style: {
            cursor: 'pointer',
            minHeight: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        })}
      >
        <input {...getInputProps()} />
        Drag your logo file here, or click to select a logo file
      </div>

      {upImg && (
        <>
          <h5>Choose Section</h5>
          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
            className='m-b'
            style={{ maxWidth: '500px' }}
            imageStyle={{ minWidth: '500px' }}
          />

          {completedCrop && (
            <>
              <div style={{}}>
                <h5>Preview</h5>
                <div
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: `${_width + 20}px`,
                    height: `${_height + 20}px`,
                    border: '1px solid #efefef',
                  }}
                >
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      width: Math.round(completedCrop?.width + 20 ?? 0),
                      height: Math.round(completedCrop?.height + 20 ?? 0),
                    }}
                  />
                </div>
                <Button
                  className='m-l'
                  bsStyle='success'
                  bsSize='sm'
                  onClick={handleSubmit}
                  disabled={isSaving}
                >
                  <Icon icon='fa-check' isSpinning={isSaving} /> Use This Logo
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </section>
  );
}

const ResetAppLogoSection = () => {
  const [isClearing, setIsClearing] = useState(false);
  const handleResetLogoClick = async () => {
    setIsClearing(true);
    const data = await resetAppLogo();
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Logo Cleared');
      AppUtils.publish(AppUtils.Events.AppLogoUpdated);
    });
    setIsClearing(false);
  };
  return (
    <>
      <h5>Reset App Logo</h5>
      <p>
        If you want to reset the app logo back to the default then you can do so
        by clicking the Reset App Logo button below.
      </p>
      <Button
        onClick={handleResetLogoClick}
        bsSize='sm'
        bsStyle='primary'
        className='m-b-sm'
        disabled={isClearing}
      >
        <Icon icon='fa-redo' isSpinning={isClearing} /> Reset App Logo
      </Button>
      <hr />
    </>
  );
};

const AgencyEditLogoTab = () => {
  return <LogoUpload />;
};

export default AgencyEditLogoTab;
