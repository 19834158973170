import { Col, Row } from 'react-bootstrap';
import { ModalContent } from 'components/Planner/ModalContent';
import { useShiftMenuModalContext } from 'components/Planner/ShiftMenuModalContext';
import {
  BackButton,
  CloseButton,
} from 'components/Planner/shiftMenuModalUtils';
import { ShiftNotesUi } from 'components/ShiftNotes';

export const UiStateViewNotes = () => {
  const { shiftToHandle } = useShiftMenuModalContext();
  return (
    <ModalContent
      title='View Notes'
      Body={<ViewNotesBody shiftId={shiftToHandle.shiftId} />}
      Footer={<ViewNotesActions />}
    />
  );
};

const ViewNotesBody = ({ shiftId }: { shiftId: string }) => {
  return <ShiftNotesUi shiftId={shiftId} />;
};

const ViewNotesActions = () => {
  return (
    <Row>
      <Col sm={4} style={{ textAlign: 'left' }}>
        <BackButton />
      </Col>
      <Col sm={8}>
        <CloseButton />
      </Col>
    </Row>
  );
};
