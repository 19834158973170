import { Component } from 'react';
import { Alert, Button, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import {
  createNewShiftConfig,
  getConfigForNewShiftConfigModal,
} from 'utils/api';
import { AppUtils } from '../Utils/app-utils';
import { Field, Icon, WellSmall, SpinIcon } from '../Utils/react-utils';

export const NewShiftConfigModal = (props) => {
  const history = useHistory();
  return <NewShiftConfigModalMain {...props} history={history} />;
};

class NewShiftConfigModalMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isSaved: false,
      isGettingConfig: false,
      hasFinishedGettingConfig: false,
      configuration: null,
    };

    this.onEntered = this.onEntered.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  async onEntered() {
    console.log('NewShiftConfigModal.onEntered');
    const me = this;
    me.setState({ isGettingConfig: true, configuration: null });
    const data = await getConfigForNewShiftConfigModal();
    AppUtils.handleAjaxDone(data, () => {
      if (data.configuration) {
        me.setState({ configuration: data.configuration });
        setTimeout(() => {
          document.getElementById('Name').focus();
        }, 250);
      } else {
        throw new Error('Could not get configuration');
      }
    });

    me.setState({ isGettingConfig: false, hasFinishedGettingConfig: true });
  }

  async handleSubmitClick() {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);

    this.setState({ isSaving: true });
    AppUtils.clearNotifications();
    const data = await createNewShiftConfig(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Template saved');
      this.setState({ isSaved: true });
      const navigateToNewShiftConfig = () => {
        this.props.history.push(
          `ShiftConfig/Edit/${data.record.shiftConfigId}`
        );
      };
      setTimeout(navigateToNewShiftConfig, 1000);
    });
    this.setState({ isSaving: false });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.showModal}
        onHide={this.props.handleCloseModalClick}
        onEntered={this.onEntered}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.state} />
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize='sm' onClick={this.props.handleCloseModalClick}>
            <i className='fa fa-times'></i> Cancel
          </Button>
          <ModalSaveButton
            {...this.props}
            {...this.state}
            onClick={this.handleSubmitClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = ({
  isGettingConfig,
  configuration,
  hasFinishedGettingConfig,
  isSaved,
}) => {
  if (isGettingConfig) {
    return (
      <WellSmall className='m-b-none'>
        <SpinIcon /> Getting configuration options...
      </WellSmall>
    );
  }
  if (!configuration && hasFinishedGettingConfig) {
    return (
      <Alert bsStyle='danger' className='m-b-none'>
        <Icon icon='fa-times' /> Could not get configuration options
      </Alert>
    );
  }
  if (!configuration) {
    return null;
  }
  if (isSaved) {
    return <SavedUi isSaved={isSaved} />;
  }
  return (
    <>
      <Row>
        <Field.InCol columns={12} name='Name' maxLength='100' required />
      </Row>
      <Row>
        <Field.InCol
          columns={12}
          name='ShiftIndustryType'
          label='Type'
          options={configuration.shiftIndustryTypeOptions}
          value={
            configuration.shiftIndustryTypeOptions.length === 1
              ? configuration.shiftIndustryTypeOptions[0]
              : null
          }
          isSelect
          required
        />
      </Row>
      <Row>
        <Field.InCol
          columns={12}
          name='HirerId'
          label='Customer'
          options={configuration.hirerOptions}
          isSelect
          required
        />
      </Row>
    </>
  );
};

const SavedUi = () => (
  <WellSmall className='text-success m-b-none'>
    <Icon icon='fa-check' /> Template created. Please wait a moment...
  </WellSmall>
);

const ModalSaveButton = (props) => {
  const isDisabled = props.isSaving || props.isSaved;
  const buttonTitle = props.isSaving
    ? 'Creating...'
    : props.isSaved
    ? 'Created'
    : 'Create Template';
  return (
    <Button
      bsSize='sm'
      bsStyle='success'
      onClick={props.onClick}
      disabled={isDisabled}
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
