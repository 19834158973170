import { useState } from 'react';
import { CustomerSelect } from 'components/CustomerSelect';
import { useHirerOptions } from './queries/hirerQueries';

const localStorageKey = 'selectedCustomerId';

const writeToLocalStorage = (selectedCustomerId: string) => {
  localStorage.setItem(localStorageKey, selectedCustomerId);
};

const getSelectedCustomerIdFromLocalStorage = () => {
  const selectedCustomerId = localStorage.getItem(localStorageKey);
  return selectedCustomerId;
};

export const useSelectedCustomer = () => {
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    getSelectedCustomerIdFromLocalStorage()
  );

  const handleSelectedCustomer = (selectedCustomerId: string) => {
    writeToLocalStorage(selectedCustomerId);
    setSelectedCustomerId(selectedCustomerId);
  };

  const renderCustomerSelect = () => {
    return (
      <CustomerSelect
        selectedCustomerId={selectedCustomerId}
        setSelectedCustomerId={handleSelectedCustomer}
      />
    );
  };

  const getHirerOptions = useHirerOptions();

  const getCustomerName = () => {
    if (!selectedCustomerId) {
      return null;
    }

    return (
      getHirerOptions.options.find(
        (option) => option.value === selectedCustomerId
      )?.text ?? null
    );
  };

  return {
    renderCustomerSelect,
    selectedCustomerId,
    getCustomerName,
  };
};
