import { useState } from 'react';
import { Panel, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelectedCustomer } from 'hooks';
import { useGetDailyViewQuery } from 'hooks/queries';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils } from 'pages/Utils/app-utils';
import { AlertWithIcon, SmallInfo, SmallMuted } from 'pages/Utils/react-utils';
import { DailyViewItem, DailyViewNoteItem } from 'types/ShiftTypes';
import DataTable from 'components/DataTable';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';

export const ShiftDaily = () => {
  const { selectedCustomerId, renderCustomerSelect } = useSelectedCustomer();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col sm={8}>
            <Panel.Title componentClass='h3'>Planner</Panel.Title>
          </Col>
          <Col sm={4}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ flexBasis: '56px' }}>Customer:</span>
              <div style={{ flexGrow: 1 }}>{renderCustomerSelect()}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ flexBasis: '56px' }}>Date:</span>
              <div style={{ flexGrow: 1 }}>
                <DatePicker
                  name={`StartDate`}
                  value={AppUtils.getDateString(selectedDate)}
                  changeDate={(e: { date: Date }) => {
                    setSelectedDate(e.date);
                  }}
                  noLabel
                  notInFormGroup
                />
              </div>
            </div>
          </Col>
        </Row>
      </Panel.Heading>
      <Panel.Body>
        {selectedCustomerId ? (
          <ShiftTable
            selectedCustomerId={selectedCustomerId}
            selectedDate={selectedDate}
          />
        ) : (
          <AlertWithIcon>Please select a Customer</AlertWithIcon>
        )}
      </Panel.Body>
    </Panel>
  );
};

const _tableId = 'shift-daily-view-table';

const ShiftTable = ({
  selectedCustomerId,
  selectedDate,
}: {
  selectedCustomerId: string;
  selectedDate: Date;
}) => {
  const { isSuccess, isLoading, isError, error, data } = useGetDailyViewQuery({
    hirerId: selectedCustomerId,
    date: selectedDate,
  });

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <ErrorAlertForQueryOrNull isError={isError} error={error} />;
  }

  if (isSuccess) {
    return (
      <DataTable
        records={data}
        tableId={_tableId}
        render={() => {
          return (
            <table
              className='table table-condensed table-striped table-hover'
              id={_tableId}
              data-order='[[ 0, "asc" ]]'
            >
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>When</th>
                  <th style={{ width: '15%' }}>Who</th>
                  <th style={{ width: '15%' }}>Status</th>
                  <th>Notes</th>
                  <th style={{ width: '10%' }} className='no-sort' />
                </tr>
              </thead>
              <tbody>
                {data.map((record, i) => (
                  <TableRow key={i} record={record} />
                ))}
              </tbody>
            </table>
          );
        }}
      />
    );
  }

  return null;
};

const TableRow = ({ record }: { record: DailyViewItem }) => {
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  return (
    <tr>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime, undefined, 'HH:mm')}
      </td>
      <td data-order={record.workerSurname}>
        {`${record.workerName}`}
        {record.vehicleReg ? (
          <>
            <br />
            <SmallInfo>{record.vehicleReg}</SmallInfo>
          </>
        ) : null}
        {record.trailerRefs ? (
          <>
            <br />
            <SmallMuted>{record.trailerRefs.join(', ')}</SmallMuted>
          </>
        ) : null}
      </td>
      <td>{record.status}</td>
      <td>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          {record.notes
            ? record.notes.map((note) => {
                return <NoteUi key={note.noteId} note={note} />;
              })
            : null}
        </div>
      </td>
      <td className='text-right'>
        <Link to={`/Shift/Edit/${record.shiftId}`}>Open &raquo;</Link>
      </td>
    </tr>
  );
};

const NoteUi = ({ note }: { note: DailyViewNoteItem }) => {
  return (
    <div
      style={{
        borderRadius: '8px',
        backgroundColor: '#6aa9ff',
        padding: '8px',
        border: '1px solid #fff',
      }}
    >
      <div
        className='pull-right'
        style={{ fontSize: '0.9em', color: '#fff', fontStyle: 'italic' }}
      >
        {note.createdBy}: {AppUtils.formatDateTimeUtc(note.createdDateTime)}
      </div>
      <div style={{ color: '#fff' }}>
        <i
          className={`fa fa-${
            note.isAvailableToWorker ? 'eye' : 'lock'
          } m-r-xs text-white`}
          data-toggle='tooltip'
          title={
            note.isAvailableToWorker
              ? 'Visible by worker'
              : 'Not visible by worker'
          }
        />
        {note.comments}
      </div>
    </div>
  );
};
