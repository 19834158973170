import { Component } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import useSubscription from 'hooks/useSubscription';
import $ from 'jquery';
import { ShiftUtils, hasShiftBeenRunningTooLong } from 'pages/Shift/ShiftUtils';
import { AppUtils, Constants } from 'pages/Utils/app-utils';
import { ListViewUtils } from 'pages/Utils/list-view-utils';
import { MapToDiv, WellSmall, Icon } from 'pages/Utils/react-utils';
import { getInProgressShifts } from 'utils/api';

export const InProgressShiftsListView = (props) => {
  const { isLoading, isFetching, error, data, refetch } = useQuery(
    ['getInProgressShifts'],
    getInProgressShifts,
    {
      refetchInterval: Constants.DefaultAutoRefetchFrequency,
    }
  );
  const records = data?.records || [];

  useSubscription([ShiftUtils.Events.ShiftCreated], refetch);

  if (error) {
    return (
      <WellSmall className='m-b-none'>Error encountered: {error}</WellSmall>
    );
  }
  if (isLoading) {
    return (
      <WellSmall className='m-b-none' hasSpinner>
        Getting Shifts...
      </WellSmall>
    );
  }
  if (records.length <= 0) {
    return (
      <WellSmall className='m-b-none'>No In Progress Shifts Found.</WellSmall>
    );
  }
  return (
    <TableContainer
      {...props}
      records={records}
      refetch={refetch}
      isFetching={isFetching}
    />
  );
};

const TableContainer = (props) => {
  const { isFetching, refetch } = props;
  return (
    <>
      <Alert bsStyle='info'>
        <Button
          onClick={refetch}
          bsSize='xs'
          bsStyle='primary'
          className='pull-right'
          disabled={isFetching}
        >
          <Icon icon='fa-redo' /> Refresh
        </Button>
        <Icon icon='fa-sync' /> Shifts which are In Progress but have not yet
        been Completed.
      </Alert>
      <Table {...props} />
    </>
  );
};

const tableId = 'in-progress-shifts-table';
class Table extends Component {
  componentDidMount() {
    this.initDataTables();
  }

  shouldComponentUpdate(nextProps) {
    if (
      JSON.stringify(nextProps.records) !== JSON.stringify(this.props.records)
    ) {
      return true;
    }

    return false;
  }

  UNSAFE_componentWillUpdate() {
    this.destroyDataTables();
  }

  componentDidUpdate() {
    this.initDataTables();
  }

  componentWillUnmount() {
    this.destroyDataTables();
  }

  initDataTables() {
    if (this.props.records.length > 0) {
      ListViewUtils.init(tableId);
    }
  }

  destroyDataTables() {
    const $table = $('#' + tableId);
    if ($table.length) {
      $table.DataTable().destroy();
    }
  }

  render() {
    return (
      <table
        className='table table-condensed table-striped table-hover'
        id={tableId}
        data-order='[[ 1, "asc" ]]'
      >
        <thead>
          <tr>
            <th>Details</th>
            <th>When</th>
            <th>Worker</th>
            <th>Started</th>
            <th className='no-sort' />
          </tr>
        </thead>
        <tbody>
          {this.props.records.map((item, i) => (
            <TableRow
              key={i}
              record={item}
              setDisplayShiftId={this.props.setDisplayShiftId}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

const TableRow = (props) => {
  const { record, setDisplayShiftId } = props;
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  const startedDtObj = ShiftUtils.GetShiftStartedData(record);
  const wasStartedMoreThan15HoursAgo = hasShiftBeenRunningTooLong(record);
  return (
    <tr>
      <td>
        <MapToDiv>{[record.companyName, record.name]}</MapToDiv>
      </td>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime)}
      </td>
      <td
        data-order={record.workerSurname}
      >{`${record.workerFirstName} ${record.workerSurname}`}</td>
      <td data-order={startedDtObj.value}>
        <InProgressContainer
          wasStartedMoreThan15HoursAgo={wasStartedMoreThan15HoursAgo}
        >
          <TooLongIcon record={record} />
          {startedDtObj.formatted}
        </InProgressContainer>
      </td>
      <td className='text-right'>
        <Button
          bsStyle='primary'
          bsSize='xs'
          onClick={() => setDisplayShiftId(record.shiftId)}
        >
          Open &raquo;
        </Button>
      </td>
    </tr>
  );
};

const InProgressContainer = ({ wasStartedMoreThan15HoursAgo, children }) => {
  if (wasStartedMoreThan15HoursAgo) {
    return (
      <span
        style={{ color: 'red' }}
        title={`Started more than ${ShiftUtils.ShiftRunWarningTimeHours} hours ago, please investigate.`}
        data-toggle='tooltip'
      >
        {children}
      </span>
    );
  } else {
    return <span>{children}</span>;
  }
};

const TooLongIcon = ({ record }) => {
  return (
    hasShiftBeenRunningTooLong(record) && (
      <Icon icon='fa-exclamation-circle' style={{ marginRight: '4px' }} />
    )
  );
};
