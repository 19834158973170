import { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useDeleteAllInvoiceItemsMutation } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { useModal } from 'hooks/useModal';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon } from 'pages/Utils/react-utils';
import { Invoice } from 'types/Invoice';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';

export const useDeleteAllItemsDialog = () => {
  const [invoice, setInvoice] = useState<Invoice | null>(null);

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  const showDialog = (invoice: Invoice) => {
    setInvoice(invoice);
    handleModalOpen();
  };

  const renderDialog = () => {
    if (!invoice) {
      return null;
    }

    return (
      <DeleteAllItemsDialog
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        onExited={() => setInvoice(null)}
        invoice={invoice}
      />
    );
  };

  return { showDialog, renderDialog };
};

const DeleteAllItemsDialog = ({
  isModalOpen,
  handleModalClose,
  onExited,
  invoice,
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
  onExited?: () => void;
  invoice: Invoice;
}) => {
  const queryClient = useQueryClient();
  const deleteRequest = useDeleteAllInvoiceItemsMutation();
  const handleDeleteClick = () => {
    deleteRequest.mutate(
      { invoiceId: invoice.invoiceId },
      {
        onSuccess: () => {
          AppUtils.publish('invoice.allItemsdeleted');
          AppUtils.displaySuccess('Thanks', 'All shifts removed');
          handleModalClose();

          // Invalidate awaiting invoice shifts cache
          queryClient.invalidateQueries([
            QueryKeys.GetInvoice,
            'shiftsAwaitingInvoice',
            invoice.hirerId,
          ]);

          // Remove items from cache
          queryClient.setQueryData<typeof invoice>(
            [QueryKeys.GetInvoice, invoice.invoiceId],
            (prev) => {
              return {
                ...prev,
                invoiceItems: [],
                subTotal: 0,
                vatAmount: 0,
                total: 0,
              };
            }
          );
        },
      }
    );
  };

  return (
    <Modal
      backdrop='static'
      show={isModalOpen}
      onHide={handleModalClose}
      onExited={onExited}
    >
      <Modal.Header closeButton>
        <Modal.Title>Remove All Shifts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You are going to remove all shifts from this invoice. Shifts will be
          returned to the awaiting invoice shifts list.
        </p>

        <Alert bsStyle='info'>
          You will not be able to undo this.{' '}
          <strong>Are you sure you want to proceed?</strong>
        </Alert>

        {deleteRequest.isError ? (
          <ErrorAlertForQueryOrNull
            isError={deleteRequest.isError}
            error={deleteRequest.error}
          />
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>Cancel</Button>
        <Button
          bsStyle='danger'
          onClick={handleDeleteClick}
          disabled={deleteRequest.isLoading}
        >
          <Icon icon='fa-times' isSpinning={deleteRequest.isLoading} /> Yes,
          Remove Shifts
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
