import { Panel, Row, Col, FormGroup, Alert, Button } from 'react-bootstrap';
import {
  useDownloadVehicleReportDataQuery,
  useGetVehicleReportQuery,
} from 'hooks/queries';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import { VehicleReportQueryResponse } from 'types/ReportingTypes';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { LoadingButton } from 'components/LoadingButton';
import './ReportingAdmin.scss';
import { ResponsiveBarChartHorizontal } from './common';
import { useReportDates } from './reportiUtils';

export const VehicleReport = () => {
  const { selectedStartDate, selectedEndDate, handleSetDate } =
    useReportDates();

  const queryParams = {
    startDate: selectedStartDate,
    endDate: selectedEndDate,
  };

  const query = useGetVehicleReportQuery(queryParams);

  const handleGnerateClick = () => {
    query.refetch();
  };

  const isLoading = query.isFetching;

  const downloadData = useDownloadVehicleReportDataQuery(queryParams);
  const handleDownloadClick = () => {
    downloadData.refetch();
  };

  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col sm={8}>
            <Panel.Title componentClass='h3'>Vehicle Report</Panel.Title>
          </Col>
          <Col sm={4} className='text-right'>
            <Button
              bsSize='sm'
              bsStyle='primary'
              onClick={handleDownloadClick}
              disabled={downloadData.isFetching}
            >
              <Icon icon='fa-download' isSpinning={downloadData.isFetching} />{' '}
              Download
            </Button>
          </Col>
        </Row>
      </Panel.Heading>
      <Panel.Body>
        <Row>
          <Col md={4}>
            <DatePicker
              name={`VehicleStartDate`}
              label='Start Date'
              value={AppUtils.getDateString(selectedStartDate)}
              changeDate={(e: { date: Date }) => {
                handleSetDate('start', e.date);
              }}
            />
          </Col>
          <Col md={4}>
            <DatePicker
              name={`VehicleEndDate`}
              label='End Date'
              value={AppUtils.getDateString(selectedEndDate)}
              changeDate={(e: { date: Date }) => {
                handleSetDate('end', e.date);
              }}
            />
          </Col>
          <Col md={4}>
            <FormGroup>
              <label className='control-label'>&nbsp;</label>
              <div>
                <LoadingButton
                  isLoading={isLoading}
                  onClick={handleGnerateClick}
                  text='Generate Report'
                  loadingText='Generating Report'
                  Icon={<Icon icon='fa-cog' />}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>

        <hr />

        {!query.data && isLoading ? (
          <WellSmall hasSpinner>Generating report...</WellSmall>
        ) : null}

        <ErrorAlertForQueryOrNull isError={query.isError} error={query.error} />

        {query.data ? (
          <ReportBody data={query.data} />
        ) : (
          <WellSmall>No report data</WellSmall>
        )}
      </Panel.Body>
    </Panel>
  );
};

const ReportBody = ({ data }: { data: VehicleReportQueryResponse }) => {
  return (
    <>
      <Row>
        <Col sm={12}>
          <VehicleByRegChart data={data} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <VehicleUtilisationPanel data={data} />
        </Col>
      </Row>
    </>
  );
};

const VehicleByRegChart = ({ data }: { data: VehicleReportQueryResponse }) => {
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title componentClass='h3'>
          Vehicle Profitability from{' '}
          {AppUtils.formatDateTimeUtc(data.startDate, undefined, 'DD MMM YYYY')}{' '}
          to{' '}
          {AppUtils.formatDateTimeUtc(data.endDate, undefined, 'DD MMM YYYY')}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        {data.vehicleRegData.length === 0 ? (
          <Alert bsStyle='warning'>No vehicle data found for this period</Alert>
        ) : (
          <div style={{ height: '600px', overflowY: 'auto' }}>
            <div style={{ height: '2000px' }}>
              <ResponsiveBarChartHorizontal
                data={data.vehicleRegData.sort((a, b) => {
                  return a.profit - b.profit;
                })}
                keys={['profit']}
                indexBy={'reg'}
                showLegend={false}
                colors={({ id, data }) => {
                  const val = data[id];
                  if (typeof val === 'number') {
                    if (val > 0) {
                      return '#ccebc5';
                    } else if (val < 0) {
                      return '#fbb4ae';
                    }
                  } else {
                    return 'grey';
                  }
                }}
              />
            </div>
          </div>
        )}
      </Panel.Body>
    </Panel>
  );
};

const VehicleUtilisationPanel = ({
  data,
}: {
  data: VehicleReportQueryResponse;
}) => {
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title componentClass='h3'>
          Vehicle Utilisation from{' '}
          {AppUtils.formatDateTimeUtc(data.startDate, undefined, 'DD MMM YYYY')}{' '}
          to{' '}
          {AppUtils.formatDateTimeUtc(data.endDate, undefined, 'DD MMM YYYY')}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        {!data.vehicleUtilisationByWeekData?.dates?.length ? (
          <Alert bsStyle='warning'>
            No vehicle utilisation data found for this period
          </Alert>
        ) : (
          <table
            id='utilisation-table'
            className='table table-hover table-condensed'
          >
            <thead>
              <tr>
                <th>Reg</th>
                {data.vehicleUtilisationByWeekData.dates.map((d) => (
                  <th key={d}>{d}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.vehicleUtilisationByWeekData.vehicleUtilisationByWeekDataArrays.map(
                (v) => (
                  <tr key={v.reg}>
                    <td>{v.reg}</td>
                    {v.utilisationsByWeek.map((u, i) => (
                      <td
                        key={i}
                        className={
                          u >= 80
                            ? 'utilisation-high'
                            : u >= 30
                            ? 'utilisation-medium'
                            : 'utilisation-low'
                        }
                      >
                        {u}%
                      </td>
                    ))}
                  </tr>
                )
              )}
            </tbody>
          </table>
        )}
      </Panel.Body>
    </Panel>
  );
};
