import { ReactNode, useMemo } from 'react';
import { Alert, Panel, Row, Col, Button } from 'react-bootstrap';
import { CellProps, Column, UseSortByColumnOptions } from 'react-table';
import { useQueryClient } from '@tanstack/react-query';
import { Dropdown, FormLabel } from 'components';
import { useSelectedCustomer } from 'hooks';
import { useGetUnpaidShiftsQuery } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import useFilterData from 'hooks/useFilterData';
import { useListViewCheckbox } from 'hooks/useListViewCheckbox';
import useSubscription from 'hooks/useSubscription';
import moment from 'moment';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { Checkbox } from 'pages/Utils/Checkbox';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils, Constants } from 'pages/Utils/app-utils';
import { DashboardPanel, Icon, SmallInfo } from 'pages/Utils/react-utils';
import { ICustomEvent } from 'types/GeneralTypes';
import { UnpaidShift } from 'types/ShiftTypes';
import { useAuth } from 'utils/auth';
import { userPermissions } from 'utils/userPermissions';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { Table } from 'components/Table';
import { PaySelectedButton } from '../_Components';
import { DownloadCsvButton } from './DownloadSelectedButton';

type Shift = UnpaidShift;

const _filterFieldPrefix = 'awaitingPaymentFilter';

export const UnpaidListView = ({
  setDisplayShiftId,
}: {
  setDisplayShiftId: (shiftId: string) => void;
}) => {
  const { selectedCustomerId, renderCustomerSelect } = useSelectedCustomer();

  const { filterData, getDataModel, handleFilterChange } = useFilterData(
    _filterFieldPrefix,
    -30,
    0
  );

  const { isFetching, isSuccess, isError, error, data, refetch } =
    useGetUnpaidShiftsQuery(
      { ...getDataModel(), hirerId: selectedCustomerId },
      {
        onSuccess: (data) => {
          handleNewRecords(data.records);
        },
      }
    );

  const records = useMemo(() => {
    return data?.records ?? [];
  }, [data?.records]);

  const {
    resetSelectedRecords,
    recordIdsSelected,
    selectedTotal,
    handleCheckboxChange,
    handleNewRecords,
  } = useListViewCheckbox({
    records,
    canRecordBeChecked: (shift) => {
      return shift.paymentAmount !== null;
    },
  });

  const queryClient = useQueryClient();
  useSubscription(
    [ShiftUtils.Events.ShiftPaymentsAuthorised, ShiftUtils.Events.ShiftsPaid],
    () => {
      queryClient.invalidateQueries([QueryKeys.UnpaidShifts]);
      resetSelectedRecords();
    }
  );

  const handleSearchClick = () => {
    refetch();
  };

  return (
    <>
      <Panel>
        <Panel.Heading>
          <Row>
            <Col sm={4}>
              Shifts Awaiting Payment{' '}
              <Icon
                icon='fa-info-circle'
                tooltip="Shifts which have been authorised for payment but not yet paid. Choose which shifts you want to mark as paid and press 'Mark as Paid'."
              />
            </Col>

            <Col sm={8} className='text-right'>
              <DownloadSelectedButtonContainer
                records={records}
                recordIdsSelected={recordIdsSelected}
              />
              <PaySelectedButtonContainer
                records={records}
                recordIdsSelected={recordIdsSelected}
                selectedTotal={selectedTotal}
              />
            </Col>
          </Row>
        </Panel.Heading>
        <Panel.Body>
          <Filters
            filterData={filterData}
            handleSearchClick={handleSearchClick}
            handleFilterChange={handleFilterChange}
            isFetching={isFetching}
            renderCustomerSelect={renderCustomerSelect}
          />

          <Summary
            recordIdsSelected={recordIdsSelected}
            selectedTotal={selectedTotal}
            records={records}
          />

          <ErrorAlertForQueryOrNull isError={isError} error={error} />

          {isSuccess && records.length <= 0 ? (
            <Alert>
              No shifts found. Update the filters above and try again.
            </Alert>
          ) : null}

          {records.length > 0 ? (
            <TableContainer
              records={records}
              recordIdsSelected={recordIdsSelected}
              handleCheckboxChange={handleCheckboxChange}
              setDisplayShiftId={setDisplayShiftId}
            />
          ) : null}
        </Panel.Body>
      </Panel>
    </>
  );
};

type FilterState = {
  [key: string]: string;
};

const Filters = ({
  isFetching,
  handleSearchClick,
  filterData,
  handleFilterChange,
  renderCustomerSelect,
}: {
  isFetching: boolean;
  filterData: FilterState;
  handleFilterChange: (e: ICustomEvent) => void;
  handleSearchClick: () => void;
  renderCustomerSelect: () => ReactNode;
}) => {
  const handleChangeDate = (name: string, date: Date) =>
    handleFilterChange({
      target: { name, value: moment(date).format('YYYY-MM-DD') },
    });

  console.log('filterData', filterData);
  return (
    <Panel id='ApprovedFilterContainer'>
      <Panel.Body>
        <Row>
          <DatePicker
            columns={3}
            name={`${_filterFieldPrefix}FromDate`}
            label='Date From'
            value={AppUtils.getDateString(
              filterData[`${_filterFieldPrefix}FromDate`]
            )}
            changeDate={(e: { date: Date }) => {
              handleChangeDate(`${_filterFieldPrefix}FromDate`, e.date);
            }}
            required
            notInFormGroup
          />
          <DatePicker
            columns={3}
            name={`${_filterFieldPrefix}ToDate`}
            label='Date To'
            value={AppUtils.getDateString(
              filterData[`${_filterFieldPrefix}ToDate`]
            )}
            changeDate={(e: { date: Date }) => {
              handleChangeDate(`${_filterFieldPrefix}ToDate`, e.date);
            }}
            required
            notInFormGroup
          />
          <Col sm={3}>
            <Dropdown
              name={`${_filterFieldPrefix}WorkerType`}
              value={filterData[`${_filterFieldPrefix}WorkerType`]}
              options={['PAYE', 'Salary', 'Agency Worker', 'Contractor']}
              onChange={(e: ICustomEvent) => {
                handleFilterChange(e);
              }}
              label={'Worker Type'}
              required
            />
          </Col>
          <Col sm={3}>
            <FormLabel name='HirerId' label='Customer' />
            {renderCustomerSelect()}
          </Col>
        </Row>
      </Panel.Body>
      <Panel.Footer className='text-right'>
        <SearchButton
          isGetting={isFetching}
          handleSearchClick={handleSearchClick}
        />
      </Panel.Footer>
    </Panel>
  );
};

const SearchButton = ({
  isGetting,
  handleSearchClick,
}: {
  isGetting: boolean;
  handleSearchClick: () => void;
}) => {
  return (
    <Button
      bsStyle='primary'
      bsSize='sm'
      onClick={handleSearchClick}
      disabled={isGetting}
    >
      <Icon icon='fa-search' isSpinning={isGetting} />{' '}
      {isGetting ? 'Searching' : 'Search'}
    </Button>
  );
};

const PaySelectedButtonContainer = (props: {
  records: Shift[];
  recordIdsSelected: string[];
  selectedTotal: number;
}) => {
  const { hasPermission } = useAuth();

  const canMarkAsPaid = hasPermission(
    userPermissions.shift.awaitingPayment.markAsPaid
  );

  if (!canMarkAsPaid) {
    return null;
  }

  if (props.records.length <= 0) {
    return null;
  }
  return <PaySelectedButton {...props} />;
};

const DownloadSelectedButtonContainer = (props: {
  records: Shift[];
  recordIdsSelected: string[];
}) => {
  if (props.records.length <= 0) {
    return null;
  }
  return <DownloadCsvButton {...props} />;
};

const Summary = ({
  records,
  recordIdsSelected,
  selectedTotal,
}: {
  records: Shift[];
  recordIdsSelected: string[];
  selectedTotal: number;
}) => {
  if (records.length <= 0) {
    return null;
  }
  const containerStyle = { marginBottom: '0' };
  const total = AppUtils.formatNumber(
    records.reduce((total, record) => total + record.paymentAmount, 0)
  );

  const getContent = (value: string | number, isSelected = false) => (
    <h1
      className='no-margins'
      style={{
        color: isSelected
          ? Constants.Colours.success
          : Constants.Colours.primary,
      }}
    >
      {value}
    </h1>
  );
  return (
    <Row
      style={{
        background: '#efefef',
        marginBottom: '10px',
        padding: '10px 0',
      }}
    >
      <Col sm={3}>
        <DashboardPanel
          containerStyle={containerStyle}
          title='Total Amount'
          content={getContent(`£${total}`)}
        />
      </Col>
      <Col sm={3}>
        <DashboardPanel
          containerStyle={containerStyle}
          title='Total Shift Qty'
          content={getContent(records.length)}
        />
      </Col>
      <Col sm={3}>
        <DashboardPanel
          containerStyle={containerStyle}
          title='Selected Amount'
          content={getContent(`£${AppUtils.formatNumber(selectedTotal)}`, true)}
        />
      </Col>
      <Col sm={3}>
        <DashboardPanel
          containerStyle={containerStyle}
          title='Selected Shift Qty'
          content={getContent(recordIdsSelected.length, true)}
        />
      </Col>
    </Row>
  );
};

const TableContainer = ({
  handleCheckboxChange,
  recordIdsSelected,
  records,
  setDisplayShiftId,
}: {
  handleCheckboxChange: (id: string) => void;
  recordIdsSelected: string[];
  records: Shift[];
  setDisplayShiftId: (shiftId: string) => void;
}) => {
  const isRecordSelected = (id: string) => recordIdsSelected.indexOf(id) > -1;
  const areAllSelected =
    records.length > 0 && records.length === recordIdsSelected.length;

  const columns: (Column<Shift> & UseSortByColumnOptions<Shift>)[] = [
    {
      accessor: 'shiftId',
      disableSortBy: true,
      width: '20px',
      Header: () => (
        <Checkbox
          id={`checkbox_all`}
          checked={areAllSelected}
          onChange={() => handleCheckboxChange('all')}
        />
      ),
      Cell: (cellProps: CellProps<Shift>) => {
        const record = cellProps.row.original;
        return (
          <Checkbox
            id={`checkbox_${record.shiftId}`}
            checked={isRecordSelected(record.shiftId)}
            onChange={() => handleCheckboxChange(record.shiftId)}
          />
        );
      },
    },
    {
      Header: 'Shift',
      accessor: 'name',
      Cell: (cellProps: CellProps<Shift>) => (
        <>
          {cellProps.row.original.name}
          <div>
            <SmallInfo>{cellProps.row.original.companyName}</SmallInfo>
          </div>
        </>
      ),
    },
    {
      Header: 'When',
      accessor: (record: Shift) =>
        AppUtils.concatDateAndTime(record.shiftDate, record.shiftTime),
      Cell: ({ value }: { value: string }) =>
        AppUtils.formatDateTimeNonUtc(value),
    },
    {
      Header: 'Worker',
      accessor: (record: Shift) => record.workerSurname,
      Cell: (cellProps: CellProps<Shift>) => (
        <>
          {cellProps.row.original.workerFirstName}{' '}
          {cellProps.row.original.workerSurname}
        </>
      ),
    },
    {
      Header: 'Payment Amount £',
      accessor: (record: Shift) => record.paymentAmount,
      Cell: ({ value }: { value: string }) => (
        <span>{AppUtils.formatNumber(value)}</span>
      ),
    },
    {
      id: 'open',
      accessor: (record: Shift) => record.shiftId,
      disableSortBy: true,
      Cell: (cellProps: CellProps<Shift>) => {
        const shift = cellProps.row.original;
        return (
          <>
            <Button
              className='pull-right'
              bsStyle='primary'
              bsSize='xs'
              onClick={() => setDisplayShiftId(shift.shiftId)}
            >
              Open &raquo;
            </Button>
          </>
        );
      },
    },
  ];

  return <Table columns={columns} records={records} />;
};
