import BackgroundImage from './BackgroundImage';
import './Section.scss';

function Section(props) {
  const {
    bg,
    textColor,
    bgImage,
    bgImageOpacity,
    bgImageRepeat,
    className,
    children,
    size,
    ...otherProps
  } = props;

  return (
    <section
      className={
        'SectionComponent py-5 position-relative' +
        (bg ? ` bg-${bg}` : '') +
        (textColor ? ` text-${textColor}` : '') +
        (className ? ` ${className}` : '')
      }
      {...otherProps}
    >
      {bgImage && (
        <BackgroundImage
          image={bgImage}
          opacity={bgImageOpacity}
          repeat={bgImageRepeat}
        />
      )}

      <div
        className={
          '' +
          (['md', 'lg'].includes(size) ? ' py-md-5' : '') +
          (size === 'lg' ? ' my-md-5' : '')
        }
      >
        {children}
      </div>
    </section>
  );
}

export default Section;
