import { Alert, Button, Col, Panel, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export const Uploads = () => {
  return (
    <Panel>
      <Panel.Heading>Uploads</Panel.Heading>
      <Panel.Body>
        <Row>
          <Col sm={6}>
            <UploadAlert
              title='Shift Mileage'
              text='Upload mileage for shifts. The mileage will be added to the shifts and will be used to calculate the charges for shifts where applicable.'
              link='Shift/UploadMileage'
              btnTitle='Upload Mileage'
            />
          </Col>
          <Col sm={6}>
            <UploadAlert
              title='Shift Times'
              text='Upload completed shift times. The times will be added to the shifts and will be used to calculate the payments for shifts where applicable.'
              link='Shift/UploadTimes'
              btnTitle='Upload Times'
            />
          </Col>
        </Row>
      </Panel.Body>
    </Panel>
  );
};

const UploadAlert = ({
  title,
  text,
  link,
  btnTitle,
}: {
  title: string;
  text: string;
  link: string;
  btnTitle: string;
}) => {
  const history = useHistory();
  return (
    <Alert bsStyle='info'>
      <h4 className='alert-heading>'>{title}</h4>
      <p className='m-b-sm'>{text}</p>

      <Button bsSize='sm' bsStyle='primary' onClick={() => history.push(link)}>
        {btnTitle}
      </Button>
    </Alert>
  );
};
