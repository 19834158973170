import { ReactNode } from 'react';
import { ShiftEdit } from './Edit';

interface RecordContainerProps {
  displayShiftId: string;
  children: ReactNode;
}

export const ContentContainer = ({
  displayShiftId,
  children,
}: RecordContainerProps) => {
  return (
    <div style={{ display: displayShiftId ? 'none' : 'block' }}>{children}</div>
  );
};

export const ShiftContainer = ({
  displayShiftId,
  setDisplayShiftId,
}: RecordContainerProps & { setDisplayShiftId: () => void }) => {
  return (
    <>
      <div style={{ display: displayShiftId ? 'block' : 'none' }}>
        {displayShiftId ? (
          <ShiftEdit
            shiftId={displayShiftId}
            setDisplayShiftId={setDisplayShiftId}
          />
        ) : null}
      </div>
    </>
  );
};
