import { Button, Col, Row } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { Chip } from 'components';
import {
  useUpdateShiftPlanSingleShiftWorkerMutation,
  useWorkerOptions,
} from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall } from 'pages/Utils/react-utils';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { ModalContent } from 'components/Planner/ModalContent';
import { useShiftMenuModalContext } from 'components/Planner/ShiftMenuModalContext';
import {
  BackButton,
  CloseButton,
} from 'components/Planner/shiftMenuModalUtils';

export const UpdateWorkerModalContent = () => {
  const { shiftPlanItemToHandle, shiftToHandle, handleModalClose, workerType } =
    useShiftMenuModalContext();

  const queryClient = useQueryClient();

  const { mutate, isError, error, isLoading, isSuccess } =
    useUpdateShiftPlanSingleShiftWorkerMutation();

  const handleSubmitClick = () => {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }

    const dataModel = AppUtils.getDataModel($modal) as { WorkerId: string };
    const { shiftPlanItemId } = shiftPlanItemToHandle;
    const { shiftId } = shiftToHandle;
    const params = {
      shiftPlanItemId,
      shiftId,
      workerId: dataModel.WorkerId,
    };

    if (params.workerId === shiftToHandle.workerId) {
      AppUtils.displayError(
        'Error',
        `The ${workerType} you have selected is already allocated with this shift. Please select a different ${workerType}.`,
        false,
        'workerAlreadyAssociatedError'
      );
      return;
    }

    mutate(params, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VehiclePlanForHirer],
        });
        AppUtils.displaySuccess('Success', 'Worker updated successfully');
        handleModalClose();
      },
    });
  };

  return (
    <ModalContent
      title={`Change ${workerType}`}
      Body={<UiStateUpdateShiftWorker isError={isError} error={error} />}
      Footer={
        <UpdateShiftWorkerActions
          isSuccess={isSuccess}
          isLoading={isLoading}
          handleSubmitClick={handleSubmitClick}
        />
      }
    />
  );
};

export const UiStateUpdateShiftWorker = ({
  isError,
  error,
}: {
  isError: boolean;
  error: unknown;
}) => {
  const { shiftPlanItemToHandle, shiftToHandle, workerType } =
    useShiftMenuModalContext();
  const { workerOptions } = useWorkerOptions(
    shiftPlanItemToHandle.shiftIndustryType
  );
  return (
    <>
      <WellSmall>
        <p>
          You are going to update the {workerType} on this shift. The current{' '}
          {workerType} is{' '}
          <Chip value={shiftToHandle.workerName} variant='success' />
        </p>

        <p>
          <strong>Please Note: </strong>This will not affect any other shifts
          associated with this row.
        </p>
      </WellSmall>

      <Row>
        <Field.InCol
          columns={12}
          name={`WorkerId`}
          label={`New ${workerType}`}
          options={workerOptions}
          defaultValue={shiftToHandle.workerId}
          value={shiftToHandle.workerId}
          isSelect
          required
        />
      </Row>

      {isError ? (
        <Row>
          <Col xs={12}>
            <ErrorAlert msg={extractErrorMessages(error)} />
          </Col>
        </Row>
      ) : null}
    </>
  );
};

const UpdateShiftWorkerActions = ({
  isSuccess,
  isLoading,
  handleSubmitClick,
}: {
  isSuccess: boolean;
  isLoading: boolean;
  handleSubmitClick: () => void;
}) => {
  return (
    <Row>
      <Col sm={4} style={{ textAlign: 'left' }}>
        <BackButton />
      </Col>
      <Col sm={8}>
        <CloseButton />
        <ModalSaveButton
          isSaved={isSuccess}
          isSaving={isLoading}
          onClick={handleSubmitClick}
        />
      </Col>
    </Row>
  );
};

const ModalSaveButton = ({
  isSaving,
  isSaved,
  onClick,
}: {
  isSaving: boolean;
  isSaved: boolean;
  onClick: () => void;
}) => {
  const { workerType } = useShiftMenuModalContext();
  const isDisabled = isSaving || isSaved;
  const buttonTitle = isSaving
    ? 'Updating...'
    : isSaved
    ? 'Updated!'
    : `Update ${workerType}`;
  return (
    <Button bsStyle='success' onClick={onClick} disabled={isDisabled}>
      <Icon icon='fa-check' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};
