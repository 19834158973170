import { ComponentProps } from 'react';
import { Button as BSButton } from 'react-bootstrap';
import { Icon } from 'pages/Utils/react-utils';

type ButtonProps = ComponentProps<typeof BSButton> & {
  isLoading?: boolean;
  icon?: string;
};

export const Button = (props: ButtonProps) => {
  const { children, isLoading, icon, ...rest } = props;
  return (
    <BSButton
      bsSize='sm'
      bsStyle='primary'
      disabled={props.disabled || isLoading}
      {...rest}
    >
      {icon ? (
        <>
          <Icon icon={icon} isSpinning={isLoading} />{' '}
        </>
      ) : !icon && isLoading ? (
        <>
          <Icon isSpinning={isLoading} />{' '}
        </>
      ) : null}
      {children}
    </BSButton>
  );
};
