import { Component } from 'react';
import { Button, Col, Panel, Row, Well } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import useFilterData from 'hooks/useFilterData';
import useGetConfig from 'hooks/useGetConfig';
import useSubscription from 'hooks/useSubscription';
import $ from 'jquery';
import moment from 'moment';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { DatePicker } from 'pages/Utils/DatePicker';
import { TimeDropdown } from 'pages/Utils/TimeDropdown';
import { AppUtils, Constants } from 'pages/Utils/app-utils';
import { ListViewUtils } from 'pages/Utils/list-view-utils';
import {
  Field,
  MapToDiv,
  SmallMuted,
  WellSmall,
  Icon,
} from 'pages/Utils/react-utils';
import {
  getPreAllocatedListViewConfig,
  getPreAllocatedShifts,
} from 'utils/api';

const _filterContainerId = 'PreAllocatedFiltersContainer';
const _filterFieldPrefix = 'preAllocatedFilter';

export const PreAllocatedShiftsListView = (props) => {
  const { isGettingConfig, configuration } = useGetConfig(
    getPreAllocatedListViewConfig
  );
  const { filterData, getDataModel, handleFilterChange } =
    useFilterData(_filterFieldPrefix);
  const { data, refetch, isLoading, updatedAt } = useQuery(
    ['preAllocatedShifts', getDataModel()],
    () => getPreAllocatedShifts(getDataModel()),
    {
      enabled: !!configuration,
      refetchInterval: Constants.DefaultAutoRefetchFrequency,
    }
  );
  const records = data?.records || [];

  useSubscription(
    [ShiftUtils.Events.ShiftCreated, ShiftUtils.Events.ShiftCancelled],
    refetch
  );

  if (isGettingConfig) {
    return <GettingConfigMsg isGettingConfig={isGettingConfig} />;
  }
  if (!configuration) {
    return null;
  }
  return (
    <>
      <Filters
        updatedAt={updatedAt}
        isGetting={isLoading}
        configuration={configuration}
        filterData={filterData}
        handleSearchClick={refetch}
        handleFilterChange={handleFilterChange}
      />
      <TableContainer
        records={records}
        setDisplayShiftId={props.setDisplayShiftId}
      />
    </>
  );
};

const GettingConfigMsg = ({ isGettingConfig }) => {
  if (!isGettingConfig) {
    return null;
  }
  return (
    <WellSmall hasSpinner>
      Please wait while the Pre-Allocated Shift section is configured...
    </WellSmall>
  );
};

const Filters = (props) => {
  const {
    updatedAt,
    handleSearchClick,
    isGetting,
    configuration,
    filterData,
    handleFilterChange,
  } = props;

  const handleChangeDate = (name, date) =>
    handleFilterChange({
      target: { name, value: moment(date).format('YYYY-MM-DD') },
    });

  return (
    <Panel id={_filterContainerId}>
      <Panel.Body>
        <Row>
          <Col sm={4}>
            <DatePicker
              name={`${_filterFieldPrefix}FromDate`}
              label='From Date'
              value={AppUtils.getDateString(
                filterData[`${_filterFieldPrefix}FromDate`]
              )}
              changeDate={(e) =>
                handleChangeDate(`${_filterFieldPrefix}FromDate`, e.date)
              }
            />
            <TimeDropdown
              name={`${_filterFieldPrefix}FromTime`}
              label='From Time'
              placeholder='From Time'
              value={filterData[`${_filterFieldPrefix}FromTime`]}
              onChange={handleFilterChange}
            />
          </Col>

          <Col sm={4}>
            <DatePicker
              name={`${_filterFieldPrefix}ToDate`}
              label='To Date'
              value={AppUtils.getDateString(
                filterData[`${_filterFieldPrefix}ToDate`]
              )}
              changeDate={(e) =>
                handleChangeDate(`${_filterFieldPrefix}ToDate`, e.date)
              }
            />
            <TimeDropdown
              name={`${_filterFieldPrefix}ToTime`}
              label='To Time'
              placeholder='To Time'
              value={filterData[`${_filterFieldPrefix}ToTime`]}
              onChange={handleFilterChange}
            />
          </Col>

          <Col sm={4}>
            <Field.Main
              name={`${_filterFieldPrefix}HirerId`}
              label='Customer'
              value={filterData[`${_filterFieldPrefix}HirerId`]}
              options={configuration.hirerOptions}
              isSelect
              onChange={handleFilterChange}
            />
          </Col>
        </Row>
      </Panel.Body>
      <Panel.Footer className='text-right'>
        {updatedAt && (
          <SmallMuted className='pull-left'>
            Last updated {AppUtils.getTimeAgo(updatedAt)}
          </SmallMuted>
        )}
        <Button
          className='m-b-none'
          bsStyle='primary'
          bsSize='sm'
          onClick={handleSearchClick}
          disabled={isGetting}
        >
          <Icon icon='fa-search' isSpinning={isGetting} />{' '}
          {isGetting ? 'Searching' : 'Search'}
        </Button>
      </Panel.Footer>
    </Panel>
  );
};

const TableContainer = (props) => {
  if (props.records.length <= 0) {
    return (
      <WellSmall className='m-b-none'>No Pre-Allocated Shifts Found.</WellSmall>
    );
  }
  return (
    <>
      <Well>
        <Icon icon='fa-user-check' /> Shifts which have been Pre-Allocated but
        not yet reviewed.
      </Well>
      <Table {...props} />
    </>
  );
};

const tableId = 'pre-allocated-shifts-table';
class Table extends Component {
  componentDidMount() {
    this.initDataTables();
  }

  shouldComponentUpdate(nextProps) {
    if (
      JSON.stringify(nextProps.records) !== JSON.stringify(this.props.records)
    ) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillUpdate() {
    this.destroyDataTables();
  }

  componentDidUpdate() {
    this.initDataTables();
  }

  componentWillUnmount() {
    this.destroyDataTables();
  }

  initDataTables() {
    if (this.props.records.length > 0) {
      ListViewUtils.init(tableId);
    }
  }

  destroyDataTables() {
    const $table = $('#' + tableId);
    if ($table.length) {
      $table.DataTable().destroy();
    }
  }

  render() {
    const { records } = this.props;
    if (!records.length) {
      return null;
    }
    return (
      <table
        className='table table-condensed table-striped table-hover'
        id={tableId}
        data-order='[[ 1, "asc" ]]'
      >
        <thead>
          <tr>
            <th>Details</th>
            <th>When</th>
            <th>Worker</th>
            <th>Posted</th>
            <th className='no-sort' />
          </tr>
        </thead>
        <tbody>
          {this.props.records.map((item, i) => (
            <TableRow
              key={i}
              record={item}
              setDisplayShiftId={this.props.setDisplayShiftId}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

const TableRow = (props) => {
  const { record, setDisplayShiftId } = props;
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  return (
    <tr>
      <td>
        <MapToDiv>{[record.companyName, record.name]}</MapToDiv>
      </td>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime)}
      </td>
      <td
        data-order={record.workerSurname}
      >{`${record.workerFirstName} ${record.workerSurname}`}</td>
      <td data-order={record.createdDateTime}>
        {AppUtils.formatDateTimeUtc(record.createdDateTime)}
        <div>
          <SmallMuted>by {record.createdBy}</SmallMuted>
        </div>
      </td>
      <td className='text-right'>
        <Button
          bsStyle='primary'
          bsSize='xs'
          onClick={() => setDisplayShiftId(record.shiftId)}
        >
          Open &raquo;
        </Button>
      </td>
    </tr>
  );
};
