import { useState } from 'react';
import { Alert, Button, Col, Modal, Row, Well } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useSelectedCustomer } from 'hooks';
import {
  useAwaitingInvoiceShiftsSummaryQuery,
  useCreateInvoiceMutation,
} from 'hooks/queries/invoiceQueries';
import moment from 'moment';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon } from 'pages/Utils/react-utils';
import { ICustomEvent } from 'types/GeneralTypes';
import { InvoiceQueryType } from 'types/Invoice';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { Stack } from 'components/Stack';
import { YearAndWeekNumber } from 'components/YearAndWeekNumber';

const _storedDateFormat = 'YYYY-MM-DD';
const _displayDateFormat = 'DD/MM/YYYY';

const _defaultStateWeekNumber: InvoiceQueryType = {
  type: 'weekNumber',
  data: {
    year: moment().format('YYYY'),
    weekNumber: moment().format('W'),
  },
};

const _defaultStateDateRange: InvoiceQueryType = {
  type: 'dateRange',
  data: {
    toDate: moment().format(_storedDateFormat),
    fromDate: moment().subtract(7, 'days').format(_storedDateFormat),
  },
};

export const CreateInvoiceDialog = (props: {
  showModal: boolean;
  handleCloseModalClick: () => void;
}) => {
  const [filterState, setFilterState] = useState<InvoiceQueryType>(
    _defaultStateWeekNumber
  );

  const handleFilterChange = (e: ICustomEvent) => {
    setFilterState((currentFilterData) => {
      const newDataWithType = {
        ...currentFilterData,
      };
      if (newDataWithType.type === 'weekNumber') {
        if (e.target.name === 'Year') {
          newDataWithType.data.year = e.target.value;
        }
        if (e.target.name === 'WeekNumber') {
          newDataWithType.data.weekNumber = e.target.value;
        }
      } else if (newDataWithType.type === 'dateRange') {
        if (e.target.name === 'FromDate') {
          newDataWithType.data.fromDate = e.target.value;
        }
        if (e.target.name === 'ToDate') {
          newDataWithType.data.toDate = e.target.value;
        }
      }
      return newDataWithType;
    });
  };

  const { selectedCustomerId, renderCustomerSelect, getCustomerName } =
    useSelectedCustomer();
  const customerName = getCustomerName();

  const query = useAwaitingInvoiceShiftsSummaryQuery({
    hirerId: selectedCustomerId,
    queryType: filterState,
  });

  const mutation = useCreateInvoiceMutation();
  const { mutate, isLoading } = mutation;

  const history = useHistory();

  const handleCreateClick = () => {
    if (
      !selectedCustomerId ||
      (filterState.type === 'weekNumber' &&
        !filterState.data.year &&
        !filterState.data.weekNumber) ||
      (filterState.type === 'dateRange' &&
        !filterState.data.fromDate &&
        !filterState.data.toDate)
    ) {
      AppUtils.displayError('Error', 'Please select a customer, year and week');
      return;
    }

    mutate(
      { hirerId: selectedCustomerId, queryType: filterState },
      {
        onSuccess: (data) => {
          AppUtils.displaySuccess('Done', 'Invoice created');
          props.handleCloseModalClick();
          history.push(`/Invoice/${data.invoiceId}`);
        },
      }
    );
  };

  return (
    <Modal
      backdrop='static'
      show={props.showModal}
      onHide={props.handleCloseModalClick}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Invoice?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack gap={10}>
          <strong>
            Choose which customer you want to create an invoice for:
          </strong>
          <div>{renderCustomerSelect()}</div>

          <Row>
            {filterState.type === 'weekNumber' ? (
              <YearAndWeekNumber
                year={filterState.data.year}
                weekNumber={filterState.data.weekNumber}
                handleChange={handleFilterChange}
              />
            ) : null}
            {filterState.type === 'dateRange' ? (
              <FromAndToDateRange
                fromDate={filterState.data.fromDate}
                toDate={filterState.data.toDate}
                handleChange={handleFilterChange}
              />
            ) : null}
            <Col sm={4}>
              <Button
                block
                style={{ marginTop: '25px' }}
                bsSize='sm'
                bsStyle='primary'
                onClick={() =>
                  filterState.type === 'weekNumber'
                    ? setFilterState(_defaultStateDateRange)
                    : setFilterState(_defaultStateWeekNumber)
                }
              >
                <>
                  {filterState.type === 'dateRange' ? <>&laquo;</> : null}
                  {filterState.type === 'weekNumber'
                    ? 'Pick Dates '
                    : ' Use Week Number'}
                  {filterState.type === 'weekNumber' ? <>&raquo;</> : null}
                </>
              </Button>
            </Col>
          </Row>

          <WeekNumberTypeSummary
            selectedCustomerId={selectedCustomerId}
            filterState={filterState}
            query={query}
          />

          <DateRangeTypeSummary
            selectedCustomerId={selectedCustomerId}
            filterState={filterState}
            query={query}
          />

          <ErrorAlertForQueryOrNull
            isError={query.isError}
            error={query.error}
          />

          {selectedCustomerId &&
          query.isSuccess &&
          query.data.weekStartDate &&
          query.data.weekEndDate ? (
            <>
              <p>
                You are going to create a new Invoice for {customerName}. This
                will pull in{' '}
                <strong>
                  all {customerName} shifts ready to be invoiced which fall
                  between{' '}
                  {AppUtils.formatDateTimeUtc(
                    query.data.weekStartDate,
                    undefined,
                    'DD MMM'
                  )}{' '}
                  and{' '}
                  {AppUtils.formatDateTimeUtc(
                    query.data.weekEndDate,
                    undefined,
                    'DD MMM'
                  )}{' '}
                  inclusive.
                </strong>
              </p>
              <p>
                There are currently <code>{query.data.count}</code> shifts
                awaiting invoice for the selected period with a total of{' '}
                <code>{AppUtils.getCurrencyVal(query.data.total)}</code>
              </p>
              <p>
                When you create an invoice, it will have a status of{' '}
                <code>Draft</code>. You can remove shifts from this invoice,
                before you submit it for approval, if you want to include them
                on a different invoice later.
              </p>
              <Alert bsStyle='info'>Are you sure you want to proceed?</Alert>
              <ErrorAlertForQueryOrNull
                error={mutation.error}
                isError={mutation.isError}
              />
            </>
          ) : null}
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.handleCloseModalClick}>Cancel</Button>
        <Button
          bsStyle='success'
          onClick={handleCreateClick}
          disabled={isLoading || !query.isSuccess}
        >
          <Icon icon='fa-check' isSpinning={isLoading} /> Yes, Create Invoice
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

type FromAndToDateRangeProps = {
  fromDate: string;
  toDate: string;
  handleChange: (e: ICustomEvent) => void;
};

const FromAndToDateRange = ({
  fromDate,
  toDate,
  handleChange,
}: FromAndToDateRangeProps) => {
  return (
    <>
      <DatePicker
        columns={4}
        name='FromDate'
        label='From Date'
        value={moment(fromDate).format(_displayDateFormat)}
        changeDate={({ date }: { date: Date }) => {
          handleChange({
            target: {
              name: 'FromDate',
              value: moment(date).format(_storedDateFormat),
            },
          });
        }}
        isDatepicker
        required
      />
      <DatePicker
        columns={4}
        name='ToDate'
        label='To Date'
        value={moment(toDate).format(_displayDateFormat)}
        changeDate={({ date }: { date: Date }) => {
          handleChange({
            target: {
              name: 'ToDate',
              value: moment(date).format(_storedDateFormat),
            },
          });
        }}
        isDatepicker
        required
      />
    </>
  );
};

const WeekNumberTypeSummary = ({
  selectedCustomerId,
  filterState,
  query,
}: {
  selectedCustomerId: string;
  filterState: InvoiceQueryType;
  query: ReturnType<typeof useAwaitingInvoiceShiftsSummaryQuery>;
}) => {
  if (filterState.type !== 'weekNumber') {
    return null;
  }

  return (
    <>
      {selectedCustomerId &&
      filterState.data.year &&
      filterState.data.weekNumber ? (
        <Well>
          <strong>
            Summary for {filterState.data.year} week{' '}
            {filterState.data.weekNumber}
            {query.isSuccess
              ? ` (${AppUtils.formatDateTimeUtc(
                  query.data.weekStartDate,
                  undefined,
                  'DD MMM'
                )} - ${AppUtils.formatDateTimeUtc(
                  query.data.weekEndDate,
                  undefined,
                  'DD MMM'
                )})`
              : null}
            {query.isFetching ? (
              <>
                {' '}
                <Icon isSpinning />
              </>
            ) : null}
          </strong>
        </Well>
      ) : (
        <Alert bsStyle='warning'>
          Please select a customer, year and week number
        </Alert>
      )}
    </>
  );
};

const DateRangeTypeSummary = ({
  selectedCustomerId,
  filterState,
  query,
}: {
  selectedCustomerId: string;
  filterState: InvoiceQueryType;
  query: ReturnType<typeof useAwaitingInvoiceShiftsSummaryQuery>;
}) => {
  if (filterState.type !== 'dateRange') {
    return null;
  }

  return (
    <>
      {selectedCustomerId &&
      filterState.data.fromDate &&
      filterState.data.toDate ? (
        <Well>
          <strong>
            Summary for
            {query.isSuccess
              ? ` ${AppUtils.formatDateTimeUtc(
                  query.data.weekStartDate,
                  undefined,
                  'DD MMM YY'
                )} - ${AppUtils.formatDateTimeUtc(
                  query.data.weekEndDate,
                  undefined,
                  'DD MMM YY'
                )}`
              : null}
            {query.isFetching ? (
              <>
                {' '}
                <Icon isSpinning />
              </>
            ) : null}
          </strong>
        </Well>
      ) : (
        <Alert bsStyle='warning'>
          Please select a customer, year and week number
        </Alert>
      )}
    </>
  );
};
