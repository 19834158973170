import { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import { useShiftPaymentAdjustmentsQuery } from 'hooks/queries';
import { AppUtils } from 'pages/Utils/app-utils';
import { ShiftPaymentAdjustment } from 'types/ShiftTypes';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { Table } from 'components/Table';

export const PaymentAdjustmentsTable = ({ shiftId }: { shiftId: string }) => {
  const { data, isError, error, isSuccess, isLoading } =
    useShiftPaymentAdjustmentsQuery(shiftId);

  const columns = useMemo(
    () => [
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value }: { value: string }) =>
          '£' + AppUtils.formatNumber(value),
      },
      {
        Header: 'Reason',
        accessor: 'reason',
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
        width: '20%',
      },
      {
        Header: 'When',
        accessor: (record: ShiftPaymentAdjustment) => record.createdDateTime,
        Cell: ({ value }: { value: string }) =>
          AppUtils.formatDateTimeUtc(value),
        width: '20%',
      },
    ],
    []
  );

  const records = useMemo(() => data ?? [], [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <ErrorAlert msg={extractErrorMessages(error)} className='m-b-sm' />;
  }

  if (isSuccess && records.length > 0) {
    return (
      <>
        <Alert>
          Please note, payment adjustments are not applied until the payment is
          authorised.
        </Alert>
        <Table columns={columns} records={records} />
      </>
    );
  }

  if (isSuccess && records.length <= 0) {
    return <Alert>No payment adjustments found.</Alert>;
  }

  return null;
};
