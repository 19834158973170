import { ReactNode } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import { useEditInvoiceMutation } from 'hooks/queries/invoiceQueries';
import $ from 'jquery';
import moment from 'moment';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { Invoice } from 'types/Invoice';
import { nameof } from 'utils';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { InvoiceEvents } from '../invoiceUtils';

const getName = (fieldName: keyof Invoice) => nameof<Invoice>(fieldName);

const _displayDateFormat = 'DD/MM/YYYY';

export const EditInvoiceModal = (props: {
  showModal: boolean;
  handleCloseModalClick: () => void;
  record: Invoice;
}) => {
  const record = props.record;
  const editInvoice = useEditInvoiceMutation();

  const handleSubmitClick = () => {
    const handleSuccess = () => {
      AppUtils.displaySuccess('Done', 'Invoice saved');
      props.handleCloseModalClick();
      PubSub.publish(InvoiceEvents.Saved);
    };

    const $modal = $('.modal');
    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    const model = AppUtils.getDataModel($modal) as Invoice;

    model['invoiceId'] = record.invoiceId;
    editInvoice.mutate(model, {
      onSuccess: () => {
        handleSuccess();
      },
    });
  };

  const isLoading = editInvoice.isLoading;
  const error = editInvoice.error;
  const isError = !!error;

  return (
    <Modal
      backdrop='static'
      show={props.showModal}
      onHide={props.handleCloseModalClick}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Field.InCol
            columns={6}
            name={getName('invoiceReference')}
            label='Invoice Reference'
            value={record.invoiceReference}
            maxLength='20'
            required
          />
          <Field.InCol
            columns={6}
            name={getName('poReference')}
            label='Purhacse Order Reference'
            value={record.poReference}
            maxLength='50'
          />
        </Row>

        <Row>
          <DatePicker
            columns={6}
            name={getName('invoiceDate')}
            label='Invoice Date'
            value={moment(record.invoiceDate).format(_displayDateFormat)}
          />
          <DatePicker
            columns={6}
            name={getName('invoiceDueDate')}
            label='Invoice Due Date'
            value={moment(record.invoiceDueDate).format(_displayDateFormat)}
          />
        </Row>

        <ErrorAlertForQueryOrNull error={error} isError={isError} />
      </Modal.Body>
      <FooterMain
        handleSubmitClick={handleSubmitClick}
        closeButton={
          <Button onClick={props.handleCloseModalClick}>
            <Icon icon='fa-times' /> Cancel
          </Button>
        }
        isLoading={isLoading}
      />
    </Modal>
  );
};

type SaveButtonProps = {
  isLoading: boolean;
  handleSubmitClick: () => void;
};

type FooterMainProps = {
  closeButton: ReactNode;
} & SaveButtonProps;

const FooterMain = (props: FooterMainProps) => {
  const { closeButton, ...rest } = props;

  return (
    <Modal.Footer>
      {props.closeButton}
      <SaveButton {...rest} />
    </Modal.Footer>
  );
};

const SaveButton = (props: SaveButtonProps) => {
  const buttonTitle = `Sav${props.isLoading ? 'ing...' : 'e Invoice'}`;
  return (
    <Button
      bsStyle='success'
      onClick={props.handleSubmitClick}
      disabled={props.isLoading}
    >
      <Icon icon='fa-check' isSpinning={props.isLoading} /> {buttonTitle}
    </Button>
  );
};
