import { Component } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import $ from 'jquery';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, SmallMuted, WellSmall } from 'pages/Utils/react-utils';
import { acceptShift } from 'utils/api';
import { WorkerImage } from '../../Worker/WorkerUtils';
import { ShiftUtils } from '../ShiftUtils';

export class AcceptModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();

    this.onExited = this.onExited.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  defaultState() {
    return {
      isSaving: false,
      isSaved: false,
      errors: [],
    };
  }

  onExited() {
    this.setState(this.defaultState());
  }

  async handleSubmitClick() {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);
    model['ShiftApplicationId'] = this.props.record.shiftApplicationId;

    this.setState({ errors: [], isSaving: true });
    AppUtils.clearNotifications();

    try {
      const data = await acceptShift(model);
      AppUtils.handleAjaxDone(data, () => {
        this.setState({ isSaved: true });
        AppUtils.publish(ShiftUtils.Events.WorkerAllocated, data);

        const { firstName, surname } = this.props.record;
        AppUtils.displaySuccess(
          'Applicant Accepted',
          `This Shift has been allocated to ${firstName} ${surname}`
        );
      });
    } catch (error) {
      AppUtils.displayErrors([error.message]);
      this.setState({ errors: [error.message] });
    }

    this.setState({ isSaving: false });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.showModal}
        onHide={this.props.handleAcceptModalCloseClick}
        onEntered={this.onEntered}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Accept Applicant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.state} {...this.props} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.handleAcceptModalCloseClick}>
            <i className='fa fa-times' /> Close
          </Button>
          <ModalSaveButton
            {...this.props}
            {...this.state}
            onClick={this.handleSubmitClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = (props) => {
  const { record } = props;
  if (!record) {
    return null;
  }
  return (
    <>
      <div className='text-center'>
        <Errors {...props} />
        {record.workerHasImage && (
          <WorkerImage
            workerId={record.workerId}
            style={{ width: '100px' }}
            isClickDisabled
          />
        )}
        <h1 className='m-t m-b-none'>
          {record.firstName} {record.surname}
        </h1>
        <h3>
          <Icon icon='fa-phone' /> {record.mobileNumber}
        </h3>
        <SmallMuted>
          Applied {AppUtils.formatDateTimeUtc(record.createdDateTime)}
        </SmallMuted>
        <Saved {...props} />
        <BodyMain {...props} />
      </div>
    </>
  );
};

const Errors = ({ errors }) => {
  if (errors.length <= 0) {
    return null;
  }
  return (
    <Alert bsStyle='danger' bsSize='sm'>
      <h3>Something went wrong</h3>
      {errors > 1 ? (
        <ul>
          {errors.map((error, i) => (
            <li key={i}>{error}</li>
          ))}
        </ul>
      ) : (
        <p>{errors[0]}</p>
      )}
    </Alert>
  );
};

const Saved = (props) => {
  if (!props.isSaved) {
    return null;
  }
  if (!props.isSaved) {
    return null;
  }
  return (
    <Alert bsStyle='success' className='m-t m-b-none'>
      <h3>
        <Icon icon='fa-thumbs-up' /> Applicant Accepted
      </h3>
      <p>They will receive a notification shortly.</p>
    </Alert>
  );
};

const BodyMain = (props) => {
  const { isSaved, record } = props;
  if (isSaved) {
    return null;
  }
  return (
    <>
      {record.isPublic ? (
        <Alert bsStyle='info' className='m-t'>
          {record.firstName} {record.surname} is not currently associated with
          your agency. If you proceed, it will automatically create the
          association.
        </Alert>
      ) : null}
      <WellSmall className='m-t m-b-none'>
        Do you want to accept this applicant for this shift?
      </WellSmall>
    </>
  );
};

const ModalSaveButton = (props) => {
  const isDisabled = props.isSaving || props.isSaved;
  const buttonTitle = props.isSaving
    ? 'Accepting Applicant...'
    : props.isSaved
    ? 'Applicant Accepted'
    : 'Yes, Accept Now';
  return (
    <Button bsStyle='success' onClick={props.onClick} disabled={isDisabled}>
      <Icon icon='fa-thumbs-up' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
