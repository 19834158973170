import { ReactNode, useState } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useVehicleOptions } from 'hooks/queries';
import { useModal } from 'hooks/useModal';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { WellSmall, Icon } from 'pages/Utils/react-utils';
import { ShiftPlanItem, ShiftPlanItemShift } from 'types/ShiftPlanningTypes';
import { dateFormats, formatDate } from 'utils';
import { ModalContent } from 'components/Planner/ModalContent';
import {
  ShiftMenuModalContextProvider,
  useShiftMenuModalContext,
} from 'components/Planner/ShiftMenuModalContext';
import { UiStateViewNotes } from 'components/Planner/ViewNotes';
import {
  EShiftModalMenuUi,
  ShiftMenuModalProps,
} from 'components/Planner/shiftMenuModalTypes';
import { ConfirmDeleteModalContent } from './ConfirmDelete';
import { SendToWorkerModalContent } from './SendToWorker';
import { UpdateTimeModalContent } from './UpdateShiftTime';
import { UpdateVehicleModalContent } from './UpdateShiftVehicle';

export const useShiftMenuModal = () => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();

  const [shiftToHandle, setShiftToHandle] = useState<ShiftPlanItemShift | null>(
    null
  );
  const [shiftPlanItemToHandle, setShiftPlaItemToHandle] =
    useState<ShiftPlanItem | null>(null);

  const handleShiftMenuModalOpen = ({
    shift,
    shiftPlanItem,
  }: {
    shift: ShiftPlanItemShift;
    shiftPlanItem: ShiftPlanItem;
  }) => {
    setShiftToHandle(shift);
    setShiftPlaItemToHandle(shiftPlanItem);
    handleModalOpen();
  };

  const handleShiftMenuModalClose = () => {
    setShiftToHandle(null);
    setShiftPlaItemToHandle(null);
    handleModalClose();
  };

  const renderShiftMenuModal = () => {
    if (!isModalOpen || !shiftToHandle) {
      return null;
    }

    return (
      <ShiftMenuModal
        isModalOpen={isModalOpen}
        handleModalClose={handleShiftMenuModalClose}
        shift={shiftToHandle}
        shiftPlanItem={shiftPlanItemToHandle}
      />
    );
  };

  return {
    handleShiftMenuModalOpen,
    renderShiftMenuModal,
  };
};

export const ShiftMenuModal = (props: ShiftMenuModalProps) => {
  return (
    <ShiftMenuModalContextProvider {...props}>
      <ShiftMenuModalMain {...props} />
    </ShiftMenuModalContextProvider>
  );
};

const ShiftMenuModalMain = ({
  isModalOpen,
  handleModalClose,
}: ShiftMenuModalProps) => {
  const { uiState } = useShiftMenuModalContext();

  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <>
        {uiState === EShiftModalMenuUi.Default ? <UiStateDefault /> : null}
        {uiState === EShiftModalMenuUi.ViewNotes ? <UiStateViewNotes /> : null}
        {uiState === EShiftModalMenuUi.UpdateTime ? (
          <UpdateTimeModalContent />
        ) : null}
        {uiState === EShiftModalMenuUi.UpdateVehicle ? (
          <UpdateVehicleModalContent />
        ) : null}
        {uiState === EShiftModalMenuUi.ConfirmDelete ? (
          <ConfirmDeleteModalContent />
        ) : null}
        {uiState === EShiftModalMenuUi.SendToWorker ? (
          <SendToWorkerModalContent />
        ) : null}
      </>
    </Modal>
  );
};

const UiStateDefault = () => {
  const { shiftToHandle, shiftPlanItemToHandle, workerType } =
    useShiftMenuModalContext();
  const history = useHistory();
  const { getVehicleReg } = useVehicleOptions();
  return (
    <ModalContent
      title='Shift'
      Body={
        <>
          <WellSmall>
            <ShiftSummaryRow label='Who' value={shiftToHandle.workerName} />
            <ShiftSummaryRow label='Shift' value={shiftPlanItemToHandle.name} />
            <ShiftSummaryRow
              label='Date'
              value={formatDate(
                shiftToHandle.shiftDate,
                dateFormats.niceShortDay
              )}
            />
            <ShiftSummaryRow
              label='Time'
              value={
                shiftToHandle.shiftTime
                  ? shiftToHandle.shiftTime.substring(0, 5)
                  : ''
              }
            />
            {shiftToHandle.shiftTime !== shiftPlanItemToHandle.shiftTime ? (
              <ShiftSummaryRow
                label='Plan Time'
                value={
                  shiftPlanItemToHandle.shiftTime
                    ? shiftPlanItemToHandle.shiftTime.substring(0, 5)
                    : ''
                }
              />
            ) : null}
            {shiftToHandle.vehicleId ? (
              <ShiftSummaryRow
                label='Vehicle'
                value={getVehicleReg(shiftToHandle.vehicleId)}
              />
            ) : null}
            {shiftToHandle.vehicleId !== shiftPlanItemToHandle.vehicleId ? (
              <ShiftSummaryRow
                label='Plan Vehicle'
                value={getVehicleReg(shiftPlanItemToHandle.vehicleId)}
              />
            ) : null}
            <ShiftSummaryRow
              label='Status'
              value={
                shiftToHandle.status === ShiftUtils.Status.PreAllocated
                  ? 'Awaiting Acceptance'
                  : shiftToHandle.status
              }
            />
            {shiftToHandle.status !== ShiftUtils.Status.Planned ? (
              <ShiftSummaryRow
                label=''
                value={
                  <Button
                    bsStyle='primary'
                    bsSize='xs'
                    onClick={() => {
                      history.push(`/Shift/Edit/${shiftToHandle.shiftId}`);
                    }}
                  >
                    Open Shift Record <i className='fa fa-arrow-right' />
                  </Button>
                }
                className='m-t'
              />
            ) : null}
          </WellSmall>

          <hr />

          {shiftToHandle.status === ShiftUtils.Status.Planned ? (
            <>
              <Row>
                <Col xs={4}>
                  <MenuButton
                    icon={'fa-envelope'}
                    label={`Send to ${AppUtils.toTitleCase(workerType)}`}
                    state={EShiftModalMenuUi.SendToWorker}
                  />
                </Col>
                <Col xs={6}>
                  Send the shift to {shiftPlanItemToHandle.workerName}.
                </Col>
              </Row>

              <hr />
            </>
          ) : null}

          <Row>
            <Col xs={4}>
              <MenuButton
                icon={'fa-comments'}
                label={'Notes'}
                state={EShiftModalMenuUi.ViewNotes}
              />
            </Col>
            <Col xs={6}>Add or view notes for this shift.</Col>
          </Row>

          <hr />

          {shiftToHandle.status === ShiftUtils.Status.Planned ? (
            <>
              <Row>
                <Col xs={4}>
                  <MenuButton
                    icon={'fa-clock'}
                    label={'Update Shift Time'}
                    state={EShiftModalMenuUi.UpdateTime}
                  />
                </Col>
                <Col xs={6}>Update the start time for this shift.</Col>
              </Row>

              <hr />
            </>
          ) : null}

          {shiftPlanItemToHandle.isVehicleRequired ||
          shiftPlanItemToHandle.vehicleId ||
          shiftToHandle.vehicleId ? (
            <>
              <Row>
                <Col xs={4}>
                  <MenuButton
                    icon={'fa-truck'}
                    label={'Add/Change Vehicle'}
                    state={EShiftModalMenuUi.UpdateVehicle}
                  />
                </Col>
                <Col xs={6}>Update the vehicle for this shift.</Col>
              </Row>

              <hr />
            </>
          ) : null}

          {shiftToHandle.status === ShiftUtils.Status.Planned ? (
            <Row>
              <Col xs={4}>
                <MenuButton
                  icon={'fa-trash'}
                  label={'Delete Shift'}
                  state={EShiftModalMenuUi.ConfirmDelete}
                  bsStyle='danger'
                />
              </Col>
              <Col xs={6}>
                Delete this shift. This will remove the shift from the shift
                plan.
              </Col>
            </Row>
          ) : null}
        </>
      }
      Footer={<UiStateDefaultActions />}
    />
  );
};

const MenuButton = ({
  icon,
  label,
  state,
  bsStyle = 'primary',
}: {
  icon: string;
  label: string;
  state: EShiftModalMenuUi;
  bsStyle?: string;
}) => {
  const { setUiState } = useShiftMenuModalContext();
  return (
    <Button
      className='btn-block'
      bsStyle={bsStyle}
      bsSize='sm'
      onClick={() => setUiState(state)}
      style={{ textAlign: 'left' }}
    >
      <Icon icon={icon} className='fa-fw' style={{ marginRight: '5px' }} />{' '}
      {label} {'>'}
    </Button>
  );
};

const ShiftSummaryRow = ({
  label,
  value,
  className,
}: {
  label: string;
  value: string | number | ReactNode;
  className?: string;
}) => {
  return (
    <Row className={className}>
      <Col xs={4} style={{ textAlign: 'right' }}>
        <strong>{label}</strong>
      </Col>
      <Col xs={8}>{value}</Col>
    </Row>
  );
};

const UiStateDefaultActions = () => {
  const { handleModalClose } = useShiftMenuModalContext();
  return (
    <Button onClick={handleModalClose}>
      <i className='fa fa-times' /> Close
    </Button>
  );
};
