/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';
import { apiConfig } from 'config';
import auth0 from 'utils/auth0';

const axiosInt = axios.create({ baseURL: apiConfig.baseUrl.toString() });

export async function get<TResponse>(url: string, config?: AxiosRequestConfig) {
  const response = await axiosInt.get<TResponse>(url, config);
  return response.data;
}

export async function post<TResponse, TRequest = unknown>(
  url: string,
  requestData: TRequest = null
) {
  const response = await axiosInt.post<TResponse>(url, requestData);
  return response.data;
}

export async function put<TResponse, TRequest = unknown>(
  url: string,
  requestData: TRequest = null
) {
  const response = await axiosInt.put<TResponse>(url, requestData);
  return response.data;
}

export async function del<TResponse>(url: string) {
  const response = await axiosInt.delete<TResponse>(url);
  return response.data;
}

axiosInt.interceptors.request.use((config) => {
  const accessToken = auth0.extended.getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

axiosInt.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(
      (error.response && error.response.data) ||
        (error.message && { message: error.message }) ||
        '❌ There is an error!'
    );
  }
);

export default axiosInt;
