import { AppUtils } from 'pages/Utils/app-utils';

export const OpenShiftInNewTabLink = ({ shiftId }: { shiftId: string }) => {
  return (
    <a
      href={`/Shift/Edit/${shiftId}`}
      target='_blank'
      rel='noreferrer'
      data-toggle='tooltip'
      title='Open Shift in New Tab'
      onClick={() => {
        AppUtils.destroyTooltips();
      }}
    >
      View Shift
    </a>
  );
};
