import { useState } from 'react';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { useUpdateShiftPlanTimeMutation } from 'hooks/queries/shiftPlanningQueries';
import { useModal } from 'hooks/useModal';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import { UpdateShiftPlanTimeModalParams } from 'types/ShiftPlanningTypes';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { HourAndMinuteDropdown } from 'components/HourAndMinuteDropdown';

export const useUpdateTimeModal = () => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();

  const [updateTimeParams, setUpdateTimeParams] =
    useState<UpdateShiftPlanTimeModalParams | null>(null);

  const handleUpdateTimeModalOpen = (
    params: UpdateShiftPlanTimeModalParams
  ) => {
    setUpdateTimeParams(params);
    handleModalOpen();
  };

  const handleUpdateTimeModalClose = () => {
    setUpdateTimeParams(null);
    handleModalClose();
  };

  const renderUpdateTimeModal = () => {
    if (!isModalOpen || !updateTimeParams) {
      return null;
    }

    return (
      <UdpateTimeModal
        isModalOpen={isModalOpen}
        handleModalClose={handleUpdateTimeModalClose}
        params={updateTimeParams}
      />
    );
  };

  return {
    handleUpdateTimeModalOpen,
    renderUpdateTimeModal,
  };
};

export const UdpateTimeModal = ({
  isModalOpen,
  handleModalClose,
  params,
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
  params: UpdateShiftPlanTimeModalParams;
}) => {
  const { shiftPlanItem } = params;
  const queryClient = useQueryClient();

  const updateTimeMutation = useUpdateShiftPlanTimeMutation();

  const handleSubmitClick = () => {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    const dataModel = AppUtils.getDataModel($modal) as {
      ShiftTimeHour: string;
      ShiftTimeMinutes: string;
    };
    const params = {
      shiftPlanItemId: shiftPlanItem.shiftPlanItemId,
      shiftTime:
        dataModel.ShiftTimeHour && dataModel.ShiftTimeMinutes
          ? `${dataModel.ShiftTimeHour}:${dataModel.ShiftTimeMinutes}`
          : null,
    };
    updateTimeMutation.mutate(params, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ShiftPlanForHirer],
        });
        AppUtils.displaySuccess('Success', 'Shift time updated');
        handleModalClose();
      },
    });
  };

  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Time for Shift Plan Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WellSmall>
          <p>
            You are going to update the start time for this shift plan itme.
          </p>
          <p>
            <strong>Please note:</strong> This will only apply to shifts which
            have not had their time changed individually. Also any shifts which
            have already been sent to the worker will have to be adjusted
            manually.
          </p>
        </WellSmall>

        <HourAndMinuteDropdown
          prefix='Shift'
          label='Shift Start Time'
          timeValue={shiftPlanItem.shiftTime}
          required
        />

        <Alert bsStyle='info'>Are you sure you want to proceed?</Alert>

        {updateTimeMutation.isError ? (
          <Row>
            <Col xs={12}>
              <ErrorAlert
                msg={extractErrorMessages(updateTimeMutation.error)}
              />
            </Col>
          </Row>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>
          <i className='fa fa-times' /> Close
        </Button>
        <ModalSaveButton
          isSaved={updateTimeMutation.isSuccess}
          isSaving={updateTimeMutation.isLoading}
          onClick={handleSubmitClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

const ModalSaveButton = ({
  isSaving,
  isSaved,
  onClick,
}: {
  isSaving: boolean;
  isSaved: boolean;
  onClick: () => void;
}) => {
  const isDisabled = isSaving || isSaved;
  const buttonTitle = isSaving
    ? 'Updating...'
    : isSaved
    ? 'Updated!'
    : `Yes, Update Shift Time`;
  return (
    <Button bsStyle='success' onClick={onClick} disabled={isDisabled}>
      <Icon icon='fa-times' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};
