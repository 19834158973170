import { Bounce, toast } from 'react-toastify';
import TimeAgo from 'javascript-time-ago';
import moment from 'moment';
import numeral from 'numeral';
import PubSub from 'pubsub-js';
// eslint-disable-next-line import/order
import 'bootstrap/js/dropdown';
// eslint-disable-next-line import/order
import 'bootstrap/js/tooltip';
// eslint-disable-next-line import/order
import 'bootstrap/js/popover';
import $ from './app-utils-jquery';

export { default as $ } from './app-utils-jquery';

export const Constants = {
  DefaultAutoRefetchFrequency: 30000,
  Colours: {
    primary: '#25adf7',
    success: '#1ab394',
    muted: '#ccc',
  },
};

const _currentYear = moment().year();

export const AppUtils = {
  init: function () {
    // Init Tooltips
    AppUtils.initTooltips();

    // Init popovers
    $('body').popover({
      selector: '[data-toggle="popover"]',
    });

    // Disable Bootstrap onClick for ToggleButtonGroup
    // https://github.com/react-bootstrap/react-bootstrap/issues/2774#issuecomment-387553896
    // Never going to use the default Bootstrap onClick so fine to disable globally
    $(document).off('click.bs.button.data-api', '[data-toggle^="button"]');

    // Polyfill for find method :-(
    AppUtils.findMethodPolyfill();
  },

  initTooltips: function () {
    AppUtils.destroyTooltips();

    $('body').tooltip({
      selector: '[data-toggle="tooltip"]',
      container: 'body',
    });
  },

  findMethodPolyfill: function () {
    // https://tc39.github.io/ecma262/#sec-array.prototype.find
    if (!Array.prototype.find) {
      // eslint-disable-next-line
      Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate) {
          // 1. Let O be ? ToObject(this value).
          if (this == null) {
            throw TypeError('"this" is null or not defined');
          }

          var o = Object(this);

          // 2. Let len be ? ToLength(? Get(O, "length")).
          var len = o.length >>> 0;

          // 3. If IsCallable(predicate) is false, throw a TypeError exception.
          if (typeof predicate !== 'function') {
            throw TypeError('predicate must be a function');
          }

          // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
          var thisArg = arguments[1];

          // 5. Let k be 0.
          var k = 0;

          // 6. Repeat, while k < len
          while (k < len) {
            // a. Let Pk be ! ToString(k).
            // b. Let kValue be ? Get(O, Pk).
            // c. Let testResult be ToBoolean(? Call(predicate, T, � kValue, k, O �)).
            // d. If testResult is true, return kValue.
            var kValue = o[k];
            if (predicate.call(thisArg, kValue, k, o)) {
              return kValue;
            }
            // e. Increase k by 1.
            k++;
          }

          // 7. Return undefined.
          return undefined;
        },
        configurable: true,
        writable: true,
      });
    }
  },

  Events: {
    SiteAssessmentSaved: 'siteAssessment.saved',
    ExperienceRequirementSaved: 'experienceRequirement.saved',
    ExperienceRequirementDeleted: 'experienceRequirement.deleted',
    AppLogoUpdated: 'appLogo.updated',
    WorkerFileAdded: 'worker.fileAdded',
    WorkerFileRemoved: 'worker.fileRemoved',
  },

  ApiStatus: {
    Success: 'success',
    Fail: 'fail',
  },

  handleAjaxDone: function (data, doneFunc, errorFunc) {
    try {
      if (!data?.status) {
        AppUtils.displayError(
          'Error',
          'Could not retrieve a response status from the request.'
        );
        return;
      }
      // Check status was valid
      if (data.status !== AppUtils.ApiStatus.Success) {
        return;
      }

      // Run received function
      doneFunc();
    } catch (error) {
      console.error(error);
      AppUtils.displayError('Error', error.message);
      if (errorFunc) {
        errorFunc();
      }
    }
  },

  options: {
    vehicleTypeOptions: ['Artic (C + E)', '7.5 Tonne (C1)', 'Rigid (C)', 'Van'],
    trailerTypeOptions: [
      'Box',
      'Container',
      'Curtain Sider',
      'Double Deck',
      'Drawbar',
      'Flatbed',
      'HIAB',
      'Mixers',
      'Refridgerated',
      'Skips',
      'Tail Lift',
      'Tankers',
      'Tilt',
      'Tippers',
      'Van',
      'Urban',
    ],
    runTypeOptions: [
      'Collection',
      'Delivery',
      'Docks',
      'Factory',
      'General Haulage',
      'Home Delivery',
      'Multi Drop',
      'Night Out',
      'Shunting',
      'Small Shops',
      'Supermarkets',
      'Superstore',
      'Tramping',
      'Trunking',
    ],
    loadTypeOptions: [
      'Ambient',
      'Chilled',
      'Composite',
      'Drinks/Kegs',
      'ADR',
      'Frozen',
      'High Value',
      'Minerals',
      'Pallets/Cages',
    ],
    healthAndSafetyOptions: [
      'High Viz',
      'Safety Boots',
      'Gloves',
      'Report to security',
      'Parking',
      'No parking',
    ],
    licenceTypeOptions: [
      'B (Van)',
      'B+E',
      'C1 (7.5T)',
      'C1+E (7.5T + Trailer)',
      'C (Rigid)',
      'C+E (Artic)',
    ],
    prohibitedConvictionOptions: [
      'AC - Accident offences',
      'BA - Disqualified driver',
      'CD - Careless driving',
      'CU - Construction and use offences',
      'DD - Reckless / dangerous driving',
      'DR - Drink or drugs',
      'IN - Insurance offences',
      'LC - Licence offences',
      'MS - Miscellaneous offences',
      'MW - Motorway offences',
      'PC - Pedestrian crossings',
      'SP - Speed limits',
      'TS - Traffic direction and signs',
      'TT - Special code',
      'UT - Theft or unauthorised taking',
    ],
  },

  publish: (event, data) =>
    setTimeout(() => {
      PubSub.publish(event, data);
    }, 500),

  subscribe: (event, fn) => PubSub.subscribe(event, fn),

  unsubscribe: (subscriptionToken) => PubSub.unsubscribe(subscriptionToken),

  clearNotifications: () => toast.dismiss(),

  spinIconClass: 'fas fa-circle-notch fa-spin',

  sidebarPrep: () => {
    const pageWrapper = document.getElementById('page-wrapper');
    if (pageWrapper) {
      pageWrapper.classList.add('sidebar-content');
    }
  },

  // Props: https://davidwalsh.name/javascript-debounce-function
  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  debounce: function (func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  },

  invalidClassName: 'invalid',

  clearInvalidClassName: () => {
    $('.' + AppUtils.invalidClassName).removeClass(AppUtils.invalidClassName);
  },

  validateItems: function ($items, validationMsg) {
    AppUtils.clearInvalidClassName();

    let isValid = true;
    $($items).each(function (i, item) {
      if (!AppUtils.isItemValid($(item))) {
        isValid = false;
      }
    });

    if (!isValid) {
      AppUtils.displayError(
        'Error',
        validationMsg || 'Please complete all required fields and try again.',
        false,
        'validationError'
      );

      return false;
    }
    return true;
  },

  validateItemsWithIds: (itemIds, validationMsg) => {
    let foundError = false;
    const $items = itemIds.map((itemId) => {
      const $item = $(`#${itemId}`);
      if (!$item.length) {
        foundError = true;
        console.log(`Could not find field with id: ${itemId}`);
        AppUtils.displayError(
          'Error',
          `Could not find field with id: ${itemId}`
        );
      }
      return $item;
    });
    if (foundError) {
      return false;
    }
    return AppUtils.validateItems($items, validationMsg);
  },

  isItemValid: ($item) => {
    const itemVal = AppUtils.getItemVal($item);
    let isItemValid = true;
    if (itemVal === '') {
      isItemValid = false;
      AppUtils.addInvalidClass($item);
    } else {
      if ($item.hasClass('is-number')) {
        if (!AppUtils.isNumeric(itemVal)) {
          // Check if zero is not allowed
          isItemValid = false;
          AppUtils.addInvalidClass($item);
          AppUtils.addTooltip($item, 'Please enter a number');
        } else {
          // This field is a number field and we have a numeric value
          // Check if zero is allowed
          if ($item.hasClass('zero-not-allowed')) {
            // Zero is not allowed
            // Check for it and return false if found
            if (parseFloat(itemVal) === 0) {
              isItemValid = false;
              AppUtils.addInvalidClass($item);
              AppUtils.addTooltip(
                $item,
                'Please enter a number greater than zero'
              );
            }
          }
        }
      }
    }

    return isItemValid;
  },

  addInvalidClass: ($item) => {
    $item.addClass(AppUtils.invalidClassName);
    if ($item.hasClass('is-select')) {
      $item.children('div').first().addClass(AppUtils.invalidClassName);
    }

    AppUtils.addInvalidClassToClosestTab($item);
  },

  addInvalidClassToClosestTab: ($item) => {
    const $tabPane = $item.closest('.tab-pane');
    if ($tabPane.length) {
      AppUtils.addInvalidClass($(`#${$tabPane.attr('aria-labelledby')}`));
    }
  },

  getItemVal: ($item) => {
    if ($item.hasClass('is-date')) {
      return $item.val()
        ? moment($item.val(), 'DD/MM/YYYY').format('YYYY-MM-DD')
        : '';
    } else if ($item.hasClass('is-select')) {
      return $item
        .find(`input[name="${$item.attr('id')}"]`)
        .map(function () {
          return $(this).val();
        })
        .get()
        .join(';');
    } else {
      return $item.val();
    }
  },

  getDataModelForContainerWithId: (containerId, ignoreWithinId) => {
    return AppUtils.getDataModel($(`#${containerId}`), ignoreWithinId);
  },

  getDataModel: ($container, ignoreWithinId) => {
    // If ignoreWithinId is received then items within the element with the received id will not be included
    const itemsSelector = '.form-control, .is-select';
    let $items, $checkboxes, $radios;
    if ($container) {
      $items = $container.find(itemsSelector);
      $checkboxes = $container.find('input[type="checkbox"]');
      $radios = $container.find('input[type="radio"]:checked');
    } else {
      $items = $(itemsSelector);
      $checkboxes = $('input[type="checkbox"]');
      $radios = $('input[type="radio"]:checked');
    }

    if (ignoreWithinId) {
      $items = $items.filter(function () {
        return $(this).closest(ignoreWithinId).length <= 0;
      });
      $checkboxes = $checkboxes.filter(function () {
        return $(this).closest(ignoreWithinId).length <= 0;
      });
    }

    let $this,
      id,
      name,
      model = {};
    $items.each(function () {
      $this = $(this);
      id = $this.attr('id');
      if (id) {
        model[id] = AppUtils.getItemVal($this);
      }
    });

    $checkboxes.each(function () {
      $this = $(this);
      name = $this.attr('name');
      if (name) {
        model[name] = $this.is(':checked') ? true : false;
      }
    });

    if ($radios.length > 0) {
      $radios.each(function () {
        $this = $(this);
        name = $this.attr('name');
        if (name) {
          model[name] = $this.val();
        }
      });
    }

    return model;
  },

  getDateString: function (dateVal, format) {
    if (!dateVal) {
      return '';
    }
    return moment(dateVal).format(format || 'DD/MM/YYYY');
  },

  resetFields: ($container) => {
    const $items = $container.find('.form-control, .is-select');
    $items.each(function () {
      const $this = $(this);
      $this.val('');
      $this.removeClass(AppUtils.invalidClassName);
      if ($this.hasClass('is-select')) {
        $this.children('div').first().removeClass(AppUtils.invalidClassName);
      }
    });
  },

  destroyTooltips: function () {
    $('[data-toggle="tooltip"]').tooltip('destroy');
  },

  getMessagesFromXhr: function (xhr) {
    return xhr && xhr.responseJSON && xhr.responseJSON.messages
      ? xhr.responseJSON.messages
      : ['Something went wrong: ' + xhr.statusText];
  },

  getMessagesFromXhrAsString: function (xhr, separator) {
    return this.getMessagesFromXhr(xhr).join(separator || '; ');
  },

  // Extract message from xhr object
  // Display error containing joined messages
  // Return array of error messages
  handleXhrFail: function (xhr, prefix) {
    var messages = this.getMessagesFromXhr(xhr);
    this.displayErrors(messages, prefix);
    return messages;
  },

  displayErrors: function (errors, prefix) {
    this.displayError('Error', (prefix ? prefix : '') + errors.join('; '));
  },

  displayError: function (title, msg, isSticky, toastId) {
    isSticky = typeof isSticky === 'undefined' ? false : isSticky;

    const options = this.getToastOptions(isSticky);
    if (toastId) {
      options.toastId = toastId;
    }

    toast.error(this.getToastContent(title, msg), options);
  },

  displayWarning: function (title, msg, isSticky, toastId) {
    isSticky = typeof isSticky === 'undefined' ? false : isSticky;

    const options = this.getToastOptions(isSticky);
    if (toastId) {
      options.toastId = toastId;
    }

    toast.warning(this.getToastContent(title, msg), options);
  },

  displaySuccess: function (title, msg, isSticky) {
    toast.success(
      this.getToastContent(title, msg),
      this.getToastOptions(isSticky)
    );
  },

  getToastContent: function (title, msg) {
    return (
      <div>
        <h4>{title}</h4>
        <p>{msg}</p>
      </div>
    );
  },

  getToastOptions: function (isSticky) {
    const options = {
      pauseOnFocusLoss: true,
      position: toast.POSITION.BOTTOM_LEFT,
      theme: 'colored',
      transition: Bounce,
    };
    if (isSticky) {
      options.autoClose = false;
    }
    return options;
  },

  properCase: (str) =>
    str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    }),

  startsWith: function (searchIn, searchFor) {
    return searchIn.indexOf(searchFor) === 0;
  },

  isNull: function (object) {
    return object === null ? true : false;
  },

  isUndefined: function (object) {
    return typeof object === 'undefined' ? true : false;
  },

  isNullOrUndefined: function (object) {
    return AppUtils.isUndefined(object) || AppUtils.isNull(object);
  },

  isObject: function (object) {
    return typeof object === 'object' ? true : false;
  },

  isNumeric: function (value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  },

  formatNumber: function (value, defaultIfEmpty = '0') {
    var ret = defaultIfEmpty;
    if (value && AppUtils.isNumeric(value)) {
      ret = numeral(value).format('#,#[.]##');
    }
    return ret;
  },

  getCurrencyVal: (val) => `£${val === 0 ? '0' : AppUtils.formatNumber(val)}`,

  getTimeAgo: (dt) => {
    if (!dt) {
      return null;
    }
    const timeAgo = new TimeAgo();
    return timeAgo.format(dt);
  },

  getLocalMomentFromUtcVal: (utcVal, inFormat) => {
    const _inFormat = inFormat || 'YYYY-MM-DDThh:mm:ss';
    return moment.utc(utcVal, _inFormat).local();
  },

  formatTime: (timeStr) => moment(timeStr, 'HH:mm:ss').format('HH:mm'),

  // Format a UTC date/time value
  // The value is UAT so first get a local version of the moment and then format and return that
  formatDateTimeUtc: function (utcValue, inFormat, outFormat) {
    if (!utcValue) {
      return '';
    }
    return this.getFormattedMoment(
      this.getLocalMomentFromUtcVal(utcValue, inFormat),
      outFormat
    );
  },

  // Format a non UTC date/time value (i.e. a date/time value which is stored in local time)
  // The date/time value is already local so no need to first convert to local
  formatDateTimeNonUtc: function (value, inFormat, outFormat) {
    if (!value) {
      return '';
    }
    const _inFormat = inFormat || 'YYYY-MM-DDThh:mm:ss';
    return this.getFormattedMoment(moment(value, _inFormat), outFormat);
  },

  getFormattedMoment: function (momentObject, outFormat) {
    let _outFormat;
    if (!outFormat) {
      const includeYearInOutFormat = momentObject.year() !== _currentYear;
      _outFormat = `DD MMM ${includeYearInOutFormat ? 'YYYY ' : ''}HH:mm`;
    } else {
      _outFormat = outFormat;
    }

    return momentObject.format(_outFormat);
  },

  concatDateAndTime: (dateStr, timeStr) => {
    if (!dateStr || !timeStr) {
      return '';
    }
    return `${moment(dateStr, 'YYYY-MM-DD').format('YYYY-MM-DD')} ${timeStr}`;
  },

  concatDateAndTimeAsMoment: (dateStr, timeStr) => {
    if (!dateStr || !timeStr) {
      return '';
    }
    return moment(
      `${moment(dateStr, 'YYYY-MM-DD').format('YYYY-MM-DD')}T${timeStr}`
    );
  },

  addTooltip: function ($item, msg) {
    $item
      .tooltip({
        title: msg,
      })
      .tooltip('show');
    setTimeout(function () {
      $item.tooltip('destroy');
    }, 3000);
  },

  getYesNo: (boolVal) => (boolVal ? 'Yes' : 'No'),

  pluralise: (count, singleString, multipleString) => {
    if (count === 1) {
      return singleString;
    }
    return multipleString;
  },

  toCamelCase: (stringValue) => {
    return stringValue.replace(/^([A-Z])|\s(\w)/g, function (match, p1, p2) {
      if (p2) {
        return p2.toUpperCase();
      }
      return p1.toLowerCase();
    });
  },

  toTitleCase: (stringValue) => {
    if (!stringValue) {
      return stringValue;
    }
    return stringValue.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },

  capitaliseFirstLetter: (stringValue) => {
    return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
  },

  createGuid: () => {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    );
  },
};

$(function () {
  AppUtils.init();
});

// eslint-disable-next-line
String.prototype.toCamelCase = function () {
  return this.replace(/^([A-Z])|\s(\w)/g, function (match, p1, p2) {
    if (p2) {
      return p2.toUpperCase();
    }
    return p1.toLowerCase();
  });
};
