import { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AppUtils } from 'pages/Utils/app-utils';
import { SpinIcon, WellSmall, SmallMuted, Icon } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { getAgencyInvites } from 'utils/api';
import { HirerUtils } from '../HirerUtils';
import { ApproveButton } from './ApproveButton';
import { RejectButton } from './RejectButton';

class AgencyInvites extends Component {
  constructor(props) {
    console.log('AgencyInvites constructor');
    super(props);
    this.state = { isGetting: false, records: [] };

    this.subscriber = this.subscriber.bind(this);
  }

  componentDidMount() {
    console.log('AgencyInvites.componentDidMount');
    this.getData();

    var me = this;
    me.subscribeToEvents().forEach(function (event) {
      AppUtils.subscribe(event, me.subscriber);
    });
  }

  componentWillUnmount() {
    console.log('AgencyInvites.componentWillUnmount');
    this.subscribeToEvents().forEach(function (key) {
      PubSub.unsubscribe(key);
    });
  }

  subscriber(event) {
    console.log('AgencyInvites.subscribe: ', { event });
    this.getData();
  }

  subscribeToEvents() {
    return [
      HirerUtils.Events.AgencyInviteAccepted,
      HirerUtils.Events.AgencyInviteRejected,
    ];
  }

  setIsGetting(val) {
    this.setState({ isGetting: val });
  }

  async getData() {
    this.setIsGetting(true);
    const data = await getAgencyInvites();
    if (data.records) {
      this.setState({ records: data.records });
    }
    this.setIsGetting(false);

    // $.ajax({
    //   url: AppSettings.baseUrl + "Hirer/AgencyInvites/",
    // })
    //   .done(function (data) {
    //     if (data.status === AppUtils.ApiStatus.Success && data.records) {
    //       _me.setState({ records: data.records });
    //     }
    //   })
    //   .always(function () {
    //     _me.setIsGetting(false);
    //   });
  }

  render() {
    if (this.state.isGetting) {
      return <SpinIcon />;
    }
    console.log('AgencyInvites.render');
    return (
      <>
        <a
          className='dropdown-toggle count-info'
          data-toggle='dropdown'
          href='#agencyInvitesLink'
          aria-expanded='false'
        >
          <Icon icon='fa-bell' /> <Label {...this.state} />
        </a>
        <ul className='dropdown-menu dropdown-alerts'>
          <Invites {...this.state} />
        </ul>
      </>
    );
  }
}

const Label = ({ records }) => {
  if (records.length <= 0) {
    return <span className='label bg-muted'>{records.length}</span>;
  }
  return <span className='label label-success'>{records.length}</span>;
};

const Invites = ({ records }) => {
  if (records.length <= 0) {
    return <WellSmall className='m-b-none'>No agency invites found.</WellSmall>;
  }

  var invites = records.map((record, i) => <Invite record={record} key={i} />);
  invites.unshift(
    <li key={-1}>
      <h4>Agency Invites</h4>
      <hr />
    </li>
  );
  return invites;
};

const Invite = ({ record }) => {
  return (
    <li>
      <Row>
        <Col md={6}>
          <strong>{record.companyName}</strong>
          <br />
          <SmallMuted>
            {AppUtils.formatDateTimeUtc(
              record.invitedDateTime,
              null,
              'DD MMM YYYY HH:mm'
            )}
          </SmallMuted>
        </Col>
        <Col md={6} className='text-right'>
          <span className='label'>
            {AppUtils.formatDateTimeUtc(record.createdDateTime)}
          </span>
        </Col>
      </Row>
      <Buttons record={record} />
      <hr />
    </li>
  );
};

const Buttons = ({ record }) => {
  return (
    <Row className='m-t'>
      <Col md={12} className='text-right'>
        <RejectButton record={record} />
        <ApproveButton record={record} />
      </Col>
    </Row>
  );
};

export default AgencyInvites;
