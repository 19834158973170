import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { DashboardPanel } from 'pages/Utils/react-utils';
import { ButtonCreateNew } from './Buttons/CreateNewHirerButton';
import HirerListViewActive from './HirerListViewActive';
import HirerListViewPending from './HirerListViewPending';

const HirerAdmin = () => {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabSelect = (tabToMakeActive) => {
    setActiveTab(tabToMakeActive);
  };
  return (
    <>
      <Intro />
      <TabsMain activeTab={activeTab} handleTabSelect={handleTabSelect} />
    </>
  );
};

const Intro = (props) => {
  return <DashboardPanel noHeader content={<ButtonCreateNew {...props} />} />;
};

const TabsMain = ({ activeTab, handleTabSelect }) => {
  let eventKey = 1;
  return (
    <div className='tabs-container'>
      <Tabs
        activeKey={activeTab}
        animation={false}
        onSelect={handleTabSelect}
        id='hirer-admin-tabs'
      >
        <Tab eventKey={eventKey++} title='Active Customers'>
          <ActiveHirersTab />
        </Tab>
        <Tab eventKey={eventKey++} title='Pending Invitations'>
          <PendingHirersTab />
        </Tab>
      </Tabs>
    </div>
  );
};

const ActiveHirersTab = () => {
  return <HirerListViewActive />;
};

const PendingHirersTab = () => {
  return <HirerListViewPending />;
};

export default HirerAdmin;
