import { Component } from 'react';
import { Button, Col, Overlay, Popover, Panel, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import PubSub from 'pubsub-js';
import { ShiftTemplateStatus } from 'types/ShiftConfigTypes';
import { getShiftConfigTemplates } from 'utils/api';
import { userPermissions, usePermissions } from 'utils/userPermissions';
import { Label } from 'components/Label';
import { AppUtils } from '../Utils/app-utils';
import { ListViewUtils } from '../Utils/list-view-utils';
import { WellSmall, Icon, SmallMuted } from '../Utils/react-utils';
import { CreateNewShiftConfigButton } from './Buttons/CreateNewShiftConfigButton';
import { DeleteShiftConfigModal } from './DeleteShiftConfigModal';
import { DuplicateShiftConfigModal } from './DuplicateShiftConfigModal';
import { ShiftConfigEvents } from './ShiftConfigUtils';

export const ShiftConfigListView = (props) => {
  const { hasPermission } = usePermissions();
  if (!hasPermission(userPermissions.shiftTemplate.view)) {
    return null;
  }

  const canEdit = hasPermission(userPermissions.shiftTemplate.edit);

  return <ShiftConfigListViewMain {...props} canEdit={canEdit} />;
};

class ShiftConfigListViewMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGetting: false,
      records: [],
      lastGet: null,
      recordToDelete: null,
    };

    this.subscriber = this.subscriber.bind(this);
    this.getData = this.getData.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.handleCloseDeleteModalClick =
      this.handleCloseDeleteModalClick.bind(this);
    this.handleDuplicateButtonClick =
      this.handleDuplicateButtonClick.bind(this);
    this.handleCloseDuplicateModalClick =
      this.handleCloseDuplicateModalClick.bind(this);
  }

  componentDidMount() {
    this.getData();

    const _me = this;
    [
      ShiftConfigEvents.ShiftConfigDeleted,
      ShiftConfigEvents.ShiftConfigDuplicated,
    ].forEach((e) => {
      PubSub.subscribe(e, _me.subscriber);
    });
  }

  subscriber() {
    this.getData();
  }

  handleDeleteButtonClick(recordToDelete) {
    console.log(`handleDeleteButtonClick`, recordToDelete);
    this.setState({ recordToDelete });
  }

  handleCloseDeleteModalClick() {
    this.setState({ recordToDelete: null });
  }

  handleDuplicateButtonClick(recordToDuplicate) {
    console.log(`handleDuplicateButtonClick`, recordToDuplicate);
    this.setState({ recordToDuplicate });
  }

  handleCloseDuplicateModalClick() {
    this.setState({ recordToDuplicate: null });
  }

  setIsGetting(val, lastGet) {
    let newState = { isGetting: val };
    if (lastGet) {
      newState['lastGet'] = lastGet;
    }
    this.setState(newState);
  }

  async getData() {
    this.setIsGetting(true);

    const data = await getShiftConfigTemplates();
    if (data.records) {
      this.setState({ records: data.records });
    }
    this.setIsGetting(false, new Date());
  }

  render() {
    return (
      <>
        <Panel>
          <Panel.Heading>
            <Row>
              <Col sm={6}>
                <Panel.Title componentClass='h3'>Shift Templates</Panel.Title>
              </Col>
              <Col sm={6} className='text-right'>
                <CreateNewShiftConfigButton isSm />
              </Col>
            </Row>
          </Panel.Heading>
          <Panel.Body>
            <TableContainer
              {...this.state}
              handleDeleteButtonClick={this.handleDeleteButtonClick}
              handleDuplicateButtonClick={this.handleDuplicateButtonClick}
              canEdit={this.props.canEdit}
            />
          </Panel.Body>
        </Panel>
        <DeleteShiftConfigModal
          recordToDelete={this.state.recordToDelete}
          handleCloseModalClick={this.handleCloseDeleteModalClick}
        />
        <DuplicateShiftConfigModal
          recordToDuplicate={this.state.recordToDuplicate}
          handleCloseModalClick={this.handleCloseDuplicateModalClick}
        />
      </>
    );
  }
}

const TableContainer = (props) => {
  if (props.isGetting) {
    return <WellSmall hasSpinner>Getting Shift Templates...</WellSmall>;
  }
  if (props.records.length <= 0) {
    return <WellSmall>No Shift Templates found.</WellSmall>;
  }
  return <Table {...props} />;
};

const tableId = 'shift-config-table';
class Table extends Component {
  componentDidMount() {
    this.initDataTables();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.lastGet !== this.props.lastGet) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillUpdate() {
    this.destroyDataTables();
  }

  componentDidUpdate() {
    this.initDataTables();
  }

  componentWillUnmount() {
    this.destroyDataTables();
  }

  initDataTables() {
    if (this.props.records.length > 0) {
      ListViewUtils.init(tableId);
    }
  }

  destroyDataTables() {
    const $table = $('#' + tableId);
    if ($table.length) {
      $table.DataTable().destroy();
    }
  }

  render() {
    if (this.props.records.length <= 0) {
      return null;
    }

    const getTableRow = (record, key) => {
      return (
        <TableRow
          key={key}
          record={record}
          handleDeleteButtonClick={this.props.handleDeleteButtonClick}
          handleDuplicateButtonClick={this.props.handleDuplicateButtonClick}
          canEdit={this.props.canEdit}
        />
      );
    };

    return (
      <table className='table table-condensed table-striped' id={tableId}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Company</th>
            <th>Payment</th>
            <th>Type</th>
            <th>Status</th>
            <th>Created</th>
            <th className='no-sort' />
          </tr>
        </thead>
        <tbody>
          {this.props.records.map((item, i) => getTableRow(item, i))}
        </tbody>
      </table>
    );
  }
}

const TableRow = (props) => {
  const {
    record,
    handleDeleteButtonClick,
    handleDuplicateButtonClick,
    canEdit,
  } = props;
  return (
    <tr>
      <td>
        <LinkToRecord record={record} />
      </td>
      <td>{record.companyName}</td>
      <td>{record.shiftPaymentType}</td>
      <td>{record.shiftIndustryType}</td>
      <td>
        <Status templateStatus={record.templateStatus} />
      </td>
      <td data-order={record.createdDateTime}>
        {AppUtils.formatDateTimeUtc(record.createdDateTime)}
        <br />
        <SmallMuted>by {record.createdBy}</SmallMuted>
      </td>
      <td className='text-right'>
        {canEdit ? (
          <PopoverButton
            record={record}
            handleDeleteButtonClick={handleDeleteButtonClick}
            handleDuplicateButtonClick={handleDuplicateButtonClick}
          />
        ) : null}
      </td>
    </tr>
  );
};

const LinkToRecord = ({ record }) => {
  return (
    <Link to={`ShiftConfig/Edit/${record.shiftConfigId}`}>{record.name}</Link>
  );
};

const Status = ({ templateStatus }) => (
  <Label
    bsStyle={
      templateStatus === ShiftTemplateStatus.Live ? 'success' : 'warning'
    }
  >
    {templateStatus}
  </Label>
);

class PopoverButton extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = (event) => {
      this.setState((s) => ({ target: event.target, show: !s.show }));
      event.stopPropagation();
    };

    this.state = {
      show: false,
      target: null,
    };
  }

  render() {
    return (
      <>
        <Button bsSize='xs' bsStyle='primary' onClick={this.handleClick}>
          <Icon icon='fa-ellipsis-h' />
        </Button>
        <Overlay
          show={this.state.show}
          target={this.state.target}
          placement='left'
          onHide={() => {
            this.setState({ show: false });
          }}
          rootClose={true}
        >
          <Popover id='popover-contained'>
            <Button
              bsStyle='danger'
              bsSize='xs'
              block
              onClick={() =>
                this.props.handleDeleteButtonClick(this.props.record)
              }
            >
              <Icon icon='fa-times' /> Delete
            </Button>
            <Button
              bsStyle='primary'
              bsSize='xs'
              block
              onClick={() =>
                this.props.handleDuplicateButtonClick(this.props.record)
              }
            >
              <Icon icon='fa-clone' /> Duplicate
            </Button>
          </Popover>
        </Overlay>
      </>
    );
  }
}
