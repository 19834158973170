import { Component } from 'react';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils, Constants } from 'pages/Utils/app-utils';
import { Icon, DashboardPanel } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { authorisePayments } from 'utils/api';

export class AuthoriseSelectedButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isModalShowing: false,
    };

    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  handleShowModalClick() {
    if (this.props.recordIdsSelected.length <= 0) {
      AppUtils.displayError(
        'Error',
        'No Shifts selected. Please select some and try again.'
      );
    } else {
      this.setState({ isModalShowing: true });
    }
  }

  handleCloseModalClick() {
    this.setState({ isModalShowing: false });
  }

  async handleSubmitClick() {
    const model = {
      shiftIds: this.props.recordIdsSelected,
    };

    this.setState({ isSaving: true });
    const data = await authorisePayments(model);
    AppUtils.handleAjaxDone(data, () => {
      const authorisedCount =
        data.shiftsWithAdjustmentsAuthorised +
        data.shiftsWithoutAdjustmentsAuthorised;

      if (authorisedCount > 0) {
        AppUtils.displaySuccess(
          'Done',
          authorisedCount + ' Shift Payments Authorised.'
        );
      }

      if (data.shiftsWithAdjustmentsNotAuthorised > 0) {
        AppUtils.displayWarning(
          'Warning',
          data.shiftsWithAdjustmentsNotAuthorised +
            ' Shift Payment' +
            AppUtils.pluralise(
              data.shiftsWithAdjustmentsNotAuthorised,
              '',
              's'
            ) +
            ' with Adjustments ' +
            AppUtils.pluralise(
              data.shiftsWithAdjustmentsNotAuthorised,
              'was',
              'were'
            ) +
            ' not authorised because you do not have permission.',
          true
        );
      }

      this.handleCloseModalClick();
      PubSub.publish(ShiftUtils.Events.ShiftPaymentsAuthorised, data);
    });
    this.setState({ isSaving: false });
  }

  onExited() {
    AppUtils.destroyTooltips();
  }

  render() {
    const recordsSelectedCount = this.props.recordIdsSelected.length;
    const plural = recordsSelectedCount > 1 ? 's' : '';
    const btnText = `Mark ${
      recordsSelectedCount === 0 ? '' : `${recordsSelectedCount} Shift${plural}`
    } as Authorised...`;

    return (
      <>
        <Button
          bsStyle='success'
          bsSize='sm'
          onClick={this.handleShowModalClick}
          disabled={recordsSelectedCount <= 0}
        >
          <Icon icon='fa-money-bill-alt' /> {btnText}
        </Button>
        <Modal
          backdrop='static'
          show={this.state.isModalShowing}
          onHide={this.handleCloseModalClick}
          onExited={this.onExited}
        >
          <Modal.Header closeButton>
            <Modal.Title>Authorise Payment for Selected Shifts</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Body
              {...this.state}
              {...this.props}
              recordsSelectedCount={recordsSelectedCount}
              plural={plural}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCloseModalClick} bsSize='sm'>
              <i className='fa fa-times' /> Close
            </Button>
            <SubmitButton
              {...this.state}
              handleSubmitClick={this.handleSubmitClick}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const Body = ({ selectedTotal, recordIdsSelected, records }) => {
  const hasPaymentAdjustments = records
    .filter((r) => recordIdsSelected.includes(r.shiftId))
    .some((r) => r.hasPaymentAdjustments);

  const containerStyle = { marginBottom: '0' };
  const getContent = (value) => (
    <h1 className='no-margins' style={{ color: Constants.Colours.success }}>
      {value}
    </h1>
  );
  return (
    <>
      <p>{`Here's a summary of the shifts you're going to mark as authorised for payment:`}</p>
      <Row
        style={{
          background: '#efefef',
          margin: '0 -15px 10px',
          padding: '10px 0',
        }}
      >
        <Col sm={6}>
          <DashboardPanel
            containerStyle={containerStyle}
            title='Amount To Authorise'
            content={getContent(
              `£${AppUtils.formatNumber(selectedTotal)}`,
              true
            )}
          />
        </Col>
        <Col sm={6}>
          <DashboardPanel
            containerStyle={containerStyle}
            title='Shift Qty'
            content={getContent(recordIdsSelected.length, true)}
          />
        </Col>
        {hasPaymentAdjustments ? (
          <Col sm={12}>
            <Alert bsStyle='danger' className='m-t-sm'>
              Please note, some shifts you are authorising have payment
              adjustments.
            </Alert>
          </Col>
        ) : null}
      </Row>
      <Alert bsStyle='info'>
        {`You will not be able to undo this. Are you sure you'd like to proceed?`}
      </Alert>
    </>
  );
};

const SubmitButton = (props) => {
  const buttonTitle = props.isSaving
    ? 'Authorising...'
    : 'Yes, Authorise Payments';
  return (
    <Button
      bsStyle='success'
      bsSize='sm'
      className='m-l-sm'
      onClick={props.handleSubmitClick}
      disabled={props.isSaving}
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
