import { AppUtils } from 'pages/Utils/app-utils';
import {
  AcceptMultipleShiftsParams,
  AcceptShiftsConfigParams,
  AssignVehicleToPlanItemParams,
  AssignWorkerToPlanItemParams,
  CancelShiftFromPlanParams,
  CreateSingleShiftForDateParams,
  CreateSingleShiftForVehicleMutationParams,
  DailyReportForHirerQueryParams,
  DeletePlanItemParams,
  DeleteShiftFromPlanParams,
  GetPostShiftConfigResponse,
  PlanForHirerQueryParams,
  PostShiftsConfigParams,
  SendMessageParams,
  SendMultipleShiftsToWorkersParams,
  SendShiftToWorkerParams,
  ShiftPlanItem,
  UpdateShiftPlanItemTimeParams,
  UpdateShiftPlanSingleShiftTimeParams,
  UpdateShiftPlanSingleShiftVehicleParams,
  UpdateShiftPlanSingleShiftWorkerParams,
} from 'types/ShiftPlanningTypes';
import { CreatePlanItemsParams } from 'types/ShiftPlanningTypes';
import axiosInt, { del, get, post, put } from './apiBase';

const createPlanItems = (params: CreatePlanItemsParams) => {
  return post('ShiftPlan/ShiftPlanItems', params);
};

const sendMessage = (params: SendMessageParams) => {
  const { hirerId, message, subject } = params;
  return post(`ShiftPlan/${hirerId}/SendMessage`, { message, subject });
};

const assignWorkerToPlanItem = (
  shiftPlanItemId: string,
  params: AssignWorkerToPlanItemParams
) => {
  return post(`ShiftPlan/${shiftPlanItemId}/Assign`, params);
};

const assignVehicleToPlanItem = (
  shiftPlanItemId: string,
  params: AssignVehicleToPlanItemParams
) => {
  return post(`ShiftPlan/${shiftPlanItemId}/Vehicle`, params);
};

const getPlanForHirer = ({
  hirerId,
  startDate,
  vehiclePlanItems = false,
}: PlanForHirerQueryParams) => {
  let url = `ShiftPlan/${hirerId}?startDate=${AppUtils.getDateString(
    startDate,
    'YYYY-MM-DD'
  )}`;

  if (vehiclePlanItems) {
    url += '&vehiclePlanItems=true';
  }

  return get<ShiftPlanItem[]>(url);
};

const deletePlanItem = (params: DeletePlanItemParams) => {
  return post(`ShiftPlan/${params.shiftPlanItemId}/Delete`, {
    hirerId: params.hirerId,
  });
};

const createSingleShift = (params: CreateSingleShiftForDateParams) => {
  return post(`ShiftPlan/${params.shiftPlanItem.shiftPlanItemId}/Shift`, {
    date: params.date,
  });
};

const createSingleShiftForVehicle = (
  params: CreateSingleShiftForVehicleMutationParams
) => {
  const {
    shiftPlanItem: { shiftPlanItemId },
    ...remainingParams
  } = params;
  return post(`ShiftPlan/${shiftPlanItemId}/Shift`, remainingParams);
};

const updateShiftPlanItemTime = (params: UpdateShiftPlanItemTimeParams) => {
  const { shiftTime, shiftPlanItemId } = params;
  return put(`ShiftPlan/${shiftPlanItemId}/ShiftTime`, {
    shiftTime,
  });
};

const updateSingleShiftTime = (
  params: UpdateShiftPlanSingleShiftTimeParams
) => {
  const { shiftTime, shiftPlanItemId, shiftId } = params;
  return put(`ShiftPlan/${shiftPlanItemId}/Shift/${shiftId}/ShiftTime`, {
    shiftTime,
  });
};

const updateSingleShiftVehicle = (
  params: UpdateShiftPlanSingleShiftVehicleParams
) => {
  const { vehicleId, shiftPlanItemId, shiftId } = params;
  return put(`ShiftPlan/${shiftPlanItemId}/Shift/${shiftId}/Vehicle`, {
    vehicleId,
  });
};

const updateSingleShiftWorker = (
  params: UpdateShiftPlanSingleShiftWorkerParams
) => {
  const { workerId, shiftPlanItemId, shiftId } = params;
  return put(`ShiftPlan/${shiftPlanItemId}/Shift/${shiftId}/Worker`, {
    workerId,
  });
};

const deleteShiftFromPlan = (params: DeleteShiftFromPlanParams) => {
  return del(`ShiftPlan/${params.shiftPlanItemId}/Shift/${params.shiftId}`);
};

const cancelShiftFromPlan = (params: CancelShiftFromPlanParams) => {
  return post(`Shift/Cancel`, params);
};

const sendShiftToWorker = (params: SendShiftToWorkerParams) => {
  return post(
    `ShiftPlan/${params.shiftPlanItemId}/Shift/${params.shiftId}/Worker`
  );
};

const getPostShiftsConfig = (params: PostShiftsConfigParams) => {
  const { hirerId, shiftPlanItemIds, fromDate, toDate } = params;
  const shiftPlanItemIdParams = shiftPlanItemIds
    .map((id) => `shiftPlanItemIds=${id}`)
    .join('&');
  return get<GetPostShiftConfigResponse>(
    `ShiftPlan/${hirerId}/PostShiftsConfig/${fromDate}/${toDate}?${shiftPlanItemIdParams}`
  );
};

const sendShiftsToWorkers = (params: SendMultipleShiftsToWorkersParams) => {
  return post(`ShiftPlan/Send`, { ...params });
};

const getAcceptShiftsConfig = (params: AcceptShiftsConfigParams) => {
  const { hirerId, shiftPlanItemIds, fromDate, toDate, isVehiclePlan } = params;
  const shiftPlanItemIdParams = shiftPlanItemIds
    .map((id) => `shiftPlanItemIds=${id}`)
    .join('&');
  return get<GetPostShiftConfigResponse>(
    `ShiftPlan/${hirerId}/ApproveShiftsConfig/${fromDate}/${toDate}?${shiftPlanItemIdParams}&isVehiclePlan=${isVehiclePlan.toString()}`
  );
};

const acceptShifts = (params: AcceptMultipleShiftsParams) => {
  return post(`ShiftPlan/Approve`, { ...params });
};

const getWeeklySummaryDownload = ({
  hirerId,
  startDate,
  vehiclePlanItems = false,
}: PlanForHirerQueryParams) => {
  let url = `ShiftPlan/${hirerId}/Download?startDate=${AppUtils.getDateString(
    startDate,
    'YYYY-MM-DD'
  )}`;

  if (vehiclePlanItems) {
    url += '&vehiclePlanItems=true';
  }

  return axiosInt.get<Blob>(url, {
    responseType: 'blob',
  });
};

const getDailySummaryDownload = ({
  hirerId,
  vehiclePlanItems = false,
}: DailyReportForHirerQueryParams) => {
  let url = `ShiftPlan/${hirerId}/DailyDownload`;

  if (vehiclePlanItems) {
    url += '?vehiclePlanItems=true';
  }

  return axiosInt.get<Blob>(url, {
    responseType: 'blob',
  });
};

export const shiftPlanApi = {
  getPlanForHirer,
  createPlanItems,
  createSingleShift,
  createSingleShiftForVehicle,
  updateShiftPlanItemTime,
  updateSingleShiftTime,
  updateSingleShiftVehicle,
  updateSingleShiftWorker,
  assignWorkerToPlanItem,
  assignVehicleToPlanItem,
  deletePlanItem,
  deleteShiftFromPlan,
  cancelShiftFromPlan,
  sendMessage,
  sendShiftToWorker,
  sendShiftsToWorkers,
  getPostShiftsConfig,
  getAcceptShiftsConfig,
  acceptShifts,
  getWeeklySummaryDownload,
  getDailySummaryDownload,
};
