export const WorkerAdminUtils = {
  Events: {
    WorkerSaved: 'worker.saved',
    WorkerRejected: 'worker.rejected',
  },
  PayType: {
    PAYE: 'PAYE',
    Salary: 'Salary',
    AgencyWorker: 'Agency Worker',
    Contractor: 'Contractor',
  },
};
