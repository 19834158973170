import { ReactNode } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { LinkedShiftsUi } from 'components/LinkedShifts';
import { useShiftMenuModalContext } from './ShiftMenuModalContext';
import { BackButton, CloseButton } from './shiftMenuModalUtils';

type ModalContentProps = {
  title: string;
  Body: ReactNode;
  Footer?: ReactNode;
};

export const ModalContent = ({ title, Body, Footer }: ModalContentProps) => {
  const { shiftToHandle } = useShiftMenuModalContext();
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {shiftToHandle.isPartOfShiftGroup ? (
          <Row>
            <Col sm={8}>{Body}</Col>
            <Col sm={4}>
              <LinkedShiftsUi shiftId={shiftToHandle.shiftId} />
            </Col>
          </Row>
        ) : (
          Body
        )}
      </Modal.Body>
      <Modal.Footer>{Footer ? Footer : <StandardActions />}</Modal.Footer>
    </>
  );
};

const StandardActions = () => {
  return (
    <Row>
      <Col sm={4} style={{ textAlign: 'left' }}>
        <BackButton />
      </Col>
      <Col sm={8}>
        <CloseButton />
      </Col>
    </Row>
  );
};
