import { useAuth } from 'utils/auth.js';
import { Route, Switch } from 'utils/router.js';
import MainLayout from 'components/Layouts/MainLayout';
import AdminRoutes from './Admin/AdminRoutes';
import AgencyRoutes from './Agency/AgencyRoutes';
import HirerRoutes from './Hirer/HirerRoutes';
import Auth0Callback from './auth0-callback';
import NotFoundPage from './not-found';
import IndexPage from './index';

const Routes = () => {
  const auth = useAuth();
  const userRecord = auth?.user?.record;

  return (
    <Route>
      <MainLayout>
        <Switch>
          <Route exact path='/' component={IndexPage} />
          <Route exact path='/auth0-callback' component={Auth0Callback} />
          {userRecord?.isAdminUser && <AdminRoutes />}
          {userRecord?.isAgencyUser && <AgencyRoutes />}
          {userRecord?.isHirerUser && <HirerRoutes />}
          <Route path='*' component={NotFoundPage} />
        </Switch>
      </MainLayout>
    </Route>
  );
};

export default Routes;
