import { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Icon } from 'pages/Utils/react-utils';
import { userPermissions, usePermissions } from 'utils/userPermissions';
import { NewShiftConfigModal } from '../NewShiftConfigModal';

export const CreateNewShiftConfigButton = (props) => {
  const { hasPermission } = usePermissions();
  if (!hasPermission(userPermissions.shiftTemplate.edit)) {
    return null;
  }

  return <CreateNewShiftConfigButtonMain {...props} />;
};

class CreateNewShiftConfigButtonMain extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, isSaving: false };
    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  handleShowModalClick() {
    this.setState({ showModal: true });
  }

  handleCloseModalClick() {
    this.setState({ showModal: false });
  }

  render() {
    const title = 'New Shift Template';
    const bsSize = this.props.isXs ? 'xs' : this.props.isSm ? 'sm' : null;
    const block = this.props.block || false;
    return (
      <>
        <Button
          bsStyle='primary'
          bsSize={bsSize}
          block={block}
          onClick={this.handleShowModalClick}
          className={this.props.className}
          style={this.props.style}
        >
          <Icon icon='fa-clipboard-check' /> {title}
        </Button>
        <NewShiftConfigModal
          {...this.state}
          {...this.props}
          title={title}
          handleCloseModalClick={this.handleCloseModalClick}
        />
      </>
    );
  }
}
