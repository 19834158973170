import { Component } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import $ from 'jquery';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { saveUser } from 'utils/api';
import { UserUtils } from '../UserUtils';

export class AddUserButton extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, isSaving: false };
    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  handleShowModalClick() {
    this.setState({ showModal: true });
  }

  handleCloseModalClick() {
    this.setState({ showModal: false });
  }

  render() {
    const title = 'Create User...';
    const bsSize = this.props.isXs ? 'xs' : this.props.isSm ? 'sm' : null;
    const block = this.props.block || false;
    return (
      <>
        <Button
          bsStyle='primary'
          bsSize={bsSize}
          block={block}
          onClick={this.handleShowModalClick}
          className={this.props.className}
          style={this.props.style}
        >
          <Icon icon='fa-clipboard-check' /> {title}
        </Button>
        <AddUserModal
          {...this.state}
          {...this.props}
          title={title}
          handleCloseModalClick={this.handleCloseModalClick}
        />
      </>
    );
  }
}

class AddUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
    };

    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  onEntered() {
    $('#FirstName').focus();
  }

  async handleSubmitClick() {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);

    this.setState({ isSaving: true });
    const data = await saveUser(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'User Created');
      this.props.handleCloseModalClick();
      PubSub.publish(UserUtils.Events.UserSaved, data);
    });
    this.setState({ isSaving: false });

    // $.ajax({
    //   type: "POST",
    //   url: `${AppSettings.baseUrl}Admin/UserSave`,
    //   data: JSON.stringify(model),
    // })
    //   .done(function (data) {
    //     AppUtils.handleAjaxDone(data, () => {
    //       AppUtils.displaySuccess("Done", "User Created");
    //       this.props.handleCloseModalClick();
    //       PubSub.publish(UserUtils.Events.UserSaved, data);
    //     });
    //   })
    //   .fail(function (xhr) {
    //     AppUtils.handleXhrFail(xhr);
    //   })
    //   .always(function () {
    //     this.setState({ isSaving: false });
    //   });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.showModal}
        onHide={this.props.handleCloseModalClick}
        onEntered={this.onEntered}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.state} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.handleCloseModalClick} bsSize='sm'>
            <i className='fa fa-times' /> Close
          </Button>
          <SubmitButton
            {...this.state}
            handleSubmitClick={this.handleSubmitClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = () => {
  return (
    <>
      <Row>
        <Field.InCol
          columns={6}
          name='FirstName'
          label='First Name'
          maxLength='100'
          required
        />
        <Field.InCol columns={6} name='Surname' maxLength='100' required />
      </Row>
      <Row>
        <Field.InCol
          columns={12}
          name='EmailAddress'
          label='Email Address'
          maxLength='256'
        />
      </Row>
      <Row>
        <Field.InCol columns={6} name='Password' type='password' required />
        <Field.InCol
          columns={6}
          name='PasswordConfirm'
          label='Confirm Password'
          type='password'
          required
        />
      </Row>
    </>
  );
};

const SubmitButton = (props) => {
  const buttonTitle = props.isSaving ? 'Creating...' : 'Create';
  return (
    <Button
      bsStyle='success'
      bsSize='sm'
      className='m-l-sm'
      onClick={props.handleSubmitClick}
      disabled={props.isSaving}
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
