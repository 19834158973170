import { Alert } from 'react-bootstrap';

function FormAlert(props) {
  const { type, message, ...otherProps } = props;

  return (
    <Alert bsStyle={type === 'error' ? 'danger' : 'success'} {...otherProps}>
      {message}
    </Alert>
  );
}

export default FormAlert;
