import qs from 'qs';
import {
  ReportQueryParams,
  VehicleReportQueryResponse,
} from 'types/ReportingTypes';
import {
  ArchiveVehicleParams,
  CreateVehicleParams,
  GetVehicleSummaryParams,
  IGetVehiclesResponse,
  Vehicle,
} from 'types/VehicleTypes';
import axiosInt, { get, post, put } from './apiBase';

const createVehicle = (params: CreateVehicleParams) => {
  return post(`Vehicle`, params);
};

const editVehicle = (params: Vehicle) => {
  return put(`Vehicle/${params.vehicleId}`, params);
};

const archiveVehicle = (params: ArchiveVehicleParams) => {
  return put(`Vehicle/${params.vehicleId}/Archive`);
};

const getVehicleById = async (vehicleId: string) => {
  return get(`Vehicle/${vehicleId}`);
};

const getVehicleEditConfig = async () => {
  return get(`Vehicle/EditConfig`);
};

const getVehiclesActive = async () => {
  return get<IGetVehiclesResponse>(`Vehicle/Active`);
};

const getVehicleSummary = async ({
  vehicleId,
  shiftDate,
}: GetVehicleSummaryParams) => {
  return get<{ shifts: { shiftDateTime: string; shiftId: string }[] }>(
    `Vehicle/${vehicleId}/${shiftDate}/Summary`
  );
};

const getVehicleReport = (params: ReportQueryParams) => {
  const url = `Vehicle/Report?${qs.stringify(params)}`;
  return get<VehicleReportQueryResponse>(url);
};

const getVehicleReportData = (params: ReportQueryParams) => {
  const url = `Vehicle/ReportData?${qs.stringify(params)}`;

  return axiosInt.get<Blob>(url, {
    responseType: 'blob',
  });
};

export const vehicleApi = {
  createVehicle,
  editVehicle,
  archiveVehicle,
  getVehicleById,
  getVehicleEditConfig,
  getVehiclesActive,
  getVehicleSummary,
  getVehicleReport,
  getVehicleReportData,
};
