import { ShiftPlanItem, ShiftPlanItemShift } from 'types/ShiftPlanningTypes';

export enum EShiftModalMenuUi {
  Default,
  SendToWorker,
  UpdateTime,
  UpdateWorker,
  UpdateTrailer,
  CancelShift,
  ViewNotes,
  ViewPaymentAdjustments,
  LinkShift,
  ViewShiftSegments,
  UpdateVehicle,
  CompleteShift,
  ConfirmDelete,
}

export type ShiftMenuModalProps = {
  isModalOpen: boolean;
  handleModalClose: () => void;
  shift: ShiftPlanItemShift;
  shiftPlanItem: ShiftPlanItem;
};
