import { ToastContainer, Slide } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { ProvideAuth } from 'utils/auth';
import { Route, Router, Switch } from 'utils/router.js';
import SimpleLayout from 'components/Layouts/SimpleLayout';
import './../styles/global.scss';
import HirerUserInviteReview from './Hirer/HirerUserInviteReview';
import AuthPage from './auth';
import Routes from './routes';

TimeAgo.addDefaultLocale(en);

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ProvideAuth>
        <ToastContainer transition={Slide} />
        <Router>
          <Switch>
            <Route
              exact
              path='/HirerUserInviteReview/:hirerUserInviteId'
              component={HirerUserInviteReview}
            />
            <Route exact path='/auth/:type'>
              <SimpleLayout>
                <Switch>
                  <Route exact path='/auth/:type' component={AuthPage} />
                </Switch>
              </SimpleLayout>
            </Route>
            <Routes />
          </Switch>
        </Router>
      </ProvideAuth>
    </QueryClientProvider>
  );
}

export default App;
