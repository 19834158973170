import { ComponentProps } from 'react';
import { Button } from 'react-bootstrap';
import { SpinIcon } from 'pages/Utils/react-utils';

type LoadingButtonProps = ComponentProps<typeof Button> & {
  isLoading?: boolean;
  Icon?: React.ReactNode;
  text: string;
  loadingText?: string;
};

export const LoadingButton = ({
  isLoading = false,
  Icon,
  text,
  loadingText = 'Loading...',
  bsStyle = 'primary',
  bsSize = 'sm',
  ...rest
}: LoadingButtonProps) => {
  return (
    <Button bsStyle={bsStyle} bsSize={bsSize} disabled={isLoading} {...rest}>
      {isLoading ? (
        <>
          <SpinIcon style={{}} />{' '}
        </>
      ) : Icon ? (
        <>{Icon} </>
      ) : null}
      {isLoading ? loadingText : text}
    </Button>
  );
};
