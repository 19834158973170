import { ReactNode, useState } from 'react';
import { Button, Modal, Row, Col, Alert } from 'react-bootstrap';
import { Dropdown } from 'components';
import {
  useArchiveVehicleMutation,
  useCreateVehicleMutation,
  useEditVehicleMutation,
} from 'hooks/queries/vehicleQueries';
import $ from 'jquery';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { Vehicle } from 'types/VehicleTypes';
import { nameof } from 'utils';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { Stack } from 'components/Stack';
import { VehicleUtils } from './VehicleUtils';

const getName = (fieldName: keyof Vehicle) => nameof<Vehicle>(fieldName);

export const VehicleModal = (props: {
  showModal: boolean;
  handleCloseModalClick: () => void;
  record?: Vehicle;
}) => {
  const record = props.record ?? ({} as Vehicle);
  const createVehicle = useCreateVehicleMutation();
  const editVehicle = useEditVehicleMutation();

  const handleSubmitClick = () => {
    const handleSuccess = () => {
      AppUtils.displaySuccess('Done', 'Vehicle saved');
      props.handleCloseModalClick();
      PubSub.publish(VehicleUtils.Events.VehicleSaved);
    };

    const $modal = $('.modal');
    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    const model = AppUtils.getDataModel($modal) as Vehicle;

    if (record?.vehicleId) {
      model['vehicleId'] = record.vehicleId;
      editVehicle.mutate(model, {
        onSuccess: () => {
          handleSuccess();
        },
      });
    } else {
      createVehicle.mutate(model, {
        onSuccess: () => {
          handleSuccess();
        },
      });
    }
  };

  const archiveVehicle = useArchiveVehicleMutation();
  const handleArchiveConfirmClick = record.vehicleId
    ? () => {
        if (!record.vehicleId) {
          AppUtils.displayError('Error', 'Could not find vehicle id');
          return;
        }

        archiveVehicle.mutate(
          { vehicleId: record.vehicleId },
          {
            onSuccess: () => {
              AppUtils.displaySuccess('Done', 'Vehicle archived');
              props.handleCloseModalClick();
              PubSub.publish(VehicleUtils.Events.VehicleSaved);
            },
          }
        );
      }
    : null;

  const isNew = !props.record;
  const isLoading = editVehicle.isLoading || createVehicle.isLoading;
  const error = editVehicle.error || createVehicle.error;
  const isError = !!error;

  return (
    <Modal
      backdrop='static'
      show={props.showModal}
      onHide={props.handleCloseModalClick}
    >
      <Modal.Header closeButton>
        <Modal.Title>{isNew ? 'Create' : 'Edit'} Vehicle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Field.InCol
            columns={6}
            name={getName('reg')}
            label='Registration'
            value={record.reg}
            maxLength='15'
            required
          />
        </Row>
        <Row>
          <Field.InCol
            columns={6}
            name={getName('make')}
            label='Make'
            value={record.make}
            maxLength='50'
          />
          <Field.InCol
            columns={6}
            name={getName('model')}
            label='Model'
            value={record.model}
            maxLength='50'
          />
        </Row>
        <Row>
          <Field.InCol
            columns={6}
            name={getName('otherReference1')}
            label='Other Ref'
            value={record.otherReference1}
            maxLength='50'
          />
          <Col sm={6}>
            <Dropdown
              name={getName('isSpotHire')}
              value={record.isSpotHire?.toString() ?? 'false'}
              label='Spot Hire?'
              required
              options={[
                {
                  value: 'true',
                  text: 'Yes',
                },
                {
                  value: 'false',
                  text: 'No',
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Dropdown
              name={getName('vehicleOffRoadReason')}
              value={record?.vehicleOffRoadReason ?? null}
              label='Off Road Reason'
              isClearable
              options={[
                {
                  value: 'MOT',
                  text: 'MOT',
                },
                {
                  value: 'Service',
                  text: 'Service',
                },
              ]}
            />
          </Col>
        </Row>

        <h4>Costs</h4>
        <hr />
        <Row>
          <NumberField
            record={record}
            name='rentalCostPerDay'
            label='Rental Cost'
          />
          <NumberField
            record={record}
            name='insuranceCostPerDay'
            label='Insurance Cost'
          />
          <NumberField
            record={record}
            name='washAllowancePerDay'
            label='Wash Allowance'
          />
          <NumberField
            record={record}
            name='trackerCostPerDay'
            label='Tracker Cost'
          />
          <NumberField
            record={record}
            name='depreciationAllowancePerDay'
            label='Depreciation Allowance'
          />
          <NumberField
            record={record}
            name='telematicsCostPerDay'
            label='Telematics Cost'
          />
          <NumberField
            record={record}
            name='damageAllowancePerDay'
            label='Damage Allowance'
          />
        </Row>

        <ErrorAlertForQueryOrNull error={error} isError={isError} />
      </Modal.Body>
      <FooterMain
        handleArchiveConfirmClick={handleArchiveConfirmClick}
        isArchiving={archiveVehicle.isLoading}
        handleSubmitClick={handleSubmitClick}
        closeButton={
          <Button onClick={props.handleCloseModalClick}>
            <Icon icon='fa-times' /> Cancel
          </Button>
        }
        isNew={isNew}
        isLoading={isLoading}
      />
    </Modal>
  );
};

const NumberField = ({
  record,
  name,
  label,
}: {
  record: Vehicle;
  name: keyof Vehicle;
  label: string;
}) => {
  return (
    <Field.InCol
      columns={6}
      name={name}
      label={label}
      value={AppUtils.formatNumber(record[name])}
      maxLength='9'
      isNumber
      addOnPre='£'
      addOnPost={'Per Day'}
      required
    />
  );
};

type SaveButtonProps = {
  isNew: boolean;
  isLoading: boolean;
  handleSubmitClick: () => void;
};

type FooterMainProps = {
  handleArchiveConfirmClick: () => void;
  isArchiving: boolean;
  closeButton: ReactNode;
} & SaveButtonProps;

const FooterMain = (props: FooterMainProps) => {
  const { closeButton, ...rest } = props;

  const [isArchiveUiShowing, setIsArchiveUiShowing] = useState(false);
  const handleArchiveClick = () => {
    setIsArchiveUiShowing(true);
  };

  return (
    <Modal.Footer>
      {!isArchiveUiShowing ? (
        <Stack
          direction='row'
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <div>
            {props.handleArchiveConfirmClick ? (
              <Button
                bsStyle='danger'
                onClick={handleArchiveClick}
                disabled={props.isLoading}
              >
                <Icon icon='fa-times' isSpinning={props.isLoading} /> Archive
              </Button>
            ) : null}
          </div>
          <Row>
            <Col sm={12}>
              {props.closeButton}
              <SaveButton {...rest} />
            </Col>
          </Row>
        </Stack>
      ) : null}

      {isArchiveUiShowing ? (
        <Alert bsStyle='danger' style={{ textAlign: 'left' }}>
          Are you sure you want to archive this vehicle?
          <Stack direction='row' style={{ marginTop: 10 }}>
            <Button
              onClick={() => {
                setIsArchiveUiShowing(false);
              }}
              disabled={props.isArchiving}
            >
              <Icon icon='fa-times' /> Cancel
            </Button>
            <Button
              bsStyle='danger'
              onClick={props.handleArchiveConfirmClick}
              disabled={props.isArchiving}
            >
              <Icon icon='fa-check' isSpinning={props.isArchiving} /> Yes,
              Archive
            </Button>
          </Stack>
        </Alert>
      ) : null}
    </Modal.Footer>
  );
};

const SaveButton = (props: SaveButtonProps) => {
  const buttonTitle = `${props.isNew ? 'Creat' : 'Sav'}${
    props.isLoading ? 'ing...' : 'e Vehicle'
  }`;
  return (
    <Button
      bsStyle='success'
      onClick={props.handleSubmitClick}
      disabled={props.isLoading}
    >
      <Icon icon='fa-check' isSpinning={props.isLoading} /> {buttonTitle}
    </Button>
  );
};
