import { Button, Col, Panel, Row } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import useFilterData from 'hooks/useFilterData';
import useGetConfig from 'hooks/useGetConfig';
import useSubscription from 'hooks/useSubscription';
import moment from 'moment';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { DatePicker } from 'pages/Utils/DatePicker';
import { TimeDropdown } from 'pages/Utils/TimeDropdown';
import { AppUtils, Constants } from 'pages/Utils/app-utils';
import {
  Field,
  MapToDiv,
  RateCol,
  SmallMuted,
  WellSmall,
  Icon,
} from 'pages/Utils/react-utils';
import { getAllocatedListViewConfig } from 'utils/api';
import { getAllocatedShifts } from 'utils/api';
import { useAlertWithRefreshButton } from 'components/AlertWithRefreshButton';
import DataTable from 'components/DataTable';
import { NoRecordsFoundMessage } from 'components/NoRecordsFoundMessage';

const _filterContainerId = 'AllocatedFiltersContainer';
const _filterFieldPrefix = 'allocatedFilter';

export const AllocatedShiftsListView = (props) => {
  const { isGettingConfig, configuration } = useGetConfig(
    getAllocatedListViewConfig
  );
  const { filterData, getDataModel, handleFilterChange } =
    useFilterData(_filterFieldPrefix);
  const { data, refetch, isLoading, isFetching, updatedAt } = useQuery(
    ['allocatedShifts', getDataModel()],
    () => getAllocatedShifts(getDataModel()),
    {
      enabled: !!configuration,
      refetchInterval: Constants.DefaultAutoRefetchFrequency,
    }
  );
  const records = data?.records || [];

  useSubscription(
    [ShiftUtils.Events.ShiftCreated, ShiftUtils.Events.ShiftCancelled],
    refetch
  );

  const alertWithRefreshButton = useAlertWithRefreshButton(
    'Shifts which have been Allocated but not yet started.',
    isLoading,
    isFetching,
    refetch
  );

  if (isGettingConfig) {
    return <GettingConfigMsg isGettingConfig={isGettingConfig} />;
  }
  if (!configuration) {
    return null;
  }

  return (
    <>
      <Filters
        updatedAt={updatedAt}
        isGetting={isLoading}
        configuration={configuration}
        filterData={filterData}
        handleSearchClick={refetch}
        handleFilterChange={handleFilterChange}
      />
      <TableContainer
        records={records}
        setDisplayShiftId={props.setDisplayShiftId}
        alertWithRefreshButton={alertWithRefreshButton}
      />
    </>
  );
};

const GettingConfigMsg = ({ isGettingConfig }) => {
  if (!isGettingConfig) {
    return null;
  }
  return (
    <WellSmall hasSpinner>
      Please wait while the Allocated Shift section is configured...
    </WellSmall>
  );
};

const Filters = (props) => {
  const {
    updatedAt,
    handleSearchClick,
    isGetting,
    configuration,
    filterData,
    handleFilterChange,
  } = props;

  const handleChangeDate = (name, date) =>
    handleFilterChange({
      target: { name, value: moment(date).format('YYYY-MM-DD') },
    });

  return (
    <Panel id={_filterContainerId}>
      <Panel.Body>
        <Row>
          <Col sm={4}>
            <DatePicker
              name={`${_filterFieldPrefix}FromDate`}
              label='From Date'
              value={AppUtils.getDateString(
                filterData[`${_filterFieldPrefix}FromDate`]
              )}
              changeDate={(e) =>
                handleChangeDate(`${_filterFieldPrefix}FromDate`, e.date)
              }
            />
            <TimeDropdown
              name={`${_filterFieldPrefix}FromTime`}
              label='From Time'
              placeholder='From Time'
              value={filterData[`${_filterFieldPrefix}FromTime`]}
              onChange={handleFilterChange}
            />
          </Col>

          <Col sm={4}>
            <DatePicker
              name={`${_filterFieldPrefix}ToDate`}
              label='To Date'
              value={AppUtils.getDateString(
                filterData[`${_filterFieldPrefix}ToDate`]
              )}
              changeDate={(e) =>
                handleChangeDate(`${_filterFieldPrefix}ToDate`, e.date)
              }
            />
            <TimeDropdown
              name={`${_filterFieldPrefix}ToTime`}
              label='To Time'
              placeholder='To Time'
              value={filterData[`${_filterFieldPrefix}ToTime`]}
              onChange={handleFilterChange}
            />
          </Col>

          <Col sm={4}>
            <Field.Main
              name={`${_filterFieldPrefix}HirerId`}
              label='Customer'
              value={filterData[`${_filterFieldPrefix}HirerId`]}
              options={configuration.hirerOptions}
              isSelect
              onChange={handleFilterChange}
            />
          </Col>
        </Row>
      </Panel.Body>
      <Panel.Footer className='text-right'>
        {updatedAt && (
          <SmallMuted className='pull-left'>
            Last updated {AppUtils.getTimeAgo(updatedAt)}
          </SmallMuted>
        )}
        <Button
          className='m-b-none'
          bsStyle='primary'
          bsSize='sm'
          onClick={handleSearchClick}
          disabled={isGetting}
        >
          <Icon icon='fa-search' isSpinning={isGetting} />{' '}
          {isGetting ? 'Searching' : 'Search'}
        </Button>
      </Panel.Footer>
    </Panel>
  );
};

const tableId = 'allocated-shifts-table';
const TableContainer = (props) => {
  const {
    records,
    setDisplayShiftId,
    alertWithRefreshButton: { alert, refreshButton },
  } = props;

  if (records.length <= 0) {
    return (
      <NoRecordsFoundMessage
        message='No Allocated Shifts Found.'
        records={records}
        refreshButton={refreshButton}
      />
    );
  }
  return (
    <>
      {alert}
      <DataTable
        records={records}
        tableId={tableId}
        render={() => {
          return (
            <table
              className='table table-condensed table-striped table-hover'
              id={tableId}
              data-order='[[ 1, "asc" ]]'
            >
              <thead>
                <tr>
                  <th>Details</th>
                  <th>When</th>
                  <th>Rate</th>
                  <th>Worker</th>
                  <th>Posted</th>
                  <th>Allocated</th>
                  <th className='no-sort' />
                </tr>
              </thead>
              <tbody>
                {records.map((record, i) => (
                  <TableRow
                    key={i}
                    record={record}
                    setDisplayShiftId={setDisplayShiftId}
                  />
                ))}
              </tbody>
            </table>
          );
        }}
      />
    </>
  );
};

const TableRow = (props) => {
  const { record, setDisplayShiftId } = props;
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  return (
    <tr>
      <td>
        <MapToDiv>{[record.companyName, record.name]}</MapToDiv>
      </td>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime)}
      </td>
      <td>
        <RateCol record={record} />
      </td>
      <td
        data-order={record.workerSurname}
      >{`${record.workerFirstName} ${record.workerSurname}`}</td>
      <td data-order={record.createdDateTime}>
        {AppUtils.formatDateTimeUtc(record.createdDateTime)}
        <div>
          <SmallMuted>by {record.createdBy}</SmallMuted>
        </div>
      </td>
      <td data-order={record.allocatedDateTime}>
        {AppUtils.formatDateTimeUtc(record.allocatedDateTime)}
        <div>
          <SmallMuted>{record.allocatedBy}</SmallMuted>
        </div>
      </td>
      <td className='text-right'>
        <Button
          bsStyle='primary'
          bsSize='xs'
          onClick={() => setDisplayShiftId(record.shiftId)}
        >
          Open &raquo;
        </Button>
      </td>
    </tr>
  );
};
