import { useState, useCallback } from 'react';

type CheckableShift = {
  shiftId: string;
  paymentAmount: number;
  paymentAuthRequestedDateTime?: string;
};

const calculateSelectedTotal = (
  records: CheckableShift[],
  recordIdsSelected: string[]
) => {
  return records
    .filter((record) => recordIdsSelected.indexOf(record.shiftId) > -1)
    .reduce((total, record) => total + record.paymentAmount, 0);
};

export const useListViewCheckbox = ({
  records,
  canRecordBeChecked = () => true,
}: {
  records: CheckableShift[];
  canRecordBeChecked?: (record: CheckableShift) => boolean;
}) => {
  const [recordIdsSelected, setRecordIdsSelected] = useState<string[]>([]);
  const [selectedTotal, setSelectedTotal] = useState(0);

  const handleCheckboxChange = (id: string) => {
    if (id === 'all') {
      return handleCheckboxSelectAllClick();
    }

    const getRecordIdsSelected = [...recordIdsSelected];
    const index = getRecordIdsSelected.indexOf(id);
    if (index > -1) {
      getRecordIdsSelected.splice(index, 1);
    } else {
      getRecordIdsSelected.push(id);
    }

    handleSelectRecordsFromState(getRecordIdsSelected);
  };

  const handleCheckboxSelectAllClick = () => {
    const getRecordIdsToBeSelected = () => {
      if (
        recordIdsSelected.length === records.filter(canRecordBeChecked).length
      ) {
        return [];
      }
      return records.filter(canRecordBeChecked).map((record) => record.shiftId);
    };

    handleSelectRecordsFromState(getRecordIdsToBeSelected());
  };

  const handleSelectRecordsFromManualSource = (
    sourceRecords: CheckableShift[],
    recordIdsSelected: string[]
  ) => {
    const selectedRecords = sourceRecords.filter(canRecordBeChecked);
    const filteredRecordIdsSelected = recordIdsSelected.filter((id) => {
      return selectedRecords.findIndex((record) => record.shiftId === id) > -1;
    });
    const selectedTotal = calculateSelectedTotal(
      sourceRecords,
      filteredRecordIdsSelected
    );
    setRecordIdsSelected(filteredRecordIdsSelected);
    setSelectedTotal(selectedTotal);
  };

  const handleSelectRecordsFromState = useCallback(
    (recordIdsSelected: string[]) => {
      const selectedRecords = records.filter(canRecordBeChecked);
      const filteredRecordIdsSelected = recordIdsSelected.filter((id) => {
        return (
          selectedRecords.findIndex((record) => record.shiftId === id) > -1
        );
      });
      const selectedTotal = calculateSelectedTotal(
        selectedRecords,
        filteredRecordIdsSelected
      );
      setRecordIdsSelected(filteredRecordIdsSelected);
      setSelectedTotal(selectedTotal);
    },
    [canRecordBeChecked, records]
  );

  const handleNewRecords = (newRecords: CheckableShift[]) => {
    const newRecordIdsSelected = recordIdsSelected.filter((id) => {
      return newRecords.findIndex((record) => record.shiftId === id) > -1;
    });
    handleSelectRecordsFromManualSource(newRecords, newRecordIdsSelected);
  };

  const resetSelectedRecords = () => {
    setRecordIdsSelected([]);
    setSelectedTotal(0);
  };

  const areAllSelected = () => {
    return (
      recordIdsSelected.length === records.filter(canRecordBeChecked).length
    );
  };

  return {
    handleCheckboxChange,
    resetSelectedRecords,
    handleNewRecords,
    selectedTotal,
    recordIdsSelected,
    areAllSelected,
  };
};
