import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useGetTrailersActiveQuery } from 'hooks/queries/trailerQueries';
import { useModal } from 'hooks/useModal';
import useSubscription from 'hooks/useSubscription';
import { Trailer } from 'types/TrailerTypes';
import { useAlertWithRefreshButton } from 'components/AlertWithRefreshButton';
import DataTable from 'components/DataTable';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { NoRecordsFoundMessage } from 'components/NoRecordsFoundMessage';
import { TrailerModal } from './TrailerModal';
import { TrailerUtils } from './TrailerUtils';

const tableId = 'trailers-active-table';

const useEditTrailerModal = () => {
  const [trailerToEdit, setTrailerToEdit] = useState<Trailer | null>(null);
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const handleShowModalClick = (trailer: Trailer) => {
    setTrailerToEdit(trailer);
    handleModalOpen();
  };

  const handleCloseModalClick = () => {
    setTrailerToEdit(null);
    handleModalClose();
  };

  return {
    handleShowModalClick,
    handleCloseModalClick,
    isModalOpen,
    trailerToEdit,
  };
};

export const TrailersListViewActive = () => {
  const { data, isLoading, isFetching, refetch, error, isError } =
    useGetTrailersActiveQuery();
  const records = data || [];

  useSubscription([TrailerUtils.Events.TrailerSaved], refetch);

  const {
    handleCloseModalClick,
    handleShowModalClick,
    isModalOpen,
    trailerToEdit,
  } = useEditTrailerModal();

  const handleEditClick = (trailer: Trailer) => {
    handleShowModalClick(trailer);
  };

  const { alert, refreshButton } = useAlertWithRefreshButton(
    'List of your registered trailers',
    isLoading,
    isFetching,
    refetch
  );

  if (isError) {
    return <ErrorAlertForQueryOrNull isError={isError} error={error} />;
  }

  if (records.length <= 0) {
    return (
      <NoRecordsFoundMessage
        records={records}
        message='No trailers found'
        refreshButton={refreshButton}
      />
    );
  }

  return (
    <>
      {alert}
      <DataTable
        records={records}
        tableId={tableId}
        render={() => {
          return (
            <table
              className='table table-condensed table-striped table-hover'
              id={tableId}
            >
              <thead>
                <tr>
                  <th>Trailer Reference</th>
                  <th className='no-sort' />
                </tr>
              </thead>
              <tbody>
                {records.map((item, i) => (
                  <Row
                    key={i}
                    record={item}
                    handleEditClick={handleEditClick}
                  />
                ))}
              </tbody>
            </table>
          );
        }}
      />
      {trailerToEdit ? (
        <TrailerModal
          handleCloseModalClick={handleCloseModalClick}
          showModal={isModalOpen}
          record={trailerToEdit}
        />
      ) : null}
    </>
  );
};

const Row = ({
  record,
  handleEditClick,
}: {
  record: Trailer;
  handleEditClick: (record: Trailer) => void;
}) => {
  return (
    <tr>
      <td>{record.reference}</td>
      <td className='pull-right'>
        <Button
          bsStyle='primary'
          bsSize='xs'
          onClick={() => handleEditClick(record)}
        >
          Edit
        </Button>
      </td>
    </tr>
  );
};
