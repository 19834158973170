import { Fragment, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Panel,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { ResponsiveBar } from '@nivo/bar';
import {
  useDownloadProfitReportDataQuery,
  useGetProfitabilityReportQuery,
} from 'hooks/queries';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils } from 'pages/Utils/app-utils';
import { DashboardPanel, Icon, WellSmall } from 'pages/Utils/react-utils';
import { ProfitabilityReportQueryResponse } from 'types/ReportingTypes';
import { usePermissions, userPermissions } from 'utils/userPermissions';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { LoadingButton } from 'components/LoadingButton';
import { UnauthorisedAlert } from 'components/UnauthorisedAlert';
import { VehicleReport } from './VehicleReport';
import { ResponsiveBarChartHorizontal } from './common';
import { useReportDates } from './reportiUtils';

export const ReportingAdmin = () => {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabSelect = (tabToMakeActive: number) => {
    setActiveTab(tabToMakeActive);
  };

  const { hasPermission } = usePermissions();
  const canView = hasPermission(userPermissions.reports.view);

  if (!canView) {
    return <UnauthorisedAlert />;
  }

  let eventKey = 1;
  return (
    <div className='tabs-container'>
      <Tabs
        activeKey={activeTab}
        animation={false}
        onSelect={(tab) => handleTabSelect(tab as number)}
        id='reporting-tabs'
      >
        <Tab eventKey={eventKey++} title='Profitability'>
          <ReportMain />
        </Tab>
        <Tab eventKey={eventKey++} title='Vehicles'>
          <VehicleReport />
        </Tab>
      </Tabs>
    </div>
  );
};

const ReportMain = () => {
  const { selectedStartDate, selectedEndDate, handleSetDate } =
    useReportDates();

  const queryParams = {
    startDate: selectedStartDate,
    endDate: selectedEndDate,
  };

  const query = useGetProfitabilityReportQuery(queryParams);

  const handleGenerateClick = () => {
    query.refetch();
  };

  const isLoading = query.isFetching;

  const downloadData = useDownloadProfitReportDataQuery(queryParams);
  const handleDownloadClick = () => {
    downloadData.refetch();
  };

  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col sm={8}>
            <Panel.Title componentClass='h3'>Profitability Report</Panel.Title>
          </Col>
          <Col sm={4} className='text-right'>
            <Button
              bsSize='sm'
              bsStyle='primary'
              onClick={handleDownloadClick}
              disabled={downloadData.isFetching}
            >
              <Icon icon='fa-download' isSpinning={downloadData.isFetching} />{' '}
              Download
            </Button>
          </Col>
        </Row>
      </Panel.Heading>
      <Panel.Body>
        <Row>
          <Col md={4}>
            <DatePicker
              name={`StartDate`}
              label='Start Date'
              value={AppUtils.getDateString(selectedStartDate)}
              changeDate={(e: { date: Date }) => {
                handleSetDate('start', e.date);
              }}
            />
          </Col>
          <Col md={4}>
            <DatePicker
              name={`EndDate`}
              label='End Date'
              value={AppUtils.getDateString(selectedEndDate)}
              changeDate={(e: { date: Date }) => {
                handleSetDate('end', e.date);
              }}
            />
          </Col>
          <Col md={4}>
            <FormGroup>
              <label className='control-label'>&nbsp;</label>
              <div>
                <LoadingButton
                  isLoading={isLoading}
                  onClick={handleGenerateClick}
                  text='Generate Report'
                  loadingText='Generating Report'
                  Icon={<Icon icon='fa-cog' />}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>

        <hr />

        {!query.data && isLoading ? (
          <WellSmall hasSpinner>Generating report...</WellSmall>
        ) : null}

        <ErrorAlertForQueryOrNull isError={query.isError} error={query.error} />

        {query.data ? (
          <ReportBody data={query.data} />
        ) : (
          <WellSmall>No report data</WellSmall>
        )}
      </Panel.Body>
    </Panel>
  );
};

const ReportBody = ({ data }: { data: ProfitabilityReportQueryResponse }) => {
  const topLevelData = data.topLevelData;
  return (
    <>
      <Row>
        <Col sm={2}>
          <DashboardPanel
            title={'Shifts'}
            content={
              <h1 className='no-margins'>
                {AppUtils.formatNumber(topLevelData.shiftQty)}
              </h1>
            }
          />
        </Col>
        <Col sm={10}>
          <Row>
            <Col md={3}>
              <CurrencyPanel
                title='Total Revenue'
                value={topLevelData.revenue}
              />
            </Col>
            <Col md={3}>
              <CurrencyPanel title='Total Costs' value={topLevelData.costs} />
            </Col>
            <Col md={3}>
              <CurrencyPanel title='Total Profit' value={topLevelData.profit} />
            </Col>
            <Col md={3}>
              <DashboardPanel
                title={'Profit Margin'}
                content={
                  <h1 className='no-margins'>
                    {AppUtils.formatNumber(topLevelData.margin)}%
                  </h1>
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <CustomerValueChart data={data} />
        </Col>
        <Col sm={6}>
          <CustomerMarginChart data={data} />
        </Col>
      </Row>
      <LastSixMonthsChart data={data} />
      <CustomerSummaryPanel data={data} />
    </>
  );
};

const CurrencyPanel = ({
  title,
  value,
}: {
  title: string;
  value: number | null;
}) => {
  return (
    <DashboardPanel
      title={title}
      content={<h1 className='no-margins'>{AppUtils.getCurrencyVal(value)}</h1>}
    />
  );
};

const CustomerValueChart = ({
  data,
}: {
  data: ProfitabilityReportQueryResponse;
}) => {
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title componentClass='h3'>
          Customer Breakdown from{' '}
          {AppUtils.formatDateTimeUtc(data.startDate, undefined, 'DD MMM YYYY')}{' '}
          to{' '}
          {AppUtils.formatDateTimeUtc(data.endDate, undefined, 'DD MMM YYYY')}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        {data.customerNameData.length === 0 ? (
          <Alert bsStyle='warning'>
            No customer data found for this period
          </Alert>
        ) : (
          <div style={{ width: '100%', height: '500px' }}>
            <ResponsiveBarChartHorizontal
              data={data.customerNameData
                .filter((item) => item.customerName !== 'General Haulage')
                .sort((a, b) => a.profit - b.profit)}
              keys={['costs', 'revenue', 'profit']}
              indexBy={'customerName'}
            />
          </div>
        )}
      </Panel.Body>
    </Panel>
  );
};

const CustomerMarginChart = ({
  data,
}: {
  data: ProfitabilityReportQueryResponse;
}) => {
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title componentClass='h3'>
          Customer Margin from{' '}
          {AppUtils.formatDateTimeUtc(data.startDate, undefined, 'DD MMM YYYY')}{' '}
          to{' '}
          {AppUtils.formatDateTimeUtc(data.endDate, undefined, 'DD MMM YYYY')}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        {data.customerNameData.length === 0 ? (
          <Alert bsStyle='warning'>
            No customer data found for this period
          </Alert>
        ) : (
          <div style={{ width: '100%', height: '500px' }}>
            <ResponsiveBarChartHorizontal
              data={data.customerNameData
                .filter((item) => item.customerName !== 'General Haulage')
                .sort((a, b) => a.margin - b.margin)}
              indexBy='customerName'
              keys={['margin']}
              valueFormat={(v) => `${AppUtils.formatNumber(v)}%`}
              colors={['#ccebc5']}
            />
          </div>
        )}
      </Panel.Body>
    </Panel>
  );
};

const LastSixMonthsChart = ({
  data,
}: {
  data: ProfitabilityReportQueryResponse;
}) => {
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title componentClass='h3'>
          Monthly Breakdown for 6 months prior to{' '}
          {AppUtils.formatDateTimeUtc(data.endDate, undefined, 'DD MMM YYYY')}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        {data.sixMonthsPriorData.length === 0 ? (
          <Alert bsStyle='warning'>No monthly data found for this period</Alert>
        ) : (
          <div style={{ width: '100%', height: '500px' }}>
            <ResponsiveBar
              colors={{ scheme: 'pastel1' }}
              data={data.sixMonthsPriorData}
              indexBy='date'
              keys={['costs', 'revenue', 'profit']}
              groupMode='grouped'
              margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
              valueScale={{ type: 'linear' }}
              valueFormat={(v) => `£${AppUtils.formatNumber(v)}`}
              indexScale={{ type: 'band', round: true }}
              axisLeft={{
                format: (v) => `£${AppUtils.formatNumber(v)}`,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        )}
      </Panel.Body>
    </Panel>
  );
};

const CustomerSummaryPanel = ({
  data,
}: {
  data: ProfitabilityReportQueryResponse;
}) => {
  if (!data.shiftsByWeekSummary) {
    return null;
  }

  const weeklyData = data.shiftsByWeekSummary;

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title componentClass='h3'>
          Customer Weekly Summary from{' '}
          {AppUtils.formatDateTimeUtc(
            weeklyData.startDate,
            undefined,
            'DD MMM YYYY'
          )}{' '}
          to{' '}
          {AppUtils.formatDateTimeUtc(
            weeklyData.endDate,
            undefined,
            'DD MMM YYYY'
          )}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        {!weeklyData.dates?.length ? (
          <Alert bsStyle='warning'>
            No weekly summary data found for this period
          </Alert>
        ) : (
          <table
            id='customer-weekly-summary-table'
            className='table table-hover table-condensed table-bordered'
          >
            <thead>
              <tr>
                <th>Customer</th>
                {weeklyData.dates.map((date) => (
                  <th
                    key={date}
                    colSpan={2}
                    style={{
                      textAlign: 'center',
                      borderLeft: '2px solid #ccc',
                    }}
                  >
                    {date}
                  </th>
                ))}
              </tr>
              <tr>
                <th />
                {weeklyData.dates.map((date) => (
                  <Fragment key={date}>
                    <th style={{ borderLeft: '2px solid #ccc' }}>Qty</th>
                    <th>Revenue</th>
                  </Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {weeklyData.shiftsByWeekSummaryData
                .filter((item) => item.customerName !== 'General Haulage')
                .map((row) => (
                  <tr key={row.customerName}>
                    <td>{row.customerName}</td>
                    {row.shiftsByWeekSummaryItems.map((item, i) => {
                      const previousItem =
                        i === 0 ? null : row.shiftsByWeekSummaryItems[i - 1];
                      const classNameQty = getIncreasedOrDecreased(
                        previousItem?.shiftQty,
                        item.shiftQty
                      );

                      const classNameRevenue = getIncreasedOrDecreased(
                        previousItem?.revenue,
                        item.revenue
                      );

                      return (
                        <Fragment key={i}>
                          <td
                            className={classNameQty}
                            style={{ borderLeft: '2px solid #ccc' }}
                          >
                            {getIcon(classNameQty)} {item.shiftQty}
                          </td>
                          <td className={classNameRevenue}>
                            {getIcon(classNameRevenue)}{' '}
                            {AppUtils.getCurrencyVal(item.revenue)}
                          </td>
                        </Fragment>
                      );
                    })}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </Panel.Body>
    </Panel>
  );
};

const getIncreasedOrDecreased = (
  previousVal: number | undefined,
  currentVal: number
) => {
  if (previousVal === undefined) {
    return null;
  }

  if (currentVal > previousVal) {
    return 'increase';
  }

  if (currentVal < previousVal) {
    return 'decrease';
  }

  return null;
};

const getIcon = (type: 'increase' | 'decrease' | null) => {
  if (type === 'increase') {
    return <Icon icon='fa-arrow-up' />;
  }

  if (type === 'decrease') {
    return <Icon icon='fa-arrow-down' />;
  }

  return null;
};
