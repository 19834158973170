import { useState } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';
import { useAuth } from 'utils/auth.js';
import { history, useLocation } from 'utils/router';
import { Icon, SpinIcon } from '../pages/Utils/react-utils';
import FormField from './FormField';

function AuthForm(props) {
  const auth = useAuth();
  const location = useLocation();
  const qs = queryString.parse(location.search);

  const [pending, setPending] = useState(false);
  const [passwordResetEmailSent, setPasswordResetEmailSent] = useState(false);
  const { handleSubmit, register, errors, getValues } = useForm({
    defaultValues: {
      ...(qs.emailAddress && { email: qs.emailAddress }),
    },
  });

  const submitHandlersByType = {
    signin: ({ email, pass }) => {
      return auth.signin(email, pass).then((user) => {
        // Call auth complete handler
        props.onAuth(user);
      });
    },
    signup: ({ email, pass }) => {
      return auth.signup(email, pass).then((user) => {
        // Call auth complete handler
        props.onAuth(user);
      });
    },
    forgotpass: ({ email }) => {
      return auth.sendPasswordResetEmail(email).then(() => {
        setPending(false);
        setPasswordResetEmailSent(true);

        // Show success alert message
        props.onFormAlert({
          type: 'success',
          message: 'Password reset email sent',
        });
      });
    },
    changepass: ({ pass }) => {
      return auth.confirmPasswordReset(pass).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: 'success',
          message: 'Your password has been changed',
        });
      });
    },
  };

  // Handle form submission
  const onSubmit = ({ email, pass }) => {
    // Show pending indicator
    setPending(true);

    // Call submit handler for auth type
    submitHandlersByType[props.type]({
      email,
      pass,
    }).catch((error) => {
      setPending(false);
      // Show error alert message
      props.onFormAlert({
        type: 'error',
        message: error.message,
      });
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {['signup', 'signin', 'forgotpass'].includes(props.type) && (
        <FormGroup
          controlId='formEmail'
          validationState={errors.email ? 'error' : undefined}
        >
          <FormField
            size='lg'
            name='email'
            type='email'
            placeholder='Email'
            error={errors.email}
            inputRef={register({
              required: 'Please enter an email',
            })}
          />
        </FormGroup>
      )}
      {['signup', 'signin', 'changepass'].includes(props.type) && (
        <FormGroup
          controlId='formPassword'
          validationState={errors.pass ? 'error' : undefined}
        >
          <FormField
            size='lg'
            name='pass'
            type='password'
            placeholder='Password'
            error={errors.pass}
            inputRef={register({
              required: 'Please enter a password',
            })}
          />
        </FormGroup>
      )}
      {['signup', 'changepass'].includes(props.type) && (
        <FormGroup controlId='formConfirmPass'>
          <FormField
            size='lg'
            name='confirmPass'
            type='password'
            placeholder='Confirm Password'
            error={errors.confirmPass}
            inputRef={register({
              required: 'Please enter your password again',
              validate: (value) => {
                if (value === getValues().pass) {
                  return true;
                } else {
                  return "This doesn't match your password";
                }
              },
            })}
          />
        </FormGroup>
      )}
      <Button bsStyle='primary' block type='submit' disabled={pending}>
        {!pending && (
          <>
            <Icon icon='fa-envelope' /> {props.typeValues.buttonText}
          </>
        )}

        {pending && (
          <Spinner
            animation='border'
            size='sm'
            role='status'
            aria-hidden={true}
            className='align-baseline'
          >
            <span className='sr-only'>Loading...</span>
          </Spinner>
        )}
      </Button>
      {passwordResetEmailSent && (
        <Button
          bsStyle='primary'
          block
          onClick={() => {
            history.replace('/auth/signin');
          }}
        >
          <Icon icon='fa-home' /> Sign In
        </Button>
      )}
    </Form>
  );
}

const Spinner = () => {
  return <SpinIcon />;
};

export default AuthForm;
