import { useState } from 'react';
import { Panel, Row, Col, Alert, Well } from 'react-bootstrap';
import { useSelectedCustomer } from 'hooks';
import { useCalculateMutation, useVehicleOptions } from 'hooks/queries';
import { useGetVehicleCostConfigQuery } from 'hooks/queries/agencyQueries';
import { AppUtils } from 'pages/Utils/app-utils';
import {
  AlertWithIcon,
  Field,
  InputField,
  WellSmall,
} from 'pages/Utils/react-utils';
import { ICustomEvent } from 'types/GeneralTypes';
import { CalculationResponse } from 'types/ShiftTypes';
import { Vehicle } from 'types/VehicleTypes';
import { nameof } from 'utils';
import { Button } from 'components/Button';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { Stack } from 'components/Stack';
import { CalculatorLocations } from './CalculatorLocations';
import './_style/shiftCalculatorStyles.scss';

const getName = (fieldName: keyof Vehicle) => nameof<Vehicle>(fieldName);

export const ShiftCalculator = () => {
  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col sm={8}>
            <Panel.Title componentClass='h3'>Job Calculator</Panel.Title>
          </Col>
        </Row>
      </Panel.Heading>
      <Panel.Body>
        <CalculatorMain />
      </Panel.Body>
    </Panel>
  );
};

const CalculatorMain = () => {
  const selectedCustomerProps = useSelectedCustomer();
  const hirerId =
    Boolean(selectedCustomerProps.selectedCustomerId) === false
      ? 'NoCustomerSelected'
      : selectedCustomerProps.selectedCustomerId;
  const [vehicleId, setVehicleId] = useState<string | null>(null);
  const [responseData, setResponseData] = useState<CalculationResponse | null>(
    null
  );

  const [inputData, setInputData] = useState<{
    desiredMarginPercentage: number;
    driverPayAmount: number;
  } | null>({
    desiredMarginPercentage: 10,
    driverPayAmount: 200,
  });

  const handleUpdateProperty = (
    property: keyof typeof inputData,
    value: string
  ) => {
    setInputData({
      ...inputData,
      [property]: value,
    });
  };

  const calculate = useCalculateMutation();
  const vehicleCostConfigQuery = useGetVehicleCostConfigQuery();
  const vehiclesQuery = useVehicleOptions();
  const isFetching =
    vehicleCostConfigQuery.isFetching || vehiclesQuery.query.isFetching;
  const isLoading =
    vehicleCostConfigQuery.isLoading || vehiclesQuery.query.isLoading;

  if (isLoading || (isFetching && !vehicleCostConfigQuery.data)) {
    return <WellSmall hasSpinner />;
  }

  const globalConfigData = vehicleCostConfigQuery.data;
  const vehicle = vehiclesQuery.vehicleData.find(
    (vehicle) => vehicle.vehicleId === vehicleId
  );

  const handleCalculateClick = () => {
    if (isNaN(inputData.desiredMarginPercentage)) {
      AppUtils.displayError('Error', 'Please enter a valid number for margin');
      return;
    }

    if (isNaN(inputData.driverPayAmount)) {
      AppUtils.displayError(
        'Error',
        'Please enter a valid number for pay amount'
      );
      return;
    }

    setResponseData(null);

    calculate.mutate(
      {
        hirerId,
        vehicleId,
        desiredMarginPercentage: inputData.desiredMarginPercentage,
        driverPayAmount: inputData.driverPayAmount,
      },
      {
        onSuccess: (data) => {
          setResponseData(data);
        },
      }
    );
  };

  return (
    <>
      <Row>
        <Field.InCol
          columns={3}
          name='AverageMilesPerGallon'
          label='Average MPG'
          value={AppUtils.formatNumber(globalConfigData.averageMilesPerGallon)}
          maxLength='5'
          isNumber
          required
          tooltip='Average Miles Per Gallon. This is used to calculate the fuel costs when a vehicle is used on a shift.'
          disabled
        />
        <Field.InCol
          columns={3}
          name='FuelCostPerLitre'
          label='Fuel Cost Per Litre'
          value={AppUtils.formatNumber(globalConfigData.fuelCostPerLitre)}
          maxLength='9'
          isNumber
          addOnPre='£'
          addOnPost={'Per Litre'}
          required
          disabled
        />
        <Field.InCol
          columns={3}
          name='TyreCostPerMile'
          label='Tyre Cost Per Mile'
          value={AppUtils.formatNumber(globalConfigData.tyreCostPerMile)}
          maxLength='9'
          isNumber
          addOnPre='£'
          addOnPost={'Per Mile'}
          required
          disabled
        />
        <Field.InCol
          columns={3}
          name='AdBlueCostPerMile'
          label='AdBlue Cost Per Mile'
          value={AppUtils.formatNumber(globalConfigData.adBlueCostPerMile)}
          maxLength='9'
          isNumber
          addOnPre='£'
          addOnPost={'Per Mile'}
          required
          disabled
        />
      </Row>

      <Row>
        <Field.InCol
          columns={3}
          name={`VehicleId`}
          label={`Vehicle`}
          options={vehiclesQuery.vehicleOptions}
          isSelect
          value={vehicleId}
          onChange={(e: ICustomEvent) => {
            setVehicleId(e.target.value);
          }}
        />

        {vehicle ? (
          <>
            <Field.InCol
              columns={3}
              name={getName('make')}
              label='Make'
              value={vehicle.make}
              maxLength='50'
              disabled
              isControlled
            />
            <Field.InCol
              columns={3}
              name={getName('model')}
              label='Model'
              value={vehicle.model}
              maxLength='50'
              disabled
              isControlled
            />
          </>
        ) : null}
      </Row>

      {vehicleId && !vehicle ? (
        <AlertWithIcon>
          Vehicle not found. Please select a valid vehicle.
        </AlertWithIcon>
      ) : null}

      {!vehicleId ? (
        <AlertWithIcon>Please select a vehicle.</AlertWithIcon>
      ) : null}

      {vehicle ? (
        <>
          <h4>Costs</h4>
          <hr />
          <Row>
            <NumberField
              record={vehicle}
              name='rentalCostPerDay'
              label='Rental Cost'
            />
            <NumberField
              record={vehicle}
              name='insuranceCostPerDay'
              label='Insurance Cost'
            />
            <NumberField
              record={vehicle}
              name='washAllowancePerDay'
              label='Wash Allowance'
            />
            <NumberField
              record={vehicle}
              name='trackerCostPerDay'
              label='Tracker Cost'
            />
            <NumberField
              record={vehicle}
              name='depreciationAllowancePerDay'
              label='Depreciation Allowance'
            />
            <NumberField
              record={vehicle}
              name='telematicsCostPerDay'
              label='Telematics Cost'
            />
            <NumberField
              record={vehicle}
              name='damageAllowancePerDay'
              label='Damage Allowance'
            />
          </Row>

          <hr />

          <Row className='m-b'>
            <Col sm={4}>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <span style={{ flexBasis: '56px' }}>Customer:</span>
                <div style={{ flexGrow: 1 }}>
                  {selectedCustomerProps.renderCustomerSelect()}
                </div>
              </div>
            </Col>
          </Row>

          <CalculatorLocations hirerId={hirerId} />

          <hr />
          <Row>
            <Col sm={4}>
              <InputField
                isControlled
                label='% Margin Required'
                value={inputData?.desiredMarginPercentage}
                onChange={(e: ICustomEvent) => {
                  handleUpdateProperty(
                    'desiredMarginPercentage',
                    e.target.value
                  );
                }}
              />
            </Col>
            <Col sm={4}>
              <InputField
                isControlled
                label='Worker Pay Amount Total'
                value={inputData?.driverPayAmount}
                onChange={(e: ICustomEvent) => {
                  handleUpdateProperty('driverPayAmount', e.target.value);
                }}
              />
            </Col>
          </Row>
          <hr />
          <Button
            onClick={handleCalculateClick}
            bsStyle='primary'
            isLoading={calculate.isLoading}
          >
            Calculate
          </Button>

          <ErrorAlertForQueryOrNull
            className='m-t'
            isError={calculate.isError}
            error={calculate.error}
          />

          {responseData ? (
            <Well className='m-t'>
              <Stack gap={30}>
                <div>
                  <h4>Results</h4>
                  <Row>
                    <Col sm={3}>
                      <Card
                        title='Charge Amount'
                        value={responseData.chargeAmount}
                      />
                    </Col>
                    <Col sm={3}>
                      <Card
                        title='Total Cost'
                        value={responseData.totalCost}
                        bsStyle='danger'
                      />
                    </Col>
                    <Col sm={3}>
                      <Card
                        title='Total Vehicle Cost'
                        value={responseData.totalVehicleCost}
                        bsStyle='info'
                      />
                    </Col>
                    <Col sm={3}>
                      <Card
                        title='Driver Pay'
                        value={responseData.driverPayAmount}
                        bsStyle='info'
                      />
                    </Col>
                  </Row>
                </div>

                <div>
                  <h4>Breakdown</h4>
                  <p>
                    <strong>Distance In Miles:</strong>{' '}
                    {AppUtils.formatNumber(responseData.distanceInMiles)}
                  </p>

                  <ResultRow label='Fuel' value={responseData.fuelCost} />
                  <ResultRow label='Tyres' value={responseData.tyreCost} />
                  <ResultRow label='Ad Blue' value={responseData.adBlueCost} />
                  <ResultRow
                    label='Vehicle Fixed Cost (day)'
                    value={responseData.vehicleFixedCost}
                  />
                  <ResultRow
                    label='Total Vehicle Costs'
                    value={responseData.totalVehicleCost}
                  />
                  <ResultRow
                    label='Driver Pay'
                    value={responseData.driverPayAmount}
                  />
                  <ResultRow
                    label='Total Costs'
                    value={responseData.totalCost}
                  />
                  <ResultRow
                    label='Charge Amount'
                    value={responseData.chargeAmount}
                  />
                </div>
              </Stack>
            </Well>
          ) : null}
        </>
      ) : null}
    </>
  );
};

const Card = ({
  bsStyle = 'success',
  title,
  value,
}: {
  title: string;
  value: number;
  bsStyle?: string;
}) => {
  return (
    <Alert bsStyle={bsStyle}>
      <div className='result-card'>
        <h3>{title}</h3>
        <p>{AppUtils.getCurrencyVal(value)}</p>
      </div>
    </Alert>
  );
};

const ResultRow = ({ label, value }: { label: string; value: number }) => {
  return (
    <p>
      <strong>{label}:</strong> {AppUtils.getCurrencyVal(value)}
    </p>
  );
};

const NumberField = ({
  record,
  name,
  label,
}: {
  record: Vehicle;
  name: keyof Vehicle;
  label: string;
}) => {
  return (
    <Field.InCol
      columns={3}
      name={name}
      label={label}
      value={AppUtils.formatNumber(record[name])}
      maxLength='9'
      isNumber
      addOnPre='£'
      addOnPost={'Per Day'}
      required
      disabled
      isControlled
    />
  );
};
