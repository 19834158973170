import { IGetHirersResponse } from 'types/HirerTypes';
import {
  IApiData,
  apiGet,
  apiPost,
  apiRequestRaw,
  apiPostWithFormData,
} from './apiUtils';

export const getAgencyHomeData = async () => {
  return apiGet('Agency/Home');
};

export const getHirerHomeData = async () => {
  return apiGet('Hirer/Home');
};

export const getConfigForNewShiftConfigModal = async () => {
  return apiGet('ShiftConfig/ConfigForNewShiftConfig');
};

export const getShiftsRunningForTooLong = async () => {
  return apiGet('Shift/RunningForTooLong');
};

export const getOpenShiftsListViewConfig = async () => {
  return apiGet('Shift/OpenListViewConfig');
};

export const getOpenShifts = async (data: IApiData) => {
  return apiGet('Shift/GetOpen', data);
};

export const createNewShiftConfig = async (data: IApiData) => {
  return apiPost('ShiftConfig/CreateNew', data);
};

export const getChangeOnCallUserConfig = async () => {
  return apiGet('Agency/ChangeOnCallUserConfig');
};

export const updateOnCallUser = async (data: IApiData) => {
  return apiPost('Agency/ChangeOnCallUser', data);
};

export const getNewShiftConfig = async () => {
  return apiGet('Shift/Config');
};

export const createShift = async (data: IApiData) => {
  return apiPost('Shift/CreateNew', data);
};

export const acceptShift = async (data: IApiData) => {
  return apiPost('Shift/Accept', data);
};

export const getShiftById = async (shiftId: string) => {
  return apiGet(`Shift/Id/${shiftId}`);
};

export const getUnpaidShifts = async () => {
  return apiGet(`Shift/GetUnpaid`);
};

export const getExpiredShifts = async () => {
  return apiGet(`Shift/Expired`);
};

export const getInProgressShifts = async () => {
  return apiGet(`Shift/v2/InProgress`);
};

export const getRejectedShifts = async () => {
  return apiGet(`Shift/GetRejected`);
};

export const getNoShowShifts = async () => {
  return apiGet(`Shift/NoShow`);
};

export const getCurrentSchedule = async () => {
  return apiGet(`Shift/CurrentSchedule`);
};

export const getAllocatedListViewConfig = async () => {
  return apiGet(`Shift/AllocatedListViewConfig`);
};

export const getAllocatedShifts = async (data: IApiData) => {
  return apiGet(`Shift/Allocated`, data);
};

export const getPreAllocatedListViewConfig = async () => {
  return apiGet(`Shift/PreAllocatedListViewConfig`);
};

export const getPreAllocatedShifts = async (data: IApiData) => {
  return apiGet(`Shift/PreAllocatedSearch`, data);
};

export const getCompletedListViewConfig = async () => {
  return apiGet(`Shift/CompletedListViewConfig`);
};

export const getCompletedShifts = async (data: IApiData) => {
  return apiGet(`Shift/Completed`, data);
};

export const getApprovedListViewConfig = async () => {
  return apiGet(`Shift/ApprovedListViewConfig`);
};

export const getApprovedShifts = async (data: IApiData) => {
  return apiGet(`Shift/GetApproved`, data);
};

export const getCancelledShifts = async (data: IApiData) => {
  return apiGet(`Shift/GetCancelled`, data);
};

export const getRePostShiftConfig = async () => {
  return apiGet(`Shift/RePostShiftConfig`);
};

export const cancelShift = async (data: IApiData) => {
  return apiPost('Shift/Cancel', data);
};

export const resetShiftFinancials = async (shiftId: string) => {
  return apiPost(`Shift/ResetFinancials/${shiftId}`, null);
};

export const addAdjustment = async (data: IApiData) => {
  return apiPost('Shift/AddAdjustment', data);
};

export const deleteAdjustment = async (data: IApiData) => {
  return apiPost('Shift/ClearAdjustment', data);
};

export const updateShiftTime = async (data: IApiData) => {
  return apiPost('Shift/ChangeTime', data);
};

export const updateShiftTimes = async (data: IApiData) => {
  return apiPost('Shift/UpdateTimes', data);
};

export const approveShift = async (data: IApiData) => {
  return apiPost('Shift/Approve', data);
};

export const disapproveShift = async (data: IApiData) => {
  return apiPost('Shift/Disapprove', data);
};

export const setPaidForShift = async (data: IApiData) => {
  return apiPost('Shift/MarkAsPaid', data);
};

export const authorisePayments = async (data: IApiData) => {
  return apiPost('Shift/AuthorisePayments', data);
};

export const deleteShift = async (data: IApiData) => {
  return apiPost('Shift/Delete', data);
};

export const repostShift = async (data: IApiData) => {
  return apiPost('Shift/RePostShift', data);
};

export const getAgencyUsers = async () => {
  return apiGet(`Agency/Users`);
};

export const saveAgencyUser = async (data: IApiData) => {
  return apiPost('Agency/UserSave', data);
};

export const getAgencyProfile = async () => {
  return apiGet(`Agency/ProfileGet`);
};

export const getAgencyProfileEditConfig = async () => {
  return apiGet(`Agency/GetEditConfig`);
};

export const saveAgency = async (data: IApiData) => {
  return apiPost('Agency/Save', data);
};

export const getConfigForEditShiftConfigUi = async () => {
  return apiGet('ShiftConfig/Config');
};

export const getShiftConfigById = async (shiftConfigId: string) => {
  return apiGet(`ShiftConfig/Id/${shiftConfigId}`);
};

export const saveShiftConfig = async (data: IApiData) => {
  return apiPost('ShiftConfig/Save', data);
};

export const getShiftConfigTemplates = async () => {
  return apiGet('ShiftConfig/Templates');
};

export const duplicateShiftConfig = async (data: IApiData) => {
  return apiPost('ShiftConfig/Duplicate', data);
};

export const deleteShiftConfig = async (shiftConfigId: string) => {
  return apiPost(`ShiftConfig/Delete/${shiftConfigId}`, null);
};

export const updateShiftConfigStatus = async (
  shiftConfigId: string,
  newStatus: string
) => {
  return apiPost(
    `ShiftConfig/${shiftConfigId}/ChangeStatus/${newStatus}`,
    null
  );
};

export const getWorkerAdminConfig = async () => {
  return apiGet(`Agency/WorkerAdminConfig`);
};

export const rejectWorkerAgencyLink = async (data: IApiData) => {
  return apiPost('Worker/RejectWorkerAgencyLink', data);
};

export const saveWorkerAgencyLink = async (data: IApiData) => {
  return apiPost('Worker/SaveWorkerAgencyLink', data);
};

export const getWorkersActiveForAgency = async () => {
  return apiGet(`Agency/WorkersActive`);
};

export const getWorkersPendingForAgency = async () => {
  return apiGet(`Agency/WorkersPending`);
};

export const getWorkersRejectedforAgency = async () => {
  return apiGet(`Agency/WorkersRejected`);
};

export const searchWorkers = async (internalRefToSearchFor: string) => {
  return apiGet(`Agency/WorkerSearch/${internalRefToSearchFor}`);
};

export const sendAddWorkerRequest = async (workerInternalReference: string) => {
  return apiPost(
    `Agency/SendAddWorkerRequest/${workerInternalReference}`,
    null
  );
};

export const getSiteAssessmentAddNewConfig = async () => {
  return apiGet(`SiteAssessment/AddNewConfig`);
};

export const saveSiteAssessment = async (data: IApiData) => {
  return apiPost('SiteAssessment/Save', data);
};

export const deleteSiteAssessment = async (siteAssessmentId: string) => {
  return apiPost(`SiteAssessment/Delete/${siteAssessmentId}`, null);
};

export const getDriverEndorsements = async (driverId: string) => {
  return apiGet(`Driver/Endorsements/${driverId}`);
};

export const getWorkerFiles = async (workerId: string) => {
  return apiGet(`Worker/Files/${workerId}`);
};

export const getSiteAssessments = async (workerId: string) => {
  return apiGet(`Worker/SiteAssessments/${workerId}`);
};

export const getWorkerProfile = async (workerId: string) => {
  return apiGet(`Worker/GetProfile/${workerId}`);
};

export const getHirersPending = async () => {
  return apiGet(`Hirer/Pending`);
};

export const getHirersActive = async () => {
  return apiGet<IGetHirersResponse>(`Hirer/Active`);
};

export const getHirerProfile = async () => {
  return apiGet(`Hirer/ProfileGet`);
};

export const getHirerById = async (hirerId: string) => {
  return apiGet(`Hirer/Id/${hirerId}`);
};

export const saveHirer = async (data: IApiData) => {
  return apiPost('Hirer/Save', data);
};

export const inviteHirer = async (data: IApiData) => {
  return apiPost('Hirer/Invite', data);
};

export const deleteHirer = async (data: IApiData) => {
  return apiPost('Hirer/Delete', data);
};

export const getHirerUsers = async (hirerId?: string) => {
  return apiGet(`Hirer/Users/${hirerId ? `?hirerId=${hirerId}` : ``}`);
};

export const getHirerEditConfig = async () => {
  return apiGet(`Hirer/GetEditConfig`);
};

export const changePassword = async (data: IApiData) => {
  return apiPost('Account/ChangePassword', data);
};

export const addShiftFeedback = async (data: IApiData) => {
  return apiPost('Shift/AddFeedback', data);
};

export const getAgencyInvites = async () => {
  return apiGet(`Hirer/AgencyInvites`);
};

export const rejectAgencyInvite = async (data: IApiData) => {
  return apiPost('Hirer/AgencyInviteReject', data);
};

export const approveAgencyInvite = async (data: IApiData) => {
  return apiPost('Hirer/AgencyInviteApprove', data);
};

export const getHirerUserInviteById = async (hirerUserInviteId: string) => {
  return apiGet(`Account/GetHirerUserInvite/${hirerUserInviteId}`);
};

export const reviewHirerUserInvite = async (data: IApiData) => {
  return apiPost('Account/HirerUserInviteReview', data);
};

export const getAgencyAdminConfig = async () => {
  return apiGet('Admin/AgencyAdminConfig');
};

export const getUserAdminConfig = async () => {
  return apiGet('Admin/UserAdminConfig');
};

export const getUsersByType = async (userType: string) => {
  return apiGet(`Admin/Users?type=${userType}`);
};

export const saveUser = async (data: IApiData) => {
  return apiPost('Admin/UserSave', data);
};

export const getAgencies = async () => {
  return apiGet('Admin/Agencies');
};

export const adminSaveAgency = async (data: IApiData) => {
  return apiPost('Admin/AgencySave', data);
};

export const getProfileImage = async (id: string) => {
  return apiRequestRaw(`Driver/GetProfileImage/${id}`);
};

export const getWorkerFile = async (fileId: string) => {
  return apiRequestRaw(`Driver/DownloadFile/${fileId}`);
};

export const getApprovedShiftsCsv = async (
  dateFrom: string,
  dateTo: string
) => {
  return apiRequestRaw(
    `Shift/DownloadApprovedShifts?DateFrom=${dateFrom}&DateTo=${dateTo}`
  );
};

export const getExperienceRequirements = async (shiftConfigId: string) => {
  return apiGet(`ShiftConfig/${shiftConfigId}/ExperienceRequirements`);
};

export const saveExperienceRequirement = async (data: IApiData) => {
  return apiPost('ShiftConfig/SaveExperienceRequirement', data);
};

export const deleteExperienceRequirement = async (
  experienceRequirementId: string
) => {
  return apiPost(
    `ShiftConfig/DeleteExperienceRequirement/${experienceRequirementId}`,
    null
  );
};

export const uploadAppLogo = async (formData: FormData) => {
  return apiPostWithFormData('Agency/UploadLogo', formData);
};

export const resetAppLogo = async () => {
  return apiPost(`Agency/ResetAppLogo`, null);
};

export const getWorkerFileUiConfig = async (workerId: string) => {
  return apiGet(`Worker/FileUiConfig/${workerId}`);
};

export const uploadWorkerFile = async (formData: FormData) => {
  return apiPostWithFormData('Worker/UploadFile', formData);
};
