import { ReactNode, useState } from 'react';
import { Button, Modal, Row, Col, FormGroup } from 'react-bootstrap';
import { FormLabel } from 'components';
import {
  useCalculateDistanceFromRdcQuery,
  useCreateLocationMutation,
  useEditLocationMutation,
} from 'hooks/queries/locationQueries';
import $ from 'jquery';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { ICustomEvent } from 'types/GeneralTypes';
import {
  CreateLocationParams,
  Location,
  UpdateLocationParams,
} from 'types/LocationTypes';
import { nameof } from 'utils';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { Label } from 'components/Label';
import { Stack } from 'components/Stack';
import { useHirerContext } from '../HirerEditContext';
import { LocationUtils } from './LocationUtils';

const getName = (fieldName: keyof Location) => nameof<Location>(fieldName);

export const LocationModal = (props: {
  showModal: boolean;
  handleCloseModalClick: () => void;
  record?: Location;
}) => {
  const record = props.record ?? ({} as Location);
  const { hirerId } = useHirerContext();
  const [postcode, setPostcode] = useState(record.postcode ?? '');
  const [searchForPostcode, setSearchForPostcode] = useState('');
  const createLocation = useCreateLocationMutation();
  const editLocation = useEditLocationMutation();

  const handleSubmitClick = () => {
    const handleSuccess = () => {
      AppUtils.displaySuccess('Done', 'Location saved');
      props.handleCloseModalClick();
      PubSub.publish(LocationUtils.Events.LocationSaved);
    };

    const $modal = $('.modal');
    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    const model = AppUtils.getDataModel($modal) as Location;

    if (record?.locationId) {
      const updateModel: UpdateLocationParams = {
        ...model,
        locationId: record.locationId,
        hirerId,
      };

      editLocation.mutate(updateModel, {
        onSuccess: () => {
          handleSuccess();
        },
      });
    } else {
      const createModel: CreateLocationParams = {
        ...model,
        hirerId,
      };
      createLocation.mutate(createModel, {
        onSuccess: () => {
          handleSuccess();
        },
      });
    }
  };

  const getDistanceQuery = useCalculateDistanceFromRdcQuery({
    hirerId,
    postcode,
  });

  const handleCalculateClick = () => {
    if (postcode) {
      setSearchForPostcode(postcode);
      getDistanceQuery.refetch();
    }
  };

  const handleYesClick = () => {
    const distance = getDistanceQuery.data?.distance ?? '';
    $('#distanceMiles').val(distance);
    setSearchForPostcode('');
  };

  const handleNoClick = () => {
    setSearchForPostcode('');
  };

  const isNew = !props.record;
  const isLoading = editLocation.isLoading || createLocation.isLoading;
  const error = editLocation.error || createLocation.error;
  const isError = !!error;

  return (
    <Modal
      backdrop='static'
      show={props.showModal}
      onHide={props.handleCloseModalClick}
    >
      <Modal.Header closeButton>
        <Modal.Title>{isNew ? 'Create' : 'Edit'} Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Field.InCol
            columns={12}
            name={getName('name')}
            label='Name'
            value={record.name}
            required
          />
        </Row>
        <Row>
          <Field.InCol
            columns={12}
            name={getName('postcode')}
            label='Postcode'
            value={postcode}
            isControlled
            onChange={(e: ICustomEvent) => setPostcode(e.target.value)}
            required
          />
        </Row>
        <Row>
          <Field.InCol
            columns={9}
            name={getName('distanceMiles')}
            label={'Distance'}
            value={AppUtils.formatNumber(record['distanceMiles'])}
            maxLength='9'
            isNumber
            addOnPost={'Miles'}
            required
          />
          <Col sm={3}>
            <FormGroup>
              <FormLabel label='&nbsp;' style={{ display: 'block' }} />
              <Button
                block
                bsStyle='primary'
                bsSize='sm'
                onClick={handleCalculateClick}
                disabled={getDistanceQuery.isFetching || !postcode}
              >
                <Icon icon='fa-map' isSpinning={getDistanceQuery.isFetching} />{' '}
                Calculate
              </Button>
            </FormGroup>
          </Col>

          {searchForPostcode &&
          getDistanceQuery.isSuccess &&
          !getDistanceQuery.isFetching ? (
            <Col sm={12}>
              <WellSmall>
                <p style={{ lineHeight: '2em' }}>
                  The distance between the RDC postcode{' '}
                  <mark>{getDistanceQuery.data.startingPostcode}</mark> and the
                  location postcode{' '}
                  <mark>{getDistanceQuery.data.destinationPostcode}</mark> is{' '}
                  <Label bsStyle='info'>
                    {getDistanceQuery.data.distance} miles
                  </Label>
                </p>

                <p>Would you like to use this value? </p>
                <Stack direction='row' gap={8}>
                  <Button bsStyle='danger' bsSize='sm' onClick={handleNoClick}>
                    No, Cancel
                  </Button>
                  <Button
                    bsStyle='primary'
                    bsSize='sm'
                    onClick={handleYesClick}
                  >
                    Yes, use this value
                  </Button>
                </Stack>
              </WellSmall>
            </Col>
          ) : null}
        </Row>

        <ErrorAlertForQueryOrNull
          error={getDistanceQuery.error}
          isError={getDistanceQuery.isError}
        />

        <ErrorAlertForQueryOrNull error={error} isError={isError} />
      </Modal.Body>
      <FooterMain
        handleSubmitClick={handleSubmitClick}
        closeButton={
          <Button onClick={props.handleCloseModalClick}>
            <Icon icon='fa-times' /> Cancel
          </Button>
        }
        isNew={isNew}
        isLoading={isLoading}
      />
    </Modal>
  );
};

type SaveButtonProps = {
  isNew: boolean;
  isLoading: boolean;
  handleSubmitClick: () => void;
};

type FooterMainProps = {
  closeButton: ReactNode;
} & SaveButtonProps;

const FooterMain = (props: FooterMainProps) => {
  const { closeButton, ...rest } = props;

  return (
    <Modal.Footer>
      <Row>
        <Col sm={12}>
          {props.closeButton}
          <SaveButton {...rest} />
        </Col>
      </Row>
    </Modal.Footer>
  );
};

const SaveButton = (props: SaveButtonProps) => {
  const buttonTitle = `${props.isNew ? 'Creat' : 'Sav'}${
    props.isLoading ? 'ing...' : 'e Location'
  }`;
  return (
    <Button
      bsStyle='success'
      onClick={props.handleSubmitClick}
      disabled={props.isLoading}
    >
      <Icon icon='fa-check' isSpinning={props.isLoading} /> {buttonTitle}
    </Button>
  );
};
