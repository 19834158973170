import { AppUtils } from 'pages/Utils/app-utils';
import qs from 'qs';
import auth0 from './auth0';
import { getApiBaseUrl, isObject } from '.';

export enum ApiStatus {
  Success = 'success',
  Fail = 'fail',
}

export interface IApiData {
  [key: string]: unknown;
}

export interface IApiResponse {
  status: ApiStatus;
  messages: string[] | null;
}

export async function apiRequestRaw(path: string, method = 'GET') {
  const accessToken = auth0.extended.getAccessToken();
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/${path}`, {
    method: method,
    headers: {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    },
  });
}

export async function apiPostWithFormData(path: string, formData: FormData) {
  const accessToken = auth0.extended.getAccessToken();
  return fetch(`${getApiBaseUrl()}/${path}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: formData,
  })
    .then(handleResponse)
    .catch((error) => {
      console.log({ error });
    });
}

export function apiGet<ReturnType>(path: string, data?: IApiData) {
  let finalPath = path;
  if (isObject(data)) {
    finalPath += '?' + qs.stringify(data);
  }
  return apiRequest<ReturnType>(finalPath, 'GET');
}

export async function apiPost(path: string, data: unknown) {
  return apiRequest(path, 'POST', data);
}

export async function apiRequest<ReturnType>(
  path: string,
  method = 'GET',
  requestData?: unknown
) {
  try {
    const accessToken = auth0.extended.getAccessToken();
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/${path}`,
      {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        },
        body: requestData ? JSON.stringify(requestData) : undefined,
      }
    );

    return handleResponse<ReturnType>(response);
  } catch (error) {
    console.log({ error });
  }
}

async function handleResponse<T>(response: Response) {
  if ([401, 403].includes(response.status)) {
    auth0.extended.logout();
    throw new Error('Unauthorized');
  }

  if ([404].includes(response.status)) {
    throw new Error('Not found');
    // const messages = [`Could not find API endpoint: ${response.url}`];
    // AppUtils.displayErrors(messages);
    // return { status: ApiStatus.Fail, messages };
  }

  const json = await getResponseAsJson<T>(response);
  if (!response.ok) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const jsonAsAny = json as any;
    if (jsonAsAny?.messages) {
      AppUtils.displayErrors(jsonAsAny?.messages);
    }
    if (jsonAsAny?.Messages) {
      AppUtils.displayErrors(jsonAsAny?.Messages);
    }
  }

  return json as T;
}

const getResponseAsJson = async <T>(response: Response): Promise<T> => {
  return (await response.json()) as T;
};

// Create an Error with custom message and code
export function buildCustomError(code: string, message: string) {
  const error = new CustomError(message);
  error.code = code;
  return error;
}

class CustomError extends Error {
  code: string;
}
