import { Alert, Col, Panel, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useDownloadInvoiceQuery } from 'hooks/queries';
import { AppUtils } from 'pages/Utils/app-utils';
import { InvoiceStatus } from 'types/Invoice';
import { usePermissions, userPermissions } from 'utils/userPermissions';
import { Button } from 'components/Button';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { GettingRecordsMessage } from 'components/GettingRecordsMessage';
import { Stack } from 'components/Stack';
import { UnauthorisedAlert } from 'components/UnauthorisedAlert';
import { StatusChip } from '../shared';
import { useApproveDialog } from './ApproveDialog';
import { useDeleteDialog } from './DeleteDialog';
import { InvoiceContextProvider, useInvoiceContext } from './InvoiceContext';
import { InvoiceMain } from './InvoiceMain';
import { useMarkAsPaidDialog } from './MarkAsPaidDialog';
import { useRejectDialog } from './RejectDialog';
import { useRequestApprovalDialog } from './RequestApprovalDialog';

export const InvoiceSingle = () => {
  const { hasPermission } = usePermissions();
  const isAllowed = hasPermission(userPermissions.shift.finance.view);
  if (!isAllowed) {
    return <UnauthorisedAlert />;
  }

  return <SingleMain />;
};

const SingleMain = () => {
  return (
    <InvoiceContextProvider>
      <Content />
    </InvoiceContextProvider>
  );
};

export const Content = () => {
  const { invoice, query } = useInvoiceContext();

  if (query.isLoading) {
    return <GettingRecordsMessage message={'Getting invoice details...'} />;
  }

  if (query.isError) {
    return (
      <ErrorAlertForQueryOrNull error={query.error} isError={query.isError} />
    );
  }

  if (query.isSuccess && invoice) {
    return <InvoiceContent />;
  }
};

const InvoiceContent = () => {
  const history = useHistory();

  const { invoice, invoiceId, isEditable } = useInvoiceContext();

  const { hasPermission } = usePermissions();
  const isApprover = hasPermission(userPermissions.invoice.approve);

  const downloadQuery = useDownloadInvoiceQuery({ invoiceId });
  const handleDownloadClick = () => {
    downloadQuery.refetch();
  };

  return (
    <>
      <Button onClick={() => history.goBack()}>&laquo; Back</Button>
      <Panel className='m-t'>
        <Panel.Heading>
          <Row>
            <Col sm={6}>
              <Panel.Title>Invoice: {invoice.invoiceReference}</Panel.Title>
              <StatusChip status={invoice.status} />
            </Col>
            <Col sm={6} className='text-right'>
              <Stack
                direction='row'
                gap={10}
                style={{ justifyContent: 'flex-end' }}
              >
                {isEditable ? (
                  <>
                    <DeleteButtonAndDialog />
                  </>
                ) : null}

                <Button
                  icon='fa-download'
                  isLoading={downloadQuery.isFetching}
                  onClick={handleDownloadClick}
                >
                  Download
                </Button>

                {isEditable ? (
                  <>
                    <RequestApprovalButtonAndDialog />
                  </>
                ) : null}

                {invoice.status === InvoiceStatus.AwaitingApproval &&
                isApprover ? (
                  <>
                    <ApproveButtonAndDialog />
                  </>
                ) : null}

                {invoice.status === InvoiceStatus.AwaitingApproval &&
                isApprover ? (
                  <>
                    <RejectButtonAndDialog />
                  </>
                ) : null}

                {invoice.status === InvoiceStatus.Approved && isApprover ? (
                  <>
                    <MarkAsPaidButtonAndDialog />
                  </>
                ) : null}
              </Stack>
            </Col>
          </Row>
        </Panel.Heading>
        <Panel.Body>
          {invoice.approvedDateTime ? (
            <Alert bsStyle='success'>
              This invoice was approved by {invoice.approvedBy} on{' '}
              {AppUtils.formatDateTimeUtc(invoice.approvedDateTime)}.
            </Alert>
          ) : null}

          {invoice.status === InvoiceStatus.Rejected ? (
            <Alert bsStyle='danger'>
              This invoice was rejected by {invoice.rejectedBy} on{' '}
              {AppUtils.formatDateTimeUtc(invoice.rejectedDateTime)}.
            </Alert>
          ) : null}

          {invoice.paidDateTime ? (
            <Alert bsStyle='info'>
              This invoice was marked as paid by {invoice.paidBy} on{' '}
              {AppUtils.formatDateTimeUtc(invoice.paidDateTime)}.
            </Alert>
          ) : null}

          <InvoiceMain />
        </Panel.Body>
      </Panel>
    </>
  );
};

const DeleteButtonAndDialog = () => {
  const { invoice } = useInvoiceContext();
  const { renderDeleteDialog, showDeleteDialog } = useDeleteDialog();
  return (
    <>
      {renderDeleteDialog()}
      <Button
        bsStyle='danger'
        icon='fa-trash'
        onClick={() => showDeleteDialog(invoice)}
      >
        Delete
      </Button>
    </>
  );
};

const RequestApprovalButtonAndDialog = () => {
  const { invoice } = useInvoiceContext();
  const { renderRequestApprovalDialog, showRequestApprovalDialog } =
    useRequestApprovalDialog();
  return (
    <>
      {renderRequestApprovalDialog()}
      <Button
        bsStyle='success'
        icon='fa-envelope'
        onClick={() => showRequestApprovalDialog(invoice)}
      >
        Request Approval
      </Button>
    </>
  );
};

const ApproveButtonAndDialog = () => {
  const { invoice } = useInvoiceContext();
  const { renderApproveDialog, showApproveDialog } = useApproveDialog();
  return (
    <>
      {renderApproveDialog()}
      <Button
        bsStyle='success'
        icon='fa-check'
        onClick={() => showApproveDialog(invoice)}
      >
        Approve
      </Button>
    </>
  );
};

const RejectButtonAndDialog = () => {
  const { invoice } = useInvoiceContext();
  const { renderRejectDialog, showRejectDialog } = useRejectDialog();
  return (
    <>
      {renderRejectDialog()}
      <Button
        bsStyle='danger'
        icon='fa-times'
        onClick={() => showRejectDialog(invoice)}
      >
        Reject
      </Button>
    </>
  );
};

const MarkAsPaidButtonAndDialog = () => {
  const { invoice } = useInvoiceContext();
  const { renderMarkAsPaidDialog, showMarkAsPaidDialog } =
    useMarkAsPaidDialog();
  return (
    <>
      {renderMarkAsPaidDialog()}
      <Button
        bsStyle='success'
        icon='fa-check'
        onClick={() => showMarkAsPaidDialog(invoice)}
      >
        Mark As Paid
      </Button>
    </>
  );
};
