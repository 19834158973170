import { useMemo } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import {
  useRemovePermissionFromUserMutation,
  useUserPermissionsQuery,
} from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon } from 'pages/Utils/react-utils';
import { extractErrorMessages } from 'utils';
import {
  getPermissionDisplayName,
  usePermissions,
  userPermissions,
} from 'utils/userPermissions';
import { ErrorAlert } from 'components/ErrorAlert';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { Table } from 'components/Table';

export const PermissionsTable = ({
  userAccountId,
}: {
  userAccountId: string;
}) => {
  const { hasPermission } = usePermissions();
  const canAddPermissions = hasPermission(userPermissions.agency.user.manage);

  const { data, isError, error, isSuccess, isLoading } =
    useUserPermissionsQuery(userAccountId);

  const queryClient = useQueryClient();

  const {
    mutate: removePermission,
    isError: isErrorRemoving,
    error: errorRemoving,
    isLoading: isRemoving,
  } = useRemovePermissionFromUserMutation();

  const handleDeleteClick = (permissionId: string) => () => {
    removePermission(
      { userAccountId, permissionId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.UserPermissions, userAccountId],
          });

          queryClient.invalidateQueries({
            queryKey: [QueryKeys.AgencyUsers],
          });

          AppUtils.displaySuccess('Success', 'Permission deleted');
        },
      }
    );
  };

  const columns = [
    {
      Header: 'Permission',
      accessor: 'name',
      Cell: ({ value }: { value: string }) => {
        return <>{getPermissionDisplayName(value)}</>;
      },
      width: '35%',
    },
    {
      Header: 'Description',
      accessor: 'description',
      width: '50%',
    },
    canAddPermissions
      ? {
          id: 'btnContainer',
          accessor: 'permissionId',
          Cell: ({ value }: { value: string }) => {
            return (
              <Button
                bsSize='xs'
                bsStyle='danger'
                onClick={handleDeleteClick(value)}
                disabled={isRemoving}
              >
                <Icon icon='fa-trash' />
              </Button>
            );
          },
          className: 'text-right',
        }
      : undefined,
  ].filter(Boolean);

  const records = useMemo(() => data ?? [], [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <ErrorAlert msg={extractErrorMessages(error)} className='m-b-sm' />;
  }

  if (isSuccess && records.length > 0) {
    return (
      <>
        <ErrorAlertForQueryOrNull
          isError={isErrorRemoving}
          error={errorRemoving}
        />

        <Table columns={columns} records={records} />
      </>
    );
  }

  if (isSuccess && records.length <= 0) {
    return <Alert>No permissions found.</Alert>;
  }

  return null;
};
