import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useGetLocationsByHirerQuery } from 'hooks/queries/locationQueries';
import { useModal } from 'hooks/useModal';
import useSubscription from 'hooks/useSubscription';
import { WellSmall } from 'pages/Utils/react-utils';
import { Location } from 'types/LocationTypes';
import { useAlertWithRefreshButton } from 'components/AlertWithRefreshButton';
import DataTable from 'components/DataTable';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { NoRecordsFoundMessage } from 'components/NoRecordsFoundMessage';
import { useHirerContext } from '../HirerEditContext';
import { LocationModal, LocationUtils, CreateNewLocationButton } from './';

export const LocationsTab = () => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <CreateNewLocationButton />
        <LocationsListView />
      </div>
    </>
  );
};

const tableId = 'locations-table';

const useEditLocationModal = () => {
  const [locationToEdit, setLocationToEdit] = useState<Location | null>(null);
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const handleShowModalClick = (location: Location) => {
    setLocationToEdit(location);
    handleModalOpen();
  };

  const handleCloseModalClick = () => {
    handleModalClose();
  };

  return {
    handleShowModalClick,
    handleCloseModalClick,
    isModalOpen,
    locationToEdit,
  };
};

export const LocationsListView = () => {
  const { hirerId } = useHirerContext();
  const { data, isLoading, isFetching, refetch, error, isError } =
    useGetLocationsByHirerQuery({ hirerId });
  const records = data || [];

  useSubscription([LocationUtils.Events.LocationSaved], refetch);

  const locationModal = useEditLocationModal();

  const handleEditClick = (location: Location) => {
    locationModal.handleShowModalClick(location);
  };

  const { alert, refreshButton } = useAlertWithRefreshButton(
    'List of your registered locations',
    isLoading,
    isFetching,
    refetch
  );

  if (isError) {
    return <ErrorAlertForQueryOrNull isError={isError} error={error} />;
  }

  if (isLoading || (isFetching && !data)) {
    return <WellSmall hasSpinner>Loading...</WellSmall>;
  }

  if (records.length <= 0) {
    return (
      <NoRecordsFoundMessage
        records={records}
        message='No locations found'
        refreshButton={refreshButton}
      />
    );
  }

  return (
    <>
      {alert}
      <DataTable
        records={records}
        tableId={tableId}
        render={() => {
          return (
            <table
              className='table table-condensed table-striped table-hover'
              id={tableId}
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Distance</th>
                  <th>Postcode</th>
                  <th className='no-sort' />
                </tr>
              </thead>
              <tbody>
                {records.map((item, i) => (
                  <Row
                    key={i}
                    record={item}
                    handleEditClick={handleEditClick}
                  />
                ))}
              </tbody>
            </table>
          );
        }}
      />
      {locationModal.locationToEdit && locationModal.isModalOpen ? (
        <LocationModal
          handleCloseModalClick={locationModal.handleCloseModalClick}
          showModal={locationModal.isModalOpen}
          record={locationModal.locationToEdit}
        />
      ) : null}
    </>
  );
};

const Row = ({
  record,
  handleEditClick,
}: {
  record: Location;
  handleEditClick: (record: Location) => void;
}) => {
  return (
    <tr>
      <td>{record.name}</td>
      <td>{record.distanceMiles}</td>
      <td>{record.postcode}</td>
      <td className='pull-right'>
        <Button
          bsStyle='primary'
          bsSize='xs'
          onClick={() => handleEditClick(record)}
        >
          Edit
        </Button>
      </td>
    </tr>
  );
};
