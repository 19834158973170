import { ReactNode, useState } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useModal } from 'hooks/useModal';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { WellSmall, Icon, AlertWithIcon } from 'pages/Utils/react-utils';
import { ShiftPlanItem, ShiftPlanItemShift } from 'types/ShiftPlanningTypes';
import { ViewPaymentAdjustments } from 'components/PaymentAdjustments';
import { ModalContent } from 'components/Planner/ModalContent';
import {
  ShiftMenuModalContextProvider,
  useShiftMenuModalContext,
} from 'components/Planner/ShiftMenuModalContext';
import { UiStateViewNotes } from 'components/Planner/ViewNotes';
import {
  EShiftModalMenuUi,
  ShiftMenuModalProps,
} from 'components/Planner/shiftMenuModalTypes';
import { isDataRequired } from '../vehiclePlannerUtils';
import { CancelShiftModalContent } from './CancelShift';
import { CompleteShiftModalContent } from './CompleteShift';
import { LinkShiftModalContent } from './LinkShift';
import { ViewShiftSegments } from './ShiftSegments';
import { ShiftSummary } from './ShiftSummary';
import { UpdateTimeModalContent } from './UpdateShiftTime';
import { UpdateTrailerModalContent } from './UpdateShiftTrailer';
import { UpdateVehicleModalContent } from './UpdateShiftVehicle';
import { UpdateWorkerModalContent } from './UpdateShiftWorker';
import { ShiftSummaryRow } from './common';

export const useShiftMenuModal = () => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();

  const [shiftToHandle, setShiftToHandle] = useState<ShiftPlanItemShift | null>(
    null
  );
  const [shiftPlanItemToHandle, setShiftPlaItemToHandle] =
    useState<ShiftPlanItem | null>(null);

  const handleShiftMenuModalOpen = ({
    shift,
    shiftPlanItem,
  }: {
    shift: ShiftPlanItemShift;
    shiftPlanItem: ShiftPlanItem;
  }) => {
    setShiftToHandle(shift);
    setShiftPlaItemToHandle(shiftPlanItem);
    handleModalOpen();
  };

  const handleShiftMenuModalClose = () => {
    setShiftToHandle(null);
    setShiftPlaItemToHandle(null);
    handleModalClose();
  };

  const renderShiftMenuModal = () => {
    if (!isModalOpen || !shiftToHandle) {
      return null;
    }

    return (
      <ShiftMenuModal
        isModalOpen={isModalOpen}
        handleModalClose={handleShiftMenuModalClose}
        shift={shiftToHandle}
        shiftPlanItem={shiftPlanItemToHandle}
      />
    );
  };

  return {
    handleShiftMenuModalOpen,
    renderShiftMenuModal,
  };
};

export const ShiftMenuModal = (props: ShiftMenuModalProps) => {
  return (
    <ShiftMenuModalContextProvider {...props}>
      <ShiftMenuModalMain {...props} />
    </ShiftMenuModalContextProvider>
  );
};

const ShiftMenuModalMain = ({
  isModalOpen,
  handleModalClose,
}: ShiftMenuModalProps) => {
  const { uiState, shiftToHandle } = useShiftMenuModalContext();

  return (
    <Modal
      backdrop='static'
      show={isModalOpen}
      onHide={handleModalClose}
      bsSize={shiftToHandle.isPartOfShiftGroup ? 'large' : undefined}
    >
      <>
        {uiState === EShiftModalMenuUi.Default ? <UiStateDefault /> : null}

        {uiState === EShiftModalMenuUi.ViewNotes ? <UiStateViewNotes /> : null}

        {uiState === EShiftModalMenuUi.LinkShift ? (
          <LinkShiftModalContent />
        ) : null}

        {uiState === EShiftModalMenuUi.ViewPaymentAdjustments ? (
          <ViewPaymentAdjustments />
        ) : null}

        {uiState === EShiftModalMenuUi.ViewShiftSegments ? (
          <ViewShiftSegments />
        ) : null}

        {uiState === EShiftModalMenuUi.UpdateTime ? (
          <UpdateTimeModalContent />
        ) : null}

        {uiState === EShiftModalMenuUi.UpdateWorker ? (
          <UpdateWorkerModalContent />
        ) : null}

        {uiState === EShiftModalMenuUi.UpdateVehicle ? (
          <UpdateVehicleModalContent />
        ) : null}

        {uiState === EShiftModalMenuUi.UpdateTrailer ? (
          <UpdateTrailerModalContent />
        ) : null}

        {uiState === EShiftModalMenuUi.CancelShift ? (
          <CancelShiftModalContent />
        ) : null}

        {uiState === EShiftModalMenuUi.CompleteShift ? (
          <CompleteShiftModalContent />
        ) : null}
      </>
    </Modal>
  );
};

const UiStateDefault = () => {
  const { shiftToHandle, shiftPlanItemToHandle, workerType } =
    useShiftMenuModalContext();
  const history = useHistory();

  return (
    <ModalContent
      title='Shift'
      Body={
        <>
          <WellSmall>
            <ShiftSummary
              shiftToHandle={shiftToHandle}
              shiftPlanItemToHandle={shiftPlanItemToHandle}
            />

            {shiftToHandle.status !== ShiftUtils.Status.Planned ? (
              <ShiftSummaryRow
                label=''
                value={
                  <Button
                    bsStyle='primary'
                    bsSize='xs'
                    onClick={() => {
                      history.push(`/Shift/Edit/${shiftToHandle.shiftId}`);
                    }}
                  >
                    Open Shift Record <i className='fa fa-arrow-right' />
                  </Button>
                }
                className='m-t'
              />
            ) : null}
          </WellSmall>

          {shiftToHandle.status === ShiftUtils.Status.Allocated &&
          isDataRequired(shiftToHandle) ? (
            <>
              <AlertWithIcon bsStyle='danger' icon='fa-exclamation-triangle'>
                <p>
                  <strong>Warning:</strong> This shift requires a{' '}
                  {[
                    !shiftToHandle.vehicleId ? 'vehicle' : '',
                    !shiftToHandle.isShiftTimeConfirmed ? 'time' : '',
                  ]
                    .filter(Boolean)
                    .join(' and ')}{' '}
                  to be added.
                </p>
              </AlertWithIcon>
              <hr />
            </>
          ) : null}

          <ModalActionRow
            col1={
              <MenuButton
                icon={'fa-comments'}
                label={'Notes'}
                state={EShiftModalMenuUi.ViewNotes}
              />
            }
            col2={'Add or view notes for this shift.'}
          />

          <hr />

          {[
            ShiftUtils.Status.Allocated,
            ShiftUtils.Status.InProgress,
            ShiftUtils.Status.Complete,
          ].includes(shiftToHandle.status) ? (
            <>
              <ModalActionRow
                col1={
                  <MenuButton
                    icon={'fa-link'}
                    label={'Link Shift'}
                    state={EShiftModalMenuUi.LinkShift}
                  />
                }
                col2={'Link this shift to another shift.'}
              />

              <hr />
            </>
          ) : null}

          {[
            ShiftUtils.Status.Allocated,
            ShiftUtils.Status.InProgress,
            ShiftUtils.Status.Complete,
            ShiftUtils.Status.Approved,
          ].includes(shiftToHandle.status) ? (
            <>
              <ModalActionRow
                col1={
                  <MenuButton
                    icon={'fa-money-bill'}
                    label={'Payment Adjustments'}
                    state={EShiftModalMenuUi.ViewPaymentAdjustments}
                  />
                }
                col2={'Add or view payment adjustments for this shift.'}
              />

              <hr />

              <ModalActionRow
                col1={
                  <MenuButton
                    icon={'fa-map-marker'}
                    label={'Shift Locations'}
                    state={EShiftModalMenuUi.ViewShiftSegments}
                  />
                }
                col2={'Add or view shift locations.'}
              />

              <hr />
            </>
          ) : null}

          {[
            ShiftUtils.Status.PreAllocated,
            ShiftUtils.Status.Allocated,
          ].includes(shiftToHandle.status) ? (
            <>
              <ModalActionRow
                col1={
                  <MenuButton
                    icon={'fa-clock'}
                    label={'Add/Change Shift Time'}
                    state={EShiftModalMenuUi.UpdateTime}
                  />
                }
                col2={'Update the start time for this shift.'}
              />

              <hr />
            </>
          ) : null}

          {[
            ShiftUtils.Status.PreAllocated,
            ShiftUtils.Status.Allocated,
          ].includes(shiftToHandle.status) ? (
            <>
              <ModalActionRow
                col1={
                  <MenuButton
                    icon={'fa-truck'}
                    label={`Add/Change Trailer`}
                    state={EShiftModalMenuUi.UpdateTrailer}
                  />
                }
                col2={`Change the Trailer for this shift.`}
              />

              <hr />
            </>
          ) : null}

          {[
            ShiftUtils.Status.PreAllocated,
            ShiftUtils.Status.Allocated,
            ShiftUtils.Status.Rejected,
          ].includes(shiftToHandle.status) ? (
            <>
              <ModalActionRow
                col1={
                  <MenuButton
                    icon={'fa-user'}
                    label={`Add/Change ${workerType}`}
                    state={EShiftModalMenuUi.UpdateWorker}
                  />
                }
                col2={`Change the ${workerType} for this shift.`}
              />

              <hr />
            </>
          ) : null}

          {[
            ShiftUtils.Status.PreAllocated,
            ShiftUtils.Status.Allocated,
            ShiftUtils.Status.Complete,
          ].includes(shiftToHandle.status) ? (
            <>
              <ModalActionRow
                col1={
                  <MenuButton
                    icon={'fa-truck'}
                    label={'Add/Change Vehicle'}
                    state={EShiftModalMenuUi.UpdateVehicle}
                  />
                }
                col2={'Update the vehicle for this shift.'}
              />

              <hr />

              <ModalActionRow
                col1={
                  <MenuButton
                    icon={'fa-times'}
                    label={`Cancel`}
                    state={EShiftModalMenuUi.CancelShift}
                    bsStyle='danger'
                  />
                }
                col2={'Cancel this shift.'}
              />

              <hr />
            </>
          ) : null}

          {[ShiftUtils.Status.Allocated, ShiftUtils.Status.InProgress].includes(
            shiftToHandle.status
          ) ? (
            <>
              <ModalActionRow
                col1={
                  <MenuButton
                    bsStyle='success'
                    icon={'fa-check'}
                    label={'Complete Shift'}
                    state={EShiftModalMenuUi.CompleteShift}
                  />
                }
                col2={'Complete this shift.'}
              />
              <hr />
            </>
          ) : null}
        </>
      }
      Footer={<UiStateDefaultActions />}
    />
  );
};

const ModalActionRow = ({
  col1,
  col2,
}: {
  col1: ReactNode;
  col2: ReactNode;
}) => {
  return (
    <Row>
      <Col xs={4}>{col1}</Col>
      <Col xs={6}>{col2}</Col>
    </Row>
  );
};

const MenuButton = ({
  icon,
  label,
  state,
  bsStyle = 'primary',
}: {
  icon: string;
  label: string;
  state: EShiftModalMenuUi;
  bsStyle?: string;
}) => {
  const { setUiState } = useShiftMenuModalContext();
  return (
    <Button
      className='btn-block'
      bsStyle={bsStyle}
      bsSize='sm'
      onClick={() => setUiState(state)}
      style={{ textAlign: 'left' }}
    >
      <Icon icon={icon} className='fa-fw' style={{ marginRight: '5px' }} />{' '}
      {label} {'>'}
    </Button>
  );
};

const UiStateDefaultActions = () => {
  const { handleModalClose } = useShiftMenuModalContext();
  return (
    <Button onClick={handleModalClose}>
      <i className='fa fa-times' /> Close
    </Button>
  );
};
