import { useState, useEffect, useMemo, useContext, createContext } from 'react';
import PageLoader from 'components/PageLoader';
import auth0 from './auth0';
import { history } from './router';
import { useUser } from './useUser';

const authContext = createContext();

// Context Provider component that wraps your app and makes auth object
// available to any child component that calls the useAuth() hook.
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook that enables any component to subscribe to auth state
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  // Store auth user object
  const [user, setUser] = useState(null);

  // Format final user object and merge extra data from database
  const finalUser = usePrepareUser(user);

  const signin = (email, password) => {
    return auth0.extended
      .login({
        username: email,
        password: password,
      })
      .then(async (user) => {
        // Update user in state
        setUser(user);
        return user;
      });
  };

  const signout = () => {
    return auth0.extended.logout();
  };

  const sendPasswordResetEmail = (email) => {
    return auth0.extended.changePassword({
      email: email,
    });
  };

  useEffect(() => {
    // Subscribe to user on mount
    const unsubscribe = auth0.extended.onChange(async (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });

    // Unsubscribe on cleanup
    return () => unsubscribe();
  }, []);

  const hasPermission = (permission) => {
    return finalUser?.record?.permissions?.includes(permission) ?? false;
  };

  return {
    user: finalUser,
    signin,
    signout,
    sendPasswordResetEmail,
    hasPermission,
  };
}

// Format final user object and merge extra data from database
function usePrepareUser(user) {
  // Fetch extra data from database (if enabled and auth user has been fetched)
  const serverUserQuery = useUser(user && user.sub);

  // Memoize so we only create a new object if user or userDbQuery changes
  return useMemo(() => {
    // Return if auth user is null (loading) or false (not authenticated)
    if (!user) {
      return user;
    }

    // Data we want to include from auth user object
    let finalUser = {
      uid: user.sub,
      email: user.email,
      emailVerified: user.email_verified,
      name: user.name,
      picture: user.picture,
    };

    // If merging user data from database is enabled ...
    switch (serverUserQuery.status) {
      case 'idle':
        // Return null user until we have server data to merge
        return null;
      case 'loading':
        return null;
      case 'error':
        // Log query error to console
        console.error(serverUserQuery.error);
        return null;
      case 'success':
        // Merge user data from server into finalUser object
        Object.assign(finalUser, serverUserQuery.data);

      // no default
    }

    return finalUser;
  }, [user, serverUserQuery]);
}

// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    // Get authenticated user
    const auth = useAuth();

    useEffect(() => {
      // Redirect if not signed in
      if (auth.user === false) {
        history.replace('/auth/signin');
      }
    }, [auth]);

    // Show loading indicator
    // We're either loading (user is null) or we're about to redirect (user is false)
    if (!auth.user) {
      return <PageLoader />;
    }

    // Render component now that we have user
    return <Component {...props} />;
  };
};
