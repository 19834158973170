import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useGetVehiclesActiveQuery } from 'hooks/queries/vehicleQueries';
import { useModal } from 'hooks/useModal';
import useSubscription from 'hooks/useSubscription';
import { AppUtils } from 'pages/Utils/app-utils';
import { SmallErrorText, SmallMuted, WellSmall } from 'pages/Utils/react-utils';
import { Vehicle } from 'types/VehicleTypes';
import { useAlertWithRefreshButton } from 'components/AlertWithRefreshButton';
import DataTable from 'components/DataTable';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { NoRecordsFoundMessage } from 'components/NoRecordsFoundMessage';
import { VehicleModal } from './VehicleModal';
import { VehicleUtils } from './VehicleUtils';

const tableId = 'vehicles-active-table';

const useEditVehicleModal = () => {
  const [vehicleToEdit, setVehicleToEdit] = useState<Vehicle | null>(null);
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const handleShowModalClick = (vehicle: Vehicle) => {
    setVehicleToEdit(vehicle);
    handleModalOpen();
  };

  const handleCloseModalClick = () => {
    handleModalClose();
  };

  return {
    handleShowModalClick,
    handleCloseModalClick,
    isModalOpen,
    vehicleToEdit,
  };
};

export const VehiclesListViewActive = () => {
  const { data, isLoading, isFetching, refetch, error, isError } =
    useGetVehiclesActiveQuery();
  const records = data || [];

  useSubscription([VehicleUtils.Events.VehicleSaved], refetch);

  const {
    handleCloseModalClick,
    handleShowModalClick,
    isModalOpen,
    vehicleToEdit,
  } = useEditVehicleModal();

  const handleEditClick = (vehicle: Vehicle) => {
    handleShowModalClick(vehicle);
  };

  const { alert, refreshButton } = useAlertWithRefreshButton(
    'List of your registered vehicles',
    isLoading,
    isFetching,
    refetch
  );

  if (isError) {
    return <ErrorAlertForQueryOrNull isError={isError} error={error} />;
  }

  if (isLoading || (isFetching && !data)) {
    return <WellSmall hasSpinner>Loading...</WellSmall>;
  }

  if (records.length <= 0) {
    return (
      <NoRecordsFoundMessage
        records={records}
        message='No vehicles found'
        refreshButton={refreshButton}
      />
    );
  }

  return (
    <>
      {alert}
      <DataTable
        records={records}
        tableId={tableId}
        render={() => {
          return (
            <table
              className='table table-condensed table-striped table-hover'
              id={tableId}
            >
              <thead>
                <tr>
                  <th>Registration</th>
                  <th>Make</th>
                  <th>Model</th>
                  <th>Rental</th>
                  <th>Insurance</th>
                  <th>Wash</th>
                  <th>Tracker</th>
                  <th>Dep</th>
                  <th>Tele</th>
                  <th>Damage</th>
                  <th className='no-sort' />
                </tr>
              </thead>
              <tbody>
                {records.map((item, i) => (
                  <Row
                    key={i}
                    record={item}
                    handleEditClick={handleEditClick}
                  />
                ))}
              </tbody>
            </table>
          );
        }}
      />
      {vehicleToEdit ? (
        <VehicleModal
          handleCloseModalClick={handleCloseModalClick}
          showModal={isModalOpen}
          record={vehicleToEdit}
        />
      ) : null}
    </>
  );
};

const Row = ({
  record,
  handleEditClick,
}: {
  record: Vehicle;
  handleEditClick: (record: Vehicle) => void;
}) => {
  return (
    <tr>
      <td>
        {record.reg}
        {record.isSpotHire ? (
          <>
            <br />
            <SmallMuted>Spot Hire</SmallMuted>
          </>
        ) : null}
        {record.vehicleOffRoadReason ? (
          <>
            <br />
            <SmallErrorText>VOR: {record.vehicleOffRoadReason}</SmallErrorText>
          </>
        ) : null}
      </td>
      <td>{record.make}</td>
      <td>{record.model}</td>
      <td>{AppUtils.getCurrencyVal(record.rentalCostPerDay)}</td>
      <td>{AppUtils.getCurrencyVal(record.insuranceCostPerDay)}</td>
      <td>{AppUtils.getCurrencyVal(record.washAllowancePerDay)}</td>
      <td>{AppUtils.getCurrencyVal(record.trackerCostPerDay)}</td>
      <td>{AppUtils.getCurrencyVal(record.depreciationAllowancePerDay)}</td>
      <td>{AppUtils.getCurrencyVal(record.telematicsCostPerDay)}</td>
      <td>{AppUtils.getCurrencyVal(record.damageAllowancePerDay)}</td>
      <td className='pull-right'>
        <Button
          bsStyle='primary'
          bsSize='xs'
          onClick={() => handleEditClick(record)}
        >
          Edit
        </Button>
      </td>
    </tr>
  );
};
