import { Component } from 'react';
import { Button } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import $ from 'jquery';
import { AppUtils, Constants } from 'pages/Utils/app-utils';
import { ListViewUtils } from 'pages/Utils/list-view-utils';
import { Icon, SmallMuted, WellSmall } from 'pages/Utils/react-utils';
import { getWorkersPendingForAgency } from 'utils/api';
import { ProfileStatus } from '../../Worker/WorkerUtils';

export const WorkerListViewPending = () => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['workersPending'],
    getWorkersPendingForAgency,
    {
      refetchInterval: Constants.DefaultAutoRefetchFrequency,
    }
  );
  const records = data?.records || [];

  if (isLoading) {
    return (
      <WellSmall className='m-b-none' hasSpinner>
        Getting pending worker invitations...
      </WellSmall>
    );
  }

  if (records.length <= 0) {
    return (
      <WellSmall className='m-b-none'>
        No pending worker invitations found.{' '}
        <Button
          bsSize='xs'
          bsStyle='primary'
          onClick={refetch}
          disabled={isLoading || isFetching}
          className='pull-right'
        >
          <Icon icon='fa-redo' isSpinning={isLoading || isFetching} /> Refresh
        </Button>
      </WellSmall>
    );
  }
  return <Table records={records} />;
};

const tableId = 'pending-invitations-table';
class Table extends Component {
  componentDidMount() {
    this.initDataTables();
  }

  shouldComponentUpdate(nextProps) {
    if (
      JSON.stringify(nextProps.records) !== JSON.stringify(this.props.records)
    ) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillUpdate() {
    this.destroyDataTables();
  }

  componentDidUpdate() {
    this.initDataTables();
  }

  componentWillUnmount() {
    this.destroyDataTables();
  }

  initDataTables() {
    if (this.props.records.length > 0) {
      ListViewUtils.init(tableId);
    }
  }

  destroyDataTables() {
    const $table = $('#' + tableId);
    if ($table.length) {
      $table.DataTable().destroy();
    }
  }

  render() {
    return (
      <table className='table table-condensed table-striped' id={tableId}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Reference</th>
            <th>Email</th>
            <th>Invite Sent</th>
            <th>Profile Status</th>
          </tr>
        </thead>
        <tbody>
          {this.props.records.map((item, i) => (
            <Row key={i} record={item} />
          ))}
        </tbody>
      </table>
    );
  }
}

const Row = ({ record }) => {
  return (
    <tr>
      <td>
        {record.firstName} {record.surname}
      </td>
      <td>{record.internalReference}</td>
      <td>{record.emailAddress}</td>
      <td data-order={record.invitedDateTime}>
        {AppUtils.formatDateTimeUtc(record.invitedDateTime)}
        <br />
        <SmallMuted>by {record.invitedBy}</SmallMuted>
      </td>
      <td>
        <ProfileStatus record={record} />
      </td>
    </tr>
  );
};
