import { Row, Col, Alert } from 'react-bootstrap';
import moment from 'moment';
import { WellSmall } from 'pages/Utils/react-utils';
import { formatDate, dateFormats } from 'utils';
import { OpenShiftInNewTabLink } from 'components/OpenShiftInNewTabLink';
import { useVehicleOptions } from './queries';
import { useGetVehicleSummaryQuery } from './queries/vehicleQueries';

export const useCheckVehicleSchedule = (
  vehicleId: string,
  shiftDate: string,
  shiftTime: string,
  currentShiftId: string
) => {
  const { getVehicleReg } = useVehicleOptions();

  const { isFetching, isSuccess, data } = useGetVehicleSummaryQuery({
    vehicleId,
    shiftDate,
  });

  const thisShiftMoment = moment(shiftDate.split('T')[0] + 'T' + shiftTime);

  const invalidDateTimes =
    data?.shifts.filter((summary) => {
      if (summary.shiftId === currentShiftId) {
        return false;
      }
      const summaryMoment = moment(summary.shiftDateTime);
      const duration = moment.duration(summaryMoment.diff(thisShiftMoment));
      const hours = duration.asHours();
      return hours > -12 && hours < 12;
    }) ?? [];

  const renderUi = () => {
    return (
      <>
        {vehicleId ? (
          <>
            {isFetching ? (
              <p className='m-b'>Please wait, checking vehicle schedule...</p>
            ) : null}

            {!isFetching && isSuccess && invalidDateTimes.length > 0 ? (
              <Row>
                <Col xs={12}>
                  <Alert bsStyle='danger' className='m-b'>
                    <p>
                      <strong>Warning for {getVehicleReg(vehicleId)}</strong>
                    </p>
                    <p>
                      This vehicle is scheduled for use on another shift, within
                      12 hours of the start time of this shift. See below for
                      the additional scheduled times:
                    </p>
                    <ul className='m-t'>
                      {invalidDateTimes.map((invalidSummary, i) => {
                        return (
                          <ul key={i}>
                            <span style={{ color: 'red', fontWeight: 900 }}>
                              {formatDate(
                                new Date(invalidSummary.shiftDateTime),
                                dateFormats.niceShortDayWithTime
                              )}{' '}
                              |{' '}
                              <OpenShiftInNewTabLink
                                shiftId={invalidSummary.shiftId}
                              />
                            </span>
                          </ul>
                        );
                      })}
                    </ul>
                  </Alert>
                </Col>
                <Col xs={12}>
                  <WellSmall>Are you sure you want to continue?</WellSmall>
                </Col>
              </Row>
            ) : null}
          </>
        ) : null}
      </>
    );
  };

  return {
    isFetching,
    isSuccess,
    invalidDateTimes,
    renderUi,
  };
};
