import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { DashboardPanel } from 'pages/Utils/react-utils';
import { CreateNewTrailerButton } from './Buttons/CreateNewTrailerButton';
import { CreateNewVehicleButton } from './Buttons/CreateNewVehicleButton';
import { TrailersListViewActive } from './TrailersListViewActive';
import { VehiclesListViewActive } from './VehiclesListViewActive';

export const VehicleAdmin = () => {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabSelect = (tabToMakeActive) => {
    setActiveTab(tabToMakeActive);
  };
  return (
    <>
      <Intro />
      <TabsMain activeTab={activeTab} handleTabSelect={handleTabSelect} />
    </>
  );
};

const Intro = () => {
  return (
    <DashboardPanel
      noHeader
      content={
        <div style={{ display: 'flex', gap: '5px' }}>
          <CreateNewVehicleButton />
          <CreateNewTrailerButton />
        </div>
      }
    />
  );
};

const TabsMain = ({ activeTab, handleTabSelect }) => {
  let eventKey = 1;
  return (
    <div className='tabs-container'>
      <Tabs
        activeKey={activeTab}
        animation={false}
        onSelect={handleTabSelect}
        id='hirer-admin-tabs'
      >
        <Tab eventKey={eventKey++} title='Vehicles'>
          <VehiclesTab />
        </Tab>
        <Tab eventKey={eventKey++} title='Trailers'>
          <TrailersTab />
        </Tab>
      </Tabs>
    </div>
  );
};

const VehiclesTab = () => {
  return <VehiclesListViewActive />;
};

const TrailersTab = () => {
  return <TrailersListViewActive />;
};
