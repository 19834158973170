import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Icon } from 'pages/Utils/react-utils';
import { userPermissions, usePermissions } from 'utils/userPermissions';
import { PermissionForm, PermissionsTable } from '.';

export const ViewPermissionsModal = ({
  userAccountId,
  handleCloseModal,
}: {
  userAccountId: string;
  handleCloseModal: () => void;
}) => {
  return (
    <Modal backdrop='static' show={!!userAccountId} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>View Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Body userAccountId={userAccountId} />
      </Modal.Body>
      <Modal.Footer>
        <Button bsSize='sm' onClick={handleCloseModal}>
          <i className='fa fa-times'></i> Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Body = ({ userAccountId }: { userAccountId: string }) => {
  const [isFormShowing, setIsFormShowing] = useState(false);
  const { hasPermission } = usePermissions();
  const canAddPermissions = hasPermission(userPermissions.agency.user.manage);

  return (
    <>
      {!isFormShowing && canAddPermissions ? (
        <>
          <Button
            bsSize='small'
            bsStyle='primary'
            onClick={() => setIsFormShowing(true)}
          >
            <Icon icon='fa-plus' /> Add Permission
          </Button>
          <hr />
        </>
      ) : null}

      {canAddPermissions ? (
        <PermissionForm
          userAccountId={userAccountId}
          isFormShowing={isFormShowing}
          closeForm={() => setIsFormShowing(false)}
        />
      ) : null}

      <PermissionsTable userAccountId={userAccountId} />
    </>
  );
};
