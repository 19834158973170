import { Component, useMemo } from 'react';
import { AppUtils } from 'pages/Utils/app-utils';
import { WellSmall } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { getAgencies } from 'utils/api';
import { Table } from 'components/Table';
import { AgencyUtils } from './AgencyUtils';

export class AgencyListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGetting: false,
      records: [],
      lastGet: null,
    };

    this.subscriber = this.subscriber.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();

    const _me = this;
    [AgencyUtils.Events.AgencySaved].forEach((e) => {
      PubSub.subscribe(e, _me.subscriber);
    });
  }

  subscriber() {
    this.getData();
  }

  setIsGetting(val, lastGet) {
    let newState = { isGetting: val };
    if (lastGet) {
      newState['lastGet'] = lastGet;
    }
    this.setState(newState);
  }

  async getData() {
    this.setIsGetting(true);
    const data = await getAgencies();
    if (data.records) {
      this.setState({ records: data.records });
    }
    this.setIsGetting(false, new Date());
  }

  render() {
    if (this.state.records.length <= 0) {
      return <WellSmall className='m-b-none'>No agencies found.</WellSmall>;
    }
    return <TableContainer records={this.state.records} />;
  }
}

const TableContainer = ({ records }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'companyName',
      },
      {
        Header: 'Industries',
        accessor: 'canPostShiftsForIndustryTypes',
      },
      {
        Header: 'Reference',
        accessor: 'internalReference',
      },
      {
        Header: 'Created',
        accessor: (record) => record.createdDateTime,
        Cell: ({ value }) => AppUtils.formatDateTimeUtc(value),
      },
    ],
    []
  );

  return <Table columns={columns} records={records} />;
};
