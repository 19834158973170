import { useState } from 'react';

const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return {
    setIsModalOpen,
    isModalOpen,
    handleModalOpen,
    handleModalClose,
  };
};

export { useModal };
