import { Component } from 'react';
import { Alert, Col, Button, Row } from 'react-bootstrap';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import {
  SmallMuted,
  WellSmall,
  Icon,
  setIsGettingWithAutoRefresh,
  ListViewNoRecordsWell,
} from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { getInProgressShifts } from 'utils/api';
import { EditTimeModal } from '../../Shift/EditTimeModal';
import { WorkerImage } from '../../Worker/WorkerUtils';
import { HirerUtils } from '../HirerUtils';

export class ShiftInProgressListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGetting: false,
      records: [],
      lastGet: null,
      recordToBeEdited: null,
      timeout: null,
    };

    this.subscriber = this.subscriber.bind(this);
    this.getData = this.getData.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.clearRecordToBeEdited = this.clearRecordToBeEdited.bind(this);
  }

  componentDidMount() {
    this.getData();

    const _me = this;
    this.subscribeToEvents().forEach((e) => {
      PubSub.subscribe(e, _me.subscriber);
    });
  }

  componentWillUnmount() {
    console.log('ShiftInProgressListView.componentWillUnmount');
    this.subscribeToEvents().forEach(function (key) {
      PubSub.unsubscribe(key);
    });

    console.log('ShiftInProgressListView clear timeout');
    clearTimeout(this.state.timeout);
  }

  subscribeToEvents() {
    return [ShiftUtils.Events.ShiftCreated, ShiftUtils.Events.TimesUpdated];
  }

  subscriber() {
    this.getData();
  }

  setIsGetting(isGetting, lastGet) {
    setIsGettingWithAutoRefresh(
      this,
      isGetting,
      lastGet,
      this.getData,
      HirerUtils.AutoRefreshFrequencyMs
    );
  }

  async getData() {
    this.setIsGetting(true);
    const data = await getInProgressShifts();
    if (data.records) {
      this.setState({ records: data.records });
    }
    this.setIsGetting(false, new Date());

    // $.ajax({
    //   url: AppSettings.baseUrl + "Shift/InProgress",
    // })
    //   .done(function (data) {
    //     if (data.status === AppUtils.ApiStatus.Success && data.records) {
    //       this.setState({ records: data.records });
    //     }
    //   })
    //   .always(function () {
    //     this.setIsGetting(false, new Date());
    //   });
  }

  handleEditClick(shiftId) {
    console.log(`ShiftInProgressListView.handleEditClick:`, shiftId);
    const record = this.state.records.find(
      (record) => record.shiftId === shiftId
    );
    if (!record) {
      AppUtils.displayError(
        'Error',
        `Could not find Shift to edit with id: ${shiftId}`
      );
    }
    this.setRecordToBeEdited(record);
  }

  setRecordToBeEdited(record) {
    this.setState({ recordToBeEdited: record });
  }

  clearRecordToBeEdited() {
    this.setRecordToBeEdited(null);
  }

  render() {
    if (this.state.records.length <= 0 && this.state.isGetting) {
      return (
        <WellSmall className='m-b-none' hasSpinner>
          Getting Shifts In Progress...
        </WellSmall>
      );
    }
    if (this.state.records.length <= 0) {
      return (
        <ListViewNoRecordsWell
          className='m-b-none'
          btnOnClick={this.getData}
          message='No In Progress Shifts Found.'
        />
      );
    }
    return (
      <TableContainer
        {...this.state}
        getData={this.getData}
        handleEditClick={this.handleEditClick}
        clearRecordToBeEdited={this.clearRecordToBeEdited}
      />
    );
  }
}

const TableContainer = (props) => {
  return (
    <>
      <Alert bsStyle='info'>
        <Row>
          <Col sm={10}>
            <Icon icon='fa-sync' /> Shifts which are In Progress.
          </Col>
          <Col sm={2} className='text-right'>
            <Button
              bsSize='sm'
              bsStyle='primary'
              onClick={props.getData}
              disabled={props.isGetting}
            >
              <Icon icon='fa-redo' isSpinning={props.isGetting} /> Refresh
            </Button>
          </Col>
        </Row>
      </Alert>
      <Table {...props} />
      <EditTimeModal
        record={props.recordToBeEdited}
        handleCloseModalClick={props.clearRecordToBeEdited}
      />
    </>
  );
};

const tableId = 'in-progress-shifts-table';
class Table extends Component {
  render() {
    return (
      <table
        className='table table-condensed table-striped table-hover'
        id={tableId}
        data-order='[[ 0, "asc" ]]'
      >
        <thead>
          <tr>
            <th>Shift Date/Time</th>
            <th>Worker</th>
            <th>Started</th>
            <th className='no-sort' />
          </tr>
        </thead>
        <tbody>
          {this.props.records.map((item, i) => (
            <TableRow
              key={i}
              record={item}
              handleEditClick={this.props.handleEditClick}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

const TableRow = (props) => {
  const { record, handleEditClick } = props;
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  const startedDtObj = ShiftUtils.GetShiftStartedData(record);
  return (
    <tr>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime)}
      </td>
      <td data-order={record.workerSurname}>
        {record.workerHasImage && (
          <WorkerImage
            workerId={record.workerId}
            firstName={record.workerFirstName}
            surname={record.workerSurname}
            mobileNumber={record.workerMobileNumber}
          />
        )}
        {`${record.workerFirstName} ${record.workerSurname}`}
      </td>
      <td data-order={startedDtObj.value}>
        {startedDtObj.formatted}
        <div>
          <SmallMuted>{startedDtObj.by}</SmallMuted>
        </div>
      </td>
      <td className='text-right'>
        <EditButton record={record} handleEditClick={handleEditClick} />
      </td>
    </tr>
  );
};

const EditButton = (props) => {
  return (
    <Button
      bsStyle='primary'
      bsSize='xs'
      onClick={() => props.handleEditClick(props.record.shiftId)}
      data-toggle='tooltip'
      title='Edit Shift Start Time'
    >
      <Icon icon='fa-clock' />
    </Button>
  );
};
