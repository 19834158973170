import { useMutation, useQuery } from '@tanstack/react-query';
import { agencyApi } from 'apis/agencyApi';
import { VehicleCostConfig } from 'types/AgencyTypes';

export const useGetVehicleCostConfigQuery = () => {
  return useQuery({
    queryKey: ['getVehicleCostConfig'],
    queryFn: agencyApi.getVehicleCostConfig,
  });
};

export const useSaveVehicleCostConfigMutation = () => {
  return useMutation({
    mutationFn: (params: VehicleCostConfig) =>
      agencyApi.saveVehicleCostConfig(params),
  });
};

export const useWorkerImageUrlQuery = (
  workerId: string,
  preLoadedImageUrl: string
) => {
  return useQuery({
    queryKey: ['getWorkerImageUrlQuery', workerId.toString()],
    queryFn: () => agencyApi.getWorkerImageUrl(workerId),
    enabled: !preLoadedImageUrl,
  });
};
