import { Button } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useGetPlanForHirerQuery } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { Icon } from 'pages/Utils/react-utils';
import { useShiftMenuModalContext } from './ShiftMenuModalContext';
import { EShiftModalMenuUi } from './shiftMenuModalTypes';

export const BackButton = () => {
  const { setUiState } = useShiftMenuModalContext();
  return (
    <Button
      bsStyle='primary'
      onClick={() => {
        setUiState(EShiftModalMenuUi.Default);
      }}
    >
      <Icon icon='fa-arrow-left' /> Back
    </Button>
  );
};

export const CloseButton = () => {
  const { handleModalClose } = useShiftMenuModalContext();
  return (
    <Button onClick={handleModalClose}>
      <i className='fa fa-times' /> Close
    </Button>
  );
};

export const useRefreshPlannerButton = (
  query: ReturnType<typeof useGetPlanForHirerQuery>,
  isVehiclePlanner = false
) => {
  const queryClient = useQueryClient();
  const isFetchingOrLoading = query.isFetching || query.isLoading;

  const renderRefreshButton = () => {
    return (
      <Button
        className='m-r-xs'
        bsSize='sm'
        bsStyle='primary'
        disabled={isFetchingOrLoading}
        onClick={() => {
          queryClient.invalidateQueries({
            queryKey: isVehiclePlanner
              ? [QueryKeys.VehiclePlanForHirer]
              : [QueryKeys.ShiftPlanForHirer],
          });
        }}
      >
        <Icon icon='fa-redo' isSpinning={isFetchingOrLoading} />
      </Button>
    );
  };

  return { renderRefreshButton };
};
