import { VehicleCostConfig } from 'types/AgencyTypes';
import { get, put } from './apiBase';

const getVehicleCostConfig = () => {
  return get<VehicleCostConfig>(`Agency/VehicleCostConfig`);
};

const saveVehicleCostConfig = (params: VehicleCostConfig) => {
  return put(`Agency/VehicleCostConfig`, params);
};

const getWorkerImageUrl = (workerId: string) => {
  return get<{ imageUrl: string }>(`Agency/WorkerImageUrl/${workerId}`);
};

export const agencyApi = {
  getVehicleCostConfig,
  saveVehicleCostConfig,
  getWorkerImageUrl,
};
