import { useRef, useState } from 'react';
import { Panel, Row, Col, Button } from 'react-bootstrap';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import { useSelectedCustomer } from 'hooks';
import {
  useDownloadShiftPlanQuery,
  useGetPlanForHirerQuery,
} from 'hooks/queries';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils } from 'pages/Utils/app-utils';
import { AlertWithIcon, Icon, WellSmall } from 'pages/Utils/react-utils';
import { ShiftPlanItem } from 'types/ShiftPlanningTypes';
import { usePermissions, userPermissions } from 'utils/userPermissions';
import { useRefreshPlannerButton } from 'components/Planner/shiftMenuModalUtils';
import { AddShiftsModal } from './AddShiftsModal';
import { ApproveShiftsModal } from './ApproveShiftsModal';
import { PostShiftsModal } from './PostShiftsModal';
import { SendMessageModal } from './SendMessageModal';
import { ShiftPlannerGrid } from './ShiftPlannerGrid';
import {
  useAddShiftModal,
  useApproveShiftsForWorkersModal,
  usePostShiftsToWorkersModal,
  useSendMessageModal,
} from './shiftPlannerHooks';
import './style/grid.scss';

export const ShiftPlanner = () => {
  const { hasPermission } = usePermissions();
  if (!hasPermission(userPermissions.shiftPlanner.view)) {
    return (
      <AlertWithIcon bsStyle='danger'>
        You do not have permission to view this page
      </AlertWithIcon>
    );
  }

  return <ShiftPlannerMain />;
};

const ShiftPlannerMain = () => {
  const { hasPermission } = usePermissions();
  const gridRef = useRef<AgGridReact>(null);
  const getSelectedShiftPlanItemIds = () => {
    const selected = gridRef.current?.api.getSelectedRows() ?? [];
    if (selected.length <= 0) {
      AppUtils.displayError(
        'Error',
        'Please select shifts',
        false,
        'noShiftsSelected'
      );
      return;
    }
    return selected.map((s: ShiftPlanItem) => s.shiftPlanItemId);
  };

  const { selectedCustomerId, renderCustomerSelect } = useSelectedCustomer();

  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());

  const query = useGetPlanForHirerQuery({
    hirerId: selectedCustomerId,
    startDate: selectedStartDate,
  });

  const { renderRefreshButton } = useRefreshPlannerButton(query);

  const {
    isAddShiftModalOpen,
    handleAddShiftModalOpen,
    handleAddShiftModalClose,
  } = useAddShiftModal();

  const handleAddShiftsClick = () => {
    handleAddShiftModalOpen();
  };

  const [showSelectShiftsColumn, setShowSelectShiftsColumn] = useState(false);
  const handleSelectShifts = () => {
    setShowSelectShiftsColumn(!showSelectShiftsColumn);
  };

  const {
    isPostShiftsModalOpen,
    handlePostShiftsModalOpen,
    handlePostShiftsModalClose,
    shiftPlanItemIdsToPost,
  } = usePostShiftsToWorkersModal();

  const handlePostShiftsClick = () => {
    const selected = gridRef.current?.api.getSelectedRows() ?? [];
    if (selected.length <= 0) {
      AppUtils.displayError(
        'Error',
        'Please select shifts to post',
        false,
        'noShiftsSelected'
      );
      return;
    }
    const shiftPlanItemIds = selected.map(
      (s: ShiftPlanItem) => s.shiftPlanItemId
    );

    handlePostShiftsModalOpen(shiftPlanItemIds);
  };

  const {
    isApproveShiftsModalOpen,
    handleApproveShiftsModalOpen,
    handleApproveShiftsModalClose,
    shiftPlanItemIdsToApprove,
  } = useApproveShiftsForWorkersModal();

  const handleAproveShiftsClick = () => {
    const shiftPlanItemIds = getSelectedShiftPlanItemIds();
    handleApproveShiftsModalOpen(shiftPlanItemIds);
  };

  const [showDeleteColumn, setShowDeleteColumn] = useState(false);
  const handleDeleteShiftsClick = () => {
    if (!hasPermission(userPermissions.shiftPlanner.delete)) {
      return;
    }
    setShowDeleteColumn(!showDeleteColumn);
  };

  const {
    isSendMessageModalOpen,
    handleSendMessageModalOpen,
    handleSendMessageModalClose,
  } = useSendMessageModal();

  const handleSendMessageClick = () => {
    handleSendMessageModalOpen();
  };

  const downloadQuery = useDownloadShiftPlanQuery({
    hirerId: selectedCustomerId,
    startDate: selectedStartDate,
    vehiclePlanItems: false,
  });
  const handleWeeklyDownloadClick = () => {
    downloadQuery.refetch();
  };

  return (
    <>
      <Panel>
        <Panel.Heading>
          <Row>
            <Col sm={8}>
              <Panel.Title componentClass='h3'>Shift Planner</Panel.Title>
            </Col>
            <Col sm={4}>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <span>Customer:</span>
                <div style={{ flexGrow: 1 }}>{renderCustomerSelect()}</div>
              </div>
            </Col>
          </Row>
        </Panel.Heading>
        <Panel.Body>
          {selectedCustomerId ? (
            <WellSmall>
              <Row>
                <Col sm={9}>
                  {renderRefreshButton()}
                  <Button
                    bsSize='sm'
                    bsStyle='primary'
                    onClick={handleWeeklyDownloadClick}
                    disabled={downloadQuery.isFetching}
                  >
                    <Icon
                      icon='fa-download'
                      isSpinning={downloadQuery.isFetching}
                    />{' '}
                    Download Weekly
                  </Button>
                  {showSelectShiftsColumn ? (
                    <>
                      <Button
                        bsSize='sm'
                        bsStyle='success'
                        onClick={handlePostShiftsClick}
                        className='m-l-xs'
                      >
                        <Icon icon='fa-paper-plane' /> Post Selected
                      </Button>
                      <Button
                        bsSize='sm'
                        bsStyle='success'
                        onClick={handleAproveShiftsClick}
                        className='m-l-xs'
                      >
                        <Icon icon='fa-check' /> Accept Selected
                      </Button>
                      <Button
                        bsSize='sm'
                        bsStyle='danger'
                        onClick={handleSelectShifts}
                        className='m-l-xs'
                      >
                        Cancel
                      </Button>
                    </>
                  ) : null}
                  {!showSelectShiftsColumn ? (
                    <>
                      <Button
                        bsSize='sm'
                        bsStyle='primary'
                        onClick={handleAddShiftsClick}
                        className='m-l-xs'
                      >
                        <Icon icon='fa-plus' /> Add Shifts
                      </Button>
                      <Button
                        bsSize='sm'
                        bsStyle='primary'
                        onClick={handleSelectShifts}
                        className='m-l-xs'
                      >
                        <Icon icon='fa-check-square' /> Select Shifts
                      </Button>
                      <Button
                        bsSize='sm'
                        bsStyle='primary'
                        onClick={handleSendMessageClick}
                        className='m-l-xs'
                      >
                        <Icon icon='fa-envelope' /> Send Message
                      </Button>

                      {hasPermission(userPermissions.shiftPlanner.delete) ? (
                        <Button
                          bsSize='sm'
                          bsStyle='danger'
                          onClick={handleDeleteShiftsClick}
                          className='m-l-xs'
                        >
                          <Icon icon='fa-trash' /> Delete Rows
                        </Button>
                      ) : null}
                    </>
                  ) : null}
                </Col>
                <Col sm={3}>
                  <DatePicker
                    name={`StartDate`}
                    label='Start Date'
                    value={AppUtils.getDateString(selectedStartDate)}
                    changeDate={(e: { date: Date }) => {
                      setSelectedStartDate(e.date);
                    }}
                  />
                </Col>
              </Row>
            </WellSmall>
          ) : null}

          {selectedCustomerId && selectedStartDate ? (
            <ShiftPlannerGrid
              gridRef={gridRef}
              showDeleteColumn={showDeleteColumn}
              showSelectShiftsColumn={showSelectShiftsColumn}
              selectedCustomerId={selectedCustomerId}
              startDate={selectedStartDate}
              query={query}
            />
          ) : (
            <AlertWithIcon>Please select a Customer</AlertWithIcon>
          )}
        </Panel.Body>
      </Panel>

      {isAddShiftModalOpen ? (
        <AddShiftsModal
          isModalOpen={isAddShiftModalOpen}
          handleModalClose={handleAddShiftModalClose}
          hirerId={selectedCustomerId}
        />
      ) : null}

      {isSendMessageModalOpen ? (
        <SendMessageModal
          isModalOpen={isSendMessageModalOpen}
          handleModalClose={handleSendMessageModalClose}
          hirerId={selectedCustomerId}
        />
      ) : null}

      {isPostShiftsModalOpen ? (
        <PostShiftsModal
          isModalOpen={isPostShiftsModalOpen}
          handleModalClose={handlePostShiftsModalClose}
          shiftPlanItemIds={shiftPlanItemIdsToPost}
          hirerId={selectedCustomerId}
        />
      ) : null}

      {isApproveShiftsModalOpen ? (
        <ApproveShiftsModal
          isModalOpen={isApproveShiftsModalOpen}
          handleModalClose={handleApproveShiftsModalClose}
          shiftPlanItemIds={shiftPlanItemIdsToApprove}
          hirerId={selectedCustomerId}
        />
      ) : null}
    </>
  );
};
