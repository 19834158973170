import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { useMarkShiftsAsPaidMutation } from 'hooks/queries';
import { useModal } from 'hooks/useModal';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils, Constants } from 'pages/Utils/app-utils';
import { Icon, DashboardPanel, Field } from 'pages/Utils/react-utils';
import { MarkShiftsAsPaidParams } from 'types/ShiftTypes';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';

const containerName = 'mark-as-paid-container';

export const PaySelectedButton = ({
  recordIdsSelected,
  selectedTotal,
}: {
  recordIdsSelected: string[];
  selectedTotal: number;
}) => {
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const handleShowModalClick = () => {
    if (recordIdsSelected.length <= 0) {
      AppUtils.displayError(
        'Error',
        'No Shifts selected. Please select some and try again.'
      );
      return;
    }

    handleModalOpen();
  };

  const handleCloseModalClick = () => {
    handleModalClose();
  };

  const onExited = () => {
    AppUtils.destroyTooltips();
  };

  const markAsPaid = useMarkShiftsAsPaidMutation();
  const handleSubmitClick = () => {
    const $container = $(`#${containerName}`);

    if (!AppUtils.validateItems($container.find('.required'))) {
      return false;
    }

    const dataModel = AppUtils.getDataModel(
      $container
    ) as MarkShiftsAsPaidParams;
    dataModel.shiftIds = recordIdsSelected;

    markAsPaid.mutate(dataModel, {
      onSuccess: (data) => {
        AppUtils.displaySuccess('Done', 'Shifts updated successfully.');
        handleCloseModalClick();
        AppUtils.publish(ShiftUtils.Events.ShiftsPaid, data);
      },
    });
  };

  const recordsSelectedCount = recordIdsSelected.length;
  const plural = recordsSelectedCount > 1 ? 's' : '';
  const btnText = `Mark ${
    recordsSelectedCount === 0 ? '' : `${recordsSelectedCount} Shift${plural}`
  } as Paid...`;

  return (
    <>
      <Button
        bsStyle='success'
        bsSize='sm'
        onClick={handleShowModalClick}
        disabled={recordsSelectedCount <= 0}
      >
        <Icon icon='fa-money-bill-alt' /> {btnText}
      </Button>
      <Modal
        backdrop='static'
        show={isModalOpen}
        onHide={handleCloseModalClick}
        onExited={onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark Selected Shifts as Paid</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body
            recordIdsSelected={recordIdsSelected}
            selectedTotal={selectedTotal}
            plural={plural}
          />
        </Modal.Body>
        <Modal.Footer>
          {markAsPaid.isError ? (
            <ErrorAlertForQueryOrNull
              error={markAsPaid.error}
              isError={markAsPaid.isError}
            />
          ) : null}
          <Button onClick={handleCloseModalClick} bsSize='sm'>
            <i className='fa fa-times' /> Close
          </Button>
          <SubmitButton
            handleSubmitClick={handleSubmitClick}
            isSaving={markAsPaid.isLoading}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

//

const Body = ({
  selectedTotal,
  recordIdsSelected,
}: {
  selectedTotal: number;
  recordIdsSelected: string[];
  plural: 's' | '';
}) => {
  const containerStyle = { marginBottom: '0' };
  const getContent = (value: string | number) => (
    <h1 className='no-margins' style={{ color: Constants.Colours.success }}>
      {value}
    </h1>
  );
  return (
    <>
      <p>{`Here's a summary of the shifts you're going to mark as paid:`}</p>
      <Row
        style={{
          background: '#efefef',
          margin: '0 -15px 10px',
          padding: '10px 0',
        }}
      >
        <Col sm={6}>
          <DashboardPanel
            containerStyle={containerStyle}
            title='Total Amount'
            content={getContent(`£${AppUtils.formatNumber(selectedTotal)}`)}
          />
        </Col>
        <Col sm={6}>
          <DashboardPanel
            containerStyle={containerStyle}
            title='Shift Qty'
            content={getContent(recordIdsSelected.length)}
          />
        </Col>
      </Row>

      <div id={containerName}>
        <Row>
          <Field.InCol
            columns={12}
            name='PaymentReference'
            label='Payment Reference:'
            required
            maxLength={1000}
          />
        </Row>
      </div>

      <Alert bsStyle='info'>
        {`You will not be able to undo this. Are you sure you'd like to proceed?`}
      </Alert>
    </>
  );
};

const SubmitButton = ({
  isSaving,
  handleSubmitClick,
}: {
  isSaving: boolean;
  handleSubmitClick: () => void;
}) => {
  const buttonTitle = isSaving ? 'Updating...' : 'Yes, Mark as Paid';
  return (
    <Button
      bsStyle='success'
      bsSize='sm'
      className='m-l-sm'
      onClick={handleSubmitClick}
      disabled={isSaving}
    >
      <Icon icon='fa-check' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};
