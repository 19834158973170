import { AppUtils } from 'pages/Utils/app-utils';
import $ from 'pages/Utils/app-utils-jquery';
import { IExperienceRequirement } from 'types/ShiftConfigTypes';

export const hgvShiftValidateGoLive = (): boolean => {
  // idsRequiredForLive = [
  //   ...idsRequiredForLive,
  //   // 'OvernightAllowance',
  // ];

  return validateItemsWithIds([
    'Name',
    'ShiftPaymentType',
    'TypicalShiftLengthHours',
    'TypicalDropQty',
    'GuaranteedShiftLengthHours',
    'GuaranteedShiftLengthMinutes',
    'Description',
    'VehicleType',
    'TrailerType',
    'LoadType',
    'RunType',
    'HealthAndSafetyOptions',
    'SiteAddressLine1',
    'SiteTown',
    'SiteCounty',
    'SitePostcode',
    'MinimumYearsExperienceRequired',
    'DrivingLicenceTypeRequired',
    'MaxPointsOnLicenceAllowed',
    'ProhibitedConvictionList',
    ...getBreakPayAndChargeRateFieldNames(),
  ]);
};

export const otherShiftValidateGoLive = (
  experienceRecords: IExperienceRequirement[]
): boolean => {
  let isValid = validateItemsWithIds([
    'Name',
    'ShiftPaymentType',
    'TypicalShiftLengthHours',
    'GuaranteedShiftLengthHours',
    'GuaranteedShiftLengthMinutes',
    'Description',
    'HealthAndSafetyOptions',
    'SiteAddressLine1',
    'SiteTown',
    'SiteCounty',
    'SitePostcode',
    ...getBreakPayAndChargeRateFieldNames(),
  ]);

  // Validate experience requirements
  if (experienceRecords.length <= 0) {
    AppUtils.displayError(
      'Error',
      'Please enter at least one Experience Requirement'
    );
    isValid = false;
  }

  return isValid;
};

const validateItemsWithIds = (idsRequiredForLive: string[]) => {
  return AppUtils.validateItemsWithIds(
    idsRequiredForLive,
    'Please complete all required fields before changing the Status to Live'
  );
};

const getBreakPayAndChargeRateFieldNames = () => {
  return [
    ...getBreaksFieldNames(),
    ...getPayRateFieldNames(),
    ...getChargeRateFieldNames(),
  ];
};

const getBreaksFieldNames = () => {
  const retArray = [];
  if ($('#AreBreaksPaid').is(':checked')) {
    retArray.push(
      ...['BreakDurationMinutes', 'BreaksApplyHours', 'BreaksApplyMinutes']
    );
  }

  return retArray;
};

const getPayRateFieldNames = () => {
  const retArray = ['DayStartTime', 'NightStartTime'];

  const shiftPaymentType = AppUtils.getItemVal($('#ShiftPaymentType'));
  if (shiftPaymentType === 'Hourly') {
    if ($('#IsOvertimePaid').is(':checked')) {
      retArray.push(
        ...[
          'OvertimePaidAfterHours',
          'OvertimePaidAfterMinutes',
          'OvertimePercentage',
          'OvertimePercentageApplyTo',
        ]
      );
    }

    retArray.push(
      ...[
        ...getPayRateFieldNamesForPayType(shiftPaymentType),
        ...['MarginDay', 'MarginNight'],
      ]
    );
  }

  if (shiftPaymentType === 'Fixed') {
    retArray.push(
      ...[
        'FixedShiftLengthHours',
        'OvertimeRateFixedShift',
        'FixedShiftOvertimeType',
        ...getPayRateFieldNamesForPayType(shiftPaymentType),
      ]
    );
  }

  return retArray;
};

const getPayRateFieldNamesForPayType = (payType: 'Hourly' | 'Fixed') => {
  const dayArray = ['', 'Saturday', 'Sunday'];
  const timeArray = ['DayRate', 'NightRate'].map((time) => time + payType);
  const typeArray = ['', 'PayeWithHoliday', 'PayeAccruedHoliday', 'Contractor'];
  const daysAndTimes = dayArray.reduce((array, day) => {
    return [...array, ...timeArray.map((time) => day + time)];
  }, []);

  return typeArray.reduce((acc, suffix) => {
    return [...acc, ...daysAndTimes.map((dayAndTime) => dayAndTime + suffix)];
  }, []);
};

const getChargeRateFieldNames = () => {
  const dayArray = ['', 'Saturday', 'Sunday'];
  const timeArray = ['Day', 'Night'];
  const prefixes = ['chargeAmountFixed', 'chargeRateOvertimePerHour'];
  const daysAndTimes = dayArray.reduce((array, day) => {
    return [...array, ...timeArray.map((time) => day + time)];
  }, []);
  const retArray = prefixes.reduce((array, prefix) => {
    return [...array, ...daysAndTimes.map((dayAndTime) => prefix + dayAndTime)];
  }, []);

  retArray.push(
    ...[
      'ChargeType',
      'MileageQtyAllowance',
      'MileageChargePerMileAfterAllowance',
    ]
  );

  const shiftPaymentType = AppUtils.getItemVal($('#ShiftPaymentType'));
  if (shiftPaymentType === 'Hourly') {
    retArray.push(...['MarginDay', 'MarginNight']);
  }

  if (shiftPaymentType === 'Fixed') {
    retArray.push('MarginFixed');
  }

  return retArray;
};
