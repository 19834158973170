import { Component } from 'react';
import { Button } from 'react-bootstrap';
import { HirerModal } from '../HirerModal';

export class ButtonCreateNew extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  handleShowModalClick() {
    this.setState({ showModal: true });
  }

  handleCloseModalClick() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <div>
        <Button
          bsStyle='primary'
          onClick={this.handleShowModalClick}
          style={{ marginBottom: 0 }}
        >
          {' '}
          <i className='fas fa-forklift' /> Add New Customer
        </Button>
        <HirerModal
          {...this.state}
          {...this.props}
          handleCloseModalClick={this.handleCloseModalClick}
        />
      </div>
    );
  }
}
