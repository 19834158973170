import { useState } from 'react';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useVehicleOptions, useWorkerOptions } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { useCreateSingleShiftForVehicleMutation } from 'hooks/queries/shiftPlanningQueries';
import { useGetVehicleSummaryQuery } from 'hooks/queries/vehicleQueries';
import { useModal } from 'hooks/useModal';
import moment from 'moment';
import { getWorkerTypeFromIndustryType } from 'pages/ShiftConfig/ShiftConfigUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall } from 'pages/Utils/react-utils';
import { ICustomEvent } from 'types/GeneralTypes';
import { CreateSingleShiftForVehicleModalParams } from 'types/ShiftPlanningTypes';
import { dateFormats, extractErrorMessages, formatDate } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { HourAndMinuteDropdown } from 'components/HourAndMinuteDropdown';
import { OpenShiftInNewTabLink } from 'components/OpenShiftInNewTabLink';

export const useCreateSingleShift = (selectedCustomerId: string) => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();

  const [createSingleShiftParams, setCreateSingleShiftParams] =
    useState<CreateSingleShiftForVehicleModalParams | null>(null);

  const handleCreateSingleShiftModalOpen = (
    params: CreateSingleShiftForVehicleModalParams
  ) => {
    setCreateSingleShiftParams(params);
    handleModalOpen();
  };

  const handleCreateSingleShiftModalClose = () => {
    setCreateSingleShiftParams(null);
    handleModalClose();
  };

  const renderCreateSingleShiftModal = () => {
    if (!isModalOpen || !createSingleShiftParams) {
      return null;
    }

    return (
      <CreateSingleShiftForVehicleModal
        isModalOpen={isModalOpen}
        handleModalClose={handleCreateSingleShiftModalClose}
        params={createSingleShiftParams}
      />
    );
  };

  return {
    handleCreateSingleShiftModalOpen,
    renderCreateSingleShiftModal,
  };
};

export const CreateSingleShiftForVehicleModal = ({
  isModalOpen,
  handleModalClose,
  params,
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
  params: CreateSingleShiftForVehicleModalParams;
}) => {
  const { date, shiftPlanItem } = params;
  const queryClient = useQueryClient();
  const { vehicleOptions, getVehicleReg } = useVehicleOptions();

  const [vehicleId, setVehicleId] = useState<string | null>(null);

  const query = useGetVehicleSummaryQuery({
    vehicleId,
    shiftDate: date,
  });

  const invalidDateTimes =
    query.data?.shifts.filter((summary) => {
      const summaryMoment = moment(summary.shiftDateTime);
      const dtString =
        date.split('T')[0] + 'T' + (shiftPlanItem.shiftTime ?? '00:00:00');
      const thisShiftMoment = moment(dtString);
      const duration = moment.duration(summaryMoment.diff(thisShiftMoment));
      const hours = duration.asHours();
      return hours > -12 && hours < 12;
    }) ?? [];

  const { workerOptions } = useWorkerOptions(shiftPlanItem.shiftIndustryType);
  const workerType = AppUtils.toTitleCase(
    getWorkerTypeFromIndustryType(shiftPlanItem.shiftIndustryType)
  );

  const createSingleShiftMutation = useCreateSingleShiftForVehicleMutation();
  const handleSubmitClick = () => {
    const $modal = $('.modal');
    if (!AppUtils.validateItems($modal.find('.required'))) {
      return;
    }
    const model = AppUtils.getDataModel($modal) as {
      WorkerId: string;
      VehicleId: string;
      ShiftTimeHour: string;
      ShiftTimeMinutes: string;
    };

    const data = {
      ...params,
      workerId: model.WorkerId,
      vehicleId: model.VehicleId,
      shiftTime:
        model.ShiftTimeHour && model.ShiftTimeMinutes
          ? `${model.ShiftTimeHour}:${model.ShiftTimeMinutes}`
          : null,
    };

    createSingleShiftMutation.mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.VehiclePlanForHirer],
        });
        AppUtils.displaySuccess('Success', 'Shift created successfully');
        handleModalClose();
      },
    });
  };

  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Create Shift for {formatDate(date, dateFormats.niceShortDay)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WellSmall>
          <p>
            You are going to create a shift for{' '}
            <strong>{formatDate(date, dateFormats.niceShortDay)}</strong>.
          </p>
        </WellSmall>

        <Row>
          <Field.InCol
            columns={12}
            name={`WorkerId`}
            label={workerType}
            options={workerOptions}
            isSelect
            required
          />
        </Row>

        <Row>
          <Field.InCol
            columns={12}
            name={`VehicleId`}
            label={`Vehicle`}
            options={vehicleOptions}
            isSelect
            value={vehicleId}
            onChange={(e: ICustomEvent) => {
              setVehicleId(e.target.value);
            }}
          />
        </Row>

        {vehicleId ? (
          <>
            {query.isFetching ? (
              <p className='m-b'>Please wait, checking vehicle schedule...</p>
            ) : null}

            {!query.isFetching &&
            query.isSuccess &&
            invalidDateTimes.length > 0 ? (
              <Row>
                <Col xs={12}>
                  <Alert bsStyle='danger' className='m-b'>
                    <p>
                      <strong>Warning for {getVehicleReg(vehicleId)}</strong>
                    </p>
                    <p>
                      This vehicle is scheduled for use on another shift, within
                      12 hours of the start time of this shift. See below for
                      the additional scheduled times:
                    </p>
                    <ul className='m-t'>
                      {invalidDateTimes.map((invalidSummary, i) => {
                        return (
                          <ul key={i}>
                            <span style={{ color: 'red', fontWeight: 900 }}>
                              {formatDate(
                                new Date(invalidSummary.shiftDateTime),
                                dateFormats.niceShortDayWithTime
                              )}{' '}
                              |{' '}
                              <OpenShiftInNewTabLink
                                shiftId={invalidSummary.shiftId}
                              />
                            </span>
                          </ul>
                        );
                      })}
                    </ul>
                  </Alert>
                </Col>
              </Row>
            ) : null}
          </>
        ) : null}

        <HourAndMinuteDropdown prefix='Shift' label='Shift Start Time' />

        <Alert bsStyle='info'>Are you sure you want to proceed?</Alert>

        {createSingleShiftMutation.isError ? (
          <Row>
            <Col xs={12}>
              <ErrorAlert
                msg={extractErrorMessages(createSingleShiftMutation.error)}
              />
            </Col>
          </Row>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>
          <i className='fa fa-times' /> Close
        </Button>
        <ModalSaveButton
          isSaved={createSingleShiftMutation.isSuccess}
          isSaving={createSingleShiftMutation.isLoading}
          onClick={handleSubmitClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

const ModalSaveButton = ({
  isSaving,
  isSaved,
  onClick,
}: {
  isSaving: boolean;
  isSaved: boolean;
  onClick: () => void;
}) => {
  const isDisabled = isSaving || isSaved;
  const buttonTitle = isSaving
    ? 'Creating...'
    : isSaved
    ? 'Created!'
    : `Yes, Create Shift`;
  return (
    <Button bsStyle='success' onClick={onClick} disabled={isDisabled}>
      <Icon icon='fa-times' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};
