import { useRef, useState } from 'react';
import { Panel, Row, Col, Button } from 'react-bootstrap';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AgGridReact } from 'ag-grid-react';
import { useSelectedCustomer } from 'hooks';
import {
  useDownloadDailyPlanQuery,
  useDownloadShiftPlanQuery,
  useGetPlanForHirerQuery,
} from 'hooks/queries';
import useSubscription from 'hooks/useSubscription';
import moment from 'moment';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils } from 'pages/Utils/app-utils';
import { AlertWithIcon, Icon, WellSmall } from 'pages/Utils/react-utils';
import { ShiftPlanItem } from 'types/ShiftPlanningTypes';
import { usePermissions, userPermissions } from 'utils/userPermissions';
import { useRefreshPlannerButton } from 'components/Planner/shiftMenuModalUtils';
import { AddShiftsModal } from './AddShiftsModal';
import { ApproveShiftsModal } from './ApproveShiftsModal';
import { VehiclePlannerGrid } from './VehiclePlannerGrid';
import './style/grid.scss';
import {
  useAddShiftModal,
  useApproveShiftsForWorkersModal,
} from './vehiclePlannerHooks';

export const VehiclePlanner = () => {
  const { hasPermission } = usePermissions();
  if (!hasPermission(userPermissions.vehiclePlanner.view)) {
    return (
      <AlertWithIcon bsStyle='danger'>
        You do not have permission to view this page
      </AlertWithIcon>
    );
  }

  return <VehiclePlannerMain />;
};

const VehiclePlannerMain = () => {
  const { hasPermission } = usePermissions();
  const gridRef = useRef<AgGridReact>(null);
  const getSelectedShiftPlanItemIds = () => {
    const selected = gridRef.current?.api.getSelectedRows() ?? [];
    if (selected.length <= 0) {
      AppUtils.displayError(
        'Error',
        'Please select shifts',
        false,
        'noShiftsSelected'
      );
      return;
    }
    return selected.map((s: ShiftPlanItem) => s.shiftPlanItemId);
  };

  const { selectedCustomerId, renderCustomerSelect } = useSelectedCustomer();

  const firstMondayDate = moment().weekday(1).toDate();
  const [selectedStartDate, setSelectedStartDate] =
    useState<Date>(firstMondayDate);

  const query = useGetPlanForHirerQuery({
    hirerId: selectedCustomerId,
    startDate: selectedStartDate,
    vehiclePlanItems: true,
  });

  const { renderRefreshButton } = useRefreshPlannerButton(query, true);

  useSubscription([ShiftUtils.Events.ShiftLinked], () => {
    query.refetch();
  });

  const {
    isAddShiftModalOpen,
    handleAddShiftModalOpen,
    handleAddShiftModalClose,
  } = useAddShiftModal();

  const handleAddShiftsClick = () => {
    handleAddShiftModalOpen();
  };

  const [showSelectShiftsColumn, setShowSelectShiftsColumn] = useState(false);
  const handleSelectShifts = () => {
    setShowSelectShiftsColumn(!showSelectShiftsColumn);
  };

  const {
    isApproveShiftsModalOpen,
    handleApproveShiftsModalOpen,
    handleApproveShiftsModalClose,
    shiftPlanItemIdsToApprove,
  } = useApproveShiftsForWorkersModal();

  const handleAproveShiftsClick = () => {
    const shiftPlanItemIds = getSelectedShiftPlanItemIds();
    handleApproveShiftsModalOpen(shiftPlanItemIds);
  };

  const [showDeleteColumn, setShowDeleteColumn] = useState(false);
  const handleDeleteShiftsClick = () => {
    if (!hasPermission(userPermissions.vehiclePlanner.delete)) {
      return;
    }
    setShowDeleteColumn(!showDeleteColumn);
  };

  const downloadWeeklyQuery = useDownloadShiftPlanQuery({
    hirerId: selectedCustomerId,
    startDate: selectedStartDate,
    vehiclePlanItems: true,
  });
  const handleWeeklyDownloadClick = () => {
    downloadWeeklyQuery.refetch();
  };

  const downloadDailyQuery = useDownloadDailyPlanQuery({
    hirerId: selectedCustomerId,
    vehiclePlanItems: true,
  });
  const handleDailyDownloadClick = () => {
    downloadDailyQuery.refetch();
  };

  return (
    <>
      <Panel>
        <Panel.Heading>
          <Row>
            <Col sm={8}>
              <Panel.Title componentClass='h3'>Vehicle Planner</Panel.Title>
            </Col>
            <Col sm={4}>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <span>Customer:</span>
                <div style={{ flexGrow: 1 }}>{renderCustomerSelect()}</div>
              </div>
            </Col>
          </Row>
        </Panel.Heading>
        <Panel.Body>
          {selectedCustomerId ? (
            <WellSmall>
              <Row>
                <Col sm={9}>
                  {renderRefreshButton()}
                  <Button
                    bsSize='sm'
                    bsStyle='primary'
                    onClick={handleDailyDownloadClick}
                    disabled={downloadDailyQuery.isFetching}
                  >
                    <Icon
                      icon='fa-download'
                      isSpinning={downloadDailyQuery.isFetching}
                    />{' '}
                    Daily Report
                  </Button>
                  <Button
                    bsSize='sm'
                    bsStyle='primary'
                    onClick={handleWeeklyDownloadClick}
                    disabled={downloadWeeklyQuery.isFetching}
                    className='m-l-xs'
                  >
                    <Icon
                      icon='fa-download'
                      isSpinning={downloadWeeklyQuery.isFetching}
                    />{' '}
                    Weekly Report
                  </Button>
                  {showSelectShiftsColumn ? (
                    <>
                      <Button
                        bsSize='sm'
                        bsStyle='success'
                        onClick={handleAproveShiftsClick}
                        className='m-l-xs'
                      >
                        <Icon icon='fa-check' /> Accept Selected
                      </Button>
                      <Button
                        bsSize='sm'
                        bsStyle='danger'
                        onClick={handleSelectShifts}
                        className='m-l-xs'
                      >
                        Cancel
                      </Button>
                    </>
                  ) : null}
                  {!showSelectShiftsColumn ? (
                    <>
                      <Button
                        bsSize='sm'
                        bsStyle='primary'
                        onClick={handleAddShiftsClick}
                        className='m-l-xs'
                      >
                        <Icon icon='fa-plus' /> Add Shifts
                      </Button>
                      <Button
                        bsSize='sm'
                        bsStyle='primary'
                        onClick={handleSelectShifts}
                        className='m-l-xs'
                      >
                        <Icon icon='fa-check-square' /> Select Shifts
                      </Button>

                      {hasPermission(userPermissions.vehiclePlanner.delete) ? (
                        <Button
                          bsSize='sm'
                          bsStyle='danger'
                          onClick={handleDeleteShiftsClick}
                          className='m-l-xs'
                        >
                          <Icon icon='fa-trash' /> Delete Rows
                        </Button>
                      ) : null}
                    </>
                  ) : null}
                </Col>
                <Col sm={3}>
                  <DatePicker
                    name={`StartDate`}
                    label='Start Date'
                    value={AppUtils.getDateString(selectedStartDate)}
                    changeDate={(e: { date: Date }) => {
                      setSelectedStartDate(e.date);
                    }}
                  />
                </Col>
              </Row>
            </WellSmall>
          ) : null}

          {selectedCustomerId && selectedStartDate ? (
            <VehiclePlannerGrid
              gridRef={gridRef}
              showDeleteColumn={showDeleteColumn}
              showSelectShiftsColumn={showSelectShiftsColumn}
              selectedCustomerId={selectedCustomerId}
              startDate={selectedStartDate}
              query={query}
            />
          ) : (
            <AlertWithIcon>Please select a Customer</AlertWithIcon>
          )}
        </Panel.Body>
      </Panel>

      {isAddShiftModalOpen ? (
        <AddShiftsModal
          isModalOpen={isAddShiftModalOpen}
          handleModalClose={handleAddShiftModalClose}
          hirerId={selectedCustomerId}
        />
      ) : null}

      {isApproveShiftsModalOpen ? (
        <ApproveShiftsModal
          isModalOpen={isApproveShiftsModalOpen}
          handleModalClose={handleApproveShiftsModalClose}
          shiftPlanItemIds={shiftPlanItemIdsToApprove}
          hirerId={selectedCustomerId}
        />
      ) : null}
    </>
  );
};
