import { useMutation, useQuery } from '@tanstack/react-query';
import { locationApi } from 'apis/locationApi';
import {
  CreateLocationParams,
  UpdateLocationParams,
} from 'types/LocationTypes';

export const useCreateLocationMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: CreateLocationParams) => {
      return locationApi.createLocation(params);
    },
  });

  return mutation;
};

export const useEditLocationMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: UpdateLocationParams) => {
      return locationApi.updateLocation(params);
    },
  });

  return mutation;
};

export const useGetLocationsByHirerQuery = (
  params: Parameters<(typeof locationApi)['getLocationsByHirer']>[0]
) => {
  return useQuery({
    queryKey: ['getLocationsByHirer', params.hirerId],
    queryFn: () => locationApi.getLocationsByHirer(params),
    enabled:
      params.hirerId && params.hirerId !== 'NoCustomerSelected' ? true : false,
  });
};

export const useCalculateDistanceFromRdcQuery = (
  params: Parameters<(typeof locationApi)['calculateDistanceFromHirerRdc']>[0]
) => {
  return useQuery({
    queryKey: ['calculateLocationDistance', params],
    queryFn: () => locationApi.calculateDistanceFromHirerRdc(params),
    enabled: false,
    retry: false,
  });
};
