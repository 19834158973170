import { Row } from 'react-bootstrap';
import { FormLabel } from 'components';
import { TimeDropdown } from 'pages/Utils/TimeDropdown';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, InputField } from 'pages/Utils/react-utils';
import { useShiftConfigContext } from './ShiftConfigContextProvider';
import { OvernightAllowanceField, TimesRoundingTypeField } from './common';
import { RatesTabFixed } from './';

export const PayRatesTab = () => {
  return (
    <div className='shift-finance'>
      <RatesTabInner />
    </div>
  );
};

const RatesTabInner = () => {
  const { isFixedShift } = useShiftConfigContext();

  return (
    <>
      <h2>Pay Rates</h2>
      <table className='table pay-rate-table'>
        <tbody>
          <RatesTopRow />
          <StartTimeRow />
          <RateRow label='Standard' noPrefix />
          <RateRow label='Saturday' />
          <RateRow label='Sunday' />
        </tbody>
      </table>

      <hr />

      {isFixedShift ? <RatesTabFixed /> : <HourlySpecificFields />}
    </>
  );
};

const RatesTopRow = () => {
  const tooltipText =
    "This is the time for which a Day or Night starts when using this Shift Template. For example, let's say the Day Start Time is set to 8:00 and the Night Start Time is set to 20:00. If a Shift is then created which starts at 8:30, then the Day Info will be used when calculating the finance once the Shift is Approved. However If a shift is created which starts at 21:30 then the Night Info data will be used. Pay rates for any shifts that cross over between Day & Night start times will be paid according to the amount of time worked during each time period.";
  const tooltip = (
    <i
      className='fa fa-info-circle m-l text-primary'
      data-toggle='tooltip'
      title={tooltipText}
    />
  );
  return (
    <tr>
      <td />
      <td className='text-center'>
        <FormLabel label='Day Info' />
        {tooltip}
      </td>
      <td className='text-center'>
        <FormLabel label='Night Info' />
        {tooltip}
      </td>
    </tr>
  );
};

const StartTimeRow = () => {
  const { shiftFinance, disabled } = useShiftConfigContext();
  return (
    <tr>
      <td>
        <FormLabel label='Start Time' />
      </td>
      <td>
        <TimeDropdown
          name='DayStartTime'
          value={shiftFinance.dayStartTime}
          placeholder='Choose Day Start Time...'
          required
          noLabel
          disabled={disabled}
        />
      </td>
      <td>
        <TimeDropdown
          name='NightStartTime'
          value={shiftFinance.nightStartTime}
          placeholder='Choose Night Start Time...'
          required
          noLabel
          disabled={disabled}
        />
      </td>
    </tr>
  );
};

const RateRow = ({ noPrefix, label }) => {
  const { isFixedShift } = useShiftConfigContext();
  const prefix = noPrefix ? '' : label;
  const fixedOrHourly = isFixedShift ? 'Fixed' : 'Hourly';
  const dayRateString = `${prefix}DayRate${fixedOrHourly}`;
  const nightRateString = `${prefix}NightRate${fixedOrHourly}`;
  return (
    <>
      <tr>
        <td className='firstCol'>
          <FormLabel label={`${label} Agency`} />
        </td>
        <td>
          <RateField fieldName={dayRateString} />
        </td>
        <td>
          <RateField fieldName={nightRateString} />
        </td>
      </tr>
      <tr className='no-border'>
        <td className='firstCol'>
          <FormLabel label={`${label} PAYE Holiday Paid`} />
        </td>
        <td>
          <RateField fieldName={`${dayRateString}PayeWithHoliday`} />
        </td>
        <td>
          <RateField fieldName={`${nightRateString}PayeWithHoliday`} />
        </td>
      </tr>
      <tr className='no-border'>
        <td className='firstCol'>
          <FormLabel label={`${label} PAYE Holiday Accrued`} />
        </td>
        <td>
          <RateField fieldName={`${dayRateString}PayeAccruedHoliday`} />
        </td>
        <td>
          <RateField fieldName={`${nightRateString}PayeAccruedHoliday`} />
        </td>
      </tr>
      <tr className='no-border'>
        <td className='firstCol'>
          <FormLabel label={`${label} Contractor`} />
        </td>
        <td>
          <RateField fieldName={`${dayRateString}Contractor`} />
        </td>
        <td>
          <RateField fieldName={`${nightRateString}Contractor`} />
        </td>
      </tr>
    </>
  );
};

const RateField = ({ fieldName }) => {
  const { shiftFinance, disabled, isFixedShift } = useShiftConfigContext();
  return (
    <div>
      <InputField
        name={fieldName}
        value={AppUtils.formatNumber(shiftFinance[fieldName.toCamelCase()])}
        maxLength='6'
        isNumber
        noLabel
        addOnPre='£'
        addOnPost={isFixedShift ? 'Fixed Amount' : 'Per Hour'}
        disabled={disabled}
      />
    </div>
  );
};

const HourlySpecificFields = () => {
  const { configuration, shiftFinance, disabled } = useShiftConfigContext();
  return (
    <>
      <h2>Additional Hourly Payment Config</h2>
      <hr />
      <Row>
        <Field.InCol
          columns={4}
          name='OvertimePercentage'
          label='Overtime Rate'
          value={
            shiftFinance.overtimePercentage
              ? shiftFinance.overtimePercentage.toString()
              : ''
          }
          isSelect
          options={configuration.overtimeOptions}
          disabled={disabled}
        />
        <Field.InCol
          columns={4}
          name='OvertimePercentageApplyTo'
          label='Apply Overtime Rate to'
          value={shiftFinance.overtimePercentageApplyTo}
          isSelect
          options={configuration.overtimeApplyToOptions.map((item) => ({
            value: item,
            text: item + ' Rate',
          }))}
          doesNotHaveEmptyTopRow
          notClearable
          disabled={disabled}
        />
        <OvernightAllowanceField />
        <TimesRoundingTypeField />
      </Row>
    </>
  );
};
