import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { getHirerHomeData } from 'utils/api';
import { useAuth } from 'utils/auth';
import { GettingRecordsMessage } from 'components/GettingRecordsMessage';
import { ShiftUtils } from '../Shift/ShiftUtils';
import { AppUtils, Constants } from '../Utils/app-utils';
import {
  Icon,
  Muted,
  DashboardHeader,
  DashboardPanel,
  AlertWithIcon,
} from '../Utils/react-utils';
import CurrentScheduleListView from './Shift/CurrentScheduleListView';
import { RunningForTooLongListView } from './Shift/RunningForTooLongListView';

const HirerHome = () => {
  const auth = useAuth();
  return <Main auth={auth} />;
};

const _uiTypes = {
  Home: 'Home',
  ShiftsInProgressForTooLong: 'ShiftsInProgressForTooLong',
};

const Main = ({ auth }) => {
  const [uiType, setUiType] = useState(_uiTypes.Home);
  const changeUi = (uiType = _uiTypes.Home) => {
    setUiType(uiType);
  };

  const { data, isLoading, isFetching } = useQuery(
    ['hirerHomeData'],
    getHirerHomeData,
    {
      refetchInterval: Constants.DefaultAutoRefetchFrequency,
    }
  );
  const model = data?.model;
  const isGetting = isLoading || isFetching;
  if (!model && isGetting) {
    return (
      <GettingRecordsMessage message='Please wait while the home page is configured...' />
    );
  }

  if (!model) {
    return null;
  }

  return (
    <>
      <DashboardHeader>
        <Col md={6}>
          <h2>Hello {auth.user.record.firstName}</h2>
        </Col>
        <Col md={6} className='text-right'>
          <table className='' style={{ width: 'auto', marginLeft: 'auto' }}>
            <tbody>
              <HeaderInfoTableRow label='Company' value={model.companyName} />
              <HeaderInfoTableRow
                label='RightJob Ref'
                value={model.internalReference}
              />
            </tbody>
          </table>
        </Col>
      </DashboardHeader>

      {uiType === _uiTypes.Home && <MainUi model={model} changeUi={changeUi} />}
      {uiType === _uiTypes.ShiftsInProgressForTooLong && (
        <RunningForTooLongListView changeUi={changeUi} />
      )}
    </>
  );
};

const MainUi = ({ model, changeUi }) => {
  return (
    <>
      <ShiftsRunningForTooLongSection
        changeUi={changeUi}
        shiftsRunningForTooLong={model.shiftsRunningForTooLong}
      />

      <Row>
        <Col sm={9}>
          <CurrentScheduleListView />
        </Col>
        <Col sm={3}>
          <DashboardPanel
            header={
              <>
                <span className='label label-info pull-right'>Confirmed</span>
                <h5>{`Today's Shifts`}</h5>
              </>
            }
            content={
              <>
                <h1 className='no-margins'>{model.allocatedShiftsForToday}</h1>
                <small>Shifts</small>
                <div className='pull-right'>
                  <LinkContainer to='/Shift'>
                    <Button bsStyle='primary' bsSize='xs'>
                      <Icon icon='fa-search' /> View
                    </Button>
                  </LinkContainer>
                </div>
              </>
            }
          />
          <DashboardPanel
            header={
              <>
                <span className='label label-warning pull-right'>
                  In Progress
                </span>
                <h5>{`Today's Shifts`}</h5>
              </>
            }
            content={
              <>
                <h1 className='no-margins'>{model.inProgressShiftsForToday}</h1>
                <small>Shifts</small>
                <div className='pull-right'>
                  <LinkContainer to='/Shift'>
                    <Button bsStyle='primary' bsSize='xs'>
                      <Icon icon='fa-search' /> View
                    </Button>
                  </LinkContainer>
                </div>
              </>
            }
          />
          <DashboardPanel
            header={
              <>
                <span className='label label-success pull-right'>Complete</span>
                <h5>{`Today's Shifts`}</h5>
              </>
            }
            content={
              <>
                <h1 className='no-margins'>{model.completedShiftsForToday}</h1>
                <small>Shifts</small>
                <div className='pull-right'>
                  <LinkContainer to='/Shift'>
                    <Button bsStyle='primary' bsSize='xs'>
                      <Icon icon='fa-search' /> View
                    </Button>
                  </LinkContainer>
                </div>
              </>
            }
          />
          <DashboardPanel
            header={
              <>
                <span className='label label-primary pull-right'>Approved</span>
                <h5>{`Today's Shifts`}</h5>
              </>
            }
            content={
              <>
                <h1 className='no-margins'>{model.approvedShiftsForToday}</h1>
                <small>Shifts</small>
                <div className='pull-right'>
                  <LinkContainer to='/Shift'>
                    <Button bsStyle='primary' bsSize='xs'>
                      <Icon icon='fa-search' /> View
                    </Button>
                  </LinkContainer>
                </div>
              </>
            }
          />
          <DashboardPanel
            header={
              <>
                <span className='label label-danger pull-right'>
                  Outstanding
                </span>
                <h5>Awaiting Review</h5>
              </>
            }
            content={
              <>
                <h1 className='no-margins'>
                  {model.completedShiftsAwaitingReview}
                </h1>
                <small>Shifts</small>
                <div className='pull-right'>
                  <LinkContainer to='/Shift'>
                    <Button bsStyle='primary' bsSize='xs'>
                      <Icon icon='fa-search' /> View
                    </Button>
                  </LinkContainer>
                </div>
              </>
            }
          />
          <DashboardPanel
            header={
              <>
                <span className='label pull-right'>This Year</span>
                <h5>Completed Shifts</h5>
              </>
            }
            content={
              <>
                <h1 className='no-margins'>{model.approvedShiftsThisYear}</h1>
                <small>Shifts</small>
              </>
            }
          />
        </Col>
      </Row>
    </>
  );
};

const ShiftsRunningForTooLongSection = ({
  shiftsRunningForTooLong,
  changeUi,
}) => {
  return (
    shiftsRunningForTooLong > 0 && (
      <AlertWithIcon bsStyle='danger' icon='fa-exclamation-circle'>
        There {AppUtils.pluralise(shiftsRunningForTooLong, 'is', 'are')}{' '}
        {shiftsRunningForTooLong} shift
        {AppUtils.pluralise(shiftsRunningForTooLong, '', 's')} which{' '}
        {AppUtils.pluralise(shiftsRunningForTooLong, 'has', 'have')} been in
        progress for more than {ShiftUtils.ShiftRunWarningTimeHours} hours.
        <Button
          bsSize='sm'
          bsStyle='primary'
          style={{ marginLeft: 'auto' }}
          onClick={() => changeUi(_uiTypes.ShiftsInProgressForTooLong)}
        >
          View &raquo;
        </Button>
      </AlertWithIcon>
    )
  );
};

const HeaderInfoTableRow = ({ label, value }) => {
  return (
    <tr>
      <td className='text-right p-w-sm'>
        <Muted>{label}</Muted>
      </td>
      <td className='text-left'>
        <strong>{value}</strong>
      </td>
    </tr>
  );
};

export default HirerHome;
