import { ReactNode, createContext, useContext, useState } from 'react';
import { Shift } from 'types/ShiftTypes';

type ShiftContextType = {
  shiftId: string;
  setDisplayShiftId: (shiftId: string | null) => void;
  setShift: (shift: Shift) => void;
  shift: Shift | null;
};

const ShiftContext = createContext<ShiftContextType | undefined>(undefined);

type ShiftContextProviderProps = ShiftContextType & {
  children: ReactNode;
};

export const ShiftContextProvider = ({
  shiftId,
  setDisplayShiftId,
  children,
}: ShiftContextProviderProps) => {
  const [shift, setShiftState] = useState<Shift | null>(null);

  const setShift = (shift: Shift | null) => {
    setShiftState(shift);
  };

  const value = { shiftId, setDisplayShiftId, setShift, shift };

  return (
    <ShiftContext.Provider value={value}>{children}</ShiftContext.Provider>
  );
};

export const useShiftContext = () => {
  const context = useContext(ShiftContext);
  if (context === undefined) {
    throw new Error(
      'useShiftContext must be used within a ShiftContextProvider'
    );
  }
  return context;
};
