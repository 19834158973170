import { Component } from 'react';
import { Alert, Button, Modal, Row } from 'react-bootstrap';
import $ from 'jquery';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall, SmallMuted } from 'pages/Utils/react-utils';
import { cancelShift } from 'utils/api';
import { ShiftUtils } from '../ShiftUtils';

const statusesWhichAllowCancellation = [
  ShiftUtils.Status.Open,
  ShiftUtils.Status.PreAllocated,
  ShiftUtils.Status.Allocated,
  ShiftUtils.Status.Complete,
];

const canShiftBeCancelled = (record) =>
  statusesWhichAllowCancellation.includes(record.status);

export class CancelShiftButton extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  handleShowModalClick() {
    this.setState({ showModal: true });
  }

  handleCloseModalClick() {
    this.setState({ showModal: false });
  }

  render() {
    if (!canShiftBeCancelled(this.props.record)) {
      return null;
    }

    return (
      <>
        <Button
          bsStyle='danger'
          onClick={this.handleShowModalClick}
          bsSize='xs'
        >
          <Icon icon='fa-times-octagon' /> Cancel Shift
        </Button>
        <CancelShiftModal
          {...this.state}
          {...this.props}
          handleCloseModalClick={this.handleCloseModalClick}
        />
      </>
    );
  }
}

class CancelShiftModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();

    this.onExited = this.onExited.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  defaultState() {
    return {
      isCancelling: false,
      isCancelled: false,
    };
  }

  onExited() {
    this.setState(this.defaultState());
  }

  async handleCancelClick() {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);
    model['ShiftId'] = this.props.record.shiftId;

    this.setState({ isCancelling: true });
    AppUtils.clearNotifications();

    const data = await cancelShift(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Success', 'Shift cancelled');
      this.setState({ isCancelled: true });
      setTimeout(() => this.props.handleCloseModalClick(), 500);
      AppUtils.publish(ShiftUtils.Events.ShiftCancelled, data);
    });
    this.setState({ isCancelling: false });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.showModal}
        onHide={this.props.handleCloseModalClick}
        onEntered={() => $('#CancellationComments').focus()}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Shift</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.props} {...this.state} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.handleCloseModalClick} bsSize='sm'>
            <i className='fa fa-times' /> Close
          </Button>
          <ModalSaveButton
            {...this.props}
            {...this.state}
            onClick={this.handleCancelClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = (props) => {
  const { isCancelled, record } = props;
  if (isCancelled) {
    return <CancelledUi isCancelled={isCancelled} />;
  }

  const postFieldComments =
    record.status === ShiftUtils.Status.Open
      ? 'these comments will be sent to all workers who have applied for this shift.'
      : record.status === ShiftUtils.Status.Allocated
      ? 'these comments will be sent to the allocated workers.'
      : '';

  return (
    <>
      <Row>
        <Field.InCol
          columns={12}
          name='CancellationComments'
          label='Cancellation Comments'
          isTextArea
          required
          postFieldComponent={
            <SmallMuted>
              {postFieldComments && `**Please note** ${postFieldComments}`}
            </SmallMuted>
          }
        />
      </Row>
      <Alert bsStyle='danger' className='m-b-none'>
        {`You can't undo this. Are you sure you want to proceed?`}
      </Alert>
    </>
  );
};

const CancelledUi = () => (
  <WellSmall className='text-success m-b-none'>
    <Icon icon='fa-check' /> Shift cancelled!
  </WellSmall>
);

const ModalSaveButton = (props) => {
  const isDisabled = props.isCancelling || props.isCancelled;
  const buttonTitle = props.isCancelling
    ? 'Cancelling...'
    : props.isCancelled
    ? 'Shift Cancelled'
    : 'Yes, Cancel Shift';
  return (
    <Button
      bsStyle='danger'
      onClick={props.onClick}
      disabled={isDisabled}
      bsSize='sm'
    >
      <Icon icon='fa-check' isSpinning={props.isCancelling} /> {buttonTitle}
    </Button>
  );
};
