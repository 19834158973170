import { Component } from 'react';
import { Alert, Button, Modal, Row } from 'react-bootstrap';
import $ from 'jquery';
import moment from 'moment';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall, SpinIcon } from 'pages/Utils/react-utils';
import { getSiteAssessmentAddNewConfig, saveSiteAssessment } from 'utils/api';

export class AddSiteAssessmentButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };

    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  handleShowModalClick() {
    this.setState({ showModal: true });
  }

  handleCloseModalClick() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <>
        <Button
          bsSize='sm'
          bsStyle='primary'
          block
          onClick={this.handleShowModalClick}
          style={this.props.style}
        >
          <Icon icon='fa-clipboard-user' /> Add Site Assessment
        </Button>
        <SiteAssessmentModal
          {...this.state}
          {...this.props}
          handleCloseModalClick={this.handleCloseModalClick}
        />
      </>
    );
  }
}

class SiteAssessmentModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();

    this.onEntered = this.onEntered.bind(this);
    this.onExited = this.onExited.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  defaultState() {
    return {
      defaultWorkerId: this.props.workerId || null,
      defaultHirerId: this.props.hirerId || null,
      isSaving: false,
      isGettingConfig: false,
      hasFinishedGettingConfig: false,
      configuration: null,
    };
  }

  async onEntered() {
    const me = this;
    me.setState({ isSaved: false, isGettingConfig: true, configuration: null });

    const data = await getSiteAssessmentAddNewConfig();
    AppUtils.handleAjaxDone(data, () => {
      if (data.configuration) {
        me.setState({ configuration: data.configuration });
      } else {
        throw new Error('Could not get configuration');
      }
    });
    me.setState({ isGettingConfig: false, hasFinishedGettingConfig: true });
  }

  onExited() {
    this.setState(this.defaultState());
  }

  async handleSubmitClick() {
    const $modal = $('.modal');
    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    const model = AppUtils.getDataModel($modal);

    this.setState({ isSaving: true });
    const data = await saveSiteAssessment(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Site Assessment saved');
      AppUtils.publish(AppUtils.Events.SiteAssessmentSaved, data);
      this.props.handleCloseModalClick();
    });
    this.setState({ isSaving: false });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.showModal}
        onHide={this.props.handleCloseModalClick}
        onEntered={this.onEntered}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Site Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.state} {...this.props} />
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize='sm' onClick={this.props.handleCloseModalClick}>
            <i className='fas fa-times'></i> Cancel
          </Button>
          <ModalSaveButton
            {...this.state}
            {...this.props}
            onClick={this.handleSubmitClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = (props) => {
  const {
    isGettingConfig,
    configuration,
    hasFinishedGettingConfig,
    defaultWorkerId,
    defaultHirerId,
    isSaved,
  } = props;

  if (isGettingConfig) {
    return (
      <WellSmall className='m-b-none'>
        <SpinIcon /> Getting configuration options...
      </WellSmall>
    );
  }
  if (!configuration && hasFinishedGettingConfig) {
    return (
      <Alert bsStyle='danger' className='m-b-none'>
        <Icon icon='fa-times' /> Could not get configuration options
      </Alert>
    );
  }
  if (!configuration) {
    return null;
  }
  if (isSaved) {
    return <SavedUi isSaved={isSaved} />;
  }
  return (
    <>
      <Row>
        <Field.InCol
          columns={12}
          name='WorkerId'
          label='Worker'
          value={defaultWorkerId}
          options={props.configuration.workerOptions}
          isSelect
          required
        />
      </Row>
      <Row>
        <Field.InCol
          columns={12}
          name='HirerId'
          label='Customer'
          value={defaultHirerId}
          options={props.configuration.hirerOptions}
          isSelect
          required
        />
      </Row>
      <Row>
        <DatePicker
          columns={12}
          name='AssessmentDate'
          label='Assessment Date'
          value={moment().format('DD/MM/YYYY')}
          required
        />
      </Row>
    </>
  );
};

const SavedUi = ({ isSaved }) =>
  !isSaved ? null : (
    <WellSmall className='text-success m-b-none'>
      <Icon icon='fa-check' /> Assessment saved!
    </WellSmall>
  );

const ModalSaveButton = (props) => (
  <Button
    bsStyle='success'
    bsSize='sm'
    onClick={props.onClick}
    disabled={props.isSaving || !props.configuration}
  >
    <Icon icon='fa-clipboard-user' isSpinning={props.isSaving} />{' '}
    {props.isSaving ? 'Adding...' : 'Add Site Assessment'}
  </Button>
);
