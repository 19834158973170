import { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { AppUtils } from 'pages/Utils/app-utils';
import { ListViewUtils } from 'pages/Utils/list-view-utils';
import { WellSmall } from 'pages/Utils/react-utils';
import { WorkerFeedback, WorkerImage } from '../../Worker/WorkerUtils';
import { ShiftUtils } from '../ShiftUtils';
import { AcceptButton } from './AcceptButton';

export const ApplicantListView = (props) => {
  if (props.record.isPreAllocated) {
    return null;
  }
  if (props.applicants.length <= 0) {
    return <WellSmall className='m-b-none'>No Applicants Found.</WellSmall>;
  }
  return (
    <div>
      <Table {...props} />
    </div>
  );
};

const tableId = 'shift-applicants-table';
class Table extends Component {
  componentDidMount() {
    this.initDataTables();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.lastGet !== this.props.lastGet) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillUpdate() {
    this.destroyDataTables();
  }

  componentDidUpdate() {
    this.initDataTables();
  }

  componentWillUnmount() {
    this.destroyDataTables();
  }

  initDataTables() {
    if (this.props.applicants.length > 0) {
      ListViewUtils.init(tableId);
    }
  }

  destroyDataTables() {
    const $table = $('#' + tableId);
    if ($table.length) {
      $table.DataTable().destroy();
    }
  }

  render() {
    return (
      <table
        className='table table-condensed table-striped table-hover'
        id={tableId}
        data-order='[[ 1, "asc" ]]'
      >
        <thead>
          <tr>
            <th />
            <th>Who</th>
            <th>Applied</th>
            <th className='no-sort text-right'></th>
          </tr>
        </thead>
        <tbody>
          {this.props.applicants.map((item, i) => (
            <TableRow
              key={i}
              shift={this.props.record}
              record={item}
              handleAcceptModalShowClick={this.props.handleAcceptModalShowClick}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

const TableRow = (props) => {
  const { record, shift } = props;

  const acceptButtonOrStatus =
    (record.status === ShiftUtils.ApplicantStatus.Applied &&
      record.workerRequiresSiteAssessment) ||
    shift.status !== ShiftUtils.Status.Open ? (
      <StatusBadge record={record} />
    ) : record.status === ShiftUtils.ApplicantStatus.Applied ? (
      <AcceptButton {...props} />
    ) : (
      <StatusBadge record={record} />
    );

  return (
    <tr>
      <td>
        {record.workerHasImage && (
          <WorkerImage
            workerId={record.workerId}
            firstName={record.firstName}
            surname={record.surname}
            mobileNumber={record.mobileNumber}
          />
        )}
      </td>
      <td>
        <Link to={`Worker/Profile/${record.workerId}`} target='_blank'>
          {record.firstName} {record.surname}
        </Link>{' '}
        <WorkerFeedback record={record} />
      </td>
      <td data-order={record.createdDateTime}>
        {AppUtils.formatDateTimeUtc(record.createdDateTime)}
      </td>
      <td className='text-right'>{acceptButtonOrStatus}</td>
    </tr>
  );
};

const StatusBadge = ({ record }) => {
  const badgeClass =
    record.status === ShiftUtils.ApplicantStatus.Accepted
      ? 'success'
      : record.workerRequiresSiteAssessment
      ? 'danger'
      : record.status === ShiftUtils.ApplicantStatus.Rejected
      ? 'danger'
      : '';

  return (
    <span className={`badge${badgeClass ? ` badge-${badgeClass}` : ''}`}>
      {record.status === ShiftUtils.ApplicantStatus.Applied &&
      record.workerRequiresSiteAssessment
        ? 'Site Assessment Required'
        : record.status}
    </span>
  );
};
