import { Field } from 'pages/Utils/react-utils';
import { useShiftConfigContext } from '../ShiftConfigContextProvider';

export const TimesRoundingTypeField = ({ columns }) => {
  const { record, configuration, disabled } = useShiftConfigContext();
  return (
    <Field.InCol
      columns={columns ?? 4}
      name='ShiftTimesRoundingType'
      label='Times Rounding Type'
      value={record.shiftTimesRoundingType}
      isSelect
      options={configuration.shiftTimesRoundingTypeOptions}
      disabled={disabled}
      tooltip='When the shift payment calculations take place, do you want to perform calculations based on the exact approved times or do you want to round the approved times to the nearest 15 minutes?'
    />
  );
};

export const OvernightAllowanceField = () => {
  // const { isHgvShift, shiftFinance, disabled } = useShiftConfigContext();
  // if (!isHgvShift) {
  //   return null;
  // }

  // Not being used for anything at the moment
  return null;

  // return (
  //   <Field.InCol
  //     columns={columns ?? 4}
  //     name='OvernightAllowance'
  //     label='Overnight Allowance'
  //     value={AppUtils.formatNumber(shiftFinance.overnightAllowance)}
  //     maxLength='9'
  //     isNumber
  //     addOnPre='£'
  //     disabled={disabled}
  //   />
  // );
};
