import { Alert } from 'react-bootstrap';
import { useTrailerOptions, useVehicleOptions } from 'hooks/queries';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { ShiftPlanItem, ShiftPlanItemShift } from 'types/ShiftPlanningTypes';
import { formatDate, dateFormats } from 'utils';
import { ShiftSummaryRow } from './common';

type ShiftSummaryProps = {
  shiftToHandle: ShiftPlanItemShift;
  shiftPlanItemToHandle: ShiftPlanItem;
};

export const ShiftSummary = ({
  shiftToHandle,
  shiftPlanItemToHandle,
}: ShiftSummaryProps) => {
  const { getVehicleReg } = useVehicleOptions();
  const { getTrailerRef } = useTrailerOptions();
  return (
    <>
      <ShiftSummaryRow label='Who' value={shiftToHandle.workerName} />

      <ShiftSummaryRow label='Shift' value={shiftPlanItemToHandle.name} />

      <ShiftSummaryRow
        label='Date'
        value={formatDate(shiftToHandle.shiftDate, dateFormats.niceShortDay)}
      />

      <ShiftSummaryRow
        label='Time'
        value={
          shiftToHandle.isShiftTimeConfirmed
            ? shiftToHandle.shiftTime.substring(0, 5)
            : 'TBC'
        }
      />

      {shiftToHandle.vehicleId ? (
        <>
          <ShiftSummaryRow
            label='Vehicle'
            value={getVehicleReg(shiftToHandle.vehicleId)}
          />
          {shiftToHandle.vehicleChangedWhileInProgress ? (
            <ShiftSummaryRow
              label=''
              value={
                <Alert
                  bsStyle='warning'
                  style={{ padding: '3px 5px', marginBottom: '10px' }}
                >
                  Vehicle was changed while the shift was in progress.
                </Alert>
              }
            />
          ) : null}
        </>
      ) : null}

      {shiftToHandle.trailerIds && shiftToHandle.trailerIds.length
        ? shiftToHandle.trailerIds.map((trailerId) => (
            <ShiftSummaryRow
              key={trailerId}
              label='Trailer'
              value={getTrailerRef(trailerId)}
            />
          ))
        : null}

      <ShiftSummaryRow
        label='Status'
        value={
          shiftToHandle.status === ShiftUtils.Status.PreAllocated
            ? 'Awaiting Acceptance'
            : shiftToHandle.status
        }
      />
    </>
  );
};
