import { Icon } from 'pages/Utils/react-utils';

export const UnauthorisedAlert = () => {
  return (
    <div className='alert alert-danger'>
      <h4>
        <Icon icon='fa-fw fa-exclamation-triangle' /> Unauthorised
      </h4>
      <p>You do not have permission.</p>
    </div>
  );
};
