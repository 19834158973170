import { Component } from 'react';
import { Col, Row, Tab, Tabs, Well } from 'react-bootstrap';
import { DashboardPanel, SpinIcon } from 'pages/Utils/react-utils';
import { getWorkerAdminConfig } from 'utils/api';
import { AddSiteAssessmentButton } from '../SiteAssessment/AddSiteAssessmentButton';
import { AddWorkerButton } from './Buttons/AddWorkerButton';
import WorkerListViewActive from './WorkerListViewActive';
import { WorkerListViewPending } from './WorkerListViewPending';
import { WorkerListViewRejected } from './WorkerListViewRejected';

class WorkerAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isGettingConfig: false,
      configuration: null,
      activeTab: 1,
    };

    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  componentDidMount() {
    this.getConfig();
  }

  async getConfig() {
    this.setState({ isGettingConfig: true });
    const data = await getWorkerAdminConfig();
    if (data.configuration) {
      this.setState({ configuration: data.configuration });
    }
    this.setState({ isGettingConfig: false });
  }

  handleTabSelect(tabToMakeActive) {
    this.setState({ activeTab: tabToMakeActive });
  }

  render() {
    if (this.state.isGettingConfig) {
      return <GettingConfigMsg {...this.state} />;
    }

    if (!this.state.configuration) {
      return null;
    }

    return (
      <>
        <Stats {...this.state} />
        <TabsMain {...this.state} handleTabSelect={this.handleTabSelect} />
      </>
    );
  }
}

const Stats = ({ configuration }) => {
  return (
    <Row>
      <Col sm={8} md={9}>
        <Row>
          <Col sm={6}>
            <DashboardPanel
              title='Active Workers'
              content={
                <h1 className='no-margins'>
                  {configuration.activeWorkerCount}
                </h1>
              }
            />
          </Col>
          <Col sm={6}>
            <DashboardPanel
              title='Pending Invitations'
              content={
                <h1 className='no-margins'>
                  {configuration.pendingWorkerCount}
                </h1>
              }
            />
          </Col>
        </Row>
      </Col>
      <Col sm={4} md={3}>
        <DashboardPanel
          noHeader
          content={
            <>
              <AddWorkerButton block isSm />
              <AddSiteAssessmentButton style={{ marginBottom: 0 }} />
            </>
          }
        />
      </Col>
    </Row>
  );
};

const TabsMain = ({ activeTab, handleTabSelect }) => {
  return (
    <div className='tabs-container'>
      <Tabs
        activeKey={activeTab}
        animation={true}
        onSelect={handleTabSelect}
        id='worker-admin-tabs'
        mountOnEnter={true}
      >
        <Tab eventKey={1} title='Active Workers'>
          <ActiveWorkersTab />
        </Tab>
        <Tab eventKey={2} title='Pending Invitations'>
          <PendingTab />
        </Tab>
        <Tab eventKey={3} title='Rejected Invitations'>
          <RejectedTab />
        </Tab>
      </Tabs>
    </div>
  );
};

const GettingConfigMsg = ({ isGettingConfig }) => {
  console.log({ isGettingConfig });
  if (!isGettingConfig) {
    return null;
  }
  return (
    <Well>
      <SpinIcon /> Please wait while the worker admin area is configured...
    </Well>
  );
};

const ActiveWorkersTab = () => {
  return <WorkerListViewActive />;
};

const PendingTab = () => {
  return <WorkerListViewPending />;
};

const RejectedTab = () => {
  return <WorkerListViewRejected />;
};

export default WorkerAdmin;
