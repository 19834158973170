import {
  CalculateLocationDistanceParams,
  CreateLocationParams,
  IGetLocationDistanceResponse,
  IGetLocationsResponse,
  UpdateLocationParams,
} from 'types/LocationTypes';
import { get, post, put } from './apiBase';

const createLocation = (params: CreateLocationParams) => {
  const { hirerId, ...body } = params;
  return post(`Hirer/${hirerId}/Location`, body);
};

const updateLocation = (params: UpdateLocationParams) => {
  const { hirerId, locationId, ...body } = params;
  return put(`Hirer/${hirerId}/Location/${locationId}`, body);
};

const getLocationById = (locationId: string) => {
  return get(`Location/${locationId}`);
};

const getLocationEditConfig = () => {
  return get(`Location/EditConfig`);
};

const getLocationsByHirer = ({ hirerId }: { hirerId: string }) => {
  return get<IGetLocationsResponse>(`Hirer/${hirerId}/Location`);
};

const calculateDistanceFromHirerRdc = ({
  hirerId,
  postcode,
}: CalculateLocationDistanceParams) => {
  return get<IGetLocationDistanceResponse>(
    `Hirer/${hirerId}/${postcode}/Distance`
  );
};

export const locationApi = {
  createLocation,
  updateLocation,
  getLocationById,
  getLocationEditConfig,
  getLocationsByHirer,
  calculateDistanceFromHirerRdc,
};
