import { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDeleteInvoiceMutation } from 'hooks/queries';
import { useModal } from 'hooks/useModal';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon } from 'pages/Utils/react-utils';
import { Invoice } from 'types/Invoice';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';

export const useDeleteDialog = () => {
  const [invoice, setInvoice] = useState<Invoice | null>(null);

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  const showDeleteDialog = (invoice: Invoice) => {
    setInvoice(invoice);
    handleModalOpen();
  };

  const renderDeleteDialog = () => {
    if (!invoice) {
      return null;
    }

    return (
      <DeleteDialog
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        onExited={() => setInvoice(null)}
        invoice={invoice}
      />
    );
  };

  return { showDeleteDialog, renderDeleteDialog };
};

const DeleteDialog = ({
  isModalOpen,
  handleModalClose,
  onExited,
  invoice,
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
  onExited?: () => void;
  invoice: Invoice;
}) => {
  const history = useHistory();
  const deleteRequest = useDeleteInvoiceMutation();
  const handleDeleteClick = () => {
    deleteRequest.mutate(
      { invoiceId: invoice.invoiceId },
      {
        onSuccess: () => {
          AppUtils.publish('invoice.deleted');
          AppUtils.displaySuccess('Thanks', 'Invoice deleted.');
          handleModalClose();
          history.push('/Invoice');
        },
      }
    );
  };

  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You are going to delete this Invoice. Any included shifts will be
          returned to the awaiting invoice shifts list.
        </p>

        <Alert bsStyle='info'>
          You will not be able to undo this.{' '}
          <strong>Are you sure you want to proceed?</strong>
        </Alert>

        {deleteRequest.isError ? (
          <ErrorAlertForQueryOrNull
            isError={deleteRequest.isError}
            error={deleteRequest.error}
          />
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>Cancel</Button>
        <Button
          bsStyle='danger'
          onClick={handleDeleteClick}
          disabled={deleteRequest.isLoading}
        >
          <Icon icon='fa-times' isSpinning={deleteRequest.isLoading} /> Yes,
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
