import { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Icon } from 'pages/Utils/react-utils';

export const Table = ({ columns, records }) => {
  const memoisedData = useMemo(() => records, [records]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: memoisedData }, useSortBy);

  return (
    // apply the table props
    <table
      className='table table-condensed table-striped table-hover'
      {...getTableProps()}
    >
      <thead>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column) => (
                  // Apply the header cell props
                  <th
                    key={column.id}
                    {...column.getHeaderProps({
                      ...column.getSortByToggleProps(),
                      style: { minWidth: column.minWidth, width: column.width },
                    })}
                  >
                    {
                      // Render the header
                      column.render('Header')
                    }
                    <span>
                      {' '}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Icon icon='fa-sort-amount-down' />
                        ) : (
                          <Icon icon='fa-sort-amount-up' />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>
      {/* Apply the table body props */}
      <tbody {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map((row) => {
            // Prepare the row for display
            prepareRow(row);

            return (
              // Apply the row props
              <tr key={row.id} {...row.getRowProps()}>
                {
                  // Loop over the rows cells
                  row.cells.map((cell) => {
                    // Apply the cell props
                    return (
                      <td
                        key={cell.getCellProps().key}
                        {...{
                          ...cell.getCellProps({
                            style: {
                              minWidth: cell.column.minWidth,
                              width: cell.column.width,
                            },
                          }),
                          className: cell.column.className,
                        }}
                      >
                        {
                          // Render the cell contents
                          cell.render('Cell')
                        }
                      </td>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};
