import { Component, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import $ from 'jquery';
import { RadioButton } from 'pages/Utils/RadioButton';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, InputField, SmallMuted } from 'pages/Utils/react-utils';
import { saveWorkerAgencyLink } from 'utils/api';
import { WorkerAdminUtils } from './WorkerAdminUtils';

export class EditWorkerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      isSaved: false,
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExited() {
    this.setState({ isSaved: false });
  }

  async handleSaveClick() {
    const $modal = $('.modal');

    let model = AppUtils.getDataModel($modal);
    model['WorkerAgencyLinkId'] = this.props.recordToEdit.workerAgencyLinkId;

    if (model.PayType === WorkerAdminUtils.PayType.Salary) {
      if (!model.AnnualSalary) {
        AppUtils.displayError('Error', 'Annual Salary is required');
        return;
      }
    }

    this.setState({ isSaving: true });
    AppUtils.clearNotifications();
    const data = await saveWorkerAgencyLink(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Worker Saved');
      this.setState({ isSaved: true });
      AppUtils.publish(WorkerAdminUtils.Events.WorkerSaved, data);
      this.props.handleCloseModalClick();
    });
    this.setState({ isSaving: false });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={!!this.props.recordToEdit}
        onHide={this.props.handleCloseModalClick}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Worker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.props} {...this.state} />
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize='sm' onClick={this.props.handleCloseModalClick}>
            <i className='fa fa-times'></i> Cancel
          </Button>
          <ModalConfirmButton
            {...this.state}
            handleSaveClick={this.handleSaveClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = ({ recordToEdit: record }) => {
  const [payType, setPayType] = useState(record?.payType);

  if (!record) {
    return null;
  }

  const handleChange = (event) => {
    setPayType(event.target.value);
  };

  return (
    <>
      <EditRow label='Worker' value={`${record.firstName} ${record.surname}`} />
      <EditRow
        label='Pay Type'
        value={
          <RadioButton
            name='PayType'
            options={[
              WorkerAdminUtils.PayType.PAYE,
              WorkerAdminUtils.PayType.Salary,
              WorkerAdminUtils.PayType.AgencyWorker,
              WorkerAdminUtils.PayType.Contractor,
            ]}
            value={payType}
            onChange={handleChange}
            noFormGroup
          />
        }
        noSeparator={payType === WorkerAdminUtils.PayType.AgencyWorker}
      />
      <EditRow
        label='Holiday Pay Type'
        value={
          <RadioButton
            name='HolidayType'
            options={[
              { value: 'Paid', text: 'Paid' },
              { value: 'Accrued', text: 'Accrued' },
            ]}
            value={record.holidayType}
            noFormGroup
          />
        }
        hide={payType !== WorkerAdminUtils.PayType.PAYE}
      />
      <EditRow
        label='Pension Contributions?'
        value={
          <RadioButton
            name='ArePensionContributionsRequired'
            options={[
              { value: true, text: 'Yes' },
              { value: false, text: 'No' },
            ]}
            value={record.arePensionContributionsRequired}
            noFormGroup
          />
        }
        noSeparator={payType !== WorkerAdminUtils.PayType.Salary}
        hide={
          ![
            WorkerAdminUtils.PayType.PAYE,
            WorkerAdminUtils.PayType.Salary,
          ].includes(payType)
        }
      />
      <EditRow
        label='Annual Salary'
        value={
          <InputField
            name='AnnualSalary'
            value={record.annualSalary}
            noFormGroup
            noLabel
          />
        }
        noSeparator
        hide={payType !== WorkerAdminUtils.PayType.Salary}
      />
    </>
  );
};

const EditRow = ({ label, value, hide, noSeparator }) => {
  if (hide) {
    return null;
  }
  return (
    <>
      <Row>
        <Col sm={4} className='text-right'>
          <SmallMuted>{label}</SmallMuted>
        </Col>
        <Col sm={8}>{value}</Col>
      </Row>
      {noSeparator === true ? null : <hr />}
    </>
  );
};

const ModalConfirmButton = (props) => {
  const isDisabled = props.isSaving || props.isSaved;
  const buttonTitle = props.isSaving
    ? 'Saving...'
    : props.isSaved
    ? 'Saved'
    : 'Save';
  return (
    <Button
      bsStyle='success'
      bsSize='sm'
      onClick={props.handleSaveClick}
      disabled={isDisabled}
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
