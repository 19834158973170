import { Chip } from 'components';
import { InvoiceStatus } from 'types/Invoice';

export const StatusChip = ({ status }: { status: InvoiceStatus }) => {
  return (
    <Chip
      value={status}
      variant={
        status === InvoiceStatus.Sent ||
        status === InvoiceStatus.AwaitingApproval
          ? 'info'
          : status === InvoiceStatus.Paid || status === InvoiceStatus.Approved
          ? 'success'
          : status === InvoiceStatus.Draft
          ? 'default'
          : 'danger'
      }
    />
  );
};
