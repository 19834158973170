import { AppUtils } from 'pages/Utils/app-utils';
import qs from 'qs';
import {
  ReportQueryParams,
  ProfitabilityReportQueryResponse,
} from 'types/ReportingTypes';
import { NewShiftConfig } from 'types/ShiftConfigTypes';
import { GetAddToPlanConfigResponse } from 'types/ShiftPlanningTypes';
import {
  AddNoteToShiftParams,
  AddPaymentAdjustmentToShiftParams,
  AuthoriseSingleShiftParams,
  CompleteShiftParams,
  DailyViewItem,
  GetApprovedShiftsParams,
  GetDailyViewQueryParams,
  GetPaidShiftsParams,
  GetPayrollReportParams,
  GetPayrollCsvParams,
  GetUnpaidShiftsParams,
  PaidShift,
  PayrollReportShift,
  Shift,
  ShiftNote,
  ShiftPaymentAdjustment,
  UnpaidShift,
  UpdateVehicleParams,
  AddShiftSegmentParams,
  ShiftSegment,
  DeleteShiftSegmentParams,
  AddRdcShiftSegmentParams,
  MileageUploadResponse,
  PushShiftConfigToShiftParams,
  TimesUploadResponse,
  ProcessFinanceForApprovedShiftsResult,
  UnpaidContractorShift,
  LinkShiftParams,
  LinkedShift,
  UpdateShiftChargeRateParams,
  MarkShiftsAsPaidParams,
  ShiftTrailer,
  AddTrailerToShiftParams,
  RemoveTrailerFromShiftParams,
  ShiftFile,
  ShiftNoteType,
  CalculatorParams,
  CalculationResponse,
} from 'types/ShiftTypes';
import axiosInt, { del, get, post, put } from './apiBase';

const getAddShiftsToPlanConfig = (hirerId: string) => {
  return get<GetAddToPlanConfigResponse>(`Shift/AddToPlanConfig/${hirerId}`);
};

const getNewShiftConfigV2 = async () => {
  return get<NewShiftConfig>('Shift/v2/Config');
};

const addNoteToShift = (params: AddNoteToShiftParams) => {
  const { shiftId, ...body } = params;
  return post(`Shift/${shiftId}/Note`, {
    ...body,
  });
};

const addPaymentAdjustmentToShift = (
  params: AddPaymentAdjustmentToShiftParams
) => {
  const { reason, amount, shiftId } = params;
  return post(`Shift/${shiftId}/PaymentAdjustment`, {
    reason,
    amount,
  });
};

const completeShift = (params: CompleteShiftParams) => {
  const { shiftId } = params;
  return post(`Shift/${shiftId}/Complete`);
};

const getShiftNotes = async (shiftId: string, type: ShiftNoteType) => {
  return get<ShiftNote[]>(`Shift/${shiftId}/Notes?shiftNoteType=${type}`);
};

const getShiftFiles = async (shiftId: string) => {
  return get<ShiftFile[]>(`Shift/${shiftId}/Files`);
};

const getShiftFile = async (shiftId: string, shiftFileId: string) => {
  const url = `Shift/${shiftId}/File/${shiftFileId}`;

  return axiosInt.get<Blob>(url, {
    responseType: 'blob',
  });
};

const getShiftFilesZip = async (shiftId: string) => {
  const url = `Shift/${shiftId}/File/All`;

  return axiosInt.get<Blob>(url, {
    responseType: 'blob',
  });
};

const addTrailerToShift = (params: AddTrailerToShiftParams) => {
  const { trailerId, shiftId } = params;
  return put(`Shift/${shiftId}/Trailer`, {
    trailerId,
  });
};

const removeTrailerFromShift = (params: RemoveTrailerFromShiftParams) => {
  const { trailerId, shiftId } = params;
  return del(`Shift/${shiftId}/Trailer/${trailerId}`);
};

const getShiftTrailers = async (shiftId: string) => {
  return get<ShiftTrailer[]>(`Shift/${shiftId}/Trailers`);
};

const getLinkedShifts = async (shiftId: string) => {
  return get<LinkedShift[]>(`Shift/${shiftId}/Linked`);
};

const getShiftSegments = async (shiftId: string) => {
  return get<ShiftSegment[]>(`Shift/${shiftId}/ShiftSegments`);
};

const getApprovedShifts = async (params: GetApprovedShiftsParams) => {
  const url = `Shift/GetApproved?${qs.stringify(params)}`;
  return get<{ records: Shift[] }>(url);
};

const getPaidShifts = async (params: GetPaidShiftsParams) => {
  const url = `Shift/Paid?${qs.stringify(params)}`;
  return get<PaidShift[]>(url);
};

const getShiftPaymentAdjustments = async (shiftId: string) => {
  return get<ShiftPaymentAdjustment[]>(`Shift/${shiftId}/PaymentAdjustments`);
};

const updateVehicle = (params: UpdateVehicleParams) => {
  const { shiftId, vehicleId } = params;
  return put(`Shift/${shiftId}/Vehicle`, { vehicleId });
};

const getDailyView = (params: GetDailyViewQueryParams) => {
  const { hirerId, date } = params;
  return get<DailyViewItem[]>(
    `Shift/DailyView/${hirerId}/${AppUtils.getDateString(date, 'YYYY-MM-DD')}`
  );
};

const authorisePayment = (params: AuthoriseSingleShiftParams) => {
  const { shiftId } = params;
  return post(`Shift/${shiftId}/AuthorisePayment`);
};

const getUnpaidShifts = async (params: GetUnpaidShiftsParams) => {
  const url = `Shift/GetUnpaid?${qs.stringify(params)}`;
  return get<{ records: UnpaidShift[] }>(url);
};

const getUnpaidContractorShifts = async (params: GetUnpaidShiftsParams) => {
  const url = `Shift/UnpaidContractorShifts?${qs.stringify(params)}`;
  return get<UnpaidContractorShift[]>(url);
};

const getPayrollReportShifts = async (params: GetPayrollReportParams) => {
  const url = `Shift/PayrollReport?${qs.stringify(params)}`;
  return get<PayrollReportShift[]>(url);
};

const getPayrollCsv = async (params: GetPayrollCsvParams) => {
  const url = `Shift/GetPayrollCsv`;
  return post<Blob>(url, params);
};

const generateFinancials = () => {
  return post<ProcessFinanceForApprovedShiftsResult>(`Shift/ProcessFinancials`);
};

const addShiftSegment = (params: AddShiftSegmentParams) => {
  const { shiftId, locationId } = params;
  return post(`Shift/${shiftId}/ShiftSegment`, {
    locationId,
  });
};

const calculator = (params: CalculatorParams & { postcodes: string[] }) => {
  return post<CalculationResponse>(`Shift/Calculator`, params);
};

const addRdcShiftSegment = (params: AddRdcShiftSegmentParams) => {
  const { shiftId } = params;
  return post(`Shift/${shiftId}/ShiftSegment/Rdc`);
};

const deleteShiftSegment = (params: DeleteShiftSegmentParams) => {
  const { shiftId, shiftSegmentId } = params;
  return del(`Shift/${shiftId}/ShiftSegment/${shiftSegmentId}`);
};

const uploadMileage = (formData: FormData) => {
  return post<MileageUploadResponse>(`Shift/UploadMileage`, formData);
};

const uploadTimes = (formData: FormData) => {
  return post<TimesUploadResponse>(`Shift/UploadTimes`, formData);
};

const getReport = (params: ReportQueryParams) => {
  const url = `Shift/Report?${qs.stringify(params)}`;
  return get<ProfitabilityReportQueryResponse>(url);
};

const getReportData = (params: ReportQueryParams) => {
  const url = `Shift/ReportData?${qs.stringify(params)}`;

  return axiosInt.get<Blob>(url, {
    responseType: 'blob',
  });
};

const pushShiftConfigToShifts = (params: PushShiftConfigToShiftParams) => {
  const { shiftConfigId } = params;
  return post<{ shiftsUpdated: number }>(
    `Shift/PushShiftConfigDataToShifts/${shiftConfigId}`
  );
};

const sendContractorInvoiceEmails = (params: { shiftIds: string[] }) => {
  return post(`Shift/SendContractorInvoiceEmails`, params);
};

const linkShift = (params: LinkShiftParams) => {
  const { shiftId, ...rest } = params;
  return post(`Shift/${shiftId}/Link`, rest);
};

const updateChargeRate = (params: UpdateShiftChargeRateParams) => {
  const { shiftId, ...rest } = params;
  return put(`Shift/${shiftId}/ChargeAmount`, rest);
};

const markShiftsAsPaid = (params: MarkShiftsAsPaidParams) => {
  return post('Shift/MarkAsPaid', params);
};

export const shiftApi = {
  getAddShiftsToPlanConfig,
  getNewShiftConfigV2,
  getShiftNotes,
  getShiftTrailers,
  getLinkedShifts,
  getShiftSegments,
  getApprovedShifts,
  getPaidShifts,
  getShiftPaymentAdjustments,
  addNoteToShift,
  addPaymentAdjustmentToShift,
  updateVehicle,
  getDailyView,
  authorisePayment,
  getUnpaidShifts,
  getUnpaidContractorShifts,
  getPayrollCsv,
  getPayrollReportShifts,
  completeShift,
  generateFinancials,
  addShiftSegment,
  addRdcShiftSegment,
  deleteShiftSegment,
  uploadMileage,
  uploadTimes,
  getReport,
  getReportData,
  pushShiftConfigToShifts,
  sendContractorInvoiceEmails,
  linkShift,
  updateChargeRate,
  markShiftsAsPaid,
  addTrailerToShift,
  removeTrailerFromShift,
  getShiftFiles,
  getShiftFile,
  getShiftFilesZip,
  calculator,
};
