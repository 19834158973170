export const Chip = ({
  value,
  variant = 'default',
  className = '',
}: {
  variant?: 'danger' | 'success' | 'info' | 'default';
  value: string;
  className?: string;
}) => {
  const classes = ['label', `label-${variant}`, className].join(' ');
  return <span className={classes}>{value}</span>;
};
