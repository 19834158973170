import { Alert, Button } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import {
  useAddRdcShiftSegmentMutation,
  useDeleteShiftSegmentMutation,
  useShiftSegmentsQuery,
} from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { Stack } from 'components/Stack';
import './_styles/shiftSegment.scss';

export const ShiftSegmentsList = (props: {
  shiftId: string;
  setIsFormShowing: (val: boolean) => void;
}) => {
  const { shiftId } = props;
  const { data, isSuccess, isLoading } = useShiftSegmentsQuery(shiftId);
  const deleteSegment = useDeleteShiftSegmentMutation();
  const queryClient = useQueryClient();

  const handleDeleteClick = (shiftSegmentId: string) => {
    deleteSegment.mutate(
      { shiftId, shiftSegmentId },
      {
        onSuccess: () => {
          AppUtils.displaySuccess('Success', 'Location deleted');
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ShiftSegments, shiftId],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.VehiclePlanForHirer],
          });
          AppUtils.publish(ShiftUtils.Events.ShiftSegmentDeleted);
        },
      }
    );
  };

  const addRdcSegment = useAddRdcShiftSegmentMutation();
  const handleAddRdcClick = () => {
    addRdcSegment.mutate(
      { shiftId },
      {
        onSuccess: () => {
          props.setIsFormShowing(false);
          AppUtils.displaySuccess('Success', 'RDC added');
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ShiftSegments, shiftId],
          });
          AppUtils.publish(ShiftUtils.Events.ShiftSegmentSaved);
        },
      }
    );
  };

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isSuccess && data.length <= 0) {
    return <Alert>No locations found.</Alert>;
  }

  const finalItem = data[data.length - 1];

  return isSuccess ? (
    <>
      <Stack gap={5}>
        <table className='table table-striped table-hover'>
          <tbody>
            {data.map((segment, i) => {
              return (
                <tr key={segment.shiftSegmentId}>
                  <td>
                    {segment.order} - {segment.name}
                  </td>
                  <td>{segment.postcode}</td>
                  <td>{segment.distanceMiles} miles</td>
                  <td className='text-right'>
                    <Button
                      bsSize='xs'
                      disabled={deleteSegment.isLoading}
                      bsStyle='danger'
                      onClick={() => handleDeleteClick(segment.shiftSegmentId)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2} className='text-right'>
                Total
              </td>
              <td colSpan={2}>
                {AppUtils.formatNumber(
                  data.reduce((prev, curr) => prev + curr.distanceMiles, 0)
                )}{' '}
                miles
              </td>
            </tr>
          </tfoot>
        </table>

        {data.length >= 1 && finalItem && finalItem.name !== 'RDC' ? (
          <Alert bsStyle='info'>
            <Stack gap={10}>
              <div>
                <strong>Hint</strong> - Do you want to add an RDC as the final
                location?
              </div>
              <div>
                <Button
                  bsStyle='primary'
                  bsSize='sm'
                  disabled={addRdcSegment.isLoading}
                  onClick={() => handleAddRdcClick()}
                >
                  <Icon icon='fa-plus' isSpinning={addRdcSegment.isLoading} />{' '}
                  Yes, Add RDC
                </Button>
              </div>

              {addRdcSegment.isError ? (
                <ErrorAlert msg={extractErrorMessages(addRdcSegment.error)} />
              ) : null}
            </Stack>
          </Alert>
        ) : null}

        {deleteSegment.isLoading ? (
          <WellSmall hasSpinner>
            Deleting and re-calculating, please wait a moment
          </WellSmall>
        ) : null}

        {deleteSegment.isError ? (
          <ErrorAlert msg={extractErrorMessages(deleteSegment.error)} />
        ) : null}
      </Stack>
    </>
  ) : null;
};
