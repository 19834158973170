import { Component } from 'react';
import { Alert, Button, Col, Panel, Row } from 'react-bootstrap';
import $ from 'jquery';
import moment from 'moment';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils } from 'pages/Utils/app-utils';
import { ListViewUtils } from 'pages/Utils/list-view-utils';
import { Icon, MapToDiv, SmallMuted, WellSmall } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { getCancelledShifts } from 'utils/api';

export class CancelledShiftsListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGetting: false,
      records: [],
      lastGet: null,
      showModal: false,
      recordToEdit: null,
    };

    this.subscriber = this.subscriber.bind(this);
    this.performSearch = this.performSearch.bind(this);
  }

  componentDidMount() {
    const _me = this;
    this.subscribeToEvents().forEach((e) => {
      PubSub.subscribe(e, _me.subscriber);
    });
  }

  componentWillUnmount() {
    console.log('CancelledShiftsListView.componentWillUnmount');
    this.subscribeToEvents().forEach(function (key) {
      PubSub.unsubscribe(key);
    });
  }

  subscribeToEvents() {
    return [ShiftUtils.Events.ShiftCreated];
  }

  subscriber() {
    this.performSearch();
  }

  setIsGetting(val, lastGet) {
    let newState = { isGetting: val };
    if (lastGet) {
      newState['lastGet'] = lastGet;
    }
    this.setState(newState);
  }

  async performSearch() {
    this.setIsGetting(true);
    const data = await this.getData();
    if (data.status === AppUtils.ApiStatus.Success && data.records) {
      this.setState({ records: data.records });
    }
    this.setIsGetting(false, new Date());

    // _me
    //   .getData()
    //   .done(function (data) {
    //     if (data.status === AppUtils.ApiStatus.Success && data.records) {
    //       _me.setState({ records: data.records });
    //     }
    //   })
    //   .always(function () {
    //     _me.setIsGetting(false, new Date());
    //   });
  }

  getDataModel() {
    return AppUtils.getDataModel($('#CancelledFilterContainer'));
  }

  getData() {
    return getCancelledShifts(this.getDataModel());

    // return $.ajax({
    //   url: AppSettings.baseUrl + "Shift/GetCancelled",
    //   data: this.getDataModel(),
    // });
  }

  render() {
    return (
      <>
        <Alert bsStyle='danger'>
          <Icon icon='fa-times' /> Shifts which have been Cancelled.
        </Alert>
        <Filters {...this.state} handleSearchClick={this.performSearch} />
        <TableContainer {...this.props} {...this.state} />
      </>
    );
  }
}

const Filters = (props) => {
  return (
    <Panel id='CancelledFilterContainer'>
      <Panel.Body>
        <Row>
          <DatePicker
            columns={4}
            name='DateFrom'
            label='Shift Date From'
            value={AppUtils.getDateString(moment().subtract(30, 'days'))}
            required
            notInFormGroup
          />
          <DatePicker
            columns={4}
            name='DateTo'
            label='Shift Date To'
            value={AppUtils.getDateString(moment())}
            required
            notInFormGroup
          />
        </Row>
      </Panel.Body>
      <Panel.Footer className='text-right'>
        <SearchButton
          isGetting={props.isGetting}
          handleSearchClick={props.handleSearchClick}
        />
      </Panel.Footer>
    </Panel>
  );
};

const SearchButton = ({ isGetting, handleSearchClick }) => {
  return (
    <Button
      bsStyle='primary'
      bsSize='sm'
      onClick={handleSearchClick}
      disabled={isGetting}
    >
      <Icon icon='fa-search' isSpinning={isGetting} />{' '}
      {isGetting ? 'Searching' : 'Search'}
    </Button>
  );
};

const TableContainer = (props) => {
  if (props.isGetting) {
    return (
      <WellSmall className='m-b-none' isSpinning>
        Searching for Cancelled Shifts...
      </WellSmall>
    );
  }
  if (props.records.length <= 0 && !props.lastGet) {
    return null;
  }
  if (props.records.length <= 0) {
    return (
      <WellSmall className='m-b-none'>No Cancelled Shifts found.</WellSmall>
    );
  }
  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col sm={6}>Results</Col>
        </Row>
      </Panel.Heading>
      <Panel.Body>
        <Table {...props} />
      </Panel.Body>
    </Panel>
  );
};

const tableId = 'cancelled-shifts-table';
class Table extends Component {
  componentDidMount() {
    this.initDataTables();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.lastGet !== this.props.lastGet) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillUpdate() {
    this.destroyDataTables();
  }

  componentDidUpdate() {
    this.initDataTables();
  }

  componentWillUnmount() {
    this.destroyDataTables();
  }

  initDataTables() {
    if (this.props.records.length > 0) {
      ListViewUtils.init(tableId);
    }
  }

  destroyDataTables() {
    const $table = $('#' + tableId);
    if ($table.length) {
      $table.DataTable().destroy();
    }
  }

  render() {
    return (
      <table
        className='table table-condensed table-striped table-hover'
        id={tableId}
        data-order='[[ 1, "asc" ]]'
      >
        <thead>
          <tr>
            <th>Details</th>
            <th>When</th>
            <th>Worker</th>
            <th>Type</th>
            <th>Cancelled</th>
            <th className='no-sort' />
          </tr>
        </thead>
        <tbody>
          {this.props.records.map((item, i) => (
            <TableRow
              key={i}
              record={item}
              setDisplayShiftId={this.props.setDisplayShiftId}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

const TableRow = (props) => {
  const { record, setDisplayShiftId } = props;
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  return (
    <tr>
      <td>
        <MapToDiv>{[record.companyName, record.name]}</MapToDiv>
      </td>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime)}
      </td>
      <td
        data-order={record.workerSurname}
      >{`${record.workerFirstName} ${record.workerSurname}`}</td>
      <td>{record.shiftPaymentType}</td>
      <td data-order={record.cancelledDateTime}>
        {AppUtils.formatDateTimeUtc(record.cancelledDateTime)}
        <div>
          <SmallMuted>by {record.cancelledBy}</SmallMuted>
        </div>
      </td>
      <td className='text-right'>
        <Button
          bsStyle='primary'
          bsSize='xs'
          onClick={() => setDisplayShiftId(record.shiftId)}
        >
          Open &raquo;
        </Button>
      </td>
    </tr>
  );
};
