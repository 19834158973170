import { Row } from 'react-bootstrap';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field } from 'pages/Utils/react-utils';
import { useShiftConfigContext } from './ShiftConfigContextProvider';
import { OvernightAllowanceField, TimesRoundingTypeField } from './common';

export const RatesTabFixed = () => {
  const { shiftFinance, configuration, disabled } = useShiftConfigContext();
  const fixedLengthOptions = [];
  for (let i = 1, len = 24; i < len; i++) {
    fixedLengthOptions.push(i);
  }
  return (
    <>
      <h2>Additional Fixed Payment Config</h2>
      <hr />
      <Row>
        <Field.InCol
          columns={4}
          name='FixedShiftLengthHours'
          label='Expected Fixed Shift Length (hours)'
          value={shiftFinance.fixedShiftLengthHours}
          isSelect
          options={fixedLengthOptions}
          disabled={disabled}
          tooltip='This is for information only and is not used in any calculations. It is used to display the estimated length of the shift to the workers.'
        />
        <TimesRoundingTypeField columns={4} />
        <Field.InCol
          columns={4}
          name='OvertimeRateFixedShift'
          value={AppUtils.formatNumber(shiftFinance.overtimeRateFixedShift)}
          maxLength='5'
          isNumber
          label='Overtime Rate'
          addOnPre='£'
          addOnPost='per hour'
          disabled={disabled}
          tooltip={
            'This is the overtime rate for this fixed shift. This is only used if the "Is Overtime Paid?" option is checked on the Rules tab and if the length of the shift exceeds the "Overtime paid after" value  on the Rules tab.'
          }
        />
        <Field.InCol
          columns={4}
          name='FixedShiftOvertimeType'
          label='Overtime Type'
          value={shiftFinance.fixedShiftOvertimeType}
          isSelect
          options={configuration.fixedShiftOvertimeTypeOptions}
          disabled={disabled}
          tooltip='If this is Per Minute then the worker is paid per minute of overtime. If this is Round Up then the worker is paid for the whole hour if they work any part of the hour.'
        />
        <OvernightAllowanceField />
      </Row>
    </>
  );
};
