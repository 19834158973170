import { useQuery } from '@tanstack/react-query';
import useSubscription from 'hooks/useSubscription';
import { AppUtils } from '../pages/Utils/app-utils';
import { apiRequest } from './apiUtils';

/**** USERS ****/

// Fetch user data (hook)
// This is called automatically by auth.js and merged into auth.user
export function useUser(userId) {
  const queryResult = useQuery(
    ['user', { uid: userId }],
    () => apiRequest(`user`),
    {
      enabled: !!userId,
    }
  );
  const { refetch } = queryResult;
  useSubscription([AppUtils.Events.AppLogoUpdated], refetch);
  return queryResult;
}
