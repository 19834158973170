export const QueryKeys = {
  DailyView: 'DailyView',
  ShiftPlanForHirer: 'ShiftPlanForHirer',
  VehiclePlanForHirer: 'VehiclePlanForHirer',
  ShiftNotes: 'ShiftNotes',
  ShiftFiles: 'ShiftFiles',
  ShiftFile: 'ShiftFile',
  ShiftTrailers: 'ShiftTrailers',
  LinkedShifts: 'LinkedShifts',
  ShiftSegments: 'ShiftSegments',
  ShiftPaymentAdjustments: 'PaymentAdjustments',
  NewShiftConfig: 'NewShiftConfig',
  PostShiftsConfig: 'PostShiftsConfig',
  AcceptShiftsFromPlanConfig: 'AcceptShiftsFromPlanConfig',
  ShiftEdit: 'ShiftEdit',
  ApprovedShifts: 'ApprovedShifts',
  PaidShifts: 'PaidShifts',
  UnpaidShifts: 'UnpaidShifts',
  UnpaidContractorShifts: 'UnpaidContractorShifts',
  PayrollReportShifts: 'PayrollReportShifts',
  UserPermissions: 'UserPermissions',
  AgencyUsers: 'AgencyUsers',
  ShiftPlanExcelDownload: 'ShiftPlanExcelDownload',
  ShiftPlanExcelDailyDownload: 'ShiftPlanExcelDailyDownload',
  ProfitabilityReport: 'ProfitabilityReport',
  VehicleReport: 'VehicleReport',
  VehicleReportData: 'VehicleReportData',
  ProfitReportData: 'ProfitReportData',
  GetInvoice: 'GetInvoice',
  GetInvoiceDownload: 'GetInvoiceDownload',
  GetInvoices: 'GetInvoices',
  CalculatorLocations: 'CalculatorLocations',
} as const;
