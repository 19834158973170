import { Button } from 'react-bootstrap';
import { useModal } from 'hooks/useModal';
import { TrailerModal } from '../TrailerModal';

export const CreateNewTrailerButton = () => {
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const handleShowModalClick = () => {
    handleModalOpen();
  };

  const handleCloseModalClick = () => {
    handleModalClose();
  };

  return (
    <div>
      <Button
        bsStyle='primary'
        onClick={handleShowModalClick}
        style={{ marginBottom: 0 }}
      >
        {' '}
        <i className='fas fa-forklift' /> Add New Trailer
      </Button>

      {isModalOpen ? (
        <TrailerModal
          handleCloseModalClick={handleCloseModalClick}
          showModal={isModalOpen}
        />
      ) : null}
    </div>
  );
};
