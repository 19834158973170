import { Component, useState } from 'react';
import { Alert, Col, Row, Button } from 'react-bootstrap';
import { getAgencyHomeData } from 'utils/api';
import { useAuth } from 'utils/auth';
import configureSubscriptions from 'utils/configureSubscriptions';
import { CreateNewShiftButton } from '../Shift/Buttons/CreateNewShiftButton';
import { OpenShiftsListView } from '../Shift/OpenShiftsListView';
import { ShiftUtils } from '../Shift/ShiftUtils';
import { ContentContainer, ShiftContainer } from '../Shift/ShiftViewer';
import { CreateNewShiftConfigButton } from '../ShiftConfig/Buttons/CreateNewShiftConfigButton';
import { AppUtils } from '../Utils/app-utils';
import {
  DashboardPanel,
  Icon,
  SpinIcon,
  WellSmall,
  SmallMuted,
  AlertWithIcon,
} from '../Utils/react-utils';
import { AgencyUtils } from './AgencyUtils';
import { ChangeOnCallUserButton } from './ChangeOnCallUserButton';
import { RunningForTooLongListView } from './Shift/RunningForTooLongListView';

const _uiTypes = {
  Home: 'Home',
  ShiftsInProgressForTooLong: 'ShiftsInProgressForTooLong',
};

class AgencyHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uiType: _uiTypes.Home,
      isGettingAgencyHomeModel: false,
      record: null,
      cleanupSubscriptions: null,
    };

    this.subscriber = this.subscriber.bind(this);
    this.getData = this.getData.bind(this);
    this.changeUi = this.changeUi.bind(this);
  }

  componentDidMount() {
    this.getData();

    const _me = this;
    const { cleanup } = configureSubscriptions(
      _me.subscribeToEvents(),
      _me.subscriber
    );
    this.setState({ cleanupSubscriptions: cleanup });
  }

  componentWillUnmount() {
    const { cleanupSubscriptions } = this.state;
    if (cleanupSubscriptions) {
      cleanupSubscriptions();
    }
  }

  subscribeToEvents() {
    return [
      ShiftUtils.Events.ShiftCreated,
      AgencyUtils.Events.OnCallUserChanged,
    ];
  }

  subscriber() {
    this.getData();
  }

  setIsGetting(val) {
    this.setState({ isGettingAgencyHomeModel: val });
  }

  async getData() {
    this.setIsGetting(true);
    const data = await getAgencyHomeData();
    this.setState({ record: data.record });
    this.setIsGetting(false);
  }

  changeUi(uiType = _uiTypes.Home) {
    this.setState({ uiType });
  }

  render() {
    return <HomeMain {...this.state} changeUi={this.changeUi} />;
  }
}

const HomeMain = (props) => {
  const [displayShiftId, setDisplayShiftId] = useState(null);

  const auth = useAuth();

  if (props.isGettingAgencyHomeModel && !props.record) {
    return (
      <WellSmall className='m-t'>
        <SpinIcon /> Please wait while the home page is configured...
      </WellSmall>
    );
  }
  if (!props.record) {
    return (
      <Alert bsStyle='danger'>
        <Icon icon='fa-frown' /> Could not configure home page for user:{' '}
        {auth.user.record.displayName}
      </Alert>
    );
  }

  const { record, uiType } = props;
  if (!record) {
    return null;
  }
  return (
    <>
      <ShiftContainer
        displayShiftId={displayShiftId}
        setDisplayShiftId={setDisplayShiftId}
      />
      <ContentContainer displayShiftId={displayShiftId}>
        <Row
          className='border-bottom white-bg dashboard-header m-b'
          style={{ marginTop: '-15px' }}
        >
          <Col sm={8} md={9} lg={10}>
            <h2>{record.agencyName}</h2>
            <p>
              <span className='text-primary'>
                {record.onCallUserName || `No-one`}
              </span>{' '}
              is on call
            </p>
            <ChangeOnCallUserButton
              onCallUserAccountId={record.onCallUserAccountId}
            />
          </Col>
          <Col sm={4} md={3} lg={2} className='text-right'>
            <HomeButtons />
          </Col>
        </Row>

        {uiType === _uiTypes.Home && (
          <MainUi {...props} setDisplayShiftId={setDisplayShiftId} />
        )}
        {uiType === _uiTypes.ShiftsInProgressForTooLong && (
          <RunningForTooLongListView
            {...props}
            setDisplayShiftId={setDisplayShiftId}
          />
        )}
      </ContentContainer>
    </>
  );
};

const MainUi = (props) => {
  const { record, changeUi, setDisplayShiftId } = props;
  return (
    <>
      <ShiftsRunningForTooLongSection
        changeUi={changeUi}
        shiftsRunningForTooLong={record.shiftsRunningForTooLong}
      />

      <Row>
        <Col md={4}>
          <DashboardPanel
            header={
              <>
                <span className='label label-info pull-right'>All</span>
                <h5>{`Today's Shifts`}</h5>
              </>
            }
            content={<h1 className='no-margins'>{record.shiftsForToday}</h1>}
          />
        </Col>
        <Col md={4}>
          <DashboardPanel
            header={
              <>
                <span className='label label-danger pull-right'>
                  Not Started
                </span>
                <h5>{`Today's Shifts`}</h5>
              </>
            }
            content={
              <h1 className='no-margins'>{record.allocatedShiftsForToday}</h1>
            }
          />
        </Col>
        <Col md={4}>
          <DashboardPanel
            header={<h5>Shifts In Progress</h5>}
            content={<h1 className='no-margins'>{record.shiftsInProgress}</h1>}
          />
        </Col>
      </Row>
      <OpenShifts setDisplayShiftId={setDisplayShiftId} />
    </>
  );
};

const ShiftsRunningForTooLongSection = ({
  shiftsRunningForTooLong,
  changeUi,
}) => {
  return (
    shiftsRunningForTooLong > 0 && (
      <AlertWithIcon bsStyle='danger' icon='fa-exclamation-circle'>
        There {AppUtils.pluralise(shiftsRunningForTooLong, 'is', 'are')}{' '}
        {shiftsRunningForTooLong} shift
        {AppUtils.pluralise(shiftsRunningForTooLong, '', 's')} which{' '}
        {AppUtils.pluralise(shiftsRunningForTooLong, 'has', 'have')} been in
        progress for more than {ShiftUtils.ShiftRunWarningTimeHours} hours.
        Please investigate.{' '}
        <Button
          bsSize='sm'
          bsStyle='primary'
          style={{ marginLeft: 'auto' }}
          onClick={() => changeUi(_uiTypes.ShiftsInProgressForTooLong)}
        >
          View &raquo;
        </Button>
      </AlertWithIcon>
    )
  );
};

const _homeButtonsStyle = {
  display: 'inline-block',
  maxWidth: '300px',
};
const HomeButtons = () => {
  return (
    <>
      <CreateNewShiftButton isSm block style={{ ..._homeButtonsStyle }} />
      <CreateNewShiftConfigButton
        isSm
        block
        style={{ ..._homeButtonsStyle, marginBottom: '0' }}
      />
    </>
  );
};

const OpenShifts = ({ setDisplayShiftId }) => {
  return (
    <Row>
      <Col md={12}>
        <DashboardPanel
          header={<OpenShiftsHeader />}
          content={<OpenShiftsListView setDisplayShiftId={setDisplayShiftId} />}
        />
      </Col>
    </Row>
  );
};

const OpenShiftsHeader = () => {
  return (
    <Row>
      <Col md={12}>
        <h5>Open Shifts</h5>
        <SmallMuted className='m-l'>
          Shifts which are Open but have not yet been Allocated.
        </SmallMuted>
      </Col>
    </Row>
  );
};

export default AgencyHome;
