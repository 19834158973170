import { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import $ from 'jquery';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import { deleteAdjustment } from 'utils/api';

export const ClearAdjustmentButton = (props) => {
  const [showModal, setShowModal] = useState(false);

  const record = props[`shift${props.adjustmentType}`];
  if (record.adjustmentValue === 0) {
    return null;
  }

  const handleClearClick = () => {
    setShowModal(true);
  };

  const handleCancelClick = () => {
    setShowModal(false);
  };

  return (
    <>
      <Button
        bsStyle='danger'
        bsSize='xs'
        className={props.className}
        onClick={handleClearClick}
      >
        <Icon icon='fa-times' /> Clear
      </Button>
      <ClearAdjustmentModal
        adjustmentType={props.adjustmentType}
        record={record}
        showModal={showModal}
        handleCancelClick={handleCancelClick}
      />
    </>
  );
};

const ClearAdjustmentModal = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const handleClearAdjustmentClick = async () => {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);
    model['ShiftId'] = props.record.shiftId;
    model['AdjustmentType'] = props.adjustmentType;

    setIsSaving(true);
    AppUtils.clearNotifications();

    try {
      const data = await deleteAdjustment(model);
      AppUtils.handleAjaxDone(data, () => {
        AppUtils.displaySuccess('Success', 'Adjustment cleared');
        setIsSaved(true);
        setTimeout(() => props.handleCancelClick(), 500);
        AppUtils.publish(ShiftUtils.Events.AdjustmentCleared, data);
      });
    } catch (error) {
      AppUtils.displayErrors([error.message]);
    }

    setIsSaving(false);
  };

  return (
    <Modal
      backdrop='static'
      show={props.showModal}
      onHide={props.handleCancelClick}
    >
      <Modal.Header closeButton>
        <Modal.Title>Clear {props.adjustmentType} Adjustment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Body
          isSaved={isSaved}
          adjustmentType={props.adjustmentType}
          record={props.record}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.handleCancelClick} bsSize='sm'>
          <i className='fa fa-times' /> Close
        </Button>
        <ModalSaveButton
          isSaving={isSaving}
          isSaved={isSaved}
          adjustmentType={props.adjustmentType}
          onClick={handleClearAdjustmentClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

const Body = (props) => {
  const { isSaved, adjustmentType, record } = props;
  if (isSaved) {
    return <SavedUi isSaved={isSaved} adjustmentType={adjustmentType} />;
  }

  return (
    <>
      <p className='m-b-lg'>
        You have chosen to clear the following {props.adjustmentType}{' '}
        Adjustment:
      </p>
      <DataRow
        label='Amount'
        value={AppUtils.getCurrencyVal(record.adjustmentValue)}
        className='m-b-xs'
      />
      <DataRow
        label='Reason'
        value={record.adjustmentReason}
        className='m-b-lg'
      />
      <WellSmall className='text-danger'>
        Are you sure you want to proceed?
      </WellSmall>
    </>
  );
};

const SavedUi = ({ isSaved, adjustmentType }) =>
  !isSaved ? null : (
    <WellSmall className='text-success m-b-none'>
      <Icon icon='fa-check' /> {adjustmentType} Adjustment Cleared!
    </WellSmall>
  );

const DataRow = ({ label, value, className }) => {
  return (
    <Row className={className}>
      <Col md={4} className='text-muted text-right'>
        {label}
      </Col>
      <Col md={8}>{value}</Col>
    </Row>
  );
};

const ModalSaveButton = (props) => {
  const isDisabled = props.isSaving || props.isSaved;
  const buttonTitle = props.isSaving
    ? 'Clearing Adjustment...'
    : props.isSaved
    ? `${props.adjustmentType} Adjustment Cleared`
    : `Yes, Clear ${props.adjustmentType} Adjustment`;
  return (
    <Button
      bsStyle='danger'
      onClick={props.onClick}
      disabled={isDisabled}
      bsSize='sm'
    >
      <Icon icon='fa-times' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
