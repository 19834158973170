import { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { Checkbox } from 'pages/Utils/Checkbox';
import { AppUtils } from 'pages/Utils/app-utils';
import { ListViewUtils } from 'pages/Utils/list-view-utils';
import { Icon, WellSmall, SmallMuted } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { getRejectedShifts } from 'utils/api';
import { DeleteSelectedButton } from './DeleteSelectedButton';
import { RePostShiftModal } from './RePostShiftModal';
import './_styles/shiftListView.scss';

export class RejectedShiftsListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGetting: false,
      records: [],
      recordIdsSelected: [],
      lastGet: null,
      recordToBeRePosted: null,
    };

    this.subscriber = this.subscriber.bind(this);
    this.getData = this.getData.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleRePostClick = this.handleRePostClick.bind(this);
    this.clearRecordToBeRePosted = this.clearRecordToBeRePosted.bind(this);
  }

  componentDidMount() {
    this.getData();

    const _me = this;
    [
      ShiftUtils.Events.TimesUpdated,
      ShiftUtils.Events.ShiftsApproved,
      ShiftUtils.Events.ShiftRePosted,
      ShiftUtils.Events.ShiftsDeleted,
    ].forEach((e) => {
      PubSub.subscribe(e, _me.subscriber);
    });
  }

  subscriber() {
    this.setState({ recordIdsSelected: [] });
    this.getData();
  }

  setIsGetting(val, lastGet) {
    let newState = { isGetting: val };
    if (lastGet) {
      newState['lastGet'] = lastGet;
    }
    this.setState(newState);
  }

  async getData() {
    this.setIsGetting(true);
    const data = await getRejectedShifts();
    if (data.status === AppUtils.ApiStatus.Success && data.records) {
      this.setState({ records: data.records });
    }
    this.setIsGetting(false, new Date());
  }

  handleCheckboxChange(id) {
    console.log(`handleCheckboxChange`, id);

    if (id === 'all') {
      return this.handleCheckboxSelectAllClick();
    }

    let recordIdsSelected = [...this.state.recordIdsSelected];
    const index = recordIdsSelected.indexOf(id);
    if (index > -1) {
      recordIdsSelected.splice(index, 1);
    } else {
      recordIdsSelected.push(id);
    }
    this.setState({ recordIdsSelected });
  }

  handleCheckboxSelectAllClick() {
    const getRecordIdsToBeSelected = () => {
      if (this.state.recordIdsSelected.length === this.state.records.length) {
        return [];
      }
      return this.state.records.map((record) => record.shiftId);
    };
    this.setState({ recordIdsSelected: getRecordIdsToBeSelected() });
    return;
  }

  handleRePostClick(shiftId) {
    console.log(`handleRePostClick:`, shiftId);
    const record = this.state.records.find(
      (record) => record.shiftId === shiftId
    );
    if (!record) {
      AppUtils.displayError(
        'Error',
        `Could not find Shift to re-post with id: ${shiftId}`
      );
    }
    this.setRecordToBeRePosted(record);
  }

  setRecordToBeRePosted(record) {
    this.setState({ recordToBeRePosted: record });
  }

  clearRecordToBeRePosted() {
    this.setRecordToBeRePosted(null);
  }

  render() {
    return (
      <>
        <Alert bsStyle='info' className='m-b-none'>
          <p>
            <Icon icon='fa-times-circle' /> This is a list of all Shifts which
            have been Rejected.
          </p>
          <p>
            You can either allocate to a different worker and re-post each Shift
            by clicking the `Re-Post` button or choose some Shifts to delete and
            press the `Delete` button.
          </p>
        </Alert>

        <hr />

        <DeleteButtonContainer {...this.state} />

        <Table
          {...this.state}
          handleCheckboxChange={this.handleCheckboxChange}
          handleRePostClick={this.handleRePostClick}
        />

        <RePostShiftModal
          record={this.state.recordToBeRePosted}
          handleCloseModalClick={this.clearRecordToBeRePosted}
        />
      </>
    );
  }
}

const DeleteButtonContainer = (props) => {
  if (props.records.length <= 0) {
    return null;
  }
  return (
    <WellSmall className='text-right'>
      <DeleteSelectedButton {...props} />
    </WellSmall>
  );
};

const tableId = 'rejected-shifts-table';
class Table extends Component {
  componentDidMount() {
    //this.initDataTables();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.lastGet !== this.props.lastGet) {
      return true;
    }
    if (
      nextProps.recordIdsSelected.length !== this.props.recordIdsSelected.length
    ) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillUpdate() {
    //this.destroyDataTables();
  }

  componentDidUpdate() {
    //this.initDataTables();
  }

  componentWillUnmount() {
    //this.destroyDataTables();
  }

  initDataTables() {
    if (this.props.records.length > 0) {
      ListViewUtils.init(tableId);
    }
  }

  destroyDataTables() {
    const $table = $('#' + tableId);
    if ($table.length) {
      $table.DataTable().destroy();
    }
  }

  render() {
    if (this.props.records.length <= 0) {
      return (
        <WellSmall className='m-b-none'>No Rejected shifts found.</WellSmall>
      );
    }

    const isRecordSelected = (id) =>
      this.props.recordIdsSelected.indexOf(id) > -1;
    const areAllSelected =
      this.props.records.length > 0 &&
      this.props.records.length === this.props.recordIdsSelected.length;

    return (
      <table className='table table-condensed table-striped' id={tableId}>
        <thead>
          <tr>
            <th>
              <Checkbox
                id={`checkbox_all`}
                checked={areAllSelected}
                onChange={() => this.props.handleCheckboxChange('all')}
              />
            </th>
            <th>Shift</th>
            <th>Shift Date/Time</th>
            <th>Worker</th>
            <th>Rejected</th>
            <th>Re-Posted</th>
            <th className='no-sort' />
          </tr>
        </thead>
        <tbody>
          {this.props.records.map((item, i) => (
            <TableRow
              key={i}
              record={item}
              isSelected={isRecordSelected(item.shiftId)}
              handleCheckboxChange={this.props.handleCheckboxChange}
              handleRePostClick={this.props.handleRePostClick}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

const TableRow = ({
  record,
  isSelected,
  handleCheckboxChange,
  handleRePostClick,
}) => {
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  return (
    <tr className={isSelected ? `selected` : null}>
      <td>
        <Checkbox
          id={`checkbox_${record.shiftId}`}
          checked={isSelected}
          onChange={() => handleCheckboxChange(record.shiftId)}
        />
      </td>
      <td>{record.name}</td>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime)}
      </td>
      <td>
        {record.workerFirstName} {record.workerSurname}
      </td>
      <td data-order={record.rejectedDateTime}>
        {AppUtils.formatDateTimeUtc(record.rejectedDateTime)}
      </td>
      <td data-order={record.rePostedDateTime}>
        {record.rePostedDateTime ? (
          <>
            <Link to={`Shift/Edit/${record.shiftId}`}>
              {AppUtils.formatDateTimeUtc(record.rePostedDateTime)}
            </Link>
            <br />
            <SmallMuted>by {record.rePostedBy}</SmallMuted>
          </>
        ) : null}
      </td>
      <td className='text-right'>
        <RePostButton
          record={record}
          handleRePostClick={handleRePostClick}
          isSelected={isSelected}
        />
      </td>
    </tr>
  );
};

const RePostButton = (props) => {
  return (
    <Button
      bsStyle='primary'
      bsSize='xs'
      onClick={() => props.handleRePostClick(props.record.shiftId)}
      data-toggle='tooltip'
      title='Re-Post Shift'
      disabled={props.isSelected}
    >
      <Icon icon='fa-redo-alt' />
    </Button>
  );
};
