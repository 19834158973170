import {
  ChangeEvent,
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';
import $ from 'jquery';
import { AppUtils } from 'pages/Utils/app-utils';
import {
  IShiftConfig,
  IShiftConfigSaveResponse,
  ShiftIndustryType,
} from 'types/ShiftConfigTypes';
import { saveShiftConfig } from 'utils/api';
import { userPermissions, usePermissions } from 'utils/userPermissions';
import {
  isFixedShiftConfig,
  isHourlyShiftConfig,
  isHgvShiftConfig,
  isStatusDraft,
  isStatusLive,
  ShiftConfigEvents,
} from '../ShiftConfigUtils';
import {
  hgvShiftValidateGoLive,
  otherShiftValidateGoLive,
} from './goLiveValidationLogic';

export const ShiftConfigContext = createContext(null);

export const ShiftConfigContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isGettingConfig, setIsGettingConfig] = useState(false);
  const [configuration, setConfiguration] = useState(null);
  const [isGettingRecord, setIsGettingRecord] = useState(false);
  const [record, setRecordState] = useState(null);
  const [shiftFinance, setShiftFinance] = useState(null);
  const [disabled, setDisabled] = useState(null);
  const [isHgvShift, setIsHgvShift] = useState(null);
  const [isHourlyShift, setIsHourlyShift] = useState(null);
  const [isFixedShift, setIsFixedShift] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isDraft, setIsDraft] = useState(null);
  const [isLive, setIsLive] = useState(null);
  const [experienceRecords, setExperienceRecords] = useState([]);

  const setRecord = (record: IShiftConfig) => {
    setRecordState(record);
    setDisabled(isStatusLive(record) || !record.isTemplate);
    setIsHgvShift(isHgvShiftConfig(record));
    setIsHourlyShift(isHourlyShiftConfig(record));
    setIsFixedShift(isFixedShiftConfig(record));
    setIsDraft(isStatusDraft(record));
    setIsLive(isStatusLive(record));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newRecord = { ...record };
    newRecord[AppUtils.toCamelCase(e.target.name)] = e.target.value;
    setRecord(newRecord);
  };

  const handleSaveClick = async (
    doneFn: (data: IShiftConfigSaveResponse) => void
  ) => {
    setIsSaving(true);
    const data = (await save()) as IShiftConfigSaveResponse;
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Success', 'Details saved');
      AppUtils.publish(ShiftConfigEvents.ShiftConfigSaved, data);
    });
    setIsSaving(false);
    if (doneFn) {
      doneFn(data);
    }
  };

  interface IShiftConfigModel {
    ShiftConfigId: string;
  }

  interface IShiftFinanceModel {
    ShiftFinanceId: string;
  }

  const save = () => {
    const shiftConfigModel = AppUtils.getDataModel(null) as IShiftConfigModel;
    shiftConfigModel['ShiftConfigId'] = record.shiftConfigId;

    const shiftFinanceModel = AppUtils.getDataModel(
      $('.shift-finance')
    ) as IShiftFinanceModel;
    shiftFinanceModel['ShiftFinanceId'] = shiftFinance.shiftFinanceId;

    const model = {
      shiftConfigModel,
      shiftFinanceModel,
    };

    return saveShiftConfig(model);
  };

  const canShiftConfigGoLive = (): boolean => {
    switch (record.shiftIndustryType) {
      case ShiftIndustryType.HGV:
        return hgvShiftValidateGoLive();

      case ShiftIndustryType.Commercial:
      case ShiftIndustryType.Industrial:
        return otherShiftValidateGoLive(experienceRecords);

      default:
        AppUtils.displayError(
          'Error',
          `Could not find a Go Live validation method for industry type: ${record.shiftIndustryType}`
        );
        return false;
    }
  };

  const { hasPermission } = usePermissions();
  const canEdit = hasPermission(userPermissions.shiftTemplate.edit);

  const store = {
    isGettingConfig,
    setIsGettingConfig,
    configuration,
    setConfiguration,
    isGettingRecord,
    setIsGettingRecord,
    record,
    setRecord,
    shiftFinance,
    setShiftFinance,
    disabled: disabled || !canEdit,
    isSaving,
    setIsSaving,
    handleChange,
    handleSaveClick,
    save,
    isHgvShift,
    isHourlyShift,
    isFixedShift,
    isDraft,
    isLive,
    canShiftConfigGoLive,
    experienceRecords,
    setExperienceRecords,
    canEdit,
  };
  return (
    <ShiftConfigContext.Provider value={store}>
      {children}
    </ShiftConfigContext.Provider>
  );
};

export const useShiftConfigContext = () => {
  return useContext(ShiftConfigContext);
};
