import { Component } from 'react';
import {
  Alert,
  Button,
  ButtonToolbar,
  ControlLabel,
  FormGroup,
  Modal,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import $ from 'jquery';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, SpinIcon, WellSmall } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { getRePostShiftConfig, repostShift } from 'utils/api';
import { getWorkerType } from '../../ShiftConfig/ShiftConfigUtils';

const _shiftAllocationType = ShiftUtils.AllocationType;

export class RePostShiftModal extends Component {
  constructor(props) {
    console.log('RePostShiftModal.constructor');
    super(props);
    this.state = this.defaultState();

    this.onEntered = this.onEntered.bind(this);
    this.onExited = this.onExited.bind(this);
    this.handleShiftAllocationTypeChange =
      this.handleShiftAllocationTypeChange.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  defaultState() {
    return {
      isGettingConfig: false,
      hasFinishedGettingConfig: false,
      configuration: null,
      isSaving: false,
      shiftAllocationType: _shiftAllocationType.RegisteredWorkers,
    };
  }

  handleShiftAllocationTypeChange(shiftAllocationType) {
    console.log(`handleShiftAllocationTypeChange`, shiftAllocationType);
    this.setState({ shiftAllocationType });
  }

  async handleSubmitClick() {
    console.log('handleSubmitClick');
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);
    model['ShiftId'] = this.props.record.shiftId;
    model['ShiftAllocationType'] = this.state.shiftAllocationType;

    this.setState({ isSaving: true });
    AppUtils.clearNotifications();

    const data = await repostShift(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Success', 'Shift Re-Posted');
      this.props.handleCloseModalClick();
      PubSub.publish(ShiftUtils.Events.ShiftRePosted, data);
    });
    this.setState({ isSaving: false });
  }

  async onEntered() {
    console.log('RePostShiftModal.onEntered');
    this.setState({ isGettingConfig: true, configuration: null });

    const data = await getRePostShiftConfig();
    AppUtils.handleAjaxDone(data, () => {
      if (data.configuration) {
        this.setState({ configuration: data.configuration });
      } else {
        throw new Error('Could not get configuration');
      }
    });
    this.setState({ isGettingConfig: false, hasFinishedGettingConfig: true });
  }

  onExited() {
    AppUtils.destroyTooltips();
    this.setState(this.defaultState());
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={!!this.props.record}
        onHide={this.props.handleCloseModalClick}
        onEntered={this.onEntered}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Re-Post Shift</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body
            {...this.state}
            {...this.props}
            handleShiftAllocationTypeChange={
              this.handleShiftAllocationTypeChange
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.handleCloseModalClick} bsSize='sm'>
            <i className='fa fa-times' /> Close
          </Button>
          <SubmitButton
            {...this.state}
            handleSubmitClick={this.handleSubmitClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = (props) => {
  const { record, isGettingConfig, configuration, hasFinishedGettingConfig } =
    props;
  if (!record) {
    return <WellSmall className='m-b-none'>No record received.</WellSmall>;
  }
  if (isGettingConfig) {
    return (
      <WellSmall className='m-b-none'>
        <SpinIcon /> Getting configuration options...
      </WellSmall>
    );
  }
  if (!configuration && hasFinishedGettingConfig) {
    return (
      <Alert bsStyle='danger' className='m-b-none'>
        <Icon icon='fa-times' /> Could not get configuration options
      </Alert>
    );
  }
  if (!configuration) {
    return null;
  }

  const workerType = AppUtils.toTitleCase(getWorkerType(record));

  return (
    <>
      <Alert bsStyle='warning'>
        This shift was Rejected by <strong>{record.rejectedBy}</strong> on{' '}
        <strong>
          {AppUtils.formatDateTimeUtc(
            record.rejectedDateTime,
            null,
            'ddd DD MMM YYYY HH:mm'
          )}
        </strong>
        .
      </Alert>
      <FormGroup>
        <ControlLabel>Customer</ControlLabel>
        <WellSmall>{record.companyName}</WellSmall>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Shift Template</ControlLabel>
        <WellSmall>{record.name}</WellSmall>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Shift Date</ControlLabel>
        <WellSmall>
          {AppUtils.formatDateTimeNonUtc(
            AppUtils.concatDateAndTime(record.shiftDate, record.shiftTime)
          )}
        </WellSmall>
      </FormGroup>
      <ShiftPaymentTypePicker {...props} workerType={workerType} />
      <ShiftAllocationTypeAllowApplicants {...props} workerType={workerType} />
      <ShiftAllocationTypeSpecificDriver {...props} workerType={workerType} />
    </>
  );
};

const ShiftPaymentTypePicker = ({
  shiftAllocationType,
  handleShiftAllocationTypeChange,
  workerType,
}) => {
  return (
    <FormGroup>
      <ControlLabel>
        Allow Applicants or Allocate Specific {workerType}?
      </ControlLabel>
      <ButtonToolbar>
        <ToggleButtonGroup
          type='radio'
          name='ShiftAllocationType'
          value={shiftAllocationType}
          onChange={handleShiftAllocationTypeChange}
          justified
        >
          <ToggleButton
            value={_shiftAllocationType.RegisteredWorkers}
            className='btn-sm'
          >
            Allow Applicants
          </ToggleButton>
          <ToggleButton
            value={_shiftAllocationType.SpecificWorker}
            className='btn-sm'
          >
            Specific {workerType}
          </ToggleButton>
        </ToggleButtonGroup>
      </ButtonToolbar>
    </FormGroup>
  );
};

const ShiftAllocationTypeAllowApplicants = ({
  shiftAllocationType,
  workerType,
}) => {
  if (shiftAllocationType !== _shiftAllocationType.RegisteredWorkers) {
    return null;
  }
  return (
    <Alert>
      All registered {workerType}s will be able apply for this Shift
    </Alert>
  );
};

const ShiftAllocationTypeSpecificDriver = ({
  shiftAllocationType,
  configuration,
  workerType,
}) => {
  if (shiftAllocationType !== _shiftAllocationType.SpecificWorker) {
    return null;
  }
  return (
    <Row>
      <Field.InCol
        columns={12}
        name='WorkerId'
        label={`Allocate to ${workerType}`}
        options={configuration.workerOptions}
        isSelect
        required
      />
    </Row>
  );
};

const SubmitButton = (props) => {
  if (!props.configuration) {
    return null;
  }
  const buttonTitle = props.isSaving ? 'Submitting...' : 'Submit';
  return (
    <Button
      bsStyle='success'
      bsSize='sm'
      className='m-l-sm'
      onClick={props.handleSubmitClick}
      disabled={props.isSaving}
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
