import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { getWorkerTypeFromIndustryType } from 'pages/ShiftConfig/ShiftConfigUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { ShiftPlanItem, ShiftPlanItemShift } from 'types/ShiftPlanningTypes';
import { EShiftModalMenuUi, ShiftMenuModalProps } from './shiftMenuModalTypes';

type ShiftMenuModalContextType = {
  uiState: EShiftModalMenuUi;
  setUiState: (uiState: EShiftModalMenuUi) => void;
  shiftToHandle: ShiftPlanItemShift;
  shiftPlanItemToHandle: ShiftPlanItem;
  handleModalClose: () => void;
  workerType: string;
};

const ShiftMenuModalContext = createContext<
  ShiftMenuModalContextType | undefined
>(undefined);

type ShiftMenuModalContextProviderProps = ShiftMenuModalProps & {
  children: ReactNode;
};

export const ShiftMenuModalContextProvider = ({
  handleModalClose,
  shift,
  shiftPlanItem,
  children,
}: ShiftMenuModalContextProviderProps) => {
  const [shiftToHandle] = useState<ShiftPlanItemShift>(shift);
  const [shiftPlanItemToHandle] = useState<ShiftPlanItem>(shiftPlanItem);
  const [uiState, setUiState] = useState<EShiftModalMenuUi>(
    EShiftModalMenuUi.Default
  );

  const workerType = AppUtils.toTitleCase(
    getWorkerTypeFromIndustryType(shiftPlanItemToHandle?.shiftIndustryType)
  );

  const value = useMemo(
    () => ({
      uiState,
      setUiState,
      shiftToHandle,
      shiftPlanItemToHandle,
      handleModalClose,
      workerType,
    }),
    [
      uiState,
      shiftToHandle,
      shiftPlanItemToHandle,
      handleModalClose,
      workerType,
    ]
  );

  return (
    <ShiftMenuModalContext.Provider value={value}>
      {children}
    </ShiftMenuModalContext.Provider>
  );
};

export const useShiftMenuModalContext = () => {
  const context = useContext(ShiftMenuModalContext);
  if (context === undefined) {
    throw new Error(
      'useShiftMenuModalContext must be used within a ShiftMenuModalContextProvider'
    );
  }
  return context;
};
