import { useQuery } from '@tanstack/react-query';
import { workerApi } from 'apis/workerApi';
import moment from 'moment';
import { WellSmall, SpinIcon } from 'pages/Utils/react-utils';
import { WorkerShift } from 'types/WorkerTypes';
import { dateFormats, extractErrorMessages, formatDate } from 'utils';
import { useAlertWithRefreshButton } from 'components/AlertWithRefreshButton';
import DataTable from 'components/DataTable';
import { ErrorAlert } from 'components/ErrorAlert';
import { NoRecordsFoundMessage } from 'components/NoRecordsFoundMessage';
import { OpenShiftInNewTabLink } from 'components/OpenShiftInNewTabLink';
import './WorkerProfile.scss';

export const WorkerShiftsTable = ({
  record,
}: {
  record: { workerId: string };
}) => {
  const { data, isLoading, isFetching, refetch, isError, error } = useQuery(
    ['workerShifts', record.workerId],
    () => workerApi.getWorkerShifts(record.workerId)
  );

  const { alert, refreshButton } = useAlertWithRefreshButton(
    "This worker's 100 most recent Shifts.",
    isLoading,
    isFetching,
    refetch
  );

  if (isError) {
    return <ErrorAlert msg={extractErrorMessages(error)} />;
  }

  if (isLoading) {
    return (
      <WellSmall>
        <SpinIcon style={{}} /> Getting Shifts...
      </WellSmall>
    );
  }

  const records = data || [];

  if (records.length <= 0) {
    return (
      <NoRecordsFoundMessage records={records} refreshButton={refreshButton} />
    );
  }

  return (
    <>
      {alert}
      <Table records={records} />
    </>
  );
};

const tableId = 'shifts-table';

const Table = ({ records }: { records: WorkerShift[] }) => {
  return (
    <DataTable
      tableId={tableId}
      records={records}
      render={() => {
        if (records.length <= 0) {
          return null;
        }
        return (
          <table
            className='table table-condensed table-striped'
            id={tableId}
            data-order='[[0, "desc"]]'
          >
            <thead>
              <tr>
                <th>Shift Date/Time</th>
                <th>Company</th>
                <th>Status</th>
                <th>Duration</th>
                <th className='no-sort' />
              </tr>
            </thead>
            <tbody>
              {records.map((item, i) => (
                <TableRow key={i} record={item} />
              ))}
            </tbody>
          </table>
        );
      }}
    />
  );
};

const TableRow = ({ record }: { record: WorkerShift }) => {
  const gotStartAndEndTimes = record.startConfirmed && record.endConfirmed;
  const duration = gotStartAndEndTimes
    ? moment
        .duration(
          moment(record.endConfirmed).diff(moment(record.startConfirmed))
        )
        .asHours()
    : 0;

  return (
    <tr className={duration > 15 ? 'shift-duration-too-long' : ''}>
      <td data-order={record.shiftDateTime}>
        {formatDate(record.shiftDateTime, dateFormats.ukStandardWithTime)}
      </td>
      <td>{record.customerName}</td>
      <td>{record.status}</td>
      <td>{gotStartAndEndTimes ? `${duration} hrs` : ''}</td>
      <td>
        <OpenShiftInNewTabLink shiftId={record.shiftId} />
      </td>
    </tr>
  );
};
