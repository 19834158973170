import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { useSendContractorEmailMutation } from 'hooks/queries';
import { useModal } from 'hooks/useModal';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils, Constants } from 'pages/Utils/app-utils';
import { Icon, DashboardPanel } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';

export const SendEmailButton = ({
  recordIdsSelected,
  selectedTotal,
}: {
  recordIdsSelected: string[];
  selectedTotal: number;
}) => {
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const handleShowModalClick = () => {
    if (recordIdsSelected.length <= 0) {
      AppUtils.displayError(
        'Error',
        'No Shifts selected. Please select some and try again.'
      );
    } else {
      handleModalOpen();
    }
  };

  const handleCloseModalClick = () => {
    handleModalClose();
  };

  const sendEmail = useSendContractorEmailMutation();

  const handleSubmitClick = () => {
    sendEmail.mutate(
      {
        shiftIds: recordIdsSelected,
      },
      {
        onSuccess: (data) => {
          AppUtils.displaySuccess('Success', 'Emails sent successfully');
          handleCloseModalClick();
          PubSub.publish(ShiftUtils.Events.ContractorShiftEmailsSent, data);
        },
      }
    );
  };

  const recordsSelectedCount = recordIdsSelected.length;
  const btnText = `Send Email`;

  return (
    <>
      <Button
        bsStyle='success'
        bsSize='sm'
        onClick={handleShowModalClick}
        disabled={recordsSelectedCount <= 0}
      >
        <Icon icon='fa-envelope' /> {btnText}
      </Button>
      <Modal
        backdrop='static'
        show={isModalOpen}
        onHide={handleCloseModalClick}
        onExited={() => AppUtils.destroyTooltips()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Invoice Notification Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body
            selectedTotal={selectedTotal}
            recordIdsSelected={recordIdsSelected}
          />
          <ErrorAlertForQueryOrNull
            isError={sendEmail.isError}
            error={sendEmail.error}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModalClick} bsSize='sm'>
            <i className='fa fa-times' /> Close
          </Button>
          <SubmitButton
            isSaving={sendEmail.isLoading}
            handleSubmitClick={handleSubmitClick}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Body = ({
  selectedTotal,
  recordIdsSelected,
}: {
  selectedTotal: number;
  recordIdsSelected: string[];
}) => {
  const containerStyle = { marginBottom: '0' };
  const getContent = (value: string) => (
    <h1 className='no-margins' style={{ color: Constants.Colours.success }}>
      {value}
    </h1>
  );
  return (
    <>
      <p>{`Here's a summary of the shifts you're going to notifiy the contractors about:`}</p>
      <Row
        style={{
          background: '#efefef',
          margin: '0 -15px 10px',
          padding: '10px 0',
        }}
      >
        <Col sm={6}>
          <DashboardPanel
            containerStyle={containerStyle}
            title='Total Amount'
            content={getContent(`£${AppUtils.formatNumber(selectedTotal)}`)}
          />
        </Col>
        <Col sm={6}>
          <DashboardPanel
            containerStyle={containerStyle}
            title='Shift Qty'
            content={getContent(recordIdsSelected.length.toString())}
          />
        </Col>
      </Row>
      <Alert bsStyle='info'>
        {`Each contractor will receive an email summary of their shifts. Are you sure you'd like to proceed?`}
      </Alert>
    </>
  );
};

const SubmitButton = (props: {
  isSaving: boolean;
  handleSubmitClick: () => void;
}) => {
  const buttonTitle = props.isSaving ? 'Updating...' : 'Yes, Send';
  return (
    <Button
      bsStyle='success'
      bsSize='sm'
      className='m-l-sm'
      onClick={props.handleSubmitClick}
      disabled={props.isSaving}
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
