import moment from 'moment';
import { Field } from 'pages/Utils/react-utils';
import { ICustomEvent } from 'types/GeneralTypes';

const yearOptions = Array.from(Array(10).keys()).map((i) =>
  (moment().year() - 5 + i).toString()
);

const weekNumberOptions = Array.from(Array(52).keys()).map((i) =>
  (i + 1).toString()
);

type YearAndWeekNumberProps = {
  year: string;
  weekNumber: string;
  handleChange: (e: ICustomEvent) => void;
  cols?: 3 | 4;
};

export const YearAndWeekNumber = ({
  year,
  weekNumber,
  handleChange,
  cols = 4,
}: YearAndWeekNumberProps) => {
  return (
    <>
      <Field.InCol
        columns={cols}
        name='Year'
        label='Year'
        value={year}
        options={yearOptions}
        onChange={handleChange}
        isSelect
        required
      />
      <Field.InCol
        columns={cols}
        name='WeekNumber'
        label='Week Number'
        value={weekNumber}
        options={weekNumberOptions}
        onChange={handleChange}
        isSelect
        required
      />
    </>
  );
};
