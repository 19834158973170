import { Button } from 'react-bootstrap';
import { Icon } from 'pages/Utils/react-utils';
import { ShiftUtils } from '../ShiftUtils';

export const AcceptButton = (props) => {
  if (props.shift.status !== ShiftUtils.Status.Open) {
    return null;
  }
  return (
    <Button
      bsSize='xs'
      bsStyle='success'
      onClick={() => props.handleAcceptModalShowClick(props.record)}
    >
      <Icon icon='fa-user-check' /> Accept
    </Button>
  );
};
