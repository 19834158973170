import { AppUtils } from '../pages/Utils/app-utils';

const configureSubscriptions = (
  eventsToSubscribeTo: string[],
  fn: () => void
) => {
  const subscriptionTokens = eventsToSubscribeTo.map((e) =>
    AppUtils.subscribe(e, fn)
  );
  const cleanup = () => {
    subscriptionTokens.forEach((token) => AppUtils.unsubscribe(token));
  };
  return {
    subscriptionTokens,
    cleanup,
  };
};

export default configureSubscriptions;
