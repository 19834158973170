import { Button } from 'react-bootstrap';
import { useGetPayrollCsvMutation } from 'hooks/queries';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon } from 'pages/Utils/react-utils';
import { extractErrorMessages } from 'utils';

export const DownloadCsvButton = ({
  recordIdsSelected,
}: {
  recordIdsSelected: string[];
}) => {
  const getPayrollCsv = useGetPayrollCsvMutation();
  const handleClick = async () => {
    if (recordIdsSelected.length === 0) {
      AppUtils.displayError(
        'Error',
        'Please select at least one shift to download'
      );
      return;
    }

    getPayrollCsv.mutate(
      { shiftIds: recordIdsSelected },
      {
        onSuccess: (data: Blob) => {
          // Download the file
          if (data) {
            fileDownload(
              data,
              `PayrollDownload-${AppUtils.getFormattedMoment(moment())}.csv`
            );
          }
        },
        onError: (error) => {
          AppUtils.displayError(
            'Error',
            extractErrorMessages(error),
            false,
            'download-csv-unpaid-error'
          );
        },
      }
    );
  };

  return (
    <Button
      bsSize='sm'
      bsStyle='primary'
      onClick={handleClick}
      className='m-r-xs'
      disabled={getPayrollCsv.isLoading || recordIdsSelected.length === 0}
    >
      <Icon icon='fa-download' isSpinning={getPayrollCsv.isLoading} />{' '}
      {getPayrollCsv.isLoading
        ? 'Generating...'
        : `Download ${recordIdsSelected.length > 0 ? ' Selected' : ''}`}
    </Button>
  );
};
