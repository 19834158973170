import { Fragment, useCallback, useState } from 'react';
import { Button, Alert, Panel, Row, Col } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useUploadShiftTimesMutation } from 'hooks/queries';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import { TimesUploadSummary } from 'types/ShiftTypes';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { Stack } from 'components/Stack';

export const ShiftsUploadTimes = () => {
  const uploadTimes = useUploadShiftTimesMutation();

  const [files, setFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDrop,
  });

  const removeFile = () => {
    setFiles([]);
  };

  const [summaries, setSummaries] = useState<TimesUploadSummary[]>([]);

  const handleUploadClick = () => {
    if (files.length <= 0) {
      AppUtils.displayError(
        'Error',
        'Please select a file to upload',
        false,
        'file-upload-error'
      );
      return;
    }

    setSummaries([]);

    // Create form data object
    const formData = new FormData();
    formData.append('file', files[0]);

    uploadTimes.mutate(formData, {
      onSuccess: (response) => {
        AppUtils.displaySuccess('Success', 'File uploaded successfully');
        setFiles([]);
        setSummaries(response.summaries);
      },
    });
  };

  return (
    <Row>
      <Col sm={6}>
        <Panel>
          <Panel.Heading>Upload Shift Times</Panel.Heading>
          <Panel.Body>
            <Alert>
              This page allows you to upload completed times for shifts from a
              spreadsheet. The times will be added to the shifts and will be
              used to calculate the payment amounts for shifts where applicable.
              <p>
                <strong>Please note: </strong>This will only work for shifts
                with a status of Allocate, In Progress or Completed.
              </p>
            </Alert>

            <Stack gap={10}>
              <section>
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  style={{
                    border: '2px dashed #ccc',
                    borderRadius: '4px',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    minHeight: 'unset',
                  }}
                >
                  {files.length <= 0 ? (
                    <>
                      <input {...getInputProps()} />
                      <p>
                        Drag and drop an Excel file here, or click to select a
                        file.
                      </p>
                    </>
                  ) : (
                    <Stack direction='row' gap={10}>
                      {files.map((file) => (
                        <Fragment key={file.name}>
                          <span>
                            {file.name} - {file.size} bytes
                          </span>
                          <Button
                            bsSize='xs'
                            bsStyle='danger'
                            onClick={() => {
                              removeFile();
                            }}
                          >
                            <Icon icon='fa-trash' /> Remove
                          </Button>
                        </Fragment>
                      ))}
                    </Stack>
                  )}
                </div>
              </section>

              <div>
                <Button
                  bsSize='sm'
                  bsStyle='primary'
                  onClick={handleUploadClick}
                  disabled={uploadTimes.isLoading}
                >
                  <Icon icon='fa-upload' isSpinning={uploadTimes.isLoading} />{' '}
                  Upload
                </Button>
              </div>

              <ErrorAlertForQueryOrNull
                isError={uploadTimes.isError}
                error={uploadTimes.error}
              />

              {summaries.length > 0 ? (
                <Panel>
                  <Panel.Heading>Upload Summary</Panel.Heading>
                  <Panel.Body>
                    <Stack gap={10}>
                      <Alert bsStyle='info'>
                        <strong>{summaries.length}</strong> time entry records
                        uploaded successfully for the following shifts:
                      </Alert>
                      {summaries.map((summary) => (
                        <WellSmall key={summary.shiftId}>
                          <a
                            href={`/Shift/Edit/${summary.shiftId}`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {summary.shiftName} - {summary.worker} -{' '}
                            {AppUtils.formatDateTimeNonUtc(
                              summary.fromDateTime
                            )}
                            -{' '}
                            {AppUtils.formatDateTimeNonUtc(summary.toDateTime)}
                          </a>
                        </WellSmall>
                      ))}
                    </Stack>
                  </Panel.Body>
                </Panel>
              ) : null}
            </Stack>
          </Panel.Body>
        </Panel>
      </Col>
    </Row>
  );
};
