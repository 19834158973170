import {
  AddPermissionToUserParams,
  RemovePermissionFromUserParams,
  UserPermissionItem,
} from 'types/UserTypes';
import { del, get, post } from './apiBase';

const addPermissionToUser = (params: AddPermissionToUserParams) => {
  const { userAccountId, permissionName } = params;
  return post(`User/${userAccountId}/Permission`, {
    permissionName,
  });
};

const removePermissionFromUser = (params: RemovePermissionFromUserParams) => {
  const { userAccountId, permissionId } = params;
  return del(`User/${userAccountId}/Permission/${permissionId}`);
};

const getUserPermissions = async (userAccountId: string) => {
  return get<UserPermissionItem[]>(`User/${userAccountId}/Permissions`);
};

export const userApi = {
  addPermissionToUser,
  removePermissionFromUser,
  getUserPermissions,
};
