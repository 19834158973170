import { Component } from 'react';
import { Button, Col, Panel, Row, Well } from 'react-bootstrap';
import moment from 'moment';
import { HirerUtils } from 'pages/Hirer/HirerUtils';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { DatePicker } from 'pages/Utils/DatePicker';
import { TimeDropdown } from 'pages/Utils/TimeDropdown';
import { AppUtils } from 'pages/Utils/app-utils';
import {
  SmallMuted,
  WellSmall,
  Icon,
  setIsGettingWithAutoRefresh,
} from 'pages/Utils/react-utils';
import { WorkerImage } from 'pages/Worker/WorkerUtils';
import { getAllocatedShifts } from 'utils/api';
import configureSubscriptions from 'utils/configureSubscriptions';
import DataTable from 'components/DataTable';

const _filterContainerId = 'AllocatedFiltersContainer';
const _filterFieldPrefix = 'allocatedFilter';

export class ShiftAllocatedListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGetting: false,
      records: [],
      lastGet: null,
      showModal: false,
      recordToEdit: null,
      timeout: null,
      filterData: {
        [`${_filterFieldPrefix}FromDate`]: AppUtils.getDateString(
          moment().subtract(1, 'days'),
          'YYYY-MM-DD'
        ),
        [`${_filterFieldPrefix}ToDate`]: AppUtils.getDateString(
          moment().add(30, 'days'),
          'YYYY-MM-DD'
        ),
      },
      cleanupSubscriptions: null,
    };

    this.subscriber = this.subscriber.bind(this);
    this.getData = this.getData.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    const _me = this;
    const { cleanup } = configureSubscriptions(
      _me.subscribeToEvents(),
      _me.subscriber
    );
    this.setState({ cleanupSubscriptions: cleanup });

    this.getData();
  }

  componentWillUnmount() {
    const { cleanupSubscriptions } = this.state;
    if (cleanupSubscriptions) {
      cleanupSubscriptions();
    }

    console.log('ShiftAllocatedListView clear timeout');
    clearTimeout(this.state.timeout);
  }

  subscribeToEvents() {
    return [ShiftUtils.Events.ShiftCreated];
  }

  subscriber() {
    this.getData();
  }

  setIsGetting(isGetting, lastGet) {
    setIsGettingWithAutoRefresh(
      this,
      isGetting,
      lastGet,
      this.getData,
      HirerUtils.AutoRefreshFrequencyMs
    );
  }

  getDataModel() {
    console.log(`getDataModel`);
    const getDataModel = this.state.filterData;
    let dataModel = {};
    for (let [key, value] of Object.entries(getDataModel)) {
      if (key.startsWith(_filterFieldPrefix)) {
        dataModel[key.substring(_filterFieldPrefix.length)] = value;
      }
    }
    return dataModel;
  }

  async getData() {
    this.setIsGetting(true);
    const data = await getAllocatedShifts(this.getDataModel());
    if (data.records) {
      this.setState({ records: data.records });
    }
    this.setIsGetting(false, new Date());
  }

  handleFilterChange(e) {
    console.log(e.target.name.toCamelCase(), e.target.value);
    const { filterData } = this.state;
    filterData[e.target.name.toCamelCase()] = e.target.value;
    this.setState({ filterData });
  }

  render() {
    if (this.state.records.length <= 0 && this.state.isGetting) {
      return (
        <WellSmall className='m-b-none' hasSpinner>
          Getting Allocated Shifts...
        </WellSmall>
      );
    }
    return (
      <>
        <Filters
          {...this.state}
          handleSearchClick={this.getData}
          handleFilterChange={this.handleFilterChange}
        />
        <TableContainer {...this.state} />
      </>
    );
  }
}

const Filters = (props) => {
  const { handleSearchClick, isGetting, filterData, handleFilterChange } =
    props;

  const handleChangeDate = (name, date) =>
    handleFilterChange({
      target: { name, value: moment(date).format('YYYY-MM-DD') },
    });

  return (
    <Panel id={_filterContainerId}>
      <Panel.Body>
        <WellSmall>
          Search for shifts which start bewteen the selected from/to dates. You
          can also restrict the results to a particular start time.
        </WellSmall>
        <Row>
          <Col sm={3}>
            <DatePicker
              name={`${_filterFieldPrefix}FromDate`}
              label='From Date'
              value={AppUtils.getDateString(
                filterData[`${_filterFieldPrefix}FromDate`]
              )}
              changeDate={(e) =>
                handleChangeDate(`${_filterFieldPrefix}FromDate`, e.date)
              }
              readonly
            />
            <DatePicker
              name={`${_filterFieldPrefix}ToDate`}
              label='To Date'
              value={AppUtils.getDateString(
                filterData[`${_filterFieldPrefix}ToDate`]
              )}
              changeDate={(e) =>
                handleChangeDate(`${_filterFieldPrefix}ToDate`, e.date)
              }
              readonly
            />
          </Col>

          <Col sm={3}>
            <TimeDropdown
              name={`${_filterFieldPrefix}FromTime`}
              label='From Time'
              placeholder='From Time'
              value={filterData[`${_filterFieldPrefix}FromTime`]}
              onChange={handleFilterChange}
            />
            <TimeDropdown
              name={`${_filterFieldPrefix}ToTime`}
              label='To Time'
              placeholder='To Time'
              value={filterData[`${_filterFieldPrefix}ToTime`]}
              onChange={handleFilterChange}
            />
          </Col>
          <Col sm={3}>
            <TimeDropdown
              name={`${_filterFieldPrefix}RestrictToStartTime`}
              label='Restrict to Start Time'
              placeholder='Start Time'
              value={filterData[`${_filterFieldPrefix}RestrictToStartTime`]}
              onChange={handleFilterChange}
              tooltip='Restrict the results to only contain Shifts which start at the selected start time'
            />
          </Col>
        </Row>
      </Panel.Body>
      <Panel.Footer className='text-right'>
        <Button
          className='m-b-none'
          bsStyle='primary'
          bsSize='sm'
          onClick={handleSearchClick}
          disabled={isGetting}
        >
          <Icon icon='fa-search' isSpinning={isGetting} />{' '}
          {isGetting ? 'Searching' : 'Search'}
        </Button>
      </Panel.Footer>
    </Panel>
  );
};

const tableId = 'allocated-shifts-table';

const TableContainer = ({ records, lastGet }) => {
  if (records.length <= 0 && lastGet) {
    return (
      <WellSmall className='m-b-none'>
        No Confirmed Shifts Found. Please update the filters above and press
        Search to try again.
      </WellSmall>
    );
  }
  return (
    <>
      <Well>
        <Icon icon='fa-user-check' /> Shifts which have been Allocated and
        Confirmed but not yet started.
      </Well>
      <DataTable
        records={records}
        tableId={tableId}
        render={() => {
          if (records.length <= 0) {
            return null;
          }
          return (
            <table
              className='table table-condensed table-striped table-hover'
              id={tableId}
              data-order='[[ 1, "asc" ]]'
            >
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Shift Date/Time</th>
                  <th>Worker</th>
                  <th>Allocated</th>
                </tr>
              </thead>
              <tbody>
                {records.map((item, i) => (
                  <TableRow key={i} record={item} />
                ))}
              </tbody>
            </table>
          );
        }}
      />
    </>
  );
};

const TableRow = ({ record }) => {
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  return (
    <tr>
      <td>
        {record.name}
        <br />
        <SmallMuted>{record.agencyName}</SmallMuted>
      </td>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime)}
      </td>
      <td data-order={record.workerSurname}>
        {record.workerHasImage && (
          <WorkerImage
            workerId={record.workerId}
            firstName={record.workerFirstName}
            surname={record.workerSurname}
            mobileNumber={record.workerMobileNumber}
          />
        )}
        {`${record.workerFirstName} ${record.workerSurname}`}
      </td>
      <td data-order={record.allocatedDateTime}>
        {AppUtils.formatDateTimeUtc(record.allocatedDateTime)}
        <div>
          <SmallMuted>{record.allocatedBy}</SmallMuted>
        </div>
      </td>
    </tr>
  );
};
