import { Row } from 'react-bootstrap';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field } from 'pages/Utils/react-utils';
import { useShiftConfigContext } from '../ShiftConfigContextProvider';

const ShiftDetailsHgvSpecificFields = () => {
  const { record, disabled, isHgvShift } = useShiftConfigContext();
  if (!isHgvShift) {
    return null;
  }
  return (
    <Row>
      <Field.InCol
        columns={3}
        name='VehicleType'
        label='Vehicle'
        value={record.vehicleType}
        options={AppUtils.options.vehicleTypeOptions}
        isSelect
        disabled={disabled}
      />
      <Field.InCol
        columns={3}
        name='TrailerType'
        label='Trailer'
        value={record.trailerType}
        options={AppUtils.options.trailerTypeOptions}
        isSelect
        disabled={disabled}
      />
      <Field.InCol
        columns={3}
        name='LoadType'
        label='Load'
        value={record.loadType}
        options={AppUtils.options.loadTypeOptions}
        isSelect
        disabled={disabled}
      />
      <Field.InCol
        columns={3}
        name='RunType'
        label='Run'
        value={record.runType}
        options={AppUtils.options.runTypeOptions}
        isSelect
        disabled={disabled}
      />
    </Row>
  );
};

export default ShiftDetailsHgvSpecificFields;
