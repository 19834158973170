import { Component } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import $ from 'jquery';
import { AgencyUtils } from 'pages/Agency/AgencyUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, WellSmall, Icon } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { saveAgencyUser } from 'utils/api';

export class AddAgencyUserButton extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  getInitialState() {
    return {
      showModal: false,
    };
  }

  handleShowModalClick() {
    this.setState({ showModal: true });
  }

  handleCloseModalClick() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <div>
        <Button
          bsSize='sm'
          bsStyle='primary'
          onClick={this.handleShowModalClick}
        >
          <Icon icon='fa-user-plus' /> Add User
        </Button>
        <UserModal
          {...this.state}
          {...this.props}
          handleCloseModalClick={this.handleCloseModalClick}
        />
      </div>
    );
  }
}

class UserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
    };

    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  async handleSubmitClick() {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);

    if (this.state.record) {
      model['agencyId'] = this.state.record.agencyId;
    }

    this.setState({ isSaving: true });
    const data = await saveAgencyUser(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'User saved');
      this.props.handleCloseModalClick();
      PubSub.publish(AgencyUtils.Events.UserSaved, data);
    });
    this.setState({ isSaving: false });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.showModal}
        onHide={this.props.handleCloseModalClick}
        onEntered={this.onEntered}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalBodyMain {...this.state} {...this.props} />
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize='sm' onClick={this.props.handleCloseModalClick}>
            <i className='fas fa-times'></i> Cancel
          </Button>
          <ModalSaveButton
            {...this.state}
            {...this.props}
            onClick={this.handleSubmitClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const ModalBodyMain = (props) => {
  return (
    <>
      <WellSmall>
        {`Complete the details below and press "Add User". They will then receive an invitation to become a user of `}
        {props.companyName}.
      </WellSmall>
      <Row>
        <Field.InCol
          columns={6}
          name='FirstName'
          label='First Name'
          maxLength='50'
          required
        />
        <Field.InCol
          columns={6}
          name='Surname'
          label='Surname'
          maxLength='50'
          required
        />
      </Row>
      <Row>
        <Field.InCol
          columns={12}
          name='EmailAddress'
          label='Email Address'
          maxLength='50'
          required
        />
      </Row>
    </>
  );
};

const ModalSaveButton = (props) => {
  const text = props.isSaving ? 'Adding User...' : 'Add User';
  const icon = props.isSaving ? AppUtils.spinIconClass : 'fa fa-user-plus';
  return (
    <Button
      bsStyle='success'
      bsSize='sm'
      onClick={props.onClick}
      disabled={props.isSaving}
    >
      <i className={icon}></i> {text}
    </Button>
  );
};
