import { ReactNode } from 'react';
import { Alert, Button, Col, Panel, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CellProps, Column, UseSortByColumnOptions } from 'react-table';
import { FormLabel } from 'components';
import { useSelectedCustomer } from 'hooks';
import { useGetInvoicesQuery } from 'hooks/queries';
import { useModal } from 'hooks/useModal';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon } from 'pages/Utils/react-utils';
import { Invoice, InvoiceForListView } from 'types/Invoice';
import { usePermissions, userPermissions } from 'utils/userPermissions';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { GettingRecordsMessage } from 'components/GettingRecordsMessage';
import { Table } from 'components/Table';
import { UnauthorisedAlert } from 'components/UnauthorisedAlert';
import { CreateInvoiceDialog } from './CreateInvoiceDialog';
import { StatusChip } from './shared';

export const InvoiceListView = () => {
  const { hasPermission } = usePermissions();
  const isAllowed = hasPermission(userPermissions.shift.finance.view);
  if (!isAllowed) {
    return <UnauthorisedAlert />;
  }

  return <InvoicesMain />;
};

const InvoicesMain = () => {
  const { selectedCustomerId, renderCustomerSelect } = useSelectedCustomer();

  const { isLoading, isFetching, isSuccess, isError, error, data, refetch } =
    useGetInvoicesQuery({ hirerId: selectedCustomerId });

  const records = data ?? [];

  const handleSearchClick = () => {
    refetch();
  };

  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  return (
    <>
      <Panel>
        <Panel.Heading>
          <Row>
            <Col sm={4}>
              Invoices <Icon icon='fa-info-circle' tooltip='All invoices.' />
            </Col>

            <Col sm={8} className='text-right'>
              <Button bsStyle='primary' bsSize='sm' onClick={handleModalOpen}>
                Create Invoice
              </Button>
              {isModalOpen ? (
                <CreateInvoiceDialog
                  showModal={isModalOpen}
                  handleCloseModalClick={handleModalClose}
                />
              ) : null}
            </Col>
          </Row>
        </Panel.Heading>
        <Panel.Body>
          <Filters
            handleSearchClick={handleSearchClick}
            isFetching={isFetching}
            renderCustomerSelect={renderCustomerSelect}
          />

          {isLoading ? (
            <GettingRecordsMessage message={'Getting invoices...'} />
          ) : null}

          <ErrorAlertForQueryOrNull isError={isError} error={error} />

          {isSuccess && records.length <= 0 ? (
            <Alert>
              No invoices found. Update the filters above and try again.
            </Alert>
          ) : null}

          {records.length > 0 ? <TableContainer records={records} /> : null}
        </Panel.Body>
      </Panel>
    </>
  );
};

const Filters = ({
  isFetching,
  handleSearchClick,
  renderCustomerSelect,
}: {
  isFetching: boolean;
  handleSearchClick: () => void;
  renderCustomerSelect: () => ReactNode;
}) => {
  return (
    <Panel id='InvoicesFilterContainer'>
      <Panel.Body>
        <Row>
          <Col sm={4}>
            <FormLabel name='HirerId' label='Customer' />
            {renderCustomerSelect()}
          </Col>
        </Row>
      </Panel.Body>
      <Panel.Footer className='text-right'>
        <SearchButton
          isGetting={isFetching}
          handleSearchClick={handleSearchClick}
        />
      </Panel.Footer>
    </Panel>
  );
};

const SearchButton = ({
  isGetting,
  handleSearchClick,
}: {
  isGetting: boolean;
  handleSearchClick: () => void;
}) => {
  return (
    <Button
      bsStyle='primary'
      bsSize='sm'
      onClick={handleSearchClick}
      disabled={isGetting}
    >
      <Icon icon='fa-search' isSpinning={isGetting} />{' '}
      {isGetting ? 'Searching' : 'Search'}
    </Button>
  );
};

const TableContainer = ({ records }: { records: Invoice[] }) => {
  const columns: (Column<InvoiceForListView> &
    UseSortByColumnOptions<InvoiceForListView>)[] = [
    {
      Header: 'Number',
      accessor: 'invoiceReference',
      Cell: (cellProps: CellProps<InvoiceForListView>) => (
        <>
          <Link to={`/Invoice/${cellProps.row.original.invoiceId}`}>
            {cellProps.row.original.invoiceReference}
          </Link>
        </>
      ),
    },
    {
      Header: 'To',
      accessor: (record: InvoiceForListView) => record.hirerName,
    },
    {
      Header: 'Date',
      accessor: (record: InvoiceForListView) => record.invoiceDate,
      Cell: ({ value }: { value: string }) =>
        AppUtils.formatDateTimeNonUtc(value, undefined, 'DD MMM YYYY'),
    },
    {
      Header: 'Due Date',
      accessor: (record: InvoiceForListView) => record.invoiceDueDate,
      Cell: ({ value }: { value: string }) =>
        AppUtils.formatDateTimeNonUtc(value, undefined, 'DD MMM YYYY'),
    },
    {
      Header: 'Status',
      accessor: (record: InvoiceForListView) => (
        <StatusChip status={record.status} />
      ),
    },
    {
      Header: 'Total',
      accessor: (record: InvoiceForListView) => record.total,
      Cell: ({ value }: { value: string }) => (
        <span>{AppUtils.getCurrencyVal(value)}</span>
      ),
    },
  ];

  return <Table columns={columns} records={records} />;
};
