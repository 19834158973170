import { Component } from 'react';
import { Tab, Tabs, Well } from 'react-bootstrap';
import { SpinIcon, WellSmall } from 'pages/Utils/react-utils';
import { getUserAdminConfig } from 'utils/api';
import { AddUserButton } from './Buttons/AddUserButton';
import { UserListView } from './UserListView';

class UserAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isGettingConfig: false,
      configuration: null,
      activeTab: 1,
    };

    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  componentDidMount() {
    this.getConfig();
  }

  async getConfig() {
    this.setState({ isGettingConfig: true });
    const data = await getUserAdminConfig();
    if (data.configuration) {
      this.setState({ configuration: data.configuration });
    }
    this.setState({ isGettingConfig: false });
  }

  handleTabSelect(tabToMakeActive) {
    console.log(`UserAdmin.handleTabSelect: ${tabToMakeActive}`);
    this.setState({ activeTab: tabToMakeActive });
  }

  render() {
    console.log('UserAdmin.render');
    if (!this.state.configuration) {
      return null;
    }
    if (this.state.isGettingConfig) {
      return <GettingConfigMsg {...this.state} />;
    }
    return (
      <>
        <Intro />
        <TabsMain {...this.state} handleTabSelect={this.handleTabSelect} />
      </>
    );
  }
}

const Intro = (props) => {
  return (
    <WellSmall className='white-bg'>
      <AddUserButton {...props} className='m-b-none' />
    </WellSmall>
  );
};

const TabsMain = ({ activeTab, handleTabSelect }) => {
  return (
    <div className='tabs-container'>
      <Tabs
        activeKey={activeTab}
        animation={false}
        onSelect={handleTabSelect}
        id='user-admin-tabs'
      >
        <Tab eventKey={1} title='Users'>
          <UsersTab />
        </Tab>
      </Tabs>
    </div>
  );
};

const GettingConfigMsg = ({ isGettingInitialConfig }) => {
  if (!isGettingInitialConfig) {
    return null;
  }
  return (
    <Well>
      <SpinIcon /> Please wait while the user admin area is configured...
    </Well>
  );
};

const UsersTab = () => {
  console.log('UsersTab.render');
  return <UserListView type='Admin' />;
};

export default UserAdmin;
