import { Dropdown } from 'components';
import moment from 'moment';

export const TimeDropdown = (props) => {
  const timeSeed = moment().startOf('day');
  const getTime = (m) => ({
    value: m.format('HH:mm:ss'),
    text: m.format('HH:mm'),
  });
  const timeOptions = [getTime(timeSeed)];
  for (let i = 0, len = 95; i < len; i++) {
    timeOptions.push(getTime(timeSeed.add(15, 'minutes')));
  }
  return <Dropdown {...props} options={timeOptions} />;
};
