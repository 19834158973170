import ReactSelect from 'react-select';
import { AppUtils } from 'pages/Utils/app-utils';
import { FormLabel } from 'components/FormLabel';

/*
  options: string[] | { value: string, text: string, isDisabled: boolean }[]
*/
export const Dropdown = (props) => {
  const name = props.name;
  const label = props.label ?? name;
  const isMulti = props.multiple ?? false;
  const value = isMulti
    ? props.value
      ? props.value.split(';')
      : props.value
    : props.value;
  const classes = props.classes ?? [];
  const required = props.required ?? false;
  const isDisabled = props.disabled ?? false;
  const isClearable = props.notClearable ? false : true;

  let inputClasses = ['is-select'];
  if (classes.length > 0) {
    inputClasses = inputClasses.concat(classes);
  }
  if (required) {
    inputClasses.push('required');
  }
  if (props.className && props.className !== '') {
    inputClasses.push(props.className);
  }

  const labelRet = props.noLabel ? null : label ? (
    <FormLabel {...props} label={label} />
  ) : null;

  const { options = [] } = props;
  const mappedOptions = options.map((option) => {
    const isObject = AppUtils.isObject(option);
    const value = isObject ? option.value : option;
    const label = isObject ? option.text : option;
    const isDisabled = isObject ? option.isDisabled : false;
    return { value, label, isDisabled };
  });

  const borderColorFocused = '#1ab394';
  const customStyles = {
    borderRadius: '0',
    minHeight: '34px',
    boxShadow: '0',
  };
  const styles = {
    clearIndicator: (styles) => {
      return { ...styles, cursor: 'pointer', padding: '6px 8px' };
    },
    dropdownIndicator: (styles) => {
      return { ...styles, padding: '6px 8px' };
    },
    control: (styles, state) => {
      const style = { ...styles, ...customStyles };
      if (state.isFocused) {
        style['&:hover'] = { borderColor: borderColorFocused };
        style['borderColor'] = borderColorFocused;
        style['zIndex'] = '200000';
      }
      return style;
    },
  };

  const getValue = () => {
    if (isMulti) {
      return typeof value === 'undefined' || value === null || value === ''
        ? ''
        : mappedOptions.filter((option) => value.indexOf(option.value) > -1);
    } else {
      return typeof value === 'undefined' || value === null || value === ''
        ? ''
        : mappedOptions.find((option) => option.value === value);
    }
  };

  const handleChange = props.onChange
    ? (e, action) => {
        if (action.action === 'clear') {
          props.onChange({ target: { name, value: '' } }, action);
        } else {
          props.onChange({ ...e, target: { name, value: e.value } }, action);
        }
      }
    : undefined;

  const isControlled = Boolean(handleChange);
  const _dropdownSelector = `Dropdown-${name}`;

  const select = (
    <ReactSelect
      id={name}
      name={name}
      isMulti={isMulti}
      options={mappedOptions}
      value={isControlled ? getValue() : undefined}
      defaultValue={!isControlled ? getValue() : undefined}
      isClearable={isClearable}
      className={[_dropdownSelector, ...inputClasses].join(' ')}
      classNamePrefix={_dropdownSelector}
      onChange={isControlled ? handleChange : undefined}
      isDisabled={isDisabled}
      styles={styles}
      placeholder={props.placeholder}
      closeMenuOnSelect={isMulti ? false : true}
      ref={props.innerRef || null}
      onMenuOpen={() => {
        setTimeout(() => {
          document
            .getElementsByClassName(
              _dropdownSelector + '__option--is-selected'
            )[0]
            ?.scrollIntoView({
              block: 'center',
              inline: 'start',
            });
        }, 1);
      }}
    />
  );

  const labelAndSelect = (
    <>
      {labelRet}
      {select}
    </>
  );

  if (props.noFormGroup) {
    return labelAndSelect;
  } else {
    return (
      <div className='form-group'>
        {labelRet}
        {select}
      </div>
    );
  }
};
