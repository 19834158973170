import { ICellRendererParams, ColDef } from 'ag-grid-community';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { Icon } from 'pages/Utils/react-utils';
import { getNiceTime, formatDate, dateFormats } from 'utils';
import { IconContainer } from 'components/Planner';

export const ShiftCellRenderer = (props: ICellRendererParams) => {
  return !props.value
    ? ''
    : [
        props.value.shiftTime !== props.data.shiftTime ? '⏰' : null,
        props.value.hasNotes ? <IconContainer>📝</IconContainer> : null,
        props.value.vehicleId && props.value.vehicleId !== props.data.vehicleId
          ? '🚛'
          : null,
      ]
        .filter(Boolean)
        .map((item, index) => (
          <span key={index} className='m-l-xs'>
            {item}
          </span>
        ));
};

export const WorkerCellRenderer = (props: ICellRendererParams) => {
  return !props.value ? 'Unassigned' : props.value;
};

export const ShiftTimeRenderer = (props: ICellRendererParams) => {
  return props.value ? getNiceTime(props.value) : '';
};

export const DeleteColumnRenderer = (props: ICellRendererParams) => {
  return <Icon icon='fa-times' colour='red' />;
};

export const SelectShiftColumnRenderer = (props: ICellRendererParams) => {
  return <Icon icon='fa-check' colour='green' />;
};

export const baseDefaultColDef: ColDef = {
  sortable: false,
  width: 100,
  cellStyle: { cursor: 'pointer' },
};

export const useColumnDefinitions = () => {
  const getColumnDefinitions = (
    startDate: Date,
    numberOfDays: number,
    showVehicleColumn: boolean
  ): ColDef[] => {
    const columnDefinitions: ColDef[] = [
      {
        field: 'workerName',
        headerName: 'Who',
        pinned: 'left',
        cellRenderer: WorkerCellRenderer,
        width: 150,
        cellClassRules: {
          'cell-unassigned': (params) => !params.value,
        },
      },
      {
        field: 'name',
        headerName: 'Shift',
        pinned: 'left',
        width: 150,
        cellStyle: { cursor: 'unset' },
      },
      {
        field: 'shiftTime',
        headerName: 'Time',
        pinned: 'left',
        width: 80,
        cellRenderer: ShiftTimeRenderer,
      },
    ];

    // if (showVehicleColumn) {
    //   columnDefinitions.push({
    //     field: 'vehicleId',
    //     headerName: 'Vehicle',
    //     pinned: 'left',
    //     width: 100,
    //     cellRenderer: (props: ICellRendererParams) => {
    //       if (!props.value) {
    //         return '';
    //       }
    //       return getVehicleReg(props.value);
    //     },
    //   });
    // }

    const iterateForDaysFromTodayAndAddToObject = (numberOfDays: number) => {
      for (let i = 0; i < numberOfDays; i++) {
        const date = new Date(startDate);
        date.setDate(date.getDate() + i);

        const formattedDate = formatDate(date);

        const shiftColumnDefinition: ColDef = {
          colId: `shiftCol-${formattedDate}`,
          cellRenderer: ShiftCellRenderer,
          field: formattedDate,
          headerName: formatDate(date, dateFormats.niceShortDay),
          cellClassRules: {
            'shift-cell': () => true,
            'cell-planned': (params) =>
              params?.value?.status === ShiftUtils.Status.Planned,
            'cell-pre-allocated': (params) =>
              params?.value?.status === ShiftUtils.Status.PreAllocated,
            'cell-accepted': (params) =>
              params?.value?.status === ShiftUtils.Status.Allocated,
            'cell-inProgress': (params) =>
              params?.value?.status === ShiftUtils.Status.InProgress,
            'cell-rejected': (params) =>
              params?.value?.status === ShiftUtils.Status.Rejected,
            'cell-not-started': (params) =>
              params?.value?.status === ShiftUtils.Status.NotStarted,
            'cell-completed': (params) =>
              params?.value?.status === ShiftUtils.Status.Complete ||
              params?.value?.status === ShiftUtils.Status.Approved,
            'cell-cancelled': (params) =>
              params?.value?.status === ShiftUtils.Status.Cancelled,
            'cell-empty': (params) => !params.value,
          },
        };
        columnDefinitions.push(shiftColumnDefinition);
      }
    };
    iterateForDaysFromTodayAndAddToObject(numberOfDays);

    return columnDefinitions;
  };

  return { getColumnDefinitions };
};

export const deleteColumnDefinition: ColDef = {
  field: 'delCol',
  headerName: ' ',
  cellRenderer: DeleteColumnRenderer,
  width: 50,
  pinned: 'left',
  cellStyle: { textAlign: 'center' },
};

export const selectShiftsColumnDefinition: ColDef = {
  field: 'selectCol',
  checkboxSelection: true,
  headerCheckboxSelection: true,
  width: 50,
  pinned: 'left',
  cellStyle: { textAlign: 'center' },
};
