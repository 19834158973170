import { ReactNode } from 'react';
import { Row, Col } from 'react-bootstrap';

export const ShiftSummaryRow = ({
  label,
  value,
  className,
}: {
  label: string;
  value: string | number | ReactNode;
  className?: string;
}) => {
  return (
    <Row className={className}>
      <Col xs={4} style={{ textAlign: 'right' }}>
        <strong>{label}</strong>
      </Col>
      <Col xs={8}>{value}</Col>
    </Row>
  );
};
