import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { arePaymentAdjustmentsAllowed } from 'pages/Shift/ShiftUtils';
import { Icon } from 'pages/Utils/react-utils';
import { ModalContent } from '../Planner/ModalContent';
import { useShiftMenuModalContext } from '../Planner/ShiftMenuModalContext';
import { BackButton, CloseButton } from '../Planner/shiftMenuModalUtils';
import { PaymentAdjustmentForm } from './PaymentAdjustmentForm';
import { PaymentAdjustmentsTable } from './PaymentAdjustmentsTable';

// This component is used for showing payment adjustments in the shift menu modals used in the planners
export const ViewPaymentAdjustments = () => {
  return (
    <ModalContent
      title='View Payment Adjustments'
      Body={<ViewPaymentAdjustmentsBody />}
      Footer={<ViewPaymentAdjustmentsActions />}
    />
  );
};

const ViewPaymentAdjustmentsBody = () => {
  const {
    shiftToHandle: { shiftId, status },
  } = useShiftMenuModalContext();

  const [isFormShowing, setIsFormShowing] = useState(false);
  const areNewAdjustmentsAllowed = arePaymentAdjustmentsAllowed(status);

  return (
    <>
      {!isFormShowing && areNewAdjustmentsAllowed ? (
        <>
          <Button
            bsSize='small'
            bsStyle='primary'
            onClick={() => setIsFormShowing(true)}
          >
            <Icon icon='fa-plus' /> Add Payment Adjustment
          </Button>
          <hr />
        </>
      ) : null}

      {areNewAdjustmentsAllowed ? (
        <PaymentAdjustmentForm
          shiftId={shiftId}
          isFormShowing={isFormShowing}
          closeForm={() => setIsFormShowing(false)}
        />
      ) : null}
      <PaymentAdjustmentsTable shiftId={shiftId} />
    </>
  );
};

const ViewPaymentAdjustmentsActions = () => {
  return (
    <Row>
      <Col sm={4} style={{ textAlign: 'left' }}>
        <BackButton />
      </Col>
      <Col sm={8}>
        <CloseButton />
      </Col>
    </Row>
  );
};
