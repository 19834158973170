import { useRouter } from 'utils/router.js';
import AuthSection from 'components/AuthSection';

function AuthPage() {
  const router = useRouter();

  return (
    <AuthSection
      bg='white'
      textColor='dark'
      size='md'
      bgImage=''
      bgImageOpacity={1}
      type={router.query.type}
      afterAuthPath={router.query.next || '/'}
    />
  );
}

export default AuthPage;
