import {
  IShiftConfig,
  ShiftIndustryType,
  ShiftPaymentType,
  ShiftTemplateStatus,
} from 'types/ShiftConfigTypes';

export const getWorkerType = (shiftConfig: IShiftConfig) => {
  return getWorkerTypeFromIndustryType(shiftConfig.shiftIndustryType);
};

export const getWorkerTypeFromIndustryType = (
  shiftIndustryType: ShiftIndustryType
) => {
  switch (shiftIndustryType) {
    case ShiftIndustryType.HGV:
      return 'driver';

    default:
      return 'worker';
  }
};

export const getAppNameFromIndustryType = (
  shiftIndustryType: ShiftIndustryType
) => {
  switch (shiftIndustryType) {
    case ShiftIndustryType.HGV:
      return 'HGVMe';

    default:
      return 'RightJob';
  }
};

export const isHgvShift = (shiftIndustryType: string) => {
  return shiftIndustryType === ShiftIndustryType.HGV;
};

export const isHgvShiftConfig = (shiftConfig: IShiftConfig) => {
  return isHgvShift(shiftConfig.shiftIndustryType);
};

export const isHourlyShiftConfig = (shiftConfig: IShiftConfig) => {
  return shiftConfig.shiftPaymentType === ShiftPaymentType.Hourly;
};

export const isFixedShiftConfig = (shiftConfig: IShiftConfig) => {
  return shiftConfig.shiftPaymentType === ShiftPaymentType.Fixed;
};

export const isStatusDraft = (shiftConfig: IShiftConfig) => {
  return shiftConfig.templateStatus === ShiftTemplateStatus.Draft;
};

export const isStatusLive = (shiftConfig: IShiftConfig) => {
  return shiftConfig.templateStatus === ShiftTemplateStatus.Live;
};

export const ShiftConfigEvents = {
  ShiftConfigSaved: 'shiftConfig.saved',
  ShiftConfigDeleted: 'shiftConfig.deleted',
  ShiftConfigDuplicated: 'shiftConfig.duplicated',
  ShiftConfigStatusChanged: 'shiftConfig.statusChanged',
  ShiftConfigShiftSyncRequested: 'shiftConfig.shiftSyncRequested',
};
