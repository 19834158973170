import { useState, useMemo } from 'react';
import { Row, Col, Button, Alert, Modal } from 'react-bootstrap';
import {
  useDownloadAllShiftFilesQuery,
  useDownloadShiftFileQuery,
  useShiftFilesQuery,
} from 'hooks/queries';
import { useModal } from 'hooks/useModal';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon } from 'pages/Utils/react-utils';
import { ShiftFile } from 'types/ShiftTypes';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { Stack } from 'components/Stack';
import './ShiftFilesStyle.scss';

export const ShiftFilesUi = ({
  shiftId,
  addBtnSize = 'sm',
  addBtnClassName,
  addBtnContainerClassName,
  showCreatedByColumn = true,
  showCreatedDateTimeColumn = true,
}: {
  shiftId: string;
  addBtnSize?: 'sm' | 'xs';
  addBtnClassName?: string;
  addBtnContainerClassName?: string;
  showCreatedByColumn?: boolean;
  showCreatedDateTimeColumn?: boolean;
}) => {
  return (
    <>
      <FilesTable
        shiftId={shiftId}
        showCreatedByColumn={showCreatedByColumn}
        showCreatedDateTimeColumn={showCreatedDateTimeColumn}
      />
    </>
  );
};

const FilesTable = ({
  shiftId,
  showCreatedByColumn = true,
  showCreatedDateTimeColumn = true,
}: {
  shiftId: string;
  showCreatedByColumn?: boolean;
  showCreatedDateTimeColumn?: boolean;
}) => {
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();
  const [fileToView, setFileToView] = useState<ShiftFile | null>(null);

  const handleShowModalClick = (clickedFile: ShiftFile) => {
    setFileToView(clickedFile);
    handleModalOpen();
  };

  const handleCloseModalClick = () => {
    setFileToView(null);
    handleModalClose();
  };

  const downloadAllQuery = useDownloadAllShiftFilesQuery(shiftId);
  const handleDownloadAllClick = () => {
    downloadAllQuery.refetch();
  };

  const { data, isError, error, isSuccess, isLoading } =
    useShiftFilesQuery(shiftId);

  const records = useMemo(() => data ?? [], [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <ErrorAlert msg={extractErrorMessages(error)} />;
  }

  if (isSuccess && records.length > 0) {
    return (
      <>
        <Button
          bsStyle='primary'
          onClick={() => handleDownloadAllClick()}
          disabled={downloadAllQuery.isFetching}
        >
          <Icon icon='fa-download' /> Download All
        </Button>
        <hr />
        <Stack direction='row' className='file-container'>
          {data.map((file, i) => {
            return (
              <div
                key={file.shiftFileId}
                className='file-item'
                onClick={() => {
                  handleShowModalClick(file);
                }}
              >
                <img
                  src={file.fileUrl}
                  alt={`Shift file ${i}`}
                  className='file-image'
                />
                <span>{file.createdBy}</span>
                <span>{AppUtils.formatDateTimeUtc(file.createdDateTime)}</span>
              </div>
            );
          })}
        </Stack>
        <ViewFileModal
          showModal={isModalOpen}
          handleCloseModalClick={handleCloseModalClick}
          file={fileToView}
          shiftId={shiftId}
        />
      </>
    );
  }

  if (isSuccess && records.length <= 0) {
    return <Alert>No files found.</Alert>;
  }

  return null;
};

export const ViewFileModal = (props: {
  showModal: boolean;
  handleCloseModalClick: () => void;
  file?: ShiftFile;
  shiftId: string;
}) => {
  if (!props.file) {
    return null;
  }
  return (
    <Modal
      backdrop='static'
      show={props.showModal}
      onHide={props.handleCloseModalClick}
    >
      <Modal.Header closeButton>
        <Modal.Title>View File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='file-in-modal-container'>
          <img src={props.file.fileUrl} alt='shift file' />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col sm={12}>
            <Button onClick={props.handleCloseModalClick}>
              <Icon icon='fa-times' /> Close
            </Button>
            <DownloadButton
              shiftId={props.shiftId}
              shiftFileId={props.file.shiftFileId}
            />
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

const DownloadButton = ({
  shiftId,
  shiftFileId,
}: {
  shiftId: string;
  shiftFileId: string;
}) => {
  const { refetch, isFetching } = useDownloadShiftFileQuery(
    shiftId,
    shiftFileId
  );

  return (
    <Button onClick={() => refetch()} disabled={isFetching}>
      <Icon icon='fa-download' /> Download
    </Button>
  );
};
