import { ReactNode } from 'react';
import { WellSmall } from 'pages/Utils/react-utils';

const NoRecordsFoundMessage = <T,>({
  records,
  message,
  refreshButton,
}: {
  records: T[];
  message?: string;
  refreshButton: ReactNode;
}) => {
  if (records.length > 0) {
    return null;
  }
  return (
    <WellSmall>
      {message || `No records found.`}
      {refreshButton}
    </WellSmall>
  );
};

export default NoRecordsFoundMessage;
