import { Alert, Button } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import {
  useDeleteShiftCalculatorLocationMutation,
  useShiftCalculatorLocationsQuery,
} from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { WellSmall } from 'pages/Utils/react-utils';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { Stack } from 'components/Stack';

export const CalculatorLocationsList = ({ hirerId }: { hirerId: string }) => {
  const { data, isSuccess, isLoading } =
    useShiftCalculatorLocationsQuery(hirerId);
  const deleteSegment = useDeleteShiftCalculatorLocationMutation();
  const queryClient = useQueryClient();

  const handleDeleteClick = (locationArrayIndex: number) => {
    deleteSegment.mutate(
      { hirerId, locationArrayIndex },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.CalculatorLocations, hirerId],
          });
        },
      }
    );
  };

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isSuccess && data.length <= 0) {
    return <Alert>No locations found.</Alert>;
  }

  return isSuccess ? (
    <>
      <Stack gap={5}>
        <table className='table table-striped table-hover'>
          <tbody>
            {data.map((segment, i) => {
              return (
                <tr key={i}>
                  <td>
                    {i + 1} - {segment.name}
                  </td>
                  <td>{segment.postcode}</td>
                  <td className='text-right'>
                    <Button
                      bsSize='xs'
                      disabled={deleteSegment.isLoading}
                      bsStyle='danger'
                      onClick={() => handleDeleteClick(i)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {deleteSegment.isLoading ? (
          <WellSmall hasSpinner>
            Deleting and re-calculating, please wait a moment
          </WellSmall>
        ) : null}

        {deleteSegment.isError ? (
          <ErrorAlert msg={extractErrorMessages(deleteSegment.error)} />
        ) : null}
      </Stack>
    </>
  ) : null;
};
