import moment from 'moment';
import { AppUtils } from '../Utils/app-utils';
import { MapToDiv, Icon, SmallMuted } from '../Utils/react-utils';

export const ShiftUtils = {
  isOpen: (record) => record.status === ShiftUtils.Status.Open,
  isPreAllocated: (record) => record.status === ShiftUtils.Status.PreAllocated,
  isAllocated: (record) => record.status === ShiftUtils.Status.Allocated,
  isInProgress: (record) => record.status === ShiftUtils.Status.InProgress,
  isComplete: (record) => record.status === ShiftUtils.Status.Complete,
  isApproved: (record) => record.status === ShiftUtils.Status.Approved,
  isPaymentAuthorised: (record) =>
    record.status === ShiftUtils.Status.PaymentAuthorised,
  ShiftRunWarningTimeHours: 15,
  ShiftPaymentTypes: {
    Fixed: 'Fixed',
    Hourly: 'Hourly',
  },
  ShiftChargeTypes: {
    Margin: 'Margin',
    ChargeRateAndMileage: 'ChargeRateAndMileage',
  },
  Events: {
    ShiftCreated: 'shift.created',
    ContractorShiftEmailsSent: 'shift.contractorEmailsSent',
    ShiftCancelled: 'shift.cancelled',
    ShiftCompleted: 'shift.completed',
    ShiftTimeChanged: 'shift.timeChanged',
    ShiftVehicleChanged: 'shift.vehicleChanged',
    WorkerAllocated: 'worker.allocated',
    TimesUpdated: 'shift.timesUpdated',
    ShiftsApproved: 'shifts.approved',
    ShiftsDeleted: 'shifts.deleted',
    ShiftsPaid: 'shifts.paid',
    ShiftPaymentsAuthorised: 'shifts.paymentAuthorised',
    ShiftsDisapproved: 'shifts.disapproved',
    ShiftRePosted: 'shifts.shiftRePosted',
    FeedbackAdded: 'shifts.feedbackAdded',
    ShiftFinancialsReset: 'shift.financialsReset',
    AdjustmentAdded: 'shift.adjustmentAdded',
    AdjustmentCleared: 'shift.adjustmentCleared',
    ShiftSegmentSaved: 'shift.segmentSaved',
    ShiftSegmentDeleted: 'shift.segmentDeleted',
    ShiftLinked: 'shift.linked',
  },
  Status: {
    Planned: 'Planned',
    Open: 'Open',
    Allocated: 'Allocated',
    PreAllocated: 'Pre Allocated',
    NotStarted: 'Not Started',
    InProgress: 'In Progress',
    Rejected: 'Rejected',
    Complete: 'Complete',
    Approved: 'Approved',
    PaymentAuthorised: 'PaymentAuthorised',
    Cancelled: 'Cancelled',
  },
  ApplicantStatus: {
    Applied: 'Applied',
    Accepted: 'Accepted',
    Rejected: 'Rejected',
  },
  AdjustmentType: {
    Payment: 'Payment',
    Charge: 'Charge',
  },
  AllocationType: {
    Public: 'Public',
    RegisteredWorkers: 'RegisteredWorkers',
    SpecificWorker: 'SpecificWorker',
  },
  ShiftTimesRoundingType: {
    Exact: 'Exact',
    _15Minutes: '15 Minutes',
  },
  ApplicantTypeLabel: (isPublic) =>
    isPublic ? ['Public', 'info'] : ['Registered Only', 'primary'],
  GetShiftStartedData: (record) => {
    // if there is a shiftDateStartActualConfirmed value it means a start date/time has been entered manually, so use it
    // Otherwise use the system generated inProgressDateTime
    // `shiftDateStartActualConfirmed` is stored in local time
    // `inProgressDateTime` is stored in UTC
    // Format accordingly

    if (record.shiftDateStartActualConfirmed) {
      const dtValue = AppUtils.concatDateAndTime(
        record.shiftDateStartActualConfirmed,
        record.shiftTimeStartActualConfirmed
      );
      return {
        by: record.shiftTimeConfirmedBy,
        value: dtValue,
        formatted: AppUtils.formatDateTimeNonUtc(dtValue),
      };
    } else {
      return {
        by: record.inProgressBy,
        value: record.inProgressDateTime,
        formatted: AppUtils.formatDateTimeUtc(record.inProgressDateTime),
      };
    }
  },
};

export const ShiftActualTimeType = {
  Driver: 'Driver',
  Confirmed: 'Confirmed',
};

/**
 * @param shift Shift to retrieve the actual dates/times from the shift
 * @param type Either `Driver` or `Confirmed`
 */
export function GetActualTimes(shift, type) {
  return (
    <MapToDiv>
      {[
        AppUtils.formatDateTimeNonUtc(
          AppUtils.concatDateAndTime(
            shift[`shiftDateStartActual${type}`],
            shift[`shiftTimeStartActual${type}`]
          )
        ),
        AppUtils.formatDateTimeNonUtc(
          AppUtils.concatDateAndTime(
            shift[`shiftDateEndActual${type}`],
            shift[`shiftTimeEndActual${type}`]
          )
        ),
      ]}
    </MapToDiv>
  );
}

export const PaidStatusBadge = ({ record }) => {
  const config = record.isPaid
    ? ['success', 'fa-check', 'Paid']
    : ['muted', 'fa-times', 'Unpaid'];
  return (
    <small className={`text-${config[0]}`}>
      <Icon icon={config[1]} /> {config[2]}
    </small>
  );
};

export function hasShiftBeenRunningTooLong(shift) {
  const now = moment().local();
  const started = AppUtils.getLocalMomentFromUtcVal(shift.inProgressDateTime);
  return now.diff(started, 'hours') > ShiftUtils.ShiftRunWarningTimeHours;
}

const getDifference = (startDate, startTime, endDate, endTime) => {
  if (!startDate || !startTime || !endDate || !endTime) {
    return null;
  }
  const start = AppUtils.concatDateAndTimeAsMoment(startDate, startTime);
  const end = AppUtils.concatDateAndTimeAsMoment(endDate, endTime);
  const diff = end.diff(start);
  const hrs = moment.utc(diff).format('H');
  const mins = moment.utc(diff).format('mm');
  return `${hrs}h ${mins === '00' ? '0m' : ` ${mins}m`}`;
};

/**
 * @param start: start time string - local
 * @param end: end time string - local
 * @param when: when date/time string - utc
 * @param who: name of person string
 */
export const StartToEndCell = (props) => {
  const { start, startDate, end, endDate, when, who } = props;
  if (!start || !end) {
    return null;
  }
  const endDtString = end ? ` - ${AppUtils.formatTime(end)}` : null;

  const diff = getDifference(startDate, start, endDate, end);

  return (
    <>
      {AppUtils.formatTime(start)}
      {endDtString} {diff ? `(${diff})` : null}
      <br />
      <SmallMuted>
        {AppUtils.formatDateTimeUtc(when)}
        <br />
        by {who}
      </SmallMuted>
    </>
  );
};

export const arePaymentAdjustmentsAllowed = (shiftStatus) => {
  return [
    ShiftUtils.Status.Planned,
    ShiftUtils.Status.PreAllocated,
    ShiftUtils.Status.Allocated,
    ShiftUtils.Status.InProgress,
    ShiftUtils.Status.Complete,
    ShiftUtils.Status.Approved,
  ].includes(shiftStatus);
};

export const areShiftLocationsAllowed = (shiftStatus) => {
  return [
    ShiftUtils.Status.Planned,
    ShiftUtils.Status.PreAllocated,
    ShiftUtils.Status.Allocated,
    ShiftUtils.Status.InProgress,
    ShiftUtils.Status.Complete,
    ShiftUtils.Status.Approved,
  ].includes(shiftStatus);
};
