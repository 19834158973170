import { Alert, Button, Modal } from 'react-bootstrap';
import { useCompleteShiftMutation } from 'hooks/queries';
import { useModal } from 'hooks/useModal';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon } from 'pages/Utils/react-utils';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { useShiftContext } from '../ShiftContext';
import { ShiftUtils } from '../ShiftUtils';

export const CompleteShiftButton = ({ status }: { status: string }) => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();

  if (
    ![ShiftUtils.Status.Allocated, ShiftUtils.Status.InProgress].includes(
      status
    )
  ) {
    return null;
  }

  return (
    <>
      <Button
        bsStyle='primary'
        onClick={handleModalOpen}
        bsSize='xs'
        className='m-r-xs'
      >
        <Icon icon='fa-check' /> Complete Shift
      </Button>
      <CompleteShiftModal
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
      />
    </>
  );
};

const CompleteShiftModal = ({
  isModalOpen,
  handleModalClose,
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
}) => {
  const { shiftId } = useShiftContext();
  const {
    mutate: completeShift,
    isError,
    error,
    isLoading,
  } = useCompleteShiftMutation();

  const handleCompleteClick = () => {
    completeShift(
      { shiftId },
      {
        onSuccess: (data) => {
          AppUtils.publish(ShiftUtils.Events.ShiftCompleted, data);
          handleModalClose();
        },
      }
    );
  };
  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Complete Shift</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert bsStyle='info'>
          {`You are going to mark this shift as completed. Are you sure you want to proceed?`}
        </Alert>
        {isError ? (
          <ErrorAlertForQueryOrNull isError={isError} error={error} />
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose} bsSize='sm'>
          <i className='fa fa-times' /> Close
        </Button>
        <ModalSaveButton isLoading={isLoading} onClick={handleCompleteClick} />
      </Modal.Footer>
    </Modal>
  );
};

const ModalSaveButton = ({
  isLoading,
  onClick,
}: {
  isLoading: boolean;
  onClick: () => void;
}) => {
  const isDisabled = isLoading;
  const buttonTitle = 'Yes, Complete Shift';
  return (
    <Button
      bsStyle='primary'
      onClick={onClick}
      disabled={isDisabled}
      bsSize='sm'
    >
      <Icon icon='fa-check' isSpinning={isLoading} /> {buttonTitle}
    </Button>
  );
};
