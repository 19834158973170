import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useInvoiceQuery } from 'hooks/queries';
import useSubscription from 'hooks/useSubscription';
import { Invoice, InvoiceStatus } from 'types/Invoice';
import { InvoiceEvents } from '../invoiceUtils';

interface IInvoiceContext {
  invoiceId: string;
  invoice?: Invoice;
  query: ReturnType<typeof useInvoiceQuery>;
  isEditable: boolean;
}

const InvoiceContext = createContext<IInvoiceContext | undefined>(undefined);

interface IInvoiceContextProviderProps {
  children: ReactNode;
}

export const InvoiceContextProvider = ({
  children,
}: IInvoiceContextProviderProps) => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  if (!invoiceId) {
    throw new Error('Could not get invoice id from url');
  }
  const query = useInvoiceQuery(invoiceId);
  const invoice = query.data;

  useSubscription(
    [
      InvoiceEvents.Deleted,
      InvoiceEvents.Paid,
      InvoiceEvents.Saved,
      InvoiceEvents.Sent,
    ],
    query.refetch
  );

  const value = useMemo<IInvoiceContext>(() => {
    return {
      invoiceId,
      invoice,
      query,
      isEditable: invoice
        ? invoice.status === InvoiceStatus.Draft ||
          invoice.status === InvoiceStatus.Rejected
        : false,
    };
  }, [query, invoice, invoiceId]);

  return (
    <InvoiceContext.Provider value={value}>{children}</InvoiceContext.Provider>
  );
};

export const useInvoiceContext = () => {
  const context = useContext(InvoiceContext);
  if (context === undefined) {
    throw new Error(
      'useInvoiceContext must be used within a InvoiceContextProvider'
    );
  }
  return context;
};
