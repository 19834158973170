import {
  ArchiveTrailerParams,
  CreateTrailerParams,
  GetTrailerSummaryParams,
  IGetTrailersResponse,
  Trailer,
} from 'types/TrailerTypes';
import { get, post, put } from './apiBase';

const createTrailer = (params: CreateTrailerParams) => {
  return post(`Trailer`, params);
};

const editTrailer = (params: Trailer) => {
  return put(`Trailer/${params.trailerId}`, params);
};

const archiveTrailer = (params: ArchiveTrailerParams) => {
  return put(`Trailer/${params.trailerId}/Archive`);
};

export const getTrailerById = async (trailerId: string) => {
  return get(`Trailer/${trailerId}`);
};

export const getTrailerEditConfig = async () => {
  return get(`Trailer/EditConfig`);
};

export const getTrailersActive = async () => {
  return get<IGetTrailersResponse>(`Trailer/Active`);
};

export const getTrailerSummary = async ({
  trailerId,
  shiftDate,
}: GetTrailerSummaryParams) => {
  return get<{ shifts: { shiftDateTime: string; shiftId: string }[] }>(
    `Trailer/${trailerId}/${shiftDate}/Summary`
  );
};

export const trailerApi = {
  createTrailer,
  editTrailer,
  archiveTrailer,
  getTrailerById,
  getTrailerEditConfig,
  getTrailersActive,
  getTrailerSummary,
};
