import { useMutation, useQuery } from '@tanstack/react-query';
import { userApi } from 'apis/userApi';
import {
  AddPermissionToUserParams,
  RemovePermissionFromUserParams,
} from 'types/UserTypes';
import { QueryKeys } from './queryUtils';

export const useUserPermissionsQuery = (userAccountid: string) => {
  return useQuery({
    queryKey: [QueryKeys.UserPermissions, userAccountid],
    queryFn: () => {
      return userApi.getUserPermissions(userAccountid);
    },
  });
};

export const useAddPermissionToUserMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: AddPermissionToUserParams) => {
      return userApi.addPermissionToUser(params);
    },
  });

  return mutation;
};

export const useRemovePermissionFromUserMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: RemovePermissionFromUserParams) => {
      return userApi.removePermissionFromUser(params);
    },
  });

  return mutation;
};
