import { useState } from 'react';
import { Button, Col, Panel, Row } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { Dropdown } from 'components';
import { useSaveShiftSegmentMutation } from 'hooks/queries';
import { useGetLocationsByHirerQuery } from 'hooks/queries/locationQueries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { ShiftUtils, areShiftLocationsAllowed } from 'pages/Shift/ShiftUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, InputField } from 'pages/Utils/react-utils';
import { ICustomEvent } from 'types/GeneralTypes';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { Stack } from 'components/Stack';
import { ShiftSegmentsList } from './ShiftSegmentsList';

export const ShiftSegments = ({
  shiftId,
  hirerId,
  status,
}: {
  status: string;
  shiftId: string;
  hirerId: string;
}) => {
  const [isFormShowing, setIsFormShowing] = useState(false);
  const areNewSegmentsAllowed = areShiftLocationsAllowed(status);

  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col sm={8}>
            <Icon icon='fa-map' /> Shift Locations
          </Col>
          {areNewSegmentsAllowed && !isFormShowing ? (
            <Col sm={4} style={{ textAlign: 'right' }}>
              <Button
                bsStyle='primary'
                bsSize='xs'
                onClick={() => setIsFormShowing(true)}
              >
                <Icon icon='fa-plus' /> Add Location
              </Button>
            </Col>
          ) : null}
        </Row>
      </Panel.Heading>
      <Panel.Body>
        <Stack gap={10}>
          {areNewSegmentsAllowed && isFormShowing ? (
            <ShiftSegmentForm
              hirerId={hirerId}
              shiftId={shiftId}
              setIsFormShowing={setIsFormShowing}
            />
          ) : null}

          <ShiftSegmentsList
            shiftId={shiftId}
            setIsFormShowing={setIsFormShowing}
          />
        </Stack>
      </Panel.Body>
    </Panel>
  );
};

const ShiftSegmentForm = ({
  shiftId,
  hirerId,
  locationId,
  setIsFormShowing,
}: {
  shiftId: string;
  hirerId: string;
  locationId?: string;
  setIsFormShowing: (val: boolean) => void;
}) => {
  const query = useGetLocationsByHirerQuery({
    hirerId,
  });

  const [selectedLocationId, setSelectedLocationId] = useState<string | null>(
    null
  );

  const [selectedLocation, setSelectedLocation] = useState<{
    name: string;
    postcode: string;
    distanceMiles: number;
  } | null>(null);

  const handleSelectLocation = (locationId: string) => {
    if (!locationId) {
      // They've pressed the clear button
      setSelectedLocationId(null);
      setSelectedLocation(null);
      return;
    }

    // Get location and write to state
    const location = query.data?.find(
      (location) => location.locationId === locationId
    );

    if (!location) {
      AppUtils.displayError(
        'Error',
        'Could not get location. Please try again.'
      );
      return;
    }

    setSelectedLocationId(locationId);

    setSelectedLocation({
      name: location.name,
      postcode: location.postcode,
      distanceMiles: location.distanceMiles,
    });
  };

  const handleUpdateProperty = (
    property: keyof typeof selectedLocation,
    value: string
  ) => {
    setSelectedLocation({
      ...selectedLocation,
      [property]: value,
    });
  };

  const { mutate, isError, error, isLoading } = useSaveShiftSegmentMutation();

  const queryClient = useQueryClient();
  const handleSaveClick = () => {
    if (!selectedLocationId) {
      AppUtils.displayError('Error', 'Please select a location');
      return;
    }

    mutate(
      {
        shiftId,
        locationId: selectedLocationId,
      },
      {
        onSuccess: () => {
          AppUtils.displaySuccess('Success', 'Location added');
          setSelectedLocationId(null);
          setSelectedLocation(null);
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ShiftSegments, shiftId],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.VehiclePlanForHirer, hirerId],
          });
          AppUtils.publish(ShiftUtils.Events.ShiftSegmentSaved);
        },
      }
    );
  };

  return (
    <>
      <Row>
        <Col sm={locationId ? 6 : 12}>
          <LocationSelect
            selectedLocationId={selectedLocationId}
            handleSelectLocation={handleSelectLocation}
            hirerId={hirerId}
          />
        </Col>
        {locationId ? (
          <>
            <Col sm={4}>
              <InputField
                isControlled
                label='Name'
                value={selectedLocation?.name}
                onChange={(e: ICustomEvent) => {
                  handleUpdateProperty('name', e.target.value);
                }}
              />
            </Col>
            <Col sm={4}>
              <InputField
                isControlled
                label='Postcode'
                value={selectedLocation?.postcode}
                onChange={(e: ICustomEvent) => {
                  handleUpdateProperty('postcode', e.target.value);
                }}
              />
            </Col>

            <Col sm={4}>
              <InputField
                isControlled
                label='Distance'
                value={selectedLocation?.distanceMiles}
                onChange={(e: ICustomEvent) => {
                  handleUpdateProperty('postcode', e.target.value);
                }}
                addOnPost='Miles'
              />
            </Col>
          </>
        ) : null}
      </Row>

      {isError ? (
        <Row>
          <Col xs={12}>
            <ErrorAlert msg={extractErrorMessages(error)} />
          </Col>
        </Row>
      ) : null}

      <Stack direction='row' style={{ justifyContent: 'flex-end' }}>
        <Button
          bsStyle='danger'
          bsSize='sm'
          onClick={() => setIsFormShowing(false)}
          disabled={isLoading}
          className='m-r-sm'
        >
          <Icon icon='fa-times' isSpinning={isLoading} /> Cancel
        </Button>
        <Button
          bsStyle='primary'
          bsSize='sm'
          onClick={handleSaveClick}
          disabled={isLoading}
        >
          <Icon icon='fa-save' isSpinning={isLoading} /> Save Location
        </Button>
      </Stack>

      <hr />
    </>
  );
};

const LocationSelect = ({
  selectedLocationId,
  handleSelectLocation,
  hirerId,
}: {
  selectedLocationId: string | null;
  handleSelectLocation: (selectedLocationId: string) => void;
  hirerId: string;
}) => {
  const query = useGetLocationsByHirerQuery({
    hirerId,
  });

  if (query.isLoading) {
    return <span>Loading...</span>;
  }

  if (query.isSuccess) {
    const data = query.data ?? [];
    const options = data.map((location) => ({
      text: location.name,
      value: location.locationId,
    }));

    return (
      <Dropdown
        value={selectedLocationId}
        label='Choose Location'
        options={options}
        onChange={(e: ICustomEvent) => {
          handleSelectLocation(e.target.value);
        }}
      />
    );
  }
};
