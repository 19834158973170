import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { invoiceApi } from 'apis/invoiceApi';
import { AxiosResponse } from 'axios';
import useSubscription from 'hooks/useSubscription';
import { InvoiceEvents } from 'pages/Agency/Invoice/invoiceUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import {
  AddShiftToInvoiceParams,
  DeleteInvoiceParams,
  DownloadInvoiceParams,
  MarkAsPaidInvoiceParams,
  RemoveInvoiceItemFromInvoiceParams,
  GetShiftsAwaitingInvoiceQueryParams,
  GetInvoicesQueryParams,
  CreateInvoiceParams,
  GetShiftsAwaitingInvoiceSummaryQueryParams,
  InvoiceEditParams,
  RequestApprovalInvoiceParams,
  ApproveInvoiceParams,
  RejectInvoiceParams,
} from 'types/Invoice';
import { extractErrorMessages } from 'utils';
import { QueryKeys } from './queryUtils';

export const useCreateInvoiceMutation = () => {
  return useMutation({
    mutationFn: (params: CreateInvoiceParams) => {
      return invoiceApi.createInvoice(params);
    },
  });
};

export const useInvoiceQuery = (invoiceId: string) => {
  const query = useQuery({
    queryKey: [QueryKeys.GetInvoice, invoiceId],
    queryFn: () => invoiceApi.getInvoice(invoiceId),
    enabled: Boolean(invoiceId),
  });

  return query;
};

export const useAwaitingInvoiceShiftsQuery = (
  params: GetShiftsAwaitingInvoiceQueryParams
) => {
  return useQuery({
    queryKey: [QueryKeys.GetInvoice, 'shiftsAwaitingInvoice', params.hirerId],
    queryFn: () => {
      return invoiceApi.getShiftsAwaitingInvoice(params);
    },
  });
};

export const useAwaitingInvoiceShiftsSummaryQuery = (
  params: GetShiftsAwaitingInvoiceSummaryQueryParams
) => {
  return useQuery({
    queryKey: [QueryKeys.GetInvoice, 'shiftsAwaitingInvoiceSummary', params],
    queryFn: () => {
      return invoiceApi.getShiftsAwaitingInvoiceSummary(params);
    },
    enabled:
      params.hirerId &&
      ((params.queryType.type === 'weekNumber' &&
        params.queryType.data.year &&
        params.queryType.data.weekNumber) ||
        (params.queryType.type === 'dateRange' &&
          params.queryType.data.fromDate &&
          params.queryType.data.toDate))
        ? true
        : false,
  });
};

export const useRemoveShiftFromInvoiceMutation = () => {
  return useMutation({
    mutationFn: (params: RemoveInvoiceItemFromInvoiceParams) => {
      return invoiceApi.removeShift(params);
    },
  });
};

export const useAddShiftToInvoiceMutation = () => {
  return useMutation({
    mutationFn: (params: AddShiftToInvoiceParams) => {
      return invoiceApi.addShift(params);
    },
  });
};

export const useDeleteInvoiceMutation = () => {
  return useMutation({
    mutationFn: (params: DeleteInvoiceParams) => {
      return invoiceApi.deleteInvoice(params);
    },
  });
};

export const useRequestApprovalInvoiceMutation = () => {
  return useMutation({
    mutationFn: (params: RequestApprovalInvoiceParams) => {
      return invoiceApi.requestApproval(params);
    },
  });
};

export const useApproveInvoiceMutation = () => {
  return useMutation({
    mutationFn: (params: ApproveInvoiceParams) => {
      return invoiceApi.approve(params);
    },
  });
};

export const useRejectInvoiceMutation = () => {
  return useMutation({
    mutationFn: (params: RejectInvoiceParams) => {
      return invoiceApi.reject(params);
    },
  });
};

export const useDeleteAllInvoiceItemsMutation = () => {
  return useMutation({
    mutationFn: (params: DeleteInvoiceParams) => {
      return invoiceApi.deleteAllInvoiceItems(params);
    },
  });
};

export const useMarkAsPaidInvoiceMutation = () => {
  return useMutation({
    mutationFn: (params: MarkAsPaidInvoiceParams) => {
      return invoiceApi.markInvoiceAsPaid(params);
    },
  });
};

export const useDownloadInvoiceQuery = (params: DownloadInvoiceParams) => {
  const { invoiceId } = params;
  return useQuery({
    queryKey: [QueryKeys.GetInvoiceDownload, invoiceId],
    queryFn: () => {
      return invoiceApi.getInvoiceDownload(params);
    },
    enabled: false,
    retry: false,
    onSuccess: (response) => {
      handleDownloadBlob(response);
    },
    onError: (error) => {
      AppUtils.displayError('Error', extractErrorMessages(error));
    },
  });
};

const handleDownloadBlob = (response: AxiosResponse<Blob, unknown>) => {
  const url = window.URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.href = url;

  const header = response.headers['content-disposition'];
  const fileName = header
    ? header.split(';')[1].split('=')[1].replaceAll('"', '')
    : 'invoiceDownload.xlsx';

  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const useGetInvoicesQuery = (params: GetInvoicesQueryParams) => {
  const queryKey = [QueryKeys.GetInvoices, params.hirerId];
  const query = useQuery({
    queryKey,
    queryFn: () => invoiceApi.getInvoices(params),
  });

  const queryClient = useQueryClient();
  useSubscription(
    [InvoiceEvents.Paid, InvoiceEvents.Sent, InvoiceEvents.Deleted],
    () => {
      queryClient.invalidateQueries(queryKey);
    }
  );

  return query;
};

export const useEditInvoiceMutation = () => {
  const mutation = useMutation({
    mutationFn: (params: InvoiceEditParams) => {
      return invoiceApi.editInvoice(params);
    },
  });

  return mutation;
};
