import { Row, Col, Button } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import {
  useAddPermissionToUserMutation,
  useUserPermissionsQuery,
} from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon } from 'pages/Utils/react-utils';
import {
  usePermissions,
  userPermissions,
  userPermissionsLanguage,
} from 'utils/userPermissions';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';

const containerName = 'addPermissionContainer';

export const PermissionForm = ({
  userAccountId,
  isFormShowing,
  closeForm,
}: {
  userAccountId: string;
  isFormShowing: boolean;
  closeForm: () => void;
}) => {
  const { hasPermission } = usePermissions();
  const canAddPermissions = hasPermission(userPermissions.agency.user.manage);
  const userPermissionsQuery = useUserPermissionsQuery(userAccountId);
  const permissionDataForUser = userPermissionsQuery.data ?? [];

  const { mutate, isError, error, isLoading } =
    useAddPermissionToUserMutation();

  const queryClient = useQueryClient();

  const handleSaveClick = () => {
    if (!canAddPermissions) {
      AppUtils.displayError('Error', 'You do not have permission');
      return;
    }

    const $container = $(`#${containerName}`);

    if (!AppUtils.validateItems($container.find('.required'))) {
      return false;
    }

    const dataModel = AppUtils.getDataModel($container) as {
      PermissionName: string;
    };

    const params = {
      userAccountId,
      permissionName: dataModel.PermissionName,
    };
    mutate(params, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserPermissions, userAccountId],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.AgencyUsers],
        });

        AppUtils.displaySuccess('Success', 'Permission added');

        closeForm();
      },
    });
  };

  const options = Object.entries(userPermissionsLanguage)
    .filter(
      (permission) =>
        !permissionDataForUser.find(
          (userPermission) => userPermission.name === permission[0]
        )
    )
    .map(([value, text]) => ({
      text,
      value,
    }));

  return (
    <div id={containerName}>
      {isFormShowing ? (
        <>
          <Row>
            <Field.InCol
              columns={12}
              name='PermissionName'
              label='Permission'
              options={options}
              isSelect
              required
            />
          </Row>

          <Row>
            <Col sm={12} style={{ textAlign: 'right' }} className='m-b-sm'>
              <Button
                bsStyle='danger'
                bsSize='small'
                onClick={closeForm}
                disabled={isLoading}
                className='m-r-sm'
              >
                <Icon icon='fa-times' isSpinning={isLoading} /> Cancel
              </Button>
              {canAddPermissions ? (
                <Button
                  bsSize='small'
                  bsStyle='primary'
                  onClick={handleSaveClick}
                  disabled={isLoading}
                >
                  <Icon icon='fa-save' isSpinning={isLoading} /> Add Permission
                </Button>
              ) : null}
              <hr />
            </Col>
          </Row>

          <ErrorAlertForQueryOrNull isError={isError} error={error} />
        </>
      ) : null}
    </div>
  );
};
