import { Component } from 'react';
import { Tab, Tabs, Well } from 'react-bootstrap';
import { SpinIcon, WellSmall } from 'pages/Utils/react-utils';
import { getAgencyAdminConfig } from 'utils/api';
import { AgencyListView } from './AgencyListView';
import { AddAgencyButton } from './Buttons/AddAgencyButton';

class AgencyAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isGettingConfig: false,
      configuration: null,
      activeTab: 1,
    };

    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  componentDidMount() {
    this.getConfig();
  }

  async getConfig() {
    this.setState({ isGettingConfig: true });
    const data = await getAgencyAdminConfig();
    if (data.configuration) {
      this.setState({ configuration: data.configuration });
    }
    this.setState({ isGettingConfig: false });
  }

  handleTabSelect(tabToMakeActive) {
    console.log(`AgencyAdmin.handleTabSelect: ${tabToMakeActive}`);
    this.setState({ activeTab: tabToMakeActive });
  }

  render() {
    console.log('AgencyAdmin.render');
    if (!this.state.configuration) {
      return null;
    }
    if (this.state.isGettingConfig) {
      return <GettingConfigMsg {...this.state} />;
    }
    return (
      <>
        <Intro />
        <TabsMain {...this.state} handleTabSelect={this.handleTabSelect} />
      </>
    );
  }
}

const Intro = (props) => {
  return (
    <WellSmall className='white-bg'>
      <AddAgencyButton {...props} className='m-b-none' />
    </WellSmall>
  );
};

const TabsMain = ({ activeTab, handleTabSelect }) => {
  return (
    <div className='tabs-container'>
      <Tabs
        activeKey={activeTab}
        animation={false}
        onSelect={handleTabSelect}
        id='agency-admin-tabs'
        mountOnEnter={true}
      >
        <Tab eventKey={1} title='Agencies'>
          <AgenciesTab />
        </Tab>
      </Tabs>
    </div>
  );
};

const GettingConfigMsg = ({ isGettingConfig }) => {
  if (!isGettingConfig) {
    return null;
  }
  return (
    <Well>
      <SpinIcon /> Please wait while the agency admin area is configured...
    </Well>
  );
};

const AgenciesTab = () => {
  console.log('AgenciesTab.render');
  return <AgencyListView />;
};

export default AgencyAdmin;
