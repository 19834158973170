import { useAuth } from './auth';

export const userPermissions = {
  shift: {
    create: {
      outsidePlanner: 'shift.createOutsideOfPlanner',
      inPast: 'shift.createInPast',
    },
    awaitingPaymentAuth: {
      view: 'shift.awaitingPaymentAuth.view',
      authorise: 'shift.awaitingPaymentAuth.authorise',
      authoriseWithAdjustment:
        'shift.awaitingPaymentAuth.authorisePaymentAdjustments',
    },
    awaitingPayment: {
      view: 'shift.awaitingPayment.view',
      markAsPaid: 'shift.awaitingPayment.markAsPaid',
    },
    finance: {
      view: 'shift.finance.view',
    },
  },
  shiftTemplate: {
    view: 'shiftTemplate.view',
    edit: 'shiftTemplate.edit',
  },
  shiftPlanner: {
    view: 'shiftPlanner.view',
    delete: 'shiftPlanner.delete',
  },
  vehiclePlanner: {
    view: 'vehiclePlanner.view',
    delete: 'vehiclePlanner.delete',
  },
  planner: {
    acceptPastShifts: 'planner.acceptPastShifts',
  },
  agency: {
    user: {
      manage: 'agency.user.manage',
    },
  },
  reports: {
    view: 'reports.view',
  },
  invoice: {
    approve: 'invoice.approve',
  },
} as const;

export const userPermissionsLanguage: Record<string, string> = {
  [userPermissions.agency.user.manage]: 'Agency Users - Manage',
  [userPermissions.planner.acceptPastShifts]: 'Planner - Accept Past Shifts',
  [userPermissions.shift.awaitingPaymentAuth.authorise]:
    'Payments Awaiting Authorisation - Authorise',
  [userPermissions.shift.awaitingPaymentAuth.authoriseWithAdjustment]:
    'Payments Awaiting Authorisation - Authorise with Payment Adjustment',
  [userPermissions.shift.awaitingPaymentAuth.view]:
    'Payments Awaiting Authorisation - View',
  [userPermissions.shift.awaitingPayment.markAsPaid]:
    'Shifts Awaiting Payment - Mark as Paid',
  [userPermissions.shift.create.outsidePlanner]:
    'Shifts - Create Outside of Planner',
  [userPermissions.shift.create.inPast]: 'Shifts - Create In the past',
  [userPermissions.shift.awaitingPayment.view]:
    'Shifts Awaiting Payment - View',
  [userPermissions.shift.finance.view]: 'Shift Finance - View',
  [userPermissions.shiftTemplate.edit]: 'Shift Templates - Edit',
  [userPermissions.shiftTemplate.view]: 'Shift Templates - View',
  [userPermissions.shiftPlanner.view]: 'Shift Planner - View',
  [userPermissions.shiftPlanner.delete]: 'Shift Planner - Delete Row',
  [userPermissions.vehiclePlanner.view]: 'Vehicle Planner - View',
  [userPermissions.vehiclePlanner.delete]: 'Vehicle Planner - Delete Row',
  [userPermissions.reports.view]: 'Reports - View',
  [userPermissions.invoice.approve]: 'Invoice - Approve',
} as const;

export const getPermissionDisplayName = (permission: string) => {
  return userPermissionsLanguage[permission] ?? permission;
};

export const usePermissions = () => {
  const { hasPermission } = useAuth();

  return {
    hasPermission,
  };
};
