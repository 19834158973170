import { Button, Row } from 'react-bootstrap';
import {
  useGetVehicleCostConfigQuery,
  useSaveVehicleCostConfigMutation,
} from 'hooks/queries/agencyQueries';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall } from 'pages/Utils/react-utils';
import { VehicleCostConfig } from 'types/AgencyTypes';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { Stack } from 'components/Stack';

const containerName = 'vehicleCostConfigContainer';

export const VehicleCostConfigTab = () => {
  const { isLoading, isFetching, isSuccess, data, isError, error } =
    useGetVehicleCostConfigQuery();

  if (isLoading || (isFetching && !data)) {
    return <WellSmall hasSpinner />;
  }

  if (isError) {
    return <ErrorAlert msg={extractErrorMessages(error)} />;
  }

  if (isSuccess) {
    return (
      <div id={containerName}>
        <Row>
          <Field.InCol
            columns={3}
            name='AverageMilesPerGallon'
            label='Average MPG'
            value={AppUtils.formatNumber(data.averageMilesPerGallon)}
            maxLength='5'
            isNumber
            required
            tooltip='Average Miles Per Gallon. This is used to calculate the fuel costs when a vehicle is used on a shift.'
          />
          <Field.InCol
            columns={3}
            name='FuelCostPerLitre'
            label='Fuel Cost Per Litre'
            value={AppUtils.formatNumber(data.fuelCostPerLitre)}
            maxLength='9'
            isNumber
            addOnPre='£'
            addOnPost={'Per Litre'}
            required
          />
          <Field.InCol
            columns={3}
            name='TyreCostPerMile'
            label='Tyre Cost Per Mile'
            value={AppUtils.formatNumber(data.tyreCostPerMile)}
            maxLength='9'
            isNumber
            addOnPre='£'
            addOnPost={'Per Mile'}
            required
          />
          <Field.InCol
            columns={3}
            name='AdBlueCostPerMile'
            label='AdBlue Cost Per Mile'
            value={AppUtils.formatNumber(data.adBlueCostPerMile)}
            maxLength='9'
            isNumber
            addOnPre='£'
            addOnPost={'Per Mile'}
            required
          />
        </Row>

        <WellSmall>
          <SaveButton />
        </WellSmall>
      </div>
    );
  }

  return null;
};

const SaveButton = () => {
  const { mutate, isError, error, isLoading } =
    useSaveVehicleCostConfigMutation();

  const handleSaveClick = () => {
    const $container = $(`#${containerName}`);

    if (!AppUtils.validateItems($container.find('.required'))) {
      return false;
    }

    const dataModel = AppUtils.getDataModel($container) as VehicleCostConfig;

    mutate(dataModel, {
      onSuccess: () => {
        AppUtils.displaySuccess('Success', 'Vehicle Cost Config updated');
      },
    });
  };

  return (
    <Stack gap={10}>
      <div className='text-right'>
        <Button
          bsStyle='info'
          bsSize='sm'
          onClick={handleSaveClick}
          disabled={isLoading}
        >
          <Icon icon='fa-check' isSpinning={isLoading} /> Save Vehicle Cost
          Config
        </Button>
      </div>
      {isError ? <ErrorAlert msg={extractErrorMessages(error)} /> : null}
    </Stack>
  );
};
