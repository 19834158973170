import { Link } from 'utils/router.js';
import './AuthFooter.scss';

function AuthFooter(props) {
  return (
    <div className='AuthFooter text-center m-t-md'>
      {props.type === 'signup' && (
        <>
          Have an account already?
          <Link to='/auth/signin'>{props.typeValues.linkTextSignin}</Link>
        </>
      )}

      {props.type === 'signin' && (
        <>
          <Link to='/auth/forgotpass'>
            {props.typeValues.linkTextForgotpass}
          </Link>
        </>
      )}
    </div>
  );
}

export default AuthFooter;
