import { useQuery } from '@tanstack/react-query';
import useSubscription from 'hooks/useSubscription';
import { AppUtils } from 'pages/Utils/app-utils';
import { SmallMuted } from 'pages/Utils/react-utils';
import { getHirersPending } from 'utils/api';
import { useAlertWithRefreshButton } from 'components/AlertWithRefreshButton';
import DataTable from 'components/DataTable';
import { NoRecordsFoundMessage } from 'components/NoRecordsFoundMessage';
import { HirerUtils } from './HirerUtils';

const tableId = 'hirers-pending-table';

const HirerListViewPending = () => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['getHirersPending'],
    getHirersPending
  );
  const records = data?.records || [];

  useSubscription(
    [
      HirerUtils.Events.HirerSaved,
      HirerUtils.Events.HirerDeleted,
      HirerUtils.Events.HirerInvited,
    ],
    refetch
  );

  const { alert, refreshButton } = useAlertWithRefreshButton(
    'Customers who have been invited but not yet accepted or rejected their invite.',
    isLoading,
    isFetching,
    refetch
  );

  if (records.length <= 0) {
    return (
      <NoRecordsFoundMessage
        records={records}
        message='No Pending Invitations found.'
        refreshButton={refreshButton}
      />
    );
  }

  return (
    <>
      {alert}
      <DataTable
        records={records}
        tableId={tableId}
        render={() => {
          return (
            <table className='table table-condensed table-striped' id={tableId}>
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Contact Name</th>
                  <th>Email Address</th>
                  <th>Invited</th>
                </tr>
              </thead>
              <tbody>
                {records.map((record, i) => (
                  <Row key={i} record={record} />
                ))}
              </tbody>
            </table>
          );
        }}
      />
    </>
  );
};

const Row = (props) => {
  const { record } = props;
  return (
    <tr>
      <td>{record.companyName}</td>
      <td>
        {record.firstName} {record.surname}
      </td>
      <td>{record.emailAddress}</td>
      <td data-order={record.createdDateTime}>
        {AppUtils.formatDateTimeUtc(record.createdDateTime)}
        <br />
        <SmallMuted>by {record.createdBy}</SmallMuted>
      </td>
    </tr>
  );
};

export default HirerListViewPending;
