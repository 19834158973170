import { useState } from 'react';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { useCreateSingleShiftMutation } from 'hooks/queries/shiftPlanningQueries';
import { useModal } from 'hooks/useModal';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import { CreateSingleShiftForDateParams } from 'types/ShiftPlanningTypes';
import { dateFormats, extractErrorMessages, formatDate } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';

export const useCreateSingleShift = (selectedCustomerId: string) => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();

  const [createSingleShiftParams, setCreateSingleShiftParams] =
    useState<CreateSingleShiftForDateParams | null>(null);

  const handleCreateSingleShiftModalOpen = (
    params: CreateSingleShiftForDateParams
  ) => {
    setCreateSingleShiftParams(params);
    handleModalOpen();
  };

  const handleCreateSingleShiftModalClose = () => {
    setCreateSingleShiftParams(null);
    handleModalClose();
  };

  const renderCreateSingleShiftModal = () => {
    if (!isModalOpen || !createSingleShiftParams) {
      return null;
    }

    return (
      <CreateSingleShiftModal
        isModalOpen={isModalOpen}
        handleModalClose={handleCreateSingleShiftModalClose}
        params={createSingleShiftParams}
        hirerId={selectedCustomerId}
      />
    );
  };

  return {
    handleCreateSingleShiftModalOpen,
    renderCreateSingleShiftModal,
  };
};

export const CreateSingleShiftModal = ({
  isModalOpen,
  handleModalClose,
  params,
  hirerId,
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
  params: CreateSingleShiftForDateParams;
  hirerId: string;
}) => {
  const { date, workerName } = params;
  const queryClient = useQueryClient();

  const createSingleShiftMutation = useCreateSingleShiftMutation();
  const handleSubmitClick = () => {
    createSingleShiftMutation.mutate(params, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ShiftPlanForHirer],
        });
        AppUtils.displaySuccess('Success', 'Shift created successfully');
        handleModalClose();
      },
    });
  };

  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Create Shift for {formatDate(date, dateFormats.niceShortDay)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WellSmall>
          <p>
            You are going to create a shift for <strong>{workerName}</strong> on{' '}
            <strong>{formatDate(date, dateFormats.niceShortDay)}</strong>.
          </p>
        </WellSmall>

        <Alert bsStyle='info'>Are you sure you want to proceed?</Alert>

        {createSingleShiftMutation.isError ? (
          <Row>
            <Col xs={12}>
              <ErrorAlert
                msg={extractErrorMessages(createSingleShiftMutation.error)}
              />
            </Col>
          </Row>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>
          <i className='fa fa-times' /> Close
        </Button>
        <ModalSaveButton
          isSaved={createSingleShiftMutation.isSuccess}
          isSaving={createSingleShiftMutation.isLoading}
          onClick={handleSubmitClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

const ModalSaveButton = ({
  isSaving,
  isSaved,
  onClick,
}: {
  isSaving: boolean;
  isSaved: boolean;
  onClick: () => void;
}) => {
  const isDisabled = isSaving || isSaved;
  const buttonTitle = isSaving
    ? 'Creating...'
    : isSaved
    ? 'Created!'
    : `Yes, Create Shift`;
  return (
    <Button bsStyle='success' onClick={onClick} disabled={isDisabled}>
      <Icon icon='fa-times' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};
