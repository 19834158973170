import { useState } from 'react';
import { Modal, Alert, Row, Col, Button } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useSendMultipleShiftsToWorkersMutation } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import moment from 'moment';
import { DatePicker } from 'pages/Utils/DatePicker';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { usePostShiftsConfigQuery } from '../shiftPlannerHooks';

const _storedDateFormat = 'YYYY-MM-DD';
const _displayDateFormat = 'DD/MM/YYYY';

export const PostShiftsModal = ({
  isModalOpen,
  handleModalClose,
  hirerId,
  shiftPlanItemIds,
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
  hirerId: string;
  shiftPlanItemIds: string[];
}) => {
  const [fromDate, setFromDate] = useState(moment().format(_storedDateFormat));
  const [toDate, setToDate] = useState(
    moment().add(30, 'days').format(_storedDateFormat)
  );

  const queryClient = useQueryClient();

  const { isLoading, isSuccess, data, isError, error } =
    usePostShiftsConfigQuery({
      hirerId,
      shiftPlanItemIds,
      fromDate,
      toDate,
    });
  const postShiftsMutation = useSendMultipleShiftsToWorkersMutation();

  const handleSubmitClick = () => {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }

    postShiftsMutation.mutate(
      {
        hirerId,
        shiftPlanItemIds,
        fromDate,
        toDate,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ShiftPlanForHirer],
          });
          AppUtils.displaySuccess('Success', 'Shifts posted successfully.');
          handleModalClose();
        },
      }
    );
  };

  const totalQty = data?.shiftPlanItemSummaries.reduce(
    (acc, item) => acc + item.quantity,
    0
  );

  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Post Shifts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          When you post a shift to a driver, it will change the status of the
          Planned shift to Pre-Allocated, which means it`s ready for the driver
          to either accept or reject. You can pick a from date and a to date
          below and all Planned shifts found for all selected drivers between
          those dates (inclusive) will be posted to the drivers for them to
          accept or reject.
        </p>
        <hr />
        <Row>
          <DatePicker
            columns={6}
            name='PostShiftsFrom'
            label='From Date'
            value={moment(fromDate).format(_displayDateFormat)}
            options={{ startDate: new Date() }}
            changeDate={({ date }: { date: Date }) => {
              setFromDate(moment(date).format(_storedDateFormat));
            }}
            isDatepicker
            required
          />
          <DatePicker
            columns={6}
            name='PostShiftsTo'
            label='To Date'
            value={moment(toDate).format(_displayDateFormat)}
            options={{ startDate: moment().toDate() }}
            changeDate={({ date }: { date: Date }) => {
              setToDate(moment(date).format(_storedDateFormat));
            }}
            isDatepicker
            required
          />
        </Row>

        {isLoading ? (
          <Row>
            <Col xs={12}>
              <WellSmall hasSpinner />
            </Col>
          </Row>
        ) : null}

        {isError ? (
          <Row>
            <Col xs={12}>
              <ErrorAlert msg={extractErrorMessages(error)} />
            </Col>
          </Row>
        ) : null}

        {isSuccess ? (
          <>
            <table className='table'>
              <thead>
                <tr>
                  <th>Shift</th>
                  <th>Who</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tbody>
                {data.shiftPlanItemSummaries.map((item) => {
                  return (
                    <tr key={item.shiftPlanItemId}>
                      <td>{item.shiftPlanItemName}</td>
                      <td>{item.workerName}</td>
                      <td>{item.quantity}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {totalQty === 0 ? (
              <Alert bsStyle='danger' className='m-t-lg'>
                <h3>No Shifts Found</h3>
                <p>There are no shifts to post between the selected dates.</p>
              </Alert>
            ) : (
              <>
                <Alert bsStyle='warning' className='m-t-lg'>
                  <h3>What happens next?</h3>
                  <p>If you continue, the following will happen:</p>
                  <ul>
                    <li>
                      The status of the <strong>{totalQty} shifts</strong>{' '}
                      included between the dates will be changed to
                      Pre-Allocated
                    </li>
                    <li>
                      Each driver will receive an email and push notification
                      about the selected shifts
                    </li>
                    <li>
                      Each driver will be able to accept or reject the shifts
                    </li>
                  </ul>
                </Alert>

                <Alert className='m-t'>Are you sure you want to proceed?</Alert>
              </>
            )}
          </>
        ) : null}

        {postShiftsMutation.isError ? (
          <Row>
            <Col xs={12}>
              <ErrorAlert
                msg={extractErrorMessages(postShiftsMutation.error)}
              />
            </Col>
          </Row>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>
          <i className='fa fa-times' /> Close
        </Button>
        <ModalSaveButton
          disabled={!isSuccess || totalQty === 0}
          isSaved={postShiftsMutation.isSuccess}
          isSaving={postShiftsMutation.isLoading}
          onClick={handleSubmitClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

const ModalSaveButton = ({
  isSaving,
  isSaved,
  onClick,
  disabled,
}: {
  isSaving: boolean;
  isSaved: boolean;
  onClick: () => void;
  disabled: boolean;
}) => {
  const isDisabled = isSaving || isSaved || disabled;
  const buttonTitle = isSaving
    ? 'Posting...'
    : isSaved
    ? 'Posted'
    : 'Yes, Post Shifts';
  return (
    <Button bsStyle='success' onClick={onClick} disabled={isDisabled}>
      <Icon icon='fa-envelope' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};
