// Utils used by Hirer users
export const HirerUtils = {
  AutoRefreshFrequencyMs: 30000,
  Events: {
    ProfileSaved: 'hirerProfile.saved',
    UserInvited: 'hirerUser.invited',
    AgencyInviteAccepted: 'agencyInvite.accepted',
    AgencyInviteRejected: 'agencyInvite.rejected',
  },
};
