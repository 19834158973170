import { useQuery } from '@tanstack/react-query';
import { shiftApi } from 'apis/shiftApi';
import { vehicleApi } from 'apis/vehicleApi';
import { AppUtils } from 'pages/Utils/app-utils';
import { ReportQueryParams } from 'types/ReportingTypes';
import { extractErrorMessages, handleDownloadBlob } from 'utils';
import { QueryKeys } from './queryUtils';

export const useGetProfitabilityReportQuery = (params: ReportQueryParams) => {
  return useQuery({
    queryKey: [QueryKeys.ProfitabilityReport, params],
    queryFn: () => {
      return shiftApi.getReport(params);
    },
    enabled: false,
  });
};

export const useGetVehicleReportQuery = (params: ReportQueryParams) => {
  return useQuery({
    queryKey: [QueryKeys.VehicleReport, params],
    queryFn: () => {
      return vehicleApi.getVehicleReport(params);
    },
    enabled: false,
    retry: false,
  });
};

export const useDownloadProfitReportDataQuery = (params: ReportQueryParams) => {
  return useQuery({
    queryKey: [QueryKeys.ProfitReportData, params],
    queryFn: () => {
      return shiftApi.getReportData(params);
    },
    enabled: false,
    retry: false,
    onSuccess: (response) => {
      handleDownloadBlob(response);
    },
    onError: (error) => {
      AppUtils.displayError(
        'Error',
        extractErrorMessages(error),
        false,
        'download-error'
      );
    },
  });
};

export const useDownloadVehicleReportDataQuery = (
  params: ReportQueryParams
) => {
  return useQuery({
    queryKey: [QueryKeys.VehicleReportData, params],
    queryFn: () => {
      return vehicleApi.getVehicleReportData(params);
    },
    enabled: false,
    retry: false,
    onSuccess: (response) => {
      handleDownloadBlob(response);
    },
    onError: (error) => {
      AppUtils.displayError(
        'Error',
        extractErrorMessages(error),
        false,
        'download-error'
      );
    },
  });
};
