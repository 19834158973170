import { Component } from 'react';
import { Col, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, SmallMuted, DashboardHeader } from 'pages/Utils/react-utils';
import { WorkerImage } from 'pages/Worker/WorkerUtils';
import DriverWorkerEndorsementTable from './DriverWorkerEndorsementTable';
import SiteAssessmentsTable from './SiteAssessmentsTable';
import WorkerFileTable from './WorkerFileTable';
import { WorkerShiftsTable } from './WorkerShiftsTable';

export class WorkerProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
    };

    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  handleTabSelect(tabToMakeActive) {
    this.setState({ activeTab: tabToMakeActive });
  }

  render() {
    let eventKey = 1;
    const { record } = this.props;
    return (
      <>
        <DashboardHeader>
          <Col md={12}>
            <h2>
              {record.profileImageFilePath && (
                <WorkerImage
                  workerId={record.workerId}
                  firstName={record.firstName}
                  surname={record.surname}
                  mobileNumber={record.mobileNumber}
                  style={{ width: '85px' }}
                />
              )}
              {record.firstName} {record.surname}
            </h2>
          </Col>
        </DashboardHeader>
        <Tabs
          activeKey={this.state.activeTab}
          onSelect={this.handleTabSelect}
          id='worker-profile-tabs'
          animation={true}
          className='m-b'
          mountOnEnter={true}
        >
          <Tab eventKey={eventKey++} title='Personal'>
            <PersonalTab {...this.props} />
          </Tab>
          <Tab eventKey={eventKey++} title='Banking'>
            <BankingTab {...this.props} />
          </Tab>
          <Tab eventKey={eventKey++} title='Address'>
            <AddressTab {...this.props} />
          </Tab>
          <Tab eventKey={eventKey++} title='Company'>
            <CompanyTab {...this.props} />
          </Tab>
          {record.canApplyForHgvShifts && (
            <Tab eventKey={eventKey++} title='Licence'>
              <LicenceTab {...this.props} />
            </Tab>
          )}
          <Tab eventKey={eventKey++} title='Files'>
            <FilesTab {...this.props} />
          </Tab>
          {record.canApplyForHgvShifts && (
            <Tab eventKey={eventKey++} title='Experience'>
              <ExperienceTab {...this.props} />
            </Tab>
          )}
          <Tab eventKey={eventKey++} title='Site Assessments'>
            <SiteAssessmentsTable record={this.props.record} />
          </Tab>
          <Tab eventKey={eventKey++} title='Shifts'>
            <WorkerShiftsTable record={this.props.record} />
          </Tab>
        </Tabs>
      </>
    );
  }
}

const getYesNo = (bool) => (bool ? 'Yes' : 'No');

const ReadOnlyField = (props) => <Field.InCol {...props} disabled />;

const PersonalTab = (props) => {
  const record = props.record;
  return (
    <>
      <Row>
        <ReadOnlyField columns={3} name='Title' value={record.title} />
        <ReadOnlyField
          columns={3}
          name='FirstName'
          label='First Name'
          value={record.firstName}
          maxLength='50'
          required
        />
        <ReadOnlyField
          columns={3}
          name='Surname'
          value={record.surname}
          maxLength='50'
          required
        />
        <ReadOnlyField
          columns={3}
          name='DateOfBirth'
          label='Date of Birth'
          value={AppUtils.getDateString(record.dateOfBirth)}
          required
        />
      </Row>
      <Row>
        <ReadOnlyField
          columns={6}
          name='EmailAddress'
          label='Email Address'
          value={record.emailAddress}
          maxLength='256'
          readonly
        />
        <ReadOnlyField
          columns={3}
          name='MobileNumber'
          label='Mobile Number'
          value={record.mobileNumber}
          maxLength='15'
        />
        <ReadOnlyField
          columns={3}
          name='OtherContactNumber'
          label='Other Contact Number'
          value={record.otherContactNumber}
          maxLength='15'
        />
      </Row>
      <Row>
        <ReadOnlyField
          columns={3}
          name='NationalInsuranceNumber'
          label='National Insurance #'
          value={record.nationalInsuranceNumber}
          maxLength='20'
        />
        <ReadOnlyField
          columns={3}
          name='Nationality'
          value={record.nationality}
        />
        <ReadOnlyField
          columns={6}
          id='IsUkPermitRequired'
          label='Is UK work permit required?'
          value={getYesNo(record.isUkPermitRequired)}
        />
      </Row>
      <hr className='m-t-sm' />
      <h3>Next Of Kin</h3>
      <Row>
        <ReadOnlyField
          columns={3}
          name='NextOfKinFirstName'
          label='First Name'
          value={record.nextOfKinFirstName}
          maxLength='50'
        />
        <ReadOnlyField
          columns={3}
          name='NextOfKinSurname'
          label='Surname'
          value={record.nextOfKinSurname}
          maxLength='50'
        />
        <ReadOnlyField
          columns={3}
          name='NextOfKinContactNumber'
          label='Contact Number'
          value={record.nextOfKinContactNumber}
          maxLength='15'
        />
        <ReadOnlyField
          columns={3}
          name='NextOfKinRelationship'
          label='Relationship'
          value={record.nextOfKinRelationship}
          maxLength='50'
        />
      </Row>
    </>
  );
};

const BankingTab = ({ record }) => {
  return (
    <>
      <Row>
        <ReadOnlyField
          columns={12}
          name='BankName'
          label='Bank Name'
          value={record.bankName}
          maxLength='50'
        />
      </Row>
      <Row>
        <ReadOnlyField
          columns={4}
          name='BankAccountName'
          label='Account Name'
          value={record.bankAccountName}
          maxLength='100'
        />
        <ReadOnlyField
          columns={4}
          name='BankAccountSortCode'
          label='Sort Code'
          value={record.bankAccountSortCode}
          maxLength='8'
        />
        <ReadOnlyField
          columns={4}
          name='BankAccountNumber'
          label='Account Number'
          value={record.bankAccountNumber}
          maxLength='10'
        />
      </Row>
    </>
  );
};

const AddressTab = ({ record }) => {
  return (
    <>
      <Row>
        <ReadOnlyField
          columns={12}
          name='AddressLine1'
          label='Address Line 1'
          value={record.addressLine1}
          maxLength='100'
        />
        <ReadOnlyField
          columns={12}
          name='AddressLine2'
          label='Address Line 2'
          value={record.addressLine2}
          maxLength='100'
        />
        <ReadOnlyField
          columns={12}
          name='AddressLine3'
          label='Address Line 3'
          value={record.addressLine3}
          maxLength='100'
        />
      </Row>
      <Row>
        <ReadOnlyField
          columns={4}
          name='Town'
          value={record.town}
          maxLength='100'
        />
        <ReadOnlyField
          columns={4}
          name='County'
          value={record.county}
          maxLength='100'
        />
        <ReadOnlyField
          columns={4}
          name='Postcode'
          value={record.postcode}
          maxLength='10'
        />
      </Row>
    </>
  );
};

const CompanyTab = ({ record }) => {
  return (
    <>
      <Row>
        <ReadOnlyField
          columns={12}
          id='HasLimitedCompany'
          label='Do you have a limited company?'
          value={getYesNo(record.hasLimitedCompany)}
        />
      </Row>
      <Row>
        <ReadOnlyField
          columns={4}
          name='CompanyName'
          label='Company Name'
          value={record.companyName}
          maxLength='100'
        />
        <ReadOnlyField
          columns={4}
          name='CompanyNumber'
          label='Company Number'
          value={record.companyNumber}
          maxLength='100'
        />
        <ReadOnlyField
          columns={4}
          name='VatNumber'
          label='VAT Number'
          value={record.vatNumber}
          maxLength='30'
        />
      </Row>
    </>
  );
};

const LicenceTab = (props) => {
  const { record } = props;
  return (
    <>
      <Row>
        <ReadOnlyField
          columns={4}
          name='LicenceNumber'
          label='Licence Number'
          value={record.licenceNumber}
          maxLength='50'
        />
        <ReadOnlyField
          columns={4}
          name='LicenceCountryOfIssue'
          label='Country of issue'
          value={record.licenceCountryOfIssue}
        />
        <ReadOnlyField
          columns={4}
          name='LicenceDateOfIssue'
          label='Date of Issue'
          value={AppUtils.getDateString(record.licenceDateOfIssue)}
        />
      </Row>
      <Row>
        <ReadOnlyField
          columns={4}
          name='LicenceType'
          label='Licence Type'
          value={record.licenceType}
        />
        <ReadOnlyField
          columns={4}
          name='LicenceValidFromDate'
          label='Valid From'
          value={AppUtils.getDateString(record.licenceValidFromDate)}
        />
        <ReadOnlyField
          columns={4}
          name='LicenceValidToDate'
          label='Valid To'
          value={AppUtils.getDateString(record.licenceValidToDate)}
        />
      </Row>
      <Row>
        <ReadOnlyField
          columns={4}
          name='HasDigiCard'
          label='Does driver have a Digi Card?'
          value={getYesNo(record.hasDigiCard)}
        />
        <ReadOnlyField
          columns={4}
          id='HasCpCCard'
          label='Does driver have a CPC Card?'
          value={getYesNo(record.hasCpcCard)}
        />
      </Row>
      <Row>
        <ReadOnlyField
          columns={4}
          name='DigiCardExpiryDate'
          label='Digi Card Expiry'
          value={AppUtils.getDateString(record.digiCardExpiryDate)}
        />
        <ReadOnlyField
          columns={4}
          name='CpcCardExpiryDate'
          label='CPC Card Expiry'
          value={AppUtils.getDateString(record.cpcCardExpiryDate)}
        />
      </Row>
      <hr className='m-t m-b' />
      <Endorsements {...props} />
    </>
  );
};

const Endorsements = ({ record }) => {
  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col md={6}>Licence Endorsements</Col>
        </Row>
      </Panel.Heading>
      <Panel.Body>
        <DriverWorkerEndorsementTable workerId={record.workerId} />
      </Panel.Body>
    </Panel>
  );
};

const FilesTab = ({ record }) => {
  return <WorkerFileTable record={record} />;
};

const getLabel = (value) => (
  <>
    <span>{value}</span> <SmallMuted>Pick all that apply</SmallMuted>
  </>
);

const ExperienceTab = ({ record }) => {
  return (
    <>
      <Row>
        <ReadOnlyField
          columns={12}
          name='VehicleTypeExperienceList'
          label={getLabel('Vehicle Experience')}
          value={record.vehicleTypeExperienceList}
          options={AppUtils.options.vehicleTypeOptions}
          isSelect
          multiple
        />
        <ReadOnlyField
          columns={12}
          name='TrailerTypeExperienceList'
          label={getLabel('Trailer Experience')}
          value={record.trailerTypeExperienceList}
          options={AppUtils.options.trailerTypeOptions}
          isSelect
          multiple
        />
        <ReadOnlyField
          columns={12}
          name='RunTypeExperienceList'
          label={getLabel('Run Experience')}
          value={record.runTypeExperienceList}
          options={AppUtils.options.runTypeOptions}
          isSelect
          multiple
        />
        <ReadOnlyField
          columns={12}
          name='LoadTypeExperienceList'
          label={getLabel('Load Experience')}
          value={record.loadTypeExperienceList}
          options={AppUtils.options.loadTypeOptions}
          isSelect
          multiple
        />
        <ReadOnlyField
          columns={12}
          name='CompaniesWorkedForList'
          label='Companies Worked For'
          value={record.companiesWorkedForList}
          isTextArea
          maxLength='1000'
        />
      </Row>
    </>
  );
};
