import { useState } from 'react';
import { Button, Row, Col, Panel } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  useAddShiftToInvoiceMutation,
  useAwaitingInvoiceShiftsQuery,
} from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import { InvoiceStatus, ShiftAwaitingInvoice } from 'types/Invoice';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { Stack } from 'components/Stack';
import { useInvoiceContext } from './InvoiceContext';

export const AwaitingInvoiceShifts = () => {
  const [isShown, setIsShown] = useState(false);
  const [processingIds, setProcessingIds] = useState<string[]>([]);
  const { invoice, invoiceId, isEditable } = useInvoiceContext();

  const query = useAwaitingInvoiceShiftsQuery({ hirerId: invoice.hirerId });
  const awaitinginvoiceShifts = query.data ?? [];

  const handleShowAddSectionClick = () => {
    if (!isEditable) {
      AppUtils.displayError('Error', 'Cannot add shifts to this invoice');
      return;
    }

    if (awaitinginvoiceShifts.length === 0) {
      AppUtils.displayError('Error', 'No shifts to add');
      return;
    }

    setIsShown(true);
  };

  const queryClient = useQueryClient();
  const addShift = useAddShiftToInvoiceMutation();
  const handleAddClick = (shiftId: string) => {
    if (!isEditable) {
      AppUtils.displayError('Error', 'Cannot add shift to this invoice');
      return;
    }

    setProcessingIds((prev) => [...prev, shiftId]);
    addShift.mutate(
      { invoiceId, shiftId },
      {
        onSuccess: () => {
          AppUtils.displaySuccess('Done', 'Shift added to invoice');

          // Remove item from cache
          queryClient.setQueryData<typeof awaitinginvoiceShifts>(
            [QueryKeys.GetInvoice, 'shiftsAwaitingInvoice', invoice.hirerId],
            (prev) => {
              if (Array.isArray(prev)) {
                return prev.filter((item) => item.shiftId !== shiftId);
              } else {
                return prev;
              }
            }
          );

          // If that was the last one, reset the state
          if (awaitinginvoiceShifts.length === 1) {
            setIsShown(false);
          }

          queryClient.invalidateQueries({
            queryKey: [QueryKeys.GetInvoice, invoiceId],
          });
        },
        onSettled: () => {
          setProcessingIds((prev) => prev.filter((id) => id !== shiftId));
        },
      }
    );
  };

  if (invoice.status === InvoiceStatus.Paid) {
    return null;
  }

  if (query.isError) {
    return (
      <ErrorAlertForQueryOrNull isError={query.isError} error={query.error} />
    );
  }

  if (
    ((query.isSuccess && !isShown) ||
      (query.isSuccess && awaitinginvoiceShifts.length === 0 && isShown)) &&
    isEditable
  ) {
    return (
      <WellSmall style={{ marginTop: '20px' }}>
        <Row>
          <Col sm={8}>
            <p>
              There {awaitinginvoiceShifts.length === 1 ? 'is' : 'are'}{' '}
              {awaitinginvoiceShifts.length} additional{' '}
              {awaitinginvoiceShifts.length === 1 ? 'shift' : 'shifts'} which
              can be added to this invoice.
            </p>
          </Col>
          <Col sm={4} style={{ textAlign: 'right' }}>
            <Button
              bsStyle='primary'
              bsSize='sm'
              onClick={() => handleShowAddSectionClick()}
              disabled={!isEditable || awaitinginvoiceShifts.length === 0}
            >
              Add Shifts
            </Button>
          </Col>
        </Row>
      </WellSmall>
    );
  }

  if (query.isSuccess && awaitinginvoiceShifts.length > 0 && isShown) {
    return (
      <Panel style={{ marginTop: '20px' }}>
        <Panel.Heading>
          <Row>
            <Col sm={8}>
              Shifts To Be Invoiced
              {addShift.isLoading || query.isFetching ? (
                <>
                  {' '}
                  <Icon isSpinning />
                </>
              ) : null}
            </Col>
            <Col sm={4} style={{ textAlign: 'right' }}>
              <Button
                bsStyle='info'
                bsSize='sm'
                onClick={() => setIsShown(false)}
              >
                Close Section
              </Button>
            </Col>
          </Row>
        </Panel.Heading>
        <Panel.Body>
          <ErrorAlertForQueryOrNull
            isError={addShift.isError}
            error={addShift.error}
            style={{ margin: '10px' }}
          />

          <table className='table table-hover'>
            <thead>
              <tr>
                {['Description', 'Total', ''].map((item) => {
                  return (
                    <th
                      key={item}
                      style={{
                        textAlign: item !== 'Description' ? 'right' : 'left',
                      }}
                    >
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {awaitinginvoiceShifts.map((item) => (
                <TableRow
                  key={item.shiftId}
                  item={item}
                  isEditable={isEditable}
                  handleAddClick={handleAddClick}
                  isBeingProcessed={processingIds.includes(item.shiftId)}
                />
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    );
  }

  return null;
};

const TableRow = ({
  item,
  isEditable,
  handleAddClick,
  isBeingProcessed,
}: {
  item: ShiftAwaitingInvoice;
  isEditable: boolean;
  handleAddClick: (shiftId: string) => void;
  isBeingProcessed: boolean;
}) => {
  const history = useHistory();
  return (
    <>
      <tr>
        <td>{item.description}</td>
        <td align='right'>{AppUtils.getCurrencyVal(item.total)}</td>
        <td align='right'>
          <Stack direction='row' gap={5} style={{ justifyContent: 'flex-end' }}>
            <Button
              bsSize='sm'
              bsStyle='info'
              onClick={() => history.push(`/Shift/Edit/${item.shiftId}`)}
            >
              Open &raquo;
            </Button>

            {isEditable ? (
              <Button
                bsSize='sm'
                bsStyle='primary'
                onClick={() => handleAddClick(item.shiftId)}
                disabled={isBeingProcessed}
              >
                Add to invoice
              </Button>
            ) : null}
          </Stack>
        </td>
      </tr>
    </>
  );
};
