import { Component } from 'react';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { $, AppUtils } from 'pages/Utils/app-utils';
import { Field, WellSmall } from 'pages/Utils/react-utils';
import { getWorkerFileUiConfig, uploadWorkerFile } from 'utils/api';

export class AddFileButton extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  getInitialState() {
    return {
      showModal: false,
    };
  }

  handleShowModalClick() {
    this.setState({ showModal: true });
  }

  handleCloseModalClick() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <>
        <Button
          bsSize='xs'
          bsStyle='primary'
          onClick={this.handleShowModalClick}
          className='pull-right m-l'
        >
          <i className='fas fa-upload' /> Upload File
        </Button>
        <FileModal
          {...this.state}
          {...this.props}
          handleCloseModalClick={this.handleCloseModalClick}
        />
      </>
    );
  }
}

class FileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGettingFileConfig: false,
      fileConfig: null,
      isSavingFile: false,
      isModalReady: false,
    };

    this.onEntered = this.onEntered.bind(this);
    this.onExited = this.onExited.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  async onEntered() {
    const _me = this;
    if (!_me.props.workerId) {
      return null;
    }

    _me.setState({ isGettingFileConfig: true });
    const data = await getWorkerFileUiConfig(_me.props.workerId);
    if (data.status === AppUtils.ApiStatus.Success && data.configuration) {
      _me.setState({ fileConfig: data.configuration });
    }

    setTimeout(() => _me.setState({ isGettingFileConfig: false }), 500);
  }

  onExited() {
    this.setState({ fileConfig: null, isModalReady: false });
  }

  componentDidUpdate() {
    if (this.state.isGettingFileConfig) {
      return null;
    }
    if (this.state.isModalReady) {
      return null;
    }

    const $modal = $('.modal');
    const $fileUpload = $modal.find('#FileUpload');
    if (this.state.fileConfig && $modal.length > 0 && $fileUpload.length > 0) {
      $fileUpload.on('change', function () {
        let fileName = 'Choose File';
        const files = this.files;
        if (files.length > 0) {
          if (files[0].name !== '') {
            fileName = files[0].name;
          }
        }
        $('.file-name').html(fileName);
      });

      this.setState({ isModalReady: true });
    }
  }

  async handleSubmitClick() {
    const me = this;

    const invalidClassName = AppUtils.invalidClassName;
    $('.' + invalidClassName).removeClass(invalidClassName);

    let isValid = true;
    const $modal = $('.modal');
    const $fields = $modal.find('.required');
    if (!AppUtils.validateItems($fields)) {
      return false;
    }

    if ($('#FileUpload').val() === '') {
      AppUtils.displayError('Validation', 'Please choose a file to upload');
      isValid = false;
    }

    if (!isValid) {
      AppUtils.displayError(
        'Error',
        'Please enter a value in all required fields',
        false
      );
      return false;
    }

    // Create form data object
    const formData = new FormData();
    formData.append('workerId', this.props.workerId);

    // Append file
    const files = $('#FileUpload')[0].files;
    if (files.length > 0) {
      formData.append('file', files[0]);
    }

    // Append data
    $modal.find('.form-control, .is-select').each(function (i, item) {
      const $item = $(item);
      formData.append($item.attr('id'), AppUtils.getItemVal($item));
    });

    // Append checkboxes
    $modal.find('input[type="checkbox"]').each(function () {
      const $this = $(this);
      const id = $this.attr('id');
      formData.append(id, $this.is(':checked') ? true : false);
    });

    me.setState({ isSavingFile: true });
    const data = await uploadWorkerFile(formData);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'File Uploaded');
      me.props.handleCloseModalClick();
      AppUtils.publish(AppUtils.Events.WorkerFileAdded, data);
    });
    me.setState({ isSavingFile: false });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.showModal}
        onHide={this.props.handleCloseModalClick}
        onEntered={this.onEntered}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalBodyMain {...this.state} {...this.props} />
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize='sm' onClick={this.props.handleCloseModalClick}>
            <i className='fas fa-times'></i> Cancel
          </Button>
          <ModalSaveButton
            {...this.state}
            {...this.props}
            onClick={this.handleSubmitClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const ModalBodyMain = (props) => {
  if (props.isGettingFileConfig) {
    return (
      <WellSmall hasSpinner className='m-b-none'>
        Getting file configuration details...
      </WellSmall>
    );
  }
  if (!props.fileConfig) {
    return null;
  }
  if (props.fileConfig.fileTypeOptions.length <= 0) {
    return (
      <Alert bsStyle='info'>
        There are no more file types available to upload for this user.
      </Alert>
    );
  }
  return (
    <>
      <Row>
        <Field.InCol
          columns={12}
          name='FileType'
          label='File Type'
          options={props.fileConfig.fileTypeOptions}
          isSelect
          required
        />
      </Row>
      <FileUpload {...props} />
    </>
  );
};

const FileUpload = () => {
  return (
    <Row>
      <Col md={12}>
        <div className='form-group'>
          <label>Upload File</label>
          <label className='upload-label'>
            <input type='file' name='FileUpload' id='FileUpload' />
            <span>
              <i className='fas fa-upload'></i>{' '}
              <span className='file-name'>Choose File</span>
            </span>
          </label>
        </div>
      </Col>
    </Row>
  );
};

const ModalSaveButton = (props) => {
  if (!props.fileConfig) {
    return null;
  }
  if (props.fileConfig.fileTypeOptions.length <= 0) {
    return null;
  }
  const text = props.isSavingFile ? 'Uploading File...' : 'Upload File';
  const icon = props.isSavingFile ? AppUtils.spinIconClass : 'fas fa-upload';
  return (
    <Button
      bsSize='sm'
      bsStyle='success'
      onClick={props.onClick}
      disabled={props.isSavingFile}
    >
      <i className={icon}></i> {text}
    </Button>
  );
};
