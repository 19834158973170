import { Button } from 'react-bootstrap';
import { useModal } from 'hooks/useModal';
import { VehicleModal } from '../VehicleModal';

export const CreateNewVehicleButton = () => {
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const handleShowModalClick = () => {
    handleModalOpen();
  };

  const handleCloseModalClick = () => {
    handleModalClose();
  };

  return (
    <div>
      <Button
        bsStyle='primary'
        onClick={handleShowModalClick}
        style={{ marginBottom: 0 }}
      >
        {' '}
        <i className='fas fa-forklift' /> Add New Vehicle
      </Button>
      {isModalOpen ? (
        <VehicleModal
          handleCloseModalClick={handleCloseModalClick}
          showModal={isModalOpen}
        />
      ) : null}
    </div>
  );
};
