import { Component, useState } from 'react';
import { Alert, Button, Overlay, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useSubscription from 'hooks/useSubscription';
import $ from 'jquery';
import { AppUtils } from 'pages/Utils/app-utils';
import { ListViewUtils } from 'pages/Utils/list-view-utils';
import {
  WellSmall,
  SmallMuted,
  Icon,
  getDivArray,
} from 'pages/Utils/react-utils';
import { getWorkersActiveForAgency } from 'utils/api';
import {
  ProfileStatus,
  WorkerFeedback,
  WorkerImage,
} from '../../Worker/WorkerUtils';
import { EditWorkerModal } from './EditWorkerModal';
import { RejectWorkerModal } from './RejectWorkerModal';
import { WorkerAdminUtils } from './WorkerAdminUtils';

const WorkerListViewActive = () => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['getWorkersActiveForAgency'],
    getWorkersActiveForAgency
  );
  const records = data?.records || [];
  useSubscription(
    [
      WorkerAdminUtils.Events.WorkerSaved,
      WorkerAdminUtils.Events.DriverRejected,
    ],
    refetch
  );

  const [recordToEdit, setRecordToEdit] = useState(null);
  const [recordToReject, setRecordToReject] = useState(null);
  const handleEditButtonClick = (recordToEdit) => {
    setRecordToEdit(recordToEdit);
  };

  const handleCloseEditModalClick = () => {
    setRecordToEdit(null);
  };

  const handleRejectButtonClick = (recordToReject) => {
    setRecordToReject(recordToReject);
  };

  const handleCloseRejectModalClick = () => {
    setRecordToReject(null);
  };

  if (isLoading) {
    return (
      <WellSmall className='m-b-none' isSpinning>
        Getting active workers...
      </WellSmall>
    );
  }
  if (records.length <= 0) {
    return <WellSmall className='m-b-none'>No active workers found.</WellSmall>;
  }
  return (
    <>
      <Alert bsStyle='info'>
        All of your registered workers.
        <Button
          bsSize='xs'
          bsStyle='primary'
          onClick={refetch}
          disabled={isLoading || isFetching}
          className='pull-right'
        >
          <Icon icon='fa-redo' isSpinning={isLoading || isFetching} /> Refresh
        </Button>
      </Alert>
      <Table
        recordToEdit={recordToEdit}
        handleEditButtonClick={handleEditButtonClick}
        recordToReject={recordToReject}
        handleRejectButtonClick={handleRejectButtonClick}
        records={records}
      />
      <EditWorkerModal
        recordToEdit={recordToEdit}
        handleCloseModalClick={handleCloseEditModalClick}
      />
      <RejectWorkerModal
        recordToReject={recordToReject}
        handleCloseModalClick={handleCloseRejectModalClick}
      />
    </>
  );
};

const tableId = 'active-workers-table';
class Table extends Component {
  componentDidMount() {
    this.initDataTables();
  }

  shouldComponentUpdate(nextProps) {
    if (
      JSON.stringify(nextProps.records) !== JSON.stringify(this.props.records)
    ) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillUpdate() {
    this.destroyDataTables();
  }

  componentDidUpdate() {
    this.initDataTables();
  }

  componentWillUnmount() {
    this.destroyDataTables();
  }

  initDataTables() {
    if (this.props.records.length > 0) {
      ListViewUtils.init(tableId);
    }
  }

  destroyDataTables() {
    const $table = $('#' + tableId);
    if ($table.length) {
      $table.DataTable().destroy();
    }
  }

  render() {
    const getTableRow = (record, key) => {
      return (
        <Row
          key={key}
          record={record}
          handleEditButtonClick={this.props.handleEditButtonClick}
          handleRejectButtonClick={this.props.handleRejectButtonClick}
        />
      );
    };

    return (
      <table
        className='table table-condensed table-striped'
        id={tableId}
        data-order='[[1, "desc"]]'
      >
        <thead>
          <tr>
            <th className='no-sort' />
            <th>Name</th>
            <th>Feedback</th>
            <th>Reference</th>
            <th>Email</th>
            <th>Active Since</th>
            <th>Profile Status</th>
            <th className='no-sort' />
          </tr>
        </thead>
        <tbody>
          {this.props.records.map((record, i) => getTableRow(record, i))}
        </tbody>
      </table>
    );
  }
}

const Row = ({ record, handleEditButtonClick, handleRejectButtonClick }) => {
  return (
    <tr>
      <td>
        {record.workerHasImage ? (
          <WorkerImage
            workerId={record.workerId}
            firstName={record.firstName}
            surname={record.surname}
            mobileNumber={record.mobileNumber}
            imageUrl={record.imageUrl}
          />
        ) : null}
      </td>
      <td data-order={record.surname}>
        <LinkToRecord record={record} />
        {getDivArray([
          <SmallMuted key={1}>
            {record.payType}
            {record.payType === WorkerAdminUtils.PayType.PAYE
              ? record.holidayType
                ? ` - Holiday: ${record.holidayType}`
                : null
              : null}
          </SmallMuted>,
          <SmallMuted key={2}>
            <div
              data-toggle='tooltip'
              title={
                record.arePensionContributionsRequired
                  ? 'Pension Contributions ARE required'
                  : 'Pension Contributions ARE NOT required'
              }
            >
              Pension{' '}
              {record.arePensionContributionsRequired ? (
                <Icon icon='fa-check' className='text-success' />
              ) : (
                <Icon icon='fa-times' className='text-danger' />
              )}
            </div>
          </SmallMuted>,
        ])}
      </td>
      <td data-order={record.feedbackScore}>
        <WorkerFeedback record={record} />
      </td>
      <td>{record.internalReference}</td>
      <td>{record.emailAddress}</td>
      <td data-order={record.approvedDateTime}>
        {AppUtils.formatDateTimeUtc(record.approvedDateTime)}
      </td>
      <td>
        <ProfileStatus record={record} />
      </td>
      <td className='text-right'>
        <PopoverButton
          record={record}
          handleEditButtonClick={handleEditButtonClick}
          handleRejectButtonClick={handleRejectButtonClick}
        />
      </td>
    </tr>
  );
};

const LinkToRecord = ({ record }) => {
  return (
    <Link to={`Worker/Profile/${record.workerId}`}>
      {record.firstName} {record.surname}
    </Link>
  );
};

const PopoverButton = ({
  record,
  handleEditButtonClick,
  handleRejectButtonClick,
}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = (event) => {
    setTarget(event.target);
    setShow(!show);
    event.stopPropagation();
  };

  return (
    <>
      <Button bsSize='xs' bsStyle='default' onClick={handleClick}>
        <Icon icon='fa-ellipsis-h' />
      </Button>
      <Overlay
        show={show}
        target={target}
        placement='left'
        onHide={() => setShow(false)}
        rootClose
      >
        <Popover id='popover-contained'>
          <Button
            bsStyle='primary'
            bsSize='xs'
            block
            onClick={() => handleEditButtonClick(record)}
          >
            <Icon icon='fa-pencil' /> Edit
          </Button>
          <Button
            bsStyle='danger'
            bsSize='xs'
            block
            onClick={() => handleRejectButtonClick(record)}
          >
            <Icon icon='fa-thumbs-down' /> Reject
          </Button>
        </Popover>
      </Overlay>
    </>
  );
};

export default WorkerListViewActive;
