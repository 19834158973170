import { useState } from 'react';
import { Alert, Button, Col, Panel, Row } from 'react-bootstrap';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall, Muted, AlertWithIcon } from 'pages/Utils/react-utils';
import { resetShiftFinancials } from 'utils/api';
import { usePermissions, userPermissions } from 'utils/userPermissions';
import { UnauthorisedAlert } from 'components/UnauthorisedAlert';
import { AddAdjustmentButton } from './AddAdjustmentButton';
import { ClearAdjustmentButton } from './ClearAdjustmentButton';

export const ShiftFinancialInfoTabContent = (props) => {
  const { record, shiftPayment, shiftCharge } = props;
  const { hasPermission } = usePermissions();
  const canView = hasPermission(userPermissions.shift.finance.view);

  if (!canView) {
    return <UnauthorisedAlert />;
  }

  if (!shiftPayment || !shiftCharge) {
    return <NoFinanceRecords record={record} />;
  }
  return <Body {...props} />;
};

const NoFinanceRecords = (props) => {
  return (
    <WellSmall className='text-center'>
      <p>
        The financial info for this shift has not been generated yet. You can
        generate it now by pressing the button below.
      </p>
      <div className='m-t'>
        <ReCalculateButton
          record={props.record}
          isRecalculatingLabel='Generating...'
          label='Generate Financial Info Now'
        />
      </div>
    </WellSmall>
  );
};

const getHrsMins = (duration) => `${duration.hours}h ${duration.minutes}m`;
const getCurrencyVal = (val) => AppUtils.getCurrencyVal(val);
const getPercentageVal = (val) =>
  `${val === 0 ? '0' : AppUtils.formatNumber(val)}%`;

const Body = (props) => {
  const isFixedShift =
    props.shiftConfig.shiftPaymentType === ShiftUtils.ShiftPaymentTypes.Fixed;
  return (
    <>
      {props.record.status ===
      ShiftUtils.Status.PaymentAuthorised ? null : !props.record.isPaid ? (
        <WellSmall>
          <ReCalculateButton record={props.record} />
        </WellSmall>
      ) : null}
      <Row>
        <Col sm={8}>
          <PaymentPanel {...props} isFixedShift={isFixedShift} />
          <AdditionalWorkerCostsPanel {...props} isFixedShift={isFixedShift} />
          <VehicleAndTrailerCostsPanel {...props} />
        </Col>
        <Col sm={4}>
          <ChargePanel {...props} />
        </Col>
      </Row>
    </>
  );
};

const ReCalculateButton = (props) => {
  const [isRecalculating, setIsRecalculating] = useState(false);
  if (
    props.record.isPaid ||
    props.record.status === ShiftUtils.Status.PaymentAuthorised
  ) {
    return null;
  }

  const handleClick = async () => {
    setIsRecalculating(true);
    AppUtils.clearNotifications();

    const data = await resetShiftFinancials(props.record.shiftId);
    AppUtils.handleAjaxDone(data, () => {
      if (data.shiftPayment) {
        AppUtils.publish(ShiftUtils.Events.ShiftFinancialsReset, data);
        AppUtils.displaySuccess(
          'Done',
          'Re-calculation completed successfully'
        );
      } else {
        AppUtils.displayError('Error', 'Could not Re-calculate');
      }
    });
    setIsRecalculating(false);
  };

  return (
    <Button
      bsSize='sm'
      bsStyle='primary'
      onClick={handleClick}
      disabled={isRecalculating}
    >
      <Icon icon='fa-sync-alt' isSpinning={isRecalculating} />{' '}
      {isRecalculating
        ? props.isRecalculatingLabel || 'Re-calculating...'
        : props.label || 'Re-calculate'}
    </Button>
  );
};

const PaymentPanel = (props) => {
  const { record, shiftConfig, shiftPayment, isFixedShift } = props;
  return (
    <Panel>
      <Panel.Heading>Payment</Panel.Heading>
      <Panel.Body>
        {shiftConfig.shiftTimesRoundingType ===
        ShiftUtils.ShiftTimesRoundingType._15Minutes ? (
          <WellSmall className='text-muted'>
            <Icon icon='fa-information' /> Please note, Start and End Times have
            been rounded to the nearest 15 minutes.
          </WellSmall>
        ) : null}
        <table className='table table-hover'>
          <thead>
            <tr>
              <MutedTh>Period</MutedTh>
              {!isFixedShift ? <MutedTh>Type</MutedTh> : null}
              <MutedTh>Duration</MutedTh>
              <MutedTh>Rate</MutedTh>
              <MutedTh className='text-right'>Total</MutedTh>
            </tr>
          </thead>
          <tbody>
            <HourlyShiftPaymentRows {...props} />
            <FixedShiftPaymentRows {...props} />
            <TopupRow record={record} shiftPayment={shiftPayment} />
            <ShiftPaymentAdjustmentRow
              record={record}
              shiftPayment={shiftPayment}
              isFixedShift={isFixedShift}
            />
          </tbody>
        </table>
      </Panel.Body>

      {record.isPaid && (
        <Panel.Body>
          <AlertWithIcon
            bsStyle='success'
            icon='fa-smile fa-2x'
            className='m-b-none'
          >
            Marked as paid by {record.paidBy} on{' '}
            {AppUtils.formatDateTimeUtc(record.paidDateTime)}
            {record.paymentReference
              ? '. Payment Reference: ' + record.paymentReference
              : null}
          </AlertWithIcon>
        </Panel.Body>
      )}

      <Panel.Footer
        className='bg-success'
        style={{ fontWeight: 900, fontSize: '1.1em' }}
      >
        <Row>
          <Col sm={6}>Payment Total</Col>
          <Col sm={6} className='text-right'>
            {getCurrencyVal(shiftPayment.amountTotal)}
          </Col>
        </Row>
      </Panel.Footer>
    </Panel>
  );
};

const HourlyShiftPaymentRows = (props) => {
  const { record, shiftConfig, shiftPayment, isFixedShift } = props;
  if (isFixedShift) {
    return null;
  }

  return (
    <>
      {record.periods.map((period, i) => (
        <PeriodRow key={i} period={period} />
      ))}
      <tr>
        <td>Break {`(${shiftConfig.areBreaksPaid ? 'Paid' : 'Unpaid'})`}</td>
        <td>
          <MutedNa />
        </td>
        <td>{getHrsMins(record.breakDuration)}</td>
        <td>
          {shiftConfig.areBreaksPaid ? (
            record.breakDuration.totalHours > 0 ? (
              <Muted>Inclusive</Muted>
            ) : (
              <MutedNa />
            )
          ) : (
            getCurrencyVal(shiftPayment.breakRate)
          )}
        </td>
        <td className='text-right'>
          {shiftConfig.areBreaksPaid ? (
            record.breakDuration.totalHours > 0 ? (
              <Muted>Inclusive</Muted>
            ) : (
              <MutedNa />
            )
          ) : (
            <strong className='text-danger'>{`(${getCurrencyVal(
              shiftPayment.breakAmountUnpaid
            )})`}</strong>
          )}
        </td>
      </tr>
    </>
  );
};

const FixedShiftPaymentRows = (props) => {
  const { record, shiftPayment, shiftFinance, isFixedShift } = props;
  if (!isFixedShift) {
    return null;
  }

  return (
    <>
      <tr>
        <td>Fixed</td>
        <td>{getHrsMins(record.normalDuration)}</td>
        <td>Fixed</td>
        <td className='text-right'>
          <strong>{getCurrencyVal(shiftPayment.amountNormal)}</strong>
        </td>
      </tr>
      {record.overtimeDuration.totalHours > 0 ? (
        <tr>
          <td>Overtime</td>
          <td>{getHrsMins(record.overtimeDuration)}</td>
          <td>{getCurrencyVal(shiftFinance.overtimeRateFixedShift)}</td>
          <td className='text-right'>
            <strong>{getCurrencyVal(shiftPayment.amountOvertime)}</strong>
          </td>
        </tr>
      ) : null}
    </>
  );
};

const PeriodRow = ({ period }) => {
  return (
    <tr>
      <td>
        {AppUtils.formatDateTimeNonUtc(period.start, null, 'HH:mm')} -{' '}
        {AppUtils.formatDateTimeNonUtc(period.end, null, 'HH:mm')}
      </td>
      <td>{period.isOvertime ? 'Overtime' : period.type}</td>
      <td>{getHrsMins(period.duration)}</td>
      <td>{getCurrencyVal(period.rate)}</td>
      <td className='text-right'>
        <strong>{getCurrencyVal(period.amount)}</strong>
      </td>
    </tr>
  );
};

const TopupRow = (props) => {
  const { record, shiftPayment } = props;
  return (
    shiftPayment.topupValue > 0 && (
      <tr>
        <td>Topup</td>
        <td>{shiftPayment.topupReason}</td>
        <td>{getHrsMins(record.topupDuration)}</td>
        <td>{getCurrencyVal(shiftPayment.topupRate)}</td>
        <td className='text-right'>
          <strong>{getCurrencyVal(shiftPayment.topupValue)}</strong>
        </td>
      </tr>
    )
  );
};

const ShiftPaymentAdjustmentRow = ({ record, shiftPayment, isFixedShift }) => {
  return (
    <tr>
      <td>
        <span className='m-r-xs'>Adjustment</span>
        {!record.isPaid &&
        record.status !== ShiftUtils.Status.PaymentAuthorised ? (
          <>
            <AddAdjustmentButton
              shiftPayment={shiftPayment}
              adjustmentType={ShiftUtils.AdjustmentType.Payment}
            />
            <ClearAdjustmentButton
              shiftPayment={shiftPayment}
              adjustmentType={ShiftUtils.AdjustmentType.Payment}
            />
          </>
        ) : null}
      </td>
      <td colSpan={isFixedShift ? '2' : '3'}>
        {shiftPayment.adjustmentReason}
      </td>
      <td className='text-right'>
        <strong>{getCurrencyVal(shiftPayment.adjustmentValue)}</strong>
      </td>
    </tr>
  );
};

const VehicleAndTrailerCostsPanel = (props) => {
  const { record, shiftCost } = props;
  if (!record.vehicleId && !record.trailerId) {
    return null;
  }

  return (
    <Panel>
      <Panel.Heading>Vehicle and Trailer Costs</Panel.Heading>
      <Panel.Body>
        {!shiftCost ? (
          <Alert bsStyle='warning'>No Vehicle and Trailer costs found</Alert>
        ) : (
          <ShiftCostTable shiftCost={shiftCost} />
        )}
      </Panel.Body>

      {shiftCost ? (
        <Panel.Footer
          className='bg-success'
          style={{ fontWeight: 900, fontSize: '1.1em' }}
        >
          <Row>
            <Col sm={6}>Total</Col>
            <Col sm={6} className='text-right'>
              {getCurrencyVal(shiftCost.total)}
            </Col>
          </Row>
        </Panel.Footer>
      ) : null}
    </Panel>
  );
};

const ShiftCostTable = ({ shiftCost }) => {
  return (
    <table className='table table-hover'>
      <tbody>
        {[
          ['AdBlue', 'adBlueCost'],
          ['Fuel', 'fuelCost'],
          ['Tyres', 'tyreCost'],
          ['Vehicle Fixed Costs', 'vehicleCosts'],
          ['Trailer Fixed Costs', 'trailerCosts'],
        ].map(([label, key]) => {
          return (
            <tr key={key}>
              <td>{label}</td>
              <td className='text-right'>
                <strong>{getCurrencyVal(shiftCost[key])}</strong>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const AdditionalWorkerCostsPanel = (props) => {
  const { record, shiftPayment } = props;

  if (!record.workerIsPaye) {
    return null;
  }
  return (
    <Panel>
      <Panel.Heading>Additional Worker Costs</Panel.Heading>
      <Panel.Body>
        <table className='table'>
          <thead>
            <tr>
              <th />
              <MutedTh>{`NI ${getPercentageVal(
                shiftPayment.niPercentage
              )}`}</MutedTh>
              <MutedTh>{`WTD ${getPercentageVal(
                shiftPayment.wtdPercentage
              )}`}</MutedTh>
              <MutedTh>{`Pension ${getPercentageVal(
                shiftPayment.pensionPercentage
              )}`}</MutedTh>
              <MutedTh>{`App Levy ${getPercentageVal(
                shiftPayment.apprenticeshipLevyPercentage
              )}`}</MutedTh>
              <MutedTh className='text-right'>Total</MutedTh>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Muted>Standard</Muted>
              </td>
              <td>{getCurrencyVal(shiftPayment.niAmountNormal)}</td>
              <td>{getCurrencyVal(shiftPayment.wtdAmountNormal)}</td>
              <td>{getCurrencyVal(shiftPayment.pensionAmount)}</td>
              <td>{getCurrencyVal(shiftPayment.apprenticeshipLevyAmount)}</td>
              <td className='text-right'>
                <strong>{getCurrencyVal(shiftPayment.costsNormalTotal)}</strong>
              </td>
            </tr>
            <tr>
              <td>
                <Muted>Overtime</Muted>
              </td>
              <td>{getCurrencyVal(shiftPayment.niAmountOvertime)}</td>
              <td>{getCurrencyVal(shiftPayment.wtdAmountOvertime)}</td>
              <td>
                <MutedNa />
              </td>
              <td>
                <MutedNa />
              </td>
              <td className='text-right'>
                <strong>
                  {getCurrencyVal(shiftPayment.costsOvertimeTotal)}
                </strong>
              </td>
            </tr>
            <TopupCostsRow shiftPayment={shiftPayment} />
            <AdjustmentCostsRow shiftPayment={shiftPayment} />
            <tr>
              <td>
                <Muted>WTD</Muted>
              </td>
              <td>{getCurrencyVal(shiftPayment.niAmountHoliday)}</td>
              <td>
                <MutedNa />
              </td>
              <td>
                <MutedNa />
              </td>
              <td>
                <MutedNa />
              </td>
              <td className='text-right'>
                <strong>{getCurrencyVal(shiftPayment.niAmountHoliday)}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Panel.Body>
      <Panel.Footer
        className='bg-success'
        style={{ fontWeight: 900, fontSize: '1.1em' }}
      >
        <Row>
          <Col sm={6}>Additional Worker Costs Total</Col>
          <Col sm={6} className='text-right'>
            {getCurrencyVal(shiftPayment.costsTotal)}
          </Col>
        </Row>
      </Panel.Footer>
    </Panel>
  );
};

const MutedNa = () => <Muted>N/A</Muted>;
const MutedTh = (props) => (
  <th className={props.className} style={{ fontWeight: 400 }}>
    <Muted>{props.children}</Muted>
  </th>
);

const TopupCostsRow = ({ shiftPayment }) => {
  return (
    shiftPayment.topupValue > 0 && (
      <tr>
        <td>
          <Muted>Topup</Muted>
        </td>
        <td>{getCurrencyVal(shiftPayment.niAmountTopup)}</td>
        <td>{getCurrencyVal(shiftPayment.wtdAmountTopup)}</td>
        <td>
          <MutedNa />
        </td>
        <td>
          <MutedNa />
        </td>
        <td className='text-right'>
          <strong>{getCurrencyVal(shiftPayment.costsTopupTotal)}</strong>
        </td>
      </tr>
    )
  );
};

const AdjustmentCostsRow = ({ shiftPayment }) => {
  return (
    <tr>
      <td>
        <Muted>Adjustment</Muted>
      </td>
      <td>{getCurrencyVal(shiftPayment.niAmountAdjustment)}</td>
      <td>{getCurrencyVal(shiftPayment.wtdAmountAdjustment)}</td>
      <td>
        <MutedNa />
      </td>
      <td>
        <MutedNa />
      </td>
      <td className='text-right'>
        <strong>{getCurrencyVal(shiftPayment.costsAdjustmentTotal)}</strong>
      </td>
    </tr>
  );
};

const ChargePanel = (props) => {
  const { record, shiftPayment, shiftCharge } = props;
  return (
    <Panel className='m-b-none'>
      <Panel.Heading>Charge</Panel.Heading>
      <Panel.Body>
        {shiftCharge.chargeType ===
        ShiftUtils.ShiftChargeTypes.ChargeRateAndMileage ? (
          <>
            <ChargeRow
              label='Sub-Total'
              value={getCurrencyVal(shiftCharge.totalNormal)}
            />
            <ChargeRow
              label='Overtime'
              value={getCurrencyVal(shiftCharge.totalOvertime)}
            />
            <ChargeRow
              label='Topup'
              value={getCurrencyVal(shiftCharge.totalTopup)}
            />
          </>
        ) : null}

        {shiftCharge.chargeType === ShiftUtils.ShiftChargeTypes.Margin ? (
          <>
            <ChargeRow
              label='Payment Total'
              value={getCurrencyVal(shiftPayment.amountTotal)}
            />
            <ChargeRow
              label='Additional Worker Costs Total'
              value={getCurrencyVal(shiftPayment.costsTotal)}
            />
            <ChargeRow
              label={`Margin @ ${getCurrencyVal(
                shiftCharge.marginPerHour
              )} p/h`}
              value={getCurrencyVal(shiftCharge.marginTotal)}
            />
          </>
        ) : null}

        <ShiftChargeAdjustmentRow record={record} shiftCharge={shiftCharge} />
      </Panel.Body>
      <Panel.Footer
        className='bg-success'
        style={{ fontWeight: 900, fontSize: '1.1em' }}
      >
        <Row>
          <Col md={7}>Total</Col>
          <Col md={5} className='text-right'>
            {getCurrencyVal(shiftCharge.total)}
          </Col>
        </Row>
      </Panel.Footer>
    </Panel>
  );
};

const ShiftChargeAdjustmentRow = ({ record, shiftCharge }) => {
  const adjustmentButtons = !record.isPaid ? (
    <>
      <AddAdjustmentButton
        shiftCharge={shiftCharge}
        adjustmentType={ShiftUtils.AdjustmentType.Charge}
        className='m-l-sm'
      />
      <ClearAdjustmentButton
        shiftCharge={shiftCharge}
        adjustmentType={ShiftUtils.AdjustmentType.Charge}
        className='m-l-sm'
      />
    </>
  ) : null;
  return (
    <ChargeRow
      label='Adjustment'
      postLabelContent={adjustmentButtons}
      value={getCurrencyVal(shiftCharge.adjustmentValue)}
      additionalContent={
        shiftCharge.adjustmentReason && (
          <Row className='m-t-sm'>
            <Col md={12}>{shiftCharge.adjustmentReason}</Col>
          </Row>
        )
      }
    />
  );
};

const ChargeRow = ({
  label,
  postLabelContent,
  value,
  valueClassName,
  doesNotHaveSeparator,
  isValueStrong,
  additionalContent,
}) => {
  return (
    <>
      <Row>
        <Col md={7}>
          {label}
          {postLabelContent}
        </Col>
        <Col
          md={5}
          className={`text-right${valueClassName ? ` ${valueClassName}` : ''}`}
        >
          {isValueStrong ? <strong>{value}</strong> : value}
        </Col>
      </Row>
      {additionalContent && additionalContent}
      {!doesNotHaveSeparator && <hr />}
    </>
  );
};
