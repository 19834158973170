import { useMemo } from 'react';
import { Badge, Button, Col, Panel, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useFilterData from 'hooks/useFilterData';
import useGetConfig from 'hooks/useGetConfig';
import useSubscription from 'hooks/useSubscription';
import moment from 'moment';
import { getOpenShifts, getOpenShiftsListViewConfig } from 'utils/api';
import { Table } from 'components/Table';
import { ShiftUtils } from '../Shift/ShiftUtils';
import { DatePicker } from '../Utils/DatePicker';
import { TimeDropdown } from '../Utils/TimeDropdown';
import { AppUtils } from '../Utils/app-utils';
import {
  MapToDiv,
  SmallMuted,
  WellSmall,
  Icon,
  Field,
} from '../Utils/react-utils';

const _filterContainerId = 'OpenFiltersContainer';
const _filterFieldPrefix = 'openFilter';

export const OpenShiftsListView = ({ setDisplayShiftId }) => {
  const { isGettingConfig, configuration } = useGetConfig(
    getOpenShiftsListViewConfig
  );
  const { filterData, getDataModel, handleFilterChange } =
    useFilterData(_filterFieldPrefix);
  const { isLoading, error, data, refetch } = useQuery(
    ['getOpenShifts', getDataModel()],
    () => getOpenShifts(getDataModel())
  );
  const records = data?.records || [];

  useSubscription(
    [ShiftUtils.Events.ShiftCreated, ShiftUtils.Events.ShiftCancelled],
    refetch
  );

  if (isGettingConfig) {
    return <GettingConfigMsg isGettingConfig={isGettingConfig} />;
  }
  if (!configuration) {
    return null;
  }

  return (
    <>
      <Filters
        configuration={configuration}
        filterData={filterData}
        handleSearchClick={refetch}
        handleFilterChange={handleFilterChange}
        isLoading={isLoading}
      />
      <TableContainer
        records={records}
        setDisplayShiftId={setDisplayShiftId}
        error={error}
      />
    </>
  );
};

const TableContainer = ({ records, setDisplayShiftId, error }) => {
  if (error) {
    return <WellSmall className='m-b-none'>{error}</WellSmall>;
  }
  if (!records.length) {
    return <WellSmall className='m-b-none'>No Open Shifts Found.</WellSmall>;
  }
  return (
    <DisplayTable records={records} setDisplayShiftId={setDisplayShiftId} />
  );
};

const DisplayTable = ({ records, setDisplayShiftId }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Details',
        accessor: (row) => <MapToDiv>{[row.companyName, row.name]}</MapToDiv>,
        id: 'details',
      },
      {
        Header: 'When',
        accessor: (record) =>
          AppUtils.concatDateAndTime(record.shiftDate, record.shiftTime),
        Cell: ({ value }) => AppUtils.formatDateTimeNonUtc(value),
        id: 'when',
      },
      {
        Header: 'Posted',
        accessor: (record) => record.createdDateTime,
        Cell: ({ row }) => {
          return (
            <>
              {AppUtils.formatDateTimeUtc(row.original.createdDateTime)}
              <div>
                <SmallMuted>by {row.original.createdBy}</SmallMuted>
              </div>
            </>
          );
        },
        id: 'posted',
      },
      {
        Header: 'Applicant Type',
        accessor: (record) => record.isPublic,
        Cell: ({ value }) => <ShiftPaymentType isPublic={value} />,
        id: 'applicantType',
      },
      {
        Header: 'Applicants',
        accessor: (record) => record.applicationCount,
        Cell: ({ value }) => <ColourBadge number={value} />,
        id: 'applicantCount',
      },
      {
        accessor: (record) => record.shiftId,
        Cell: ({ value }) =>
          setDisplayShiftId ? (
            <Button
              bsStyle='primary'
              bsSize='xs'
              onClick={() => setDisplayShiftId(value)}
            >
              Open &raquo;
            </Button>
          ) : (
            <Link to={`Shift/Edit/${value}`} className='btn btn-xs btn-primary'>
              Open &raquo;
            </Link>
          ),
        id: 'btnContainer',
        disableSortBy: true,
        className: 'text-right',
      },
    ],
    [setDisplayShiftId]
  );

  return <Table columns={columns} records={records} />;
};

const GettingConfigMsg = ({ isGettingConfig }) => {
  if (!isGettingConfig) {
    return null;
  }
  return (
    <WellSmall hasSpinner>
      Please wait while the Open Shift section is configured...
    </WellSmall>
  );
};

const Filters = (props) => {
  const {
    handleSearchClick,
    isLoading,
    configuration,
    filterData,
    handleFilterChange,
  } = props;

  const handleChangeDate = (name, date) => {
    handleFilterChange({
      target: { name, value: moment(date).format('YYYY-MM-DD') },
    });
  };

  return (
    <Panel id={_filterContainerId}>
      <Panel.Body>
        <Row>
          <Col sm={4}>
            <DatePicker
              name={`${_filterFieldPrefix}FromDate`}
              label='From Date'
              value={AppUtils.getDateString(
                filterData[`${_filterFieldPrefix}FromDate`]
              )}
              changeDate={(e) =>
                handleChangeDate(`${_filterFieldPrefix}FromDate`, e.date)
              }
            />
            <TimeDropdown
              name={`${_filterFieldPrefix}FromTime`}
              label='From Time'
              placeholder='From Time'
              value={filterData[`${_filterFieldPrefix}FromTime`]}
              onChange={handleFilterChange}
            />
          </Col>

          <Col sm={4}>
            <DatePicker
              name={`${_filterFieldPrefix}ToDate`}
              label='To Date'
              value={AppUtils.getDateString(
                filterData[`${_filterFieldPrefix}ToDate`]
              )}
              changeDate={(e) =>
                handleChangeDate(`${_filterFieldPrefix}ToDate`, e.date)
              }
            />
            <TimeDropdown
              name={`${_filterFieldPrefix}ToTime`}
              label='To Time'
              placeholder='To Time'
              value={filterData[`${_filterFieldPrefix}ToTime`]}
              onChange={handleFilterChange}
            />
          </Col>

          <Col sm={4}>
            <Field.Main
              name={`${_filterFieldPrefix}HirerId`}
              label='Customer'
              value={filterData[`${_filterFieldPrefix}HirerId`]}
              options={configuration.hirerOptions}
              isSelect
              onChange={handleFilterChange}
            />
          </Col>
        </Row>
      </Panel.Body>
      <Panel.Footer className='text-right'>
        <Button
          className='m-b-none'
          bsStyle='primary'
          bsSize='sm'
          onClick={handleSearchClick}
          disabled={isLoading}
        >
          <Icon icon='fa-search' isSpinning={isLoading} />{' '}
          {isLoading ? 'Searching' : 'Search'}
        </Button>
      </Panel.Footer>
    </Panel>
  );
};

const ShiftPaymentType = ({ isPublic }) => {
  const config = ShiftUtils.ApplicantTypeLabel(isPublic);
  return <Badge bsStyle={config[1]}>{config[0]}</Badge>;
};

const ColourBadge = (props) => {
  const colour =
    props.number === 1 ? 'warning' : props.number > 1 ? 'success' : 'danger';
  return <span className={`badge badge-${colour}`}>{props.number}</span>;
};
