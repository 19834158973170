import qs from 'qs';
import {
  AddShiftToInvoiceParams,
  DeleteInvoiceParams,
  DownloadInvoiceParams,
  MarkAsPaidInvoiceParams,
  Invoice,
  RemoveInvoiceItemFromInvoiceParams,
  GetShiftsAwaitingInvoiceQueryParams,
  GetInvoicesQueryParams,
  CreateInvoiceParams,
  CreateInvoiceResponse,
  ShiftAwaitingInvoice,
  ShiftsAwaitingInvoiceSummary,
  GetShiftsAwaitingInvoiceSummaryQueryParams,
  InvoiceEditParams,
  RequestApprovalInvoiceParams,
  ApproveInvoiceParams,
  RejectInvoiceParams,
} from 'types/Invoice';
import axiosInt, { get, post, put } from './apiBase';

const _base = 'Invoice';
const url = (parts: string | string[]): string => {
  if (typeof parts === 'string') {
    return url([parts]);
  } else {
    return [_base, ...parts].join('/');
  }
};

type CreateInvoicePostParams = {
  hirerId: string;
} & (
  | {
      fromDate: string;
      toDate: string;
    }
  | {
      year: string;
      weekNumber: string;
    }
);

const createInvoice = (params: CreateInvoiceParams) => {
  const postParams: CreateInvoicePostParams = {
    hirerId: params.hirerId,
    ...params.queryType.data,
  };
  return post<CreateInvoiceResponse, CreateInvoicePostParams>(
    _base,
    postParams
  );
};

const getInvoice = (invoiceId: string) => {
  return get<Invoice>(url(invoiceId));
};

const getShiftsAwaitingInvoice = ({
  hirerId,
}: GetShiftsAwaitingInvoiceQueryParams) => {
  return get<ShiftAwaitingInvoice[]>(
    url(['Hirer', hirerId, 'ShiftsAwaitingInvoice'])
  );
};

const getShiftsAwaitingInvoiceSummary = (
  params: GetShiftsAwaitingInvoiceSummaryQueryParams
) => {
  const { hirerId, ...rest } = params;
  const data = rest.queryType.data;
  return get<ShiftsAwaitingInvoiceSummary>(
    url([
      'Hirer',
      hirerId,
      `ShiftsAwaitingInvoiceSummary?${qs.stringify(data)}`,
    ])
  );
};

const removeShift = ({
  invoiceId,
  invoiceItemId,
}: RemoveInvoiceItemFromInvoiceParams) => {
  return put(url([invoiceId, 'InvoiceItem', invoiceItemId, 'Remove']));
};

const addShift = ({ invoiceId, shiftId }: AddShiftToInvoiceParams) => {
  return put(url([invoiceId, 'Shift', shiftId, 'Add']));
};

const deleteInvoice = ({ invoiceId }: DeleteInvoiceParams) => {
  return put(url([invoiceId, 'Delete']));
};

const requestApproval = ({ invoiceId }: RequestApprovalInvoiceParams) => {
  return put(url([invoiceId, 'RequestApproval']));
};

const approve = ({ invoiceId }: ApproveInvoiceParams) => {
  return put(url([invoiceId, 'Approve']));
};

const reject = ({ invoiceId }: RejectInvoiceParams) => {
  return put(url([invoiceId, 'Reject']));
};

const deleteAllInvoiceItems = ({ invoiceId }: DeleteInvoiceParams) => {
  return put(url([invoiceId, 'InvoiceItem', 'RemoveAll']));
};

const markInvoiceAsPaid = ({ invoiceId }: MarkAsPaidInvoiceParams) => {
  return put(url([invoiceId, 'Paid']));
};

const getInvoiceDownload = ({ invoiceId }: DownloadInvoiceParams) => {
  return axiosInt.get<Blob>(url([invoiceId, 'Download']), {
    responseType: 'blob',
  });
};

const getInvoices = (params: GetInvoicesQueryParams) => {
  return get<Invoice[]>(url(['Hirer', params?.hirerId ?? '']));
};

const editInvoice = (params: InvoiceEditParams) => {
  return put(`Invoice/${params.invoiceId}`, params);
};

export const invoiceApi = {
  createInvoice,
  getInvoice,
  getShiftsAwaitingInvoice,
  getShiftsAwaitingInvoiceSummary,
  removeShift,
  addShift,
  deleteInvoice,
  deleteAllInvoiceItems,
  getInvoiceDownload,
  markInvoiceAsPaid,
  getInvoices,
  editInvoice,
  requestApproval,
  approve,
  reject,
};
