import { Alert, Button, Col, Row } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useCancelShiftFromPlanMutation } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall } from 'pages/Utils/react-utils';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { ModalContent } from 'components/Planner/ModalContent';
import { useShiftMenuModalContext } from 'components/Planner/ShiftMenuModalContext';
import {
  BackButton,
  CloseButton,
} from 'components/Planner/shiftMenuModalUtils';

export const CancelShiftModalContent = () => {
  const { shiftToHandle, handleModalClose } = useShiftMenuModalContext();

  const queryClient = useQueryClient();

  const { mutate, isError, error, isLoading, isSuccess } =
    useCancelShiftFromPlanMutation();

  const handleSubmitClick = () => {
    const { shiftId } = shiftToHandle;

    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }

    const model = AppUtils.getDataModel($modal) as {
      comments: string;
    };

    mutate(
      { shiftId, cancellationComments: model.comments },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.VehiclePlanForHirer],
          });
          AppUtils.displaySuccess('Success', 'Shift cancelled successfully');
          handleModalClose();
        },
      }
    );
  };

  return (
    <ModalContent
      title='Cancel Shift'
      Body={<UiStateConfirmCancel isError={isError} error={error} />}
      Footer={
        <UiStateConfirmCancelActions
          isSuccess={isSuccess}
          isLoading={isLoading}
          handleSubmitClick={handleSubmitClick}
        />
      }
    />
  );
};

const UiStateConfirmCancel = ({
  isError,
  error,
}: {
  isError: boolean;
  error: unknown;
}) => {
  const { workerType } = useShiftMenuModalContext();
  return (
    <>
      <WellSmall>
        <p>
          You are going to cancel this shift. You will not be able to undo this.
        </p>
        <p>
          <strong>Please note</strong>: Any commments you add are sent to the{' '}
          {workerType}.
        </p>
      </WellSmall>

      <Row>
        <Field.InCol
          columns={12}
          name='comments'
          label='Why are you cancelling this shift:'
          required
          isTextArea
          maxLength={1000}
        />
      </Row>

      <Alert bsStyle='danger'>Are you sure you want to proceed?</Alert>

      {isError ? (
        <Row>
          <Col xs={12}>
            <ErrorAlert msg={extractErrorMessages(error)} />
          </Col>
        </Row>
      ) : null}
    </>
  );
};

const UiStateConfirmCancelActions = ({
  isSuccess,
  isLoading,
  handleSubmitClick,
}: {
  isSuccess: boolean;
  isLoading: boolean;
  handleSubmitClick: () => void;
}) => {
  return (
    <Row>
      <Col sm={4} style={{ textAlign: 'left' }}>
        <BackButton />
      </Col>
      <Col sm={8}>
        <CloseButton />
        <ModalSaveButton
          isSaved={isSuccess}
          isSaving={isLoading}
          onClick={handleSubmitClick}
        />
      </Col>
    </Row>
  );
};

const ModalSaveButton = ({
  isSaving,
  isSaved,
  onClick,
}: {
  isSaving: boolean;
  isSaved: boolean;
  onClick: () => void;
}) => {
  const isDisabled = isSaving || isSaved;
  const buttonTitle = isSaving
    ? 'Cancelling...'
    : isSaved
    ? 'Cancelled!'
    : `Yes, Cancel Shift`;
  return (
    <Button bsStyle='danger' onClick={onClick} disabled={isDisabled}>
      <Icon icon='fa-times' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};
