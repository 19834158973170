import { Component, useEffect } from 'react';
import { Alert, Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { AgencyUtils } from 'pages/Agency/AgencyUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import {
  DashboardHeader,
  Field,
  Icon,
  SidebarData,
  WellSmall,
  Muted,
} from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import {
  getAgencyProfile,
  getAgencyProfileEditConfig,
  saveAgency,
} from 'utils/api';
import { userPermissions, usePermissions } from 'utils/userPermissions';
import AgencyEditLogoTab from './AgencyEditLogoTab';
import { AgencyUserTable } from './AgencyUsersTable';
import { AddAgencyUserButton } from './Buttons/AddAgencyUserButton';
import { VehicleCostConfigTab } from './VehicleCostConfigTab';

const Sidebar = (props) => {
  return (
    <div className='sidebar-panel' style={{ minHeight: '100%' }}>
      {props.children}
    </div>
  );
};

const Wrapper = (props) => {
  useEffect(() => {
    AppUtils.sidebarPrep();
  }, []);

  return <div className='wrapper'>{props.children}</div>;
};

class AgencyEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isGettingRecord: false,
      record: null,
      isGettingConfig: false,
      configuration: null,
      activeTab: 1,
      isSaving: false,
      errors: [],
    };

    this.subscriber = this.subscriber.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  componentDidMount() {
    this.getRecord();
    this.getConfig();

    const _me = this;
    this.subscribeToEvents().forEach((e) => {
      PubSub.subscribe(e, _me.subscriber);
    });
  }

  subscribeToEvents() {
    return [AgencyUtils.Events.ProfileSaved];
  }

  subscriber(event, data) {
    console.log(`subscriber: ${event}`);
    switch (event) {
      case AgencyUtils.Events.ProfileSaved:
        this.setState({
          record: data.record,
        });
        return;

      default:
        return;
    }
  }

  async getRecord() {
    this.setState({ isGettingRecord: true, errors: [] });
    const data = await getAgencyProfile();
    AppUtils.handleAjaxDone(data, () => {
      if (data.record) {
        this.setState({ record: data.record });
      } else {
        const errors = ['Could not get record'];
        this.setState({ errors });
        throw new Error(errors[0]);
      }
    });
    this.setState({ isGettingRecord: false });
  }

  async getConfig() {
    this.setState({ isGettingConfig: true });

    const data = await getAgencyProfileEditConfig();
    AppUtils.handleAjaxDone(data, () => {
      if (data.configuration) {
        this.setState({ configuration: data.configuration });
      } else {
        throw new Error('Could not get configuration');
      }
    });
    this.setState({ isGettingConfig: false });
  }

  handleChange(e) {
    const { record } = this.state;
    record[e.target.name.toCamelCase()] = e.target.value;
    this.setState({ record });
  }

  setIsSaving(val) {
    this.setState({ isSaving: val });
  }

  async save() {
    const _me = this;

    let model = AppUtils.getDataModel();
    model['AgencyId'] = _me.state.record.agencyId;

    _me.setIsSaving(true);
    const data = await saveAgency(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Success', 'Details saved');
      PubSub.publish(AgencyUtils.Events.ProfileSaved, data);
    });
    setTimeout(() => _me.setIsSaving(false), 500);
  }

  handleTabSelect(tabToMakeActive) {
    this.setState({ activeTab: tabToMakeActive });
  }

  render() {
    if (this.state.errors.length > 0) {
      return <Errors {...this.state} />;
    }
    if (this.state.isGettingConfig) {
      return <GettingConfigMsg {...this.state} />;
    }
    if (this.state.isGettingRecord) {
      return <GettingRecordMsg {...this.state} />;
    }
    if (!this.state.configuration) {
      return null;
    }
    if (!this.state.record) {
      return null;
    }
    return (
      <>
        <Sidebar>
          <SidebarContent {...this.state} handleSaveClick={this.save} />
        </Sidebar>
        <Wrapper>
          <DashboardHeader>
            <Col md={12}>
              <h2>{this.state.record.companyName}</h2>
              <Muted>Admin Settings</Muted>
            </Col>
          </DashboardHeader>
          <EditPanel
            {...this.state}
            handleChange={this.handleChange}
            handleTabSelect={this.handleTabSelect}
          />
        </Wrapper>
      </>
    );
  }
}

const Errors = ({ errors }) => {
  if (errors.length <= 0) {
    return null;
  }
  return (
    <Alert bsStyle='danger'>
      The following errors occurred:
      <ul>
        {errors.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>
    </Alert>
  );
};

const SidebarContent = (props) => {
  const { record } = props;
  return (
    <>
      <div className='btn-container'>
        <SaveButton {...props} handleSaveClick={props.handleSaveClick} />
      </div>
      <SidebarData
        label='Created'
        value={AppUtils.formatDateTimeUtc(record.createdDateTime)}
      />
      <SidebarData label='Created By' value={record.createdBy} />
    </>
  );
};

const EditPanel = (props) => {
  let eventKey = 1;
  return (
    <div className='tabs-container'>
      <Tabs
        activeKey={props.activeTab}
        onSelect={props.handleTabSelect}
        id='agency-edit-tabs'
        animation={false}
      >
        <Tab eventKey={eventKey++} title='Main'>
          <MainDetailsTab {...props} />
        </Tab>
        <Tab eventKey={eventKey++} title='Users'>
          <UsersTab {...props} />
        </Tab>
        <Tab eventKey={eventKey++} title='Billing'>
          <BillingTab {...props} />
        </Tab>
        <Tab eventKey={eventKey++} title='Logo'>
          <AgencyEditLogoTab />
        </Tab>
        <Tab eventKey={eventKey++} title='Vehicle Cost Config'>
          <VehicleCostConfigTab />
        </Tab>
      </Tabs>
    </div>
  );
};

const GettingConfigMsg = ({ isGettingConfig }) => {
  if (!isGettingConfig) {
    return null;
  }
  return (
    <WellSmall hasSpinner>
      Please wait while the profile is configured...
    </WellSmall>
  );
};

const GettingRecordMsg = ({ isGettingRecord }) => {
  if (!isGettingRecord) {
    return null;
  }
  return (
    <WellSmall hasSpinner>
      Please wait while the profile is retrieved...
    </WellSmall>
  );
};

const MainDetailsTab = ({ record, configuration }) => {
  return (
    <>
      <Row>
        <Field.InCol
          columns={12}
          name='CompanyName'
          label='Company Name'
          value={record.companyName}
          maxLength='100'
          disabled
        />
      </Row>
      <Row>
        <Field.InCol
          columns={4}
          name='CompanyNumber'
          label='Company Number'
          value={record.companyNumber}
          maxLength='30'
        />
        <Field.InCol
          columns={8}
          name='CompanyEmailAddress'
          label='Company Email'
          value={record.companyEmailAddress}
          maxLength='256'
        />
      </Row>
      <hr />
      <Row>
        <Field.InCol
          columns={4}
          name='MainContactName'
          label='Main Contact Name'
          value={record.mainContactName}
          maxLength='100'
        />
        <Field.InCol
          columns={8}
          name='MainContactEmailAddress'
          label='Main Contact Email'
          value={record.mainContactEmailAddress}
          maxLength='256'
        />
      </Row>
      <hr />
      <Row>
        <Field.InCol
          columns={4}
          name='AddressLine1'
          label='Address Line 1'
          value={record.addressLine1}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='AddressLine2'
          label='Address Line 2'
          value={record.addressLine2}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='AddressLine3'
          label='Address Line 3'
          value={record.addressLine3}
          maxLength='100'
        />
      </Row>
      <Row>
        <Field.InCol
          columns={4}
          name='Town'
          label='Town/City'
          value={record.town}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='County'
          value={record.county}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='Postcode'
          value={record.postCode}
          maxLength='10'
        />
      </Row>
      <Row>
        <Field.InCol
          columns={4}
          name='Region'
          value={record.region}
          maxLength='100'
        />
      </Row>

      <hr />

      <h3>Invoicing</h3>
      <Row>
        <Field.InCol
          columns={4}
          name='InvoicePrefix'
          label='Invoice Prefix'
          value={record.invoicePrefix}
          maxLength='100'
        />
      </Row>

      <hr />

      <h3>Samsara Integration</h3>
      <Row>
        <Field.InCol
          columns={8}
          name='SamsaraApiKey'
          label='Samsara API Key'
          value={configuration.samsaraApiKeyObfuscated}
          maxLength='100'
        />
      </Row>

      <hr />

      <h3>MapQuest Integration</h3>
      <Row>
        <Field.InCol
          columns={8}
          name='MapQuestApiKey'
          label='MapQuest API Key'
          value={configuration.mapQuestApiKeyObfuscated}
          maxLength='100'
        />
      </Row>
    </>
  );
};

const UsersTab = ({ record }) => {
  const { hasPermission } = usePermissions();
  const canManage = hasPermission(userPermissions.agency.user.manage);

  return (
    <>
      {canManage ? (
        <WellSmall>
          <AddAgencyUserButton companyName={record.companyName} />
        </WellSmall>
      ) : null}
      <AgencyUserTable />
    </>
  );
};

const BillingTab = ({ record }) => {
  return (
    <>
      <Row>
        <Field.InCol
          columns={12}
          name='BillingCompanyName'
          label='Company Name'
          value={record.billingCompanyName}
          maxLength='100'
        />
      </Row>
      <Row>
        <Field.InCol
          columns={4}
          name='BillingCompanyNumber'
          label='Company Number'
          value={record.billingCompanyNumber}
          maxLength='30'
        />
        <Field.InCol
          columns={8}
          name='BillingEmailAddress'
          label='Company Email'
          value={record.billingEmailAddress}
          maxLength='256'
        />
      </Row>
      <Row>
        <Field.InCol
          columns={4}
          name='BillingAddressLine1'
          label='Address Line 1'
          value={record.billingAddressLine1}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='BillingAddressLine2'
          label='Address Line 2'
          value={record.billingAddressLine2}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='BillingAddressLine3'
          label='Address Line 3'
          value={record.billingAddressLine3}
          maxLength='100'
        />
      </Row>
      <Row>
        <Field.InCol
          columns={4}
          name='BillingTown'
          label='Town/City'
          value={record.billingTown}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='BillingCounty'
          label='County'
          value={record.billingCounty}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='BillingPostcode'
          label='Postcode'
          value={record.billingPostcode}
          maxLength='10'
        />
      </Row>
    </>
  );
};

const SaveButton = (props) => {
  const text = props.isSaving ? 'Saving' : 'Save';
  return (
    <Button
      bsStyle='info'
      bsSize='sm'
      block
      onClick={props.handleSaveClick}
      disabled={props.isSaving}
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {text}
    </Button>
  );
};

export default AgencyEdit;
