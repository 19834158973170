import { Row, Col } from 'react-bootstrap';
import { Dropdown } from 'components';

const getHourOptions = () => {
  const hourOptions = [];
  for (let i = 0; i < 24; i++) {
    hourOptions.push(`0${i.toString()}`.slice(-2));
  }
  return hourOptions;
};

const getMinuteOptions = () => {
  const minuteOptions = [];
  for (let i = 0; i < 60; i = i + 5) {
    minuteOptions.push(`0${i.toString()}`.slice(-2));
  }
  return minuteOptions;
};

export const HourAndMinuteDropdown = ({
  timeValue,
  label,
  prefix = '',
  suffix = '',
  required = false,
}: {
  prefix?: string;
  suffix?: string;
  timeValue?: string;
  label: string;
  required?: boolean;
}) => {
  const timeSplit = timeValue ? timeValue.split(':') : [];
  const h = timeSplit.length > 0 ? timeSplit[0] : '';
  const m = timeSplit.length > 0 ? timeSplit[1] : '';
  return (
    <Row>
      <Col sm={6}>
        <Dropdown
          name={`${prefix}TimeHour${suffix}`}
          value={h}
          options={getHourOptions()}
          label={`${label} Hour`}
          required={required}
        />
      </Col>
      <Col sm={6}>
        <Dropdown
          name={`${prefix}TimeMinutes${suffix}`}
          value={m}
          options={getMinuteOptions()}
          label={`${label} Minutes`}
          required={required}
        />
      </Col>
    </Row>
  );
};
