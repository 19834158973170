import { Col, Panel, Row } from 'react-bootstrap';
import { Icon } from 'pages/Utils/react-utils';
import { ShiftTrailersUi } from 'components/ShiftTrailers';

export const Trailers = ({
  shiftId,
  shiftDate,
  shiftTime,
}: {
  shiftId: string;
  shiftDate: string;
  shiftTime: string;
}) => {
  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col sm={12}>
            <Icon icon='fa-clipboard-list' /> Trailers
          </Col>
        </Row>
      </Panel.Heading>
      <Panel.Body>
        <ShiftTrailersUi
          shiftId={shiftId}
          shiftDate={shiftDate}
          shiftTime={shiftTime}
          addBtnSize='xs'
          addBtnContainerClassName='text-right'
        />
      </Panel.Body>
    </Panel>
  );
};
