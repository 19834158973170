import { Route, Switch } from 'utils/router';
import NotFoundPage from '../not-found';
import HirerEdit from './Profile/Edit/HirerEdit';
import ShiftAdmin from './Shift/ShiftAdmin';

const HirerRoutes = () => {
  return (
    <Switch>
      <Route exact path='/Shift' component={ShiftAdmin} />
      <Route exact path='/Profile' component={HirerEdit} />
      <Route path='*' component={NotFoundPage} />
    </Switch>
  );
};

export default HirerRoutes;
