import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { shiftPlanApi } from 'apis/shiftPlanApi';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { useModal } from 'hooks/useModal';
import {
  AcceptShiftsConfigParams,
  PostShiftsConfigParams,
} from 'types/ShiftPlanningTypes';

export const useAddShiftModal = () => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  return {
    isAddShiftModalOpen: isModalOpen,
    handleAddShiftModalOpen: handleModalOpen,
    handleAddShiftModalClose: handleModalClose,
  };
};

export const useSendMessageModal = () => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  return {
    isSendMessageModalOpen: isModalOpen,
    handleSendMessageModalOpen: handleModalOpen,
    handleSendMessageModalClose: handleModalClose,
  };
};

export const usePostShiftsToWorkersModal = () => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  const [shiftPlanItemIds, setShiftPlanItemIds] = useState<string[]>([]);

  const handlePostShiftsModalOpen = (shiftPlanItemIds: string[]) => {
    setShiftPlanItemIds(shiftPlanItemIds);
    handleModalOpen();
  };

  return {
    isPostShiftsModalOpen: isModalOpen,
    handlePostShiftsModalOpen,
    handlePostShiftsModalClose: handleModalClose,
    shiftPlanItemIdsToPost: shiftPlanItemIds,
  };
};

export const useApproveShiftsForWorkersModal = () => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();
  const [shiftPlanItemIds, setShiftPlanItemIds] = useState<string[]>([]);

  const handleApproveShiftsModalOpen = (shiftPlanItemIds: string[]) => {
    setShiftPlanItemIds(shiftPlanItemIds);
    handleModalOpen();
  };

  return {
    isApproveShiftsModalOpen: isModalOpen,
    handleApproveShiftsModalOpen,
    handleApproveShiftsModalClose: handleModalClose,
    shiftPlanItemIdsToApprove: shiftPlanItemIds,
  };
};

export const usePostShiftsConfigQuery = (params: PostShiftsConfigParams) => {
  return useQuery({
    queryKey: [QueryKeys.PostShiftsConfig, params],
    queryFn: () => {
      return shiftPlanApi.getPostShiftsConfig(params);
    },
  });
};

export const useApproveShiftsConfigQuery = (
  params: AcceptShiftsConfigParams
) => {
  return useQuery({
    queryKey: [QueryKeys.AcceptShiftsFromPlanConfig, params],
    queryFn: () => {
      return shiftPlanApi.getAcceptShiftsConfig(params);
    },
  });
};
