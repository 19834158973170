import { Component } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import $ from 'jquery';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { deleteHirer, getHirerById, inviteHirer, saveHirer } from 'utils/api';
import { HirerUtils } from './HirerUtils';

const userInterfaces = {
  inviteHirerUser: 'inviteHirerUser',
  createHirer: 'createHirer',
};

export class HirerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingUi: userInterfaces.inviteHirerUser,
      isSaving: false,
      isGettingRecord: this.props.hirerId ? true : false,
      record: null,
      errorGettingRecord: false,
      wasDeleteClicked: false,
      isDeleting: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onEntered = this.onEntered.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.handleInviteCustomerClick = this.handleInviteCustomerClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleReturnToEditUiClick = this.handleReturnToEditUiClick.bind(this);
    this.handleDeleteConfirmClick = this.handleDeleteConfirmClick.bind(this);
    this.handleShowCreateCustomerUi =
      this.handleShowCreateCustomerUi.bind(this);
    this.handleShowInviteCustomerUi =
      this.handleShowInviteCustomerUi.bind(this);
  }

  handleChange(e) {
    console.log(`HirerModal.handleChange: ${e.target.id}: ${e.target.value}`);
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const fields = { ...this.state.fields, [e.target.id]: value };
    this.setState({ fields });
  }

  onEntered() {
    console.log('HirerModal.onEntered');
    this.getRecord();
  }

  async getRecord() {
    if (!this.props.hirerId) {
      return null;
    }

    const me = this;
    me.setState({ isGettingRecord: true, record: null });

    const data = await getHirerById(this.props.hirerId);
    AppUtils.handleAjaxDone(data, () => {
      if (data.record) {
        me.setState({ record: data.record });
      } else {
        me.setState({ errorGettingRecord: true });
        throw new Error(
          `Something went wrong while getting the company record`
        );
      }
    });
    setTimeout(() => me.setState({ isGettingRecord: false }), 500);

    // $.ajax({
    //   type: "GET",
    //   url: `${AppSettings.baseUrl}/Hirer/Id/${this.props.hirerId}`,
    // })
    //   .done(function (data) {
    //     AppUtils.handleAjaxDone(data, () => {
    //       if (data.record) {
    //         me.setState({ record: data.record });
    //       } else {
    //         me.setState({ errorGettingRecord: true });
    //         throw new Error(`Something went wrong while getting the company record`);
    //       }
    //     });
    //   })
    //   .fail(function (xhr) {
    //     AppUtils.handleXhrFail(xhr);
    //   })
    //   .always(function () {
    //     setTimeout(() => me.setState({ isGettingRecord: false }), 500);
    //   });
  }

  async handleSubmitClick() {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);

    if (this.state.record) {
      model['hirerId'] = this.state.record.hirerId;
    }

    this.setState({ isSaving: true });
    const data = await saveHirer(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Customer saved');
      this.props.handleCloseModalClick();
      PubSub.publish(HirerUtils.Events.HirerSaved, data);
    });
    this.setState({ isSaving: false });

    // $.ajax({
    //   type: "POST",
    //   url: AppSettings.baseUrl + "Hirer/Save",
    //   data: JSON.stringify(model),
    // })
    //   .done(function (data) {
    //     AppUtils.handleAjaxDone(data, () => {
    //       AppUtils.displaySuccess("Done", "Customer saved");
    //       me.props.handleCloseModalClick();
    //       PubSub.publish(HirerUtils.Events.HirerSaved, data);
    //     });
    //   })
    //   .fail(function (xhr) {
    //     AppUtils.handleXhrFail(xhr);
    //   })
    //   .always(function () {
    //     me.setState({ isSaving: false });
    //   });
  }

  async handleInviteCustomerClick() {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);

    this.setState({ isSaving: true });

    const data = await inviteHirer(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Customer Invited');
      this.props.handleCloseModalClick();
      PubSub.publish(HirerUtils.Events.HirerInvited, data);
    });
    this.setState({ isSaving: false });

    // $.ajax({
    //   type: "POST",
    //   url: AppSettings.baseUrl + "Hirer/Invite",
    //   data: JSON.stringify(model),
    // })
    //   .done(function (data) {
    //     AppUtils.handleAjaxDone(data, () => {
    //       AppUtils.displaySuccess("Done", "Customer Invited");
    //       this.props.handleCloseModalClick();
    //       PubSub.publish(HirerUtils.Events.HirerInvited, data);
    //     });
    //   })
    //   .fail(function (xhr) {
    //     AppUtils.handleXhrFail(xhr);
    //   })
    //   .always(function () {
    //     this.setState({ isSaving: false });
    //   });
  }

  handleDeleteClick() {
    console.log('handleDeleteClick');
    this.setState({ wasDeleteClicked: true });
  }

  handleReturnToEditUiClick() {
    console.log('handleReturnTOEditUiClick');
    this.setState({ wasDeleteClicked: false });
  }

  handleShowCreateCustomerUi(e) {
    console.log(`handleShowCreateCustomerUi`);
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showingUi: userInterfaces.createHirer });
  }

  handleShowInviteCustomerUi(e) {
    console.log(`handleShowInviteCustomerUi`);
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showingUi: userInterfaces.inviteHirerUser });
  }

  async handleDeleteConfirmClick() {
    if (!this.state.record) {
      AppUtils.displayError(
        'Error',
        'Could not get the Customer record. Please try again later.'
      );
      return;
    }

    this.setState({ isDeleting: true });

    const data = await deleteHirer({ hirerId: this.state.record.hirerId });
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Customer deleted');
      this.props.handleCloseModalClick();
      PubSub.publish(HirerUtils.Events.HirerDeleted, data);
    });
    this.setState({ isDeleting: false });

    // $.ajax({
    //   type: "POST",
    //   url: AppSettings.baseUrl + "Hirer/Delete",
    //   data: JSON.stringify({ hirerId: me.state.record.hirerId }),
    // })
    //   .done(function (data) {
    //     AppUtils.handleAjaxDone(data, () => {
    //       AppUtils.displaySuccess("Done", "Customer deleted");
    //       me.props.handleCloseModalClick();
    //       PubSub.publish(HirerUtils.Events.HirerDeleted, data);
    //     });
    //   })
    //   .fail(function (xhr) {
    //     AppUtils.handleXhrFail(xhr);
    //   })
    //   .always(function () {
    //     me.setState({ isDeleting: false });
    //   });
  }

  render() {
    const isNew = !this.state.record;
    return (
      <Modal
        bsSize={
          this.state.showingUi === userInterfaces.inviteHirerUser
            ? null
            : 'large'
        }
        backdrop='static'
        show={this.props.showModal}
        onHide={this.props.handleCloseModalClick}
        onEntered={this.onEntered}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.showingUi === userInterfaces.inviteHirerUser
              ? 'Invite New Customer'
              : 'Create New Customer'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body
            {...this.state}
            {...this.props}
            handleChange={this.handleChange}
            isNew={isNew}
            handleShowCreateCustomerUi={this.handleShowCreateCustomerUi}
          />
        </Modal.Body>
        <FooterMain
          {...this.props}
          {...this.state}
          handleSubmitClick={this.handleSubmitClick}
          handleInviteCustomerClick={this.handleInviteCustomerClick}
          handleDeleteClick={this.handleDeleteClick}
          handleReturnToEditUiClick={this.handleReturnToEditUiClick}
          handleDeleteConfirmClick={this.handleDeleteConfirmClick}
          handleShowInviteCustomerUi={this.handleShowInviteCustomerUi}
          closeButton={
            <Button onClick={this.props.handleCloseModalClick}>
              <Icon icon='fa-times' /> Cancel
            </Button>
          }
          isNew={isNew}
        />
      </Modal>
    );
  }
}

const Body = (props) => {
  if (props.isGettingRecord) {
    return (
      <WellSmall hasSpinner className='m-b-none'>
        Getting customer details...
      </WellSmall>
    );
  }
  if (props.errorGettingRecord) {
    return (
      <WellSmall className='m-b-none text-danger'>
        Could not get customer details.
      </WellSmall>
    );
  }
  if (props.hirerId && !props.record) {
    return null;
  }
  if (props.wasDeleteClicked) {
    return <DeleteConfirmUi {...props} />;
  }
  if (props.showingUi === userInterfaces.inviteHirerUser) {
    return <InviteHirerUserUi {...props} />;
  }
  if (props.showingUi === userInterfaces.createHirer) {
    return <EditUi {...props} />;
  }
  return null;
};

const DeleteConfirmUi = (props) => {
  if (!props.wasDeleteClicked) {
    return null;
  }
  const { record } = props;
  return (
    <>
      <p>You have chosen to delete the following:</p>
      <WellSmall className='m-b-sm'>
        <DeleteConfirmDataRow label='Company Name' value={record.companyName} />
        <DeleteConfirmDataRow
          label='Company Number'
          value={record.companyNumber}
        />
        <DeleteConfirmDataRow
          label='Company Email'
          value={record.companyEmailAddress}
        />
        <DeleteConfirmDataRow label='Town' value={record.town} />
        <DeleteConfirmDataRow label='County' value={record.county} />
        <DeleteConfirmDataRow label='Region' value={record.region} />
      </WellSmall>
      <p className='text-danger m-b-none'>
        Are you sure you want to proceed?{' '}
        <strong>You will not be able to undo this.</strong>
      </p>
    </>
  );
};

const DeleteConfirmDataRow = (props) => {
  return (
    <Row>
      <Col sm={3} className='text-right font-bold'>
        {props.label}
      </Col>
      <Col sm={9}>{props.value}</Col>
    </Row>
  );
};

const InviteHirerUserUi = () => {
  return (
    <>
      <WellSmall>
        {`Complete the details below and press "Invite Customer". The user you specify will then receive an invitation to become a customer of your agency.`}
        {/* Disable ability for Agency to manage the hirer workflow until we can build that functionality into Agency UI */}
        {/* <br />
      <SmallMuted>If you don't want to invite any users from your customer then you can <a href="" onClick={(e) => props.handleShowCreateCustomerUi(e)}>create the Customer manually</a>.</SmallMuted>*/}
      </WellSmall>
      <Row>
        <Field.InCol
          columns={12}
          name='CompanyName'
          label='Company Name'
          maxLength='100'
          required
        />
      </Row>
      <Row>
        <Field.InCol
          columns={12}
          name='FirstName'
          label='Main Contact First Name'
          maxLength='50'
          required
        />
      </Row>
      <Row>
        <Field.InCol
          columns={12}
          name='Surname'
          label='Main Contact Surname'
          maxLength='50'
          required
        />
      </Row>
      <Row>
        <Field.InCol
          columns={12}
          name='EmailAddress'
          label='Main Contact Email Address'
          maxLength='50'
          required
        />
      </Row>
    </>
  );
};

const EditUi = (props) => {
  if (props.wasDeleteClicked) {
    return null;
  }
  const record = props.record || {};
  return (
    <>
      <Row>
        <Field.InCol
          columns={4}
          name='CompanyName'
          label='Company Name'
          value={record.companyName}
          maxLength='100'
          required
        />
      </Row>
      <Row>
        <Field.InCol
          columns={4}
          name='CompanyNumber'
          label='Company Number'
          value={record.companyNumber}
          maxLength='30'
        />
        <Field.InCol
          columns={8}
          name='CompanyEmailAddress'
          label='Company Email'
          value={record.companyEmailAddress}
          maxLength='256'
        />
      </Row>
      <hr />
      <Row>
        <Field.InCol
          columns={4}
          name='MainContactName'
          label='Main Contact Name'
          value={record.mainContactName}
          maxLength='100'
        />
        <Field.InCol
          columns={8}
          name='MainContactEmailAddress'
          label='Main Contact Email'
          value={record.mainContactEmailAddress}
          maxLength='256'
        />
      </Row>
      <hr />
      <Row>
        <Field.InCol
          columns={4}
          name='AddressLine1'
          label='Address Line 1'
          value={record.addressLine1}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='AddressLine2'
          label='Address Line 2'
          value={record.addressLine2}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='AddressLine3'
          label='Address Line 3'
          value={record.addressLine3}
          maxLength='100'
        />
      </Row>
      <Row>
        <Field.InCol
          columns={4}
          name='Town'
          label='Town/City'
          value={record.town}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='County'
          value={record.county}
          maxLength='100'
        />
        <Field.InCol
          columns={4}
          name='Postcode'
          value={record.postCode}
          maxLength='10'
        />
      </Row>
      <Row>
        <Field.InCol
          columns={4}
          name='Region'
          value={record.region}
          maxLength='100'
        />
      </Row>
    </>
  );
};

const FooterMain = (props) => {
  if (props.isGettingRecord) {
    return null;
  }

  let footerUi;
  if (props.showingUi === userInterfaces.inviteHirerUser) {
    footerUi = <FooterForInviteCustomerUi {...props} />;
  } else {
    footerUi = <FooterForCreateCustomerUi {...props} />;
  }
  return <Modal.Footer>{footerUi}</Modal.Footer>;
};

const FooterForInviteCustomerUi = (props) => {
  return (
    <Row>
      <Col sm={12}>
        {props.closeButton}
        <InviteCustomerButton {...props} />
      </Col>
    </Row>
  );
};

const FooterForCreateCustomerUi = (props) => {
  return (
    <Row>
      <Col sm={5} className='text-left'>
        <ReturnToInviteUiButton {...props} />
      </Col>
      <Col sm={7}>
        {props.closeButton}
        <SaveButton {...props} />
      </Col>
    </Row>
  );
};

// const FooterForConfirmDeleteUi = (props) => {
//   return (
//     <Row>
//       <Col sm={5} className='text-left'>
//         <ReturnToEditUiButton {...props} />
//       </Col>
//       <Col sm={7} className='text-right'>
//         {props.closeButton}
//         <DeleteConfirmButton {...props} />
//       </Col>
//     </Row>
//   );
// };

const InviteCustomerButton = (props) => {
  const buttonTitle = `Invit${props.isSaving ? 'ing...' : 'e Customer'}`;

  return (
    <Button
      bsStyle='success'
      onClick={props.handleInviteCustomerClick}
      disabled={props.isSaving}
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};

const SaveButton = (props) => {
  const buttonTitle = `${props.hirerId ? 'Sav' : 'Creat'}${
    props.isLoading ? 'ing...' : 'e Customer'
  }`;
  return (
    <Button
      bsStyle='success'
      onClick={props.handleSubmitClick}
      disabled={props.isSaving}
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};

const ReturnToInviteUiButton = (props) => {
  return (
    <Button bsStyle='primary' onClick={props.handleShowInviteCustomerUi}>
      <Icon icon='fa-arrow-left' /> Back
    </Button>
  );
};

// const ReturnToEditUiButton = (props) => {
//   return (
//     <Button bsStyle='primary' onClick={props.handleReturnToEditUiClick}>
//       <Icon icon='fa-arrow-left' /> Back
//     </Button>
//   );
// };

// const DeleteButton = (props) => {
//   if (props.isNew) return null;
//   return (
//     <Button bsStyle='danger' onClick={props.handleDeleteClick}>
//       <Icon icon='fa-trash-alt' /> Delete Customer
//     </Button>
//   );
// };

// const DeleteConfirmButton = (props) => {
//   const buttonTitle = `Delet${props.isLoading ? 'ing...' : 'e Customer'}`;
//   return (
//     <Button bsStyle='danger' onClick={props.handleDeleteConfirmClick} disabled={props.isDeleting}>
//       <Icon icon='fa-trash-alt' isSpinning={props.isDeleting} /> {buttonTitle}
//     </Button>
//   );
// };
