import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useModal } from 'hooks/useModal';
import { AppUtils } from 'pages/Utils/app-utils';
import { Invoice } from 'types/Invoice';
import { Stack } from 'components/Stack';
import { AwaitingInvoiceShifts } from './AwaitingInvoiceShifts';
import { EditInvoiceModal } from './EditInvoiceModal';
import { useInvoiceContext } from './InvoiceContext';
import { InvoiceShifts } from './InvoiceShifts';

export const InvoiceMain = () => {
  return (
    <Stack gap={2} direction='column'>
      <Summary />
      <AwaitingInvoiceShifts />
      <InvoiceShifts />
    </Stack>
  );
};

const useEditInvoiceModal = () => {
  const [invoiceToEdit, setInvoiceToEdit] = useState<Invoice | null>(null);
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const handleShowModalClick = (invoice: Invoice) => {
    setInvoiceToEdit(invoice);
    handleModalOpen();
  };

  const handleCloseModalClick = () => {
    handleModalClose();
  };

  return {
    handleShowModalClick,
    handleCloseModalClick,
    isModalOpen,
    invoiceToEdit,
  };
};

const Summary = () => {
  const { invoice, isEditable } = useInvoiceContext();
  const {
    handleCloseModalClick,
    handleShowModalClick,
    isModalOpen,
    invoiceToEdit,
  } = useEditInvoiceModal();

  const handleEditClick = () => {
    handleShowModalClick(invoice);
  };

  return (
    <>
      <table className='table'>
        <thead>
          <tr>
            {[
              'To',
              'Invoice Date',
              'Due Date',
              'Invoice Number',
              'PO Number',
              'Sub-Total',
              'VAT',
              'Total',
            ].map((header, i) => (
              <th key={i}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {[
              invoice.hirerName,
              AppUtils.formatDateTimeUtc(
                invoice.invoiceDate,
                undefined,
                'DD MMM YYYY'
              ),
              AppUtils.formatDateTimeUtc(
                invoice.invoiceDueDate,
                undefined,
                'DD MMM YYYY'
              ),
              invoice.invoiceReference,
              invoice.poReference,
              AppUtils.getCurrencyVal(invoice.subTotal),
              AppUtils.getCurrencyVal(invoice.vatAmount),
              AppUtils.getCurrencyVal(invoice.total),
            ].map((value, i) => (
              <td key={i}>{value}</td>
            ))}
            <td align='right'>
              {isEditable ? (
                <Button bsStyle='primary' bsSize='sm' onClick={handleEditClick}>
                  Edit
                </Button>
              ) : null}
            </td>
          </tr>
        </tbody>
      </table>
      {invoiceToEdit ? (
        <EditInvoiceModal
          handleCloseModalClick={handleCloseModalClick}
          showModal={isModalOpen}
          record={invoiceToEdit}
        />
      ) : null}
    </>
  );
};
