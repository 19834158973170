import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import useSubscription from 'hooks/useSubscription';
import { Checkbox } from 'pages/Utils/Checkbox';
import { AppUtils, Constants } from 'pages/Utils/app-utils';
import { EmptyLabel, Field, Icon, SmallMuted } from 'pages/Utils/react-utils';
import {
  deleteExperienceRequirement,
  getExperienceRequirements,
  saveExperienceRequirement,
} from 'utils/api';
import { TabIntro } from '../ShiftConfigCommon';
import { getWorkerType } from '../ShiftConfigUtils';
import { useShiftConfigContext } from './ShiftConfigContextProvider';

const ApplicantTab = () => {
  const { record, isHgvShift, disabled } = useShiftConfigContext();
  return (
    <>
      <TabIntro
        icon={`fa-2x fa-${isHgvShift ? 'truck-container' : 'briefcase'}`}
      >
        Enter {getWorkerType(record)} requirements for this Shift.
      </TabIntro>
      <Row>
        <Checkbox
          columns={4}
          id='IsSiteAssessmentRequired'
          label={`Is site ${isHgvShift ? 'assessment' : 'induction'} required?`}
          checked={record.isSiteAssessmentRequired}
          disabled={disabled}
        />
        <Checkbox
          columns={4}
          id='IsOptOutAgreementRequired'
          label='Is 48hr opt-out agreement required?'
          checked={record.isOptOutAgreementRequired}
          disabled={disabled}
        />
        <Checkbox
          columns={4}
          id='IsNightRuleDeclarationRequired'
          label='Is 10 hour night rule declaration required?'
          checked={record.isNightRuleDeclarationRequired}
          disabled={disabled}
        />
      </Row>
      <hr />
      {isHgvShift ? (
        <ApplicantTabHgvSpecificFields />
      ) : (
        <ApplicantTabOtherSpecificFields />
      )}
    </>
  );
};

const ApplicantTabHgvSpecificFields = () => {
  const { record, disabled } = useShiftConfigContext();
  return (
    <>
      <Row>
        <Field.InCol
          columns={4}
          name='MinimumYearsExperienceRequired'
          label='Minimum years experience'
          value={record.minimumYearsExperienceRequired}
          options={Array.from(new Array(50), (val, index) => {
            const year = index + 1;
            return {
              value: year,
              text: `${year} year${year === 1 ? '' : 's'}`,
            };
          })}
          required
          isSelect
          disabled={disabled}
        />
        <Field.InCol
          columns={4}
          name='DrivingLicenceTypeRequired'
          label='Driving Licence Type'
          value={record.drivingLicenceTypeRequired}
          options={AppUtils.options.licenceTypeOptions}
          isSelect
          required
          disabled={disabled}
        />
        <Field.InCol
          columns={4}
          name='MaxPointsOnLicenceAllowed'
          label='Max points on licence'
          value={record.maxPointsOnLicenceAllowed}
          maxLength='2'
          addOnPost='Points'
          required
          isNumber
          disabled={disabled}
        />
      </Row>
      <hr />
      <Row>
        <Field.InCol
          columns={12}
          name='ProhibitedConvictionList'
          label='Prohibited Convictions'
          value={record.prohibitedConvictionList}
          options={AppUtils.options.prohibitedConvictionOptions}
          isSelect
          multiple
          disabled={disabled}
        />
      </Row>
    </>
  );
};

const ApplicantTabOtherSpecificFields = () => {
  const { record, disabled } = useShiftConfigContext();
  return (
    <>
      <Row>
        <Field.InCol
          columns={4}
          name='MinimumAgeRequired'
          label='Minimum Age Required'
          value={record.minimumAgeRequired}
          tooltip={`Leave blank for no minimum age limit`}
          maxLength={2}
          disabled={disabled}
        />
      </Row>
      <hr />
      <ExperienceRequirements />
    </>
  );
};

const experienceRequirementsContainerId = 'experience-requirements-container';
const ExperienceRequirements = () => {
  const {
    record,
    configuration,
    disabled,
    experienceRecords,
    setExperienceRecords,
  } = useShiftConfigContext();
  const [showEditFields, setShowEditFields] = useState(false);
  const [editFieldsState, setEditFieldsState] = useState({});
  const [editExperienceRecordId, setEditExperienceRecordId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const { refetch } = useQuery(
    ['experienceRequirements', record.shiftConfigId],
    () => getExperienceRequirements(record.shiftConfigId),
    {
      enabled: !!record,
      refetchInterval: Constants.DefaultAutoRefetchFrequency,
      onSuccess: (data) => setExperienceRecords(data?.records || []),
    }
  );

  const experienceTypeRef = useRef();
  useEffect(() => {
    if (showEditFields && experienceTypeRef.current) {
      experienceTypeRef.current.focus();
    }
  }, [experienceTypeRef, showEditFields]);

  const handleChange = (e) => {
    const newState = { ...editFieldsState };
    newState[e.target.name] = e.target.value;
    setEditFieldsState(newState);
  };

  const resetEditFields = () => {
    setEditFieldsState({});
  };

  useSubscription([AppUtils.Events.ExperienceRequirementSaved], () => {
    refetch();
    resetEditFields();
    setEditExperienceRecordId(null);
    experienceTypeRef.current.focus();
  });

  useSubscription([AppUtils.Events.ExperienceRequirementDeleted], refetch);

  const handleAddExperienceClick = () => {
    resetEditFields();
    setEditExperienceRecordId(null);
    setShowEditFields(true);
  };

  const validate = () => {
    return AppUtils.validateItemsWithIds(['ExperienceType', 'RequirementType']);
  };

  const handleSaveClick = async () => {
    if (validate()) {
      setIsSaving(true);
      const model = { ...editFieldsState };
      model['shiftConfigId'] = record.shiftConfigId;
      model['experienceRequirementId'] = editExperienceRecordId;
      const data = await saveExperienceRequirement(model);
      AppUtils.handleAjaxDone(data, () => {
        AppUtils.displaySuccess('Done', 'Experience Requirement saved');
        AppUtils.publish(AppUtils.Events.ExperienceRequirementSaved, data);
      });
      setIsSaving(false);
    }
  };

  const handleCancelClick = () => {
    if (editExperienceRecordId) {
      setEditExperienceRecordId(null);
      setShowEditFields(false);
    } else {
      setShowEditFields(false);
    }
  };

  const handleEditClick = (experienceRequirementId) => {
    const recordToEdit = experienceRecords.find(
      (experienceRecord) =>
        experienceRecord.experienceRequirementId === experienceRequirementId
    );
    if (!recordToEdit) {
      AppUtils.displayError('Error', 'Could not find Experience record');
      return;
    }

    AppUtils.destroyTooltips();
    const newState = {};
    Object.entries(recordToEdit).forEach(
      ([key, value]) => (newState[AppUtils.capitaliseFirstLetter(key)] = value)
    );
    setEditFieldsState(newState);
    setEditExperienceRecordId(experienceRequirementId);
    setShowEditFields(true);
  };

  const handleDeleteClick = async (experienceRequirementId) => {
    const data = await deleteExperienceRequirement(experienceRequirementId);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Experience Requirement deleted');
      AppUtils.publish(AppUtils.Events.ExperienceRequirementDeleted, data);
    });
  };

  const existingExperienceTypes = experienceRecords.map(
    (experienceRecord) => experienceRecord.experienceType
  );

  return (
    <>
      <h3>
        Experience
        {!disabled && !showEditFields && (
          <Button
            bsStyle='success'
            bsSize='xs'
            className='m-l-sm'
            onClick={handleAddExperienceClick}
          >
            <Icon icon='fa-plus' /> Add
          </Button>
        )}
      </h3>
      <SmallMuted>
        Define the types of experience desired and/or required for this shift.
      </SmallMuted>
      <hr />

      {showEditFields && (
        <>
          <Row id={experienceRequirementsContainerId}>
            <Field.InCol
              columns={3}
              name='ExperienceType'
              label='Experience'
              isSelect
              options={configuration.experienceOptions[
                record.shiftIndustryType?.toLowerCase()
              ].map((option) => {
                return {
                  value: option,
                  text: option,
                  isDisabled: existingExperienceTypes?.includes(option),
                };
              })}
              disabled={disabled || isSaving}
              innerRef={experienceTypeRef}
              onChange={handleChange}
              value={editFieldsState.ExperienceType}
            />
            <Field.InCol
              columns={3}
              name='RequirementType'
              label='Requirement'
              isSelect
              options={['Desired', 'Required']}
              disabled={disabled || isSaving}
              onChange={handleChange}
              value={editFieldsState.RequirementType}
            />
            <Field.InCol
              columns={3}
              name='MinimumYearsRequired'
              label='Min Years Experience'
              isNumber
              disabled={disabled || isSaving}
              onChange={handleChange}
              value={editFieldsState.MinimumYearsRequired}
            />
            <Col sm={3}>
              <EmptyLabel />
              <Row>
                <Col sm={6}>
                  <Button
                    bsSize='sm'
                    bsStyle='success'
                    onClick={handleSaveClick}
                    block
                    disabled={isSaving}
                  >
                    <Icon icon='fa-check' isSpinning={isSaving} /> Save
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button
                    bsSize='sm'
                    bsStyle='danger'
                    onClick={handleCancelClick}
                    block
                    disabled={isSaving}
                  >
                    <Icon icon='fa-times' isSpinning={isSaving} /> Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
        </>
      )}
      <ExperienceRequirementsTable
        experienceRecords={experienceRecords}
        editExperienceRecordId={editExperienceRecordId}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
      />
    </>
  );
};

const ExperienceRequirementsTable = ({
  experienceRecords,
  editExperienceRecordId,
  handleEditClick,
  handleDeleteClick,
}) => {
  const { disabled } = useShiftConfigContext();
  if (experienceRecords.length <= 0) {
    return null;
  }
  const isBeingEdited = (idToCheck) => idToCheck === editExperienceRecordId;
  return (
    <table className='table table-condensed table-striped table-hover'>
      <thead>
        <tr>
          <th>Experience</th>
          <th>Requirement</th>
          <th>Min Years Experience</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {experienceRecords.map((experienceRecord, i) => (
          <ExperienceRequirementsRow
            key={i}
            disabled={disabled}
            isBeingEdited={isBeingEdited(
              experienceRecord.experienceRequirementId
            )}
            experienceRecord={experienceRecord}
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
          />
        ))}
      </tbody>
    </table>
  );
};

const editStyle = { background: '#d9f7da' };
const ExperienceRequirementsRow = ({
  disabled,
  experienceRecord,
  handleEditClick,
  handleDeleteClick,
  isBeingEdited,
}) => {
  return (
    <tr style={isBeingEdited ? editStyle : {}}>
      <td>{experienceRecord.experienceType}</td>
      <td>{experienceRecord.requirementType}</td>
      <td>{experienceRecord.minimumYearsRequired}</td>
      <td className='text-right' style={{ width: '300px' }}>
        {disabled ? null : isBeingEdited ? (
          <SmallMuted>Currently Being Edited</SmallMuted>
        ) : (
          <>
            <Button
              bsSize='xs'
              bsStyle='primary'
              data-toggle='tooltip'
              title={`Edit ${experienceRecord.experienceType}`}
              onClick={() =>
                handleEditClick(experienceRecord.experienceRequirementId)
              }
            >
              <Icon icon='fa-fw fa-pencil' />
            </Button>
            <DeleteButton
              experienceRecordId={experienceRecord.experienceRequirementId}
              handleDeleteClick={handleDeleteClick}
            />
          </>
        )}
      </td>
    </tr>
  );
};

const DeleteButton = ({ experienceRecordId, handleDeleteClick }) => {
  const [isClicked, setIsClicked] = useState(false);
  const handleClick = () => {
    AppUtils.destroyTooltips();
    if (isClicked) {
      handleDeleteClick(experienceRecordId);
    } else {
      setIsClicked(true);
    }
  };

  const className = 'm-l-xs';
  return (
    <>
      {isClicked ? (
        <div style={{ display: 'inline-block' }} className={className}>
          Are you sure?
        </div>
      ) : null}
      <Button
        bsSize='xs'
        bsStyle='danger'
        onClick={handleClick}
        className={className}
        data-placement='right'
        data-toggle='tooltip'
        title='Delete?'
      >
        {isClicked ? 'Yes, Delete' : <Icon icon='fa-fw fa-times' />}
      </Button>
      {isClicked ? (
        <Button
          bsSize='xs'
          bsStyle='default'
          onClick={() => setIsClicked(false)}
          className={className}
        >
          Cancel
        </Button>
      ) : null}
    </>
  );
};

export default ApplicantTab;
