import { ComponentProps } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { AppUtils } from 'pages/Utils/app-utils';

export const ResponsiveBarChartHorizontal = ({
  data,
  keys,
  indexBy,
  colors,
  showLegend = true,
  marginLeft = 100,
  valueFormat = (v) => `£${AppUtils.formatNumber(v)}`,
}: {
  data: ComponentProps<typeof ResponsiveBar>['data'];
  keys: ComponentProps<typeof ResponsiveBar>['keys'];
  indexBy: ComponentProps<typeof ResponsiveBar>['indexBy'];
  colors?: ComponentProps<typeof ResponsiveBar>['colors'];
  showLegend?: boolean;
  marginLeft?: number;
  valueFormat?: ComponentProps<typeof ResponsiveBar>['valueFormat'];
}) => {
  return (
    <ResponsiveBar
      colors={colors ?? { scheme: 'pastel1' }}
      data={data}
      indexBy={indexBy}
      keys={keys}
      groupMode='grouped'
      layout='horizontal'
      margin={{ top: 50, right: 130, bottom: 50, left: marginLeft }}
      valueScale={{ type: 'linear' }}
      valueFormat={valueFormat}
      indexScale={{ type: 'band', round: true }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      legends={
        showLegend
          ? [
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
          : undefined
      }
    />
  );
};
