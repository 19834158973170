import { SpinIcon } from '../pages/Utils/react-utils';
import Section from './Section';
// import Spinner from "react-bootstrap/Spinner";

function PageLoader(props) {
  const { style, children, ...otherProps } = props;

  return (
    <Section
      bg='white'
      className='d-flex justify-content-center align-items-center'
      style={{
        height: '400px',
        ...style,
      }}
      {...otherProps}
    >
      <div>
        {!children ? (
          <div style={{ textAlign: 'center', padding: '40px' }}>
            <SpinIcon style={{ color: '#fff' }} />
          </div>
        ) : (
          <>{children}</>
        )}
      </div>
    </Section>
  );
}

export default PageLoader;
