import { Component } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import $ from 'jquery';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall, SmallMuted } from 'pages/Utils/react-utils';
import { addAdjustment } from 'utils/api';

export class AddAdjustmentButton extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  handleShowModalClick() {
    this.setState({ showModal: true });
  }

  handleCloseModalClick() {
    this.setState({ showModal: false });
  }

  render() {
    const record = this.props[`shift${this.props.adjustmentType}`];
    if (record.adjustmentValue !== 0) {
      return null;
    }
    return (
      <>
        <Button
          bsStyle='primary'
          onClick={this.handleShowModalClick}
          bsSize='xs'
          className={this.props.className}
        >
          <Icon icon='fa-sliders-h' /> Add...
        </Button>
        <AddAdjustmentModal
          {...this.state}
          {...this.props}
          record={record}
          handleCloseModalClick={this.handleCloseModalClick}
        />
      </>
    );
  }
}

class AddAdjustmentModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();

    this.onExited = this.onExited.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
  }

  defaultState() {
    return {
      isSaving: false,
      isSaved: false,
    };
  }

  onExited() {
    this.setState(this.defaultState());
  }

  onEntered() {
    document.getElementById('AdjustmentValue').focus();
  }

  async handleAddClick() {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    let model = AppUtils.getDataModel($modal);
    model['ShiftId'] = this.props.record.shiftId;
    model['AdjustmentType'] = this.props.adjustmentType;

    this.setState({ isSaving: true });
    AppUtils.clearNotifications();

    const data = await addAdjustment(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Success', 'Adjustment added');
      this.setState({ isSaved: true });
      setTimeout(() => this.props.handleCloseModalClick(), 500);
      AppUtils.publish(ShiftUtils.Events.AdjustmentAdded, data);
    });
    this.setState({ isSaving: false });

    // $.ajax({
    //   type: "POST",
    //   url: getBaseUrl() + "Shift/AddAdjustment",
    //   data: JSON.stringify(model),
    // })
    //   .done(function (data) {
    //     AppUtils.handleAjaxDone(data, () => {
    //       AppUtils.displaySuccess("Success", "Adjustment added");
    //       this.setState({ isSaved: true });
    //       setTimeout(() => this.props.handleCloseModalClick(), 500);
    //       AppUtils.publish(ShiftUtils.Events.AdjustmentAdded, data);
    //     });
    //   })
    //   .fail(function (xhr) {
    //     AppUtils.handleXhrFail(xhr);
    //   })
    //   .always(function () {
    //     this.setState({ isSaving: false });
    //   });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={this.props.showModal}
        onHide={this.props.handleCloseModalClick}
        onEntered={this.onEntered}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add {this.props.adjustmentType} Adjustment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.props} {...this.state} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.handleCloseModalClick} bsSize='sm'>
            <i className='fa fa-times' /> Close
          </Button>
          <ModalSaveButton
            {...this.props}
            {...this.state}
            onClick={this.handleAddClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = (props) => {
  const { isSaved, adjustmentType } = props;
  if (isSaved) {
    return <SavedUi isSaved={isSaved} adjustmentType={adjustmentType} />;
  }

  return (
    <>
      <Row>
        <Field.InCol
          columns={12}
          name='AdjustmentValue'
          label='Adjustment Amount'
          addOnPre='£'
          required
          isNumber
        />
        <Field.InCol
          columns={12}
          name='AdjustmentReason'
          label='Adjustment Reason'
          maxLength={500}
          isTextArea
          required
          postFieldComponent={
            <SmallMuted>
              Please add details of any justification for the adjustment.
            </SmallMuted>
          }
        />
      </Row>
    </>
  );
};

const SavedUi = ({ isSaved, adjustmentType }) =>
  !isSaved ? null : (
    <WellSmall className='text-success m-b-none'>
      <Icon icon='fa-check' /> {adjustmentType} Adjustment Added!
    </WellSmall>
  );

const ModalSaveButton = (props) => {
  const isDisabled = props.isSaving || props.isSaved;
  const buttonTitle = props.isSaving
    ? 'Adding...'
    : props.isSaved
    ? `${props.adjustmentType} Adjustment Added`
    : `Add ${props.adjustmentType} Adjustment`;
  return (
    <Button
      bsStyle='success'
      onClick={props.onClick}
      disabled={isDisabled}
      bsSize='sm'
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
