import { AlertWithIcon } from '../Utils/react-utils';

export const TabIntro = (props) => {
  return (
    <AlertWithIcon
      bsStyle='info'
      icon={props.icon}
      style={{ margin: '0 0 20px' }}
    >
      {props.children}
    </AlertWithIcon>
  );
};
