import { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useCheckVehicleSchedule } from 'hooks';
import { useUpdateVehicleMutation, useVehicleOptions } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall } from 'pages/Utils/react-utils';
import { ICustomEvent } from 'types/GeneralTypes';
import { extractErrorMessages } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { useShiftContext } from '../ShiftContext';
import { ShiftUtils } from '../ShiftUtils';

export const ChangeVehicleModal = ({
  isModalOpen,
  handleModalClose,
  existingVehicleId,
  shift: { shiftDate, shiftTime },
}: {
  isModalOpen: boolean;
  handleModalClose: () => void;
  existingVehicleId?: string;
  shift: { shiftDate: string; shiftTime: string };
}) => {
  const { shiftId } = useShiftContext();
  const updateVehicle = useUpdateVehicleMutation();
  const queryClient = useQueryClient();

  const [vehicleId, setVehicleId] = useState<string | null>(existingVehicleId);

  const { isFetching, renderUi } = useCheckVehicleSchedule(
    vehicleId,
    shiftDate,
    shiftTime,
    shiftId
  );

  const handleSubmitClick = () => {
    const $modal = $('.modal');

    if (!AppUtils.validateItems($modal.find('.required'))) {
      return false;
    }
    const params = AppUtils.getDataModel($modal) as { VehicleId: string };

    updateVehicle.mutate(
      {
        shiftId,
        vehicleId: params.VehicleId,
      },
      {
        onSuccess: () => {
          AppUtils.publish(ShiftUtils.Events.ShiftVehicleChanged);
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ShiftEdit, shiftId],
          });
          AppUtils.displaySuccess('Success', 'Vehicle updated successfully');
          handleModalClose();
        },
      }
    );
  };

  const { query, vehicleOptions } = useVehicleOptions();

  return (
    <Modal backdrop='static' show={isModalOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Change Vehicle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {query.isLoading ? (
          <Row>
            <Col xs={12}>
              <WellSmall hasSpinner />
            </Col>
          </Row>
        ) : null}

        {query.isError ? (
          <Row>
            <Col xs={12}>
              <ErrorAlert msg={extractErrorMessages(query.error)} />
            </Col>
          </Row>
        ) : null}

        {query.isSuccess ? (
          <>
            <Row>
              <Field.InCol
                columns={12}
                name={`VehicleId`}
                label={'Vehicle'}
                options={vehicleOptions}
                value={vehicleId}
                onChange={(e: ICustomEvent) => {
                  setVehicleId(e.target.value);
                }}
                isSelect
                required
                disabled={isFetching}
              />
            </Row>

            {renderUi()}
          </>
        ) : null}

        {updateVehicle.isError ? (
          <Row>
            <Col xs={12}>
              <ErrorAlert msg={extractErrorMessages(updateVehicle.error)} />
            </Col>
          </Row>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleModalClose}>
          <i className='fa fa-times' /> Close
        </Button>
        <ModalSaveButton
          isSaved={updateVehicle.isSuccess}
          isSaving={updateVehicle.isLoading}
          onClick={handleSubmitClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

const ModalSaveButton = ({
  isSaving,
  isSaved,
  onClick,
}: {
  isSaving: boolean;
  isSaved: boolean;
  onClick: () => void;
}) => {
  const isDisabled = isSaving || isSaved;
  const buttonTitle = isSaving
    ? 'Updating...'
    : isSaved
    ? 'Updated!'
    : `Update Vehicle`;
  return (
    <Button bsStyle='success' onClick={onClick} disabled={isDisabled}>
      <Icon icon='fa-check' isSpinning={isSaving} /> {buttonTitle}
    </Button>
  );
};
