import { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { ShiftAllocatedListView } from './ShiftAllocatedListView';
import { ShiftApprovedListView } from './ShiftApprovedListView';
import { ShiftCompleteListView } from './ShiftCompleteListView';
import { ShiftInProgressListView } from './ShiftInProgressListView';

class ShiftAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
    };

    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  handleTabSelect(tabToMakeActive) {
    this.setState({ activeTab: tabToMakeActive });
  }

  render() {
    return <TabsMain {...this.state} handleTabSelect={this.handleTabSelect} />;
  }
}

const CompleteTabLabel = (props) => {
  return (
    <>
      <span>Complete</span>
      <span
        className={`m-l-xs label ${
          props.isWarning
            ? 'label-warning'
            : props.isSuccess
            ? 'label-success'
            : ''
        }`}
      >
        {props.subTitle}
      </span>
    </>
  );
};

const TabsMain = ({ activeTab, handleTabSelect }) => {
  let eventKey = 1;
  return (
    <div className='tabs-container'>
      <Tabs
        activeKey={activeTab}
        animation={true}
        onSelect={handleTabSelect}
        id='shift-admin-tabs'
        mountOnEnter={true}
      >
        <Tab eventKey={eventKey++} title='Confirmed'>
          <ConfirmedShiftsTab />
        </Tab>
        <Tab eventKey={eventKey++} title='In Progress'>
          <InProgressShiftsTab />
        </Tab>
        <Tab
          eventKey={eventKey++}
          title={
            <CompleteTabLabel subTitle='Awaiting Time Approval' isWarning />
          }
        >
          <CompletedShiftsTab />
        </Tab>
        <Tab
          eventKey={eventKey++}
          title={<CompleteTabLabel subTitle='Approved' isSuccess />}
        >
          <ApprovedShiftsTab />
        </Tab>
      </Tabs>
    </div>
  );
};

const ConfirmedShiftsTab = () => {
  return <ShiftAllocatedListView />;
};

const InProgressShiftsTab = () => {
  return <ShiftInProgressListView />;
};

const CompletedShiftsTab = () => {
  return <ShiftCompleteListView />;
};

const ApprovedShiftsTab = () => {
  return <ShiftApprovedListView />;
};

export default ShiftAdmin;
