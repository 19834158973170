import { ComponentProps, ReactNode } from 'react';
import { Label as BSLabel } from 'react-bootstrap';

export const Label = ({
  children,
  bsStyle,
  style,
}: {
  children: ReactNode;
  bsStyle: ComponentProps<typeof BSLabel>['bsStyle'];
  style?: ComponentProps<typeof BSLabel>['style'];
}) => {
  return (
    <BSLabel bsStyle={bsStyle} style={{ fontSize: '12px', ...style }}>
      {children}
    </BSLabel>
  );
};
