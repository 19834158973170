import { useNewShiftConfigQuery } from './shiftQueries';

export const useHirerOptions = () => {
  const query = useNewShiftConfigQuery();
  const data = query.data?.hirerOptions ?? [];
  const options = data.map((customer) => ({
    text: customer.companyName,
    value: customer.hirerId,
  }));
  return {
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    options,
  };
};
