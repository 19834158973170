import { Col, FormGroup } from 'react-bootstrap';
import 'pretty-checkbox/dist/pretty-checkbox.min.css';

export const Checkbox = (props) => {
  const checkbox = <CheckboxOutput {...props} />;
  if (props.columns) {
    return <Col md={props.columns}>{checkbox}</Col>;
  }
  return checkbox;
};

const CheckboxOutput = (props) => {
  let style = {};
  if (props.style) {
    const receivedStyle = props.style;
    style = { ...style, ...receivedStyle };
  }

  const ret = (
    <div className='checkbox-container' style={style}>
      {props.label ? (
        <label className='control-label' htmlFor={props.id}>
          {props.label}
        </label>
      ) : null}
      <div className='pretty p-svg p-smooth p-bigger'>
        <CheckboxField {...props} />
        <div className='state p-primary'>
          <i className='fas fa-check svg'></i>
          <label className=''>{props.title}</label>
        </div>
      </div>
    </div>
  );

  if (props.notInFormGroup) {
    return ret;
  }

  return <FormGroup>{ret}</FormGroup>;
};

const CheckboxField = ({ id, checked, onChange, disabled }) => {
  if (onChange) {
    return (
      <input
        type='checkbox'
        id={id}
        name={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  return (
    <input
      type='checkbox'
      id={id}
      name={id}
      defaultChecked={checked}
      disabled={disabled}
    />
  );
};
