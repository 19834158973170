import { useQuery } from '@tanstack/react-query';
import { AppUtils } from 'pages/Utils/app-utils';
import { SmallMuted } from 'pages/Utils/react-utils';
import { getDriverEndorsements } from 'utils/api';
import { useAlertWithRefreshButton } from 'components/AlertWithRefreshButton';
import DataTable from 'components/DataTable';
import { GettingRecordsMessage } from 'components/GettingRecordsMessage';
import { NoRecordsFoundMessage } from 'components/NoRecordsFoundMessage';

const tableId = 'driver-endorsements-table';

const DriverWorkerEndorsementTable = ({ workerId }) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['driverWorkerEndorsements', workerId],
    () => getDriverEndorsements(workerId)
  );
  const records = data?.records || [];

  const { alert, refreshButton } = useAlertWithRefreshButton(
    `This worker's driving licence endorsements.`,
    isLoading,
    isFetching,
    refetch
  );

  if (isLoading) {
    return <GettingRecordsMessage message='Getting Licence Endorsements...' />;
  }

  if (records.length <= 0) {
    return (
      <NoRecordsFoundMessage
        records={records}
        message='No endorsements found.'
        refreshButton={refreshButton}
      />
    );
  }

  return (
    <>
      {alert}
      <DataTable
        tableid={tableId}
        records={records}
        render={() => {
          if (records.length <= 0) {
            return null;
          }
          return (
            <table
              className='table table-condensed table-striped'
              id={tableId}
              data-order='[[0, "asc"]]'
            >
              <thead>
                <tr>
                  <th>Endorsement Date</th>
                  <th>Code</th>
                  <th>Points</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                {records.map((item, i) => (
                  <Row key={i} record={item} />
                ))}
              </tbody>
            </table>
          );
        }}
      />
    </>
  );
};

const Row = ({ record }) => {
  return (
    <tr>
      <td data-order={record.endorsementDate}>
        {AppUtils.getDateString(record.endorsementDate)}
      </td>
      <td>{record.code}</td>
      <td>{record.points}</td>
      <td data-order={record.createdDateTime}>
        {AppUtils.formatDateTimeUtc(record.createdDateTime)}
        <br />
        <SmallMuted>by {record.createdBy}</SmallMuted>
      </td>
    </tr>
  );
};

export default DriverWorkerEndorsementTable;
