import { useState, useMemo } from 'react';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import { CellProps } from 'react-table';
import { useQueryClient } from '@tanstack/react-query';
import {
  useAddTrailerToShiftMutation,
  useRemoveTrailerFromShiftMutation,
  useShiftTrailersQuery,
  useTrailerOptions,
} from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { useGetTrailerSummaryQuery } from 'hooks/queries/trailerQueries';
import moment from 'moment';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, Icon, WellSmall } from 'pages/Utils/react-utils';
import { ICustomEvent } from 'types/GeneralTypes';
import { ShiftTrailer } from 'types/ShiftTypes';
import { dateFormats, extractErrorMessages, formatDate } from 'utils';
import { ErrorAlert } from 'components/ErrorAlert';
import { OpenShiftInNewTabLink } from 'components/OpenShiftInNewTabLink';
import { Table } from 'components/Table';

const _formId = 'add-trailer-container';

export const ShiftTrailersUi = ({
  shiftId,
  shiftDate,
  shiftTime,
  addBtnSize = 'sm',
  addBtnClassName,
  addBtnContainerClassName,
}: {
  shiftId: string;
  shiftDate: string;
  shiftTime: string;
  addBtnSize?: 'sm' | 'xs';
  addBtnClassName?: string;
  addBtnContainerClassName?: string;
}) => {
  const [isAddShowing, setIsAddShowing] = useState(false);

  const [trailerId, setTrailerId] = useState<string | null>(null);

  const { mutate, isError, error, isLoading } = useAddTrailerToShiftMutation();
  const removeTrailer = useRemoveTrailerFromShiftMutation();

  const queryClient = useQueryClient();

  const handleSaveClick = () => {
    if (!trailerId) {
      AppUtils.displayError('Error', 'Please select a trailer');
      return false;
    }

    mutate(
      {
        shiftId,
        trailerId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ShiftTrailers],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.VehiclePlanForHirer],
          });

          AppUtils.displaySuccess('Success', 'Trailer added');

          // Close the trailer field
          setIsAddShowing(false);
        },
      }
    );
  };

  const handleRemoveClick = (trailerId: string) => {
    if (removeTrailer.isLoading) {
      AppUtils.displayError(
        'Error',
        'Please wait for the current action to complete'
      );
      return;
    }

    removeTrailer.mutate(
      {
        shiftId,
        trailerId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ShiftTrailers],
          });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.VehiclePlanForHirer],
          });

          AppUtils.displaySuccess('Success', 'Trailer removed');
        },
      }
    );
  };

  const checkTimeQuery = useGetTrailerSummaryQuery({
    trailerId,
    shiftDate,
  });
  const { trailerOptions, getTrailerRef } = useTrailerOptions();
  const invalidDateTimes =
    checkTimeQuery.data?.shifts.filter((trailerSummary) => {
      if (trailerSummary.shiftId === shiftId) {
        return false;
      }
      const summaryMoment = moment(trailerSummary.shiftDateTime);
      const thisShiftMoment = moment(shiftDate.split('T')[0] + 'T' + shiftTime);
      const duration = moment.duration(summaryMoment.diff(thisShiftMoment));
      const hours = duration.asHours();
      return hours > -12 && hours < 12;
    }) ?? [];

  return (
    <>
      {isAddShowing ? (
        <>
          <div id={_formId}>
            <Row>
              <Field.InCol
                columns={12}
                name={`TrailerId`}
                label={`New Trailer`}
                options={trailerOptions}
                value={trailerId}
                onChange={(e: ICustomEvent) => {
                  setTrailerId(e.target.value);
                }}
                isSelect
                required
                disabled={checkTimeQuery.isFetching}
              />
            </Row>

            {trailerId ? (
              <>
                {checkTimeQuery.isFetching ? (
                  <WellSmall hasSpinner className='m-b'>
                    Please wait, checking trailer schedule...
                  </WellSmall>
                ) : null}

                {!checkTimeQuery.isFetching &&
                checkTimeQuery.isSuccess &&
                invalidDateTimes.length > 0 ? (
                  <Row>
                    <Col xs={12}>
                      <Alert bsStyle='danger' className='m-b'>
                        <p>
                          <strong>
                            Warning for {getTrailerRef(trailerId)}
                          </strong>
                        </p>
                        <p>
                          This trailer is scheduled for use on another shift,
                          within 12 hours of the start time of this shift. See
                          below for the additional scheduled times:
                        </p>
                        <ul className='m-t'>
                          {invalidDateTimes.map((trailerSummary, i) => {
                            return (
                              <ul key={i}>
                                <span style={{ color: 'red', fontWeight: 900 }}>
                                  {formatDate(
                                    new Date(trailerSummary.shiftDateTime),
                                    dateFormats.niceShortDayWithTime
                                  )}{' '}
                                  |{' '}
                                  <OpenShiftInNewTabLink
                                    shiftId={trailerSummary.shiftId}
                                  />
                                </span>
                              </ul>
                            );
                          })}
                        </ul>
                      </Alert>
                    </Col>
                    <Col xs={12}>
                      <WellSmall>Are you sure you want to continue?</WellSmall>
                    </Col>
                  </Row>
                ) : null}
              </>
            ) : null}
          </div>

          <Row>
            <Col sm={12} style={{ textAlign: 'right' }} className='m-b-sm'>
              <Button
                bsStyle='danger'
                bsSize='sm'
                onClick={() => setIsAddShowing(false)}
                disabled={isLoading || checkTimeQuery.isFetching}
                className='m-r-sm'
              >
                <Icon icon='fa-times' isSpinning={isLoading} /> Cancel
              </Button>
              <Button
                bsStyle='primary'
                bsSize='sm'
                onClick={handleSaveClick}
                disabled={isLoading || checkTimeQuery.isFetching}
              >
                <Icon icon='fa-save' isSpinning={isLoading} /> Add Trailer
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <div className={addBtnContainerClassName}>
          <Button
            bsStyle='primary'
            bsSize={addBtnSize}
            className={addBtnClassName}
            onClick={() => setIsAddShowing(true)}
          >
            <Icon icon='fa-plus' /> Add Trailer
          </Button>
        </div>
      )}

      {isError ? (
        <>
          <hr />
          <ErrorAlert msg={extractErrorMessages(error)} />
        </>
      ) : null}

      {removeTrailer.isError ? (
        <>
          <hr />
          <ErrorAlert msg={extractErrorMessages(removeTrailer.error)} />
        </>
      ) : null}

      {removeTrailer.isLoading ? (
        <>
          <hr />
          <WellSmall hasSpinner>Removing trailer...</WellSmall>
        </>
      ) : null}

      <hr />
      <TrailersTable shiftId={shiftId} handleRemoveClick={handleRemoveClick} />
    </>
  );
};

const TrailersTable = ({
  shiftId,
  handleRemoveClick,
}: {
  shiftId: string;
  handleRemoveClick: (shiftId: string) => void;
}) => {
  const { data, isError, error, isSuccess, isLoading } =
    useShiftTrailersQuery(shiftId);

  const columns = [
    {
      Header: 'Trailer Ref',
      accessor: 'trailerRef',
    },
    {
      accessor: 'actions',
      Cell: ({
        row: {
          original: { trailerId },
        },
      }: CellProps<ShiftTrailer>) => {
        return (
          <Button
            bsStyle='danger'
            bsSize='xs'
            className='pull-right'
            onClick={() => handleRemoveClick(trailerId)}
          >
            <Icon icon='fa-trash' /> Remove
          </Button>
        );
      },
    },
  ].filter(Boolean);

  const records = useMemo(() => data ?? [], [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <ErrorAlert msg={extractErrorMessages(error)} />;
  }

  if (isSuccess && records.length > 0) {
    return <Table columns={columns} records={records} />;
  }

  if (isSuccess && records.length <= 0) {
    return <Alert>No trailers found.</Alert>;
  }

  return null;
};
