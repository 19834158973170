import { Grid } from 'react-bootstrap';
import logo from '../assets/images/RightJobLogoNoPadding.png';
import Auth from './Auth';
import Section from './Section';
import SectionHeader from './SectionHeader';

function AuthSection(props) {
  // Values for each auth type
  const allTypeValues = {
    signin: {
      // Top title
      title: '',
      // Submit button text
      buttonText: 'Sign in',
      // Link text to other auth types
      linkTextSignup: 'Create an account',
      linkTextForgotpass: 'Forgot Password?',
    },
    signup: {
      title: 'Get yourself an account',
      buttonText: 'Sign up',
      linkTextSignin: 'Sign in',
    },
    forgotpass: {
      title: 'Get a new password',
      buttonText: 'Reset password',
    },
    changepass: {
      title: 'Choose a new password',
      buttonText: 'Change password',
    },
  };

  // Ensure we have a valid auth type
  const currentType = allTypeValues[props.type] ? props.type : 'signup';

  // Get values for current auth type
  const typeValues = allTypeValues[currentType];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Grid
        style={{
          maxWidth: '450px',
        }}
      >
        <div
          className='m-t m-b p-lg b-r-md'
          style={{ backgroundColor: '#ddd', border: '1px solid #fff' }}
        >
          <img
            src={logo}
            alt='RightJob Logo'
            className='img-responsive'
            style={{ margin: '0 auto' }}
          />
        </div>

        <SectionHeader
          title={allTypeValues[currentType].title}
          subtitle=''
          size={2}
          spaced={true}
          className='text-center'
        />
        <Auth
          type={currentType}
          typeValues={typeValues}
          afterAuthPath={props.afterAuthPath}
          key={currentType}
        />
      </Grid>
    </Section>
  );
}

export default AuthSection;
