import { Col, Panel, Row } from 'react-bootstrap';
import { Icon } from 'pages/Utils/react-utils';
import { ShiftNoteType } from 'types/ShiftTypes';
import { ShiftNotesUi } from 'components/ShiftNotes';

export const Notes = ({
  shiftId,
  type,
}: {
  shiftId: string;
  type: ShiftNoteType;
}) => {
  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col sm={12}>
            <Icon icon='fa-clipboard-list' />{' '}
            {type === 'Hirer' ? 'Customer' : type} Notes
          </Col>
        </Row>
      </Panel.Heading>
      <Panel.Body>
        <ShiftNotesUi
          shiftId={shiftId}
          type={type}
          addBtnSize='xs'
          addBtnContainerClassName='text-right'
        />
      </Panel.Body>
    </Panel>
  );
};
