import { Component } from 'react';
import { Alert, Col, Panel, Row, Tabs, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import { useVehicleOptions } from 'hooks/queries';
import { useModal } from 'hooks/useModal';
import $ from 'jquery';
import {
  getWorkerType,
  isHgvShiftConfig,
} from 'pages/ShiftConfig/ShiftConfigUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { AlertWithIcon, Icon, WellSmall } from 'pages/Utils/react-utils';
import { ShiftPaymentType } from 'types/ShiftConfigTypes';
import { getShiftById } from 'utils/api';
import configureSubscriptions from 'utils/configureSubscriptions';
import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { LinkedShiftsUi } from 'components/LinkedShifts';
import { ShiftSegments } from 'components/ShiftSegments';
import { AddSiteAssessmentButton } from '../../Agency/SiteAssessment/AddSiteAssessmentButton';
import { CancelShiftButton } from '../Buttons/CancelShiftButton';
import { ChangeShiftTimeButton } from '../Buttons/ChangeShiftTimeButton';
import { CompleteShiftButton } from '../Buttons/CompleteShiftButton';
import { ShiftContextProvider, useShiftContext } from '../ShiftContext';
import { ShiftUtils, arePaymentAdjustmentsAllowed } from '../ShiftUtils';
import { AcceptModal } from './AcceptModal';
import { ApplicantListView } from './ApplicantListView';
import { ChangeVehicleModal } from './ChangeVehicleModal';
import { ShiftFilesTabContent } from './Files';
import { ShiftFinancialInfoTabContent } from './Financial/ShiftFinancialInfoTabContent';
import { ShiftNotesTabContent } from './Notes';
import { PaymentAdjustments } from './PaymentAdjustments';
import { Trailers } from './Trailers';

export const ShiftEdit = ({ shiftId, setDisplayShiftId }) => {
  const params = useParams();
  const useShiftId = shiftId || params?.shiftId;
  return (
    <ShiftContextProvider
      shiftId={useShiftId}
      setDisplayShiftId={setDisplayShiftId}
    >
      <ShiftEditMain />
    </ShiftContextProvider>
  );
};

export const ShiftEditMain = () => {
  const { shiftId, setDisplayShiftId, setShift } = useShiftContext();
  return (
    <Main
      shiftId={shiftId}
      setDisplayShiftId={setDisplayShiftId}
      setShift={setShift}
    />
  );
};

const _subscribeToEvents = [
  ShiftUtils.Events.WorkerAllocated,
  ShiftUtils.Events.ShiftCancelled,
  ShiftUtils.Events.ShiftCompleted,
  ShiftUtils.Events.ShiftTimeChanged,
  ShiftUtils.Events.ShiftFinancialsReset,
  ShiftUtils.Events.ShiftVehicleChanged,
  ShiftUtils.Events.AdjustmentAdded,
  ShiftUtils.Events.AdjustmentCleared,
  AppUtils.Events.SiteAssessmentSaved,
  ShiftUtils.Events.ShiftSegmentSaved,
  ShiftUtils.Events.ShiftSegmentDeleted,
];

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isGettingRecord: false,
      record: null,
      shiftFinance: null,
      shiftConfig: null,
      shiftPayment: null,
      shiftCharge: null,
      shiftCost: null,
      applicants: [],
      errors: [],
      acceptRecord: null,
      cleanupSubscriptions: null,
    };

    this.subscriber = this.subscriber.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleAcceptModalShowClick =
      this.handleAcceptModalShowClick.bind(this);
    this.handleAcceptModalCloseClick =
      this.handleAcceptModalCloseClick.bind(this);
  }

  async componentDidMount() {
    await this.getShift();

    const _me = this;
    const { cleanup } = configureSubscriptions(
      _subscribeToEvents,
      _me.subscriber
    );
    this.setState({ cleanupSubscriptions: cleanup });
  }

  subscriber(event, data) {
    switch (event) {
      case ShiftUtils.Events.ShiftTimeChanged:
        this.setState({
          record: data.record,
        });
        return;

      case ShiftUtils.Events.ShiftFinancialsReset:
      case ShiftUtils.Events.AdjustmentAdded:
      case ShiftUtils.Events.AdjustmentCleared:
        this.setState({
          record: data.shift,
          shiftPayment: data.shiftPayment,
          shiftCharge: data.shiftCharge,
          shiftCost: data.shiftCost,
        });
        return;

      default:
        this.getShift();
        return;
    }
  }

  componentWillUnmount() {
    const { cleanupSubscriptions } = this.state;
    if (cleanupSubscriptions) {
      cleanupSubscriptions();
    }

    if (this.props.shiftId) {
      $(window).off('popstate');
    }
  }

  async getShift() {
    const _me = this;
    _me.setState({ isGettingRecord: true, errors: [] });

    try {
      const data = await getShiftById(this.props.shiftId);
      AppUtils.handleAjaxDone(data, () => {
        if (data.record) {
          _me.setState({
            record: data.record,
            shiftFinance: data.shiftFinance,
            shiftConfig: data.shiftConfig,
            shiftPayment: data.shiftPayment,
            shiftCharge: data.shiftCharge,
            shiftCost: data.shiftCost,
            applicants: data.applicants,
          });
          this.props.setShift(data.record);
        } else {
          throw new Error('Could not get record');
        }
      });
    } catch (error) {
      AppUtils.displayErrors([error.message]);
      _me.setState({ errors: [error.message] });
    }

    _me.setState({ isGettingRecord: false });
  }

  handleAcceptModalShowClick(record) {
    this.setState({ acceptRecord: record });
  }

  handleAcceptModalCloseClick() {
    this.setState({ acceptRecord: null });
  }

  handleTabSelect(tabToMakeActive) {
    this.setState({ activeTab: tabToMakeActive });
  }

  render() {
    if (this.state.errors.length > 0) {
      return <Errors {...this.state} />;
    }

    if (this.state.isGettingRecord && !this.state.record) {
      return <GettingRecordMsg {...this.state} />;
    }

    if (!this.state.record) {
      return null;
    }

    return (
      <>
        <AcceptModal
          record={this.state.acceptRecord}
          showModal={this.state.acceptRecord ? true : false}
          handleAcceptModalCloseClick={this.handleAcceptModalCloseClick}
        />
        <Body
          {...this.state}
          {...this.props}
          handleAcceptModalShowClick={this.handleAcceptModalShowClick}
          handleTabSelect={this.handleTabSelect}
        />
      </>
    );
  }
}

const GettingRecordMsg = ({ isGettingRecord }) => {
  if (!isGettingRecord) {
    return null;
  }
  return (
    <WellSmall>
      <i className={AppUtils.spinIconClass} /> Please wait while the shift is
      retrieved...
    </WellSmall>
  );
};

const getDateTimeFormatted = (dt, isShiftTimeConfirmed) =>
  isShiftTimeConfirmed
    ? AppUtils.formatDateTimeNonUtc(dt, null, 'ddd DD MMM YYYY HH:mm')
    : AppUtils.formatDateTimeNonUtc(dt, null, 'ddd DD MMM YYYY') +
      ' - Time: TBC';

const Body = (props) => {
  let eventKey = 1;
  const shiftDateTime = getDateTimeFormatted(
    props.record.shiftDateTime,
    props.record.isShiftTimeConfirmed
  );

  const tabs = (
    <div className='tabs-container'>
      <Tabs
        activeKey={props.activeTab}
        animation={false}
        onSelect={props.handleTabSelect}
        id='shift-tabs'
      >
        <Tab eventKey={eventKey++} title='Shift Details'>
          <ShiftDetailsTabContent {...props} />
        </Tab>
        <Tab eventKey={eventKey++} title='Notes' unmountOnExit>
          <ShiftNotesTabContent />
        </Tab>
        <Tab eventKey={eventKey++} title='Documents/Files' unmountOnExit>
          <ShiftFilesTabContent {...props} />
        </Tab>
        <Tab eventKey={eventKey++} title='Payment and Charge' unmountOnExit>
          <ShiftFinancialInfoTabContent {...props} />
        </Tab>
      </Tabs>
    </div>
  );

  return (
    <>
      <Header {...props} shiftDateTime={shiftDateTime} />
      <CancelPanel {...props} />
      <GroupPanel record={props.record} />

      {props.record.shiftGroupId ? (
        <Row>
          <Col sm={9}>{tabs}</Col>
          <Col sm={3}>
            <Panel>
              <Panel.Body>
                <LinkedShiftsUi shiftId={props.record.shiftId} />
              </Panel.Body>
            </Panel>
          </Col>
        </Row>
      ) : (
        <>{tabs}</>
      )}
    </>
  );
};

const Header = (props) => {
  const { record, shiftDateTime } = props;
  const history = useHistory();

  return (
    <Row
      className='border-bottom white-bg dashboard-header m-b'
      style={{ marginTop: '-15px' }}
    >
      <Col md={12}>
        <Button
          className='mb'
          onClick={() => {
            if (!props.setDisplayShiftId && history.length === 1) {
              window.close();
              return;
            }

            props.setDisplayShiftId
              ? props.setDisplayShiftId(null)
              : history.goBack();
          }}
        >
          <i className='fa fa-arrow-left' /> Back
        </Button>

        <h2>
          {shiftDateTime}{' '}
          <a
            href={`/Shift/Edit/${record.shiftId}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Icon
              icon='fa-link'
              tooltip='Open in new tab'
              style={{ fontSize: '16px' }}
              onClick={() => {
                AppUtils.destroyTooltips();
              }}
            />
          </a>
        </h2>
        <span className='text-primary'>{record.name}</span>
      </Col>
    </Row>
  );
};

const CancelPanel = ({ record }) => {
  if (record.status !== ShiftUtils.Status.Cancelled) {
    return null;
  }

  return (
    <AlertWithIcon bsStyle='danger' icon='fa-times-octagon'>
      <div>
        This shift was cancelled by {record.cancelledBy} on{' '}
        {AppUtils.formatDateTimeUtc(record.cancelledDateTime)}.
        <br />
        <strong>Cancellation Comments</strong>: {record.cancellationComments}
      </div>
    </AlertWithIcon>
  );
};

const GroupPanel = ({ record }) => {
  if (!record.shiftGroupId) {
    return null;
  }

  return (
    <AlertWithIcon
      bsStyle={record.isShiftGroupPrimary ? 'info' : 'warning'}
      icon='fa-info'
    >
      <div>
        This shift is linked with other shifts. <strong>Please note</strong>:
        This is {!record.isShiftGroupPrimary ? 'not ' : ''}the primary shift in
        the group.
      </div>
    </AlertWithIcon>
  );
};

const ShiftDetailsTabContent = (props) => {
  const history = useHistory();
  return (
    <Row>
      <Col md={6}>
        <Panel>
          <Panel.Heading>
            <Row>
              <Col sm={4}>Shift Details</Col>
              <Col sm={8} className='text-right'>
                <CompleteShiftButton status={props.record.status} />
                <CancelShiftButton record={props.record} />
              </Col>
            </Row>
          </Panel.Heading>
          <Panel.Body>
            <ShiftDetails {...props} />
          </Panel.Body>
        </Panel>
        <Panel>
          <Panel.Heading>
            <Row>
              <Col sm={4}>Other</Col>
              <Col sm={8} style={{ textAlign: 'right' }}>
                <Button
                  bsSize='xs'
                  onClick={() => {
                    history.push(
                      `/ShiftConfig/Edit/${props.shiftConfig.shiftConfigId}`
                    );
                  }}
                >
                  <Icon icon='fa-cog' /> View Shift Config
                </Button>
              </Col>
            </Row>
          </Panel.Heading>
          <Panel.Body>
            <ShiftDetailsOther {...props} />
          </Panel.Body>
        </Panel>
      </Col>
      <Col md={6}>
        <ShiftTimingInfo {...props} />
        <Applicants {...props} />
        <ShiftSegments
          shiftId={props.record.shiftId}
          hirerId={props.record.hirerId}
          status={props.record.status}
        />
        <Trailers
          shiftId={props.record.shiftId}
          shiftDate={props.record.shiftDate}
          shiftTime={props.record.shiftTime}
        />
        <PaymentAdjustments
          areNewAdjustmentsAllowed={arePaymentAdjustmentsAllowed(
            props.record.status
          )}
        />
      </Col>
    </Row>
  );
};

const getShiftLocation = (shiftConfig) =>
  [
    shiftConfig.siteAddressLine1,
    shiftConfig.siteAddressLine2,
    shiftConfig.siteAddressLine3,
    shiftConfig.siteTown,
    shiftConfig.siteCounty,
    shiftConfig.sitePostcode,
  ]
    .filter((m) => m !== null)
    .map((m, i) => <div key={i}>{m}</div>);

const canVehicleBeChanged = (record) => {
  return (
    ShiftUtils.isOpen(record) ||
    ShiftUtils.isAllocated(record) ||
    ShiftUtils.isPreAllocated(record) ||
    ShiftUtils.isInProgress(record) ||
    ShiftUtils.isComplete(record) ||
    ShiftUtils.isApproved(record)
  );
};

const ShiftDetails = (props) => {
  const { record, shiftConfig } = props;
  const { getVehicleReg } = useVehicleOptions();

  const {
    isModalOpen: isChangeVehicleModalOpen,
    handleModalOpen: handleChangeVehicleModalOpen,
    handleModalClose: handleChangeVehicleModalClose,
  } = useModal();

  const handleChangeVehicleClick = () => {
    if (!canVehicleBeChanged(record)) {
      AppUtils.displayError(
        'Error',
        'Vehicle can only be changed for Open, Pre-Allocated or Allocated shifts.',
        false,
        'vehicle-change-error'
      );
      return;
    }

    handleChangeVehicleModalOpen();
  };

  return (
    <>
      <ShiftRow label='Status' value={<StatusLabel record={record} />} />
      <ShiftRow
        label='Allocated To'
        value={
          record.workerId ? (
            <Link to={`/Worker/Profile/${record.workerId}`} target='_blank'>
              {record.workerFirstName} {record.workerSurname}
            </Link>
          ) : null
        }
      />
      {record.isPreAllocated ? null : (
        <ShiftRow
          label='Applicant Type'
          value={<ApplicantTypeLabel record={record} />}
        />
      )}
      <ShiftRow label='Customer' value={shiftConfig.companyName} />
      <ShiftRow
        label='Start'
        value={
          <div>
            {props.shiftDateTime}
            <ChangeShiftTimeButton record={record} />
          </div>
        }
      />
      {record.shiftDateTimeOriginal ? (
        <ShiftRow
          label='Original Start'
          value={getDateTimeFormatted(props.record.shiftDateTimeOriginal)}
        />
      ) : null}
      <ShiftRow label='Location' value={getShiftLocation(shiftConfig)} />

      {record.vehicleId || shiftConfig.isVehicleRequired ? (
        <>
          <ShiftRow
            label='Vehicle'
            value={
              <>
                <div style={{ marginBottom: '10px' }}>
                  {getVehicleReg(record.vehicleId)}
                  <Button
                    bsStyle='primary'
                    onClick={handleChangeVehicleClick}
                    bsSize='xs'
                    className='m-r-xs pull-right'
                  >
                    <Icon icon='fa-truck' /> Change...
                  </Button>
                </div>
                {record.vehicleChangedWhileInProgress ? (
                  <Alert bsStyle='warning' style={{ padding: '3px 5px' }}>
                    Vehicle changed while shift was in progress
                  </Alert>
                ) : null}
              </>
            }
          />
        </>
      ) : null}

      {isChangeVehicleModalOpen ? (
        <ChangeVehicleModal
          existingVehicleId={record.vehicleId}
          isModalOpen={isChangeVehicleModalOpen}
          handleModalClose={handleChangeVehicleModalClose}
          shift={record}
        />
      ) : null}
      <CompletedAtRow {...props} />
      <ApprovedAtRow {...props} />
      <PaymentAuthorisedAtRow {...props} />
    </>
  );
};
const ShiftDetailsOther = (props) => {
  const { record, shiftConfig } = props;

  return (
    <>
      <ShiftRow
        label='Site Assessment Required'
        value={shiftConfig.isSiteAssessmentRequired ? 'Yes' : 'No'}
      />
      <RateInfo shiftConfig={shiftConfig} shiftFinance={props.shiftFinance} />
      <ShiftInfo shiftConfig={shiftConfig} />
      <ShiftRow
        label='Posted'
        value={AppUtils.formatDateTimeUtc(record.createdDateTime)}
      />
    </>
  );
};

const warningStatuses = [
  ShiftUtils.Status.Open,
  ShiftUtils.Status.PreAllocated,
];
const primaryStatuses = [
  ShiftUtils.Status.Allocated,
  ShiftUtils.Status.InProgress,
];
const successStatuses = [
  ShiftUtils.Status.Complete,
  ShiftUtils.Status.Approved,
  ShiftUtils.Status.PaymentAuthorised,
];

const getStatusDisplayVal = (status) => {
  if (status === ShiftUtils.Status.PaymentAuthorised) {
    return 'Payment Authorised';
  }

  return status;
};

const StatusLabel = ({ record }) => {
  const { status } = record;
  const labelClass = warningStatuses.includes(status)
    ? 'warning'
    : primaryStatuses.includes(status)
    ? 'primary'
    : successStatuses.includes(status)
    ? 'success'
    : status === ShiftUtils.Status.Cancelled
    ? 'danger'
    : 'default';

  return (
    <Label bsStyle={labelClass} bsSize='large' style={{ fontSize: '12px' }}>
      {getStatusDisplayVal(status)}
    </Label>
  );
};

const ApplicantTypeLabel = ({ record }) => {
  if (record.isPreAllocated) {
    return null;
  }
  const config = ShiftUtils.ApplicantTypeLabel(record.isPublic);
  return <Label bsStyle={config[1]}>{config[0]}</Label>;
};

const CompletedAtRow = (props) => {
  if (!props.record.completedDateTime) {
    return null;
  }
  return (
    <>
      <ShiftRow
        label='Completed'
        value={AppUtils.formatDateTimeUtc(props.record.completedDateTime)}
      />
      <ShiftRow label='Completed By' value={props.record.completedBy} />
    </>
  );
};

const ApprovedAtRow = (props) => {
  if (!props.record.approvedDateTime) {
    return null;
  }
  return (
    <>
      <ShiftRow
        label='Approved'
        value={AppUtils.formatDateTimeUtc(props.record.approvedDateTime)}
      />
      <ShiftRow label='Approved by' value={props.record.approvedBy} />
    </>
  );
};

const PaymentAuthorisedAtRow = (props) => {
  if (!props.record.paymentAuthorisedDateTime) {
    return null;
  }
  return (
    <>
      <ShiftRow
        label='Payment Authorised'
        value={AppUtils.formatDateTimeUtc(
          props.record.paymentAuthorisedDateTime
        )}
      />
      <ShiftRow
        label='Payment Authorised By'
        value={props.record.paymentAuthorisedBy}
      />
    </>
  );
};

const RateInfo = ({ shiftConfig, shiftFinance }) => {
  const fmtNum = AppUtils.formatNumber;
  if (shiftConfig.shiftPaymentType === ShiftPaymentType.Fixed) {
    return (
      <>
        <ShiftRow
          label='Shift Length'
          value={`${shiftFinance.fixedShiftLengthHours} hours`}
        />
        <ShiftRow
          label='Pay (Agency)'
          value={`£${fmtNum(shiftFinance.dayRateFixed)} fixed`}
        />
        <ShiftRow
          label='Pay (PAYE)'
          value={`£${fmtNum(shiftFinance.dayRateFixedPayeWithHoliday)} fixed`}
        />
      </>
    );
  }

  return (
    <>
      <ShiftRow
        label='Typical Shift Length'
        value={
          shiftConfig.typicalShiftLengthHours
            ? `${shiftConfig.typicalShiftLengthHours} hours`
            : ''
        }
      />
      <ShiftRow
        label='Days'
        value={`£${fmtNum(shiftFinance.dayRateHourly)}/hr`}
      />
      <ShiftRow
        label='Nights'
        value={`£${fmtNum(shiftFinance.nightRateHourly)}/hr`}
        hideIfTestFails
        condition={shiftFinance.nightRateHourly}
      />
      <ShiftRow
        label='Saturday Days'
        value={`£${fmtNum(shiftFinance.saturdayDayRateHourly)}/hr`}
        hideIfTestFails
        condition={shiftFinance.saturdayDayRateHourly}
      />
      <ShiftRow
        label='Saturday Nights'
        value={`£${fmtNum(shiftFinance.saturdayNightRateHourly)}/hr`}
        hideIfTestFails
        condition={shiftFinance.saturdayNightRateHourly}
      />
      <ShiftRow
        label='Sunday Days'
        value={`£${fmtNum(shiftFinance.sundayDayRateHourly)}/hr`}
        hideIfTestFails
        condition={shiftFinance.sundayDayRateHourly}
      />
      <ShiftRow
        label='Sunday Nights'
        value={`£${fmtNum(shiftFinance.sundayNightRateHourly)}/hr`}
        hideIfTestFails
        condition={shiftFinance.sundayNightRateHourly}
      />
      <ShiftRow
        label='Night Out'
        value={`£${fmtNum(shiftFinance.overnightAllowance)}`}
        hideIfTestFails
        condition={shiftFinance.overnightAllowance}
      />
      <ShiftRow
        label='Is Overtime Paid?'
        value={shiftConfig.isOvertimePaid ? 'Yes' : 'No'}
      />
      <ShiftRow
        label='Overtime Paid After'
        value={`${shiftConfig.overtimePaidAfterHours}h ${shiftConfig.overtimePaidAfterMinutes}m`}
        hideIfTestFails
        condition={shiftConfig.isOvertimePaid}
      />
    </>
  );
};

const ShiftInfo = ({ shiftConfig }) => {
  if (!isHgvShiftConfig(shiftConfig)) {
    return null;
  }

  return (
    <>
      <ShiftRow
        label='Licence Required'
        value={shiftConfig.drivingLicenceTypeRequired}
      />
      <ShiftRow label='Run' value={shiftConfig.runType} />
      <ShiftRow label='Vehicle' value={shiftConfig.vehicleType} />
      <ShiftRow label='Trailer' value={shiftConfig.trailerType} />
      <ShiftRow label='Load' value={shiftConfig.loadType} />
    </>
  );
};

const ShiftRow = ({ label, value, hideIfTestFails, condition }) => {
  if (hideIfTestFails && !condition) {
    return null;
  }
  return (
    <>
      <Row>
        <Col md={4} className='text-right text-muted'>
          <small>{label}</small>
        </Col>
        <Col md={8}>{value}</Col>
      </Row>
      <hr />
    </>
  );
};

const Errors = ({ errors }) => {
  if (errors.length <= 0) {
    return null;
  }
  return (
    <Alert bsStyle='danger'>
      The following errors occurred:
      <ul>
        {errors.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>
    </Alert>
  );
};

const Applicants = (props) => {
  return (
    <Panel>
      <Panel.Heading>
        <Row>
          <Col sm={8}>
            <Icon icon='fa-clipboard-list' /> Applicants
          </Col>
          <Col sm={4}>
            {props.applicants.some((m) => m.workerRequiresSiteAssessment) ? (
              <AddSiteAssessmentButton hirerId={props.shiftConfig.hirerId} />
            ) : null}
          </Col>
        </Row>
      </Panel.Heading>
      <Panel.Body>
        <StatusOpenAlert {...props} />
        <ErrorBoundary>
          <StatusAllocatedAlert {...props} />
        </ErrorBoundary>
        <ApplicantListView {...props} />
      </Panel.Body>
    </Panel>
  );
};

const StatusOpenAlert = ({ record, shiftConfig }) => {
  if (record.status !== ShiftUtils.Status.Open) {
    return null;
  }
  return (
    <AlertWithIcon bsStyle='info' icon='fa-2x fa-info-square'>
      This Shift has not been allocated to a {getWorkerType(shiftConfig)} yet.
      Please review the applicants and accept by using the associated `Accept`
      button.
    </AlertWithIcon>
  );
};

const StatusAllocatedAlert = ({ record }) => {
  const { workerId } = record;
  if (!workerId) {
    return null;
  }
  const link = (
    <Link to={`/Worker/Profile/${workerId}`}>
      {record.workerFirstName} {record.workerSurname}
    </Link>
  );
  const msg = record.isPreAllocated ? (
    <>
      This Shift was allocated directly to {link}
      {record.status === ShiftUtils.Status.PreAllocated
        ? '. It has not yet been accepted.'
        : `. It was accepted on ${AppUtils.formatDateTimeUtc(
            record.allocatedDateTime
          )}.`}
    </>
  ) : (
    `This Shift is allocated to ${record.workerFirstName} ${record.workerSurname}`
  );
  return (
    <AlertWithIcon
      bsStyle={
        record.status === ShiftUtils.Status.PreAllocated ? 'warning' : 'success'
      }
      icon='fa-2x fa-user-check'
    >
      {msg}
    </AlertWithIcon>
  );
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

const isCompleteOrApprovedOrPaymemtAuthorised = (record) =>
  ShiftUtils.isComplete(record) ||
  ShiftUtils.isApproved(record) ||
  ShiftUtils.isPaymentAuthorised(record);

const getDt = (date, time) =>
  AppUtils.formatDateTimeNonUtc(AppUtils.concatDateAndTime(date, time));

const ShiftTimingInfo = (props) => {
  const { record } = props;
  if (!isCompleteOrApprovedOrPaymemtAuthorised(record)) {
    return null;
  }
  const isComplete = ShiftUtils.isComplete(record);
  const isApproved = record.approvedDateTime ? true : false;

  return (
    <Panel>
      <Panel.Heading>
        <Icon icon='fa-clock' /> Shift Date & Time
      </Panel.Heading>
      <Panel.Body>
        <WellSmall>Times Submitted by Worker</WellSmall>
        <ShiftRow
          label='Start'
          value={getDt(
            record.shiftDateStartActualWorker,
            record.shiftTimeStartActualWorker
          )}
        />
        <ShiftRow
          label='End'
          value={getDt(
            record.shiftDateEndActualWorker,
            record.shiftTimeEndActualWorker
          )}
        />

        <HirerTimes {...props} />

        <ShiftRow
          label='Status'
          value={
            <Label bsStyle={isApproved ? 'success' : 'warning'}>
              {isComplete ? 'Awaiting Approval' : isApproved ? 'Approved' : ''}
            </Label>
          }
        />
      </Panel.Body>
    </Panel>
  );
};

const areHirerTimesEmpty = (record) => {
  return (
    !record.shiftDateStartActualConfirmed &&
    !record.shiftTimeStartActualConfirmed &&
    !record.shiftDateEndActualConfirmed &&
    !record.shiftTimeEndActualConfirmed
  );
};

const areDatesOrTimesDifferent = (record) => {
  if (
    record.shiftDateStartActualWorker !== record.shiftDateStartActualConfirmed
  ) {
    return true;
  }
  if (
    record.shiftTimeStartActualWorker !== record.shiftTimeStartActualConfirmed
  ) {
    return true;
  }
  if (record.shiftDateEndActualWorker !== record.shiftDateEndActualConfirmed) {
    return true;
  }
  if (record.shiftTimeEndActualWorker !== record.shiftTimeEndActualConfirmed) {
    return true;
  }
  return false;
};

const HirerTimes = (props) => {
  const { record } = props;
  if (areHirerTimesEmpty(record)) {
    return null;
  }
  if (!areDatesOrTimesDifferent(record)) {
    return null;
  }
  return (
    <>
      <WellSmall>
        Times {ShiftUtils.isApproved(record) ? 'Approved' : 'Added'}
      </WellSmall>
      <ShiftRow
        label='Start'
        value={getDt(
          record.shiftDateStartActualConfirmed,
          record.shiftTimeStartActualConfirmed
        )}
      />
      <ShiftRow
        label='End'
        value={getDt(
          record.shiftDateEndActualConfirmed,
          record.shiftTimeEndActualConfirmed
        )}
      />
    </>
  );
};
