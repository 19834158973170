import { Row } from 'react-bootstrap';
import { FormLabel } from 'components';
import { AppUtils } from 'pages/Utils/app-utils';
import { Field, InputField } from 'pages/Utils/react-utils';
import { useShiftConfigContext } from './ShiftConfigContextProvider';

export const ChargeRatesTab = () => {
  return (
    <div className='shift-finance'>
      <ChargeRatesTabInner />
    </div>
  );
};

const ChargeRatesTabInner = () => {
  const { shiftFinance, disabled } = useShiftConfigContext();
  return (
    <>
      <Row>
        <Field.InCol
          columns={6}
          name='ChargeType'
          label='Charge Type'
          value={shiftFinance.chargeType?.toString() ?? ''}
          isSelect
          options={[
            { value: 'ChargeRateAndMileage', text: 'Charge Rate And Mileage' },
            { value: 'Margin', text: 'Margin' },
          ]}
          disabled={disabled}
        />
      </Row>

      <h2>Charge Rates Config</h2>
      <hr />

      <table className='table pay-rate-table'>
        <tbody>
          <RatesTopRow />
          <RateRow label='Standard' type='' />
          <RateRow label='Saturday' type='Saturday' />
          <RateRow label='Sunday' type='Sunday' />
        </tbody>
      </table>

      <hr />

      <Row>
        <Field.InCol
          columns={6}
          name='MileageQtyAllowance'
          label='Mileage Allowance'
          value={shiftFinance.mileageQtyAllowance?.toString() ?? ''}
          maxLength={11}
          addOnPost='Miles'
          isNumber
          disabled={disabled}
        />
        <Field.InCol
          columns={6}
          name='MileageChargePerMileAfterAllowance'
          label='Charge Per Mile After Allowance'
          addOnPre='£'
          addOnPost='Per Mile'
          value={
            shiftFinance.mileageChargePerMileAfterAllowance?.toString() ?? ''
          }
          maxLength={6}
          isNumber
          disabled={disabled}
        />
      </Row>

      <h2>Margin Configs</h2>
      <hr />
      <Row>
        <MarginField type='Day' />
        <MarginField type='Night' />
        <MarginField type='Fixed' />
      </Row>
    </>
  );
};

const RatesTopRow = () => {
  return (
    <tr>
      <td />
      <td className='text-center'>
        <FormLabel label='Day Info' />
      </td>
      <td className='text-center'>
        <FormLabel label='Night Info' />
      </td>
    </tr>
  );
};

const RateRow = ({
  label,
  type,
}: {
  label: string;
  type: '' | 'Saturday' | 'Sunday';
}) => {
  const dayRateString = `chargeAmountFixed${type}Day`;
  const nightRateString = `chargeAmountFixed${type}Night`;
  const dayRateOvertimeString = `chargeRateOvertimePerHour${type}Day`;
  const nightRateOvertimeString = `chargeRateOvertimePerHour${type}Night`;

  return (
    <>
      <tr>
        <td className='firstCol'>
          <FormLabel label={`${label}`} />
        </td>
        <td>
          <RateField fieldName={dayRateString} />
        </td>
        <td>
          <RateField fieldName={nightRateString} />
        </td>
      </tr>
      <tr className='no-border'>
        <td className='firstCol'>
          <FormLabel label={`${label} Overtime Rate`} />
        </td>
        <td>
          <RateField fieldName={dayRateOvertimeString} addOnPost='Per Hour' />
        </td>
        <td>
          <RateField fieldName={nightRateOvertimeString} addOnPost='Per Hour' />
        </td>
      </tr>
    </>
  );
};

const RateField = ({
  fieldName,
  addOnPost,
}: {
  fieldName: string;
  addOnPost?: string;
}) => {
  const { shiftFinance, disabled } = useShiftConfigContext();
  return (
    <div>
      <InputField
        name={fieldName}
        value={AppUtils.formatNumber(
          shiftFinance[AppUtils.toCamelCase(fieldName)]
        )}
        maxLength='6'
        isNumber
        noLabel
        addOnPre='£'
        addOnPost={addOnPost ?? 'Fixed Amount'}
        disabled={disabled}
      />
    </div>
  );
};

const MarginField = ({ type }: { type: 'Day' | 'Night' | 'Fixed' }) => {
  const { shiftFinance, disabled, isFixedShift, isHourlyShift } =
    useShiftConfigContext();

  if (isFixedShift && type !== 'Fixed') {
    return null;
  }

  if (!isFixedShift && type === 'Fixed') {
    return null;
  }

  const tooltipComments =
    type === 'Fixed'
      ? `For Fixed Shifts, the ${type} Margin is a fixed amount e.g. if the fixed pay amout is £300 and the margin is set to £150 then the charge will be the fixed shift pay amout + the margin = £450.`
      : `For Hourly Shifts, the ${type} Margin value is applied per hour for portions of the shift which fall into the ${type.toLowerCase()} time hours e.g. if the ${type.toLowerCase()}  pay rate is £15 per hour and the margin is set to £10 then the charge rate will be the ${type.toLowerCase()} pay rate + the margin = £25 per hour.`;

  return (
    <Field.InCol
      columns={6}
      name={`Margin${type}`}
      label={`Margin - ${type}`}
      value={AppUtils.formatNumber(shiftFinance[`margin${type}`])}
      maxLength='9'
      isNumber
      addOnPre='£'
      addOnPost={
        isHourlyShift ? `Per Hour` : isFixedShift ? 'Fixed Amount' : null
      }
      disabled={disabled}
      tooltip={tooltipComments}
    />
  );
};
