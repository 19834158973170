import { useEffect } from 'react';
import configureSubscriptions from 'utils/configureSubscriptions';

const useSubscription = (events: string[], fn: () => void) => {
  useEffect(() => {
    const { cleanup } = configureSubscriptions(events, fn);
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { events };
};

export default useSubscription;
