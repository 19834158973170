import { Component } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import StarRatingComponent from 'react-star-rating-component';
import { ShiftUtils } from 'pages/Shift/ShiftUtils';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import PubSub from 'pubsub-js';
import { addShiftFeedback } from 'utils/api';

export class AddFeedbackButton extends Component {
  constructor(props) {
    console.log('AddFeedbackToSelectedButton.constructor');
    super(props);
    this.state = {
      isSaving: false,
      isModalShowing: false,
      score: null,
    };

    this.handleShowModalClick = this.handleShowModalClick.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.onStarClick = this.onStarClick.bind(this);
  }

  handleShowModalClick() {
    if (this.props.recordIdsSelected.length <= 0) {
      AppUtils.displayError(
        'Error',
        'No Shifts selected. Please select some and try again.'
      );
    } else {
      this.setState({ isModalShowing: true });
    }
  }

  handleCloseModalClick() {
    this.setState({ isModalShowing: false });
  }

  onStarClick(nextValue) {
    console.log(`onStarClick: `, nextValue);
    this.setState({ score: nextValue });
  }

  async handleSubmitClick() {
    const score = this.state.score;
    if (!score || score < 0 || score > 5) {
      AppUtils.displayError(
        'Error',
        'Please choose a Feedback Score and try again'
      );
      return;
    }

    let model = {
      shiftIds: this.props.recordIdsSelected,
      score,
    };

    this.setState({ isSaving: true });

    const data = await addShiftFeedback(model);
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Feedback Saved');
      this.handleCloseModalClick();
      PubSub.publish(ShiftUtils.Events.FeedbackAdded, data);
    });
    this.setState({ isSaving: false });

    // $.ajax({
    //   type: "POST",
    //   url: `${AppSettings.baseUrl}Shift/AddFeedback`,
    //   data: JSON.stringify(model),
    // })
    //   .done(function (data) {
    //     AppUtils.handleAjaxDone(data, () => {
    //       AppUtils.displaySuccess("Done", "Feedback Saved");
    //       this.handleCloseModalClick();
    //       PubSub.publish(ShiftUtils.Events.FeedbackAdded, data);
    //     });
    //   })
    //   .fail(function (xhr) {
    //     AppUtils.handleXhrFail(xhr);
    //   })
    //   .always(function () {
    //     this.setState({ isSaving: false });
    //   });
  }

  onExited() {
    AppUtils.destroyTooltips();
  }

  render() {
    console.log('AddFeedbackToSelectedButton.render');
    const recordsSelectedCount = this.props.recordIdsSelected.length;
    const plural = recordsSelectedCount > 1 ? 's' : '';
    const btnText = `Add Feedback ${
      recordsSelectedCount === 0
        ? ''
        : `to ${recordsSelectedCount} Shift${plural}...`
    }`;
    return (
      <>
        <Button
          bsStyle='success'
          bsSize='sm'
          onClick={this.handleShowModalClick}
          disabled={recordsSelectedCount <= 0}
        >
          <Icon icon='fa-star' /> {btnText}
        </Button>
        <Modal
          backdrop='static'
          show={this.state.isModalShowing}
          onHide={this.handleCloseModalClick}
          onExited={this.onExited}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Feedback to Selected Shifts</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Body
              {...this.state}
              {...this.props}
              recordsSelectedCount={recordsSelectedCount}
              plural={plural}
              onStarClick={this.onStarClick}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCloseModalClick} bsSize='sm'>
              <i className='fa fa-times' /> Close
            </Button>
            <SubmitButton
              {...this.state}
              handleSubmitClick={this.handleSubmitClick}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const Body = ({ recordsSelectedCount, plural, score, onStarClick }) => {
  return (
    <>
      <Key />
      <p>
        Choose the rating for the{' '}
        <span className='text-success'>{recordsSelectedCount}</span> selected
        Shift{plural}:
      </p>
      <div style={{ fontSize: `25px`, textAlign: 'center' }}>
        <StarRatingComponent
          name='Score'
          onStarClick={onStarClick}
          renderStarIcon={() => <Icon icon='fa-star' />}
          value={score}
        />
      </div>
    </>
  );
};

const Key = () => {
  return (
    <WellSmall>
      <p>
        {`Rating based on the "5 P" methodology: Prepared, Professional, Positive,`}
        Polite, and Punctual
      </p>
      <p>The ratings work as follows:</p>
      <div style={{ fontSize: '16px' }}>
        <StarKeyComponent value={5} comment='Outstanding job!' />
        <StarKeyComponent value={4} comment='Nice work.' />
        <StarKeyComponent value={3} comment='Unsatisfactory.' />
        <StarKeyComponent value={2} comment='Poor Performance.' />
        <StarKeyComponent value={1} comment='Unacceptable.' />
      </div>
    </WellSmall>
  );
};

const StarKeyComponent = ({ value, comment }) => {
  let stars = [];
  for (let i = 1; i <= value; i++) {
    stars.push(<Icon key={i} icon='fa-star' colour='rgb(255, 180, 0)' />);
  }
  return (
    <Row>
      <Col sm={3} className='text-right'>
        {stars}
      </Col>
      <Col sm={9} style={{ fontSize: '14px' }}>
        {comment}
      </Col>
    </Row>
  );
};

const SubmitButton = (props) => {
  const buttonTitle = props.isSaving ? 'Adding Feedback...' : 'Add Feedback';
  return (
    <Button
      bsStyle='success'
      bsSize='sm'
      className='m-l-sm'
      onClick={props.handleSubmitClick}
      disabled={props.isSaving}
    >
      <Icon icon='fa-check' isSpinning={props.isSaving} /> {buttonTitle}
    </Button>
  );
};
