import { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { useListViewCheckbox } from 'hooks/useListViewCheckbox';
import useSubscription from 'hooks/useSubscription';
import { ShiftUtils, StartToEndCell } from 'pages/Shift/ShiftUtils';
import { Checkbox } from 'pages/Utils/Checkbox';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, SmallMuted, WellSmall } from 'pages/Utils/react-utils';
import { getCompletedShifts } from 'utils/api';
import { EditTimeModal } from '../../Shift/EditTimeModal';
import { WorkerImage } from '../../Worker/WorkerUtils';
import { HirerUtils } from '../HirerUtils';
import { ApproveSelectedButton } from './ApproveSelectedButton';
import './_styles/shiftListView.scss';

export const ShiftCompleteListView = () => {
  const { data, refetch } = useQuery(
    ['getCompletedShifts'],
    getCompletedShifts,
    {
      refetchInterval: HirerUtils.AutoRefreshFrequencyMs,
      onSuccess: (data) => {
        handleNewRecords(data.records);
      },
    }
  );
  const records = data?.records || [];

  const {
    resetSelectedRecords,
    recordIdsSelected,
    handleCheckboxChange,
    handleNewRecords,
  } = useListViewCheckbox({ records });

  const [recordToBeEdited, setRecordToBeEdited] = useState(null);

  useSubscription(
    [
      ShiftUtils.Events.TimesUpdated,
      ShiftUtils.Events.ShiftsApproved,
      ShiftUtils.Events.ShiftsDisapproved,
    ],
    () => {
      refetch();
      resetSelectedRecords();
    }
  );

  const handleEditClick = (shiftId) => {
    const record = records.find((record) => record.shiftId === shiftId);
    if (!record) {
      AppUtils.displayError(
        'Error',
        `Could not find Shift to edit with id: ${shiftId}`
      );
    }
    setRecordToBeEdited(record);
  };

  const clearRecordToBeEdited = () => {
    setRecordToBeEdited(null);
  };

  return (
    <>
      <Alert bsStyle='warning' className='m-b-none'>
        <p>
          This is a list of all Shifts which have been completed by the worker.
          They have added their Start and End times which now require approval.
        </p>
        <p>
          To approve some Shifts, check the relevant box for those you want to
          Approve and press the `Approve` button.
        </p>
        <p>
          If you want to edit the time of a Shift before you approve it, press{' '}
          <Icon icon='fa-clock' /> for the Shift you want to edit.
        </p>
      </Alert>

      <hr />

      <ApproveButtonContainer
        records={records}
        recordIdsSelected={recordIdsSelected}
      />

      <Table
        records={records}
        recordIdsSelected={recordIdsSelected}
        handleCheckboxChange={handleCheckboxChange}
        handleEditClick={handleEditClick}
      />

      <EditTimeModal
        record={recordToBeEdited}
        handleCloseModalClick={clearRecordToBeEdited}
      />
    </>
  );
};

const ApproveButtonContainer = ({ records, recordIdsSelected }) => {
  if (records.length <= 0) {
    return null;
  }
  return (
    <WellSmall className='text-right'>
      <ApproveSelectedButton
        records={records}
        recordIdsSelected={recordIdsSelected}
      />
    </WellSmall>
  );
};

const tableId = 'completed-shifts-table';

const Table = ({
  records,
  recordIdsSelected,
  handleCheckboxChange,
  handleEditClick,
}) => {
  if (records.length <= 0) {
    return (
      <WellSmall className='m-b-none'>No Completed shifts found.</WellSmall>
    );
  }

  const isRecordSelected = (id) => recordIdsSelected.includes(id);
  const areAllSelected =
    records.length > 0 && records.length === recordIdsSelected.length;

  return (
    <table className='table table-condensed table-striped' id={tableId}>
      <thead>
        <tr>
          <th>
            <Checkbox
              id={`checkbox_all`}
              checked={areAllSelected}
              onChange={() => handleCheckboxChange('all')}
            />
          </th>
          <th>Shift</th>
          <th>Shift Date/Time</th>
          <th>Worker</th>
          <th>
            Entered
            <i
              className='fa fa-info-circle m-l text-primary'
              data-toggle='tooltip'
              title='These are the times entered by the worker'
            />
          </th>
          <th>
            Edited
            <i
              className='fa fa-info-circle m-l text-primary'
              data-toggle='tooltip'
              title='These are the times which have been edited'
            />
          </th>
          <th className='no-sort' />
        </tr>
      </thead>
      <tbody>
        {records.map((item, i) => (
          <TableRow
            key={i}
            record={item}
            isSelected={isRecordSelected(item.shiftId)}
            handleCheckboxChange={handleCheckboxChange}
            handleEditClick={handleEditClick}
          />
        ))}
      </tbody>
    </table>
  );
};

const TableRow = ({
  record,
  isSelected,
  handleCheckboxChange,
  handleEditClick,
}) => {
  const shiftDateTime = AppUtils.concatDateAndTime(
    record.shiftDate,
    record.shiftTime
  );
  return (
    <tr className={isSelected ? `selected` : null}>
      <td>
        <Checkbox
          id={`checkbox_${record.shiftId}`}
          checked={isSelected}
          onChange={() => handleCheckboxChange(record.shiftId)}
        />
      </td>
      <td>
        {record.name}
        <br />
        <SmallMuted>{record.agencyName}</SmallMuted>
      </td>
      <td data-order={shiftDateTime}>
        {AppUtils.formatDateTimeNonUtc(shiftDateTime)}
      </td>
      <td>
        {record.workerHasImage && (
          <WorkerImage
            workerId={record.workerId}
            firstName={record.workerFirstName}
            surname={record.workerSurname}
            mobileNumber={record.workerMobileNumber}
          />
        )}
        {record.workerFirstName} {record.workerSurname}
      </td>
      <td>
        <StartToEndCell
          start={record.shiftTimeStartActualWorker}
          end={record.shiftTimeEndActualWorker}
          who={record.completedBy}
          when={record.completedDateTime}
        />
      </td>
      <td>
        <StartToEndCell
          start={record.shiftTimeStartActualConfirmed}
          end={record.shiftTimeEndActualConfirmed}
          who={record.shiftTimeConfirmedBy}
          when={record.shiftTimeConfirmedDateTime}
        />
      </td>
      <td className='text-right'>
        <EditButton record={record} handleEditClick={handleEditClick} />
      </td>
    </tr>
  );
};

const EditButton = ({ handleEditClick, record }) => {
  return (
    <Button
      bsStyle='primary'
      bsSize='xs'
      onClick={() => handleEditClick(record.shiftId)}
      data-toggle='tooltip'
      title='Edit Shift Times'
    >
      <Icon icon='fa-clock' />
    </Button>
  );
};
