import { useState } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useAuthoriseShiftPaymentMutation } from 'hooks/queries';
import { QueryKeys } from 'hooks/queries/queryUtils';
import { useModal } from 'hooks/useModal';
import { Icon } from 'pages/Utils/react-utils';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { PaymentAdjustmentsTable } from 'components/PaymentAdjustments';

export const usePaymentAdjustmentsModal = () => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModal();

  const [shiftId, setShiftId] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const { mutate, isError, error, isLoading } =
    useAuthoriseShiftPaymentMutation();

  const showPaymentAdjustmentsModal = (shiftId: string) => {
    setShiftId(shiftId);
    handleModalOpen();
  };

  const hidePaymentAdjustmentsModal = () => {
    setShiftId(null);
    handleModalClose();
  };

  const handleSubmitClick = () => {
    mutate(
      { shiftId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKeys.ApprovedShifts]);
          hidePaymentAdjustmentsModal();
        },
      }
    );
  };

  const renderPaymentAdjustmentsModal = () => {
    if (!shiftId) {
      return null;
    }

    return (
      <Modal
        backdrop='static'
        show={isModalOpen}
        onHide={hidePaymentAdjustmentsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Adjustments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='m-b'>
            <PaymentAdjustmentsTable shiftId={shiftId} />
          </div>
          <ErrorAlertForQueryOrNull isError={isError} error={error} />
          <Alert bsStyle='warning'>
            <strong>Warning:</strong> You are going to authorise the above
            adjustments and the overall payment for this shift. Are you sure you
            want proceed?
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hidePaymentAdjustmentsModal} bsSize='sm'>
            <i className='fa fa-times' /> Close
          </Button>
          <Button
            bsStyle='success'
            bsSize='sm'
            className='m-l-sm'
            onClick={handleSubmitClick}
            disabled={isLoading}
          >
            <Icon icon='fa-check' isSpinning={isLoading} />{' '}
            {isLoading ? 'Authorising...' : 'Yes, Authorise'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return {
    showPaymentAdjustmentsModal,
    hidePaymentAdjustmentsModal,
    renderPaymentAdjustmentsModal,
  };
};
