import { useState } from 'react';
import moment from 'moment';
import { ICustomEvent } from 'types/GeneralTypes';
import { AppUtils } from '../pages/Utils/app-utils';

const useFilterData = (
  filterFieldPrefix: string,
  adjustFromDateBy = -1,
  adjustToDateBy = 30
) => {
  const defaultFromDate = AppUtils.getDateString(
    moment().add(adjustFromDateBy, 'days'),
    'YYYY-MM-DD'
  );
  const defaultToDate = AppUtils.getDateString(
    moment().add(adjustToDateBy, 'days'),
    'YYYY-MM-DD'
  );

  const defaultFilterState = {
    [`${filterFieldPrefix}FromDate`]: defaultFromDate,
    [`${filterFieldPrefix}ToDate`]: defaultToDate,
  };
  const [filterData, setFilterData] = useState(defaultFilterState);

  const handleFilterChange = (e: ICustomEvent) => {
    setFilterData((currentFilterData) => {
      return {
        ...currentFilterData,
        [AppUtils.toCamelCase(e.target.name)]: e.target.value,
      };
    });
  };

  type FilterDataModel = {
    fromDate: string;
    toDate: string;
    [key: string]: string;
  };

  const getDataModel = (): FilterDataModel => {
    const dataModel = {};
    for (const [key, value] of Object.entries(filterData)) {
      if (key.startsWith(filterFieldPrefix)) {
        const keyToUse = key.substring(filterFieldPrefix.length);
        switch (keyToUse) {
          case 'fromDate':
            (dataModel as FilterDataModel).fromDate = value;
            break;

          case 'toDate':
            (dataModel as FilterDataModel).toDate = value;
            break;

          default:
            (dataModel as FilterDataModel)[
              key.substring(filterFieldPrefix.length)
            ] = value;
            break;
        }
      }
    }
    return dataModel as FilterDataModel;
  };

  return { filterData, getDataModel, handleFilterChange };
};

export default useFilterData;
