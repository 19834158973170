export const InvoiceEvents = {
  Saved: 'invoice.saved',
  Paid: 'invoice.paid',
  Sent: 'invoice.sent',
  Deleted: 'invoice.deleted',
} as const;

export const InvoiceStatus = {
  Draft: 'Draft',
  AwaitingApproval: 'Awaiting Approval',
  Approved: 'Approved',
  Sent: 'Sent',
  Paid: 'Paid',
} as const;
