import { Component } from 'react';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall, Muted } from 'pages/Utils/react-utils';
import { deleteSiteAssessment } from 'utils/api';
import { WorkerUtils } from './WorkerUtils';

export class DeleteSiteAssessmentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
      isDeleted: false,
    };

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExited() {
    this.setState({ isDeleted: false });
  }

  async handleDeleteClick() {
    this.setState({ isDeleting: true });
    AppUtils.clearNotifications();

    const data = await deleteSiteAssessment(
      this.props.recordToDelete.siteAssessmentId
    );
    AppUtils.handleAjaxDone(data, () => {
      AppUtils.displaySuccess('Done', 'Assessment deleted');
      this.setState({ isDeleted: true });
      AppUtils.publish(WorkerUtils.Events.SiteAssessmentDeleted, data);
      this.props.handleCloseModalClick();
    });
    this.setState({ isDeleting: false });
  }

  render() {
    return (
      <Modal
        backdrop='static'
        show={!!this.props.recordToDelete}
        onHide={this.props.handleCloseModalClick}
        onExited={this.onExited}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Shift Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Body {...this.props} {...this.state} />
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize='sm' onClick={this.props.handleCloseModalClick}>
            <i className='fa fa-times'></i> Cancel
          </Button>
          <ModalConfirmButton
            {...this.state}
            handleDeleteClick={this.handleDeleteClick}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const Body = (props) => {
  if (!props.recordToDelete) {
    return null;
  }
  return (
    <>
      <p>You have chosen to delete the following Site Assessment:</p>
      <WellSmall>
        <ConfirmRow label='Company' value={props.recordToDelete.companyName} />
        <ConfirmRow
          label='Assessment Date'
          value={AppUtils.formatDateTimeNonUtc(
            props.recordToDelete.assessmentDate,
            null,
            'DD/MM/YYYY'
          )}
        />
      </WellSmall>
      <Alert bsStyle='danger' bsSize='sm'>
        {`You won't be able to undo this. Are you sure you want to proceed?`}
      </Alert>
    </>
  );
};

const ConfirmRow = ({ label, value }) => {
  return (
    <Row>
      <Col sm={4} className='text-right'>
        <Muted>{label}</Muted>
      </Col>
      <Col sm={8}>{value}</Col>
    </Row>
  );
};

const ModalConfirmButton = (props) => {
  const isDisabled = props.isDeleting || props.isDeleted;
  const buttonTitle = props.isDeleting
    ? 'Deleting...'
    : props.isDeleted
    ? 'Deleted'
    : 'Yes, Delete Assessment';
  return (
    <Button
      bsStyle='danger'
      bsSize='sm'
      onClick={props.handleDeleteClick}
      disabled={isDisabled}
    >
      <Icon icon='fa-times' isSpinning={props.isDeleting} /> {buttonTitle}
    </Button>
  );
};
