import { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useUpdateShiftsFromShiftConfigMutation } from 'hooks/queries';
import { useModal } from 'hooks/useModal';
import { AppUtils } from 'pages/Utils/app-utils';
import { Icon, WellSmall } from 'pages/Utils/react-utils';
import { ErrorAlertForQueryOrNull } from 'components/ErrorAlertForQueryOrNull';
import { Stack } from 'components/Stack';
import { ShiftConfigEvents } from '../ShiftConfigUtils';
import { useShiftConfigContext } from './ShiftConfigContextProvider';

export const PushToShiftsSection = () => {
  return (
    <Alert bsStyle={'info'}>
      <PushToShiftsButton />
    </Alert>
  );
};

const usePushToShiftsModal = () => {
  const [shiftConfigId, setShiftConfigId] = useState<string | null>(null);
  const { handleModalClose, handleModalOpen, isModalOpen } = useModal();

  const handleShowModalClick = (shiftConfigId: string) => {
    setShiftConfigId(shiftConfigId);
    handleModalOpen();
  };

  const handleCloseModalClick = () => {
    setShiftConfigId(null);
    handleModalClose();
  };

  return {
    handleShowModalClick,
    handleCloseModalClick,
    isModalOpen,
    shiftConfigId,
  };
};

const PushToShiftsButton = () => {
  const {
    record: { shiftConfigId },
  } = useShiftConfigContext();
  const pushToShiftsModal = usePushToShiftsModal();
  return (
    <>
      <Button
        bsStyle={'primary'}
        bsSize='sm'
        block
        style={{ textAlign: 'center' }}
        onClick={() => pushToShiftsModal.handleShowModalClick(shiftConfigId)}
      >
        <Icon icon='fa-arrow-right' /> Update Shifts
      </Button>
      {pushToShiftsModal.shiftConfigId && pushToShiftsModal.isModalOpen ? (
        <ConfirmPushToShiftsModal
          handleCloseModalClick={pushToShiftsModal.handleCloseModalClick}
          showModal={pushToShiftsModal.isModalOpen}
          shiftConfigId={pushToShiftsModal.shiftConfigId}
        />
      ) : null}
    </>
  );
};

const ConfirmPushToShiftsModal = ({
  showModal,
  handleCloseModalClick,
  shiftConfigId,
}: {
  showModal: boolean;
  handleCloseModalClick: () => void;
  shiftConfigId: string;
}) => {
  const process = useUpdateShiftsFromShiftConfigMutation();
  const handleConfirmClick = () => {
    process.mutate(
      { shiftConfigId },
      {
        onSuccess: ({ shiftsUpdated }) => {
          AppUtils.publish(ShiftConfigEvents.ShiftConfigShiftSyncRequested);
          AppUtils.displaySuccess(
            'Complete',
            `${shiftsUpdated} shifts updated`
          );
          handleCloseModalClick();
        },
      }
    );
  };

  return (
    <Modal backdrop='static' show={showModal} onHide={handleCloseModalClick}>
      <Modal.Header closeButton>
        <Modal.Title>Update Shifts?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Body />
      </Modal.Body>
      <Modal.Footer>
        <Stack direction='column' gap={10}>
          <div>
            <Button bsSize='sm' onClick={handleCloseModalClick}>
              <i className='fa fa-times'></i> Cancel
            </Button>
            <ModalConfirmButton
              isLoading={process.isLoading}
              handleConfirmClick={handleConfirmClick}
            />
          </div>
          <div style={{ textAlign: 'left' }}>
            <ErrorAlertForQueryOrNull
              error={process.error}
              isError={process.isError}
            />
          </div>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
};

const Body = () => {
  return (
    <>
      <p>This process will do the following:</p>
      <WellSmall>
        <ul style={{ marginTop: 10 }}>
          <li>
            Find all existing shifts which were originally created with this
            template and are not yet authorised for payment
          </li>
          <li>
            Update the shift with the values now stored on this template, if
            they are different
          </li>
          <li>
            If a change is made to a shift where the shift times have been
            submitted, then the finance will be-recalcuated for the shift based
            on the updated values
          </li>
        </ul>
      </WellSmall>
      <Alert bsStyle={'warning'}>
        Please note, this process may take a little while to complete. Are you
        sure you want to proceed?
      </Alert>
    </>
  );
};

const ModalConfirmButton = ({
  isLoading,
  handleConfirmClick,
}: {
  isLoading: boolean;
  handleConfirmClick: () => void;
}) => {
  const isDisabled = isLoading;
  const buttonTitle = isLoading ? 'Updating Shifts...' : 'Yes, Update Shifts';
  return (
    <Button
      bsStyle={'primary'}
      bsSize='sm'
      onClick={handleConfirmClick}
      disabled={isDisabled}
    >
      <Icon icon={'fa-check'} isSpinning={isLoading} /> {buttonTitle}
    </Button>
  );
};
